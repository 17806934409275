/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable indent */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-indent */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from './store';
import Toast, { Toasts } from '../../../services/toast';
// styles
import { TableStyles, UserActionsContainer, EmptyContentText } from '../../../globalShared/CommonStyles';
import { PlayersContainer, Others } from './MealPlansStyles';

// Tooltip
import TooltipComponent from '../../../shared/components/Tooltip';

// icons
import { AvatarComponent, PaginationComponent } from '../../../globalShared';
import { localString } from '../../../services/locallization/localStrings';
import { shortenText } from '../../../services/utility';

const { Table, Tr, Th, Td } = TableStyles;

/* component */

const MealPlansView = () => {
  const [count, setCount] = useState(10);
  const dispatch = useDispatch();

  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  const { loading, mealPlanList, reloadList, success, error, totalItems, searchKey, page } = useSelector(
    (state) => state.coachMealPlan
  );

  const showMealPlanDetails = (id) => {
    dispatch(Creators.setMealPlanDetailsId({ id }));
  };

  const handleDelete = (id) => {
    dispatch(Creators.deleteCoachMealPlan({ id }));
  };

  const handleDuplicate = (id) => {
    dispatch(Creators.duplicateCoachMealPlan({ id }));
  };

  const handlePage = (page) => {
    dispatch(Creators.setMealPlanPage({ page }));
  };

  // hooks
  useEffect(() => {
    if (searchKey) {
      const payload = {
        count,
        page,
        searchKey
      };
      dispatch(Creators.searchCoachMealPlan(payload));
    } else {
      dispatch(Creators.getCoachMealPlanList({ page, count }));
    }
  }, [page, count, searchKey]);

  useEffect(() => {
    if (reloadList) {
      searchKey
        ? dispatch(Creators.searchCoachMealPlan({ count, page, searchKey }))
        : page === 1
        ? dispatch(Creators.getCoachMealPlanList({ page, count }))
        : handlePage(1);
    }
  }, [reloadList]);

  useEffect(() => {
    if (success) {
      const reset = () => dispatch(Creators.resetSuccess());
      Toast.success(string.success, 2, reset);
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      const reset = () => dispatch(Creators.resetError());
      Toast.error(error, 2, reset);
    }
  }, [error]);

  useEffect(
    () => () => {
      dispatch(Creators.mealPlanReset());
    },
    []
  );

  return (
    <>
      <Toasts />
      {mealPlanList.length ? (
        <>
          <div style={{ height: 'calc(100vh - 211px)', overflowY: 'auto', paddingRight: '1rem' }}>
            <Table>
              <thead>
                <tr>
                  <Th pLeft='2'>{string.title}</Th>
                  <Th>{string.assignedPlayers}</Th>
                  <Th>{string.mealPreference}</Th>
                  <Th>{string.createdBy}</Th>
                  <Th>{string.actions}</Th>
                </tr>
              </thead>
              <tbody>
                {mealPlanList.map((plan) => (
                  <Tr style={{ height: '80px' }} key={plan._id}>
                    <Td
                      style={{ cursor: 'pointer', maxWidth: '400px' }}
                      onClick={() => {
                        showMealPlanDetails(plan._id);
                        dispatch(Creators.setShowMealPlanDetailsModal({ show: true }));
                      }}
                    >
                      <Others className='clip'>{plan.title}</Others>
                    </Td>
                    <Td>
                      <PlayersContainer>
                        {plan.users.slice(0, 2).map((playerImg, i) => (
                          <span key={i}>
                            <AvatarComponent url={playerImg} />
                          </span>
                        ))}
                        <Others>
                          {plan.users.length - plan.users.slice(0, 2).length > 0
                            ? `+ ${plan.users.length - plan.users.slice(0, 2).length} others`
                            : ''}
                        </Others>
                      </PlayersContainer>
                    </Td>
                    <Td>{plan.typeOfFood}</Td>
                    <Td>
                      <Others>{shortenText(plan.createdBy, 15, '...')}</Others>
                    </Td>
                    <Td style={{ width: '160px' }}>
                      <UserActionsContainer style={{ justifyContent: 'space-between' }}>
                        <TooltipComponent
                          title='Duplicate'
                          type='duplicate'
                          onClick={() => handleDuplicate(plan._id)}
                        />
                        <TooltipComponent
                          title='Edit'
                          type='edit'
                          onClick={() => {
                            showMealPlanDetails(plan._id);
                            dispatch(Creators.setShowCreateMealPlanModal({ show: true }));
                          }}
                        />
                        <TooltipComponent title='Delete' type='delete' onClick={() => handleDelete(plan._id)} />
                      </UserActionsContainer>
                    </Td>
                  </Tr>
                ))}
              </tbody>
            </Table>
          </div>
          <PaginationComponent
            onChange={(value) => {
              handlePage(value.currentPage);
              setCount(value.count);
            }}
            totalItems={totalItems}
            title='Plans'
            pageNo={page}
          />
        </>
      ) : (
        !loading && <EmptyContentText style={{ height: 'calc(100vh - 162px)' }}>No meal plans to show</EmptyContentText>
      )}
    </>
  );
};

export default MealPlansView;
