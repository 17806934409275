import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Divider, FlexContainer } from '../../../../globalShared/CommonStyles';
import {
  fontSize,
  fontWeight,
  borderColor,
  accentColor,
  textColors,
  shadow,
  backgroundColor
} from '../../../../shared/constants/index';
import { Card } from '../../SkillsVIew/SkillsStyles';

export const Container = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: 2rem 0;
  padding-right: 1rem;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0rem;
`;

export const CardContainer = styled(Card)`
  padding: 0.2rem 1.2rem;
`;

export const CustomDivider = styled(Divider)`
  width: 100%;
  margin-top: 0.2rem;
`;

export const TextLite = styled.p`
  font-size: ${fontSize.smallDefault};
  font-weight: ${fontWeight.medium};
  color: #8792ac;
  margin: 0.5rem 0;
`;

export const Circle = styled.img`
  height: 40px;
  width: 40px;
  margin-right: 1.2rem;
`;

export const Session = styled.p`
  font-size: calc(${fontSize.mediumDefault} - 0.1rem);
  color: ${textColors.textLight};

  & > span {
    font-size: ${fontSize.mediumDefault};
    font-weight: ${fontWeight.medium};
    padding: 0 0.5rem;
    color: ${textColors.textWhite};
  }
`;

export const SessionContainer = styled(FlexContainer)`
  background: ${backgroundColor.cardItem};
  padding: 1.6rem;
  border-radius: 6px;
  box-shadow: 0px 10px 10px ${shadow.dark};
  margin-bottom: 1.2rem;
`;

export const Linkto = styled(Link)`
  font-size: ${fontSize.mediumDefault};
  color: ${accentColor.primary};

  &:focus {
    outline: 0px;
  }
`;

export const WorkoutContainer = styled.div`
  margin-left: 6rem;
  margin-bottom: 1rem;
  // max-height: 8rem;
  position: relative;
  // overflow-y: auto;
`;

export const WorkoutList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  max-height: 100px;
  overflow-y: auto;
`;

export const ListItem = styled.div`
  display: flex;
  font-size: ${fontSize.mediumDefault};
  margin: 10px 0 0 0;
  flex: 0 50%;
`;

export const VerticalLine = styled.div`
  background-color: ${borderColor.light};
  position: absolute;
  top: 0px;
  left: 14vw;
  height: 100%;
  width: 1px;
`;
