import styled from 'styled-components';
import { fontSize, backgroundColor, textColors } from '../../constants';

export const OuterContainer = styled.div`
  height: 100%;
  width: 100%;

  padding: 1rem;
`;

export const Container = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background: ${backgroundColor.dark};
  border-radius: 1rem;
  cursor: pointer;

  & > h2 {
    color: ${textColors.textLight};
    font-size: calc(0.2rem + ${fontSize.default});
    margin: 10px auto;
  }
`;
