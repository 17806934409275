/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable no-shadow */
import { put } from 'redux-saga/effects';
import { Creators } from './action';

import { WebService } from '../../../services/axios/webServices';
import { apiEndPoints } from '../../../services/axios/endPoints';

export function* getHelpAndSupportListSaga({ payload }) {
  const { page, count } = payload;

  try {
    yield put(Creators.getHelpAndSupportListStart());

    const fullURL = yield `${apiEndPoints.helpAndSupport}`;
    const response = yield WebService.get(fullURL, { params: { page, count } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(
        Creators.getHelpAndSupportListSuccess({
          helpAndSupportList: data[0],
          maxPage: data[1].page_count,
          totalCount: data[2].totalCount
        })
      );
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getHelpAndSupportListFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getHelpAndSupportListFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getHelpAndSupportListFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getHelpAndSupportListFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getHelpAndSupportListFailure(payload));
    }
  }
}

export function* getAdminHelpAndSupportListSaga({ payload }) {
  const { associatedTo, page, count } = payload;

  try {
    yield put(Creators.getAdminHelpAndSupportListStart());

    const fullURL = yield `${apiEndPoints.helpAndSupport}`;
    const response = yield WebService.get(fullURL, { params: { associatedTo, page, count } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(
        Creators.getAdminHelpAndSupportListSuccess({
          adminHelpAndSupportList: data[0]
        })
      );
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getAdminHelpAndSupportListFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getAdminHelpAndSupportListFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getAdminHelpAndSupportListFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getAdminHelpAndSupportListFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getAdminHelpAndSupportListFailure(payload));
    }
  }
}

export function* getCoachHelpAndSupportListSaga({ payload }) {
  const { associatedTo, page, count } = payload;

  try {
    yield put(Creators.getCoachHelpAndSupportListStart());

    const fullURL = yield `${apiEndPoints.helpAndSupport}`;
    const response = yield WebService.get(fullURL, { params: { associatedTo, page, count } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(
        Creators.getCoachHelpAndSupportListSuccess({
          coachHelpAndSupportList: data[0]
        })
      );
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getCoachHelpAndSupportListFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getCoachHelpAndSupportListFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getCoachHelpAndSupportListFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getCoachHelpAndSupportListFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getCoachHelpAndSupportListFailure(payload));
    }
  }
}

export function* getPlayerHelpAndSupportListSaga({ payload }) {
  const { associatedTo, page, count } = payload;

  try {
    yield put(Creators.getPlayerHelpAndSupportListStart());

    const fullURL = yield `${apiEndPoints.helpAndSupport}`;
    const response = yield WebService.get(fullURL, { params: { associatedTo, page, count } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(
        Creators.getPlayerHelpAndSupportListSuccess({
          playerHelpAndSupportList: data[0]
        })
      );
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getPlayerHelpAndSupportListFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getPlayerHelpAndSupportListFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getPlayerHelpAndSupportListFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getPlayerHelpAndSupportListFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getPlayerHelpAndSupportListFailure(payload));
    }
  }
}

export function* searchHelpAndSupportSaga({ payload }) {
  const { searchKey, page, count } = payload;

  try {
    yield put(Creators.searchHelpAndSupportStart());
    const fullURL = yield `${apiEndPoints.helpAndSupportSearch}`;
    const response = yield WebService.get(fullURL, { params: { searchKey, page, count } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      // console.log(data);
      yield put(
        Creators.searchHelpAndSupportSuccess({
          helpAndSupportList: data[0],
          maxPage: data[1].page_count,
          totalCount: data[2].totalCount
        })
      );
    } else {
      throw response;
    }
  } catch (error) {
    console.log(error);

    let payload;
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.searchHelpAndSupportFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.searchHelpAndSupportFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.searchHelpAndSupportFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update.Try again later'
          };
          yield put(Creators.searchHelpAndSupportFailure(payload));
      }
    } else {
      payload = {
        error: error.message
      };

      yield put(Creators.searchHelpAndSupportFailure(payload));
    }
  }
}
