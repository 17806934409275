import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  setPlayerMood: ['payload'],
  setPlayerMoodStart: ['payload'],
  setPlayerMoodSuccess: ['payload'],
  setPlayerMoodFailure: ['payload'],

  getPlayerMoodList: ['payload'],
  getPlayerMoodListStart: ['payload'],
  getPlayerMoodListSuccess: ['payload'],
  getPlayerMoodListFailure: ['payload'],

  deletePlayerMood: ['payload'],
  deletePlayerMoodStart: ['payload'],
  deletePlayerMoodSuccess: ['payload'],
  deletePlayerMoodFailure: ['payload'],

  getPlayerMoodComments: ['payload'],
  getPlayerMoodCommentsStart: ['payload'],
  getPlayerMoodCommentsSuccess: ['payload'],
  getPlayerMoodCommentsFailure: ['payload']
});

export { Types, Creators };
