import React from 'react';
import PropTypes from 'prop-types';

import { Textarea, Label } from './TextareaComponent.styles';

const TextareaComponent = (props) => {
  const { placeholderText, label, ipName, ipRef, height } = props;

  // const handleChange = ({ target }) => {
  //   if (!onChangeHandler) {
  //     return;
  //   }
  //   onChangeHandler(target);
  // };

  return (
    <div>
      <Label>{label}</Label>
      <Textarea
        placeholder={placeholderText}
        name={ipName}
        ref={ipRef}
        height={height}
        //  onChange={(e) => handleChange(e)} enable if handle change required
      />
    </div>
  );
};

TextareaComponent.defaultProps = {
  label: '',
  height: '135px'
};

TextareaComponent.propTypes = {
  label: PropTypes.string,
  height: PropTypes.string,
  ipName: PropTypes.string.isRequired,
  ipRef: PropTypes.func.isRequired,
  placeholderText: PropTypes.string.isRequired
};

export default TextareaComponent;
