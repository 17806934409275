/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { Creators } from './store';
import { Creators as CommonCreators } from '../../store';

// styles
import {
  ScrollContainer,
  MealContainer,
  MealHeader,
  MealTitleContainer,
  CheckNew,
  MealTitle,
  MealOptionsContainer,
  ActionContainer,
  DoneContainer,
  MealUndoneOption,
  MealUndoneMenu,
  DropdownWrapper,
  Rotate
} from './MealStyles';

// icons
import { ReactComponent as Dropdown } from '../../../shared/assets/Dropdown.svg';
import { ReactComponent as SmallBar } from '../../../shared/assets/small_bar_nutrition_meals.svg';
import { ReactComponent as MarkCompleteMealIcon } from '../../../shared/assets/mark-complete-exercise.svg';
import { ReactComponent as MenuIcon } from '../../../shared/assets/3dot_menu.svg';

import Accordion from './Accordion';
import MealActionModal from './MealActionModal';
import ManualInputModal from './ManualInputModal';

const Meals = (props) => {
  const { date } = props;
  // const menuRef = useRef();

  const [show, setShow] = useState(false);
  const [currentMealId, setCurrentMealId] = useState('');
  const [currentMealTitle, setCurrentMealTitle] = useState('');
  const [currentMealTime, setCurrentMealTime] = useState('');
  const [currentMeal, setCurrentMeal] = useState('');
  const [showMealActionModal, setShowMealActionModal] = useState(false);
  const [showManualInputModal, setShowManualInputModal] = useState(false);
  const [showMealUndoneOptionId, setShowMealUndoneOptionId] = useState(null);

  const mealsData = useSelector((state) => state.playerMealPlan.meals);
  const intakeSummaryData = useSelector((state) => state.playerMealPlan.intakeSummary);
  const mealCommentsList = useSelector((state) => state.playerMealPlan.mealCommentsList);
  const reloadComments = useSelector((state) => state.main.reloadComments);

  const dispatch = useDispatch();

  const toggle = (index) => {
    if (show === index) {
      return setShow(null);
    }
    setShow(index);
  };

  const handleShowMealUndoneOption = (mealId) => {
    setShowMealUndoneOptionId(mealId);

    if (showMealUndoneOptionId) {
      setShowMealUndoneOptionId(null);
    } else {
      setShowMealUndoneOptionId(mealId);
    }
  };

  const todaysDate = moment().format('YYYY-MM-DD');
  // useTouchOutside(menuRef, () => handleShowMealUndoneOption(null));

  const handleSubmitComment = (comment) => {
    dispatch(CommonCreators.addComment({ ...comment, secondaryId: intakeSummaryData._id }));
    setCurrentMealId(comment.id);
  };

  const handleGetComment = (_id) => {
    if (_id) {
      dispatch(Creators.getPlayerMealComments({ _id, referenceId: intakeSummaryData._id, count: 100 }));
      setCurrentMealId(_id);
    }
  };

  const handleMarkasUndone = (mealId) => {
    dispatch(Creators.setPlayerMealUndoneUpdate({ _id: intakeSummaryData._id, mealId }));
    setShowMealUndoneOptionId(null);
  };

  // effect hooks
  useEffect(() => {
    if (reloadComments) {
      dispatch(Creators.getPlayerMealComments({ _id: currentMealId, referenceId: intakeSummaryData._id, count: 100 }));
    }
  }, [reloadComments]);

  return (
    <>
      <ScrollContainer>
        {mealsData &&
          mealsData.map((meal, index) => {
            const { mealId, title, time, mealStatus } = meal;
            return (
              <Fragment key={index}>
                <MealContainer>
                  <MealHeader>
                    <MealTitleContainer onClick={() => toggle(index)} key={index}>
                      {mealStatus === 'New' ? (
                        <CheckNew>
                          <SmallBar />
                        </CheckNew>
                      ) : (
                        ''
                      )}
                      <MealTitle>{title}</MealTitle>
                    </MealTitleContainer>

                    <MealOptionsContainer onMouseLeave={() => handleShowMealUndoneOption()}>
                      {mealStatus === 'New' && date === todaysDate ? (
                        <ActionContainer
                          onClick={() => {
                            setCurrentMealId(mealId);
                            setCurrentMealTitle(title);
                            setCurrentMealTime(time);
                            setCurrentMeal(meal);
                            setShowMealActionModal(true);
                          }}
                        >
                          <MarkCompleteMealIcon />
                        </ActionContainer>
                      ) : (
                        ''
                      )}
                      {mealStatus !== 'New' && date === todaysDate ? (
                        <DoneContainer>
                          <p>Done</p>
                          <MealUndoneOption>
                            <MenuIcon
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleShowMealUndoneOption(mealId)}
                            />
                            <MealUndoneMenu showMealUndone={showMealUndoneOptionId === mealId}>
                              <div onClick={() => handleMarkasUndone(mealId)}>Mark as Undone</div>
                            </MealUndoneMenu>
                          </MealUndoneOption>
                        </DoneContainer>
                      ) : (
                        ''
                      )}
                      <DropdownWrapper onClick={() => toggle(index)} key={index}>
                        {show === index ? (
                          <Rotate>
                            <Dropdown />
                          </Rotate>
                        ) : (
                          <Dropdown />
                        )}
                      </DropdownWrapper>
                    </MealOptionsContainer>
                  </MealHeader>
                  <div>
                    {show === index && (
                      <Accordion
                        meal={meal}
                        intakeSummaryData={intakeSummaryData}
                        submitComment={handleSubmitComment}
                        onShowComments={handleGetComment}
                        comments={mealCommentsList}
                      />
                    )}
                  </div>
                </MealContainer>
              </Fragment>
            );
          })}
      </ScrollContainer>
      {showMealActionModal && (
        <MealActionModal
          _id={intakeSummaryData._id}
          date={date}
          meal={currentMeal}
          onCancel={() => setShowMealActionModal(false)}
          showManualInput={() => setShowManualInputModal(true)}
        />
      )}
      {showManualInputModal && (
        <ManualInputModal
          _id={intakeSummaryData._id}
          date={date}
          time={currentMealTime}
          mealId={currentMealId}
          mealTitle={currentMealTitle}
          inputModalClose={() => setShowManualInputModal(false)}
        />
      )}
    </>
  );
};

export default Meals;
