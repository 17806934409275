/* eslint-disable arrow-body-style */
import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { FlexContainer } from '../../../globalShared/CommonStyles';
import { localString } from '../../../services/locallization/localStrings';
import useTouchOutside from '../../hooks/useTouchOutside';
import {
  Container,
  Title,
  Header,
  DropdownContainer,
  Select,
  Options,
  Option,
  Chart,
  LabelCon
} from './ChartCardStyles';

const index = (props) => {
  const { title, optionArray, onClick, selected, children, label, border, bgc, showLabel = false } = props;
  const dropDownRef = useRef();

  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  const [showDropdown, setShowDropdown] = useState(false);

  useTouchOutside(dropDownRef, () => setShowDropdown(false));

  const handleClick = (option) => {
    onClick(option);
    setShowDropdown(!showDropdown);
  };

  return (
    <Container>
      <Header>
        <Title>{title}</Title>
        <DropdownContainer ref={dropDownRef}>
          <Select active={showDropdown} onClick={() => setShowDropdown(!showDropdown)}>
            {selected ? selected.label : string.select}
          </Select>
          {showDropdown && (
            <Options>
              <div>
                {optionArray.map((option) => {
                  const { label, value } = option;
                  return (
                    <Option key={value} value={value} onClick={() => handleClick(option)}>
                      {label}
                    </Option>
                  );
                })}
              </div>
            </Options>
          )}
        </DropdownContainer>
      </Header>
      <Chart>{children}</Chart>
      {showLabel && (
        <LabelCon border={border} bgc={bgc}>
          <div />
          <p>{label}</p>
        </LabelCon>
      )}
    </Container>
  );
};

export default index;
