/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Creators } from './store';
import { Creators as CommonCreators } from '../../store';
import { timeSince } from '../../../services/utility';

// styles
import { H1, MedContainer, HDiv, H2, Stats, TextSmallLight, LegendButtons } from './MeditationDetailsModalStyles';
import { CommentInputContainer, TabContainer } from './MoodDetailsModalStyles';
import { Divider, FlexContainer } from '../../../globalShared/CommonStyles';
import { CommentInputComponent, LineChart } from '../../../globalShared';

// icons
import { ReactComponent as MeditateIcon } from '../../../shared/assets/meditate.svg';

import CommentsFooter from '../../../shared/components/CommetsFooter';
import { getDay, getMothSmall, getYearMonthDay } from '../../../services/utility/moment';
import { meditationMap } from '../../../services/utility/maps';
import { getPastDays } from '../../Player/HomeView/utlity';

const MeditationDetailsModal = ({ data }) => {
  const { _id, userId, meditationName } = data;

  const dispatch = useDispatch();
  const [days, setDays] = useState(7);
  const [dataType, setDataType] = useState('days');
  const [recentCommentID, setRecentCommentID] = useState('');

  // values from redux store
  const meditationDetails = useSelector((state) => state.coachMindset.meditationDetails);
  const meditationStats = useSelector((state) => state.coachMindset.meditationStats);
  const avgMeditationDuration = useSelector((state) => state.coachMindset.avgMeditationDuration);
  const meditationCommentsList = useSelector((state) => state.coachMindset.meditationCommentsList);
  const meditationCommentCount = useSelector((state) => state.coachMindset.meditationCommentCount);
  const reloadComments = useSelector((state) => state.main.reloadComments);

  const { name, duration, updatedTime } = meditationDetails;
  const meditationMinutes = Math.floor(duration / 60);
  const meditationSeconds = duration % 60;
  const formattedTime = timeSince(updatedTime);

  const avgMeditationDurationArray = avgMeditationDuration && avgMeditationDuration.split(':');
  // const avgMeditationDurationHours = avgMeditationDuration && avgMeditationDurationArray[0];
  const avgMeditationMinutes = avgMeditationDuration && avgMeditationDurationArray[1];
  const avgMeditationSeconds = avgMeditationDuration && parseInt(avgMeditationDurationArray[2], 10);

  const pastDays = getPastDays({ type: dataType, days });

  pastDays.forEach((item) => {
    let filterDay = [];

    if (dataType === 'month') {
      filterDay = meditationStats.filter((stat) => getMothSmall(stat.time) === item.MM);
    } else {
      filterDay = meditationStats.filter((stat) => getYearMonthDay(stat.time) === item.MMDDYY);
    }

    item.sleepTime = filterDay.length > 0 ? filterDay[0].duration : null;
  });

  const meditationDates = [];
  const meditationHrs = [];

  pastDays.reverse().forEach((item) => {
    if (dataType === 'month') {
      meditationDates.push(getMothSmall(item.MMDDYY));
    } else {
      meditationDates.push(getDay(item.MMDDYY));
    }
    meditationHrs.push(item.sleepTime);
  });

  // line chart
  const chartData = (canvas) => {
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, canvas.height, 0, 0);
    gradient.addColorStop(0, '#21D0B300');
    gradient.addColorStop(1, '#21D0B382');

    return {
      labels: meditationDates,
      datasets: [
        {
          data: meditationHrs,
          fill: true,
          backgroundColor: gradient,
          borderColor: 'rgba(75,192,192,1)',
          lineTension: 0
        }
      ]
    };
  };

  // effect hooks
  useEffect(() => {
    dispatch(Creators.getCoachMeditationDetails({ meditationName, _id }));
    dispatch(Creators.getCoachMeditationComments({ _id, count: 100, page: 1 }));
  }, []);

  useEffect(() => {
    dispatch(Creators.getCoachMeditationStats({ page: 1, count: 30, userId, dataType, days }));
  }, [userId, days]);

  const handleShowComments = (_id) => {
    dispatch(Creators.getCoachMeditationComments({ _id, count: 100, page: 1 }));
    setRecentCommentID(_id);
  };

  const handleSubmitComment = (content, id) => {
    dispatch(CommonCreators.addComment({ content, id }));
    setRecentCommentID(id);
  };

  useEffect(() => {
    if (reloadComments && !!recentCommentID) {
      handleShowComments(recentCommentID);
    }
  }, [reloadComments]);

  const handleWeekMeditation = () => {
    setDataType('days');
    setDays(7);
  };

  const handleMonthMeditation = () => {
    setDataType('days');
    setDays(30);
  };

  const handleSixMonthMeditation = () => {
    setDataType('month');
    setDays(180);
  };

  return (
    <TabContainer>
      <MedContainer>
        <FlexContainer style={{ justifyContent: 'space-between', paddingBottom: '0.5rem' }}>
          <FlexContainer>
            <MeditateIcon style={{ marginRight: '1rem' }} />
            <H2>{meditationMap[name]}</H2>
            <HDiv />
            <TextSmallLight>{`${meditationMinutes} Mins ${meditationSeconds} Sec`}</TextSmallLight>
          </FlexContainer>
          <FlexContainer>
            <TextSmallLight>{formattedTime}</TextSmallLight>
          </FlexContainer>
        </FlexContainer>
        <Divider />
        <div>
          <CommentsFooter
            commentsList={meditationCommentsList}
            _id={_id}
            commentCount={meditationCommentCount || 0}
            onClick={handleShowComments}
          />
          <CommentInputContainer>
            <CommentInputComponent placeholderText='Enter Your Comment Here…' id={_id} onSubmit={handleSubmitComment} />
          </CommentInputContainer>
        </div>
      </MedContainer>

      <div>
        <H2 style={{ marginBottom: '2rem' }}>Meditation Stats</H2>
        <Stats>
          <TextSmallLight style={{ margin: '0rem 1rem' }}>Average Meditation Duration</TextSmallLight>
          <H1>{`${avgMeditationMinutes} Mins ${avgMeditationSeconds} Sec`}</H1>
        </Stats>
        <FlexContainer style={{ justifyContent: 'center' }}>
          <LegendButtons active={days === 7} onClick={() => handleWeekMeditation()}>
            Weekly
          </LegendButtons>
          <LegendButtons active={days === 30} onClick={() => handleMonthMeditation()}>
            Monthly
          </LegendButtons>
          <LegendButtons active={days === 180} onClick={() => handleSixMonthMeditation()}>
            6 Months
          </LegendButtons>
        </FlexContainer>
        <div style={{ height: '120px', position: 'relative' }}>
          <LineChart data={chartData} yLabel='Meditation Duration' />
        </div>
      </div>
    </TabContainer>
  );
};

export default MeditationDetailsModal;
