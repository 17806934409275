/* eslint-disable no-unused-vars */
/*
 * reducer.js file for coach videoplayer view
 * Author:
 * 1. Manikanta
 * 2. Vijay
 * @copyright of Panorbit Services LLP.
 */

import { createReducer } from 'reduxsauce';
import { mergeArray } from '../../../../services/utility';
import { Types } from './action';

const INITIAL_STATE = {
  loading: false,
  error: null,
  videoDetails: {},
  reload: false,
  reloadComments: false,
  success: false,
  commentsList: [],
  showEditVideo: false,
  showDeleteVideo: false,
  reloadVideoDetails: false,
  videosReload: false,
  maxPage: 0,
  commentsCount: 0
};

const getPlayerVideoDetailsStart = (state = INITIAL_STATE, action) => ({ ...state, error: null, loading: true });

const getPlayerVideoDetailsSuccess = (state = INITIAL_STATE, action) => {
  const { videoDetails } = action.payload;

  return {
    ...state,
    loading: false,
    reload: false,
    videoDetails,
    reloadVideoDetails: true
  };
};

const getPlayerVideoDetailsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const setReloadVideoDetails = (state = INITIAL_STATE, action) => {
  const { reloadVideoDetails } = action.payload;
  return {
    ...state,
    reloadVideoDetails
  };
};

const setVideosReload = (state = INITIAL_STATE, action) => {
  const { videosReload } = action.payload;
  return {
    ...state,
    videosReload
  };
};

const getPlayerVideoCommentsListStart = (state = INITIAL_STATE, action) => ({
  ...state,
  error: null,
  commentsLoading: true,
  reloadComments: false
});

const getPlayerVideoCommentsListSuccess = (state = INITIAL_STATE, action) => {
  const { commentsList, maxPage, commentsCount } = action.payload;
  const newList = commentsList;
  const oldList = state.commentsList;
  const newArray = mergeArray(oldList, newList);
  return {
    ...state,
    commentsLoading: false,
    reload: false,
    reloadComments: false,
    commentsList: newArray,
    maxPage,
    commentsCount
  };
};

const getPlayerVideoCommentsListFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    commentsLoading: false,
    reloadComments: false,
    error
  };
};

const addPlayerVideoCommentStart = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true,
  reloadComments: false
});

const addPlayerVideoCommentSuccess = (state = INITIAL_STATE) => ({ ...state, loading: false, reloadComments: true });

const addPlayerVideoCommentFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    reloadComments: false,
    error
  };
};

const editPlayerVideoStart = (state = INITIAL_STATE, action) => ({ ...state, error: null, loading: true });

const editPlayerVideoSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  error: null,
  loading: false,
  success: true,
  reloadVideoDetails: true,
  videosReload: true
});

const editPlayerVideoFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const deletePlayerVideoStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: true,
  success: false,
  reload: false,
  videosReload: false
});

const deletePlayerVideoSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  reload: true,
  success: true,
  videosReload: true
});

const deletePlayerVideoFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    reload: false,
    videosReload: false,
    error
  };
};

const setShowEditVideoModal = (state = INITIAL_STATE, action) => {
  const { showEditVideo } = action.payload;

  return {
    ...state,
    showEditVideo
  };
};

const setShowDeleteVideoModal = (state = INITIAL_STATE, action) => {
  const { showDeleteVideo } = action.payload;

  return {
    ...state,
    showDeleteVideo
  };
};

const resetSuccess = (state = INITIAL_STATE, action) => ({ ...state, success: false });
const resetError = (state = INITIAL_STATE, action) => ({ ...state, error: null });

const resetComments = (state = INITIAL_STATE) => ({ ...state, commentsList: [], commentsCount: 0 });

const HANDLERS = {
  [Types.GET_PLAYER_VIDEO_DETAILS_START]: getPlayerVideoDetailsStart,
  [Types.GET_PLAYER_VIDEO_DETAILS_SUCCESS]: getPlayerVideoDetailsSuccess,
  [Types.GET_PLAYER_VIDEO_DETAILS_FAILURE]: getPlayerVideoDetailsFailure,

  [Types.SET_RELOAD_VIDEO_DETAILS]: setReloadVideoDetails,
  [Types.SET_VIDEOS_RELOAD]: setVideosReload,

  [Types.GET_PLAYER_VIDEO_COMMENTS_LIST_START]: getPlayerVideoCommentsListStart,
  [Types.GET_PLAYER_VIDEO_COMMENTS_LIST_SUCCESS]: getPlayerVideoCommentsListSuccess,
  [Types.GET_PLAYER_VIDEO_COMMENTS_LIST_FAILURE]: getPlayerVideoCommentsListFailure,

  [Types.ADD_PLAYER_VIDEO_COMMENT_START]: addPlayerVideoCommentStart,
  [Types.ADD_PLAYER_VIDEO_COMMENT_SUCCESS]: addPlayerVideoCommentSuccess,
  [Types.ADD_PLAYER_VIDEO_COMMENT_FAILURE]: addPlayerVideoCommentFailure,

  [Types.EDIT_PLAYER_VIDEO_START]: editPlayerVideoStart,
  [Types.EDIT_PLAYER_VIDEO_SUCCESS]: editPlayerVideoSuccess,
  [Types.EDIT_PLAYER_VIDEO_FAILURE]: editPlayerVideoFailure,

  [Types.DELETE_PLAYER_VIDEO_START]: deletePlayerVideoStart,
  [Types.DELETE_PLAYER_VIDEO_SUCCESS]: deletePlayerVideoSuccess,
  [Types.DELETE_PLAYER_VIDEO_FAILURE]: deletePlayerVideoFailure,

  [Types.SET_SHOW_EDIT_VIDEO_MODAL]: setShowEditVideoModal,
  [Types.SET_SHOW_DELETE_VIDEO_MODAL]: setShowDeleteVideoModal,
  [Types.RESET_SUCCESS]: resetSuccess,
  [Types.RESET_ERROR]: resetError,
  [Types.RESET_COMMENTS]: resetComments
};

export const playerVideoPlayerReducer = createReducer(INITIAL_STATE, HANDLERS);
