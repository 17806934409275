import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  loading: false,
  error: null,
  success: false,
  resetSuccess: false,
  reloadPersonalDetails: false,
  reloadProfessionalData: false
};

const updatePlayerPersonalDetailsStart = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  reloadPersonalDetails: false
});

const updatePlayerPersonalDetailsSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  success: true,
  reloadPersonalDetails: true
});

const updatePlayerPersonalDetailsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    success: false,
    reloadPersonalDetails: false,
    error
  };
};

const updateFileStart = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  success: false,
  reloadProfessionalData: true
});

const updateFileSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  success: true,
  reloadProfessionalData: true
});

const updateFileFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;

  return {
    ...state,
    loading: false,
    reloadProfessionalData: false,
    error
  };
};

const resetSuccess = (state = INITIAL_STATE) => ({
  ...state,
  resetSuccess: false,
  success: false
});

const resetError = (state = INITIAL_STATE) => ({
  ...state,
  error: null
});

const updatePlayerProfessionalDetailsSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  success: true,
  reloadProfessionalData: true
});

const HANDLERS = {
  [Types.UPDATE_PLAYER_PERSONAL_DETAILS_START]: updatePlayerPersonalDetailsStart,
  [Types.UPDATE_PLAYER_PERSONAL_DETAILS_SUCCESS]: updatePlayerPersonalDetailsSuccess,
  [Types.UPDATE_PLAYER_PERSONAL_DETAILS_FAILURE]: updatePlayerPersonalDetailsFailure,
  [Types.UPDATE_PLAYER_PROFESSIONAL_DETAILS_SUCCESS]: updatePlayerProfessionalDetailsSuccess,

  [Types.UPDATE_FILE_START]: updateFileStart,
  [Types.UPDATE_FILE_SUCCESS]: updateFileSuccess,
  [Types.UPDATE_FILE_FAILURE]: updateFileFailure,

  [Types.RESET_SUCCESS]: resetSuccess,
  [Types.RESET_ERROR]: resetError
};

export const playerProfileReducer = createReducer(INITIAL_STATE, HANDLERS);
