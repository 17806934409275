/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-underscore-dangle */
/* eslint-disable operator-linebreak */
/* eslint-disable no-shadow */
/* eslint-disable arrow-body-style */

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Toast, { Toasts } from '../../../services/toast';

import { Creators } from './store';
import { Creators as CreateMeal } from '../MealPlansView/store';
import { Creators as NutritionCreators } from '../NutritionViews/store';

import { datesInMonth, MonthIndicator, WeekdayIndicator } from '../../../services/utility/Calander';

// styles
import { Divider, FlexContainer, FlexWraper } from '../../../globalShared/CommonStyles';
import {
  Header,
  HeaderTxt,
  P,
  Day,
  DayHeader,
  Meal,
  SessionsContainer,
  Menu,
  MenuItem,
  WeekRow,
  Aside,
  AlergyButton,
  Preference
} from './PlannerDetailsStyles';

// icons
import { ReactComponent as BackIcon } from '../../../shared/assets/back-header.svg';
import { ReactComponent as MenuIcon } from '../../../shared/assets/3dot_menu.svg';

import {
  compareDates,
  getDayOfMonth,
  getMonthDayYear,
  getToday,
  getYearMonthDay
} from '../../../services/utility/moment';
import { LoadingComponent, TooltipComponnet } from '../../../globalShared';
import AddMealPlanModal from './AddMealPlanModal';
import FoodAllergyModal from './FoodAllergyModal';
import MealPlanDetailsModal from './MealDetailsModal';
import CreateCustomMealPlanModal from './CreateCustomMeal';
import { shortenText } from '../../../services/utility';

// constants

const Options = ({ date, copyDate, onCopy, onPaste, onClear, onAdd, isOptions }) => {
  const [showMenu, setShowMenu] = useState(false);

  const today = getToday();
  const isAfter = compareDates(date, today);
  const OptionsActive = isAfter || getMonthDayYear(today) === getMonthDayYear(date);
  const showAdd = !(isOptions && getMonthDayYear(today) === getMonthDayYear(date));
  const isCopySameDate = getMonthDayYear(copyDate) !== getMonthDayYear(date);

  const handleOnPaste = () => {
    if (copyDate && isCopySameDate && OptionsActive) {
      onPaste(copyDate, date);
    }
  };

  return (
    <div style={{ position: 'relative', display: 'flex' }}>
      {OptionsActive && showAdd && (
        <TooltipComponnet title='Add Meal Plan' position='bottom' type='add' onClick={() => onAdd(date)} />
      )}

      <MenuIcon style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={() => setShowMenu(true)} />

      <Menu show={showMenu} onMouseLeave={() => setShowMenu(false)}>
        <MenuItem
          isActive={date !== copyDate && isOptions}
          onClick={() => (date !== copyDate && isOptions ? onCopy(date) : null)}
        >
          Copy Schedule
        </MenuItem>

        <MenuItem isActive={copyDate && isCopySameDate && OptionsActive} onClick={handleOnPaste}>
          Paste Schedule
        </MenuItem>

        <MenuItem isActive={isOptions} onClick={() => (isOptions ? onClear(date) : null)}>
          Clear Schedule
        </MenuItem>
      </Menu>
    </div>
  );
};

const WeekOptions = ({ startDate, endDate, copiedWeek, text, onCopy, onPaste, onClear }) => {
  const [showMenu, setShowMenu] = useState(false);
  const today = getYearMonthDay(getToday());
  const isCompleted =
    !compareDates(getYearMonthDay(startDate), today) && !compareDates(getYearMonthDay(endDate), today);

  const isCopySameWeek =
    getMonthDayYear(copiedWeek?.selectStartDate) === getMonthDayYear(startDate) &&
    getMonthDayYear(copiedWeek?.selectEndDate) === getMonthDayYear(endDate);

  const handleOnPaste = () => {
    if (!isCopySameWeek && !isCompleted) {
      onPaste({ targetStartDate: startDate, targetEndDate: endDate });
    }
  };

  const handleOnCopy = () => {
    onCopy({ selectStartDate: startDate, selectEndDate: endDate });
  };

  const handleOnClear = () => {
    if (!isCompleted) {
      onClear({ startDate, endDate });
    }
  };

  return (
    <Aside>
      {text}
      <MenuIcon style={{ marginLeft: '1rem', cursor: 'pointer', zIndex: '0' }} onClick={() => setShowMenu(true)} />

      <Menu
        style={{ transform: 'rotate(90deg)', zIndex: '9999999' }}
        show={showMenu}
        onMouseLeave={() => setShowMenu(false)}
      >
        <MenuItem isActive onClick={handleOnCopy}>
          Copy Schedule
        </MenuItem>

        <MenuItem isActive={!isCopySameWeek && !isCompleted} onClick={handleOnPaste}>
          Paste Schedule
        </MenuItem>

        <MenuItem isActive={!isCompleted} onClick={handleOnClear}>
          Clear Schedule
        </MenuItem>
      </Menu>
    </Aside>
  );
};

const MealPlannerDetailView = (props) => {
  const { history } = props;
  const { playerId } = useParams();
  const dispatch = useDispatch();
  const plannerDetails = useSelector((state) => state.coachPlayerMealPlan.plannerDetails);
  const reloadPlanner = useSelector((state) => state.coachPlayerMealPlan.reloadPlanner);
  const success = useSelector((state) => state.coachPlayerMealPlan.success);
  const error = useSelector((state) => state.coachPlayerMealPlan.error);
  const loading = useSelector((state) => state.coachPlayerMealPlan.loading);

  const reloadList = useSelector((state) => state.coachMealPlan.reloadList);
  const customsuccess = useSelector((state) => state.coachMealPlan.success);
  const customerror = useSelector((state) => state.coachMealPlan.error);

  const [mealsList, setMealsList] = useState([]);
  const [showAddMeal, setShowAddMeal] = useState(false);
  const [showAllergy, setshowAllergy] = useState(false);
  const [selectDate, setSelectDate] = useState(getToday());
  const [selectId, setselectId] = useState('');
  const [copyDate, setCopyDate] = useState('');

  const [MealDetails, setMealDetails] = useState(null);

  const [DatesInMonth, setdatsInMonth] = useState([]);
  const [sessionDate, setsessionDate] = useState(null);

  const [selectedWeek, setselectedWeek] = useState(null);
  const [isWeek6, setisWeek6] = useState(false);
  const [showCreateMeal, setshowCreateMeal] = useState(false);
  const [showMealDetails, setShowMealDetails] = useState(false);
  const [mealToEdit, setmealToEdit] = useState(null);

  const getDaySessions = (day) => {
    return mealsList?.filter((item) => item.plannerDate === getYearMonthDay(day));
  };

  useEffect(() => {
    if (playerId && DatesInMonth.length) {
      dispatch(
        Creators.getMealPlanDetails({
          _id: playerId,
          fromDate: getYearMonthDay(DatesInMonth[0]?.date),
          toDate: getYearMonthDay(DatesInMonth[41]?.date)
        })
      );
    }
  }, [playerId, DatesInMonth]);

  useEffect(() => {
    if (reloadPlanner) {
      dispatch(
        Creators.getMealPlanDetails({
          _id: playerId,
          fromDate: getYearMonthDay(DatesInMonth[0]?.date),
          toDate: getYearMonthDay(DatesInMonth[41]?.date)
        })
      );
    }
  }, [reloadPlanner]);

  useEffect(() => {
    if (success) {
      const reset = () => dispatch(Creators.resetSuccess());
      Toast.success('success', 2, reset);
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      const reset = () => dispatch(Creators.resetError());
      Toast.error(error, 2, reset);
    }
  }, [error]);

  useEffect(() => {
    if (customerror) {
      const reset = () => dispatch(CreateMeal.resetError());
      Toast.error(error, 2, reset);
    }
  }, [customerror]);

  useEffect(() => {
    if (reloadList) {
      dispatch(
        Creators.getMealPlanDetails({
          _id: playerId,
          fromDate: getYearMonthDay(DatesInMonth[0]?.date),
          toDate: getYearMonthDay(DatesInMonth[41]?.date)
        })
      );
    }
  }, [reloadList]);

  useEffect(() => {
    if (customsuccess) {
      const reset = () => dispatch(CreateMeal.resetSuccess());
      Toast.success('success', 2, reset);
    }
  }, [customsuccess]);

  useEffect(() => {
    if (plannerDetails) {
      setMealsList([...plannerDetails.mealsPlans]);
    }
  }, [plannerDetails]);

  useEffect(() => {
    setdatsInMonth(datesInMonth(selectDate));
  }, [selectDate]);

  useEffect(() => {
    const BooleanArray = DatesInMonth.filter((_, i) => i > 34).map((day) => day.currentMonth);
    const result = BooleanArray.reduce((res, cur) => res || cur, false);
    setisWeek6(result);
  }, [DatesInMonth]);

  const handlePaste = (selectDate, targetDate) => {
    dispatch(
      Creators.copyMealPlannerByDay({
        _id: playerId,
        selectDate: getYearMonthDay(selectDate),
        targetDate: getYearMonthDay(targetDate)
      })
    );
  };

  const handleClear = (date) => {
    const payload = {
      _id: playerId,
      plannerStartDate: getYearMonthDay(date),
      dayType: true
    };

    dispatch(Creators.clearPlayerMealPlan(payload));
  };

  const handleWeekClear = (date) => {
    const payload = {
      _id: playerId,
      plannerStartDate: getYearMonthDay(date.startDate),
      plannerEndDate: getYearMonthDay(date.endDate),
      dayType: false
    };

    dispatch(Creators.clearPlayerMealPlan(payload));
  };

  const handleWeekPaste = (value) => {
    const payload = {
      _id: playerId,
      ...value,
      ...selectedWeek
    };

    dispatch(Creators.copyMealPlannerByWeek(payload));
  };

  const handleAddMeal = (value) => {
    const payload = {
      playerId,
      sessionDate,
      ...value
    };
    dispatch(Creators.addPlayerMealPlan(payload));
  };

  const handleCreateMeal = (value) => {
    const mealPlan = { ...value, playerId, plannerDate: getYearMonthDay(sessionDate), assignedType: 'Individual' };
    dispatch(CreateMeal.createMealPlan({ mealPlan }));
  };

  const handleBack = () => {
    history.goBack();
    dispatch(NutritionCreators.setActiveTab({ tab: 'Planner' }));
  };

  const _generateDay = (date, sessions, currentMonth) => {
    return (
      <Day
        key={date}
        isActive={sessions.length}
        currentMonth={!currentMonth}
        onMouseOver={() => setselectId(date)}
        onMouseLeave={() => setselectId('')}
        isToday={getMonthDayYear(date) === getMonthDayYear(getToday())}
      >
        <DayHeader>
          <p>{getDayOfMonth(date)}</p>
          {getMonthDayYear(selectId) === getMonthDayYear(date) && currentMonth ? (
            <Options
              isOptions={sessions.length}
              date={date}
              copyDate={copyDate}
              onPaste={handlePaste}
              onCopy={() => setCopyDate(date)}
              onAdd={() => {
                setShowAddMeal(true);
                setsessionDate(date);
              }}
              onClear={handleClear}
            />
          ) : (
            ''
          )}
        </DayHeader>
        <SessionsContainer
          onClick={() => {
            if (sessions.length) {
              setShowMealDetails(true);
              setMealDetails(sessions);
              setsessionDate(date);
            }
          }}
        >
          {[...sessions].map((item) => (
            <Meal key={item._id}>{item.title}</Meal>
          ))}
        </SessionsContainer>
      </Day>
    );
  };

  return (
    <>
      {loading && <LoadingComponent />}
      {/* Toasts */}
      <Toasts />

      {/* modals */}
      {showAddMeal && (
        <AddMealPlanModal
          onCancel={() => setShowAddMeal(false)}
          onAdd={(value) => handleAddMeal(value)}
          onCreate={() => setshowCreateMeal(true)}
          selectDate={sessionDate}
        />
      )}
      {showAllergy && <FoodAllergyModal onCancel={() => setshowAllergy(false)} food={plannerDetails?.foodAllergy} />}
      {showMealDetails && (
        <MealPlanDetailsModal
          filteredMealPlan={MealDetails}
          onClose={() => setShowMealDetails(false)}
          onEdit={(meal) => {
            setshowCreateMeal(true);
            setmealToEdit(meal);
          }}
          selectedDate={sessionDate}
        />
      )}
      {showCreateMeal && (
        <CreateCustomMealPlanModal
          mealPlan={mealToEdit}
          onCancel={() => {
            setshowCreateMeal(false);
            setmealToEdit(null);
          }}
          onSubmit={handleCreateMeal}
        />
      )}

      {/* header */}
      <Header>
        <FlexContainer style={{ padding: '10px 0' }}>
          <BackIcon style={{ cursor: 'pointer', width: '60px' }} onClick={handleBack} />
          <HeaderTxt>{`${shortenText(plannerDetails?.fullName, 20, '...')} Meal Planner`}</HeaderTxt>
        </FlexContainer>
        <FlexContainer>
          <P>Meal Preference:</P>
          <Preference>{plannerDetails?.mealPreference}</Preference>
          <AlergyButton onClick={() => setshowAllergy(true)}>Food Allergy</AlergyButton>
        </FlexContainer>
      </Header>
      <Divider />

      {/* Calander view */}
      <div style={{ padding: '0 3rem' }}>
        <MonthIndicator selectDate={selectDate} setSelectDate={setSelectDate} />
        <WeekdayIndicator />

        {/* week 1 */}
        <WeekRow>
          <FlexWraper style={{ width: '100%' }}>
            <WeekOptions
              text='W1'
              selectedWeek={selectedWeek}
              startDate={DatesInMonth[0]?.date}
              endDate={DatesInMonth[6]?.date}
              onCopy={(value) => setselectedWeek(value)}
              onPaste={handleWeekPaste}
              onClear={handleWeekClear}
            />
            {DatesInMonth.filter((_, i) => i <= 6).map((day) => {
              const { currentMonth, date } = day;
              const sessions = getDaySessions(date);

              return _generateDay(date, sessions, currentMonth);
            })}
          </FlexWraper>
        </WeekRow>

        {/* week 2 */}
        <WeekRow>
          <FlexWraper style={{ width: '100%' }}>
            <WeekOptions
              text='W2'
              selectedWeek={selectedWeek}
              startDate={DatesInMonth[7]?.date}
              endDate={DatesInMonth[13]?.date}
              onCopy={(value) => setselectedWeek(value)}
              onPaste={handleWeekPaste}
              onClear={handleWeekClear}
            />
            {DatesInMonth.filter((_, i) => i >= 7 && i <= 13).map((day) => {
              const { currentMonth, date } = day;
              const sessions = getDaySessions(date);

              return _generateDay(date, sessions, currentMonth);
            })}
          </FlexWraper>
        </WeekRow>

        {/* week 3 */}
        <WeekRow>
          <FlexWraper style={{ width: '100%' }}>
            <WeekOptions
              text='W3'
              selectedWeek={selectedWeek}
              startDate={DatesInMonth[14]?.date}
              endDate={DatesInMonth[20]?.date}
              onCopy={(value) => setselectedWeek(value)}
              onPaste={handleWeekPaste}
              onClear={handleWeekClear}
            />
            {DatesInMonth.filter((_, i) => i >= 14 && i <= 20).map((day) => {
              const { currentMonth, date } = day;
              const sessions = getDaySessions(date);

              return _generateDay(date, sessions, currentMonth);
            })}
          </FlexWraper>
        </WeekRow>

        {/* week 4 */}
        <WeekRow>
          <FlexWraper style={{ width: '100%' }}>
            <WeekOptions
              text='W4'
              selectedWeek={selectedWeek}
              startDate={DatesInMonth[21]?.date}
              endDate={DatesInMonth[27]?.date}
              onCopy={(value) => setselectedWeek(value)}
              onPaste={handleWeekPaste}
              onClear={handleWeekClear}
            />
            {DatesInMonth.filter((_, i) => i >= 21 && i <= 27).map((day) => {
              const { currentMonth, date } = day;
              const sessions = getDaySessions(date);

              return _generateDay(date, sessions, currentMonth);
            })}
          </FlexWraper>
        </WeekRow>

        {/* week 5 */}
        <WeekRow>
          <FlexWraper style={{ width: '100%' }}>
            <WeekOptions
              text='W5'
              selectedWeek={selectedWeek}
              startDate={DatesInMonth[28]?.date}
              endDate={DatesInMonth[34]?.date}
              onCopy={(value) => setselectedWeek(value)}
              onPaste={handleWeekPaste}
              onClear={handleWeekClear}
            />
            {DatesInMonth.filter((_, i) => i >= 28 && i <= 34).map((day) => {
              const { currentMonth, date } = day;
              const sessions = getDaySessions(date);

              return _generateDay(date, sessions, currentMonth);
            })}
          </FlexWraper>
        </WeekRow>

        {/* week 6 */}
        {isWeek6 && (
          <WeekRow>
            <FlexWraper style={{ width: '100%' }}>
              <WeekOptions
                text='W6'
                selectedWeek={selectedWeek}
                startDate={DatesInMonth[35]?.date}
                endDate={DatesInMonth[41]?.date}
                onCopy={(value) => setselectedWeek(value)}
                onPaste={handleWeekPaste}
                onClear={handleWeekClear}
              />
              {DatesInMonth.filter((_, i) => i > 34).map((day) => {
                const { currentMonth, date } = day;
                const sessions = getDaySessions(date);

                return _generateDay(date, sessions, currentMonth);
              })}
            </FlexWraper>
          </WeekRow>
        )}
      </div>
    </>
  );
};

export default MealPlannerDetailView;
