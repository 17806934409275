import React from 'react';

// styles
import { FlexContainer } from '../../../globalShared/CommonStyles';
import { shortenText } from '../../../services/utility';
import Avatar from '../UserAvatarComponent';
import { CommentContainer, PlayerTitle, PlayerComment, PostedAt } from './CommentComponent.styles';

const CommentInfoComponent = ({ userPic, playerName, comment, time }) => (
  <CommentContainer>
    <div style={{ width: '50px', marginRight: '10px' }}>
      <Avatar url={userPic} />
    </div>

    <div style={{ width: '100%' }}>
      <FlexContainer style={{ justifyContent: 'space-between' }}>
        <PlayerTitle>{shortenText(playerName, 10, '...')}</PlayerTitle>
        <PostedAt>{time}</PostedAt>
      </FlexContainer>
      <PlayerComment className='word'>{comment}</PlayerComment>
    </div>
  </CommentContainer>
);
export default CommentInfoComponent;
