import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  loading: false,
  error: null,
  playerList: [],
  reload: false,
  success: false,
  page: 1,
  pageCount: 1,
  playerDetails: {},
  totalCount: 0,
  maxPage: 0
};

const getCoachPlayerListStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getCoachPlayerListSuccess = (state = INITIAL_STATE, action) => {
  const { playerList, maxPage, totalCount } = action.payload;

  return {
    ...state,
    loading: false,
    reload: false,
    playerList,
    maxPage,
    totalCount
  };
};

const SearchCoachPlayersSuccess = (state = INITIAL_STATE, action) => {
  const { playerList, maxPage } = action.payload;
  return {
    ...state,
    loading: false,
    reload: false,
    playerList,
    maxPage
  };
};

const getCoachPlayerListFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const setPage = (state = INITIAL_STATE, action) => {
  const { page } = action.payload;
  return {
    ...state,
    page
  };
};

const setPlayerDetails = (state = INITIAL_STATE, action) => {
  const { playerDetails } = action.payload;
  return {
    ...state,
    playerDetails
  };
};

const resetError = (state = INITIAL_STATE) => ({ ...state, error: null });

const HANDLERS = {
  [Types.GET_COACH_PLAYER_LIST_START]: getCoachPlayerListStart,
  [Types.GET_COACH_PLAYER_LIST_SUCCESS]: getCoachPlayerListSuccess,
  [Types.GET_COACH_PLAYER_LIST_FAILURE]: getCoachPlayerListFailure,

  [Types.SEARCH_COACH_PLAYERS_SUCCESS]: SearchCoachPlayersSuccess,

  [Types.SET_PLAYER_DETAILS]: setPlayerDetails,
  [Types.RESET_ERROR]: resetError,

  [Types.SET_PAGE]: setPage
};

export const coachPlayersReducer = createReducer(INITIAL_STATE, HANDLERS);
