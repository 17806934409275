/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable no-shadow */
/*
 * saga.js file for videoplayer view
 * Author:
 * 1. Manikanta
 * 2. Vijay
 * @copyright of Panorbit Services LLP.
 */

import { put } from 'redux-saga/effects';
import { Creators } from './action';

import { WebService } from '../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../services/axios/endPoints';

export function* getPlayerVideoDetailsSaga({ payload }) {
  const { id, page = '1' } = payload;
  try {
    yield put(Creators.getPlayerVideoDetailsStart());
    const fullURL = yield `${apiEndPoints.videoDetail}`;

    const response = yield WebService.get(fullURL, { params: { _id: id, page } });

    if (response.status >= 200 && response.status < 300) {
      const data = response.data.data[0];
      yield put(Creators.getPlayerVideoDetailsSuccess({ videoDetails: data[0] }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getPlayerVideoDetailsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getPlayerVideoDetailsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getPlayerVideoDetailsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getPlayerVideoDetailsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getPlayerVideoDetailsFailure(payload));
    }
  }
}

export function* getPlayerVideoCommentsListSaga({ payload }) {
  const { id, page = '1' } = payload;
  try {
    yield put(Creators.getPlayerVideoCommentsListStart());
    const fullURL = yield `${apiEndPoints.commentList}`;

    const response = yield WebService.get(fullURL, { params: { _id: id, page, count: 100 } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      const payload = {
        commentsList: data[0],
        maxPage: data[1].page_count,
        commentsCount: data[2].commentCount
      };
      yield put(Creators.getPlayerVideoCommentsListSuccess(payload));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getPlayerVideoCommentsListFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getPlayerVideoCommentsListFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getPlayerVideoCommentsListFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getPlayerVideoCommentsListFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getPlayerVideoCommentsListFailure(payload));
    }
  }
}

export function* addPlayerVideoCommentSaga({ payload }) {
  const { content, videoId } = payload;

  try {
    yield put(Creators.addPlayerVideoCommentStart());

    const dataToApi = new FormData();
    dataToApi.append('content', content);
    dataToApi.append('referenceId', videoId);

    const fullURL = yield `${apiEndPoints.addComment}`;

    const response = yield WebService({
      method: 'POST',
      url: fullURL,
      data: dataToApi
    });

    if (response.status >= 200 && response.status < 300) {
      const { message } = response.data;

      yield put(Creators.addPlayerVideoCommentSuccess({ message }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.addPlayerVideoCommentFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.addPlayerVideoCommentFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.addPlayerVideoCommentFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.addPlayerVideoCommentFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.addPlayerVideoCommentFailure(payload));
    }
  }
}

export function* editPlayerVideoSaga({ payload }) {
  const { data } = payload;
  // console.log(data);
  try {
    yield put(Creators.editPlayerVideoStart());
    const fullURL = yield `${apiEndPoints.addVideo}`;
    // let METHOD = 'PUT';

    const dataToApi = new FormData();

    dataToApi.append('_id', data._id);
    dataToApi.append('title', data.title);
    dataToApi.append('collectionId', data.collectionId);
    dataToApi.append('collectionName', data.collectionName);
    if (data.videoHidden) {
      dataToApi.append('access', 'Private');
    } else {
      dataToApi.append('access', 'Public');
    }

    const response = yield WebService({
      method: 'PUT',
      url: fullURL,
      data: dataToApi
    });

    if (response.status >= 200 && response.status < 300) {
      const { message } = response.data;
      yield put(Creators.editPlayerVideoSuccess({ message }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.editPlayerVideoFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.editPlayerVideoFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.editPlayerVideoFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.editPlayerVideoFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.editPlayerVideoFailure(payload));
    }
  }
}

export function* deletePlayerVideoSaga({ payload }) {
  try {
    const { _id } = payload;

    yield put(Creators.deletePlayerVideoStart());
    const fullURL = yield `${apiEndPoints.addVideo}`;

    const response = yield WebService({
      method: 'DELETE',
      url: fullURL,
      data: { _id }
    });

    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.deletePlayerVideoSuccess());
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.deletePlayerVideoFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.deletePlayerVideoFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.deletePlayerVideoFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.deletePlayerVideoFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.deletePlayerVideoFailure(payload));
    }
  }
}

export function* removeFromCollectionSaga({ payload }) {
  const { collectionId, _id } = payload;

  try {
    yield put(Creators.removeFromCollectionStart());

    const dataToApi = new FormData();
    dataToApi.append('collectionId', collectionId);
    dataToApi.append('_id', _id);

    const fullURL = yield `${apiEndPoints.removeFromCollection}`;

    const response = yield WebService({
      method: 'POST',
      url: fullURL,
      data: dataToApi
    });

    if (response.status >= 200 && response.status < 300) {
      const { message } = response.data;
      yield put(Creators.removeFromCollectionSuccess({ message }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.removeFromCollectionFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.removeFromCollectionFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.removeFromCollectionFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.removeFromCollectionFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.removeFromCollectionFailure(payload));
    }
  }
}
