import moment from 'moment';
import { getToday } from '../../../services/utility/moment';

export const getPastDays = ({ type, days }) => {
  const daysAgo = [];
  const date = getToday();
  let noOfDays = 1;
  if (type === 'month') {
    noOfDays = 6;
  } else {
    noOfDays = days;
  }

  for (let day = 0; day < noOfDays; day += 1) {
    let actualDays = '';

    if (type === 'month') {
      actualDays = moment(date).subtract(day, 'months');
    } else {
      actualDays = moment(date).subtract(day, 'days');
    }

    const formattedDay = actualDays.date();
    const MMDDYY = actualDays.format('YYYY-MM-DD');
    const MM = actualDays.format('MMM');
    daysAgo.push({ formattedDay, MMDDYY, MM });
  }

  return daysAgo;
};
