/* eslint-disable no-console */
/* eslint-disable no-shadow */
/*
 * saga.js file for createpassword view
 * Author:
 * 1. manikanta
 *
 * @copyright of Panorbit Services LLP.
 */

import { put } from 'redux-saga/effects';
import { Creators } from './action';

import { WebService } from '../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../services/axios/endPoints';
import { cookie } from '../../../../services/cookies/cookieServices';
import { setAccessTokenToHeader } from '../../../../services/axios/helper';
import { setUserDataToLocal } from '../../../../services/cookies/helper';

export function* createPasswordSaga({ payload }) {
  try {
    const { password } = payload;
    yield put(Creators.createPasswordStart());
    const fullURL = yield `${apiEndPoints.resetPassword}`;

    const response = yield WebService({
      method: 'POST',
      url: fullURL,
      data: { password }
    });

    if (response.status >= 200 && response.status < 300) {
      const { message, token } = response.data;

      // set auth token to axios headers
      yield setAccessTokenToHeader(token);

      const cookiedata = cookie.get('userDetails');
      yield setUserDataToLocal({ ...cookiedata, accessToken: token, passwordChanged: true });

      yield put(Creators.createPasswordSuccess(message));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.createPasswordFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.createPasswordFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.createPasswordFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.createPasswordFailure(payload));
      }
    }

    // for custom error
    payload = {
      error: error.message
    };

    yield put(Creators.createPasswordFailure(payload));
  }
}
