/* eslint-disable no-unused-vars */
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';

import rootReducer from './reducers';
import watchAuth from './sagas';

const composeEnhancers = compose;

const sagaMiddleware = createSagaMiddleware();

const reduxLogger = createLogger({
  diff: true
});

const config = {
  // Only These actions will be synced in other tabs
  whitelist: [
    // 'LOGIN_START',
    // 'LOGIN',
    // 'LOGIN_SUCCESS',
    'LOGOUT',
    'LOGOUT_SUCCESS'
  ]
};

/* eslint-disable no-underscore-dangle */
export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk, sagaMiddleware, createStateSyncMiddleware(config)))
);

initMessageListener(store);
sagaMiddleware.run(watchAuth);
