/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import styled, { css } from 'styled-components';
import { textColors, accentColor, fontWeight, fontSize, backgroundColor } from '../../constants';

export const Container = styled.button`
  padding: 1.5rem 2rem;
  min-width: 125px;
  max-height: 50px;

  border: none;
  border-radius: 0.5rem;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: ${fontSize.mediumDefault};
  color: ${textColors.textDark};

  font-weight: ${fontWeight.bold};

  ${(props) =>
    props.bgType === 'primary' &&
    css`
      background: transparent linear-gradient(108deg, ${accentColor.secondaryLite} 0%, ${accentColor.primaryDark} 100%)
        0% 0% no-repeat;
    `};

  ${(props) =>
    props.bgType === 'dark' &&
    css`
      background: ${backgroundColor.lightBlue};
      color: ${textColors.textWhite};
    `};

  ${(props) =>
    props.bgType === 'danger' &&
    css`
      background: ${backgroundColor.danger};
      color: ${textColors.textWhite};
    `};

  ${(props) =>
    props.margin &&
    css`
      margin: ${props.margin};
    `};

  &:focus {
    outline: none;
  }

  & > svg {
    margin: 0 1rem 0 0;
  }
`;
