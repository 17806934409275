import styled from 'styled-components';

import { fontSize, fontWeight } from '../../../shared/constants';

export const Container = styled.div``;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const HeaderTxt = styled.h1`
  font-size: ${fontSize.titleVerySmall};
  font-weight: ${fontWeight.bold};
  margin: 0 1rem;
  text-transform: capitalize;
`;

export const SearchContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const VideoTitle = styled.div`
  font-size: ${fontSize.mediumDefault};
  font-weight: ${fontWeight.bold};

  margin: 0 1rem;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

export const SelectButtonContainer = styled.div`
  margin-right: 1rem;
  width: 25px;
  cursor: pointer;
`;

export const Radio = styled.div`
  height: 25px;
  width: 25px;
  border: 2px solid #3e3457;
  border-radius: 12px;

  &:hover {
    background: #2f2843;
  }
`;

export const EmptyContainer = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  color: #ffffff1c;
`;
