/* eslint-disable no-confusing-arrow */
import styled from 'styled-components';
import {
  backgroundColor,
  borderColor,
  fontSize,
  fontWeight,
  textColors,
  gradient,
  accentColor
} from '../../../shared/constants';

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ManageContainer = styled.div`
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  justify-content: flex-end;

  background-color: ${backgroundColor.popupBg};
  z-index: 9999;
`;

export const ManageContent = styled.div`
  height: 100%;
  width: 841px;

  padding: 2.4rem;

  background-color: ${backgroundColor.popup};
  border-radius: 6px 0px 0px 6px;

  overflow-y: auto;
  overflow-x: hidden;
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 863px;
  top: 2rem;
  z-index: 999;
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  & > svg {
    cursor: pointer;
  }
`;

export const Title = styled.h1`
  font-size: ${fontSize.titleVerySmall};
`;

export const CreateContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;

  & > svg {
    stroke-width: bold;
    width: 12px;
    height: 12px;
  }

  & > p {
    font-size: 16px;
    color: ${accentColor.primary};
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  position: relative;

  padding-bottom: ${(props) => (props.expand ? '1rem' : '1.6rem')};
  border-bottom: 1px solid ${borderColor.light};
  margin-bottom: 1rem;
`;

export const CollectionFilterContainer = styled.div`
  width: 805px;
  display: flex;
  gap: 6px;
  flex-wrap: ${(props) => (props.expand ? 'wrap' : 'nowrap')};

  height: ${(props) => (props.expand ? 'auto' : '73px')};
  ${'' /* overflow: hidden; */}
`;

export const ExpandContainer = styled.span`
  position: absolute;
  right: 0;

  display: flex;
  align-items: center;
  gap: 6px;

  width: 90px;
  height: 73px;

  background: #15151c;
  cursor: pointer;
  z-index: 9999;

  box-shadow: -35px -5px 40px 5px #15151c, -35px 5px 40px 5px #15151c, 0px 15px 10px 5px #15151c,
    40px -5px 30px 15px #15151c, 40px 5px 30px 15px #15151c;
  ${'' /* box-shadow: -5px -5px 30px 5px red, 5px 5px 30px 5px blue; */}

  & > p {
    font-size: 14px;
    font-weight: ${fontWeight.medium};
    text-transform: capitalize;
    color: #8792ac;
  }
`;

export const Collapse = styled.span`
  transform: rotate(180deg);
`;

export const CollectionFilter = styled.span`
  padding: 16px 19px;
  min-width: 201px;
  height: 73px;

  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 1rem;
  margin-right: 1rem;

  border-radius: 6px;
  background: ${(props) => (props.isActive ? `${gradient.primary}` : `${backgroundColor.darkOpaq}`)};
  cursor: pointer;

  & > span > p,
  & > span,
  & > p {
    color: ${(props) => (props.isActive ? `${textColors.textDark}` : `${textColors.light}`)};
  }

  & > span > svg > g > path {
    fill: ${(props) => (props.isActive ? '#2E3034' : `${textColors.light}`)};
  }
`;

export const CollectionItemsContainer = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;

  & > .dots {
    margin-left: auto;
    margin-top: 3px;
    width: 1rem;
  }
`;

export const CollectionName = styled.p`
  font-size: 18px;
  font-weight: ${fontWeight.medium};
  text-transform: capitalize;
`;

export const CollectionMenu = styled.div`
  position: absolute;
  right: 0px;
  top: 20px;
  height: 80px;
  width: 156px;

  background: #262130;
  box-shadow: 0px 5px 18px #0000002e;
  border-radius: 6px;
  z-index: 9999;

  display: ${(props) => (props.showCollection ? 'flex' : 'none')};
  align-items: unset;
  flex-direction: column;
  justify-content: center;

  & > div {
    padding: 1rem 2rem;
    font-size: 14px;
    cursor: pointer;
  }
`;

export const VideoCount = styled.span`
  font-size: 12px;
  font-weight: ${fontWeight.medium};

  display: flex;
  align-items: center;

  & > p {
    display: inline-block;
    margin-left: 3px;

    font-size: 12px;
    font-weight: ${fontWeight.medium};
  }
`;

// Collection Videos Table

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0 1rem;

  & > td,
  th {
    white-space: nowrap;
    vertical-align: middle;
    border: 0;
  }
`;

export const TableRow = styled.tr`
  background: transparent;
  margin-bottom: 1rem;

  border-bottom: 1px solid ${borderColor.light};

  &:last-child {
    border-bottom: none;
  }
`;

export const VideoTitle = styled.div`
  font-size: ${fontSize.mediumDefault};
  font-weight: ${fontWeight.bold};
  margin: 0 1rem;
`;

export const VideoType = styled.div`
  font-size: ${fontSize.smallDefault};
  font-weight: ${fontWeight.light};

  margin: 0.5rem 1rem;
`;

export const NoVideos = styled.div`
  height: 50vh;

  display: flex;
  justify-content: center;
  align-items: center;

  & > p {
    font-size: 40px;
    font-weight: ${fontWeight.bold};
    text-transform: capitalize;
    color: ${textColors.textLight};
  }
`;

export const VideoOptions = styled.td`
  position: relative;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const VideoMenu = styled.span`
  position: absolute;
  right: 32px;
  top: 50px;
  height: 82px;
  width: 204px;

  background: #262130;
  box-shadow: 0px 5px 18px #0000002e;
  border-radius: 6px;
  z-index: 9999;

  display: ${(props) => (props.showVideo ? 'flex' : 'none')};
  align-items: unset;
  flex-direction: column;
  justify-content: center;

  & > div {
    padding: 1rem 2rem;
    font-size: 14px;
    cursor: pointer;
  }
`;
