/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Creators } from './store';
import { Creators as LoginCreators } from '../../auth/LoginView/store';
import Toast, { Toasts } from '../../../services/toast';

// components
import { ButtonComponent, LoadingComponent, TooltipComponnet } from '../../../globalShared';
import { Dropdown, FormInput, RadioGroup, TextArea } from '../../../shared/components/FormComponents';

// styles
import { Container } from './ProfileModalStyles';
import { DetailsCnt, DetailsRow, Header, H2, Image, P, H3, Flex } from './PersonalStyles';

import {
  Layout,
  Width50,
  FlexContainer,
  DashedContainer,
  InputContainer,
  FileInput,
  PictureContainer,
  ButtonContainer,
  ErrorMsg
} from '../../PersonalDetails/PersonalDetails.styles';

// assets
import dummyImage from '../../../shared/assets/user.jpg';
import { ReactComponent as UploadIcon } from '../../../shared/assets/upload.svg';
import { ReactComponent as DeleteIcon } from '../../../shared/assets/delete.svg';

// maps
import { genderMap, levelMap, trainingAgeMap } from '../../../services/utility/maps';
import { shortenText } from '../../../services/utility';

const { Row, Column } = Layout;

const levels = [
  { value: 'level1', label: 'Under 14' },
  { value: 'level2', label: 'Under 17' },
  { value: 'level3', label: 'Senior' }
];

const trainingAge = [
  { value: 'level1', label: 'Lesser Than 1 Year' },
  { value: 'level2', label: 'Between 1 And 3 Years' },
  { value: 'level3', label: 'Greater Than 3 Years' }
];

const feet = [3, 4, 5, 6, 7];
const inches = ['0', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
const numbersRegx = /^[0-9]+$/;

const Personal = () => {
  const dispatch = useDispatch();

  const [editMode, setEditMode] = useState(false);
  const [state, setstate] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    age: '',
    gender: '',
    weight: '',
    heightFeet: '',
    heightInch: '',
    level: '',
    about: '',
    goals: '',
    profilePicture: '',
    trainingAge: ''
  });
  const [errors, seterror] = useState({});

  const personalDetails = useSelector((state) => state.login.personalDetail);
  const loading = useSelector((state) => state.playerProfile.loading);
  const error = useSelector((state) => state.playerProfile.error);
  const success = useSelector((state) => state.playerProfile.success);

  // for profilePicture
  const [isImage, setIsImage] = useState(false);
  const [profilePictureObj, setProfilePicObj] = useState('');

  const modifyUrlLocal = (url, isFileImage, imgFile) => {
    if (url !== null) {
      setstate({ ...state, profilePicture: null });
      setIsImage(isFileImage);
    }
    setIsImage(isFileImage);
    setstate({ ...state, profilePicture: url });
  };

  const handleFileChange = (event) => {
    const imgFile = event.target.files[0];
    if (!imgFile) return;

    setProfilePicObj(imgFile); //  set  file object to state

    // creates a local url for uploaded image to preview
    // eslint-disable-next-line no-undef
    const URL = window.URL || window.webkitURL;
    const url = URL.createObjectURL(imgFile);

    modifyUrlLocal(url, true, imgFile);
  };

  // form validatins and onChange error handlers
  const validateControl = (control) => {
    const errs = errors;
    const formFields = state;
    // other controls
    if (formFields[control] !== '') {
      errors[control] = '';
    } else {
      errors[control] = 'Required';
    }
    seterror({ ...errs });
  };

  const validateForm = () => {
    const errors = {};
    let IsValid = true;

    if (!state.firstName) {
      IsValid = false;
      errors.firstName = 'Required';
    }

    if (!state.lastName) {
      IsValid = false;
      errors.lastName = 'Required';
    }

    if (!state.email) {
      IsValid = false;
      errors.email = 'Required';
    } else if (!state.email.match(/.+@.+\../)) {
      IsValid = false;
      errors.email = 'please enter correct email';
    }

    if (!state.phoneNumber) {
      IsValid = false;
      errors.phoneNumber = 'Required';
    }

    if (!state.age) {
      IsValid = false;
      errors.age = 'Required';
    }

    if (!state.gender) {
      IsValid = false;
      errors.gender = 'Required';
    }

    if (!state.weight) {
      IsValid = false;
      errors.weight = 'Required';
    }

    if (!state.heightFeet) {
      IsValid = false;
      errors.heightFeet = 'Select Feet';
    }

    if (!state.heightInch) {
      IsValid = false;
      errors.heightInch = 'Select Inch';
    }

    if (!state.level.value) {
      IsValid = false;
      errors.level = 'Select Level';
    }
    if (!state.trainingAge.value) {
      IsValid = false;
      errors.trainingAge = 'Required';
    }

    seterror(errors);
    return IsValid;
  };

  useEffect(() => {
    if (personalDetails) {
      modifyUrlLocal(personalDetails.profilePicture, !!personalDetails.profilePicture, null);
      const height = personalDetails.height.split('.');
      setstate({
        ...state,
        ...personalDetails,
        gender: {
          value: personalDetails.gender,
          label: personalDetails.gender
        },
        heightFeet: height[0],
        heightInch: height[1],
        level: {
          value: personalDetails.level,
          label: levelMap[personalDetails.level]
        },
        trainingAge: {
          value: personalDetails.trainingAge,
          label: trainingAgeMap[personalDetails.trainingAge]
        }
      });
    }
  }, [personalDetails]);

  useEffect(() => {
    if (success) {
      setEditMode(false);
      dispatch(LoginCreators.getUserData());
      Toast.success('success', 2, () => dispatch(Creators.resetSuccess()));
      dispatch(Creators.resetSuccess());
    }
  }, [success]);

  // form handlers
  const handleFirstName = (value) => {
    setstate({ ...state, firstName: value });
    validateControl('firstName');
  };

  const handleLastName = (value) => {
    setstate({ ...state, lastName: value });
    validateControl('lastName');
  };

  const handlePhonenumber = (value) => {
    if ((value.match(numbersRegx) || value.length === 0) && value.length <= 10) {
      setstate({ ...state, phoneNumber: value });
    }
    validateControl('phoneNumber');
  };

  const handleAge = (value) => {
    if (value.match(numbersRegx) || value.length === 0) {
      setstate({ ...state, age: value });
    }
    validateControl('age');
  };

  const handleWeight = (value) => {
    if (value.match(numbersRegx) || value.length === 0) {
      setstate({ ...state, weight: value });
    }
    validateControl('weight');
  };

  const handleGender = (value) => {
    setstate({ ...state, gender: value });
    validateControl('gender');
  };

  const handledelete = () => {
    modifyUrlLocal(null);
    setProfilePicObj('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const picDelete = !state.profilePicture && !profilePictureObj;

    if (validateForm()) {
      const payload = { ...state, profilePictureObj, picDelete };
      dispatch(Creators.updatePlayerPersonalDetails(payload));
    }
  };

  return (
    <>
      {loading && <LoadingComponent />}
      <Toasts />
      {!editMode ? (
        <Container style={{ margin: '2rem 0' }}>
          <Header>
            <H2>Details</H2>
            <TooltipComponnet type='edit2' title='Edit' onClick={() => setEditMode(true)} />
          </Header>
          <Flex>
            <Image style={{ backgroundImage: `url(${personalDetails?.profilePicture || dummyImage})` }} />
            <DetailsCnt after>
              <DetailsRow>
                <P light>Name</P>
                <H3>{shortenText(`${personalDetails?.firstName} ${personalDetails?.lastName}`, 10, '...')}</H3>
              </DetailsRow>
              <DetailsRow>
                <P light>Email</P>
                <H3 style={{ overflowWrap: 'anywhere' }}>{`${personalDetails?.email}`}</H3>
              </DetailsRow>
              <DetailsRow>
                <P light>Phone</P>
                <H3>{`${personalDetails?.phoneNumber}`}</H3>
              </DetailsRow>
              <DetailsRow>
                <P light>Age</P>
                <H3>{`${personalDetails?.age}`}</H3>
              </DetailsRow>
              <DetailsRow>
                <P light>Gender</P>
                <H3>{genderMap[personalDetails?.gender]}</H3>
              </DetailsRow>
              <DetailsRow>
                <P light>Weight</P>
                <H3>{`${personalDetails?.weight}`}</H3>
              </DetailsRow>
              <DetailsRow>
                <P light>Height</P>
                <H3>{`${personalDetails?.height}`}</H3>
              </DetailsRow>
            </DetailsCnt>

            <DetailsCnt>
              <DetailsRow>
                <P light>Level</P>
                <H3>{levelMap[personalDetails?.level]}</H3>
              </DetailsRow>
              <DetailsRow>
                <P light>Training Age</P>
                <H3>{trainingAgeMap[personalDetails?.trainingAge]}</H3>
              </DetailsRow>
              <DetailsRow>
                <P light>About</P>
                <H3>{personalDetails?.about}</H3>
              </DetailsRow>
              <DetailsRow>
                <P light>Goals</P>
                <H3>{personalDetails?.goals}</H3>
              </DetailsRow>
            </DetailsCnt>
          </Flex>
        </Container>
      ) : (
        <Container style={{ margin: '2rem 0' }}>
          <form onSubmit={handleSubmit}>
            <Header>
              <H2>Details</H2>
              <ButtonComponent buttonText='Save' buttonType='submit' />
            </Header>

            <Row>
              <Column>
                <DashedContainer isImage={isImage}>
                  {!isImage ? (
                    // eslint-disable-next-line jsx-a11y/label-has-associated-control
                    <label htmlFor='profileImage'>
                      <FileInput
                        type='file'
                        name='profileImage'
                        data-type='file'
                        id='profileImage'
                        accept='image/x-png,image/gif,image/jpeg'
                        onChange={handleFileChange}
                      />

                      <InputContainer>
                        <UploadIcon />
                        Upload your photo (optional)
                      </InputContainer>
                    </label>
                  ) : (
                    <>
                      <PictureContainer style={{ backgroundImage: `url(${state.profilePicture})` }} />
                      <ButtonContainer onClick={handledelete}>
                        <DeleteIcon />
                      </ButtonContainer>
                    </>
                  )}
                </DashedContainer>
              </Column>
            </Row>

            <Row>
              <Column>
                <FormInput
                  placeholder='First Name'
                  label='First Name'
                  value={state.firstName}
                  setValue={handleFirstName}
                />
                <ErrorMsg>{errors.firstName}</ErrorMsg>
              </Column>
              <Column>
                <FormInput placeholder='Last Name' label='Last Name' value={state.lastName} setValue={handleLastName} />
                <ErrorMsg>{errors?.lastName}</ErrorMsg>
              </Column>
              <Column>
                <FormInput placeholder='Email' disabled={true} label='Email' value={state.email} />
              </Column>
            </Row>

            <Row>
              <Column>
                <FormInput
                  placeholder='Phone Number'
                  label='Phone Number'
                  value={state.phoneNumber}
                  setValue={handlePhonenumber}
                />
                <ErrorMsg>{errors.phoneNumber}</ErrorMsg>
              </Column>
              <Column>
                <FlexContainer>
                  <Width50>
                    <FormInput placeholder='25' label='Age' value={state.age} setValue={handleAge} />
                    <ErrorMsg>{errors?.age}</ErrorMsg>
                  </Width50>

                  <Width50>
                    <RadioGroup
                      label='Gender'
                      list={[
                        { value: 'M', label: 'M' },
                        { value: 'F', label: 'F' }
                      ]}
                      selected={state.gender}
                      setValue={handleGender}
                    />
                    <ErrorMsg style={{ marginTop: '0' }}>{errors.gender?.message}</ErrorMsg>
                  </Width50>
                </FlexContainer>
              </Column>
              <Column>
                <FlexContainer>
                  <Width50>
                    <FormInput placeholder='Kgs' label='Weight' value={state.weight} setValue={handleWeight} />
                    <ErrorMsg>{errors.weight?.message}</ErrorMsg>
                  </Width50>
                  <Width50>
                    <FlexContainer>
                      <Width50>
                        <Dropdown
                          list={feet}
                          label='Height'
                          placeholder='ft'
                          name='feet'
                          value={state.heightFeet}
                          setValue={(value) => setstate({ ...state, heightFeet: value })}
                        />
                      </Width50>

                      <Width50>
                        <Dropdown
                          list={inches}
                          label=' &nbsp;'
                          placeholder='in'
                          name='inches'
                          value={state.heightInch}
                          setValue={(value) => setstate({ ...state, heightInch: value })}
                          maxHeight='180'
                        />
                      </Width50>
                    </FlexContainer>
                    <ErrorMsg style={{ marginTop: '0' }}>{errors.feet || errors.inches}</ErrorMsg>
                  </Width50>
                </FlexContainer>
              </Column>
            </Row>

            <Row>
              <Column>
                <Dropdown
                  list={levels}
                  label='Level'
                  placeholder='Select'
                  name='level'
                  value={state.level}
                  setValue={(value) => setstate({ ...state, level: value })}
                  objType={true}
                />
                <ErrorMsg>{errors.level}</ErrorMsg>

                <Dropdown
                  list={trainingAge}
                  label='Training Age'
                  placeholder='Number of Years Trained'
                  name='trainingAge'
                  value={state.trainingAge}
                  setValue={(value) => setstate({ ...state, trainingAge: value })}
                  objType={true}
                />
                <ErrorMsg>{errors.trainingAge}</ErrorMsg>
              </Column>
              <Column>
                <TextArea
                  placeholder='Enter a description about yourself here...'
                  label='About ( optional )'
                  value={state.about}
                  setValue={(value) => setstate({ ...state, about: value })}
                />
              </Column>
              <Column>
                <TextArea
                  placeholder='Enter your goals here...'
                  label='Goals ( optional )'
                  value={state.goals}
                  setValue={(value) => setstate({ ...state, goals: value })}
                />
              </Column>
            </Row>
          </form>
        </Container>
      )}
    </>
  );
};

export default Personal;
