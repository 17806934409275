import React from 'react';
import styled from 'styled-components';
import { accentColor, fontSize } from '../../constants';

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  padding: 1rem 0;

  & > button {
    background: transparent;
    border: none;

    font-size: ${fontSize.mediumDefault};
    color: ${accentColor.primary};

    cursor: pointer;

    &:focus {
      outline: 0px;
    }
  }
`;

const AddMore = ({ onClick, children }) => (
  <Button>
    <button type='button' onClick={onClick}>
      {children}
    </button>
  </Button>
);

export default AddMore;
