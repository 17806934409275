import React from 'react';
import { ReactComponent as Logo } from '../../assets/logo-header.svg';

import { Container } from './LogoHeader.styles';

const LogoHeadercomponent = () => (
  <Container>
    <Logo />
  </Container>
);

export default LogoHeadercomponent;
