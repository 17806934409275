/* eslint-disable no-underscore-dangle */
/* eslint-disable operator-linebreak */
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Creators } from './store';
import { Creators as CompareCreators } from '../CompareView/store';
import { Creators as CommonCreators } from '../../store';
// components
import {
  PlayerVideoPlayer,
  CommentsComponent,
  CompareButtonComponent,
  DeletePopupComponent,
  LoadingComponent
} from '../../../globalShared';

// styles
import { Divider, FlexContainer } from '../../../globalShared/CommonStyles';
import {
  Header,
  HeaderTxt,
  MainContainer,
  PlayerContainer,
  CommentsContainer,
  Player,
  VideoMenu
} from './VideoplayerStyles';

// icons
import { ReactComponent as BackIcon } from '../../../shared/assets/back-header.svg';
import { ReactComponent as MenuIcon } from '../../../shared/assets/3dot_menu.svg';

import EditVideoModal from './EditVideoModal';
import AddToAnotherCollectionModal from '../SkillsView/AddToAnotherCollectionModal';
import DeletePopup from '../../../shared/components/DeletePopupComponent';
import { shortenText } from '../../../services/utility';

const PlayerView = () => {
  const history = useHistory();
  const { videoId } = useParams();
  const dispatch = useDispatch();

  // const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [showVideoOptionId, setShowVideoOptionId] = useState(null);
  const [showAddtoCollection, setshowAddtoCollection] = useState(false);
  const [removeFromcollection, setremoveFromcollection] = useState(false);
  const [showEditVideoModal, setShowEditVideoModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  // state values
  const videoDetails = useSelector((state) => state.playerVideoPlayer.videoDetails);
  const commentsList = useSelector((state) => state.playerVideoPlayer.commentsList);
  const compareVideos = useSelector((state) => state.compare.videos);
  const reloadComments = useSelector((state) => state.main.reloadComments);
  const reloadVideoDetails = useSelector((state) => state.playerVideoPlayer.reloadVideoDetails);
  const maxPage = useSelector((state) => state.playerVideoPlayer.maxPage);
  const commentsCount = useSelector((state) => state.playerVideoPlayer.commentsCount);
  const loading = useSelector((state) => state.playerVideoPlayer.loading);

  const [state, setState] = useState({
    URL: '',
    videoTitle: ''
  });
  const [page, setPage] = useState(1);

  const handleAddToCompare = () => {
    if (compareVideos.length < 2) {
      const videos = [...compareVideos, videoDetails];
      dispatch(CompareCreators.addVideoToCompare({ videos }));
    }
  };

  const handleRemovefromCompare = () => {
    const { _id } = videoDetails;
    const videos = compareVideos.filter((item) => item._id !== _id);
    dispatch(CompareCreators.addVideoToCompare({ videos }));
  };

  const handleShowVideoOption = (videoId) => {
    setShowVideoOptionId(videoId);

    if (showVideoOptionId) {
      setShowVideoOptionId(null);
    } else {
      setShowVideoOptionId(videoId);
    }
  };

  const handleSubmitComment = (content, id) => dispatch(CommonCreators.addComment({ content, id }));

  const deleteVideo = (id) => {
    setDeleteItem(true);
    setDeleteItemId(id);
  };

  const popUpCancelFunc = () => {
    setDeleteItemId(null);
    setDeleteItem(false);
  };

  const popUpDeteleFunc = (_id) => {
    dispatch(Creators.deletePlayerVideo({ _id }));
    // console.log(_id);
    popUpCancelFunc();
    history.goBack();
  };

  useEffect(() => {
    if (videoDetails) {
      setState({ ...state, URL: videoDetails.videoUrl, videoTitle: videoDetails.title });
    }
  }, [videoDetails]);

  // const handleShowMoreOptions = () => {
  //   setShowMoreOptions(!showMoreOptions);
  // };

  const handleRemovecollection = () => {
    const { collectionId, _id } = videoDetails;
    dispatch(Creators.removeFromCollection({ collectionId, _id }));
    setremoveFromcollection(false);
  };

  // effect hooks
  useEffect(() => {
    dispatch(Creators.getPlayerVideoDetails({ id: videoId }));
  }, []);

  useEffect(() => {
    if (reloadVideoDetails) {
      dispatch(Creators.getPlayerVideoDetails({ id: videoId }));
    }
  }, [reloadVideoDetails]);

  useEffect(() => {
    dispatch(Creators.getPlayerVideoCommentsList({ id: videoId, page }));
  }, [page]);

  // useEffect(() => {
  //   dispatch(Creators.getPlayerVideoCommentsList({ id: videoId }));
  // }, []);

  useEffect(() => {
    if (reloadComments) {
      dispatch(Creators.getPlayerVideoCommentsList({ id: videoId }));
    }
  }, [reloadComments]);

  const handleUpdateComments = () => {
    if (maxPage > page) {
      setPage(page + 1);
    }
  };

  // resets state on unmount
  useEffect(
    () => () => {
      dispatch(Creators.resetComments());
    },
    []
  );

  // state destructuring
  const { URL, videoTitle } = state;

  return (
    <>
      {loading && <LoadingComponent />}
      {showAddtoCollection && (
        <AddToAnotherCollectionModal currentVideoId={videoId} onCancel={() => setshowAddtoCollection(false)} />
      )}
      {deleteItem && deleteItemId ? (
        <DeletePopupComponent
          title='Delete Video'
          primaryText='Are You Sure, You want to delete the video permanently?'
          secondaryText=''
          closePopupFunc={popUpCancelFunc}
          deleteFunc={popUpDeteleFunc}
          itemID={deleteItemId}
        />
      ) : (
        ''
      )}
      {removeFromcollection && (
        <DeletePopup
          MainBtnText='Remove'
          title='Remove From Collectiom'
          primaryText='Are you sure you permanently want to remove from collection?'
          closePopupFunc={() => setremoveFromcollection(false)}
          deleteFunc={handleRemovecollection}
        />
      )}

      <Header>
        <FlexContainer style={{ padding: '10px 0', maxWidth: '80%' }}>
          <BackIcon style={{ cursor: 'pointer', width: '60px' }} onClick={() => history.goBack()} />
          <HeaderTxt className='clip'>{shortenText(videoTitle, 50, '...')}</HeaderTxt>
        </FlexContainer>
        <CompareButtonComponent list={compareVideos} to='/player/skills/comparevideo' />
      </Header>

      <Divider />
      <MainContainer>
        <PlayerContainer>
          <Player>
            <PlayerVideoPlayer
              URL={URL}
              videoDetails={videoDetails}
              addToCompareAction={handleAddToCompare}
              removeFromCompare={handleRemovefromCompare}
              compareVideos={compareVideos}
            />
            <MenuIcon style={{ cursor: 'pointer' }} onClick={() => handleShowVideoOption(videoId)} />
            <VideoMenu showVideoMenu={showVideoOptionId === videoId}>
              <div
                role='presentation'
                onClick={() => {
                  // dispatch(Creators.setShowEditVideoModal({ showEditVideo: true }));
                  setShowEditVideoModal(true);
                  setShowVideoOptionId(null);
                }}
              >
                Edit Video
              </div>
              <div
                role='presentation'
                onClick={() => {
                  // dispatch(Creators.setShowDeleteVideoModal({ showDeleteVideo: true }));
                  deleteVideo(videoId);
                  setShowVideoOptionId(null);
                }}
              >
                Delete Video
              </div>
              <div
                role='presentation'
                onClick={() => {
                  setShowVideoOptionId(null);
                  setremoveFromcollection(true);
                }}
              >
                Remove From This Collection
              </div>
              <div
                role='presentation'
                onClick={() => {
                  setShowVideoOptionId(null);
                  setshowAddtoCollection(true);
                }}
              >
                Add to another collection
              </div>
            </VideoMenu>
          </Player>
        </PlayerContainer>
        <CommentsContainer>
          <CommentsComponent
            commentsList={commentsList}
            id={videoId}
            submitComment={handleSubmitComment}
            count={commentsCount}
            updateComments={handleUpdateComments}
          />
        </CommentsContainer>
      </MainContainer>
      {showEditVideoModal && <EditVideoModal videoId={videoId} onCancel={() => setShowEditVideoModal(false)} />}
    </>
  );
};

export default PlayerView;
