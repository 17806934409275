/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  Wrapper,
  Column,
  AttachmentContainer,
  SubHeading,
  FileInput,
  FlexContainer,
  AttachButton,
  DeleteButton,
  AddMoreButton,
  AddMoreButtonContainer,
  FileName,
  Container
} from './AttachmentsList.styles';

import { TextareaComponent } from '../../../globalShared';

import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';
import { ReactComponent as AttachIcon } from '../../assets/attach-files.svg';
import { localString } from '../../../services/locallization/localStrings';

const Attach = (props) => {
  const { name, ipRef } = props;
  const [fileName, setFileName] = useState(null);
  const handleChange = ({ target }) => {
    const file = target.files[0];
    const FileNameLocal = file.name;
    if (FileNameLocal) setFileName(FileNameLocal);
  };

  return (
    <>
      <input style={{ display: ' none' }} type='file' name={name} id={name} ref={ipRef} onChange={handleChange} />
      <FileName>{fileName}</FileName>
      <label htmlFor={name}>
        <AttachButton>
          <AttachIcon />
        </AttachButton>
      </label>
    </>
  );
};

const Files = (props) => {
  const { control, register, fieldName, errors } = props;

  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName
  });

  return (
    <Wrapper>
      {fields.map((item, index) => (
        <Column key={item.id}>
          <AttachmentContainer>
            <FlexContainer style={{ margin: '0 0' }}>
              <SubHeading>{index + 1}</SubHeading>

              <Container>
                <Attach name={`${fieldName}[${index}].file`} ipRef={register({ required: false })} />

                <DeleteButton type='button' onClick={() => remove(index)}>
                  <DeleteIcon />
                </DeleteButton>
              </Container>
            </FlexContainer>

            <TextareaComponent
              ipName={`${fieldName}[${index}].description`}
              placeholderText='Enter details here…'
              ipRef={register({ required: true })}
              height='100px'
            />
          </AttachmentContainer>
        </Column>
      ))}
      <Column>
        <AddMoreButtonContainer>
          <AddMoreButton
            type='button'
            onClick={() => {
              append({ file: '' });
            }}
          >
            {`+ ${string.addMore}`}
          </AddMoreButton>
        </AddMoreButtonContainer>
      </Column>
    </Wrapper>
  );
};

export default Files;
