/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
import styled, { css } from 'styled-components';
import { textColors, backgroundColor } from '../../constants';

export const TooltipWrapper = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
  margin: 0 1rem;

  justify-content: center;
  align-items: center;

  & > svg {
    height: 20px;
  }

  &:hover > div {
    visibility: visible;
  }
`;

export const Tooltip = styled.div`
  visibility: hidden;
  padding: 0.75rem 1.5rem;

  background-color: #41375a;
  color: ${textColors.textWhite};
  font-size: 12px;
  white-space: nowrap;
  z-index: 9;

  text-align: center;
  border-radius: 6px;

  position: absolute;
  ${(props) =>
    props.position === 'top' &&
    css`
      bottom: 130%;
    `};

  ${(props) =>
    props.position === 'bottom' &&
    css`
      top: 160%;
    `};

  ${(props) =>
    props.position === 'right' &&
    css`
      left: 130%;
    `};

  ${(props) =>
    props.position === 'left' &&
    css`
      right: 130%;
    `}

  transition: visibility 0.3s ease-in-out;
  cursor: default;

  &::after {
    content: '';
    position: absolute;

    ${(props) =>
      props.position === 'top' &&
      css`
        bottom: -7px;
        left: 50%;
      `};

    ${(props) =>
      props.position === 'bottom' &&
      css`
        top: 0;
        left: 50%;
      `};

    ${(props) =>
      props.position === 'right' &&
      css`
        top: 50%;
        left: 0;
      `};

    ${(props) =>
      props.position === 'left' &&
      css`
        top: 50%;
        right: -8px;
      `}

    width: 8px;
    height: 8px;

    transform: translate(-50%, -50%) rotate(45deg);
    background-color: ${backgroundColor.lightBlue};
  }
`;
