import { createGlobalStyle } from 'styled-components';

import { accentColor, textColors, backgroundColor } from '../constants';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 62.5%;
    font-family: 'Lato', sans-serif;
    color:${textColors.textWhite};
    scrollbar-color: ${backgroundColor.dark} transparent;
    scrollbar-width: none;
  }

  p {
    user-select: none;
  }

  a{
    text-decoration: none;
  }

  #root{
    background: linear-gradient(124deg, ${accentColor.midNightBlue} 0%, ${accentColor.dark} 100%);
    overflow-y: auto;
    height:100vh;

  }
  .Toastify{
    font-size: 1.5rem !important;
  }

  .break{
    word-break: break-all;
  }

  .word{
    word-break: break-word;
  }

  .clip{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  }


::-webkit-scrollbar-track{
border-radius: 10px;
background-color: transparent;

};

::-webkit-scrollbar{
  width: 5px;
  padding: 5px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb{
  border-radius: 10px;
  background-color: ${backgroundColor.dark};
}



`;

export default GlobalStyle;

// input:-webkit-autofill{
//   background-color:${backgroundColor.darkOpaq}  !important;
//   -webkit-box-shadow: 0 0 0 30px  ${backgroundColor.dark} inset !important;
//   -webkit-text-fill-color: ${textColors.textWhite} !important;

//   &:after{
//     -webkit-box-shadow: 0 0 0 30px  ${backgroundColor.dark}   !important;
//   }
// }
