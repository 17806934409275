/* eslint-disable max-len */
import styled, { keyframes } from 'styled-components';
import { Divider } from '../../../globalShared/CommonStyles';
import { accentColor, fontSize, fontWeight, textColors, backgroundColor } from '../../../shared/constants';
import {
  Container,
  CardContainer,
  Title,
  CloseButton
} from '../../../shared/components/EditUserDetailsComponent/EditUserDetails.styles';

export const PlayerTitle = styled.h1`
  font-size: ${fontSize.mediumDefault};
  font-weight: ${fontWeight.medium};

  margin: 0 1rem;
  max-width: 240px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

export const ArrayString = styled.p`
  color: #cbd1df;
  width: ${(props) => props.width};
  font-size: ${fontSize.mediumDefault};
  font-weight: 500;

  margin-right: 0.5rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

export const OpenPlanner = styled.p`
  font-size: ${fontSize.title};
  color: ${accentColor.primary};

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;

  & > svg {
    margin-left: 0.7rem;
    transform: rotate(-90deg);
    height: 1rem;
    width: 1.5rem;
  }

  & > svg > g > path {
    stroke: ${accentColor.primary};
  }
`;

export const Menu = styled.div`
  position: absolute;
  right: 5px;
  top: 50px;
  height: 50px;
  width: 170px;
  background: #262130;
  box-shadow: 0px 5px 18px #0000002e;
  border-radius: 6px;
  z-index: 99;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  align-items: unset;
  flex-direction: column;
  justify-content: center;
`;

export const Option = styled.p`
  padding: 1rem 2rem;
  font-size: 14px;
  cursor: ${(props) => (props.isActive ? 'pointer ' : 'disabled')};
  color: ${(props) => (props.isActive ? `${textColors.textWhite}` : `${textColors.textLight}`)};
`;

// add meal modal styles
export const ModalContainer = styled(Container)``;

export const ModalContent = styled(CardContainer)`
  min-height: 480px;
  min-width: 450px;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ModalTitle = styled(Title)``;

export const CloseBtn = styled(CloseButton)``;

export const Label = styled.p`
  color: ${textColors.textLight};
  font-size: 1.3rem;
  margin: 3rem 0rem 2rem 0rem;
`;

export const ExistingMealPlan = styled.div`
  display: flex;
  flex-flow: column;
  max-height: 500px;
  overflow-y: auto;
`;

export const InputContainer = styled.div`
  margin: 0rem 0 1rem 0;
`;

export const Input = styled.input`
  opacity: 0;
  label {
    position: relative;
    cursor: pointer;
    padding-left: 25px;
    font-size: ${fontSize.mediumDefault};

    &: before {
      content: '';
      position: absolute;
      top: 0;
      left: -10px;
      height: 20px;
      width: 20px;
      border: 1px solid #3f335b;
      border-radius: 50%;
    }
    &: after {
      content: '';
      position: absolute;
      top: 0;
      left: -10px;
      height: 20px;
      width: 20px;
      border: 1px solid #3f335b;
      border-radius: 50%;
    }
  }
  &:checked + label {
    &: before {
      content: '';
      width: 20px;
      heigth: 20px;
      background: ${backgroundColor.lightBlue};
    }
  }
`;

export const CustomDivider = styled(Divider)`
  margin-top: 1.5rem;
  margin-bottom: 0rem;
`;

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0 1rem 0;
`;

export const DashedLine = styled.div`
  width: 100%;
  border-bottom: 1px dashed grey;
  position: relative;
  margin: 2rem 0 3rem 0;
`;

export const DashedLineTxt = styled.span`
  position: absolute;
  background-color: ${backgroundColor.popup};
  color: ${textColors.textLight};
  font-size: ${fontSize.smallDefault};
  padding: 0rem 1rem;
  top: -8px;
  left: 180px;
`;

export const forward = keyframes`
  from{ margin-left: 0px;}
  to{ margin-left: 20px;}
`;

export const CustomisedBtn = styled.button`
  width: 100%;
  padding: 1rem 0;
  font-size: ${fontSize.mediumDefault};
  background-color: ${backgroundColor.popup};
  border: 1px solid grey;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    color: ${accentColor.primary};
    border-color: ${accentColor.primary};
    & > svg {
      animation: ${forward} forwards;
    }
  }
  & > svg {
    margin-left: 0.7rem;
    transform: rotate(-90deg);
    height: 1rem;
    width: 1.5rem;
  }

  & > svg > g > path {
    stroke: ${accentColor.primary};
  }
`;
