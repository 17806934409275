/* eslint-disable no-console */
/* eslint-disable no-shadow */
import { put } from 'redux-saga/effects';
import { Creators } from './action';

import { WebService } from '../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../services/axios/endPoints';

export function* getCoachMealPlanListSaga({ payload }) {
  const { page, count } = payload;

  try {
    yield put(Creators.getCoachMealPlanListStart());
    const fullURL = yield `${apiEndPoints.mealPlanList}`;

    const response = yield WebService.get(fullURL, { params: { page, count } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      yield put(Creators.getCoachMealPlanListSuccess({ mealPlanList: data[0], totalItems: data[2].totalCount }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getCoachMealPlanListFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getCoachMealPlanListFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getCoachMealPlanListFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getCoachMealPlanListFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getCoachMealPlanListFailure(payload));
    }
  }
}

export function* createMealPlanSaga({ payload }) {
  const { mealPlan } = payload;

  try {
    yield put(Creators.createMealPlanStart());
    let fullURL = yield `${apiEndPoints.createMealPlan}`;
    let METHOD = 'POST';

    if (mealPlan._id) {
      METHOD = 'PUT';
      fullURL = yield `${apiEndPoints.mealPlanEdit}`;
    }

    const response = yield WebService({
      method: METHOD,
      url: fullURL,
      data: mealPlan
    });

    if (response.status >= 200 && response.status < 300) {
      const { message } = response.data;
      yield put(Creators.createMealPlanSuccess({ message }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.createMealPlanFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.createMealPlanFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.createMealPlanFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.createMealPlanFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.createMealPlanFailure(payload));
    }
  }
}

export function* deleteCoachMealPlanSaga({ payload }) {
  const { id } = payload;

  try {
    yield put(Creators.deleteCoachMealPlanStart());
    const fullURL = yield `${apiEndPoints.deleteMealPlan}`;

    const response = yield WebService({
      method: 'DELETE',
      url: fullURL,
      data: { _id: id }
    });

    if (response.status >= 200 && response.status < 300) {
      const { message } = response.data;
      yield put(Creators.deleteCoachMealPlanSuccess({ message }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.deleteCoachMealPlanFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.deleteCoachMealPlanFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.deleteCoachMealPlanFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.deleteCoachMealPlanFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.deleteCoachMealPlanFailure(payload));
    }
  }
}

export function* searchCoachMealPlanSaga({ payload }) {
  const { searchKey, page, count } = payload;
  try {
    yield put(Creators.searchCoachMealPlanStart());
    const fullURL = yield `${apiEndPoints.mealPlanSearch}`;
    const response = yield WebService.get(fullURL, { params: { searchKey, page, count } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      // console.log(data);
      yield put(Creators.searchCoachMealPlanSuccess({ mealPlanList: data[0], totalItems: data[2].totalCount }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log(error);

    let payload;
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.searchCoachMealPlanFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.searchCoachMealPlanFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.searchCoachMealPlanFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update.Try again later'
          };
          yield put(Creators.searchCoachMealPlanFailure(payload));
      }
    } else {
      payload = {
        error: error.message
      };

      yield put(Creators.searchCoachMealPlanFailure(payload));
    }
  }
}

export function* duplicateCoachMealPlanSaga({ payload }) {
  const { id } = payload;

  try {
    yield put(Creators.duplicateCoachMealPlanStart());
    const fullURL = yield `${apiEndPoints.duplicateMealPlan}`;

    const response = yield WebService({
      method: 'POST',
      url: fullURL,
      data: { _id: id }
    });

    if (response.status >= 200 && response.status < 300) {
      const { message } = response.data;
      yield put(Creators.duplicateCoachMealPlanSuccess({ message }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.duplicateCoachMealPlanFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.duplicateCoachMealPlanFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.duplicateCoachMealPlanFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.duplicateCoachMealPlanFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.duplicateCoachMealPlanFailure(payload));
    }
  }
}
