/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import InfiniteScroll from 'react-infinite-scroll-component';

import { Container, TextLite, Circle, Session, SessionContainer } from './RecentStyles';

import { EmptyContentText } from '../../../../globalShared/CommonStyles';
import { MasonryLayout, RecentCardComponent } from '../../../../globalShared';

import { timeSince } from '../../../../services/utility';

import CompletedIcon from '../../../../shared/assets/completed.svg';
import ProgressIcon from '../../../../shared/assets/work_inprogress.svg';

import { Creators } from './store';
import SessionDetails from './SessionDetails/SessionDetails';

const RecentView = (props) => {
  const { playerId } = props;
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const { isNext, fetchTime } = useSelector((state) => state.coachStrengthFeed);
  const strengthFeedList = useSelector((state) => state.coachStrengthFeed.strengthFeed);
  const loading = useSelector((state) => state.coachStrengthFeed.loading);
  const { showModal } = useSelector((state) => state.coachStrengthFeed);

  useEffect(() => {
    const payload = { page, count: 10, playerId };
    if (page > 1 && fetchTime) {
      payload.fetchTime = fetchTime;
    }
    dispatch(Creators.getCoachStrengthFeed(payload));
  }, [page]);

  const handleScroll = () => {
    setPage(page + 1);
  };

  const handleSeeDetail = (session) => {
    dispatch(Creators.selectedSession({ session }));
    dispatch(Creators.setShowDetailsModal({ show: true }));
  };

  // rests on unmount
  useEffect(
    () => () => {
      dispatch(Creators.resetCoachStrengthFeed());
    },
    []
  );

  return (
    <>
      <Container id='strengthscrollableDiv'>
        {strengthFeedList.length ? (
          <>
            <InfiniteScroll
              dataLength={strengthFeedList.length}
              next={handleScroll}
              scrollableTarget='strengthscrollableDiv'
              inverse={false}
              hasMore={isNext}
            >
              <MasonryLayout gap={20}>
                {strengthFeedList.map((feed) => {
                  const { userName, profilePicture, plannerId, sessionDate } = feed[0];
                  return (
                    <RecentCardComponent
                      profilePicture={profilePicture}
                      userName={userName}
                      date={sessionDate}
                      key={`${plannerId}_${Math.random()}`}
                      onClick={() => handleSeeDetail(feed)}
                    >
                      {feed.map((session) => {
                        const { progressStatus, sessionName, updatedTime } = session;

                        return (
                          <SessionContainer key={`${updatedTime}_${Math.random()}`}>
                            <>
                              {progressStatus === 'In Progress' ? (
                                <Circle src={ProgressIcon} alt='In Progress' />
                              ) : (
                                <Circle src={CompletedIcon} alt='Completed' />
                              )}
                            </>

                            <div>
                              <Session>
                                {`${progressStatus}`}
                                <span>{`${sessionName}`}</span>
                              </Session>
                              <TextLite>{timeSince(updatedTime)}</TextLite>
                            </div>
                          </SessionContainer>
                        );
                      })}
                    </RecentCardComponent>
                  );
                })}
              </MasonryLayout>
            </InfiniteScroll>
          </>
        ) : (
          <EmptyContentText>{!loading && 'No Data Available'}</EmptyContentText>
        )}
      </Container>
      {showModal && <SessionDetails />}
    </>
  );
};

export default RecentView;
