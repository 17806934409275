/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable operator-linebreak */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from './store';
import { Creators as CompareCreators } from '../../CompareView/store';

// components
import { SearchBoxComponent, ButtonComponent, AvatarComponent } from '../../../globalShared';

// styles
import { Divider, TableStyles, FlexContainer } from '../../../globalShared/CommonStyles';
import {
  Container,
  HeaderTxt,
  Header,
  SearchContainer,
  VideoTitle,
  SelectButtonContainer,
  Radio,
  EmptyContainer
} from './searchVideoStyles';

// icons
import { ReactComponent as BackIcon } from '../../../shared/assets/back-header.svg';
import { ReactComponent as SelectIcon } from '../../../shared/assets/Radio_selected.svg';
import { Timeformater } from '../../../services/utility';

const { Table, Th, Tr, Td } = TableStyles;
/**
 *
 * component
 */
const searchVideosView = ({ history }) => {
  // hook state values
  const [searchValue, setSearchValue] = useState('');

  // redux store values
  const videoList = useSelector((state) => state.teamVideos.videosList);
  const page = useSelector((state) => state.teamVideos.pageNo);
  const compareVideos = useSelector((state) => state.compare.videos);

  // dispatch
  const dispatch = useDispatch();

  const onSearchChange = (value) => {
    setSearchValue(value);
  };

  // checks wether the video is selected or not
  const isSelecetd = (id) => compareVideos.some((video) => video._id === id);

  useEffect(() => {
    if (searchValue) {
      dispatch(Creators.getTeamVideoSearchList({ searchValue, page }));
    } else {
      dispatch(Creators.searchReset());
    }
  }, [searchValue, page]);

  const handleRemovefromCompare = (id) => {
    const videos = compareVideos.filter((item) => item._id !== id);
    dispatch(CompareCreators.addVideoToCompare({ videos }));
  };

  const handleAddToCompare = (video) => {
    if (compareVideos.some((item) => item._id === video._id)) {
      handleRemovefromCompare(video._id);
    } else if (compareVideos.length < 2) {
      const videos = [...compareVideos, video];

      dispatch(CompareCreators.addVideoToCompare({ videos }));
    }
  };

  const handleClick = () => {
    if (compareVideos.length) {
      history.push('comparevideo');
    }
  };

  return (
    <>
      <Header>
        <FlexContainer style={{ padding: '10px 0' }}>
          <BackIcon style={{ cursor: 'pointer' }} onClick={() => history.goBack()} />
          <HeaderTxt>Compare Videos</HeaderTxt>
        </FlexContainer>
        <ButtonComponent buttonText='Compare' isButtonAction={true} buttonClick={handleClick} margin={false} />
      </Header>

      <Divider />
      <SearchContainer>
        <SearchBoxComponent placeHolder='Search' onSearchChange={onSearchChange} />
      </SearchContainer>

      <Container>
        {videoList.length ? (
          <Table>
            <thead>
              <tr>
                <Th style={{ textAlign: 'center' }}>Title</Th>
                <Th>uploaded by</Th>
                <Th>Upload date</Th>
                <Th>Duration</Th>
                <Th>Comments</Th>
              </tr>
            </thead>
            <tbody>
              {videoList.length &&
                videoList.map((video) => {
                  const { _id, firstName, lastName, thumbUrl, title, uploadDateWeb, commentCount, duration } = video;

                  return (
                    <Tr key={_id} onClick={() => handleAddToCompare(video)} style={{ cursor: 'pointer' }}>
                      <Td style={{ maxWidth: '300px' }}>
                        <FlexContainer>
                          <SelectButtonContainer>{isSelecetd(_id) ? <SelectIcon /> : <Radio />}</SelectButtonContainer>
                          <AvatarComponent url={thumbUrl} />
                          <VideoTitle>{title}</VideoTitle>
                        </FlexContainer>
                      </Td>
                      <Td>{`${firstName} ${lastName}`}</Td>
                      <Td>{uploadDateWeb}</Td>
                      <Td>{Timeformater(duration)}</Td>
                      <Td>{commentCount}</Td>
                    </Tr>
                  );
                })}
            </tbody>
          </Table>
        ) : (
          <EmptyContainer>Search for Videos or Players</EmptyContainer>
        )}
      </Container>
    </>
  );
};

export default searchVideosView;
