/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { dayFormater, shortenText } from '../../../services/utility';
import { ModalContainer, ContentContainer, CloseButton, Header } from './MoodDetailsModalStyles';

// icons
import { ReactComponent as CloseIcon } from '../../../shared/assets/Close.svg';

import { TabsComponenet } from '../../../globalShared';

import Avatar from '../../../shared/components/UserAvatarComponent';
import { Time } from './MindsetStyles';
import { PlayerTitle } from '../../../shared/components/CommentInfoComponent/CommentComponent.styles';
import MoodDetails from './MoodDetailsModal';
import MeditationDetails from './MeditationDetailsModal';
import SleepDetails from './SleepDetailsModal';

const MindSetModal = ({ data, onCancel }) => {
  const tabs = data[0].map((tab) => {
    if (tab === 'mood') return 'mood Journal';
    if (tab === 'meditation') return 'meditate';
    return tab;
  });

  const mindSetObjs = data.filter((_, k) => k > 0);
  const { fullName, profilePicture, createdDate } = data[1];
  const sleep = mindSetObjs.find(({ mindsetType }) => mindsetType === 'sleep');
  const mood = mindSetObjs.find(({ mindsetType }) => mindsetType === 'mood');
  const meditation = mindSetObjs.find(({ mindsetType }) => mindsetType === 'meditation');

  // eslint-disable-next-line no-unused-vars
  const [tab, setTab] = useState(tabs[0]);

  const handleClose = () => {
    onCancel();
  };

  const renderTab = {
    mood: <MoodDetails feedDetails={mood} />,
    meditate: <MeditationDetails data={meditation} />,
    sleep: <SleepDetails data={sleep} />
  };

  return (
    <div>
      <ModalContainer>
        <ContentContainer>
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
          <Header>
            <Avatar url={profilePicture} />
            <div>
              <PlayerTitle>{shortenText(fullName, 10, '...')}</PlayerTitle>
              <Time>{dayFormater(createdDate)}</Time>
            </div>
          </Header>
          <TabsComponenet getactiveTab={(tab) => setTab(tab)}>
            {tabs.map((tab) => (
              <div key={tab} label={tab}>
                {tab === 'mood Journal' ? renderTab.mood : renderTab[tab]}
              </div>
            ))}
          </TabsComponenet>
        </ContentContainer>
      </ModalContainer>
    </div>
  );
};

export default MindSetModal;
