/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Toast, { Toasts } from '../../../services/toast';

import { Creators } from './store';
import { Creators as LoginCreators } from '../../auth/LoginView/store';
// import { Creators as FileCreators } from '../../PlayerDetailsView/store';

import { Container } from './ProfileModalStyles';
import { A, Details, DetailsRow, Header, H2, P, H3, Table, FileContainer } from './PersonalStyles';
import {
  Layout,
  Width50,
  TogglerContainer,
  Divider,
  FlexContainer,
  ErrorMsg,
  Timer,
  Dash
} from '../../ProfessionalDetailsView/ProfessionalDetails.styles';

import { TooltipComponnet, ButtonComponent, ToggleSwitch, LoadingComponent } from '../../../globalShared';

import { ReactComponent as Arrow } from '../../../shared/assets/back.svg';
import { Dropdown, FormInput, HistoryAttachments } from '../../../shared/components/FormComponents';
import { typeOfFood } from '../../../shared/constants/lists';
import { shortenText } from '../../../services/utility';

const { Row } = Layout;

const timeRegex = /(0[0-9]|1[012]):([0-5][0-9])/;

const defaultValues = {
  injury: [],
  health: [],

  mealPreference: '',
  foodAllergy: '',

  practiceStartTime: '',
  practiceEndTime: '',

  practiceStartTimeMeredian: 'AM',
  practiceEndTimeMeredian: 'PM',

  trainingStartTime: '',
  trainingEndTime: '',

  trainingStartTimeMeredian: 'AM',
  trainingEndTimeMeredian: 'PM'
};

// eslint-disable-next-line arrow-body-style
const Professional = () => {
  const dispatch = useDispatch();

  const [editMode, setEditMode] = useState(false);
  const [addInjury, setAddInjury] = useState(false);
  const [addHealth, setAddHealth] = useState(false);
  const [state, setstate] = useState(defaultValues);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (addHealth) {
      setAddInjury(false);
    }
  }, [addHealth]);

  // STATE values
  const professionalDetails = useSelector((state) => state.login.professionalDetail);
  const loading = useSelector((state) => state.playerProfile.loading);
  const success = useSelector((state) => state.playerProfile.success);

  const reload = useSelector((state) => state.playerProfile.reloadProfessionalData);
  const fileUploadErr = useSelector((state) => state.userMeta.error);

  useEffect(() => {
    if (success) {
      setEditMode(false);
      dispatch(LoginCreators.getUserData());
      Toast.success('success', 2, () => dispatch(Creators.resetSuccess()));
      dispatch(Creators.resetSuccess());
    }
  }, [success]);

  useEffect(() => {
    if (reload) {
      dispatch(LoginCreators.getUserData());
    }
  }, [reload]);

  useEffect(() => {
    if (fileUploadErr) {
      Toast.error('Upload Failed', 2);
    }
  }, [fileUploadErr]);

  useEffect(() => {
    if (professionalDetails) {
      const { practiceStartTime, practiceEndTime, trainingStartTime, trainingEndTime, foodAllergy } =
        professionalDetails;

      const splitFunc = (string, i, key) => {
        const arry = string.split(key);
        return arry[i];
      };

      setstate({
        ...state,
        ...professionalDetails,
        practiceStartTime: splitFunc(practiceStartTime, 0, '-'),
        practiceEndTime: splitFunc(practiceEndTime, 0, '-'),

        trainingStartTime: splitFunc(trainingStartTime, 0, '-'),
        trainingEndTime: splitFunc(trainingEndTime, 0, '-'),

        practiceStartTimeMeredian: splitFunc(practiceStartTime, 1, '-'),
        practiceEndTimeMeredian: splitFunc(practiceEndTime, 1, '-'),

        trainingStartTimeMeredian: splitFunc(trainingStartTime, 1, '-'),
        trainingEndTimeMeredian: splitFunc(trainingEndTime, 1, '-'),
        foodAllergy: foodAllergy || ''
      });
    }
  }, [professionalDetails]);

  // form validatins and onChange error handlers
  function checkFormFilled(props) {
    const { description } = props;

    const isdescription = !!`${description}`.trim().length;
    // const ismediaUrl = !!mediaUrl;
    // const isfile = !!file;

    return isdescription; /* && (ismediaUrl || isfile) */
  }

  const validateControl = (control) => {
    const errs = errors;
    const formFields = state;

    // practiceStartTime
    if (control === 'practiceStartTime' && formFields[control] !== '') {
      if (!formFields.practiceStartTime.match(/(0[0-9]|1[012]):([0-5][0-9])/)) {
        errors.practiceStartTime = 'Required format HH:MM';
      }
    }

    // practiceEndTime
    if (control === 'practiceEndTime' && formFields[control] !== '') {
      if (!formFields.practiceEndTime.match(/(0[0-9]|1[012]):([0-5][0-9])/)) {
        errors.practiceEndTime = 'Required format HH:MM';
      }
    }

    // trainingStartTime
    if (control === 'trainingStartTime' && formFields[control] !== '') {
      if (!formFields.trainingStartTime.match(/(0[0-9]|1[012]):([0-5][0-9])/)) {
        errors.trainingStartTime = 'Required format HH:MM';
      }
    }

    // trainingEndTime
    if (control === 'trainingEndTime' && formFields[control] !== '') {
      if (!formFields.trainingEndTime.match(/(0[0-9]|1[012]):([0-5][0-9])/)) {
        errors.trainingEndTime = 'Required format HH:MM';
      }
    }

    // other controls
    if (formFields[control] !== '') {
      errors[control] = '';
    } else {
      errors[control] = 'Required';
    }
    setErrors({ ...errs });
  };

  const validateForm = () => {
    const errors = {};
    let IsValid = true;

    const healthFilled = state.health.map((el) => checkFormFilled({ ...el })).reduce((res, cur) => res && cur, true);
    const injuryFilled = state.injury.map((el) => checkFormFilled({ ...el })).reduce((res, cur) => res && cur, true);

    if (!healthFilled) {
      IsValid = false;
      errors.health = 'All fields are required';
    }

    if (!injuryFilled) {
      IsValid = false;
      errors.injury = 'All fields are required';
    }

    if (!state.mealPreference) {
      IsValid = false;
      errors.mealPreference = 'Required';
    }

    if (!state.practiceStartTime) {
      IsValid = false;
      errors.practiceStartTime = 'Required';
    } else if (!state.practiceStartTime.match(timeRegex)) {
      IsValid = false;
      errors.practiceStartTime = 'Required format HH:MM';
    }

    if (!state.practiceEndTime) {
      IsValid = false;
      errors.practiceEndTime = 'Required';
    } else if (!state.practiceEndTime.match(timeRegex)) {
      IsValid = false;
      errors.practiceEndTime = 'Required format HH:MM';
    }

    if (!state.trainingStartTime) {
      IsValid = false;
      errors.trainingStartTime = 'Required';
    } else if (!state.trainingStartTime.match(timeRegex)) {
      IsValid = false;
      errors.trainingStartTime = 'Required format HH:MM';
    }

    if (!state.trainingEndTime) {
      IsValid = false;
      errors.trainingEndTime = 'Required';
    } else if (!state.trainingEndTime.match(timeRegex)) {
      IsValid = false;
      errors.trainingEndTime = 'Required format HH:MM';
    }

    setErrors(errors);
    return IsValid;
  };

  // form handlers
  const onSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const getNewFiles = (arry) => arry.filter((k) => !k._id);
      const getUpdatedFiles = (arry) => arry.filter((k) => !!k._id && k.updated && (k.file || k.mediaUrl));
      // function to filter fields
      // eslint-disable-next-line no-unused-vars
      const filterdataToApi = (arry) => {
        const data = arry.map((item) => {
          const { _id, description, file, mediaUrl } = item;
          if (file) {
            return { _id, description, file };
          }
          return { _id, description, mediaUrl };
        });
        return data;
      };

      const { health, injury } = state;
      const newInjuries = getNewFiles(injury);
      const newHealths = getNewFiles(health);
      const updatedInjury = getUpdatedFiles(injury);
      const updatedHealth = getUpdatedFiles(health);
      // const healthDataToAPi = filterdataToApi([...updatedHealth]);
      // const injuryDataToApi = filterdataToApi([...updatedInjury]);

      //
      const healthFiles = [...updatedHealth];
      const injuryFiles = [...updatedInjury];

      const newHealth = [...newHealths];
      const newInjury = [...newInjuries];

      const payload = {
        ...state
      };

      if (healthFiles.length) {
        payload.healthFiles = healthFiles;
      }

      if (injuryFiles.length) {
        payload.injuryFiles = injuryFiles;
      }

      if (newHealth.length) {
        payload.newHealth = newHealth;
      }

      if (newInjury.length) {
        payload.newInjury = newInjury;
      }

      // updates details
      dispatch(Creators.updatePlayerProfessionalDetails(payload));

      // uploades new files if any new files are added
      // if (newInjuries.length) {
      //   dispatch(FileCreators.fileUpload({ mediaType: 'injuries', files: newInjuries, arrayType: false }));
      // }

      // if (newHealths.length) {
      //   dispatch(FileCreators.fileUpload({ mediaType: 'health', files: newHealths, arrayType: false }));
      // }

      // // updates files if any files are updtaded
      // if (fileData.length) {
      //   dispatch(Creators.updateFile({ fileData: { fileData } }));
      // }
    }
  };

  const handleMealType = (value) => {
    setstate({ ...state, mealPreference: value });
    validateControl('mealPreference');
  };

  const handlepracticeStartTimeMeredian = (value) => {
    setstate({ ...state, practiceStartTimeMeredian: value });
    validateControl('practiceStartTimeMeredian');
  };

  const handlepracticeStartTime = (value) => {
    if (value.match(/^[0-9 :]+$/) || value.length === 0) {
      setstate({ ...state, practiceStartTime: value });
    }
    validateControl('practiceStartTime');
  };

  const handlepracticeEndTimeMeredian = (value) => {
    setstate({ ...state, practiceEndTimeMeredian: value });
    validateControl('practiceEndTimeMeredian');
  };

  const handlepracticeEndTime = (value) => {
    if (value.match(/^[0-9 :]+$/) || value.length === 0) {
      setstate({ ...state, practiceEndTime: value });
    }
    validateControl('practiceEndTime');
  };

  const handletrainingStartTimeMeredian = (value) => {
    setstate({ ...state, trainingStartTimeMeredian: value });
    validateControl('trainingStartTimeMeredian');
  };

  const handletrainingStartTime = (value) => {
    if (value.match(/^[0-9 :]+$/) || value.length === 0) {
      setstate({ ...state, trainingStartTime: value });
    }
    validateControl('trainingStartTime');
  };

  const handletrainingEndTimeMeredian = (value) => {
    setstate({ ...state, trainingEndTimeMeredian: value });
    validateControl('trainingEndTimeMeredian');
  };

  const handletrainingEndTime = (value) => {
    if (value.match(/^[0-9 :]+$/) || value.length === 0) {
      setstate({ ...state, trainingEndTime: value });
    }
    validateControl('trainingEndTime');
  };

  const toggleInjury = (value) => {
    setAddInjury(value);
    if (addHealth) {
      setAddHealth(false);
    }
  };

  const toggleHealth = (value) => {
    setAddHealth(value);
  };

  const handleAlergy = (value) => {
    setstate({ ...state, foodAllergy: value });
    validateControl('foodAllergy');
  };

  const injuryDescription = ({ value, index }) => {
    const newArray = [...state.injury];
    newArray[index] = { ...newArray[index], description: value, updated: true };
    setstate({ ...state, injury: newArray });
  };

  const healthDescription = ({ value, index }) => {
    const newArray = [...state.health];
    newArray[index] = { ...newArray[index], description: value, updated: true };
    setstate({ ...state, health: newArray });
  };

  const handleHealthFiles = ({ file, fileName, index }) => {
    const newArray = [...state.health];
    newArray[index] = { ...newArray[index], file, fileName, index, updated: true };
    setstate({ ...state, health: newArray });
  };

  const handleInjuryFiles = ({ file, fileName, index }) => {
    const arry = [...state.injury];
    arry[index] = { ...arry[index], file, fileName, index, updated: true };
    setstate({ ...state, injury: arry });
  };

  const handleDeleteHealthFile = (_id) => {
    if (_id) {
      dispatch(Creators.deleteFile({ _id }));
    }
  };

  const _tableRow = ({ description, fileName, mediaUrl, _id, i }) => (
    <tr key={`${_id}_${i}`}>
      <td>
        <H3>{`${i + 1}. ${description}`}</H3>
      </td>
      <td>
        <FileContainer>
          <P light>{shortenText(fileName, 10, '...')}</P>
          {mediaUrl && (
            <A href={mediaUrl} target='_blank'>
              <Arrow />
            </A>
          )}
        </FileContainer>
      </td>
    </tr>
  );

  return (
    <>
      {loading && <LoadingComponent />}
      <Toasts />
      {!editMode ? (
        <Container style={{ margin: '2rem 0' }}>
          <Header>
            <H2>Details</H2>
            <TooltipComponnet type='edit2' title='Edit' onClick={() => setEditMode(true)} />
          </Header>
          <Details>
            <DetailsRow>
              <P light>Injury History</P>
              {state.injury.length ? (
                <Table>
                  <tbody>{state.injury.map((item, i) => _tableRow({ ...item, i }))}</tbody>
                </Table>
              ) : (
                <H3>No Injuries History</H3>
              )}
            </DetailsRow>

            <DetailsRow>
              <P light>Health History</P>
              {state.health.length ? (
                <Table>
                  <tbody>{state.health.map((item, i) => _tableRow({ ...item, i }))}</tbody>
                </Table>
              ) : (
                <H3>No Health History</H3>
              )}
            </DetailsRow>

            <DetailsRow>
              <P light>Meal Preference</P>
              <H3>{state.mealPreference}</H3>
            </DetailsRow>

            <DetailsRow>
              <P light>Food Allergy</P>
              <H3>{state.foodAllergy}</H3>
            </DetailsRow>

            <DetailsRow>
              <P light>Practice Time</P>
              <H3>{`${state.practiceStartTime} ${state.practiceStartTimeMeredian} - ${state.practiceEndTime} ${state.practiceEndTimeMeredian}`}</H3>
            </DetailsRow>

            <DetailsRow>
              <P light>Training Time</P>
              <H3>{`${state.trainingStartTime} ${state.trainingStartTimeMeredian} - ${state.trainingEndTime} ${state.trainingEndTimeMeredian}`}</H3>
            </DetailsRow>
          </Details>
        </Container>
      ) : (
        <Container style={{ margin: '2rem 0' }}>
          <form onSubmit={onSubmit}>
            <Header>
              <H2>Details</H2>
              <ButtonComponent buttonText='Save' buttonType='submit' />
            </Header>
            <Row>
              <TogglerContainer>
                <p>Injury History</p>
                <ToggleSwitch isChecked={addInjury} disabled={false} onChangeHandler={toggleInjury} />
              </TogglerContainer>
            </Row>

            {addInjury ? (
              <>
                <Row>
                  <HistoryAttachments
                    value={state.injury}
                    name='injury'
                    onFileChange={handleInjuryFiles}
                    desChange={injuryDescription}
                    onDelete={handleDeleteHealthFile}
                    setValue={(value) => setstate({ ...state, injury: value })}
                  />
                </Row>
                <ErrorMsg>{errors.injury}</ErrorMsg>
              </>
            ) : (
              ''
            )}

            <Divider />

            <Row>
              <TogglerContainer>
                <p>Health History</p>
                <ToggleSwitch isChecked={addHealth} disabled={false} onChangeHandler={toggleHealth} />
              </TogglerContainer>
            </Row>
            {addHealth ? (
              <>
                <Row>
                  <HistoryAttachments
                    value={state.health}
                    name='Health'
                    onFileChange={(value) => handleHealthFiles(value)}
                    desChange={healthDescription}
                    onDelete={handleDeleteHealthFile}
                    setValue={(value) => {
                      setstate({ ...state, health: value });
                    }}
                  />
                </Row>
                <ErrorMsg>{errors.health}</ErrorMsg>
              </>
            ) : (
              ''
            )}

            <Divider />

            <Row>
              <Width50>
                <Dropdown
                  list={typeOfFood}
                  label='Meal Preference'
                  value={state.mealPreference}
                  setValue={handleMealType}
                />
                <ErrorMsg style={{ marginTop: '0' }}>{errors.mealPreference}</ErrorMsg>
              </Width50>

              <Width50>
                <FormInput
                  placeholder='Eg: Peanut, Lactose....'
                  label='Food Allergy'
                  value={state.foodAllergy}
                  setValue={handleAlergy}
                />
              </Width50>
            </Row>
            <Row>
              <Width50>
                <FlexContainer>
                  <div>
                    <FlexContainer>
                      <FormInput
                        label='Practice Time'
                        value={state.practiceStartTime}
                        setValue={handlepracticeStartTime}
                        placeholder='HH:MM'
                      />
                      <Timer>
                        <Dropdown
                          list={['AM', 'PM']}
                          label=' &nbsp;'
                          value={state.practiceStartTimeMeredian}
                          setValue={handlepracticeStartTimeMeredian}
                        />
                      </Timer>
                    </FlexContainer>
                    <ErrorMsg>{errors.practiceStartTime}</ErrorMsg>
                  </div>
                  <Dash>&mdash;</Dash>
                  <div>
                    <FlexContainer>
                      <FormInput
                        label=' &nbsp;'
                        placeholder='HH:MM'
                        value={state.practiceEndTime}
                        setValue={handlepracticeEndTime}
                      />
                      <Timer>
                        <Dropdown
                          list={['AM', 'PM']}
                          label=' &nbsp;'
                          value={state.practiceEndTimeMeredian}
                          setValue={handlepracticeEndTimeMeredian}
                        />
                      </Timer>
                    </FlexContainer>
                    <ErrorMsg>{errors.practiceEndTime}</ErrorMsg>
                  </div>
                </FlexContainer>
              </Width50>
              <Width50>
                <FlexContainer>
                  <div>
                    <FlexContainer>
                      <FormInput
                        label='Training Time'
                        placeholder='HH:MM'
                        value={state.trainingStartTime}
                        setValue={handletrainingStartTime}
                      />
                      <Timer>
                        <Dropdown
                          list={['AM', 'PM']}
                          label=' &nbsp;'
                          value={state.trainingStartTimeMeredian}
                          setValue={handletrainingStartTimeMeredian}
                        />
                      </Timer>
                    </FlexContainer>
                    <ErrorMsg>{errors.trainingStartTime}</ErrorMsg>
                  </div>
                  <Dash>&mdash;</Dash>
                  <div>
                    <FlexContainer>
                      <FormInput
                        placeholder='HH:MM'
                        label=' &nbsp;'
                        value={state.trainingEndTime}
                        setValue={handletrainingEndTime}
                      />
                      <Timer>
                        <Dropdown
                          list={['AM', 'PM']}
                          label=' &nbsp;'
                          value={state.trainingEndTimeMeredian}
                          setValue={handletrainingEndTimeMeredian}
                        />
                      </Timer>
                    </FlexContainer>
                    <ErrorMsg>{errors.trainingEndTime}</ErrorMsg>
                  </div>
                </FlexContainer>
              </Width50>
            </Row>
          </form>
        </Container>
      )}
    </>
  );
};

export default Professional;
