import styled from 'styled-components';
import { fontSize } from '../../constants';

export const Container = styled.div`
  display: flex;
  align-items: start;
`;

export const ShowComments = styled.div`
  font-size: calc(${fontSize.mediumDefault} - 0.2rem);
  // text-transform: uppercase;
  cursor: pointer;
  margin-bottom: 1.5rem;

  & > svg {
    margin: 0 0.5rem;
  }
`;

export const ScrollContainer = styled.div`
  max-height: 150px;
  padding-right: 0.5rem;
  margin-bottom: 1.5rem;
  overflow-y: auto;
`;
