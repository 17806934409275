/* eslint-disable max-len */
/*
 * actions.js file for store
 * Author:
 * 1. Manikanta
 * @copyright of Panorbit Services LLP.
 */

//  imports from component store
import { Types as LoginTypes, Creators as LoginCreators } from '../views/auth/LoginView/store';
import { Types as CreatePasswordTypes, Creators as CreatePasswordCreators } from '../views/auth/CreatePassword/store';
import { Types as CommonTypes, Creators as CommonCreators } from '../views/store';

import {
  Types as ForgotPasswordTypes,
  Creators as ForgotPasswordCreators
} from '../views/auth/ForgotPasswordView/store';

import { Types as UserMetaTypes, Creators as UserMetaCreators } from '../views/PlayerDetailsView/store';

import { Types as VerifyOtpTypes, Creators as VerifyOtpCreators } from '../views/auth/OtpView/store';
import { Types as PersonalDetailTypes, Creators as PersonalDetailCreators } from '../views/PersonalDetails/store';
import { Types as ProfessionalTypes, Creators as ProfessionalCreators } from '../views/ProfessionalDetailsView/store';
import { Types as AdminUsersTypes, Creators as AdminUsersCreators } from '../views/admin/UsersView/store';
import { Types as CompareTypes, Creators as CompareCreators } from '../views/CompareView/store';

// userType : Player actions
import { Types as PlayerTypes, Creators as PlayerCreators } from '../views/Player/HomeView/store';
import { Types as PlayerSkillsTypes, Creators as PlayerSkillsCreators } from '../views/Player/SkillsView/store';
import { Types as PlayerCompareTypes, Creators as PlayerCompareCreators } from '../views/Player/CompareView/store';
import { Types as AddVideoTypes, Creators as AddVideoCreators } from '../views/Player/AddVideosView/store';
import {
  Types as PlayerVideoPlayerTypes,
  Creators as PlayerVideoPlayerCreators
} from '../views/Player/VideoplayerView/store';
import { Types as PlayerStrengthTypes, Creators as PlayerStrengthCreators } from '../views/Player/StrengthView/store';

// userType: Player Nutrition actions
import { Types as PlayerMealPlanTypes, Creators as PlayerMealPlanCreators } from '../views/Player/NutritionView/store';

// userType: Player Mindset actions
import { Types as PlayerMindsetTypes, Creators as PlayerMindsetCreators } from '../views/Player/MindsetView/store';
import { Types as PlayerMoodTypes, Creators as PlayerMoodCreators } from '../views/Player/MindsetView/MoodStore';
import { Types as PlayerProfileTypes, Creators as PlayerProfileCreators } from '../views/Player/ProfileModal/store';

// userType: Coach actions:
import { Types as CoachTypes, Creators as CoachCreators } from '../views/Coach/HomeView/store';
import {
  Types as PlayerDashboardTypes,
  Creators as PlayerDashboardCreators
} from '../views/Coach/PlayerDashboardView/store';
import { Types as CoachPlayersTypes, Creators as CoachPlayersCreators } from '../views/Coach/PlayersView/store';
import { Types as CoachSkillsTypes, Creators as CoachSkillsCreators } from '../views/Coach/SkillsVIew/store';
import {
  Types as CoachVideoPlayerTypes,
  Creators as CoachVideoPlayerCreators
} from '../views/Coach/VideoplayerView/store';
import { Types as SearchVideoTypes, Creators as SearchVideoCreators } from '../views/Coach/SearchVideosView/store';

import { Types as GroupListTypes, Creators as GroupListCreators } from '../views/Coach/GroupView/store';
import { Types as ModalDataTypes, Creators as ModalDataCreators } from '../views/Coach/RestPlayers/store';

import { Types as CoachExerciseTypes, Creators as CoachExerciseCreators } from '../views/Coach/ExerciseView/store';
import { Types as CoachWorkoutTypes, Creators as CoachWorkoutCreators } from '../views/Coach/Workoutsview/store';
import { Types as CoachPlannerTypes, Creators as CoachPlannerCreators } from '../views/Coach/PlannerView/store';
import {
  Types as CoachPlannerDetailsTypes,
  Creators as CoachPlannerDetailsCreators
} from '../views/Coach/PlannerDetailsView/store';

import {
  Types as CoachStrengthTypes,
  Creators as CoachStrengthCreators
} from '../views/Coach/StrengthViews/RecentView/store';

import { Types as CStrengthTypes, Creators as CStrengthCreators } from '../views/Coach/StrengthViews/store';
// Coach Nutrition actions
import { Types as NutritionTypes, Creators as NutritionCreators } from '../views/Coach/NutritionViews/store';

import { Types as CoachMealPlanTypes, Creators as CoachMealPlanCreators } from '../views/Coach/MealPlansView/store';
import {
  Types as CoachMealPlannerTypes,
  Creators as CoachMealPlannerCreators
} from '../views/Coach/MealPlannerView/store';

import {
  Types as CoachMealRecentTypes,
  Creators as CoachMealRecentCreators
} from '../views/Coach/MealRecentView/store';

import {
  Types as CoachPlayerMealTypes,
  Creators as CoachPlayerMealCreators
} from '../views/Coach/MealPlannerDetailView/store';

import {
  Types as CoachStrengthFeedDetailsTypes,
  Creators as CoachStrengthFeedDetailsCreators
} from '../views/Coach/StrengthViews/RecentView/SessionDetails/store';

import {
  Types as UpdatePersonalDetailsTypes,
  Creators as UpdatePersonalDetailsCreators
} from '../views/ProfileModal/store';

// Coach Mindset actions
import { Types as CoachMindsetTypes, Creators as CoachMindsetCreators } from '../views/Coach/MindsetView/store';

// Coach Profile
import {
  Types as UpdateCoachPersonalDetailsTypes,
  Creators as UpdateCoachPersonalDetailsCreators
} from '../views/Coach/CoachProfile/store';

// Help and Support
import { Types as HelpAndSupportTypes, Creators as HelpAndSupportCreators } from '../views/HelpAndSupport/store';

//

// userType: admin
import { Types as AdminHomeTypes, Creators as AdminHomeCreators } from '../views/admin/HomeView/store';

//
const Types = {
  ...LoginTypes,
  ...CreatePasswordTypes,
  ...ForgotPasswordTypes,
  ...UserMetaTypes,
  ...VerifyOtpTypes,
  ...PersonalDetailTypes,
  ...ProfessionalTypes,
  ...AdminUsersTypes,
  ...PlayerSkillsTypes,
  ...PlayerVideoPlayerTypes,
  ...PlayerMealPlanTypes,
  ...PlayerMindsetTypes,
  ...CoachPlayersTypes,
  ...CoachSkillsTypes,
  ...CoachVideoPlayerTypes,
  ...CompareTypes,
  ...SearchVideoTypes,
  ...GroupListTypes,
  ...ModalDataTypes,
  ...CoachExerciseTypes,
  ...CoachWorkoutTypes,
  ...CoachPlannerTypes,
  ...CoachPlannerDetailsTypes,
  ...CoachStrengthTypes,
  ...CoachMealPlanTypes,
  ...CoachStrengthFeedDetailsTypes,
  ...CoachMealPlannerTypes,
  ...CoachMealRecentTypes,
  ...CoachPlayerMealTypes,
  ...UpdatePersonalDetailsTypes,
  ...CoachMindsetTypes,
  ...PlayerCompareTypes,
  ...AddVideoTypes,
  ...PlayerMoodTypes,
  ...PlayerStrengthTypes,
  ...PlayerTypes,
  ...CoachTypes,
  ...PlayerDashboardTypes,
  ...UpdateCoachPersonalDetailsTypes,
  ...PlayerProfileTypes,
  ...HelpAndSupportTypes,
  ...AdminHomeTypes,
  ...CommonTypes,
  ...NutritionTypes,
  ...CStrengthTypes
};

const Creators = {
  ...LoginCreators,
  ...CreatePasswordCreators,
  ...ForgotPasswordCreators,
  ...UserMetaCreators,
  ...VerifyOtpCreators,
  ...PersonalDetailCreators,
  ...ProfessionalCreators,
  ...AdminUsersCreators,
  ...PlayerSkillsCreators,
  ...PlayerVideoPlayerCreators,
  ...PlayerMealPlanCreators,
  ...PlayerMindsetCreators,
  ...CoachPlayersCreators,
  ...CoachSkillsCreators,
  ...CoachVideoPlayerCreators,
  ...CompareCreators,
  ...SearchVideoCreators,
  ...GroupListCreators,
  ...ModalDataCreators,
  ...CoachExerciseCreators,
  ...CoachWorkoutCreators,
  ...CoachPlannerCreators,
  ...CoachPlannerDetailsCreators,
  ...CoachStrengthCreators,
  ...CoachMealPlanCreators,
  ...CoachStrengthFeedDetailsCreators,
  ...CoachMealPlannerCreators,
  ...CoachMealRecentCreators,
  ...CoachPlayerMealCreators,
  ...UpdatePersonalDetailsCreators,
  ...CoachMindsetCreators,
  ...PlayerCompareCreators,
  ...AddVideoCreators,
  ...PlayerMoodCreators,
  ...PlayerStrengthCreators,
  ...PlayerCreators,
  ...CoachCreators,
  ...PlayerDashboardCreators,
  ...UpdateCoachPersonalDetailsCreators,
  ...PlayerProfileCreators,
  ...HelpAndSupportCreators,
  ...AdminHomeCreators,
  ...CommonCreators,
  ...NutritionCreators,
  ...CStrengthCreators
};

export { Types, Creators };
