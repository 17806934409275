import styled from 'styled-components';

import { fontSize, backgroundColor, fontWeight } from '../../../shared/constants';

export const Container = styled.div`
  position: absolute;
  z-index: 999999;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${backgroundColor.popupBg};
`;

export const CardContainer = styled.div`
  border-radius: 10px;

  padding: 38px;
  position: relative;

  background-color: ${backgroundColor.popup};

  z-index: 100;

  min-height: 314px;
  min-width: 641px;
  max-width: 1000px;
`;

export const Title = styled.div`
  font-size: calc(${fontSize.titleVerySmall} - 0.1rem);
  font-weight: ${fontWeight.bold};

  margin-bottom: 15px;
`;

export const CloseButton = styled.span`
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Body = styled.div`
  padding: 10px 0;
`;

export const ScrollContainer = styled.div`
  overflow-y: auto;
  max-height: 155px;
  margin: 10px 0;
`;

export const Muted = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 98%;
  font-size: 1.5rem;
  border: none;
  border-radius: 0.6rem;
  background-color: rgba(78, 67, 105, 0.2);

  color: #cbd1df;
  padding: 20px;
`;

export const Linkto = styled.a`
  cursor: pointer;
`;

export const DownloadSpan = styled.span`
  &:hover > svg > g > path {
    stroke: #31ce56;
  }

  &:hover > svg > g > g > line {
    stroke: #31ce56;
  }

  & > svg > g > g {
    transition: 0.3s;
  }

  &:hover > svg > g > g {
    transform: translate(7px, 3px);
  }
`;
