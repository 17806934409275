import React from 'react';
// import PropTypes from 'prop-types';
import { ToggleButton } from './ToggleButton.styles';

const { Container: ToggleContainer, Checkbox, Knob, Layer } = ToggleButton;
/*
Toggle Switch Component
Note:  checked and onChangeHandler are required for ToggleSwitch component to function.
Usage: <ToggleSwitch checked={true} disabled={false}} />
*/

const ToggleSwitch = ({ isChecked, disabled, onChangeHandler, ipRef }) => {
  const handleChange = () => {
    if (!disabled) return onChangeHandler(!isChecked);
    return null;
  };

  return (
    <ToggleContainer checked={isChecked} ref={ipRef}>
      <Checkbox type='checkbox' value={isChecked} onChange={handleChange} />
      <Knob checked={isChecked} />
      <Layer checked={isChecked} />
    </ToggleContainer>
  );
};

export default ToggleSwitch;
