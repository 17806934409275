import React from 'react';
import PropTypes from 'prop-types';

// styles
import { Container } from './Button.styles';

class ButtonComponent extends React.Component {
  handleClick = () => {
    const { isButtonAction, buttonClick } = this.props;

    if (isButtonAction) buttonClick();
  };

  render() {
    const { buttonText, isButtonAction, buttonType, bgType, autoWidth, margin, marginLeft, marginRight } = this.props;
    return (
      <Container
        type={buttonType}
        onClick={() => this.handleClick()}
        isButtonAction={isButtonAction}
        bgType={bgType}
        autoWidth={autoWidth}
        margin={margin}
        marginLeft={marginLeft}
        marginRight={marginRight}
      >
        {buttonText}
      </Container>
    );
  }
}

ButtonComponent.defaultProps = {
  buttonType: 'button',
  bgType: 'primary',
  isButtonAction: true,
  buttonClick: () => null,
  autoWidth: false,
  margin: true,
  marginLeft: false,
  marginRight: false
};

ButtonComponent.propTypes = {
  buttonText: PropTypes.string.isRequired,
  isButtonAction: PropTypes.bool,
  buttonClick: PropTypes.func,
  bgType: PropTypes.string,
  buttonType: PropTypes.string,
  autoWidth: PropTypes.bool,
  margin: PropTypes.bool,
  marginLeft: PropTypes.bool,
  marginRight: PropTypes.bool
};

export default ButtonComponent;
