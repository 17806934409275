/* eslint-disable react/state-in-constructor */
import React from 'react';
import { useSelector } from 'react-redux';

import { SelectItemsContainer, Title, SelectItem, Button, Select, Drop, DNDContainer, DND } from './VideoTypeStyles';

import { ReactComponent as Youtube } from '../../assets/youtube_logo.svg';
import { ReactComponent as Vimeo } from '../../assets/vimeo_logo.svg';
import { ReactComponent as Delete } from '../../assets/delete.svg';
import { FormInput } from '../FormComponents';
import Toast from '../../../services/toast';
import { localString } from '../../../services/locallization/localStrings';

const VideoType = (props) => {
  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  // values
  const { videoTitle, videoLink, videoFile, videoType } = props;
  // functions
  const { setFile, setVideoType, setVideoTitle, setLink } = props;

  const onChangeHandler = ({ target }) => {
    const file = target.files[0];
    const videoType = file.type;
    if (videoType === 'video/mp4') {
      setFile(target.files[0]);
    } else {
      Toast.error(string.invalidFileFormat, 2);
    }
  };

  return (
    <div>
      <Title>Upload a Video</Title>
      <SelectItemsContainer>
        <SelectItem>
          <Button onClick={() => setVideoType('youtube')}>
            <Select>{videoType === 'youtube' && <Drop />}</Select>
          </Button>

          <div>
            <Youtube />
          </div>
        </SelectItem>

        <SelectItem>
          <Button onClick={() => setVideoType('vimeo')}>
            <Select>{videoType === 'vimeo' && <Drop />}</Select>
          </Button>

          <div>
            <Vimeo />
          </div>
        </SelectItem>

        <SelectItem>
          <Button onClick={() => setVideoType('Upload Video')}>
            <Select>{videoType === 'Upload Video' && <Drop />}</Select>
          </Button>

          <div>Upload Video</div>
        </SelectItem>
      </SelectItemsContainer>

      {videoType !== 'Upload Video' ? (
        <FormInput
          placeholder={string.pasteUrl}
          label={string.videoUrl}
          value={videoLink}
          setValue={(value) => setLink(value)}
        />
      ) : (
        <>
          <FormInput
            placeholder={string.tutorialVideoTitle}
            label={string.videoTitle}
            value={videoTitle || ''}
            setValue={(value) => setVideoTitle(value)}
          />
          <DNDContainer>
            {!videoFile ? (
              <>
                <input style={{ display: 'none' }} type='file' accept='video/*' id='video' onChange={onChangeHandler} />
                <DND htmlFor='video'>{string.clickToUpload}</DND>
              </>
            ) : (
              <>
                <div style={{ fontSize: '16px', margin: '0 1rem' }}>{videoFile.name || videoFile.videoFileName}</div>
                <Delete style={{ cursor: 'pointer' }} onClick={() => setFile(null)} />
              </>
            )}
          </DNDContainer>
        </>
      )}
    </div>
  );
};

export default VideoType;
