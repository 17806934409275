/* eslint-disable indent */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable arrow-body-style */
/* eslint-disable object-property-newline */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-const */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from './store';

// styles
import {
  FormTitle,
  InputsContainer,
  MealInputsContainer,
  Column,
  NutrientHeader,
  MealContainer,
  AddMealButton,
  AddmealContainer
} from './CreateMealPlanModalStyles';

import { Divider, FlexContainer, ValdErrMsg } from '../../../globalShared/CommonStyles';

import { ReactComponent as DeleteIcon } from '../../../shared/assets/delete.svg';
import { ReactComponent as CloseIcon } from '../../../shared/assets/Close.svg';
import { ReactComponent as AddICon } from '../../../shared/assets/add_meal.svg';
import { ReactComponent as DragIcon } from '../../../shared/assets/drag.svg';

import { AddMoreComponent, ModalComponent } from '../../../globalShared';
import { FormInput, Dropdown } from '../../../shared/components/FormComponents';
import { ErrorMsg } from '../../PersonalDetails/PersonalDetails.styles';
import { foodTime, typeOfFood } from '../../../shared/constants/lists';
import { scrollToView } from '../../../services/utility';
import { localString } from '../../../services/locallization/localStrings';
// import Toast from '../../../services/toast';

const numbersRegx = /^[0-9]+$/;
const floatRegex = /^[0-9]*(\.[0-9]{0,2})?$/;
/**
 * component
 */
const CreateMealPlanModal = ({ mealPlan }) => {
  // store to props
  const dispatch = useDispatch();
  const lastItemRef = useRef();
  const listItemRef = useRef();

  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  const success = useSelector((state) => state.coachMealPlan.success);

  // form data
  const [formFields, setFormFields] = useState({
    title: '',
    typeOfFood: '',
    protein: '',
    carbohydrates: '',
    fats: '',
    totalKcal: '',
    totalWaterIntake: ''
  });

  const [mealFormFields] = useState({
    title: '',
    kcal: '',
    time: '',
    intake: [{ descr: '', remarks: '' }]
  });

  const [mealFormArray, setMealFormArray] = useState([mealFormFields]);
  const [errors, setErrors] = useState({
    ...formFields,
    toatlMeal: '',
    meal: [{ Mtitle: '', Mcal: '', Mtime: '', MIntake: '' }]
  });

  // form data ends */
  const _calcKcal = (name, value) => {
    const { protein, carbohydrates, fats } = formFields;
    let total = 0;

    if (name === 'protein') {
      total = carbohydrates * 4 + fats * 9 + value * 4;
    }
    if (name === 'carb') {
      total = value * 4 + fats * 9 + protein * 4;
    }
    if (name === 'fats') {
      total = carbohydrates * 4 + protein * 4 + value * 9;
    }

    return total;
  };

  // form validations
  const validateForm = () => {
    const errs = errors;
    let IsValid = true;

    if (!formFields.title) {
      IsValid = false;
      setErrors({ ...errors, title: true });
      errs.title = true;
    }

    if (!formFields.typeOfFood) {
      IsValid = false;
      setErrors({ ...errors, typeOfFood: true });
      errs.typeOfFood = true;
    }

    if (!`${formFields.protein}`) {
      IsValid = false;
      setErrors({ ...errors, protein: string.required });
      errs.protein = string.required;
    }

    if (!`${formFields.carbohydrates}`) {
      IsValid = false;
      setErrors({ ...errors, carbohydrates: string.required });
      errs.carbohydrates = string.required;
    }

    if (!`${formFields.fats}`) {
      IsValid = false;
      setErrors({ ...errors, fats: string.required });
      errs.fats = string.required;
    }

    if (!`${formFields.totalKcal}`) {
      IsValid = false;
      setErrors({ ...errors, totalKcal: string.required });
      errs.totalKcal = string.required;
    }

    if (!`${formFields.totalWaterIntake}`) {
      IsValid = false;
      setErrors({ ...errors, totalWaterIntake: string.required });
      errs.totalWaterIntake = string.required;
    }

    // eslint-disable-next-line array-callback-return
    mealFormArray.map((meal, i) => {
      const mealErr = errors.meal;
      const intakeIsFilled = meal.intake.map(({ descr }) => !!descr).reduce((res, cur) => res && cur, true);

      if (!meal.title) {
        IsValid = false;
        mealErr[i] = { ...mealErr[i], Mtitle: true };
        setErrors({ ...errors, meal: mealErr });
      }

      if (!meal.kcal) {
        IsValid = false;
        mealErr[i] = { ...mealErr[i], Mcal: string.required };
        setErrors({ ...errors, meal: mealErr });
      }

      if (!meal.time) {
        IsValid = false;
        mealErr[i] = { ...mealErr[i], Mtime: true };
        setErrors({ ...errors, meal: mealErr });
      }

      if (!intakeIsFilled) {
        IsValid = false;
        mealErr[i] = { ...mealErr[i], MIntake: 'Fill Intakes' };
        setErrors({ ...errors, meal: mealErr });
      }
    });

    if (!mealFormArray.length) {
      IsValid = false;
      setErrors({ ...errors, toatlMeal: 'Required Meal' });
    }

    // setErrors(errs);
    return IsValid;
  };

  const handleCancel = () => {
    dispatch(Creators.setShowCreateMealPlanModal({ show: false }));
    dispatch(Creators.setMealPlanDetailsId({ id: '' }));
    setFormFields(formFields);
    setMealFormArray([mealFormArray]);
  };

  useEffect(() => {
    if (success) {
      handleCancel();
    }
  }, [success]);

  const onSubmit = () => {
    // eslint-disable-next-line no-constant-condition
    if (validateForm()) {
      let mainData = formFields;
      mainData.title = mainData.title.trim();
      const mealPlan = { ...mainData, meals: mealFormArray, assignedType: 'Team' };
      dispatch(Creators.createMealPlan({ mealPlan }));
    }
  };

  useEffect(() => {
    if (mealPlan) {
      const { title, _id, carbohydrates, fats, meals, protein, totalKcal, totalWaterIntake, typeOfFood } = mealPlan;
      setFormFields({ title, _id, carbohydrates, fats, protein, totalKcal, totalWaterIntake, typeOfFood });
      setMealFormArray(meals);
    }
  }, [mealPlan]);

  // form functionality
  const addMeals = () => {
    setMealFormArray([
      ...mealFormArray,
      {
        title: '',
        kcal: '',
        time: '',
        intake: [{ descr: '', remarks: '' }]
      }
    ]);
    // adds err fields
    let mealErr = errors.meal;
    mealErr = [...mealErr, { Mtitle: '', Mcal: '', Mtime: '', MIntake: '' }];
    setErrors({ ...errors, meal: mealErr, toatlMeal: '' });

    scrollToView(lastItemRef);
  };

  const deleteMeal = (_mealId) => {
    const newArray = mealFormArray.filter((_, K) => K !== _mealId);
    setMealFormArray(newArray);

    // removes err fields
    let mealErr = errors.meal;
    mealErr = mealErr.filter((_, K) => K !== _mealId);
    setErrors({ ...errors, meal: mealErr });
  };

  const deleteFood = (mealId, intakeId) => {
    let IntakesArray = [...mealFormArray[mealId].intake];
    IntakesArray = IntakesArray.filter((_, index) => index !== intakeId);

    const newArray = [...mealFormArray];
    newArray[mealId] = { ...newArray[mealId], intake: IntakesArray };
    setMealFormArray(newArray);

    // onChange err handler
    const mealErr = errors.meal;
    mealErr[mealId] = { ...mealErr[mealId], MIntake: '' };
    setErrors({ ...errors, meal: mealErr });
  };

  // plan details form onchange handlers
  const handlePlanTitle = (title) => {
    setFormFields({ ...formFields, title });

    // onChange form Handler
    const errs = errors;
    if (!title) {
      errs.title = true;
      setErrors({ ...errs });
    } else {
      errs.title = false;
      setErrors({ ...errs });
    }
  };

  const handleFoodType = (typeOfFood) => {
    setFormFields({ ...formFields, typeOfFood });
    // onChange form Handler
    const errs = errors;
    if (errs.typeOfFood) {
      errs.typeOfFood = false;
      setErrors({ ...errs });
    }
  };

  const handleProtein = (value) => {
    if (value.match(numbersRegx) || value.length === 0) {
      setFormFields({
        ...formFields,
        protein: value,
        totalKcal: _calcKcal('protein', value)
      });
    }

    // onChange form Handler
    const errs = errors;
    if (!value || !value.match(numbersRegx)) {
      errs.protein = string.required;
      setErrors({ ...errs });
    } else {
      errs.protein = false;
      errs.totalKcal = false;
      setErrors({ ...errs });
    }
  };

  const handleCarbohydrates = (value) => {
    if (value.match(numbersRegx) || value.length === 0) {
      setFormFields({ ...formFields, carbohydrates: value, totalKcal: _calcKcal('carb', value) });
    }
    // onChange form Handler
    const errs = errors;
    if (!value || !value.match(numbersRegx)) {
      errs.carbohydrates = string.required;
      setErrors({ ...errs });
    } else {
      errs.carbohydrates = false;
      errs.totalKcal = false;
      setErrors({ ...errs });
    }
  };

  const handleFats = (value) => {
    if (value.match(numbersRegx) || value.length === 0) {
      setFormFields({ ...formFields, fats: value, totalKcal: _calcKcal('fats', value) });
    }

    // onChange form Handler
    const errs = errors;
    if (!value || !value.match(numbersRegx)) {
      errs.fats = string.required;
      setErrors({ ...errs });
    } else {
      errs.fats = false;
      errs.totalKcal = false;
      setErrors({ ...errs });
    }
  };

  const handleTotalKcal = (value) => {
    if (value.match(numbersRegx) || value.length === 0) {
      setFormFields({ ...formFields, totalKcal: value });
    }

    // onChange form Handler
    const errs = errors;
    if (!value || !value.match(numbersRegx)) {
      errs.totalKcal = string.required;
      setErrors({ ...errs });
    } else {
      errs.totalKcal = false;
      setErrors({ ...errs });
    }
  };

  const handleTotalWater = (value) => {
    if (floatRegex.test(value) || value.length === 0) {
      setFormFields({ ...formFields, totalWaterIntake: value });
    }

    // onChange form Handler
    const errs = errors;
    if (!value) {
      errs.totalWaterIntake = string.required;
      setErrors({ ...errs });
    } else {
      errs.totalWaterIntake = false;
      setErrors({ ...errs });
    }
  };

  // meal form onchange handlers
  const handleMealTitleChange = (value, index) => {
    const newArray = [...mealFormArray];
    newArray[index] = { ...newArray[index], title: value };
    setMealFormArray(newArray);

    // onChange err handler
    const mealErr = errors.meal;
    if (!value) {
      mealErr[index] = { ...mealErr[index], Mtitle: true };
    } else {
      mealErr[index] = { ...mealErr[index], Mtitle: false };
    }

    setErrors({ ...errors, meal: mealErr });
  };

  const handleKcalChange = (value, index) => {
    if ((value.match(numbersRegx) && value) || value.length === 0) {
      const newArray = [...mealFormArray];
      newArray[index] = { ...newArray[index], kcal: value };
      setMealFormArray(newArray);
    }

    // onChange err handler
    const mealErr = errors.meal;
    if (!value) {
      mealErr[index] = { ...mealErr[index], Mcal: string.required };
    } else {
      mealErr[index] = { ...mealErr[index], Mcal: false };
    }
    setErrors({ ...errors, meal: mealErr });
  };

  const handleTimeChange = (value, index) => {
    const newArray = [...mealFormArray];
    newArray[index] = { ...newArray[index], time: value };
    setMealFormArray(newArray);

    // onChange err handler
    const mealErr = errors.meal;
    mealErr[index] = { ...mealErr[index], Mtime: false };
    setErrors({ ...errors, meal: mealErr });
  };

  const handleAddIntake = (index) => {
    let newArray = [...mealFormArray];
    newArray[index].intake = [...newArray[index].intake, { descr: '', remake: '' }];
    setMealFormArray(newArray);
  };

  const handleIntakeChange = (value, mainIndex, subIndex, type) => {
    let newArry = [...mealFormArray];

    let newIntakeArry = newArry[mainIndex].intake;
    newIntakeArry[subIndex] = { ...newIntakeArry[subIndex], [type]: value };

    newArry[mainIndex] = { ...newArry[mainIndex], intake: newIntakeArry };
    setMealFormArray(newArry);

    // onChange err handler
    const mealErr = errors.meal;
    mealErr[mainIndex] = { ...mealErr[mainIndex], MIntake: '' };
    setErrors({ ...errors, meal: mealErr });
  };

  return (
    <ModalComponent width='1083' onCancel={handleCancel} onSubmit={onSubmit}>
      <form style={{ padding: '3.5rem', paddingBottom: '0px' }}>
        <FormTitle>Create Meal Plan</FormTitle>
        <InputsContainer style={{ marginTop: '1rem' }}>
          <FlexContainer
            style={{ width: '100%', marginBottom: '1.5rem', justifyContent: 'space-between', alignItems: 'flex-start' }}
          >
            <Column wdth='759px'>
              <FormInput
                placeholder='Plan Title'
                label='Meal Plan'
                value={formFields.title}
                setValue={handlePlanTitle}
                error={errors.title}
              />
              <ErrorMsg>{errors.title ? string.required : ''}</ErrorMsg>
            </Column>
            <Column wdth='200px'>
              <Dropdown
                list={typeOfFood}
                label='Meal Preference'
                value={formFields.typeOfFood}
                setValue={handleFoodType}
              />
              <ErrorMsg>{errors.typeOfFood && string.required}</ErrorMsg>
            </Column>
          </FlexContainer>

          <NutrientHeader>Per Day Nutrients Intake</NutrientHeader>
          <FlexContainer style={{ width: '100%', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <Column wdth='175px'>
              <FormInput
                label='Protein(g)'
                placeholder='Enter in gm'
                value={formFields.protein}
                setValue={handleProtein}
                error={!!errors.protein}
              />
              <ErrorMsg>{errors.protein}</ErrorMsg>
            </Column>
            <Column wdth='175px'>
              <FormInput
                label='Carbohydrates(g)'
                placeholder='Enter in gm'
                value={formFields.carbohydrates}
                setValue={handleCarbohydrates}
                error={!!errors.carbohydrates}
              />
              <ErrorMsg>{errors.carbohydrates}</ErrorMsg>
            </Column>
            <Column wdth='175px'>
              <FormInput
                label='Fats(g)'
                placeholder='Enter in gm'
                value={formFields.fats}
                setValue={handleFats}
                error={!!errors.fats}
              />
              <ErrorMsg>{errors.fats}</ErrorMsg>
            </Column>
            <Column wdth='175px'>
              <FormInput
                label='Total kcal'
                placeholder='Enter Value'
                value={formFields.totalKcal}
                setValue={handleTotalKcal}
                error={!!errors.totalKcal}
              />
              <ErrorMsg>{errors.totalKcal}</ErrorMsg>
            </Column>
            <Column wdth='175px'>
              <FormInput
                label='Total Water Intake(L)'
                placeholder='1'
                value={formFields.totalWaterIntake}
                setValue={handleTotalWater}
                error={!!errors.totalWaterIntake}
              />
              <ErrorMsg>{errors.totalWaterIntake}</ErrorMsg>
            </Column>
          </FlexContainer>
        </InputsContainer>
        <AddmealContainer>
          <FormTitle style={{ margin: '0' }}>Meals</FormTitle>
          <AddMealButton onClick={addMeals}>
            <AddICon />
            Add Meal
          </AddMealButton>
        </AddmealContainer>

        <>
          {mealFormArray.length
            ? mealFormArray.map((form, _mealId, array) => {
                const { title: mealTitle, kcal, time } = form;
                const errs = errors.meal;
                return (
                  <MealContainer key={_mealId} ref={array.length - 1 === _mealId ? lastItemRef : listItemRef}>
                    <MealInputsContainer style={{ width: '100%', marginRight: '0.5rem', paddingLeft: '0.5rem' }}>
                      <DragIcon style={{ marginTop: '4rem', marginRight: '1.5rem' }} />
                      <div>
                        <FlexContainer
                          style={{ width: '100%', justifyContent: 'space-between', alignItems: 'flex-start' }}
                        >
                          <Column wdth='530px'>
                            <FormInput
                              label='Meal Title'
                              placeholder='Enter Meal Title...'
                              value={mealTitle}
                              setValue={(value) => handleMealTitleChange(value, _mealId)}
                              error={!!errs[_mealId]?.Mtitle}
                            />
                            <ErrorMsg>{errs[_mealId]?.Mtitle && string.required}</ErrorMsg>
                          </Column>
                          <Column wdth='175px' style={{ margin: '0 4px' }}>
                            <FormInput
                              label='Kcal'
                              placeholder='Enter Value'
                              value={kcal}
                              setValue={(value) => handleKcalChange(value, _mealId)}
                              error={!!errs[_mealId]?.Mcal}
                            />
                            <ErrorMsg>{errs[_mealId]?.Mcal}</ErrorMsg>
                          </Column>
                          <Column wdth='175px'>
                            <Dropdown
                              list={foodTime}
                              label='Time'
                              value={time}
                              setValue={(value) => handleTimeChange(value, _mealId)}
                              maxHeight='107'
                            />
                            <ErrorMsg>{errs[_mealId]?.Mtime && string.required}</ErrorMsg>
                          </Column>
                        </FlexContainer>
                        <Divider />
                        <ErrorMsg style={{ margin: 0 }}>{errs[_mealId]?.MIntake}</ErrorMsg>
                        {form.intake.map((intakeItem, k) => {
                          return (
                            <FlexContainer
                              key={k}
                              style={{ width: '100%', justifyContent: 'space-between', margin: '0.3rem 0' }}
                            >
                              <Column wdth='500px'>
                                <FormInput
                                  showLable={k === 0}
                                  label='Intake'
                                  placeholder='Type The Intakes...'
                                  value={intakeItem.descr}
                                  setValue={(value) => handleIntakeChange(value, _mealId, k, 'descr')}
                                />
                              </Column>

                              <Column wdth='350px'>
                                <FormInput
                                  showLable={k === 0}
                                  label='Remarks'
                                  placeholder='Input Remarks Here...'
                                  value={intakeItem.remarks}
                                  setValue={(value) => handleIntakeChange(value, _mealId, k, 'remarks')}
                                />
                              </Column>
                              <CloseIcon
                                onClick={() => deleteFood(_mealId, k)}
                                style={{ cursor: 'pointer', marginTop: `${k === 0 ? '0.5' : '-2'}rem` }}
                              />
                            </FlexContainer>
                          );
                        })}
                        <AddMoreComponent onClick={() => handleAddIntake(_mealId)}>+ Add Intake</AddMoreComponent>
                      </div>
                    </MealInputsContainer>
                    <Column style={{ cursor: 'pointer' }}>
                      <DeleteIcon style={{ marginTop: '5rem', height: '2.2rem' }} onClick={() => deleteMeal(_mealId)} />
                    </Column>
                  </MealContainer>
                );
              })
            : ''}
        </>
        <>
          <ValdErrMsg style={{ marginTop: 0 }}>{errors.toatlMeal}</ValdErrMsg>
        </>
      </form>
    </ModalComponent>
  );
};

export default CreateMealPlanModal;
