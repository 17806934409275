import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import screenfull from 'screenfull';
import ReactPlayer from 'react-player';

// styles
import {
  Container,
  ControllsContainer,
  PlayButton,
  Slider,
  Timer,
  // PlayControls,
  PlaybackButton,
  PlayerFooter,
  FooterTitle,
  CompareButton,
  PlayerContainer,
  Controlls,
  VolumeButton,
  RemoveButton,
  CompareContainer
  // UserDetailsContainer,
  // UserDetails,
  // TimeSince
} from './PlayerVideoPlayerStyles';

// import { PlayerImage } from '../../../globalShared/CommonStyles';

import { ReactComponent as PlayIcon } from '../../assets/play.svg';
import { ReactComponent as PauseIcon } from '../../assets/pause.svg';
// import { ReactComponent as ForwardIcon } from '../../assets/forward.svg';
// import { ReactComponent as BackwardIcon } from '../../assets/backward.svg';
import { ReactComponent as VolumeIcon } from '../../assets/volume.svg';
import { ReactComponent as Fullscreen } from '../../assets/maximize.svg';
import { ReactComponent as CompareIcon } from '../../assets/Compare.svg';
import { ReactComponent as MuteIcon } from '../../assets/mute.svg';
import { ReactComponent as CloseIcon } from '../../assets/Close.svg';
import { ReactComponent as Speed } from '../../assets/Video speed.svg';
// import userImg from '../../assets/user.jpg';

import { Timeformater /* timeSince */ } from '../../../services/utility';
import { localString } from '../../../services/locallization/localStrings';

const Duration = ({ seconds }) => <Timer dateTime={`P${Math.round(seconds)}S`}>{Timeformater(seconds)}</Timer>;

// componant
const PlayerVideoPlayer = ({ URL, addToCompareAction, compareVideos, removeFromCompare, videoDetails }) => {
  // refs
  const playerRef = useRef(null);
  const playerContainerRef = useRef(null);
  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  // player State
  const [state, setState] = useState({
    playing: false,
    controls: false,
    muted: false,
    played: 0,
    duration: 0,
    playbackRate: 1.0,
    seeking: false,
    showControls: false
  });

  const { played, playbackRate, muted, playing, duration, showControls } = state;

  // const compareVideos = useSelector((state) => state.compare.videos);

  const handlePlayPause = () => {
    setState({ ...state, playing: !state.playing });
  };

  const handleProgress = (changeState) => {
    setState({ ...state, ...changeState });
  };

  const handleEnded = () => {
    setState({ ...state, playing: false });
  };

  const handleDuration = (duration) => {
    setState({ ...state, duration });
  };

  const onSeek = ({ target }) => {
    setState({ ...state, playing: false, played: parseFloat(target.value / 100) });
    playerRef.current.seekTo(target.value / 100, 'fraction');
  };

  const onSeekMouseDown = () => {
    setState({ ...state, seeking: true });
  };

  const onSeekMouseUp = ({ target }) => {
    setState({ ...state, seeking: false });
    playerRef.current.seekTo(target.value / 100, 'fraction');
  };

  const handleToggleMuted = () => {
    setState({ ...state, muted: !muted });
  };

  const handleSetPlaybackRate = (speed) => {
    setState({ ...state, playbackRate: speed });
  };

  const handleShowControls = () => {
    setState({ ...state, showControls: !showControls });
  };

  const checkAddedToCompare = (currentVideoId) => compareVideos.some((video) => video._id === currentVideoId);

  const toggleFullScreen = () => {
    screenfull.toggle(playerContainerRef.current);
  };

  // const handleRewind = () => {
  //   playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
  // };

  // const handleFastForward = () => {
  //   playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
  // };

  //
  return (
    <Container>
      <PlayerContainer onMouseOver={handleShowControls} ref={playerContainerRef}>
        <ReactPlayer
          ref={playerRef}
          width='100%'
          height='100%'
          url={URL}
          muted={muted}
          playing={playing}
          playbackRate={playbackRate}
          onProgress={handleProgress}
          onEnded={handleEnded}
          onDuration={handleDuration}
        />
      </PlayerContainer>

      {showControls ? (
        <Controlls show={showControls} onMouseLeave={handleShowControls}>
          {/* <PlayControls>
            <BackwardIcon onClick={handleRewind} />
            <ForwardIcon onClick={handleFastForward} />
          </PlayControls> */}

          <ControllsContainer className='controlls_container'>
            <PlayButton onClick={handlePlayPause}>{playing ? <PauseIcon /> : <PlayIcon />}</PlayButton>
            <Slider
              type='range'
              min='1'
              max='100'
              step='any'
              value={played * 100}
              onChange={onSeek}
              onMouseUp={onSeekMouseUp}
              onMouseDown={onSeekMouseDown}
            />
            <Duration seconds={duration * played} />

            <VolumeButton onClick={handleToggleMuted}>{muted ? <MuteIcon /> : <VolumeIcon />}</VolumeButton>
            <Fullscreen onClick={toggleFullScreen} style={{ margin: '0 1.5rem' }} />
          </ControllsContainer>
        </Controlls>
      ) : (
        ''
      )}
      <PlayerFooter>
        <div>
          <FooterTitle>
            <Speed />
            {string.videoSpeed}
          </FooterTitle>
          <div>
            <PlaybackButton isSelected={playbackRate === 0.5} onClick={() => handleSetPlaybackRate(0.5)}>
              0.5x
            </PlaybackButton>
            <PlaybackButton isSelected={playbackRate === 0.75} onClick={() => handleSetPlaybackRate(0.75)}>
              0.75x
            </PlaybackButton>
            <PlaybackButton isSelected={playbackRate === 1} onClick={() => handleSetPlaybackRate(1)}>
              1x
            </PlaybackButton>
            <PlaybackButton isSelected={playbackRate === 1.25} onClick={() => handleSetPlaybackRate(1.25)}>
              1.25x
            </PlaybackButton>
            <PlaybackButton isSelected={playbackRate === 1.5} onClick={() => handleSetPlaybackRate(1.5)}>
              1.5x
            </PlaybackButton>
          </div>
        </div>
        <CompareContainer>
          {checkAddedToCompare(videoDetails?._id) ? (
            <RemoveButton onClick={removeFromCompare}>
              <CloseIcon />
              {string.removeFromCompare}
            </RemoveButton>
          ) : (
            <CompareButton onClick={() => addToCompareAction(videoDetails)}>
              <CompareIcon />
              {string.addToCompare}
            </CompareButton>
          )}
        </CompareContainer>
      </PlayerFooter>
    </Container>
  );
};

export default PlayerVideoPlayer;
