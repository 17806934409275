import styled from 'styled-components';
import { accentColor, fontSize, borderColor, backgroundColor } from '../../../shared/constants';

export const UserActionsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;

  & > button {
    background: none;
    border: none;
    cursor: pointer;

    &:focus {
      outline: 0px;
    }
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 1113px;
  top: 18px;
  z-index: 999;
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const FormTitle = styled.h1`
  font-size: ${fontSize.titleVerySmall};
  margin-bottom: 23px;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0px;

  padding: 20px;
  border: 0.5px solid ${borderColor.light};

  margin-bottom: 1rem;
  margin-top: 2rem;
  border-radius: 1rem;
`;

export const MealInputsContainer = styled.div`
  display: flex;

  margin: 0px;
  justify-content: center;

  padding: 20px;
  border: 0.5px solid ${borderColor.light};

  margin-bottom: 1rem;
  border-radius: 1rem;
`;

export const Column = styled.div`
  width: ${(props) => props.wdth};

  & > span {
    padding: 0px;
  }
`;

export const NutrientHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  text-transform: capitalize;
  font-size: 14px;

  padding-bottom: 24px;
  color: #d0d5e2;
`;

export const DividerTxt = styled.p`
  font-size: 14px;
  padding: 20px;
  text-align: center;
  position: relative;

  &:before {
    content: '';
    border: 1px dashed #a3a9b7;
    position: absolute;
    width: 46%;
    left: 0;
    top: 50%;
    opacity: 20%;
  }

  &:after {
    content: '';
    border: 1px dashed #a3a9b7;
    position: absolute;
    width: 46%;
    right: 0;
    top: 50%;
    opacity: 20%;
  }
`;

export const ScrollContainer = styled.div`
  height: 255px;
  width: 100%;
  padding-right: 3rem;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const AddMoreButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;

  padding: 2rem 0;

  & > button {
    text-align: center;
    background: transparent;
    border: none;

    font-size: ${fontSize.mediumDefault};
    color: ${accentColor.primary};

    cursor: pointer;

    &:focus {
      outline: 0px;
    }
  }
`;

export const MealContainer = styled.div`
  display: flex;
  padding: 0.5rem;
`;

export const FormFooter = styled.div`
  // position: absolute;
  right: 10px;
  bottom: 0;

  padding: 1.5rem 0;
  display: flex;
  justify-content: flex-end;
`;

export const AddmealContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.5rem 0;
  background-color: ${backgroundColor.popup};
  padding-right: 3rem;
  position: sticky;
  top: 0;
  z-index: 99;
`;

export const AddMealButton = styled.div`
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${fontSize.medium};

  background: ${backgroundColor.darkOpaq};
  padding: 0.7rem 1.4rem;
  border-radius: 5px;
  cursor: pointer;
  & > svg {
    margin-right: 1rem;
  }
`;
