/* eslint-disable indent */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import { Creators } from './store';
import { Creators as CommonCreators } from '../../store';

import { timeSince } from '../../../services/utility';

// styles
import {
  Container,
  MeditationHeader,
  BasicMeditationCard,
  GuidedMeditationCard,
  ExpertMeditationCard,
  CardDetailsContainer,
  CardHeader,
  CardDetails,
  MeditationDuration,
  IllusContainer,
  PlayContainer,
  TodaysCheckHeader,
  TodaysCheck,
  MedContainer,
  H3,
  HDiv,
  TextSmallLight,
  CommentInputContainer
} from './MeditationTrackStyles';

import { Divider, FlexContainer } from '../../../globalShared/CommonStyles';

// icons
import { ReactComponent as BasicMeditationIcon } from '../../../shared/assets/meditation_1.svg';
import { ReactComponent as GuidedMeditationIcon } from '../../../shared/assets/meditation_2.svg';
import { ReactComponent as ExpertMeditationIcon } from '../../../shared/assets/meditation_3.svg';
import { ReactComponent as MeditationDurationIcon } from '../../../shared/assets/meditation_duration.svg';
import { ReactComponent as MeditationPlayIcon } from '../../../shared/assets/meditation_play_btn.svg';
import { ReactComponent as CloseIcon } from '../../../shared/assets/Close.svg';

import CommentsFooter from '../../../shared/components/CommetsFooter';
import MeditationDetailsModal from './MeditationDetailsModal';
import { CommentInputComponent, MasonryLayout } from '../../../globalShared';
import { meditationMap } from '../../../services/utility/maps';

const MeditationTrack = (props) => {
  const { date } = props;
  const checkDate = date;
  const dispatch = useDispatch();

  const [recentCommentID, setRecentCommentID] = useState('');
  const [currentMeditation, setCurrentMeditation] = useState('');
  const [showMeditationDetailsModal, setShowMeditationDetailsModal] = useState(false);

  // values from redux store
  const todaysMeditationCheck = useSelector((state) => state.playerMindset.todaysMeditationCheck);
  const reloadTodaysMeditationCheck = useSelector((state) => state.playerMindset.reloadTodaysMeditationCheck);
  const basicMeditation = useSelector((state) => state.playerMindset.basicMeditation);
  const guidedMeditation = useSelector((state) => state.playerMindset.guidedMeditation);
  const expertMeditation = useSelector((state) => state.playerMindset.expertMeditation);

  const meditationTrackCommentsList = useSelector((state) => state.playerMindset.meditationTrackCommentsList);
  const reloadMeditationTrackComments = useSelector((state) => state.main.reloadComments);

  // effect hooks
  useEffect(() => {
    dispatch(Creators.getPlayerTodaysMeditationCheck({ date }));
  }, [date]);

  useEffect(() => {
    if (reloadTodaysMeditationCheck) {
      dispatch(Creators.getPlayerTodaysMeditationCheck({ date }));
    }
  }, [reloadTodaysMeditationCheck, date]);

  // handlers
  const handleMeditationDetails = (meditation) => {
    setCurrentMeditation(meditation);
    setShowMeditationDetailsModal(true);
  };

  const handleShowComments = (_id) => {
    dispatch(Creators.getPlayerMeditationTrackComments({ _id }));
    setRecentCommentID(_id);
  };

  const handleSubmitComment = (content, id) => {
    dispatch(CommonCreators.addComment({ content, id }));
    handleShowComments(id);
  };

  useEffect(() => {
    if (reloadMeditationTrackComments && !!recentCommentID) {
      handleShowComments(recentCommentID);
    }
  }, [reloadMeditationTrackComments]);

  const todaysDate = moment().format('YYYY-MM-DD');

  const handleMeditationDelete = (id, name) => {
    if (checkDate === todaysDate) {
      dispatch(Creators.deletePlayerTodaysMeditationCheck({ _id: id, meditationName: name }));
    }
  };

  const basicMeditationMinutes = 5;
  const guidedMeditationMinutes = 10;
  const expertMeditationMinutes = 15;

  // if (basicMeditation) {
  //   basicMeditationMinutes = Math.floor(basicMeditation.duration / 60);
  // }
  // if (guidedMeditation) {
  //   guidedMeditationMinutes = Math.floor(guidedMeditation.duration / 60);
  // }
  // if (expertMeditation) {
  //   expertMeditationMinutes = Math.floor(expertMeditation.duration / 60);
  // }

  return (
    <>
      <Container>
        <MeditationHeader>Today&apos;s Meditation</MeditationHeader>
        <MasonryLayout>
          <BasicMeditationCard onClick={() => handleMeditationDetails(basicMeditation || { name: 'basicMeditation' })}>
            <CardDetailsContainer>
              <CardHeader>{meditationMap.basicMeditation}</CardHeader>
              <CardDetails>
                <MeditationDurationIcon />
                <MeditationDuration>{`${basicMeditationMinutes} Min`}</MeditationDuration>
              </CardDetails>
            </CardDetailsContainer>
            <IllusContainer>
              <BasicMeditationIcon />
            </IllusContainer>
            <PlayContainer>
              <MeditationPlayIcon />
            </PlayContainer>
          </BasicMeditationCard>

          <GuidedMeditationCard
            onClick={() => handleMeditationDetails(guidedMeditation || { name: 'guidedMeditation' })}
          >
            <CardDetailsContainer>
              <CardHeader>{meditationMap.guidedMeditation}</CardHeader>
              <CardDetails>
                <MeditationDurationIcon />
                <MeditationDuration>{`${guidedMeditationMinutes} Min`}</MeditationDuration>
              </CardDetails>
            </CardDetailsContainer>
            <IllusContainer>
              <GuidedMeditationIcon />
            </IllusContainer>
            <PlayContainer>
              <MeditationPlayIcon />
            </PlayContainer>
          </GuidedMeditationCard>

          <ExpertMeditationCard
            onClick={() => handleMeditationDetails(expertMeditation || { name: 'expertMeditation' })}
          >
            <CardDetailsContainer>
              <CardHeader>{meditationMap.expertMeditation}</CardHeader>
              <CardDetails>
                <MeditationDurationIcon />
                <MeditationDuration>{`${expertMeditationMinutes} Min`}</MeditationDuration>
              </CardDetails>
            </CardDetailsContainer>
            <IllusContainer>
              <ExpertMeditationIcon />
            </IllusContainer>
            <PlayContainer>
              <MeditationPlayIcon />
            </PlayContainer>
          </ExpertMeditationCard>
        </MasonryLayout>

        {todaysMeditationCheck.length > 0 ? (
          <>
            <TodaysCheckHeader>Today&apos;s Checks</TodaysCheckHeader>

            {todaysMeditationCheck.map((check) => {
              const { id, name, duration, updatedTime, commentCount } = check;
              const meditationMinutes = Math.floor(duration / 60);
              const meditationSeconds = duration % 60;
              const formattedTime = timeSince(updatedTime);

              return (
                <TodaysCheck key={id}>
                  <MedContainer>
                    <FlexContainer style={{ justifyContent: 'space-between', paddingBottom: '0.5rem' }}>
                      <FlexContainer>
                        <H3>{meditationMap[name]}</H3>
                        <HDiv />
                        <TextSmallLight>{`${meditationMinutes} Mins ${meditationSeconds} Sec`}</TextSmallLight>
                      </FlexContainer>
                      <FlexContainer>
                        <TextSmallLight>{formattedTime}</TextSmallLight>
                      </FlexContainer>
                    </FlexContainer>
                    <Divider />
                    <div>
                      <CommentsFooter
                        _id={id}
                        commentCount={commentCount || 0}
                        commentsList={meditationTrackCommentsList}
                        onClick={handleShowComments}
                      />
                      <CommentInputContainer>
                        <CommentInputComponent
                          placeholderText='Enter Your Comment Here…'
                          id={id}
                          onSubmit={handleSubmitComment}
                        />
                      </CommentInputContainer>
                    </div>
                  </MedContainer>
                  {checkDate === todaysDate ? (
                    <CloseIcon onClick={() => handleMeditationDelete(id, name)} style={{ cursor: 'pointer' }} />
                  ) : (
                    ''
                  )}
                </TodaysCheck>
              );
            })}
          </>
        ) : (
          ''
        )}
      </Container>
      {showMeditationDetailsModal && (
        <MeditationDetailsModal
          checkDate={checkDate}
          currentMeditation={currentMeditation}
          meditationDetailsClose={() => setShowMeditationDetailsModal(false)}
        />
      )}
    </>
  );
};

export default MeditationTrack;
