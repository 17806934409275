/*
 * action.js file for forgot password views
 * Author:
 * 1. Manikanta
 *
 * @copyright of Panorbit Services LLP.
 */

import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  verifyEmail: ['payload'],
  verifyEmailStart: ['payload'],
  verifyEmailSuccess: ['payload'],
  verifyEmailFailure: ['payload'],

  setUserEmail: ['payload'],
  resetData: null
});

export { Types, Creators };
