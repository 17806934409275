import styled from 'styled-components';
import { backgroundColor, fontSize, textColors } from '../../constants';

export const ManinContainer = styled.div`
  position: relative;
`;

export const Container = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  position: relative;
`;

export const Label = styled.p`
  color: ${textColors.textLight};
  font-size: ${fontSize.mediumDefault};
  margin-bottom: 1rem;
  height: 16px;
`;

export const Input = styled.input`
  width: 100%;
  height: 46px;

  padding: 1rem 1.5rem;
  font-size: ${fontSize.mediumDefault};

  color: ${textColors.textWhite};
  border: none;

  border-radius: ${(props) => (props.isActive ? '0.6rem 0.6rem 0 0' : '0.6rem 0.6rem 0.6rem 0.6rem')};
  background-color: ${backgroundColor.darkOpaq};

  &:focus {
    outline: none;
  }

  &::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }

  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }

  &:-internal-autofill-selected {
    appearance: initial !important;
    background-color: ${backgroundColor.darkOpaq} !important;

    color: ${textColors.textWhite} !important;
  }
`;

export const DropdownContainer = styled.div`
  height: 150px;
  width: 100%;
  overflow-y: auto;
  padding: 0.5rem;

  position: absolute;
  top: 72px;

  background: #221e2a;
  border-radius: 0 0 0.6rem 0.6rem;
  z-index: 999;
`;

export const Thumbnail = styled.img`
  width: 88px;
  height: 48px;

  border-radius: 0.6rem;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const Title = styled.p`
  font-size: ${fontSize.mediumDefault};
  padding: 0 1rem;
`;
