/*
 * action.js file for skills view
 * Author:
 * 1.Manikanta
 *
 * @copyright of Panorbit Services LLP.
 */

import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getPlayerVideosList: ['payload'],
  getPlayerVideosListStart: ['payload'],
  getPlayerVideosListSuccess: ['payload'],
  getPlayerVideosListFailure: ['payload'],

  getPlayerCollectionsList: ['payload'],
  getPlayerCollectionsListStart: ['payload'],
  getPlayerCollectionsListSuccess: ['payload'],
  getPlayerCollectionsListFailure: ['payload'],

  getPlayerCollectionVideosList: ['payload'],
  getPlayerCollectionVideosListStart: ['payload'],
  getPlayerCollectionVideosListSuccess: ['payload'],
  getPlayerCollectionVideosListFailure: ['payload'],

  searchPlayerVideo: ['payload'],
  searchPlayerVideoStart: ['payload'],
  searchPlayerVideoSuccess: ['payload'],
  searchPlayerVideoFailure: ['payload'],

  setShowManageCollectionModal: ['payload'],
  setShowCreateCollectionModal: ['payload'],
  setShowEditCollectionModal: ['payload'],
  setShowDeleteCollectionModal: ['payload'],
  setShowAddToAnotherCollectionModal: ['payload'],
  setCurrentCollectionId: ['payload'],

  setPlayerCollectionName: ['payload'],
  setPlayerCollectionNameStart: ['payload'],
  setPlayerCollectionNameSuccess: ['payload'],
  setPlayerCollectionNameFailure: ['payload'],

  deletePlayerCollection: ['payload'],
  deletePlayerCollectionStart: ['payload'],
  deletePlayerCollectionSuccess: ['payload'],
  deletePlayerCollectionFailure: ['payload'],

  addToAnotherCollection: ['payload'],
  addToAnotherCollectionStart: ['payload'],
  addToAnotherCollectionSuccess: ['payload'],
  addToAnotherCollectionFailure: ['payload'],

  skillsReset: ['payload'],

  createCollection: ['payload'],

  uploadVideo: ['payload'],
  uploadVideoStart: ['payload'],
  uploadVideoSuccess: ['payload'],
  uploadVideoFailure: ['payload'],
  resetSuccess: null,
  resetError: null,
  resetReload: null
});

export { Types, Creators };
