/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// styles
import { Container, LinkItem, HomeLink, Help, ToggleButton } from './CoachSideNav';

// icons
import { ReactComponent as HomeIcon } from '../../assets/Home.svg';
import { ReactComponent as MindFulIcon } from '../../assets/Mindful.svg';
import { ReactComponent as SCIcon } from '../../assets/S&C.svg';
import { ReactComponent as SkillIcon } from '../../assets/Skill.svg';
import { ReactComponent as NutritionIcon } from '../../assets/Nutrition.svg';
import { ReactComponent as HelpIcon } from '../../assets/Help.svg';
import { ReactComponent as Collapse } from '../../assets/Hamburger_collapse_menu.svg';
import { ReactComponent as Expand } from '../../assets/Hamburger_expand_menu.svg';
import { ReactComponent as PlayersIcon } from '../../assets/players.svg';
import { localString } from '../../../services/locallization/localStrings';
// component
const CoachSidenav = (props) => {
  const [active, setactive] = useState(true);
  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  return (
    <Container collapse={active}>
      <ToggleButton onClick={() => setactive(!active)}>{active ? <Collapse /> : <Expand />}</ToggleButton>

      <HomeLink className={props.isExact ? 'active' : ''} to='/coach/'>
        <HomeIcon />
        {active ? string.home : ''}
      </HomeLink>

      <LinkItem to='/coach/players'>
        <PlayersIcon />
        {active ? string.players : ''}
      </LinkItem>

      <LinkItem to='/coach/skills'>
        <SkillIcon />
        {active ? string.skill : ''}
      </LinkItem>

      <LinkItem to='/coach/Strength'>
        <SCIcon />
        {active ? string.strength : ''}
      </LinkItem>

      <LinkItem to='/coach/nutrition'>
        <NutritionIcon />
        {active ? string.nutrition : ''}
      </LinkItem>

      <LinkItem to='/coach/mindset'>
        <MindFulIcon />
        {active ? string.mindset : ''}
      </LinkItem>

      {/* <Help to='/coach/help-and-support'>
        <HelpIcon />
        {active ? 'string.helpNsupport' : ''}
      </Help> */}
    </Container>
  );
};

export default CoachSidenav;
