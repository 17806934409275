import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
`;

export const NewPasswordWrapper = styled.div`
  display: flex;
  gap: 2rem;
`;

export const Column = styled.div`
  width: ${(props) => props.wdth};
`;
