/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Creators } from './store';

import {
  ContainerComponent,
  InputComponent,
  DropdownComponent,
  ButtonComponent,
  ToggleSwitch
} from '../../globalShared';

import {
  Title,
  SubHeading,
  Layout,
  Width50,
  TogglerContainer,
  Divider,
  FlexContainer,
  ErrorMsg,
  Footer,
  Timer,
  Dash
} from './ProfessionalDetails.styles';

import Files from '../../shared/components/AttachmentsListComponent';
import { mealTypes } from '../../shared/constants/lists';

// eslint-disable-next-line no-unused-vars
const { Row } = Layout;

const defaultValues = {
  injuries: [
    {
      file: '',
      name: '',
      description: ''
    }
  ],
  health: [
    {
      file: '',
      name: ''
    }
  ]
};

/**
 *
 * component
 */
const PersonalDetailsView = (props) => {
  // STATE values
  const professionalDetails = useSelector((state) => state.professionalDetails);

  const dispatch = useDispatch();
  // prop functions
  const { backButtonAction, submitButtonAction } = props;

  // hook form
  // eslint-disable-next-line no-unused-vars
  const { control, register, handleSubmit, getValues, errors, setValue } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { ...professionalDetails }
  });

  const [addInjury, setAddInjury] = useState(false);
  const [addHealth, setAddHealth] = useState(false);

  const onSubmit = (data) => {
    dispatch(Creators.setProfessionalDetails(data));
    submitButtonAction(data);
  };

  const handleMealType = (selectedvalue) => {
    setValue('mealPreference', selectedvalue.value);
  };

  const handlepracticeStartTimeMeredian = (selectedvalue) => {
    setValue('practiceStartTimeMeredian', selectedvalue.value);
  };
  const handlepracticeEndTimeMeredian = (selectedvalue) => {
    setValue('practiceEndTimeMeredian', selectedvalue.value);
  };

  const handletrainingStartTimeMeredian = (selectedvalue) => {
    setValue('trainingStartTimeMeredian', selectedvalue.value);
  };
  const handletrainingEndTimeMeredian = (selectedvalue) => {
    setValue('trainingEndTimeMeredian', selectedvalue.value);
  };

  const toggleInjury = (value) => {
    setAddInjury(value);
  };

  const toggleHealth = (value) => {
    setAddHealth(value);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ContainerComponent bgType='dark'>
          <Title>2. Professional Details</Title>
          <SubHeading>Fill the details to proceed</SubHeading>

          <Row>
            <TogglerContainer>
              <p>Injury History</p>
              <ToggleSwitch isChecked={addInjury} disabled={false} onChangeHandler={toggleInjury} />
            </TogglerContainer>
          </Row>

          {addInjury ? (
            <>
              <Row>
                <Files fieldName='injuries' {...{ control, register, defaultValues, getValues, setValue, errors }} />
              </Row>
              <ErrorMsg>{errors.injuries?.length ? 'All fields are required' : ''}</ErrorMsg>
            </>
          ) : (
            ''
          )}

          <Divider />

          <Row>
            <TogglerContainer>
              <p>Health History</p>
              <ToggleSwitch isChecked={addHealth} disabled={false} onChangeHandler={toggleHealth} />
            </TogglerContainer>
          </Row>
          {addHealth ? (
            <>
              <Row>
                <Files fieldName='health' {...{ control, register, defaultValues, getValues, setValue, errors }} />
              </Row>
              <ErrorMsg>{errors.health?.length ? 'All fields are required' : ''}</ErrorMsg>
            </>
          ) : (
            ''
          )}

          <Divider />

          <Row>
            <Width50>
              <DropdownComponent
                optionArray={mealTypes}
                label='Meal Preference'
                defaultText='Select'
                selectName='mealPreference'
                defaultValue={{ value: professionalDetails.mealPreference, label: professionalDetails.mealPreference }}
                selectRef={register({ required: 'Required' })}
                onChangeHandler={handleMealType}
              />
              <ErrorMsg style={{ marginTop: '0' }}>{errors.mealPreference?.message}</ErrorMsg>
            </Width50>

            <Width50>
              <InputComponent
                ipType='text'
                placeholderText='Eg: Peanut, Lactose....'
                ipName='foodAllergy'
                label='Food Allergy'
                ipRef={register}
              />
            </Width50>
          </Row>
          <Row>
            <Width50>
              <FlexContainer>
                <div>
                  <FlexContainer>
                    <InputComponent
                      ipType='text'
                      label='Practice Time'
                      ipName='practiceStartTime'
                      placeholderText='HH:MM'
                      ipRef={register({
                        required: 'Required',
                        pattern: { value: /(0[0-9]|1[012]):([0-5][0-9])/, message: 'Required format 09:10' }
                      })}
                    />
                    <Timer>
                      <DropdownComponent
                        optionArray={[
                          { value: 'AM', label: 'AM' },
                          { value: 'PM', label: 'PM' }
                        ]}
                        label=' &nbsp;'
                        defaultText='Select'
                        selectName='practiceStartTimeMeredian'
                        defaultValue={{
                          value: professionalDetails.practiceStartTimeMeredian,
                          label: professionalDetails.practiceStartTimeMeredian
                        }}
                        selectRef={register({ required: true, name: 'practiceStartTimeMeredian' })}
                        onChangeHandler={handlepracticeStartTimeMeredian}
                        position='top'
                      />
                    </Timer>
                  </FlexContainer>
                  <ErrorMsg>{errors.practiceStartTime?.message}</ErrorMsg>
                </div>
                <Dash>&mdash;</Dash>
                <div>
                  <FlexContainer>
                    <InputComponent
                      ipType='text'
                      label=' &nbsp;'
                      ipName='practiceEndTime'
                      placeholderText='HH:MM'
                      ipRef={register({
                        required: 'Required',
                        pattern: { value: /(0[0-9]|1[012]):([0-5][0-9])/, message: 'Required format 09:10' }
                      })}
                    />
                    <Timer>
                      <DropdownComponent
                        optionArray={[
                          { value: 'AM', label: 'AM' },
                          { value: 'PM', label: 'PM' }
                        ]}
                        label=' &nbsp;'
                        defaultText='Select'
                        selectName='practiceEndTimeMeredian'
                        defaultValue={{
                          value: professionalDetails.practiceEndTimeMeredian,
                          label: professionalDetails.practiceEndTimeMeredian
                        }}
                        selectRef={register({ required: true, name: 'practiceEndTimeMeredian' })}
                        onChangeHandler={handlepracticeEndTimeMeredian}
                        position='top'
                      />
                    </Timer>
                  </FlexContainer>
                  <ErrorMsg>{errors.practiceEndTime?.message}</ErrorMsg>
                </div>
              </FlexContainer>
            </Width50>
            <Width50>
              <FlexContainer>
                <div>
                  <FlexContainer>
                    <InputComponent
                      ipType='text'
                      label='Training Time'
                      ipName='trainingStartTime'
                      placeholderText='HH:MM'
                      ipRef={register({
                        required: 'Required',
                        pattern: { value: /(0[0-9]|1[012]):([0-5][0-9])/, message: 'Required format 09:10' }
                      })}
                    />
                    <Timer>
                      <DropdownComponent
                        optionArray={[
                          { value: 'AM', label: 'AM' },
                          { value: 'PM', label: 'PM' }
                        ]}
                        label=' &nbsp;'
                        defaultText='Select'
                        selectName='trainingStartTimeMeredian'
                        defaultValue={{
                          value: professionalDetails.trainingStartTimeMeredian,
                          label: professionalDetails.trainingStartTimeMeredian
                        }}
                        selectRef={register({ required: true, name: 'trainingStartTimeMeredian' })}
                        onChangeHandler={handletrainingStartTimeMeredian}
                        position='top'
                      />
                    </Timer>
                  </FlexContainer>
                  <ErrorMsg>{errors.trainingStartTime?.message}</ErrorMsg>
                </div>
                <Dash>&mdash;</Dash>
                <div>
                  <FlexContainer>
                    <InputComponent
                      ipType='text'
                      label=' &nbsp;'
                      ipName='trainingEndTime'
                      placeholderText='HH:MM'
                      ipRef={register({
                        required: 'Required',
                        pattern: { value: /(0[0-9]|1[012]):([0-5][0-9])/, message: 'Required format 09:10' }
                      })}
                    />
                    <Timer>
                      <DropdownComponent
                        optionArray={[
                          { value: 'AM', label: 'AM' },
                          { value: 'PM', label: 'PM' }
                        ]}
                        label=' &nbsp;'
                        defaultText='Select'
                        selectName='trainingEndTimeMeredian'
                        defaultValue={{
                          value: professionalDetails.trainingEndTimeMeredian,
                          label: professionalDetails.trainingEndTimeMeredian
                        }}
                        selectRef={register({ required: true, name: 'trainingEndTimeMeredian' })}
                        onChangeHandler={handletrainingEndTimeMeredian}
                        position='top'
                      />
                    </Timer>
                  </FlexContainer>
                  <ErrorMsg>{errors.trainingEndTime?.message}</ErrorMsg>
                </div>
              </FlexContainer>
            </Width50>
          </Row>
        </ContainerComponent>
        <Footer>
          <ButtonComponent buttonText='back' isButtonAction={true} buttonClick={backButtonAction} bgType='dark' />

          <ButtonComponent buttonText='Submit' isDisabled={true} buttonType='submit' />
        </Footer>
      </form>
    </>
  );
};

export default PersonalDetailsView;
