/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ManinContainer, Input, Label, Container, DropdownContainer, Thumbnail, Title } from './SearchInputComponent';
import dummyImage from '../../../assets/workout.jpeg';
import { FlexContainer } from '../../../../globalShared/CommonStyles';
import { Muted } from '../../EditUserDetailsComponent/EditUserDetails.styles';
import useTouchOutside from '../../../hooks/useTouchOutside';
import { localString } from '../../../../services/locallization/localStrings';
import { shortenText } from '../../../../services/utility';

const SearchInput = (props) => {
  // values
  const { placeholder, list, imageType = false, label, value, focus = false, position = 'bottom' } = props;
  let { name = 'input' } = props;

  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  // functions
  const { onChange, setValue } = props;
  const [show, setshow] = useState(true);
  const [searchValue, setSearchValue] = useState('');

  const Reff = useRef();
  name = useRef();

  useTouchOutside(Reff, () => {
    setshow(false);
  });

  const handleSearchChange = ({ target }) => {
    onChange(target.value);
    setSearchValue(target.value);
  };

  const handleBlur = () => {
    setSearchValue('');
  };

  useEffect(() => {
    if (focus) {
      name.current.focus();
    }
  }, [focus]);

  return (
    <ManinContainer ref={Reff}>
      <Container>
        {label && <Label>{label}</Label>}
        {value?.title ? (
          <Muted>{shortenText(value.title, 30, '...')}</Muted>
        ) : (
          <Input
            isActive={list.length && show}
            type='search'
            placeholder={placeholder}
            onChange={handleSearchChange}
            onFocus={() => setshow(true)}
            value={searchValue}
            onBlur={handleBlur}
            ref={name}
            position={position}
          />
        )}
      </Container>
      {searchValue && !list.length && (
        <DropdownContainer label={label} position={position}>
          <Title style={{ margin: '1rem auto', textAlign: 'center', fontSize: '16px' }}>
            {`' ${searchValue} ' ${string.notFound}`}
          </Title>
        </DropdownContainer>
      )}
      {show ? (
        list.length && !value?._id ? (
          <DropdownContainer label={label} position={position}>
            {list.map((item) => (
              <FlexContainer
                key={item._id}
                style={{ padding: '1rem', cursor: 'pointer' }}
                onClick={() => {
                  setValue(item);
                  setSearchValue('');
                }}
              >
                {imageType && <Thumbnail src={item.thumbUrl || dummyImage} />}
                <Title>{shortenText(item.title, 30, '...')}</Title>
              </FlexContainer>
            ))}
          </DropdownContainer>
        ) : (
          ''
        )
      ) : (
        ''
      )}
    </ManinContainer>
  );
};

export default SearchInput;
