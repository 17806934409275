import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { Creators as LoginCreators } from './views/auth/LoginView/store';

import Routes from './services/router/Routes';
import LoginRedirect from './services/router/loginRedirect';
import { cookie } from './services/cookies/cookieServices';
import { WebService } from './services/axios/webServices';
import { EmptyContentText } from './globalShared/CommonStyles';
import { LoadingComponent } from './globalShared';

//
function App() {
  const dispatch = useDispatch();
  const [userDetails] = useState(cookie.get('userDetails'));
  const accessToken = useSelector((state) => state.login.accessToken);
  const userType = useSelector((state) => state.login.userType);
  const loadingMetaData = useSelector((state) => state.login.loadingMetaData);
  const headerToken = WebService.defaults.headers.common.Authorization;

  const location = useLocation();

  useEffect(() => {
    if (userDetails) {
      // eslint-disable-next-line no-shadow
      const { accessToken } = userDetails;

      if (accessToken) {
        dispatch(LoginCreators.userMetaData(userDetails));
      }
    }
  }, [userDetails]);

  if (loadingMetaData) {
    return (
      <EmptyContentText>
        <LoadingComponent />
      </EmptyContentText>
    );
  }

  if (!accessToken && !userType && !userDetails) {
    return (
      <>
        <Routes access={null} userType={null} />
        <Redirect to='/' />
      </>
    );
  }

  return (
    <>
      <Routes access={accessToken || headerToken} userType={userType} />
      <LoginRedirect
        userType={userDetails?.userType || null}
        path={location.pathname}
        metaUpdated={userDetails?.metaUpdated}
        passwordChanged={userDetails?.passwordChanged}
      />
    </>
  );
}

export default App;
