/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Creators } from './store';
import { ReactComponent as UploadIcon } from '../../shared/assets/upload.svg';
import { ReactComponent as DeleteIcon } from '../../shared/assets/delete.svg';
import { cookie } from '../../services/cookies/cookieServices';

import {
  ContainerComponent,
  InputComponent,
  RadioGroupComponent,
  TextareaComponent,
  DropdownComponent,
  ButtonComponent
} from '../../globalShared';

import {
  Title,
  SubHeading,
  Layout,
  Width50,
  FlexContainer,
  DashedContainer,
  InputContainer,
  FileInput,
  PictureContainer,
  ButtonContainer,
  ErrorMsg,
  Footer
} from './PersonalDetails.styles';
import { levelMap, trainingAgeMap } from '../../services/utility/maps';

const { Row, Column } = Layout;

const levels = [
  { value: 'level1', label: 'Under 14' },
  { value: 'level2', label: 'Under 17' },
  { value: 'level3', label: 'Senior' }
];

const trainingAge = [
  { value: 'level1', label: 'Lesser Than 1 Year' },
  { value: 'level2', label: 'Between 1 And 3 Years' },
  { value: 'level3', label: 'Greater Than 3 Years' }
];

const feet = [
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 }
];

const inches = [
  { value: '0', label: '0' },
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 },
  { value: 11, label: 11 }
];

const PersonalDetailsView = (props) => {
  // state Values
  const personalDetails = useSelector((state) => state.personalDetails);

  const [userDetails] = useState(cookie.get('userDetails'));

  const { register, handleSubmit, errors, setValue, clearErrors } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...personalDetails,
      fName: userDetails?.firstName,
      lName: userDetails?.lastName,
      email: userDetails?.email
    }
  });

  const { buttonAction } = props;
  const dispatch = useDispatch();

  const [urlLocal, setUrlLocal] = useState('');

  const [isImage, setIsImage] = useState(false);
  const [profilePicture, setProfilePic] = useState('');

  const onSubmit = (data) => {
    const payload = { ...data, profilePicture };
    dispatch(Creators.setPersonalDetails(payload));
    buttonAction(payload);
  };

  const handlelevel = (selectedvalue) => {
    setValue('level', selectedvalue.value);
    clearErrors('level');
  };

  const handleTrainingAge = (selectedvalue) => {
    setValue('trainingAge', selectedvalue.value);
    clearErrors('trainingAge');
  };

  const handleFeet = (selectedvalue) => {
    setValue('feet', selectedvalue.value);
    clearErrors('feet');
  };

  const handleInch = (selectedvalue) => {
    setValue('inches', selectedvalue.value);
    clearErrors('inches');
  };

  const modifyUrlLocal = (url, isFileImage, imgFile) => {
    if (url !== null) {
      setUrlLocal(url);
      setIsImage(isFileImage);
    }
    setIsImage(isFileImage);
    setUrlLocal(url);
  };

  const handleFileChange = (event) => {
    const imgFile = event.target.files[0];
    if (!imgFile) return;

    setProfilePic(imgFile); //  set  file object to state

    // creates a local url for uploaded image to preview
    // eslint-disable-next-line no-undef
    const URL = window.URL || window.webkitURL;
    const url = URL.createObjectURL(imgFile);

    modifyUrlLocal(url, true, imgFile);
  };

  // console.log(errors);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ContainerComponent bgType='dark'>
        <Title>1. Personal Details</Title>
        <SubHeading>Fill in the mandatory details to proceed</SubHeading>

        <Row>
          <Column>
            <DashedContainer isImage={isImage}>
              {!isImage ? (
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label htmlFor='profileImage'>
                  <FileInput
                    type='file'
                    name='profileImage'
                    data-type='file'
                    id='profileImage'
                    ref={register}
                    accept='image/x-png,image/gif,image/jpeg'
                    onChange={handleFileChange}
                  />

                  <InputContainer>
                    <UploadIcon />
                    Upload your photo (optional)
                  </InputContainer>
                </label>
              ) : (
                <>
                  <PictureContainer style={{ backgroundImage: `url(${urlLocal})` }} />
                  <ButtonContainer onClick={() => modifyUrlLocal()}>
                    <DeleteIcon />
                  </ButtonContainer>
                </>
              )}
            </DashedContainer>
          </Column>
        </Row>

        <Row>
          <Column>
            <InputComponent
              ipType='text'
              placeholderText='First Name'
              ipName='fName'
              label='First Name'
              ipRef={register({ required: 'Required' })}
            />
            <ErrorMsg>{errors.fName?.message}</ErrorMsg>
          </Column>
          <Column>
            <InputComponent
              ipType='text'
              placeholderText='Last Name'
              ipName='lName'
              label='Last Name'
              ipRef={register({ required: 'Required' })}
            />
            <ErrorMsg>{errors.lName?.message}</ErrorMsg>
          </Column>
          <Column>
            <InputComponent
              ipType='email'
              placeholderText='Email'
              ipName='email'
              label='Email Address'
              disabled={true}
              ipRef={register({ required: false })}
            />
            <ErrorMsg>{errors.email?.message}</ErrorMsg>
          </Column>
        </Row>

        <Row>
          <Column>
            <InputComponent
              ipType='tel'
              placeholderText='Enter Phone Number'
              ipName='phoneNumber'
              label=' Phone Number'
              ipRef={register({
                required: 'Required',
                minLength: { value: 10, message: 'The number shuold contain 10 digits ' },
                maxLength: { value: 10, message: 'The number shuold contain 10 digits ' },
                pattern: { value: /^[0-9]*$/, message: 'Should contain only numbers' }
              })}
            />
            <ErrorMsg>{errors.phoneNumber?.message}</ErrorMsg>
          </Column>
          <Column>
            <FlexContainer>
              <Width50>
                <InputComponent
                  ipType='text'
                  placeholderText='age'
                  ipName='age'
                  label=' Age'
                  ipRef={register({
                    required: 'Required',
                    pattern: { value: /^[0-9]*$/, message: 'Numbers Only' }
                  })}
                />
                <ErrorMsg>{errors.age?.message}</ErrorMsg>
              </Width50>

              <Width50>
                <RadioGroupComponent
                  label='Gender'
                  radioGroupArray={['M', 'F']}
                  radioGroupName='gender'
                  radioRef={register({ required: 'Select the gender' })}
                />
                <ErrorMsg style={{ marginTop: '0' }}>{errors.gender?.message}</ErrorMsg>
              </Width50>
            </FlexContainer>
          </Column>
          <Column>
            <FlexContainer>
              <Width50>
                <InputComponent
                  ipType='text'
                  placeholderText='Kgs'
                  ipName='weight'
                  label='Weight'
                  ipRef={register({
                    required: userDetails?.userType === 'Player' ? 'Required' : false,
                    pattern: { value: /^[0-9]*$/, message: 'add your weight in numbers' }
                  })}
                />
                <ErrorMsg>{errors.weight?.message}</ErrorMsg>
              </Width50>
              <Width50>
                <FlexContainer>
                  <Width50>
                    <DropdownComponent
                      optionArray={feet}
                      label='Height'
                      defaultText='ft'
                      defaultValue={{ value: personalDetails.feet, label: personalDetails.feet }}
                      selectName='feet'
                      selectRef={register({ required: userDetails?.userType === 'Player' ? 'Required' : false })}
                      onChangeHandler={handleFeet}
                    />
                  </Width50>

                  <Width50>
                    <DropdownComponent
                      optionArray={inches}
                      label=' &nbsp;'
                      defaultText='in'
                      defaultValue={{ value: personalDetails.inches, label: personalDetails.inches }}
                      selectName='inches'
                      selectRef={register({ required: userDetails?.userType === 'Player' ? 'Required' : false })}
                      onChangeHandler={handleInch}
                    />
                  </Width50>
                </FlexContainer>
                <ErrorMsg style={{ marginTop: '0' }}>{errors.feet || errors.inches ? 'Required' : ''}</ErrorMsg>
              </Width50>
            </FlexContainer>
          </Column>
        </Row>

        {userDetails?.userType === 'Player' ? (
          <Row>
            <Column>
              <div>
                <DropdownComponent
                  optionArray={levels}
                  label='Level'
                  defaultText='Select'
                  selectName='level'
                  defaultValue={{ value: personalDetails.level, label: levelMap[personalDetails.level] }}
                  selectRef={register({
                    required: userDetails?.userType === 'Player' ? 'Required' : false
                  })}
                  onChangeHandler={handlelevel}
                />
                <ErrorMsg style={{ marginTop: '3px' }}>{errors.level?.message}</ErrorMsg>
              </div>

              <div style={{ marginTop: '2rem' }}>
                <DropdownComponent
                  optionArray={trainingAge}
                  label='Training Age'
                  defaultText='Number of Years Trained'
                  selectName='trainingAge'
                  defaultValue={{
                    value: personalDetails.trainingAge,
                    label: trainingAgeMap[personalDetails.trainingAge]
                  }}
                  selectRef={register({ required: 'Required' })}
                  onChangeHandler={handleTrainingAge}
                  position='top'
                />
                <ErrorMsg style={{ marginTop: '3px' }}>{errors.trainingAge?.message}</ErrorMsg>
              </div>
            </Column>
            <Column>
              <TextareaComponent
                placeholderText='Enter a description about yourself here...'
                ipName='about'
                label='About ( optional )'
                ipRef={register}
              />
            </Column>
            <Column>
              <TextareaComponent
                placeholderText='Enter your goals here...'
                ipName='goals'
                label='Goals ( optional )'
                ipRef={register}
              />
            </Column>
          </Row>
        ) : (
          <Row style={{ width: '66%' }}>
            <Width50>
              <TextareaComponent
                placeholderText='Enter a description about yourself here...'
                ipName='about'
                label='About ( optional )'
                ipRef={register}
              />
            </Width50>
            <Width50>
              <TextareaComponent
                placeholderText='Enter your goals here...'
                ipName='goals'
                label='Goals ( optional )'
                ipRef={register}
              />
            </Width50>
          </Row>
        )}
      </ContainerComponent>

      <Footer>
        {userDetails?.userType !== 'Player' ? (
          <ButtonComponent buttonText='Submit' buttonAction={false} buttonType='submit' />
        ) : (
          <ButtonComponent buttonText='Next' isDisabled={true} buttonType='submit' />
        )}
      </Footer>
    </form>
  );
};

export default PersonalDetailsView;
