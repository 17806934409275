import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  loading: false,
  error: null,
  success: false,
  details: {
    players: '',
    admins: '',
    coaches: '',
    users: '',
    bannerImage: ''
  }
};

const getAdminomeDetailsStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getAdminomeDetailsSuccess = (state = INITIAL_STATE, action) => {
  const { details } = action.payload;

  return {
    ...state,
    loading: false,
    details
  };
};

const getFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    readinessLoading: false,
    error
  };
};

const HANDLERS = {
  [Types.GET_ADMIN_HOME_DETAILS_START]: getAdminomeDetailsStart,
  [Types.GET_ADMIN_HOME_DETAILS_SUCCESS]: getAdminomeDetailsSuccess,
  [Types.GET_FAILURE]: getFailure
};

export const adminHomeReducer = createReducer(INITIAL_STATE, HANDLERS);
