import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getSelectedGroup: ['payload'],
  setPlayersModal: ['payload'],
  setHistoryModal: ['payload'],
  setAddGroupModal: ['payload'],
  setHistoryModalData: ['payload'],
  getGroupPlayersSearchList: ['payload'],
  getGroupPlayersSearchListStart: ['payload'],
  getGroupPlayersSearchListSuccess: ['payload'],
  getGroupPlayersSearchListFailure: ['payload'],
  getAddGroup: ['payload'],
  getAddGroupStart: ['payload'],
  getAddGroupSuccess: ['payload'],
  getAddGroupFailure: ['payload'],
  editDeleteGroup: ['payload'],
  editDeleteGroupStart: ['payload'],
  editDeleteGroupSuccess: ['payload'],
  editDeleteGroupFailure: ['payload'],

  searchReset: null,
  resetReload: null,
  resetSuccess: null,
  resetError: null
});

export { Types, Creators };
