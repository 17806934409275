/* eslint-disable no-shadow */
import React from 'react';
import { Tooltip, TooltipWrapper } from './Tooltip.styles';

import { ReactComponent as Edit } from '../../assets/edit.svg';
import { ReactComponent as Delete } from '../../assets/delete.svg';
import { ReactComponent as Add } from '../../assets/add_session.svg';
import { ReactComponent as Manage } from '../../assets/manage.svg';
import { ReactComponent as Close } from '../../assets/Close.svg';
import { ReactComponent as Clear } from '../../assets/Clear.svg';
import { ReactComponent as Pen } from '../../assets/edit2.svg';
import { ReactComponent as Duplicate } from '../../assets/duplicate.svg';

const TooltipComponent = (props) => {
  const { title, onClick, position, type } = props;

  const renderIcon = (type) => {
    if (type === 'edit') {
      return <Edit />;
    }

    if (type === 'delete') {
      return <Delete />;
    }

    if (type === 'add') {
      return <Add style={{ height: '15px' }} />;
    }

    if (type === 'manage') {
      return <Manage />;
    }

    if (type === 'close') {
      return <Close />;
    }

    if (type === 'clear') {
      return <Clear />;
    }

    if (type === 'edit2') {
      return <Pen />;
    }

    if (type === 'duplicate') {
      return <Duplicate />;
    }

    return null;
  };

  return (
    <TooltipWrapper onClick={onClick}>
      {renderIcon(type)}
      <Tooltip position={position || 'top'}>{title}</Tooltip>
    </TooltipWrapper>
  );
};

export default TooltipComponent;
