/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from './store';

import { Header, CardContainer, CardsContainer } from './HomeStyles';

import { Creators as SleepCreators } from '../MindsetView/store';
import { GreetingComponent, MasonryLayout, ScroreCardComponent } from '../../../globalShared';
import Card from '../../../shared/components/ChartCardsComponent';

import Readiness from './Readiness';
import StrengthConditioning from './StrengthConditioning';
import SleepQuality from './SleepQuality';
import NutritionAdherence from './NutritionAdherence';
import Hydration from './Hydration';
import PlayerMoodTracker from '../../../shared/components/Charts/MoodTracker';

const options = [
  { label: 'Weekly', value: 7, dataType: 'days' },
  { label: 'Monthly', value: 30, dataType: 'days' },
  { label: '6 Months', value: 180, dataType: 'month' }
];

const options2 = [
  { label: 'Weekly', value: 'weeks' },
  { label: 'Monthly', value: 'months' }
];

const HomeView = () => {
  const [strength, setstrength] = useState(options2[0]);
  const [readiness, setreadiness] = useState(options2[0]);
  const [sleep, setsleep] = useState(options[1]);
  const [nutrition, setnutrition] = useState(options[1]);
  const [Hydrate, setHydration] = useState(options[1]);
  const [Mood, setMood] = useState(options[1]);

  const dispatch = useDispatch();

  const _Readiness = useSelector((state) => state.playerHome.readiness);
  const _Strength = useSelector((state) => state.playerHome.strength);
  const nutritionStats = useSelector((state) => state.playerHome.nutritionStats);
  const _SleepStats = useSelector((state) => state.playerMindset.sleepStats);
  const moodStats = useSelector((state) => state.playerHome.moodStats);
  const hydrationStats = useSelector((state) => state.playerHome.hydrationStats);
  const professionalDetail = useSelector((state) => state.login.professionalDetail);

  useEffect(() => {
    dispatch(Creators.getPlayerHomeStrength({ dataType: strength.value }));
  }, [strength]);

  useEffect(() => {
    dispatch(Creators.getPlayerReadiness({ dataType: readiness.value }));
  }, [readiness]);

  useEffect(() => {
    dispatch(SleepCreators.getPlayerSleepStats({ page: 1, count: 30, dataType: sleep.dataType, days: sleep.value }));
  }, [sleep]);

  useEffect(() => {
    dispatch(Creators.getPlayerHomeNutrition({ dataType: nutrition.dataType, days: nutrition.value }));
  }, [nutrition]);

  useEffect(() => {
    dispatch(Creators.getPlayerHomeHydration({ dataType: Hydrate.dataType, days: Hydrate.value }));
  }, [Hydrate]);

  useEffect(() => {
    dispatch(Creators.getPlayerHomeMoodStats({ days: Mood.value }));
  }, [Mood]);

  return (
    <>
      <Header>
        <div>
          <GreetingComponent />
        </div>

        <div>
          <ScroreCardComponent score={professionalDetail?.tcrScore} percent={professionalDetail?.percentile} />
        </div>
      </Header>
      <CardsContainer>
        <MasonryLayout columns={3} gap={10}>
          {/* Readiness Chart */}
          <CardContainer>
            <Card
              title='Readiness'
              optionArray={options2}
              selected={readiness}
              onClick={(value) => setreadiness(value)}
            >
              <Readiness {..._Readiness} />
            </Card>
          </CardContainer>
          {/* Strength & Conditioning Chart */}

          <CardContainer>
            <Card
              title='Strength & Conditioning'
              optionArray={options2}
              selected={strength}
              onClick={(value) => setstrength(value)}
            >
              <StrengthConditioning {..._Strength} />
            </Card>
          </CardContainer>
          {/* Quality of sleep Charts */}

          <CardContainer>
            <Card
              title='Quality of Sleep'
              optionArray={options}
              selected={sleep}
              onClick={(value) => setsleep(value)}
              label='Average Sleep'
              border='#21d0b39c'
              bgc='#21d0b357'
              showLabel={true}
            >
              <SleepQuality sleepStats={_SleepStats} days={sleep.value} type={sleep.dataType} />
            </Card>
          </CardContainer>
          {/* Nutrition adherence Charts */}

          <CardContainer>
            <Card
              title='Nutrition Adherence'
              optionArray={options}
              selected={nutrition}
              onClick={(value) => setnutrition(value)}
              label='Dates'
              showLabel={true}
            >
              <NutritionAdherence nutrition={nutrition} nutritionStats={nutritionStats} />
            </Card>
          </CardContainer>
          {/* Hydration Charts */}

          <CardContainer>
            <Card
              title='Hydration'
              optionArray={options}
              selected={Hydrate}
              onClick={(value) => setHydration(value)}
              label='Average Water Intake'
              border='#21d0b39c'
              bgc='#21d0b357'
              showLabel={true}
            >
              <Hydration Hydrate={Hydrate} hydrationStats={hydrationStats} />
            </Card>
          </CardContainer>
          {/* Mood tracker Charts */}

          <CardContainer>
            <Card
              title='Mood Tracker'
              optionArray={options}
              selected={Mood}
              onClick={(value) => setMood(value)}
              showLabel={true}
            >
              <PlayerMoodTracker {...moodStats} />
            </Card>
          </CardContainer>
        </MasonryLayout>
      </CardsContainer>
    </>
  );
};

export default HomeView;
