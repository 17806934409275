/* eslint-disable no-unused-vars */
import { createReducer } from 'reduxsauce';
import { Types } from './action';
import { mergeArray } from '../../../../services/utility';

const INITIAL_STATE = {
  loading: false,
  error: null,
  success: false,
  mealPlanList: [],
  reloadList: false,
  mealPlanId: '',
  page: 1,
  maxPage: 1,
  showCreateMealPlan: false,
  showMealDetailsModal: false,
  totalItems: 0,
  searchKey: ''
};

const getCoachMealPlanListStart = (state = INITIAL_STATE, action) => ({ ...state, error: null, loading: true });

const getCoachMealPlanListSuccess = (state = INITIAL_STATE, action) => {
  const { mealPlanList, totalItems } = action.payload;

  return {
    ...state,
    loading: false,
    reloadList: false,
    mealPlanList: [...mealPlanList],
    totalItems
  };
};

const getCoachMealPlanListFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const createMealPlanStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true, reloadList: false });

const createMealPlanSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: false,
  // page: 1,
  reloadList: true,
  success: true
});

const createMealPlanFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const deleteCoachMealPlanStart = (state = INITIAL_STATE) => ({ ...state, loading: true });

const deleteCoachMealPlanSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  // page: 1,
  reloadList: true,
  success: true
});

const deleteCoachMealPlanFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const setMealPlanDetailsId = (state = INITIAL_STATE, action) => {
  const { id } = action.payload;

  return {
    ...state,
    mealPlanId: id
  };
};

const setShowCreateMealPlanModal = (state = INITIAL_STATE, action) => {
  const { show } = action.payload;
  return {
    ...state,
    showCreateMealPlan: show
  };
};

const searchCoachMealPlanStart = (state = INITIAL_STATE) => ({
  ...state,
  error: null
});

const searchCoachMealPlanSuccess = (state = INITIAL_STATE, action) => {
  const { mealPlanList, totalItems } = action.payload;
  return {
    ...state,
    mealPlanList,
    totalItems
  };
};

const searchCoachMealPlanFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    error
  };
};

const mealPlanReset = () => ({ ...INITIAL_STATE });

const setShowMealPlanDetailsModal = (state = INITIAL_STATE, action) => {
  const { show } = action.payload;
  return {
    ...state,
    showMealDetailsModal: show
  };
};

const resetSuccess = (state = INITIAL_STATE) => ({ ...state, success: false });
const resetError = (state = INITIAL_STATE) => ({ ...state, error: null });

const duplicateCoachMealPlanStart = (state = INITIAL_STATE) => ({ ...state, loading: true });

const duplicateCoachMealPlanSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  reloadList: true,
  // page: 1,
  success: true
});

const duplicateCoachMealPlanFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const setMealPlanSearchKey = (state = INITIAL_STATE, action) => {
  const { searchKey } = action.payload;
  return {
    ...state,
    searchKey,
    page: 1
  };
};

const setMealPlanPage = (state = INITIAL_STATE, action) => {
  const { page } = action.payload;
  return {
    ...state,
    page
  };
};

const HANDLERS = {
  [Types.GET_COACH_MEAL_PLAN_LIST_START]: getCoachMealPlanListStart,
  [Types.GET_COACH_MEAL_PLAN_LIST_SUCCESS]: getCoachMealPlanListSuccess,
  [Types.GET_COACH_MEAL_PLAN_LIST_FAILURE]: getCoachMealPlanListFailure,

  [Types.CREATE_MEAL_PLAN_START]: createMealPlanStart,
  [Types.CREATE_MEAL_PLAN_SUCCESS]: createMealPlanSuccess,
  [Types.CREATE_MEAL_PLAN_FAILURE]: createMealPlanFailure,

  [Types.DELETE_COACH_MEAL_PLAN_START]: deleteCoachMealPlanStart,
  [Types.DELETE_COACH_MEAL_PLAN_SUCCESS]: deleteCoachMealPlanSuccess,
  [Types.DELETE_COACH_MEAL_PLAN_FAILURE]: deleteCoachMealPlanFailure,

  [Types.SEARCH_COACH_MEAL_PLAN_START]: searchCoachMealPlanStart,
  [Types.SEARCH_COACH_MEAL_PLAN_SUCCESS]: searchCoachMealPlanSuccess,
  [Types.SEARCH_COACH_MEAL_PLAN_FAILURE]: searchCoachMealPlanFailure,

  [Types.DUPLICATE_COACH_MEAL_PLAN_START]: duplicateCoachMealPlanStart,
  [Types.DUPLICATE_COACH_MEAL_PLAN_SUCCESS]: duplicateCoachMealPlanSuccess,
  [Types.DUPLICATE_COACH_MEAL_PLAN_FAILURE]: duplicateCoachMealPlanFailure,

  [Types.SET_MEAL_PLAN_DETAILS_ID]: setMealPlanDetailsId,
  [Types.SET_SHOW_CREATE_MEAL_PLAN_MODAL]: setShowCreateMealPlanModal,
  [Types.MEAL_PLAN_RESET]: mealPlanReset,
  [Types.SET_SHOW_MEAL_PLAN_DETAILS_MODAL]: setShowMealPlanDetailsModal,
  [Types.RESET_SUCCESS]: resetSuccess,
  [Types.RESET_ERROR]: resetError,
  [Types.SET_MEAL_PLAN_SEARCH_KEY]: setMealPlanSearchKey,
  [Types.SET_MEAL_PLAN_PAGE]: setMealPlanPage
};

export const coachMealPlanReducer = createReducer(INITIAL_STATE, HANDLERS);
