/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// styles
import { Container, LinkItem, HomeLink, Help, ToggleButton } from './PlayerSideNav';

// icons
import { ReactComponent as HomeIcon } from '../../assets/Home.svg';
import { ReactComponent as MindFulIcon } from '../../assets/Mindful.svg';
import { ReactComponent as SCIcon } from '../../assets/S&C.svg';
import { ReactComponent as SkillIcon } from '../../assets/Skill.svg';
import { ReactComponent as NutritionIcon } from '../../assets/Nutrition.svg';
import { ReactComponent as HelpIcon } from '../../assets/Help.svg';
import { ReactComponent as Collapse } from '../../assets/Hamburger_collapse_menu.svg';
import { ReactComponent as Expand } from '../../assets/Hamburger_expand_menu.svg';
import { localString } from '../../../services/locallization/localStrings';
// component
const PlayerSidenav = (props) => {
  const [active, setactive] = useState(true);

  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  return (
    <Container collapse={active}>
      <ToggleButton onClick={() => setactive(!active)}>{active ? <Collapse /> : <Expand />}</ToggleButton>

      <HomeLink className={props.isExact ? 'active' : ''} to='/player/'>
        <HomeIcon />
        {active ? string.home : ''}
      </HomeLink>

      <LinkItem to='/player/skills'>
        <SkillIcon />
        {active ? string.skills : ''}
      </LinkItem>

      <LinkItem to='/player/Strength'>
        <SCIcon />
        {active ? string.strength : ''}
      </LinkItem>

      <LinkItem to='/player/nutrition'>
        <NutritionIcon />
        {active ? string.nutrition : ''}
      </LinkItem>

      <LinkItem to='/player/mindset'>
        <MindFulIcon />
        {active ? string.mindset : ''}
      </LinkItem>

      {/* <Help to='help-and-support'>
        <HelpIcon />
        {active ? string.helpNsupport : ''}
      </Help> */}
    </Container>
  );
};

export default PlayerSidenav;
