import React from 'react';
import { useSelector } from 'react-redux';
import ButtonComponent from '../IconButtonComponent';
import { ReactComponent as CompareIcon } from '../../assets/Compare.svg';

// styles
import { Wrapper, Count } from './CompareButtonStyles';
import { localString } from '../../../services/locallization/localStrings';

/**
 * component
 */

const CompareButton = (props) => {
  const { list, to, margin } = props;

  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];
  return (
    <Wrapper to={to}>
      {list.length ? <Count>{list.length}</Count> : ''}
      <ButtonComponent bgType='dark' margin={margin || ''}>
        <CompareIcon />
        {string.compareVideo}
      </ButtonComponent>
    </Wrapper>
  );
};

export default CompareButton;
