import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getCoachHomeReadiness: ['payload'],
  getCoachHomeReadinessStart: ['payload'],
  getCoachHomeReadinessSuccess: ['payload'],
  getCoachHomeReadinessFailure: ['payload'],

  getCoachHomeStrength: ['payload'],
  getCoachHomeStrengthStart: ['payload'],
  getCoachHomeStrengthSuccess: ['payload'],
  getCoachHomeStrengthFailure: ['payload'],

  getCoachHomeSleep: ['payload'],
  getCoachHomeSleepStart: ['payload'],
  getCoachHomeSleepSuccess: ['payload'],
  getCoachHomeSleepFailure: ['payload'],

  getCoachHomeNutrition: ['payload'],
  getCoachHomeNutritionStart: ['payload'],
  getCoachHomeNutritionSuccess: ['payload'],
  getCoachHomeNutritionFailure: ['payload'],

  getCoachHomeHydration: ['payload'],
  getCoachHomeHydrationStart: ['payload'],
  getCoachHomeHydrationSuccess: ['payload'],
  getCoachHomeHydrationFailure: ['payload'],

  getCoachHomeStillness: ['payload'],
  getCoachHomeStillnessStart: ['payload'],
  getCoachHomeStillnessSuccess: ['payload'],
  getCoachHomeStillnessFailure: ['payload']
});

export { Types, Creators };
