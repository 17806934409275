import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getCoachStrengthFeedDetails: ['payload'],
  getCoachStrengthFeedDetailsStart: ['payload'],
  getCoachStrengthFeedDetailsSuccess: ['payload'],
  getCoachStrengthFeedDetailsFailure: ['payload'],

  getSessionComments: ['payload'],
  getSessionCommentsStart: ['payload'],
  getSessionCommentsSuccess: ['payload'],
  getSessionCommentsFailure: ['payload'],
  resetComments: ['payload']
});

export { Types, Creators };
