/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable indent */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-indent */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TableStyles, ArrayString, EmptyContentText } from '../../../globalShared/CommonStyles';
import { ReactComponent as MenuIcon } from '../../../shared/assets/3dot_menu.svg';
import { Creators } from './store';
import { Menu } from './PlannerStyles';
import Toast, { Toasts } from '../../../services/toast';
import { PaginationComponent, TooltipComponnet } from '../../../globalShared';
import { getDayMonthYear } from '../../../services/utility/moment';
import { localString } from '../../../services/locallization/localStrings';
import { shortenText } from '../../../services/utility';

const { Table, Tr, Th, Td } = TableStyles;

/**
 * component
 */
const PlannerView = ({ history }) => {
  // const [page, setpage] = useState(1);
  const [count, setCount] = useState(10);
  const [showOptionId, setshowOptionId] = useState(null);

  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  const dispatch = useDispatch();
  const { totalItems, plannerList, reloadList, error, success, loading, searchKey, page } = useSelector(
    (state) => state.coachPlanner
  );

  const deletePlanner = (id) => {
    dispatch(Creators.deleteCoachPlanner({ id }));
  };

  const handleEdit = (item) => {
    dispatch(Creators.setPlannerDetails(item));
    dispatch(Creators.setshowCreatePlanner({ show: true }));
    setshowOptionId(null);
  };

  const handlePage = (page) => {
    dispatch(Creators.setPlannerPage({ page }));
  };

  useEffect(() => {
    if (searchKey) {
      dispatch(
        Creators.searchCoachPlanner({
          count,
          page,
          searchKey
        })
      );
    } else {
      dispatch(Creators.getCoachPlannerList({ page, count }));
    }
  }, [page, count, searchKey]);

  useEffect(() => {
    if (reloadList) {
      searchKey
        ? dispatch(Creators.searchCoachPlanner({ count, page, searchKey }))
        : page === 1
        ? dispatch(Creators.getCoachPlannerList({ page, count }))
        : handlePage(1);
    }
  }, [reloadList]);

  useEffect(() => {
    if (error) {
      const reset = () => dispatch(Creators.resetError());
      Toast.error(error, 2, reset);
    }
  }, [error]);

  useEffect(() => {
    if (success) {
      const reset = () => dispatch(Creators.resetSuccess());
      Toast.success(string.success, 2, reset);
    }
  }, [success]);

  useEffect(
    () => () => {
      dispatch(Creators.resetStrengthPlanner());
    },
    []
  );

  return (
    <>
      <Toasts />
      {plannerList.length ? (
        <>
          <div style={{ height: 'calc(100vh - 211px)', overflowY: 'auto', paddingRight: '10px' }}>
            <Table>
              <thead>
                <tr>
                  <Th style={{ paddingLeft: '2rem' }}>{string.title}</Th>
                  <Th>{string.assignedTo}</Th>
                  <Th>{string.createdBy}</Th>
                  <Th>{string.startDate}</Th>
                  <Th>{string.endDate}</Th>
                  <Th>Actions </Th>
                </tr>
              </thead>
              <tbody>
                {plannerList.map((item) => (
                  <Tr style={{ height: '80px' }} key={item._id}>
                    <Td
                      style={{ maxWidth: '360px', cursor: 'pointer' }}
                      onClick={() => (showOptionId ? null : history.push(`strength/planner/${item._id}`))}
                    >
                      <ArrayString>{shortenText(item.title, 20, '...')}</ArrayString>
                    </Td>
                    <Td>
                      <ArrayString width='120px'>{item.assignedTo}</ArrayString>
                    </Td>
                    <Td style={{ maxWidth: '250px' }}>
                      <ArrayString>{shortenText(item.createdBy, 30, '...')}</ArrayString>
                    </Td>
                    <Td>{getDayMonthYear(item.startDate)}</Td>
                    <Td>{getDayMonthYear(item.endDate)}</Td>
                    <Td style={{ maxWidth: '100px' }}>
                      <TooltipComponnet title='Delete' type='delete' onClick={() => deletePlanner(item._id)} />
                    </Td>
                    {/* <Td style={{ position: 'relative' }}>
                      <MenuIcon onMouseOver={() => setshowOptionId(item._id)} style={{ cursor: 'pointer' }} />
                      <div>
                        <Menu show={showOptionId === item._id} onMouseLeave={() => setshowOptionId(null)}>
                          <div role='presentation' onClick={() => handleEdit(item)}>
                            Edit
                          </div>
                          <div role='presentation' onClick={() => deletePlanner(item._id)}>
                            Delete
                          </div>
                        </Menu>
                      </div>
                    </Td> */}
                  </Tr>
                ))}
              </tbody>
            </Table>
          </div>
          <PaginationComponent
            onChange={(value) => {
              handlePage(value.currentPage);
              setCount(value.count);
            }}
            totalItems={totalItems}
            title={string.planners}
            pageNo={page}
          />
        </>
      ) : (
        !loading && (
          <EmptyContentText style={{ height: 'calc(100vh - 162px)' }}>{string.noDataAvailable}</EmptyContentText>
        )
      )}
    </>
  );
};

export default PlannerView;
