import styled from 'styled-components';
import { borderColor, fontSize, fontWeight, textColors } from '../../constants';

export const CommentContainer = styled.div`
  display: flex;

  padding: 1rem 0;
  border-bottom: 0.5px solid ${borderColor.light};
`;

export const PlayerTitle = styled.h1`
  font-size: ${fontSize.mediumDefault};
  font-weight: ${fontWeight.medium};

  margin-bottom: 0.5rem;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

export const PlayerComment = styled.p`
  color: #cbd1df;
  font-size: calc(${fontSize.mediumDefault} - 0.1rem);
  word-wrap: break-word;
`;

export const PostedAt = styled.p`
  font-size: calc(${fontSize.smallDefault} - 0.1rem);
  color: ${textColors.textLight};
  white-space: nowrap;
`;
