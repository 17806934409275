import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getPlayerDashboardReadiness: ['payload'],
  getPlayerDashboardReadinessStart: ['payload'],
  getPlayerDashboardReadinessSuccess: ['payload'],
  getPlayerDashboardReadinessFailure: ['payload'],

  getPlayerDashboardStrength: ['payload'],
  getPlayerDashboardStrengthStart: ['payload'],
  getPlayerDashboardStrengthSuccess: ['payload'],
  getPlayerDashboardStrengthFailure: ['payload'],

  getPlayerDashboardSleepStats: ['payload'],
  getPlayerDashboardSleepStatsStart: ['payload'],
  getPlayerDashboardSleepStatsSuccess: ['payload'],
  getPlayerDashboardSleepStatsFailure: ['payload'],

  getPlayerDashboardNutrition: ['payload'],
  getPlayerDashboardNutritionStart: ['payload'],
  getPlayerDashboardNutritionSuccess: ['payload'],
  getPlayerDashboardNutritionFailure: ['payload'],

  getPlayerDashboardHydration: ['payload'],
  getPlayerDashboardHydrationStart: ['payload'],
  getPlayerDashboardHydrationSuccess: ['payload'],
  getPlayerDashboardHydrationFailure: ['payload'],

  getPlayerDashboardMoodStats: ['payload'],
  getPlayerDashboardMoodStatsStart: ['payload'],
  getPlayerDashboardMoodStatsSuccess: ['payload'],
  getPlayerDashboardMoodStatsFailure: ['payload']
});

export { Types, Creators };
