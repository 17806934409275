/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import sortArray from 'sort-array';

import Toast, { Toasts } from '../../../services/toast';
import { Creators } from './store';

// components
import {
  SearchBoxComponent,
  DeletePopupComponent,
  EditUserDetailsComponent,
  TabsComponenet,
  IconButtonComponenet,
  LoadingComponent,
  PaginationComponent,
  AvatarComponent
} from '../../../globalShared';

import AddUserView from './AdduserModal';

// styles
import {
  ScrollContainer,
  FlexContainer,
  UserName,
  UserBagde,
  UserActionsContainer,
  TableStyles,
  ActionsContainer,
  MainContainer
} from './Users.styles';

// icons
import { ReactComponent as DeleteIcon } from '../../../shared/assets/delete.svg';
import { ReactComponent as SortIcon } from '../../../shared/assets/sorting.svg';
import { ReactComponent as EditIcon } from '../../../shared/assets/edit.svg';
import { ReactComponent as AdduserIcon } from '../../../shared/assets/Adduser.svg';

import { EmptyContentText } from '../../../globalShared/CommonStyles';
import { localString } from '../../../services/locallization/localStrings';

const { Table, Th, Tr, Td } = TableStyles;

const Tabs = {
  Admin: 'Admins',
  All: 'All',
  player: 'Players',
  Coach: 'Coaches'
};

// Tabs content

/**
 *
 * componnet
 */

const UserView = () => {
  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  // state values
  const usersList = useSelector((state) => state.adminUsers.usersList);
  const reload = useSelector((state) => state.adminUsers.reload);
  const totalCount = useSelector((state) => state.adminUsers.totalCount);
  const success = useSelector((state) => state.adminUsers.success);
  const errorMsg = useSelector((state) => state.adminUsers.error);
  const loading = useSelector((state) => state.adminUsers.loading);

  const [showAddUser, setshowAddUser] = useState(false);

  const [userfilter, setfilter] = useState(string.all);
  const [searchKey, setsearchKey] = useState('');

  const [deleteItem, setDeleteItem] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [editUser, setEditUser] = useState(false);
  const [editUserData, setEditUserData] = useState({ uid: '', firstName: '', lastName: '', email: '' });

  const [count, setCount] = useState(10);
  const [page, setPage] = useState(1);

  const [sortType, setSortType] = useState('');
  const [filteredPlayers, setFilteredPlayers] = useState([]);

  const handleSort = () => {
    const type = sortType === 'asc' ? 'desc' : 'asc';
    setSortType(type);
    const sortPlayers = sortArray(filteredPlayers, {
      by: 'fullName',
      order: type,
      computed: {
        fullName: (item) => item.fullName
      }
    });

    setFilteredPlayers(sortPlayers);
  };
  useEffect(() => {
    setFilteredPlayers(usersList);
  }, [usersList]);
  // dispatch
  const dispatch = useDispatch();

  const deleteUser = (id) => {
    setDeleteItem(true);
    setDeleteItemId(id);
  };

  const popUpCancelFunc = () => {
    setDeleteItemId(null);
    setDeleteItem(false);
  };

  const popUpDeteleFunc = (id) => {
    dispatch(Creators.adminDeleteUser({ id }));
    popUpCancelFunc();
  };

  const editUserDetails = ({ _id, firstName, lastName, email }) => {
    setEditUser(true);
    setEditUserData({ ...editUserData, uid: _id, firstName, lastName, email });
  };

  const cancelEditUserDetails = () => {
    setEditUser(false);
    setEditUserData({ uid: '', firstName: '', lastName: '', email: '' });
    dispatch(Creators.resetUserDetails());
  };

  const submitEditUserDetails = (data) => {
    dispatch(Creators.adminUpdateUserDetails({ ...data }));
    cancelEditUserDetails();
  };

  const handleTabChange = (label) => {
    const lables = {
      Admins: string.admin,
      Coaches: string.coach,
      Players: string.player,
      All: string.all
    };
    setfilter(lables[label]);
    setPage(1);
  };

  const onSearchChange = (searchedValue) => {
    setsearchKey(searchedValue);
    handleTabChange('All');
  };

  useEffect(() => {
    if (!searchKey) {
      dispatch(Creators.getAdminUsersList({ userfilter, page, count }));
    } else {
      dispatch(Creators.getAdminUsersList({ page, count, searchKey }));
    }
  }, [userfilter, page, count, searchKey]);

  useEffect(() => {
    if (reload) {
      searchKey
        ? dispatch(Creators.getAdminUsersList({ page, count, searchKey }))
        : dispatch(Creators.getAdminUsersList({ userfilter, page, count }));
    }
  }, [reload]);

  // hooks to  display toast notifications
  useEffect(() => {
    if (success) {
      const reset = () => dispatch(Creators.resetSuccess());
      Toast.success(`${string.success}`, 2, reset);
    }

    if (errorMsg) {
      const reset = () => dispatch(Creators.resetError());
      Toast.error(errorMsg, 2, reset);
    }
  }, [success, errorMsg]);

  // function to render table
  const _renderData = (data = []) => (
    <>
      {data.length ? (
        <>
          <ScrollContainer>
            <Table>
              <thead>
                <tr>
                  <Th onClick={handleSort} style={{ cursor: 'pointer' }}>
                    {`${string.users} `}
                    <SortIcon />
                  </Th>
                  <Th>{string.email}</Th>
                  <Th>{string.phone}</Th>
                  <Th>{string.actions}</Th>
                </tr>
              </thead>
              <tbody>
                {data.map((user) => {
                  const { _id, userType, fullName, email, phoneNumber, profilePicture } = user;
                  return (
                    <Tr key={_id}>
                      <Td style={{ maxWidth: '300px' }}>
                        <FlexContainer>
                          <AvatarComponent url={profilePicture} />
                          <UserName>{fullName}</UserName>
                          {userfilter === string.all ? (
                            <UserBagde userType={userType}>
                              {userType === string.tcrAdmin ? string.admin : userType}
                            </UserBagde>
                          ) : (
                            ''
                          )}
                        </FlexContainer>
                      </Td>
                      <Td className='word'>{email}</Td>
                      <Td>{phoneNumber}</Td>
                      <Td>
                        <UserActionsContainer>
                          <button
                            type='button'
                            onClick={() => {
                              editUserDetails(user);
                            }}
                          >
                            <EditIcon />
                          </button>

                          <button type='button' onClick={() => deleteUser(_id)}>
                            <DeleteIcon />
                          </button>
                        </UserActionsContainer>
                      </Td>
                    </Tr>
                  );
                })}
              </tbody>
            </Table>
          </ScrollContainer>
          <PaginationComponent
            totalItems={totalCount}
            pageNo={page}
            showCount={false}
            onChange={(value) => {
              setPage(value.currentPage);
              setCount(value.count);
            }}
          />
        </>
      ) : (
        <EmptyContentText style={{ height: 'calc(100vh - 211px)' }}>{string.noUsersFound}</EmptyContentText>
      )}
    </>
  );

  return (
    <>
      {loading && <LoadingComponent />}
      <div>
        <Toasts />
        {deleteItem && deleteItemId ? (
          <DeletePopupComponent
            title={string.deleteUser}
            primaryText={string.want2DeleteUser}
            secondaryText={string.deleteAllTheInfo}
            closePopupFunc={popUpCancelFunc}
            deleteFunc={popUpDeteleFunc}
            itemID={deleteItemId}
          />
        ) : (
          ''
        )}
        {editUser && (
          <EditUserDetailsComponent
            title={string.editUser}
            closePopupFunc={cancelEditUserDetails}
            submitFunc={submitEditUserDetails}
            defaultData={editUserData}
          />
        )}
        <MainContainer>
          <ActionsContainer>
            <FlexContainer>
              <SearchBoxComponent placeHolder={string.searchUsers} onSearchChange={onSearchChange} />
              <IconButtonComponenet isOnClickAction={true} onClick={() => setshowAddUser(true)} margin='0 0 0 1rem'>
                <AdduserIcon />
                {string.addUser}
              </IconButtonComponenet>
            </FlexContainer>
          </ActionsContainer>

          <TabsComponenet getactiveTab={handleTabChange} tab={Tabs[userfilter]}>
            <div label={string.all}>{_renderData(filteredPlayers)}</div>
            <div label={string.admins}>{_renderData(filteredPlayers)}</div>
            <div label={string.coaches}>{_renderData(filteredPlayers)}</div>
            <div label={string.players}>{_renderData(filteredPlayers)}</div>
          </TabsComponenet>
        </MainContainer>
      </div>

      {/*
       *
       *
       * add users slider
       *
       *
       *
       */}
      {showAddUser ? <AddUserView onCancel={() => setshowAddUser(false)} /> : ''}
    </>
  );
};

export default UserView;
