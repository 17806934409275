/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { styles } from './ControllComponent.styles';

// styles
const { Container, ActionContainer, ActionItem } = styles;

class ControllComponent extends React.Component {
  updateControlState = (controlState) => this.props.updateControlState(controlState);

  render() {
    const { controlState, firstItemName, SecondItemName } = this.props;
    return (
      <Container>
        <ActionContainer>
          <ActionItem
            selectedControl={controlState === firstItemName}
            onClick={() => {
              if (controlState !== firstItemName) {
                this.updateControlState(firstItemName);
              }
            }}
          >
            <p>{firstItemName}</p>
          </ActionItem>
          <ActionItem
            selectedControl={controlState === SecondItemName}
            onClick={() => {
              if (controlState !== SecondItemName) {
                this.updateControlState(SecondItemName);
              }
            }}
          >
            <p>{SecondItemName}</p>
          </ActionItem>
        </ActionContainer>
      </Container>
    );
  }
}

export default ControllComponent;
