import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  readinessloading: false,
  strengthloading: false,
  error: null,
  nutritionStats: [],
  hydrationStats: [],
  moodStats: {},
  reload: false,
  success: false,
  readiness: null,
  strength: null
};

const getPlayerReadinessStart = (state = INITIAL_STATE) => ({ ...state, error: null, readinessloading: true });

const getPlayerReadinessSuccess = (state = INITIAL_STATE, action) => {
  const { readiness } = action.payload;

  return {
    ...state,
    readinessloading: false,
    readiness
  };
};

const getPlayerReadinessFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    readinessloading: false,
    error
  };
};

const getPlayerHomeNutritionStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getPlayerHomeNutritionSuccess = (state = INITIAL_STATE, action) => {
  const { nutritionStats } = action.payload;

  return {
    ...state,
    loading: false,
    reload: false,
    nutritionStats
  };
};

const getPlayerHomeNutritionFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const getPlayerHomeStrengthStart = (state = INITIAL_STATE) => ({ ...state, error: null, strengthloading: true });

const getPlayerHomeStrengthSuccess = (state = INITIAL_STATE, action) => {
  const { strength } = action.payload;

  return {
    ...state,
    strengthloading: false,
    reload: false,
    strength
  };
};

const getPlayerHomeStrengthFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    strengthloading: false,
    error
  };
};
const getPlayerHomeHydrationStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getPlayerHomeHydrationSuccess = (state = INITIAL_STATE, action) => {
  const { hydrationStats } = action.payload;

  return {
    ...state,
    loading: false,
    reload: false,
    hydrationStats
  };
};

const getPlayerHomeHydrationFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const getPlayerHomeMoodStatsStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getPlayerHomeMoodStatsSuccess = (state = INITIAL_STATE, action) => {
  const { moodStats } = action.payload;
  return {
    ...state,
    loading: false,
    reload: false,
    moodStats
  };
};

const getPlayerHomeMoodStatsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const HANDLERS = {
  [Types.GET_PLAYER_HOME_STRENGTH_START]: getPlayerHomeStrengthStart,
  [Types.GET_PLAYER_HOME_STRENGTH_SUCCESS]: getPlayerHomeStrengthSuccess,
  [Types.GET_PLAYER_HOME_STRENGTH_FAILURE]: getPlayerHomeStrengthFailure,

  [Types.GET_PLAYER_READINESS_START]: getPlayerReadinessStart,
  [Types.GET_PLAYER_READINESS_SUCCESS]: getPlayerReadinessSuccess,
  [Types.GET_PLAYER_READINESS_FAILURE]: getPlayerReadinessFailure,

  [Types.GET_PLAYER_HOME_NUTRITION_START]: getPlayerHomeNutritionStart,
  [Types.GET_PLAYER_HOME_NUTRITION_SUCCESS]: getPlayerHomeNutritionSuccess,
  [Types.GET_PLAYER_HOME_NUTRITION_FAILURE]: getPlayerHomeNutritionFailure,

  [Types.GET_PLAYER_HOME_HYDRATION_START]: getPlayerHomeHydrationStart,
  [Types.GET_PLAYER_HOME_HYDRATION_SUCCESS]: getPlayerHomeHydrationSuccess,
  [Types.GET_PLAYER_HOME_HYDRATION_FAILURE]: getPlayerHomeHydrationFailure,

  [Types.GET_PLAYER_HOME_MOOD_STATS_START]: getPlayerHomeMoodStatsStart,
  [Types.GET_PLAYER_HOME_MOOD_STATS_SUCCESS]: getPlayerHomeMoodStatsSuccess,
  [Types.GET_PLAYER_HOME_MOOD_STATS_FAILURE]: getPlayerHomeMoodStatsFailure
};

export const playerHomeReducer = createReducer(INITIAL_STATE, HANDLERS);
