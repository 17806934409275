/* eslint-disable no-extend-native */
export const commarize = (number = 0) => {
  // Alter numbers larger than 1k
  if (number >= 1e3) {
    const units = ['k', 'M', 'B', 'T'];

    // Divide to get SI Unit engineering style numbers (1e3,1e6,1e9, etc)
    const unit = Math.floor((number.toFixed(0).length - 1) / 3) * 3;
    // Calculate the remainder
    const num = (number / `1e${unit}`).toFixed(2);
    const unitname = units[Math.floor(unit / 3) - 1];

    // output number remainder + unitname
    return num + unitname;
  }

  // return formatted original number
  return number.toLocaleString();
};

// number converter
export const _converIntoKAndM = (num) => {
  Number.prototype.round = function (places) {
    return +`${Math.round(`${this}e+${places}`)}e-${places}`;
  };

  if (num > 9999 && num < 1000000) {
    return `${Math.floor(num / 1000)}K`;
  }
  if (num > 999999 && num < 1000000000) {
    return `${Math.floor(num / 1000000)}M`;
  }
  if (num > 999999999 && num < 1000000000000) {
    return `${Math.floor(num / 1000000000)}B`;
  }
  if (num > 999999999999) {
    return `${Math.floor(num / 1000000000000)}T`;
  }
  return num.round(2) || 0;
};
