import styled from 'styled-components';
import { backgroundColor, fontSize } from '../../../shared/constants';

export const ModalContainer = styled.div`
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  justify-content: flex-end;

  background-color: ${backgroundColor.popupBg};

  z-index: 999999;
`;

export const ContentContainer = styled.div`
  position: relative;
  height: 100%;
  width: 632px;
  padding: 36px;

  background-color: ${backgroundColor.popup};
  border-radius: 6px 0px 0px 6px;

  overflow-y: auto;
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 654px;
  top: 18px;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 999999;
`;

export const FormTitle = styled.h1`
  font-size: ${fontSize.titleVerySmall};
  margin-bottom: 32px;
`;

export const DividerContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 80px;
  right: 0px;
`;

export const FormFooter = styled.div`
  position: absolute;
  bottom: 22px;
  right: 22px;
`;
