/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/state-in-constructor */
/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Container, ShowComments, ScrollContainer } from './ConmmentsStyles';
import { ReactComponent as Dropdown } from '../../assets/Dropdown.svg';

import { timeSince } from '../../../services/utility';
import CommentInfo from '../CommentInfoComponent';
import userImg from '../../assets/user.jpg';
import { EmptyContentText } from '../../../globalShared/CommonStyles';
import { localString } from '../../../services/locallization/localStrings';

const CommentsFooter = (props) => {
  const [showComments, setshowComments] = useState(false);

  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  const handleClick = () => {
    const { _id, onClick } = props;
    if (!showComments) {
      onClick(_id);
    }
    setshowComments(!showComments);
  };

  const { commentCount, commentsList, _id } = props;
  const filteredComment = commentsList.filter((comment) => comment.referenceId === _id);

  return (
    <>
      <Container>
        <ShowComments onClick={handleClick}>
          {filteredComment.length > 0 || commentCount ? (
            <>
              {`${string.comments} (${filteredComment.length || commentCount})`}
              <Dropdown />
            </>
          ) : (
            string.noComments
          )}
        </ShowComments>
      </Container>
      {showComments && (
        <ScrollContainer>
          {filteredComment.length > 0 ? (
            filteredComment.map((comment) => {
              const { _id, content, firstName, lastName, profilePicture, dateAdded } = comment;
              return (
                <CommentInfo
                  key={_id}
                  comment={content}
                  playerName={`${firstName} ${lastName}`}
                  userPic={profilePicture || userImg}
                  time={timeSince(dateAdded)}
                />
              );
            })
          ) : (
            <EmptyContentText fontSize='16px'>{string.noComments}</EmptyContentText>
          )}
        </ScrollContainer>
      )}
    </>
  );
};
export default CommentsFooter;
