import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Input, InputContainer, Button } from './CommentInput.styles';
import { ReactComponent as Send } from '../../assets/send.svg';

//
const CommentInputComponent = (props) => {
  const { placeholderText, onSubmit, id } = props;
  const [state, setstate] = useState({ comment: '' });

  const handleChange = ({ target }) => {
    setstate({ ...state, comment: target.value });
  };

  const handleSubmit = () => {
    const { comment } = state;
    if (comment) onSubmit(comment, id);
    setstate({ ...state, comment: '' });
  };

  const { comment } = state;

  return (
    <Container>
      <InputContainer>
        <Input type='text' value={comment} placeholder={placeholderText} onChange={handleChange} />
        <Button type='button' onClick={handleSubmit}>
          <Send />
        </Button>
      </InputContainer>
    </Container>
  );
};

CommentInputComponent.propTypes = {
  placeholderText: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired
};

export default CommentInputComponent;
