/*
 * reducer.js file for nutrition plannerdetails view
 * Author:
 * 1.Manikanta
 * @copyright of Panorbit Services LLP.
 */

import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  loading: false,
  error: null,
  success: false,
  workoutList: [],
  plannerDetails: null,
  groupDetails: null,
  pageNo: 1,
  maxPage: 1,
  reloadPlanner: false
};

const getMealPlanDetailsStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getMealPlanDetailsSuccess = (state = INITIAL_STATE, action) => {
  const { plannerDetails } = action.payload;

  return {
    ...state,
    loading: false,
    plannerDetails,
    reloadPlanner: false,
    success: false
  };
};

const getMealPlanDetailsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const createPlannerSessionStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const createPlannerSessionSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: false,
  reloadPlanner: true,
  success: true
});

const createPlannerSessionFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const addPlayerMealPlanStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const addPlayerMealPlanSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: false,
  reloadPlanner: true,
  success: true
});

const addPlayerMealPlanFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const copyMealPlannerByDayStart = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true,
  reloadPlanner: false
});

const copyMealPlannerByDaySuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: false,
  reloadPlanner: true,
  success: true
});

const copyMealPlannerByDayFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const clearPlayerMealPlanStart = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true,
  reloadPlanner: false
});

const clearPlayerMealPlanSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: false,
  reloadPlanner: true,
  success: true
});

const clearPlayerMealPlanFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const copyMealPlannerByWeekStart = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true,
  reloadPlanner: false
});

const copyMealPlannerByWeekSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: false,
  reloadPlanner: true,
  success: true
});

const copyMealPlannerByWeekFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const resetSuccess = (state = INITIAL_STATE) => ({ ...state, success: false });
const resetError = (state = INITIAL_STATE) => ({ ...state, error: null });

const HANDLERS = {
  [Types.GET_MEAL_PLAN_DETAILS_START]: getMealPlanDetailsStart,
  [Types.GET_MEAL_PLAN_DETAILS_SUCCESS]: getMealPlanDetailsSuccess,
  [Types.GET_MEAL_PLAN_DETAILS_FAILURE]: getMealPlanDetailsFailure,

  [Types.CREATE_PLANNER_SESSION_START]: createPlannerSessionStart,
  [Types.CREATE_PLANNER_SESSION_SUCCESS]: createPlannerSessionSuccess,
  [Types.CREATE_PLANNER_SESSION_FAILURE]: createPlannerSessionFailure,

  [Types.ADD_PLAYER_MEAL_PLAN_START]: addPlayerMealPlanStart,
  [Types.ADD_PLAYER_MEAL_PLAN_SUCCESS]: addPlayerMealPlanSuccess,
  [Types.ADD_PLAYER_MEAL_PLAN_FAILURE]: addPlayerMealPlanFailure,

  [Types.COPY_MEAL_PLANNER_BY_DAY_START]: copyMealPlannerByDayStart,
  [Types.COPY_MEAL_PLANNER_BY_DAY_SUCCESS]: copyMealPlannerByDaySuccess,
  [Types.COPY_MEAL_PLANNER_BY_DAY_FAILURE]: copyMealPlannerByDayFailure,

  [Types.CLEAR_PLAYER_MEAL_PLAN_START]: clearPlayerMealPlanStart,
  [Types.CLEAR_PLAYER_MEAL_PLAN_SUCCESS]: clearPlayerMealPlanSuccess,
  [Types.CLEAR_PLAYER_MEAL_PLAN_FAILURE]: clearPlayerMealPlanFailure,

  [Types.COPY_MEAL_PLANNER_BY_WEEK_START]: copyMealPlannerByWeekStart,
  [Types.COPY_MEAL_PLANNER_BY_WEEK_SUCCESS]: copyMealPlannerByWeekSuccess,
  [Types.COPY_MEAL_PLANNER_BY_WEEK_FAILURE]: copyMealPlannerByWeekFailure,

  [Types.RESET_SUCCESS]: resetSuccess,
  [Types.RESET_ERROR]: resetError
};

export const coachPlayerMealPlanReducer = createReducer(INITIAL_STATE, HANDLERS);
