/*
 * action.js file for coach workouts view
 * Author:
 * 1.Manikanta
 *
 * @copyright of Panorbit Services LLP.
 */

import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getCoachWorkoutList: ['payload'],
  getCoachWorkoutListStart: ['payload'],
  getCoachWorkoutListSuccess: ['payload'],
  getCoachWorkoutListFailure: ['payload'],

  createWorkout: ['payload'],
  createWorkoutStart: ['payload'],
  createWorkoutSuccess: ['payload'],
  createWorkoutFailure: ['payload'],

  deleteCoachWorkout: ['payload'],
  deleteCoachWorkoutStart: ['payload'],
  deleteCoachWorkoutSuccess: ['payload'],
  deleteCoachWorkoutFailure: ['payload'],

  searchWorkout: ['payload'],
  searchWorkoutStart: ['payload'],
  searchWorkoutSuccess: ['payload'],
  searchWorkoutFailure: ['payload'],
  searchWorkoutList: ['payload'],
  searchWorkoutListSuccess: ['payload'],
  resetWorkoutList: null,

  resetWorkout: null,
  setWorkout: ['payload'],
  setShowmodal: ['payload'],
  resetError: null,
  resetSuccess: null,
  setShowDetail: ['payload'],
  setWorkoutSearchKey: ['payload'],
  setWorkoutPage: ['payload']
});

export { Types, Creators };
