import React from 'react';
import PropTypes from 'prop-types';
import { Container, Input, Label } from './InputComponent.styles';

const InputComponent = (props) => {
  const { ipType, placeholderText, ipName, ipRef, label, onChangeHandler, disabled, value } = props;

  const handleChange = (text) => {
    if (!onChangeHandler) {
      return;
    }
    onChangeHandler(text);
  };
  return (
    <Container>
      {label ? <Label forhtml={ipName}>{label}</Label> : ''}
      <div>
        {value !== null ? (
          <Input
            disabled={disabled}
            type={ipType}
            placeholder={placeholderText}
            name={ipName}
            ref={ipRef}
            onChange={(e) => handleChange(e.target.value)}
            value={value}
          />
        ) : (
          <Input
            disabled={disabled}
            type={ipType}
            placeholder={placeholderText}
            name={ipName}
            ref={ipRef}
            onChange={() => handleChange()}
          />
        )}
      </div>
    </Container>
  );
};

InputComponent.defaultProps = {
  ipType: 'text',
  label: '',
  onChangeHandler: null,
  disabled: false,
  value: null
};

InputComponent.propTypes = {
  placeholderText: PropTypes.string.isRequired,
  ipName: PropTypes.string.isRequired,
  ipRef: PropTypes.func.isRequired,
  onChangeHandler: PropTypes.func,
  ipType: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string
};

export default InputComponent;
