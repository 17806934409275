import styled from 'styled-components';
import { fontWeight } from '../../constants';

export const AudioPlayerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DurationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    margin-right: 8px;
  }
`;

export const TimeInfo = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const TimeElapsed = styled.p`
  font-size: 16px;
  font-weight: ${fontWeight.medium};
  color: #f6f6f6;
`;

export const TotalDuration = styled.p`
  font-size: 12px;
  font-weight: ${fontWeight.medium};
  color: #f6f6f6;
  padding-bottom: 1px;
`;

export const SaveWrapper = styled.div`
  margin-top: 14px;
`;
