/* eslint-disable no-console */
/* eslint-disable no-shadow */
/* eslint-disable no-undef */
/*
 * saga.js file for player strength view
 * Author:
 * 1. manikanta
 * @copyright of Panorbit Services LLP.
 */

import { put } from 'redux-saga/effects';
import { Creators } from './action';

import { WebService } from '../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../services/axios/endPoints';

export function* getDaySessionListSaga({ payload }) {
  const { date } = payload;

  try {
    yield put(Creators.getDaySessionListStart());
    const fullURL = yield `${apiEndPoints.sessionList}`;

    const response = yield WebService.get(fullURL, { params: { date } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(
        Creators.getDaySessionListSuccess({
          sessionsList: data
        })
      );
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getDaySessionListFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getDaySessionListFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getDaySessionListFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getDaySessionListFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getDaySessionListFailure(payload));
    }
  }
}

export function* getAvailableSessionsSaga({ payload }) {
  const { fromDate, toDate } = payload;

  try {
    yield put(Creators.getAvailableSessionsStart());
    const fullURL = yield `${apiEndPoints.sessionList}`;

    const response = yield WebService.get(fullURL, { params: { fromDate, toDate } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      yield put(Creators.setAvailableSessions({ availableSessions: data }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getAvailableSessionsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getAvailableSessionsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getAvailableSessionsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getAvailableSessionsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getAvailableSessionsFailure(payload));
    }
  }
}

export function* getSessionWorkoutsSaga({ payload }) {
  const { _id, count, page } = payload;

  try {
    yield put(Creators.getSessionWorkoutsStart());
    const fullURL = yield `${apiEndPoints.exerciseInWorkout}`;

    const response = yield WebService.get(fullURL, { params: { _id, count, page } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(Creators.getSessionWorkoutsSuccess({ workoutList: data[0], sessionStats: data[1] || {} }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getSessionWorkoutsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getSessionWorkoutsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getSessionWorkoutsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getSessionWorkoutsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getSessionWorkoutsFailure(payload));
    }
  }
}

export function* setSessionStatusSaga({ payload }) {
  const { _id, progressStatus } = payload;

  try {
    yield put(Creators.setSessionStatusStart());
    const fullURL = yield `${apiEndPoints.addSession}`;

    const dataToApi = new FormData();
    dataToApi.append('_id', _id);
    dataToApi.append('progressStatus', progressStatus);

    const response = yield WebService({
      method: 'PUT',
      url: fullURL,
      data: dataToApi
    });

    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.setSessionStatusSuccess());
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.setSessionStatusFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.setSessionStatusFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.setSessionStatusFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.setSessionStatusFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.setSessionStatusFailure(payload));
    }
  }
}

export function* getWorkoutExerciseDetailsSaga({ payload }) {
  const { workoutId, exerciseId, sessionId } = payload;

  try {
    yield put(Creators.getWorkoutExerciseDetailsStart());
    const fullURL = yield `${apiEndPoints.workoutExerciseDetails}`;

    const response = yield WebService.get(fullURL, { params: { workoutId, exerciseId, sessionId } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(
        Creators.getWorkoutExerciseDetailsSuccess({
          exerciseDetails: data
        })
      );
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getWorkoutExerciseDetailsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getWorkoutExerciseDetailsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getWorkoutExerciseDetailsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getWorkoutExerciseDetailsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getWorkoutExerciseDetailsFailure(payload));
    }
  }
}

export function* setPlayerExerciseSaga({ payload }) {
  try {
    yield put(Creators.setPlayerExerciseStart());
    const fullURL = yield `${apiEndPoints.updateExercise}`;

    const response = yield WebService({
      method: 'POST',
      url: fullURL,
      data: JSON.stringify(payload)
    });

    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.setPlayerExerciseSuccess());
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.setPlayerExerciseFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.setPlayerExerciseFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.setPlayerExerciseFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.setPlayerExerciseFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.setPlayerExerciseFailure(payload));
    }
  }
}

export function* getPlayerSessionCommentsSaga({ payload }) {
  const { _id, page = '1', count } = payload;

  try {
    yield put(Creators.getPlayerSessionCommentsStart());
    const fullURL = yield `${apiEndPoints.commentList}`;

    const response = yield WebService.get(fullURL, { params: { _id, page, count } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      const payload = {
        commentsList: data[0],
        maxPageNo: data[1]
      };

      yield put(Creators.getPlayerSessionCommentsSuccess(payload));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getPlayerSessionCommentsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getPlayerSessionCommentsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getPlayerSessionCommentsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getPlayerSessionCommentsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getPlayerSessionCommentsFailure(payload));
    }
  }
}
