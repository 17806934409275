import styled from 'styled-components';
import { fontSize, borderColor, fontWeight, textColors } from '../../../shared/constants';

export const Container = styled.div`
  height: calc(100vh - 150px);
  overflow-y: auto;
  padding-right: 1rem;
`;

export const SleepDateWrapper = styled.div`
  margin-top: 28px;
  display: flex;
  justify-content: center;
  margin-bottom: 21px;
`;

export const SleepDate = styled.p`
  font-size: 12px;
  font-weight: ${fontWeight.medium};
  color: ${textColors.textLight};
`;

export const SavedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
`;

export const SavedText = styled.p`
  margin-top: 18px;
  font-size: 12px;
  font-weight: ${fontWeight.medium};
  color: #7ac943;
  margin-bottom: 17px;
`;

export const SleepDurationWrapper = styled.span`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const SleepDurationText = styled.p`
  font-size: 12px;
  font-weight: ${fontWeight.medium};
  color: ${textColors.textLight};
`;

export const Duration = styled.p`
  font-size: 22px;
  font-weight: ${fontWeight.bold};
  color: #f6f6f6;
`;

export const TodaysEntryHeader = styled.p`
  font-size: 14px;
  font-weight: ${fontWeight.bold};
  color: #f6f6f6;
  text-transform: capitalize;
  margin-bottom: 12px;
`;

export const TodaysEntry = styled.div`
  display: flex;
  gap: 17px;
  margin-bottom: 0.5rem;

  & > svg > g > line {
    stroke: #a3a9b7;
  }

  & > svg {
    margin-top: 2rem;
  }
`;

export const BedTimeContainer = styled.div`
  width: 100%;
  border: 1px solid ${borderColor.light};
  padding: 1rem !important;
  border-radius: 6px;
`;

export const BedTimes = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
`;

export const Text = styled.div`
  margin-right: 1.4rem;

  display: flex;
  align-items: center;

  & > span {
    margin-right: 0.6rem;
  }

  & > p {
    display: inline-block;
    font-size: ${fontSize.medium};
  }
`;

export const H1 = styled.h1`
  font-size: ${fontSize.large};
`;

export const DividerWrapper = styled.div``;

export const TextSmallLight = styled.p`
  font-size: ${fontSize.smallDefault};
  color: ${textColors.textLight};
`;

export const CommentInputContainer = styled.div`
  & > div > div > input {
    font-size: 12px;
  }
`;
