/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';

// usage
// eg:['value1', 'value2', 'value3', 'value4',.......'n']

// styles
import { Add, AddButton, Container, DropdownContainer, Item, MainContainer } from './DropdownStyles';
import { Label } from '../../../../globalShared/CommonStyles';

// icon
import { ReactComponent as Arrow } from '../../../assets/Dropdown.svg';
import { ReactComponent as Plus } from '../../../assets/add_session.svg';

import useTouchOutside from '../../../hooks/useTouchOutside';
import { shortenText } from '../../../../services/utility';

// component
const Dropdown = (props) => {
  const ref = React.useRef();
  const [isVisible, setisVisible] = useState(false);
  const {
    list,
    label,
    value,
    setValue,
    placeholder = 'Select',
    showLabel = true,
    maxHeight = null,
    button = false,
    buttonText = '',
    onAdd = () => null,
    objType = false,
    position = 'bottom'
  } = props;

  useTouchOutside(ref, () => setisVisible(false));

  const handleClick = (value) => {
    setValue(value);
    setisVisible(!isVisible);
  };

  return (
    <div ref={ref}>
      {showLabel && <Label>{label}</Label>}
      <MainContainer style={{ position: 'relative' }}>
        <Container isVisible={isVisible} position={position} onClick={() => setisVisible(!isVisible)}>
          <p>{objType ? shortenText(value.label, 15, '...') : shortenText(value, 15, '...') || placeholder}</p>
          <Arrow style={{ marginLeft: 'auto' }} />
        </Container>

        {isVisible && (
          <>
            <DropdownContainer maxHeight={maxHeight} position={position}>
              {!objType &&
                list.map((value, i) => (
                  <Item key={i} onClick={() => handleClick(value)}>
                    {shortenText(value, 100, '...')}
                  </Item>
                ))}
              {objType &&
                list.map((item, i) => (
                  <Item key={i} onClick={() => handleClick(item)}>
                    {shortenText(item.label, 100, '...')}
                  </Item>
                ))}

              {button && (
                <AddButton
                  onClick={() => {
                    onAdd();
                    setisVisible(!isVisible);
                  }}
                >
                  <Plus />
                  <Add>{buttonText}</Add>
                </AddButton>
              )}
            </DropdownContainer>
          </>
        )}
      </MainContainer>
    </div>
  );
};

export default Dropdown;
