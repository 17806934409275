/* eslint-disable no-unused-vars */
import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  loading: false,
  error: null,
  success: false,
  helpAndSupportList: [],
  maxPage: 1,
  helpCount: 0,
  adminHelpAndSupportList: [],
  coachHelpAndSupportList: [],
  playerHelpAndSupportList: [],
  reload: false
};

const getHelpAndSupportListStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getHelpAndSupportListSuccess = (state = INITIAL_STATE, action) => {
  const { helpAndSupportList, maxPage, totalCount } = action.payload;

  return {
    ...state,
    loading: false,
    reload: false,
    helpAndSupportList,
    maxPage,
    helpCount: totalCount
  };
};

const getHelpAndSupportListFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const getAdminHelpAndSupportListStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getAdminHelpAndSupportListSuccess = (state = INITIAL_STATE, action) => {
  const { adminHelpAndSupportList } = action.payload;

  return {
    ...state,
    loading: false,
    reload: false,
    adminHelpAndSupportList
  };
};

const getAdminHelpAndSupportListFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const getCoachHelpAndSupportListStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getCoachHelpAndSupportListSuccess = (state = INITIAL_STATE, action) => {
  const { coachHelpAndSupportList } = action.payload;

  return {
    ...state,
    loading: false,
    reload: false,
    coachHelpAndSupportList
  };
};

const getCoachHelpAndSupportListFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const getPlayerHelpAndSupportListStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getPlayerHelpAndSupportListSuccess = (state = INITIAL_STATE, action) => {
  const { playerHelpAndSupportList } = action.payload;

  return {
    ...state,
    loading: false,
    reload: false,
    playerHelpAndSupportList
  };
};

const getPlayerHelpAndSupportListFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const searchHelpAndSupportStart = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  error: null
});

const searchHelpAndSupportSuccess = (state = INITIAL_STATE, action) => {
  const { helpAndSupportList, totalCount } = action.payload;
  return {
    ...state,
    loading: false,
    helpAndSupportList,
    helpCount: totalCount
  };
};

const searchHelpAndSupportFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const HANDLERS = {
  [Types.GET_HELP_AND_SUPPORT_LIST_START]: getHelpAndSupportListStart,
  [Types.GET_HELP_AND_SUPPORT_LIST_SUCCESS]: getHelpAndSupportListSuccess,
  [Types.GET_HELP_AND_SUPPORT_LIST_FAILURE]: getHelpAndSupportListFailure,

  [Types.GET_ADMIN_HELP_AND_SUPPORT_LIST_START]: getAdminHelpAndSupportListStart,
  [Types.GET_ADMIN_HELP_AND_SUPPORT_LIST_SUCCESS]: getAdminHelpAndSupportListSuccess,
  [Types.GET_ADMIN_HELP_AND_SUPPORT_LIST_FAILURE]: getAdminHelpAndSupportListFailure,

  [Types.GET_COACH_HELP_AND_SUPPORT_LIST_START]: getCoachHelpAndSupportListStart,
  [Types.GET_COACH_HELP_AND_SUPPORT_LIST_SUCCESS]: getCoachHelpAndSupportListSuccess,
  [Types.GET_COACH_HELP_AND_SUPPORT_LIST_FAILURE]: getCoachHelpAndSupportListFailure,

  [Types.GET_PLAYER_HELP_AND_SUPPORT_LIST_START]: getPlayerHelpAndSupportListStart,
  [Types.GET_PLAYER_HELP_AND_SUPPORT_LIST_SUCCESS]: getPlayerHelpAndSupportListSuccess,
  [Types.GET_PLAYER_HELP_AND_SUPPORT_LIST_FAILURE]: getPlayerHelpAndSupportListFailure,

  [Types.SEARCH_HELP_AND_SUPPORT_START]: searchHelpAndSupportStart,
  [Types.SEARCH_HELP_AND_SUPPORT_SUCCESS]: searchHelpAndSupportSuccess,
  [Types.SEARCH_HELP_AND_SUPPORT_FAILURE]: searchHelpAndSupportFailure
};

export const helpAndSupportReducer = createReducer(INITIAL_STATE, HANDLERS);
