/*
 * reducers.js file for store
 * Author:
 * 1. Manikanta
 *
 * @copyright of Panorbit Services LLP.
 */

import { combineReducers } from 'redux';
import { commonReducer } from '../views/store';
import { loginReducer } from '../views/auth/LoginView/store';
import { createPasswordReducer } from '../views/auth/CreatePassword/store';

import { forgetPasswordReducer } from '../views/auth/ForgotPasswordView/store';

import { userMetaReducer } from '../views/PlayerDetailsView/store';
import { verifyOtpReducer } from '../views/auth/OtpView/store';
import { PersonalDetailsReducer } from '../views/PersonalDetails/store';
import { professionalDetailsReducer } from '../views/ProfessionalDetailsView/store';
import { adminUsersReducer } from '../views/admin/UsersView/store';
import { compareReducer } from '../views/CompareView/store';
import { updatePersonalDetailsReducer } from '../views/ProfileModal/store';
import { updateCoachPersonalDetailsReducer } from '../views/Coach/CoachProfile/store';

// userType:Player reducers
import { playerSkillsReducer } from '../views/Player/SkillsView/store';
import { CompareVideosReducer } from '../views/Player/CompareView/store';
import { playerVideoPlayerReducer } from '../views/Player/VideoplayerView/store';
import { playerStrengthReducer } from '../views/Player/StrengthView/store';
import { playerMealPlanReducer } from '../views/Player/NutritionView/store';
import { playerMindsetReducer } from '../views/Player/MindsetView/store';
import { playerHomeReducer } from '../views/Player/HomeView/store';
import { playerProfileReducer } from '../views/Player/ProfileModal/store';

// userType: Coach Reducers
import { coachHomeReducer } from '../views/Coach/HomeView/store';
import { playerDashboardReducer } from '../views/Coach/PlayerDashboardView/store';
import { coachPlayersReducer } from '../views/Coach/PlayersView/store';
import { coachSkillsReducer } from '../views/Coach/SkillsVIew/store';
import { coachVideoPlayerReducer } from '../views/Coach/VideoplayerView/store';
import { coachSearchVideoReducer } from '../views/Coach/SearchVideosView/store';
import { groupListReducer } from '../views/Coach/GroupView/store';
import { modalReducer } from '../views/Coach/RestPlayers/store';
import { coachExerciseReducer } from '../views/Coach/ExerciseView/store';
import { coachWorkoutReducer } from '../views/Coach/Workoutsview/store';
import { coachPlannerReducer } from '../views/Coach/PlannerView/store';
import { coachPlannerDetailsReducer } from '../views/Coach/PlannerDetailsView/store';
import { coachStrengthReducer } from '../views/Coach/StrengthViews/RecentView/store';
import { coachStrengthFeedDetailsReducer } from '../views/Coach/StrengthViews/RecentView/SessionDetails/store';

// Coach Nutrition Reducers
import { coachMealPlanReducer } from '../views/Coach/MealPlansView/store';
import { coachMealPlannerReducer } from '../views/Coach/MealPlannerView/store';
import { coachMealRecentReducer } from '../views/Coach/MealRecentView/store';
import { coachPlayerMealPlanReducer } from '../views/Coach/MealPlannerDetailView/store';

// Coach Mindset Reducers
import { coachMindsetReducer } from '../views/Coach/MindsetView/store';
import { PlayerTeamVideoReducer } from '../views/Player/AddVideosView/store';
import { playerMoodReducer } from '../views/Player/MindsetView/MoodStore';

// Help and Support Reducer
import { helpAndSupportReducer } from '../views/HelpAndSupport/store';
// admin
import { adminHomeReducer } from '../views/admin/HomeView/store';
import { NutritonReducer } from '../views/Coach/NutritionViews/store';
import { strengthReducer } from '../views/Coach/StrengthViews/store';

//
const appReducer = combineReducers({
  login: loginReducer,
  createPassword: createPasswordReducer,
  forgotPassword: forgetPasswordReducer,
  userMeta: userMetaReducer,
  verifyOtp: verifyOtpReducer,
  personalDetails: PersonalDetailsReducer,
  updatePersonalDetails: updatePersonalDetailsReducer,
  updateCoachPersonalDetails: updateCoachPersonalDetailsReducer,
  professionalDetails: professionalDetailsReducer,
  adminUsers: adminUsersReducer,
  playerSkills: playerSkillsReducer,
  playerVideoPlayer: playerVideoPlayerReducer,
  playerMealPlan: playerMealPlanReducer,
  playerMindset: playerMindsetReducer,
  coachPlayers: coachPlayersReducer,
  coachSkills: coachSkillsReducer,
  coachVideoPlayer: coachVideoPlayerReducer,
  compare: compareReducer,
  teamVideos: coachSearchVideoReducer,
  groupList: groupListReducer,
  modalData: modalReducer,
  coachExercise: coachExerciseReducer,
  coachWorkout: coachWorkoutReducer,
  coachPlanner: coachPlannerReducer,
  coachPlannerDetails: coachPlannerDetailsReducer,
  coachStrengthFeed: coachStrengthReducer,
  coachMealPlan: coachMealPlanReducer,
  coachStrengthFeedDetails: coachStrengthFeedDetailsReducer,
  coachMealPlanner: coachMealPlannerReducer,
  coachMealRecent: coachMealRecentReducer,
  coachPlayerMealPlan: coachPlayerMealPlanReducer,
  coachMindset: coachMindsetReducer,
  compareVideos: CompareVideosReducer,
  playerTeamVideos: PlayerTeamVideoReducer,
  playerMood: playerMoodReducer,
  playerStrength: playerStrengthReducer,
  playerHome: playerHomeReducer,
  coachHome: coachHomeReducer,
  playerDashboard: playerDashboardReducer,
  playerProfile: playerProfileReducer,
  helpAndSupport: helpAndSupportReducer,
  adminHome: adminHomeReducer,
  main: commonReducer,
  nutrition: NutritonReducer,
  strength: strengthReducer
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'LOGOUT') {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
