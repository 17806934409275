import styled from 'styled-components';
import { FlexContainer } from '../../../globalShared/CommonStyles';
import { borderColor, fontSize, textColors } from '../../../shared/constants';

export const Container = styled.div`
  display: flex;
  height: calc(100vh - 175px);
`;

export const SessionsContainer = styled.div`
  width: 34%;

  padding-right: 1.5rem;
  margin-right: 1.5rem;

  position: relative;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 1rem;
    width: 0.5px;
    height: 100%;
    background: ${borderColor.light};
    z-index: 99;
  }
`;

export const SessionsHeader = styled.div`
  padding: 2rem 0;
`;

export const H2 = styled.p`
  font-size: ${fontSize.titleVerySmall};
`;
export const H3 = styled.p`
  font-size: ${fontSize.title};
  font-weight: ${(props) => (props.bold ? '700' : 'normal')};
`;

export const P = styled.p`
  font-size: ${fontSize.medium};
  color: ${(props) => (props.light ? `${textColors.textLight}` : `${textColors.textWhite}`)};
`;

export const WorkoutsContainer = styled.div`
  width: 66%;
  position: relative;
`;

export const WorkoutsBody = styled.div`
  /* height: 75%; */
  overflow-y: auto;
  padding-right: 1rem;
`;

export const WorkoutsFooter = styled.div`
  padding: 1.5rem 0;
  display: flex;
  justify-content: flex-end;
  border-top: 0.5px solid ${borderColor.light};
`;

export const Workout = styled.div`
  margin-bottom: 1rem;
  opacity: ${(props) => (props.Inactive ? '30%' : '100%')};
`;

export const ExercisesC = styled.div`
  display: flex;
  align-items: center;

  margin-left: auto;

  & > p {
    margin: 0 1rem;
  }

  & > svg {
    transform: rotate(-90deg);
  }
`;

export const Calender = styled.div`
  height: auto;
`;

export const SessionsCardContainer = styled.div`
  height: 90%;
  overflow-y: auto;
  padding-right: 0.5rem;
`;

export const StatusContainer = styled.div`
  width: 100%;
  padding: 2rem;
  border: 1px solid ${borderColor.light};
  border-radius: 6px;
  display: flex;
  margin-bottom: 1.5rem;
`;

export const ProgressContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  border-right: 1px solid ${borderColor.light};
  padding-left: 2rem;
  padding-right: 4rem;
`;

export const Progress = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: ${fontSize.heavy};
`;

export const Stats = styled(FlexContainer)`
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

export const Stat = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > h1 {
    margin-top: 1.2rem;
    font-size: ${fontSize.titleMedium};
  }

  & > span {
    font-size: ${fontSize.medium};
    color: ${textColors.textLight};
  }
`;

export const WorktoutFlowCon = styled.div`
  height: calc(100vh - 224px);
  overflow-y: auto;
`;
