import React from 'react';

import { Label, Container, InputLabel, InputC, IpGroup } from '../../RadioGroupComponent/RadioGroup.styles';

const RadioGroup = (props) => {
  const { label, list, name, setValue, selected, showLabel } = props;
  return (
    <div>
      {showLabel && <Label>{label}</Label>}
      <Container>
        {list.map((_k) => {
          const { value, label } = _k;
          return (
            <IpGroup key={value} onClick={() => setValue(_k)}>
              <InputC type='radio' name={name} className={selected.value === value ? 'checked' : ''} id={value} />
              <InputLabel htmlfor={value}>{label}</InputLabel>
            </IpGroup>
          );
        })}
      </Container>
    </div>
  );
};

RadioGroup.defaultProps = {
  label: '',
  showLabel: true
};

export default RadioGroup;
