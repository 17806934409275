/* eslint-disable arrow-body-style */
/*
 * reducer file for otp views
 * Author:
 * 1. Manikanta
 *
 * @copyright of Panorbit Services LLP.
 */

import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  loading: false,
  error: null,
  success: false
};

const verifyOtpStart = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: true
  };
};

const verifyOtpSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: false,
    success: true
  };
};

const verifyOtpFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;

  return {
    ...state,
    loading: false,
    success: false,
    error
  };
};

const resetOtp = (state = INITIAL_STATE) => ({ ...state, success: false });

const HANDLERS = {
  [Types.VERIFY_OTP_START]: verifyOtpStart,
  [Types.VERIFY_OTP_SUCCESS]: verifyOtpSuccess,
  [Types.VERIFY_OTP_FAILURE]: verifyOtpFailure,
  [Types.RESET_OTP]: resetOtp
};

export const verifyOtpReducer = createReducer(INITIAL_STATE, HANDLERS);
