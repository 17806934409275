import React from 'react';

// icons
import { ReactComponent as Delightful } from '../../assets/mood_delightful.svg';
import { ReactComponent as Happy } from '../../assets/mood_happy.svg';
import { ReactComponent as Good } from '../../assets/mood_good.svg';
import { ReactComponent as Sad } from '../../assets/mood_sad.svg';
import { ReactComponent as Tired } from '../../assets/mood_tired.svg';
import { ReactComponent as Awful } from '../../assets/mood_awful.svg';

const MoodIcon = ({ type }) => {
  const renderMood = {
    awful: <Awful />,
    tired: <Tired />,
    sad: <Sad />,
    good: <Good />,
    happy: <Happy />,
    delightful: <Delightful />,

    level1: <Awful />,
    level2: <Tired />,
    level3: <Sad />,
    level4: <Good />,
    level5: <Happy />,
    level6: <Delightful />
  };
  return <>{renderMood[type]}</>;
};

export default MoodIcon;
