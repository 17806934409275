import styled from 'styled-components';
import { backgroundColor, borderColor, fontSize } from '../../../shared/constants';

export const ContentContainer = styled.div`
  height: 100%;
  width: 1150px;

  background-color: ${backgroundColor.popup};
  border-radius: 6px 0px 0px 6px;

  overflow-y: auto;
  padding: 3.5rem 4.5rem 0 4.5rem;
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 1180px;
  top: 18px;
  z-index: 999;
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const H2 = styled.h2`
  font-size: ${fontSize.titleVerySmall};
`;

export const Container = styled.div`
  border: 1px solid ${borderColor.light};

  border-radius: 6px;
  padding: 2.5rem;
`;
