import styled from 'styled-components';
import { borderColor, fontSize, fontWeight, textColors } from '../../../shared/constants';

export const H1 = styled.h1`
  font-size: ${fontSize.large};
  margin-right: 13px;

  &.border {
    border-right: 1px solid ${borderColor.light};
    padding-right: 1rem;
  }
`;

export const Text = styled.p`
  font-size: ${fontSize.mediumDefault};
  font-weight: ${fontWeight.medium};

  & > span {
    font-size: ${fontSize.medium};
    color: ${textColors.textLight};
    padding: 0 0.5rem;
  }
`;

export const ContentContainer = styled.div`
  padding: 3rem;
`;

export const DescContainer = styled.div`
  padding: 2rem;
  border-radius: 6px;
  border: 1px solid ${borderColor.light};
  margin-bottom: 2.5rem;
`;

export const WorkoutCon = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  & > td,
  th {
    white-space: nowrap;
    vertical-align: middle;
    border: 0;
  }
`;

export const Thead = styled.thead`
  background-color: ${borderColor.light};
  border-bottom: none;
`;

export const Tbody = styled.tbody`
  border: 1px solid ${borderColor.medium};
  border-top: none;

  &:last-child {
    border-bottom-left-radius: 1rem;
  }
`;

export const Th = styled.th`
  text-align: start;
  padding: 1.7rem 2rem;
  font-size: ${fontSize.medium};

  &:first-child {
    border-top-left-radius: 0.6rem;
  }

  &:last-child {
    border-top-right-radius: 0.6rem;
  }
`;

export const Tr = styled.tr`
  border-bottom: 1px solid ${borderColor.light};
`;

export const Td = styled.td`
  padding: 1.5rem 2rem;
`;
