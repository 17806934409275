/* eslint-disable react/jsx-indent */
/* eslint-disable no-unused-vars */

import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';

import { Divider, FlexContainer } from '../../../globalShared/CommonStyles';
import { getPrevDay, getNextDay, getWeekDays, getMonthSet, getYearSet } from '../../../services/utility/date-utils';
import {
  getDayOfMonth,
  getDayWeekDay,
  getMonthYear,
  getMothSmall,
  getSpecificDate,
  getToday,
  getYear,
  getYearMonthDay
} from '../../../services/utility/moment';
import {
  Block,
  Day,
  Button,
  H3,
  MonthContainer,
  MonthContainerHeader,
  P,
  Wrapper,
  MonthButton,
  MonthView,
  MonthDaysContainer,
  ToggleButton,
  WeekRow,
  FlexWraper,
  DayInMonth
} from './HeaderCalenderStyles';

import { ReactComponent as Arrow } from '../../assets/Dropdown.svg';
import { ReactComponent as Calender } from '../../assets/calendar.svg';
import { monthsShort, weekdays } from '../../../services/utility/dates';
import { datesInMonth } from '../../../services/utility/Calander';
import useTouchOutside from '../../hooks/useTouchOutside';

const HeaderCalander = ({ onChange, getDates, list }) => {
  const [state, setstate] = useState(getToday());
  const [weekDays, setWeekDays] = useState([]);
  const [DatesInMonth, setDatesInMonth] = useState([]);
  const [isWeek6, setisWeek6] = useState(false);
  const [monthView, setMonthView] = useState(false);
  const [monthSet, setMonthSet] = useState(getMonthSet(state));
  const [showYearView, setshowYearView] = useState(false);
  const [yearSet, setYearSet] = useState(getYearSet(state));
  const calanderRef = useRef();

  const handleClick = (type) => {
    let newDate = '';
    if (type === 'prev') {
      newDate = getPrevDay(state);
    }

    if (type === 'next') {
      newDate = getNextDay(state);
    }

    setstate(newDate);
  };

  useEffect(() => {
    if (monthView) {
      getDates({ startDate: DatesInMonth[0].date, endDate: DatesInMonth[41].date });
    } else {
      getDates({ startDate: weekDays[0], endDate: weekDays[6] });
    }
  }, [weekDays, DatesInMonth, monthView]);

  const isSelected = (date) => getYearMonthDay(date) === getYearMonthDay(state);

  const isAvailable = (date) => {
    if (list) {
      return list.some((item) => item.sessionDate === getYearMonthDay(date));
    }
    return false;
  };

  useEffect(() => {
    const Days = getWeekDays(state);
    setWeekDays(Days);

    const MonthDates = datesInMonth(state);
    setDatesInMonth(MonthDates);

    setMonthSet(getMonthSet(state));

    onChange(state);
    setYearSet(getYearSet(state));
  }, [state]);

  useEffect(() => {
    const BooleanArray = DatesInMonth.filter((_, i) => i > 34).map((day) => day.currentMonth);
    const result = BooleanArray.reduce((res, cur) => res || cur, false);
    setisWeek6(result);
  }, [DatesInMonth]);

  const changeMonth = (e) => setstate(e);

  const handleSetDate = (currentMonth, date) => {
    if (currentMonth) {
      setstate(date);
      setMonthView(false);
    }
  };

  const changeYear = (date) => {
    setstate(date);
  };

  const setYearMonth = (month) => {
    const year = getYear(state);
    const date = moment(`${month}-1-${year}`, 'MMM-DD-YYYY').toDate();
    setstate(date);
  };
  const closeCalander = () => {
    if (showYearView) {
      setshowYearView(false);
    }
  };

  useTouchOutside(calanderRef, closeCalander);
  //
  return (
    <div>
      <FlexContainer style={{ justifyContent: 'space-between' }}>
        <div ref={calanderRef}>
          <ToggleButton onClick={() => setshowYearView(!showYearView)}>
            <H3>{getMonthYear(state)}</H3>
            <Arrow />
          </ToggleButton>
          {showYearView && (
            <MonthContainer>
              <MonthContainerHeader>
                <P style={{ marginRight: '1rem' }}> Year</P>
                <Button prev onClick={() => changeYear(yearSet.prev)}>
                  <Arrow />
                </Button>
                <Block font='18' style={{ cursor: 'initial' }}>
                  {getYear(state)}
                </Block>
                <Button next onClick={() => changeYear(yearSet.next)}>
                  <Arrow />
                </Button>
              </MonthContainerHeader>
              <Wrapper>
                {monthsShort.map((day) => (
                  <Block active={getMothSmall(state) === day} key={day} onClick={() => setYearMonth(day)}>
                    {day}
                  </Block>
                ))}
              </Wrapper>
            </MonthContainer>
          )}
        </div>
        <FlexContainer>
          {!monthView ? (
            <>
              <Button prev onClick={() => handleClick('prev')}>
                <Arrow />
              </Button>
              <FlexContainer style={{ justifyContent: 'space-around', width: '500px' }}>
                {weekDays.map((day) => (
                  <Day key={day} isAvailable={isAvailable(day)} active={isSelected(day)} onClick={() => setstate(day)}>
                    {getDayWeekDay(day)}
                  </Day>
                ))}
              </FlexContainer>

              <Button next onClick={() => handleClick('next')}>
                <Arrow />
              </Button>
            </>
          ) : (
            <FlexContainer style={{ justifyContent: 'space-around', width: '500px' }}>
              {weekdays.map((day) => (
                <Day key={day}>{day}</Day>
              ))}
            </FlexContainer>
          )}
        </FlexContainer>
        <ToggleButton active={monthView} onClick={() => setMonthView(!monthView)}>
          <Calender />
          <Arrow />
        </ToggleButton>
      </FlexContainer>
      <Divider style={{ marginBottom: '0' }} />
      {monthView && (
        <MonthView>
          <MonthButton prev onClick={() => changeMonth(monthSet.prev)}>
            <Arrow />
            {getMonthYear(monthSet.prev)}
          </MonthButton>
          <MonthDaysContainer>
            {/* week1 to week5 */}
            <WeekRow>
              <FlexWraper>
                {DatesInMonth.filter((_, i) => i <= 34).map(({ currentMonth, date }) => (
                  <DayInMonth
                    isSelected={getYearMonthDay(date) === getYearMonthDay(state)}
                    key={date}
                    currentMonth={currentMonth}
                    onClick={() => handleSetDate(currentMonth, date)}
                    active={isAvailable(date)}
                  >
                    {getDayOfMonth(date)}
                  </DayInMonth>
                ))}
              </FlexWraper>
            </WeekRow>

            {/* week6 */}
            {isWeek6 && (
              <WeekRow>
                <FlexWraper>
                  {DatesInMonth.filter((_, i) => i > 34).map(({ currentMonth, date }) => (
                    <DayInMonth key={date} active={isAvailable(date)} currentMonth={currentMonth}>
                      {getDayOfMonth(date)}
                    </DayInMonth>
                  ))}
                </FlexWraper>
              </WeekRow>
            )}
          </MonthDaysContainer>

          <MonthButton next onClick={() => changeMonth(monthSet.next)}>
            {getMonthYear(monthSet.next)}
            <Arrow />
          </MonthButton>
        </MonthView>
      )}
    </div>
  );
};

export default HeaderCalander;
