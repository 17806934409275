/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

// data from store
import { Creators as PlayerCreators } from '../store';
import { Header, HeaderTxt, MsgContainer, CustomisedDivider } from './CoachPlayerDetailsStyles';

import { ButtonComponent, LoadingComponent, TabsComponenet } from '../../../../globalShared';
import { FlexContainer, CommingSoon, FeedContainer } from '../../../../globalShared/CommonStyles';

// icon
import { ReactComponent as BackIcon } from '../../../../shared/assets/back-header.svg';

// components
import PlayerDashboardView from '../../PlayerDashboardView/PlayerDashboardView';
import SkillsView from '../../SkillsVIew/SkillsView';
import StrengthView from '../../StrengthViews/RecentView/RecentView';
import NutritionView from '../../MealRecentView/MealRecentView';
import MindsetView from '../../MindsetView/MindsetView';
import PlayerDetailsModal from '../PlayerDetailsModal/ProfileModal';
import { shortenText } from '../../../../services/utility';

// component
const CoachPlayerDetailsView = (props) => {
  const { history } = props;
  const { id: playerId } = useParams();

  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState('Dashboard');
  const [showModal, setShowModal] = useState(false);

  const playerList = useSelector((state) => state.coachPlayers.playerList);
  const { playerDetails } = useSelector((state) => state.coachPlayers);
  const loading = useSelector((state) => state.coachPlayers.loading);
  const skillLoading = useSelector((state) => state.coachSkills.loading);
  const strengthLoading = useSelector((state) => state.coachStrengthFeed.loading);
  const nutritionLoading = useSelector((state) => state.coachMealRecent.loading);
  const mindLoading = useSelector((state) => state.coachMindset.loading);

  const handleTabChanged = (label) => {
    setCurrentTab(label);
  };

  // effect hooks
  useEffect(() => {
    dispatch(PlayerCreators.getCoachPlayerDetails({ _id: playerId }));
  }, [playerId]);

  useEffect(() => {
    handleTabChanged(currentTab);
  }, [playerList]);

  let renderPlayerData = null;
  // render tabs
  if (currentTab === 'Dashboard') {
    renderPlayerData = (
      <FeedContainer style={{ height: 'calc(100vh - 210px)' }}>
        <PlayerDashboardView playerId={playerId} />
      </FeedContainer>
    );
  } else if (currentTab === 'Skill') {
    renderPlayerData = (
      <FeedContainer style={{ height: 'calc(100vh - 210px)', paddingTop: '2rem' }}>
        <SkillsView playerId={playerId} />
      </FeedContainer>
    );
  } else if (currentTab === 'Strength') {
    renderPlayerData = (
      <FeedContainer style={{ height: 'calc(100vh - 210px)' }}>
        <StrengthView playerId={playerId} />
      </FeedContainer>
    );
  } else if (currentTab === 'Nutrition') {
    renderPlayerData = (
      <FeedContainer style={{ height: 'calc(100vh - 210px)' }}>
        <NutritionView playerId={playerId} />
      </FeedContainer>
    );
  } else if (currentTab === 'Mindset') {
    renderPlayerData = (
      <FeedContainer style={{ height: 'calc(100vh - 210px)', paddingTop: '2rem' }}>
        <MindsetView playerId={playerId} />
      </FeedContainer>
    );
  } else {
    renderPlayerData = (
      <MsgContainer>
        <CommingSoon>Coming Soon</CommingSoon>
      </MsgContainer>
    );
  }

  const handleBack = () => {
    history.goBack();
  };

  return (
    <>
      {(loading || skillLoading || strengthLoading || nutritionLoading || mindLoading) && <LoadingComponent />}
      {showModal && <PlayerDetailsModal data={playerDetails} onCancel={() => setShowModal(false)} />}
      <Header>
        <FlexContainer>
          <BackIcon style={{ cursor: 'pointer' }} onClick={handleBack} />
          <HeaderTxt>{shortenText(playerDetails?.fullName, 50, '...')}</HeaderTxt>
        </FlexContainer>
        <ButtonComponent buttonText='See Details' buttonClick={() => setShowModal(true)} />
      </Header>
      <CustomisedDivider />
      <TabsComponenet getactiveTab={handleTabChanged}>
        <div label='Dashboard'>{renderPlayerData}</div>
        <div label='Skill'>{renderPlayerData}</div>
        <div label='Strength'>{renderPlayerData}</div>
        <div label='Nutrition'>{renderPlayerData}</div>
        <div label='Mindset'>{renderPlayerData}</div>
      </TabsComponenet>
    </>
  );
};

export default CoachPlayerDetailsView;
