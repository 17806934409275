/* eslint-disable no-inner-declarations */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable no-shadow */
/*
 * saga.js file for
 * Author:
 * 1. manikanta
 *
 * @copyright of Panorbit Services LLP.
 */

import { all, put, call } from 'redux-saga/effects';
import { Creators } from './action';
import { Creators as PersonalCreators } from '../../PersonalDetails/store';
import { Creators as ProfessionalCreators } from '../../ProfessionalDetailsView/store';

import { WebService } from '../../../services/axios/webServices';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { cookie } from '../../../services/cookies/cookieServices';
import { setUserDataToLocal } from '../../../services/cookies/helper';

export function* setUserMetaSaga({ payload }) {
  try {
    const { health, injuries } = payload;
    yield put(Creators.setUserMetaStart());

    const files = [];

    function* fileFn(item, mediaType) {
      if (item.file[0]) {
        const fileFormData = new FormData();
        fileFormData.append('mediaFile', item.file[0]);
        const response = yield WebService.post(apiEndPoints.fileUploadApi, fileFormData);
        const { url, fileName } = response.data?.data;

        const obj = {
          mediaUrl: url,
          mediaType,
          description: item.description,
          fileName
        };

        files.push(obj);
      } else {
        const obj = {
          mediaType,
          mediaUrl: null,
          fileName: null,
          description: item.description
        };
        files.push(obj);
      }
    }

    if (health) {
      yield all(health.map((item) => call(fileFn, item, 'Health')));
    }

    if (injuries) {
      yield all(injuries.map((item) => call(fileFn, item, 'Injury')));
    }

    // const fullURL = yield `${apiEndPoints.personalDetails}`;

    // eslint-disable-next-line no-undef
    const dataToApi = new FormData();
    dataToApi.append('firstName', payload.fName);
    dataToApi.append('lastName', payload.lName);
    dataToApi.append('age', payload.age);
    dataToApi.append('gender', payload.gender);
    dataToApi.append('weight', payload.weight);
    dataToApi.append('height', `${payload.feet}.${payload.inches}`);
    dataToApi.append('level', payload.level);
    dataToApi.append('goals', payload.goals);
    dataToApi.append('about', payload.about);
    dataToApi.append('phoneNumber', payload.phoneNumber);
    dataToApi.append('profilePicture', payload.profilePicture || '');
    dataToApi.append('mealPreference', payload.mealPreference);
    dataToApi.append('foodAllergy', payload.foodAllergy);
    dataToApi.append('practiceStartTime', `${payload.practiceStartTime}-${payload.practiceStartTimeMeredian}`);
    dataToApi.append('practiceEndTime', `${payload.practiceEndTime}-${payload.practiceEndTimeMeredian}`);
    dataToApi.append('trainingStartTime', `${payload.trainingStartTime}-${payload.trainingStartTimeMeredian}`);
    dataToApi.append('trainingEndTime', `${payload.trainingEndTime}-${payload.trainingEndTimeMeredian}`);
    dataToApi.append('trainingAge', payload.trainingAge);

    const [injuryRes, healthRes, formRes] = yield all([
      !!injuries &&
        WebService.post(apiEndPoints.uplaodFiles, {
          files: files.filter((item) => item.mediaType === 'Injury')
        }),
      !!health &&
        WebService.post(apiEndPoints.uplaodFiles, {
          files: files.filter((item) => item.mediaType === 'Health')
        }),
      WebService.post(apiEndPoints.personalDetails, dataToApi)
    ]);

    if (formRes.status >= 200 && formRes.status < 300) {
      if ((injuryRes.status >= 200 && injuryRes.status < 300) || !injuryRes) {
        if ((healthRes.status >= 200 && healthRes.status < 300) || !healthRes) {
          yield put(Creators.setUserMetaSuccess());

          const cookies = cookie.get('userDetails');
          yield setUserDataToLocal({ ...cookies, metaUpdated: true });

          // reset store values
          yield put(ProfessionalCreators.resetProfessionalDetails());
          yield put(PersonalCreators.resetPersonalDetails());
        } else {
          throw healthRes;
        }
      } else {
        throw injuryRes;
      }
    } else {
      throw formRes;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.setUserMetaFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.setUserMetaFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.setUserMetaFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.setUserMetaFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.setUserMetaFailure(payload));
    }
  }
}

export function* fileUploadSaga({ payload }) {
  try {
    const { mediaType, files, arrayType = true } = payload;

    yield put(Creators.fileUploadStart());
    const fullURL = yield `${apiEndPoints.multifileupload}`;

    const dataToApi = new FormData();
    dataToApi.append('mediaType', mediaType);

    for (const item of files) {
      dataToApi.append('description[]', item.description);
      if (arrayType) {
        dataToApi.append('files[]', item.file[0]);
      } else {
        dataToApi.append('files[]', item.file);
      }
    }

    const response = yield WebService.post(fullURL, dataToApi);
    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.fileUploadSuccess());
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.fileUploadFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.fileUploadFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.fileUploadFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.fileUploadFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.fileUploadFailure(payload));
    }
  }
}
