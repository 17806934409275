import styled from 'styled-components';

export const CardsContainer = styled.div`
  padding: 1rem;
`;

export const CardContainer = styled.div`
  width: 100%;
  height: 400px;
  margin-bottom: 1rem;
`;

export const ChartCanvasWrapper = styled.div`
  position: relative;
  height: 100%;
  // & > canvas {
  //   width: 100% !important;
  //   height: 275px !important;
  // }
`;
