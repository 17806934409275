/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { LogoHeaderComponent, ButtonComponent } from '../../globalShared';

import { ReactComponent as Success } from '../../shared/assets/success.svg';

// styles
import { Container, FormContainer, Heading, SubHeading } from './ThankyouView.styles';

// text constants
const HeadingText = 'Thank you';
const SubHeadingText = 'for filling the information ';

const SuccessView = () => {
  const userType = useSelector((state) => state.login.userType);
  const history = useHistory();

  const onclickHandler = () => {
    history.push(`/${userType}/`);
  };

  return (
    <>
      <LogoHeaderComponent />
      <Container>
        <FormContainer>
          <Success />
          <Heading>{HeadingText}</Heading>
          <SubHeading>{SubHeadingText}</SubHeading>

          <ButtonComponent buttonText='Home' isButtonAction={true} buttonClick={onclickHandler} />
        </FormContainer>
      </Container>
    </>
  );
};

export default SuccessView;
