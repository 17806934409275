import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  loading: false,
  error: null,
  success: false,
  comments: {},
  reloadComments: false,
  language: 'English'
};

const addCommentStart = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  reloadComments: false
});

const addCommentSuccess = (state = INITIAL_STATE) => ({ ...state, reloadComments: true });

const setError = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    reloadComments: false,
    error
  };
};

const resetData = () => ({ ...INITIAL_STATE });

const HANDLERS = {
  [Types.ADD_COMMENT_START]: addCommentStart,
  [Types.ADD_COMMENT_SUCCESS]: addCommentSuccess,

  [Types.SET_ERROR]: setError,
  [Types.RESET_DATA]: resetData
};

export const commonReducer = createReducer(INITIAL_STATE, HANDLERS);
