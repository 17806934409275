/* eslint-disable no-undef */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';

import { useDispatch } from 'react-redux';
import { Creators as PlayerMeditationCreators } from '../../../views/Player/MindsetView/store';

// styles
import {
  AudioPlayerWrapper,
  DurationContainer,
  TimeInfo,
  TimeElapsed,
  TotalDuration,
  SaveWrapper
} from './AudioPlayerStyles';

// icons
import { ReactComponent as MeditationPlayIcon } from '../../assets/meditation_play_1.svg';
import { ReactComponent as MeditationPauseIcon } from '../../assets/meditation_pause_1.svg';
import { ReactComponent as MeditationDurationIcon } from '../../assets/meditation_duration.svg';

import ButtonComponent from '../ButtonComponent';

const AudioControls = ({ isPlaying, onPlayPauseClick }) => (
  <>
    {isPlaying ? (
      <MeditationPauseIcon style={{ cursor: 'pointer' }} onClick={() => onPlayPauseClick(false)} />
    ) : (
      <MeditationPlayIcon style={{ cursor: 'pointer' }} onClick={() => onPlayPauseClick(true)} />
    )}
  </>
);

const AudioPlayer = (props) => {
  const { checkDate, currentMeditation, audioSrc, audioDuration, meditationDetailsClose } = props;

  const [trackProgress, setTrackProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const dispatch = useDispatch();
  const todaysDate = moment().format('YYYY-MM-DD');

  // Refs
  const audioRef = useRef(new Audio(audioSrc));
  const intervalRef = useRef();

  let duration = audioDuration;
  const currentDuration = audioRef.current.duration;
  if (currentDuration) {
    duration = currentDuration;
  } else {
    duration = audioDuration;
  }

  const startTimer = () => {
    // Clear any timers already running
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      if (audioRef.current.ended) {
        setTrackProgress(Math.floor(audioRef.current.duration));
        setIsPlaying(false);
      } else {
        const time = Math.floor(audioRef.current.currentTime);
        setTrackProgress(time);
      }
    }, [1000]);
  };

  useEffect(() => {
    if (audioDuration) {
      // Clear any timers already running
      clearInterval(intervalRef.current);
      audioRef.current.currentTime = duration;
      setTrackProgress(audioRef.current.currentTime);
      if (!isPlaying) {
        setIsPlaying(true);
      }
      startTimer();
    }
  }, []);

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
      startTimer();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    if (trackProgress) {
      dispatch(PlayerMeditationCreators.setCurrentTrackProgress({ currentTrackProgress: trackProgress }));
    }
  }, [trackProgress]);

  useEffect(() => {
    // Pause and clean up on unmount
    return () => {
      audioRef.current.pause();
      clearInterval(intervalRef.current);
    };
  }, []);

  const elapsedMeditationMinutes = Math.floor(trackProgress / 60);
  const elapsedMeditationSeconds = trackProgress % 60;

  const totalMeditationMinutes = Math.floor(currentDuration / 60) || 0;
  const totalMeditationSeconds = Math.floor(currentDuration - totalMeditationMinutes * 60) || 0;

  let basicMeditation = null;
  let guidedMeditation = null;
  let expertMeditation = null;

  const handleSave = () => {
    if (!isPlaying) {
      // save duration
      if (currentMeditation.name === 'basicMeditation') {
        basicMeditation = trackProgress;
      } else if (currentMeditation.name === 'guidedMeditation') {
        guidedMeditation = trackProgress;
      } else if (currentMeditation.name === 'expertMeditation') {
        expertMeditation = trackProgress;
      }

      if (currentMeditation.id) {
        dispatch(
          PlayerMeditationCreators.editPlayerMeditationDuration({
            _id: currentMeditation.id,
            meditationName: currentMeditation.name,
            basicMeditation,
            guidedMeditation,
            expertMeditation
          })
        );
      } else {
        dispatch(
          PlayerMeditationCreators.setPlayerMeditationDuration({
            meditationName: currentMeditation.name,
            basicMeditation,
            guidedMeditation,
            expertMeditation
          })
        );
      }

      if (trackProgress) {
        meditationDetailsClose();
      } else if (trackProgress === 0 && checkDate === todaysDate) {
        meditationDetailsClose();
      }
    }
  };

  return (
    <AudioPlayerWrapper>
      <AudioControls isPlaying={isPlaying} onPlayPauseClick={setIsPlaying} />
      <DurationContainer>
        <MeditationDurationIcon />
        <TimeInfo>
          <TimeElapsed>{`${elapsedMeditationMinutes} Min ${elapsedMeditationSeconds} Sec`}</TimeElapsed>
          <TotalDuration>{` / ${totalMeditationMinutes} Min ${totalMeditationSeconds} Sec`}</TotalDuration>
        </TimeInfo>
      </DurationContainer>
      <SaveWrapper>
        <ButtonComponent
          buttonText='Save'
          isButtonAction={checkDate === todaysDate && !isPlaying && trackProgress !== 0}
          buttonClick={handleSave}
        />
      </SaveWrapper>
    </AudioPlayerWrapper>
  );
};

export default AudioPlayer;
