/*
 * action.js file for users view
 * Author:
 * 1.Manikanta
 *
 * @copyright of Panorbit Services LLP.
 */

import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getAdminUsersList: ['payload'],
  getAdminUsersListStart: ['payload'],
  getAdminUsersListSuccess: ['payload'],
  getAdminUsersListFailure: ['payload'],

  setAdminUsersList: ['payload'],
  setAdminUsersListStart: ['payload'],
  setAdminUsersListSuccess: ['payload'],
  setAdminUsersListFailure: ['payload'],

  adminDeleteUser: ['payload'],
  adminDeleteUserStart: ['payload'],
  adminDeleteUserSuccess: ['payload'],
  adminDeleteUserFailure: ['payload'],

  setAdminBulkUsersList: ['payload'],
  setAdminBulkUsersListStart: ['payload'],
  setAdminBulkUsersListSuccess: ['payload'],
  setAdminBulkUsersListFailure: ['payload'],

  adminUpdateUserDetails: ['payload'],
  adminUpdateUserDetailsStart: ['payload'],
  adminUpdateUserDetailsSuccess: ['payload'],
  adminUpdateUserDetailsFailure: ['payload'],

  getAdminUserDetails: ['payload'],
  getAdminUserDetailsStart: ['payload'],
  getAdminUserDetailsSuccess: ['payload'],
  getAdminUserDetailsFailure: ['payload'],
  resetUserDetails: null,

  adminSearchUser: ['payload'],
  adminSearchUserStart: ['payload'],
  adminSearchUserSuccess: ['payload'],
  adminSearchUserFailure: ['payload'],

  reset: ['payload'],
  resetError: null,
  resetSuccess: null
});

export { Types, Creators };
