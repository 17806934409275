/* eslint-disable no-param-reassign */
/* eslint-disable prefer-const */
import React from 'react';
import { Line } from 'react-chartjs-2';

import { ChartCanvasWrapper } from './HomeStyles';
import { getPastDays } from './utlity';
import { getDay, getMothSmall, getYearMonthDay } from '../../../services/utility/moment';
import { _converIntoKAndM } from '../../../services/utility/commurize';

const NutritionAdherence = ({ nutrition, nutritionStats }) => {
  const { dataType: type, value: days } = nutrition;
  const pastDays = getPastDays({ type, days });

  pastDays.forEach((item) => {
    let filterDay = [];

    if (type !== 'month') {
      filterDay = nutritionStats.filter((stat) => getYearMonthDay(stat.date) === item.MMDDYY);
    } else {
      filterDay = nutritionStats.filter((stat) => getMothSmall(stat.date) === item.MM);
    }
    item.Intake = filterDay.length > 0 ? filterDay[0].caloriesIntake : null;
    item.Target = filterDay.length > 0 ? filterDay[0].caloriesTarget : null;
  });

  let nutritionDates = [];
  let caloriesIntakeData = [];
  let caloriesTargetData = [];

  pastDays.reverse().forEach((item) => {
    if (type !== 'month') {
      nutritionDates.push(getDay(item.MMDDYY));
    } else {
      nutritionDates.push(getMothSmall(item.MMDDYY));
    }
    caloriesIntakeData.push(item.Intake);
    caloriesTargetData.push(item.Target);
  });

  const chartData = (canvas) => {
    const ctx = canvas.getContext('2d');
    const takenGradient = ctx.createLinearGradient(0, canvas.height, 0, 0);
    takenGradient.addColorStop(0, '#21D0B300');
    takenGradient.addColorStop(1, '#24243D');

    const targetGradient = ctx.createLinearGradient(0, canvas.height, 0, 0);
    targetGradient.addColorStop(0, '#7AC943');
    targetGradient.addColorStop(1, '#24243D');

    return {
      labels: nutritionDates,
      datasets: [
        {
          label: 'Calories Taken',
          data: caloriesIntakeData,
          fill: true,
          backgroundColor: takenGradient,
          borderColor: 'rgba(75,192,192,1)',
          lineTension: 0
        },
        {
          label: 'Target Calories',
          data: caloriesTargetData,
          fill: true,
          backgroundColor: targetGradient,
          borderColor: '#7AC943',
          lineTension: 0
        }
      ]
    };
  };

  const options = {
    tooltips: {
      enabled: true
    },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        boxWidth: 12
      }
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            zeroLineBorderDash: [5, 5],
            color: 'rgba(0, 0, 0, 0)',
            zeroLineColor: 'rgba(255, 255, 255, 0.3)'
          },
          spanGaps: false
        }
      ],
      yAxes: [
        {
          gridLines: {
            color: 'rgba(255, 255, 255, 0.3)',
            zeroLineColor: 'rgba(255, 255, 255, 0.3)',
            zeroLineBorderDash: [5, 5],
            borderDash: [5, 5]
          },

          scaleLabel: {
            display: true,
            labelString: 'Calories Taken (Kcal)'
          },

          ticks: {
            beginAtZero: true,
            // eslint-disable-next-line no-unused-vars
            userCallback(value, index, labels) {
              return _converIntoKAndM(value);
            }
          }
        }
      ]
    }
  };

  return (
    <ChartCanvasWrapper>
      <Line data={chartData} options={options} />
    </ChartCanvasWrapper>
  );
};

export default NutritionAdherence;
