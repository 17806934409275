import React from 'react';
import { useSelector } from 'react-redux';
import { Divider, FlexContainer } from '../../../globalShared/CommonStyles';
import {
  Active,
  CardContainer,
  Progress,
  Complete,
  ProgressContainer,
  Status,
  Title,
  Thumbnail,
  P,
  Details,
  ImageStack
} from './StrengthSessionCardStyles';
import { ReactComponent as ClockIcon } from '../../assets/clock.svg';
import { ReactComponent as DurationIcon } from '../../assets/time_clock.svg';
import Completed from '../../assets/completed.svg';
import Pending from '../../assets/work_inprogress.svg';

import CommentsFooter from '../CommetsFooter';
import CommentInputComponent from '../CommentInputComponent';
import { localString } from '../../../services/locallization/localStrings';
import ProgressRing from '../../../views/Coach/MealRecentView/ProgressRing';
import { shortenText } from '../../../services/utility';

const StrengthSessionCard = ({ data, onClick, active, submitComment, onShowComments, comments }) => {
  const {
    sessionId,
    assignedBy,
    duration,
    progressStatus,
    preferrableTimeEnd,
    preferrableTimeStart,
    progressPercentage,
    commentCount,
    remark,
    title,
    thumbUrl
  } = data;

  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  return (
    <CardContainer onClick={() => onClick(sessionId)} active={active}>
      {active && <Active />}
      <FlexContainer style={{ justifyContent: 'space-between' }}>
        <FlexContainer>
          {progressStatus === 'New' ? (
            <Complete src={Pending} />
          ) : (
            <>
              {progressPercentage >= 100 ? (
                <Complete src={Completed} />
              ) : (
                <ProgressContainer>
                  <ProgressRing radius={27} stroke={2} progress={progressPercentage} />
                  <Progress>{`${Math.round(progressPercentage)}%`}</Progress>
                </ProgressContainer>
              )}
            </>
          )}

          <Title>{title}</Title>
        </FlexContainer>
        <Status>{progressStatus}</Status>
      </FlexContainer>

      <Details>
        <FlexContainer style={{ justifyContent: 'space-between' }}>
          <div>
            {preferrableTimeStart && (
              <FlexContainer style={{ marginBottom: '1rem' }}>
                <ClockIcon />
                <P style={{ marginLeft: '0.5rem' }}>
                  {`${preferrableTimeStart}  ${preferrableTimeEnd ? `to ${preferrableTimeEnd}` : ''}`}
                </P>
              </FlexContainer>
            )}

            {duration && duration.trim() && (
              <FlexContainer style={{ marginBottom: '1rem' }}>
                <DurationIcon />
                <P style={{ marginLeft: '0.5rem' }}>{duration}</P>
              </FlexContainer>
            )}

            <FlexContainer style={{ marginBottom: '1rem' }}>
              <P light>Assigned By:</P>
              <P style={{ marginLeft: '0.5rem' }}>{shortenText(assignedBy, 15, '...')}</P>
            </FlexContainer>
          </div>
          <ImageStack>
            <Thumbnail src={thumbUrl[0]} className='before' />
            <Thumbnail src={thumbUrl[1]} className='after' />
            <Thumbnail src={thumbUrl[2]} />
          </ImageStack>
        </FlexContainer>
        {remark && (
          <>
            <P light style={{ marginBottom: '1rem' }}>
              Remarks:
            </P>
            <P className='word'>{remark}</P>
          </>
        )}
      </Details>
      <Divider />
      <CommentsFooter
        _id={sessionId}
        commentCount={commentCount}
        commentsList={comments}
        onClick={() => onShowComments(sessionId)}
      />
      <CommentInputComponent
        placeholderText={string.enterCommentsHere}
        id={sessionId}
        onSubmit={(content, id) => submitComment({ content, id })}
      />
    </CardContainer>
  );
};

export default StrengthSessionCard;
