import styled from 'styled-components';
import { NavLink, Link } from 'react-router-dom';

import { accentColor, fontSize, gradient } from '../../constants';

export const Container = styled.div`
  width: ${(props) => (!props.collapse ? '75px' : '215px')};

  display: flex;
  flex-direction: column;
  position: relative;

  height: calc(100vh - 70px);
  background-color: #00000042;

  transition: 0.3s;
`;

export const LinkItem = styled(NavLink)`
  font-size: ${fontSize.mediumDefault};
  height: 60px;
  padding: 0 2rem;
  position: relative;

  display: flex;
  align-items: center;
  & > svg {
    height: 20px;
    margin-right: 1.5rem;
  }

  &.active {
    background-color: #00000026;

    & > svg > g > path {
      fill: ${accentColor.primary};
    }

    &::after {
      content: '';

      height: 100%;
      width: 2px;
      position: absolute;

      top: 0;
      left: 0;
      background: ${gradient.primary};
    }
  }
`;

export const HomeLink = styled(Link)`
  font-size: ${fontSize.mediumDefault};
  height: 60px;
  padding: 0 2rem;
  position: relative;

  display: flex;
  align-items: center;

  & > svg {
    height: 20px;
    margin-right: 1.5rem;
  }

  &.active {
    background-color: #00000026;

    & > svg > path {
      fill: ${accentColor.primary};
    }

    &::after {
      content: '';

      height: 100%;
      width: 2px;
      position: absolute;

      top: 0;
      left: 0;
      background: ${gradient.primary};
    }
  }
`;

export const Help = styled(Link)`
  font-size: ${fontSize.mediumDefault};
  height: 60px;
  padding: 0 2rem;

  position: absolute;
  bottom: 10px;

  display: flex;
  align-items: center;
  & > svg {
    height: 20px;
    margin-right: 1.5rem;
  }
`;

export const ToggleButton = styled.div`
  height: 60px;

  padding: 2rem;
  cursor: pointer;
`;
