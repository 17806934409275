/* eslint-disable no-confusing-arrow */
import styled from 'styled-components';
import { backgroundColor, borderColor, fontSize, fontWeight, textColors } from '../../../shared/constants';

export const ModalContainer = styled.div`
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  justify-content: flex-end;

  background-color: ${backgroundColor.popupBg};
  z-index: 99999;
`;

export const ContentContainer = styled.div`
  height: 100%;
  width: 782px;

  padding: 2.4rem;

  background-color: ${backgroundColor.popup};
  border-radius: 6px 0px 0px 6px;

  overflow-y: auto;
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 806px;
  top: 2rem;
  z-index: 999;
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  & > h1 {
    font-size: ${fontSize.titleVerySmall};
  }
`;

// mood details
export const MoodContainer = styled.div`
  width: 100%;
  height: auto;
  border: 1px solid ${borderColor.light};

  border-radius: 6px;
  margin-bottom: 45px;
  padding: 18px 14px;
`;

export const MoodHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-bottom: 16px;
  border-bottom: 1px solid ${borderColor.light};
`;

export const MoodWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const MoodTitle = styled.p`
  display: inline-block;
  padding-left: 1rem;
  font-size: 12px;
  text-transform: capitalize;
`;

export const MoodTime = styled.p`
  display: inline-block;
  font-size: 12px;

  color: #f6f6f6;
  opacity: 0.4;
`;

export const MoodBody = styled.div`
  padding: 12px 0;
  border-bottom: 1px solid ${borderColor.light};

  & > p {
    font-size: 14px;
    color: #f6f6f6;
  }
`;

export const CommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
`;

export const NoComments = styled.p`
  font-size: 12px;
  font-weight: ${fontWeight.medium};

  text-transform: capitalize;
  color: ${textColors.textLight};
`;

export const CommentInputContainer = styled.div`
  & > div > div > input {
    font-size: 12px;
  }
`;

// mood stats
export const StatsHeader = styled.p`
  font-size: 16px;
  font-weight: ${fontWeight.medium};
  padding-bottom: 16px;
`;

export const ScrollContainer = styled.div``;

// mood filter
export const FilterMood = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

export const Filter = styled.div`
  border-radius: 13px;
  padding: 5px 15px;
  background: #c4c4c41a;
  margin-bottom: 12px;

  cursor: pointer;
  border: 1px solid ${(props) => (props.active === true ? '#B5B7BC' : 'transparent')};

  & > p {
    display: inline-block;
    font-size: 12px;
    font-weight: ${fontWeight.medium};
    text-transform: capitalize;
  }
`;

// mood details accordion
export const DayContainer = styled.div`
  border: 1px solid ${borderColor.light};
  border-radius: 6px;
  padding: 12px 18px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 1rem;

  &:hover {
    cursor: pointer;
  }
`;

export const Rotate = styled.div`
  & > svg {
    transform: rotateX(180deg);
  }
`;

export const DayStatsHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DayStatsWrapper = styled.div`
  display: flex;
  gap: 2.5rem;
`;

export const DayStats = styled.div`
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
`;

export const Day = styled.p`
  display: inline-block;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: ${fontWeight.medium};
  color: #ffffff;
  opacity: 0.9;
`;

export const MoodDate = styled.p`
  display: inline-block;
  font-size: 14px;
  font-weight: ${fontWeight.medium};
  color: #ffffff;
  opacity: 0.5;
`;

export const DayMood = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

export const MoodDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;

  background: ${(props) => props.delightful && '#70C54D'};
  background: ${(props) => props.happy && '#1EA79C'};
  background: ${(props) => props.good && '#E6D544'};
  background: ${(props) => props.sad && '#EC8541'};
  background: ${(props) => props.tired && '#DF4A37'};
  background: ${(props) => props.awful && '#E23433'};
`;

export const TabContainer = styled.div`
  padding: 2rem 0px;
  height: calc(100vh - 158px);
  overflow-y: auto;
  padding-right: 1rem;
`;
