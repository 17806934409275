/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from './store';
import { Creators as VideoPlayerCreators } from '../VideoplayerView/store';

// styles
import {
  FlexContainer,
  Table,
  TableRow,
  VideoTitle,
  VideoType,
  ManageContainer,
  ManageContent,
  CloseButton,
  Header,
  Title,
  FilterWrapper,
  CollectionFilterContainer,
  CreateContainer,
  CollectionFilter,
  CollectionItemsContainer,
  CollectionName,
  CollectionMenu,
  VideoCount,
  ExpandContainer,
  Collapse,
  NoVideos,
  VideoOptions,
  VideoMenu
} from './ManageCollectionModalStyles';

import { TableStyles } from '../../../globalShared/CommonStyles';

// icons
import { ReactComponent as CloseIcon } from '../../../shared/assets/Close.svg';
import { ReactComponent as CreateIcon } from '../../../shared/assets/add_for_compare.svg';
import { ReactComponent as ExpandIcon } from '../../../shared/assets/Dropdown.svg';
import { ReactComponent as MenuIcon } from '../../../shared/assets/3dot_menu.svg';

import EditCollectionModal from './EditCollectionModal';
import DeleteCollectionModal from './DeleteCollectionModal';
import AddToAnotherCollectionModal from './AddToAnotherCollectionModal';
import { AvatarComponent } from '../../../globalShared';
import { shortenText } from '../../../services/utility';

const { Th, Td } = TableStyles;

const ManageCollectionModal = () => {
  const dispatch = useDispatch();

  const [expand, setExpand] = useState(false);
  const [showCollectionOptionId, setShowCollectionOptionId] = useState(null);
  const [showVideoOptionId, setShowVideoOptionId] = useState(null);
  const [currentCollectionName, setCurrentCollectionName] = useState('');
  const [currentVideoId, setCurrentVideoId] = useState('');
  const [showAddtoCollection, setshowAddtoCollection] = useState(false);
  // state values
  const collectionsList = useSelector((state) => state.playerSkills.collectionsList);
  const collectionVideos = useSelector((state) => state.playerSkills.collectionVideos);
  const collectionVideosList = collectionVideos.length ? collectionVideos[0] : [];

  const showEditCollection = useSelector((state) => state.playerSkills.showEditCollection);
  const showDeleteCollection = useSelector((state) => state.playerSkills.showDeleteCollection);

  const collectionReload = useSelector((state) => state.playerSkills.collectionReload);

  const [collectionFilter, setCollectionFilter] = useState(collectionsList[0]);
  const [toggleVideosReload, setToggleVideosReload] = useState(false);
  const loading = useSelector((state) => state.playerSkills.collectionLoading);

  // effect hooks
  useEffect(() => {
    if (collectionReload) {
      dispatch(Creators.getPlayerCollectionsList());
    }
  }, [collectionReload]);

  useEffect(() => {
    if (collectionFilter) {
      dispatch(
        Creators.getPlayerCollectionVideosList({
          _id: collectionFilter._id,
          page: 1,
          count: collectionFilter.videoCount ? collectionFilter.videoCount : 10
        })
      );
    }
  }, [collectionFilter]);

  useEffect(() => {
    if (collectionsList.length) {
      setCollectionFilter(collectionsList[0]);
    }
  }, [collectionsList]);

  const handleShowCollectionOption = (_id) => {
    // setShowCollectionOptionId(_id);

    if (showCollectionOptionId) {
      setShowCollectionOptionId(null);
    } else {
      setShowCollectionOptionId(_id);
    }
  };

  const handleShowVideoOption = (_id) => {
    setShowVideoOptionId(_id);

    if (showVideoOptionId) {
      setShowVideoOptionId(null);
    } else {
      setShowVideoOptionId(_id);
    }
  };

  const handleSelectCollection = (collection) => {
    setCollectionFilter(collection);
    setCurrentCollectionName(collection.name);
  };

  const handleClose = () => {
    setToggleVideosReload(!toggleVideosReload);
    dispatch(VideoPlayerCreators.setVideosReload({ videosReload: toggleVideosReload }));
    dispatch(Creators.setShowManageCollectionModal({ showManageCollection: false }));
  };

  const createCollection = () => {
    dispatch(Creators.setShowCreateCollectionModal({ showCreateCollection: true }));
    dispatch(Creators.setShowManageCollectionModal({ showManageCollection: false }));
  };

  return (
    <>
      {/*  */}
      <ManageContainer>
        <ManageContent>
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>

          <Header>
            <Title>Manage Collection</Title>
            <CreateContainer onClick={createCollection}>
              <CreateIcon />
              <p>Create</p>
            </CreateContainer>
          </Header>

          <FilterWrapper expand={expand}>
            <CollectionFilterContainer expand={expand}>
              {collectionsList &&
                collectionsList.map((collection) => {
                  const { name, _id, videoCount } = collection;
                  return (
                    <CollectionFilter
                      key={_id}
                      isActive={collectionFilter?.name === name}
                      onClick={() => handleSelectCollection(collection)}
                      onMouseLeave={() => setShowCollectionOptionId(null)}
                    >
                      <CollectionItemsContainer>
                        <CollectionName>{shortenText(name, 12, '...')}</CollectionName>
                        <MenuIcon className='dots' onClick={() => handleShowCollectionOption(_id)} />
                        <CollectionMenu showCollection={showCollectionOptionId === _id}>
                          <div
                            role='presentation'
                            onClick={() => {
                              dispatch(Creators.setCurrentCollectionId({ currentCollectionId: _id }));
                              dispatch(Creators.setShowEditCollectionModal({ showEditCollection: true }));
                              setShowCollectionOptionId(null);
                            }}
                          >
                            Edit
                          </div>
                          <div
                            role='presentation'
                            onClick={() => {
                              dispatch(Creators.setCurrentCollectionId({ currentCollectionId: _id }));
                              dispatch(Creators.setShowDeleteCollectionModal({ showDeleteCollection: true }));
                              setShowCollectionOptionId(null);
                            }}
                          >
                            Delete Collection
                          </div>
                        </CollectionMenu>
                      </CollectionItemsContainer>

                      <VideoCount>
                        {videoCount}
                        <p>videos</p>
                      </VideoCount>
                    </CollectionFilter>
                  );
                })}
            </CollectionFilterContainer>
            <ExpandContainer onClick={() => setExpand(!expand)}>
              <p>{expand ? 'Collapse' : 'Expand'}</p>
              {expand ? (
                <Collapse>
                  <ExpandIcon />
                </Collapse>
              ) : (
                <ExpandIcon />
              )}
            </ExpandContainer>
          </FilterWrapper>

          {/* Collection Videos */}

          {collectionVideosList.length ? (
            <Table>
              <thead>
                <tr>
                  <Th style={{ paddingLeft: '66px' }}>Title</Th>
                  <Th>Upload date</Th>
                  <Th>Duration</Th>
                  <Th />
                </tr>
              </thead>
              <tbody>
                {collectionVideosList.length &&
                  collectionVideosList.map((video) => {
                    const { _id, title, access, uploadDate, duration, thumbUrl } = video;
                    const newUploadDate = moment(uploadDate).format('D MMM YY').toLowerCase();
                    const videoMinutes = Math.floor(duration / 60).toLocaleString('en-US', {
                      minimumIntegerDigits: 2,
                      useGrouping: false
                    });
                    const videoSeconds = Math.floor(duration % 60).toLocaleString('en-US', {
                      minimumIntegerDigits: 2,
                      useGrouping: false
                    });

                    return (
                      <TableRow key={_id}>
                        <Td style={{ paddingLeft: '0', maxWidth: '200px' }}>
                          <FlexContainer>
                            <AvatarComponent url={thumbUrl} />
                            <div>
                              <VideoTitle>{shortenText(title, 10, '...')}</VideoTitle>
                              <VideoType>{access}</VideoType>
                            </div>
                          </FlexContainer>
                        </Td>

                        <Td>{newUploadDate}</Td>
                        <Td>{`${videoMinutes}:${videoSeconds}`}</Td>

                        <VideoOptions onClick={() => handleShowVideoOption(_id)}>
                          <MenuIcon />
                          <VideoMenu
                            showVideo={showVideoOptionId === _id}
                            onMouseLeave={() => handleShowVideoOption('')}
                          >
                            <div
                              role='presentation'
                              onClick={() => {
                                dispatch(Creators.addToAnotherCollection({ _id, access: 'Private' }));
                                setShowVideoOptionId(null);
                              }}
                            >
                              Make Video Hidden
                            </div>
                            <div
                              role='presentation'
                              onClick={() => {
                                setshowAddtoCollection(true);
                                setCurrentVideoId(_id);
                                setShowVideoOptionId(null);
                              }}
                            >
                              Add to Another Collection
                            </div>
                          </VideoMenu>
                        </VideoOptions>
                      </TableRow>
                    );
                  })}
              </tbody>
            </Table>
          ) : (
            <NoVideos>
              {/* <p>{loading ? 'Loading...' : 'No Videos'}</p> */}
              <p>No Videos</p>
            </NoVideos>
          )}
        </ManageContent>
      </ManageContainer>
      {showEditCollection && <EditCollectionModal currentCollectionName={currentCollectionName} />}
      {showDeleteCollection && <DeleteCollectionModal />}
      {showAddtoCollection && (
        <AddToAnotherCollectionModal currentVideoId={currentVideoId} onCancel={() => setshowAddtoCollection(false)} />
      )}
    </>
  );
};

export default ManageCollectionModal;
