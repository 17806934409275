/*
 * action.js file for videoplayer view
 * Author:
 * 1. Manikanta
 * 2. Vijay
 * @copyright of Panorbit Services LLP.
 */

import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getPlayerVideoDetails: ['payload'],
  getPlayerVideoDetailsStart: ['payload'],
  getPlayerVideoDetailsSuccess: ['payload'],
  getPlayerVideoDetailsFailure: ['payload'],

  setReloadVideoDetails: ['payload'],
  setVideosReload: ['payload'],

  getPlayerVideoCommentsList: ['payload'],
  getPlayerVideoCommentsListStart: ['payload'],
  getPlayerVideoCommentsListSuccess: ['payload'],
  getPlayerVideoCommentsListFailure: ['payload'],

  addPlayerVideoComment: ['payload'],
  addPlayerVideoCommentStart: ['payload'],
  addPlayerVideoCommentSuccess: ['payload'],
  addPlayerVideoCommentFailure: ['payload'],

  editPlayerVideo: ['payload'],
  editPlayerVideoStart: ['payload'],
  editPlayerVideoSuccess: ['payload'],
  editPlayerVideoFailure: ['payload'],

  deletePlayerVideo: ['payload'],
  deletePlayerVideoStart: ['payload'],
  deletePlayerVideoSuccess: ['payload'],
  deletePlayerVideoFailure: ['payload'],

  setShowEditVideoModal: ['payload'],
  setShowDeleteVideoModal: ['payload'],

  removeFromCollection: ['payload'],
  removeFromCollectionStart: ['payload'],
  removeFromCollectionSuccess: ['payload'],
  removeFromCollectionFailure: ['payload'],

  VideoDetailsReset: ['payload'],
  resetSuccess: null,
  resetError: null,
  resetComments: null
});

export { Types, Creators };
