import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import LoginView from '../../views/auth/LoginView/Login.view';
import ForgotPasswordView from '../../views/auth/ForgotPasswordView/ForgotPasswordView';
import CreatePasswordView from '../../views/auth/CreatePassword/CreatePasswordView';
import PasswordSuccessView from '../../views/auth/PasswordSuccess/PasswordSuccessView';
import OtpView from '../../views/auth/OtpView/OtpView';
import UserDetailsView from '../../views/PlayerDetailsView/PlayerDetailsView';
import ThankyouView from '../../views/ThankyouView/ThankyouView';
import UseDesktop from '../../views/UseDesktop/UseDesktop';
import AdminView from '../../views/admin/adminView';
import PlayerDashBoard from '../../views/Player/Dashboard/PlayerView';
import CoachView from '../../views/Coach/Dashboard/CoachView';
import ResetPassword from '../../views/auth/ResetPassword/ResetPasswordView';
import ResetSuccess from '../../views/auth/ResetSuccess/ResetSuccessView';
import { EmptyContentText } from '../../globalShared/CommonStyles';
// page not found
const PageNotFound = () => <EmptyContentText>Page not found</EmptyContentText>;

// routes
class Routes extends React.Component {
  render() {
    const { access, userType } = this.props;

    if (!access) {
      return (
        <Switch>
          <Route exact path='/' component={LoginView} />
          <Route path='/forgotpsw' component={ForgotPasswordView} />
          <Route path='/verification' component={OtpView} />
          <Route path='/resetpassword' component={ResetPassword} />
          <Route path='/resetsuccess' component={ResetSuccess} />
          <Route path='/usedesktop' component={UseDesktop} />
          <Route path='/*'>
            <Redirect to='/' />
          </Route>
        </Switch>
      );
    }

    return (
      <Switch>
        <Route exact path='/' component={LoginView} />
        <Route path='/forgotpsw' component={ForgotPasswordView} />
        <Route path='/createpassword' component={CreatePasswordView} />
        <Route path='/resetpassword' component={ResetPassword} />
        <Route path='/success' component={PasswordSuccessView} />
        <Route path='/resetsuccess' component={ResetSuccess} />
        <Route path='/userdetails' component={UserDetailsView} />
        <Route path='/verification' component={OtpView} />
        <Route path='/thankyou' component={ThankyouView} />
        <Route path='/usedesktop' component={UseDesktop} />
        <Route path='/admin'>
          <AdminView userType={userType} />
        </Route>
        <Route path='/player'>
          <PlayerDashBoard userType={userType} />
        </Route>
        <Route path='/coach'>
          <CoachView userType={userType} />
        </Route>
        <Route path='/*' component={PageNotFound} />
      </Switch>
    );
  }
}

export default Routes;
