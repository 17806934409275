import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  loading: false,
  error: null,
  readinessLoading: false,
  strengthLoading: false,
  readiness: null,
  strength: null,
  sleepStats: [],
  nutritionStats: [],
  hydrationStats: [],
  stillnessStats: [],
  reload: false,
  success: false
};

const getCoachHomeReadinessStart = (state = INITIAL_STATE) => ({ ...state, error: null, readinessLoading: true });

const getCoachHomeReadinessSuccess = (state = INITIAL_STATE, action) => {
  const { readiness } = action.payload;

  return {
    ...state,
    readinessLoading: false,
    readiness
  };
};

const getCoachHomeReadinessFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    readinessLoading: false,
    error
  };
};

const getCoachHomeStrengthStart = (state = INITIAL_STATE) => ({ ...state, error: null, strengthLoading: true });

const getCoachHomeStrengthSuccess = (state = INITIAL_STATE, action) => {
  const { strength } = action.payload;

  return {
    ...state,
    strengthLoading: false,
    reload: false,
    strength
  };
};

const getCoachHomeStrengthFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;

  return {
    ...state,
    strengthLoading: false,
    error
  };
};

const getCoachHomeSleepStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getCoachHomeSleepSuccess = (state = INITIAL_STATE, action) => {
  const { sleepStats } = action.payload;

  return {
    ...state,
    loading: false,
    reload: false,
    sleepStats
  };
};

const getCoachHomeSleepFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const getCoachHomeNutritionStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getCoachHomeNutritionSuccess = (state = INITIAL_STATE, action) => {
  const { nutritionStats } = action.payload;

  return {
    ...state,
    loading: false,
    reload: false,
    nutritionStats
  };
};

const getCoachHomeNutritionFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const getCoachHomeHydrationStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getCoachHomeHydrationSuccess = (state = INITIAL_STATE, action) => {
  const { hydrationStats } = action.payload;

  return {
    ...state,
    loading: false,
    reload: false,
    hydrationStats
  };
};

const getCoachHomeHydrationFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const getCoachHomeStillnessStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getCoachHomeStillnessSuccess = (state = INITIAL_STATE, action) => {
  const { stillnessStats } = action.payload;

  return {
    ...state,
    loading: false,
    reload: false,
    stillnessStats
  };
};

const getCoachHomeStillnessFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const HANDLERS = {
  [Types.GET_COACH_HOME_READINESS_START]: getCoachHomeReadinessStart,
  [Types.GET_COACH_HOME_READINESS_SUCCESS]: getCoachHomeReadinessSuccess,
  [Types.GET_COACH_HOME_READINESS_FAILURE]: getCoachHomeReadinessFailure,

  [Types.GET_COACH_HOME_STRENGTH_START]: getCoachHomeStrengthStart,
  [Types.GET_COACH_HOME_STRENGTH_SUCCESS]: getCoachHomeStrengthSuccess,
  [Types.GET_COACH_HOME_STRENGTH_FAILURE]: getCoachHomeStrengthFailure,

  [Types.GET_COACH_HOME_SLEEP_START]: getCoachHomeSleepStart,
  [Types.GET_COACH_HOME_SLEEP_SUCCESS]: getCoachHomeSleepSuccess,
  [Types.GET_COACH_HOME_SLEEP_FAILURE]: getCoachHomeSleepFailure,

  [Types.GET_COACH_HOME_NUTRITION_START]: getCoachHomeNutritionStart,
  [Types.GET_COACH_HOME_NUTRITION_SUCCESS]: getCoachHomeNutritionSuccess,
  [Types.GET_COACH_HOME_NUTRITION_FAILURE]: getCoachHomeNutritionFailure,

  [Types.GET_COACH_HOME_HYDRATION_START]: getCoachHomeHydrationStart,
  [Types.GET_COACH_HOME_HYDRATION_SUCCESS]: getCoachHomeHydrationSuccess,
  [Types.GET_COACH_HOME_HYDRATION_FAILURE]: getCoachHomeHydrationFailure,

  [Types.GET_COACH_HOME_STILLNESS_START]: getCoachHomeStillnessStart,
  [Types.GET_COACH_HOME_STILLNESS_SUCCESS]: getCoachHomeStillnessSuccess,
  [Types.GET_COACH_HOME_STILLNESS_FAILURE]: getCoachHomeStillnessFailure
};

export const coachHomeReducer = createReducer(INITIAL_STATE, HANDLERS);
