/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Creators } from './store';
import { Creators as SkillCreators } from '../SkillsView/store';

// assets
import { ReactComponent as CloseIcon } from '../../../shared/assets/Close.svg';

// styles
import {
  ModalContainer,
  Content,
  Title,
  CloseButton,
  FormContainer,
  Header,
  VideoInfoContainer,
  InfoTitle,
  InfoWrapper,
  VideoInfo,
  VideoTitle,
  VideoDuration,
  InputContainer,
  HideVideo,
  HideVideoTitle,
  DividerContainer,
  FormFooter
} from './EditVideoModalStyles';

import { Divider } from '../../../globalShared/CommonStyles';

// components
import { FormInput, Dropdown } from '../../../shared/components/FormComponents';
import { AvatarComponent, ButtonComponent, ToggleSwitch } from '../../../globalShared';

const EditVideoModal = (props) => {
  const { videoId, onCancel } = props;
  const dispatch = useDispatch();

  // state values
  const videoDetails = useSelector((state) => state.playerVideoPlayer.videoDetails);
  const collectionsList = useSelector((state) => state.playerSkills.collectionsList);
  const reloadVideoDetails = useSelector((state) => state.playerVideoPlayer.reloadVideoDetails);
  // console.log(videoDetails);
  // console.log(reloadVideoDetails);

  const newCollectionList =
    collectionsList &&
    collectionsList.map((item) => ({
      _id: item._id,
      name: item.name
    }));
  // console.log(newCollectionList);
  const dropdownList = newCollectionList.map((item) => item.name);

  // effect hooks
  useEffect(() => {
    dispatch(Creators.getPlayerVideoDetails({ id: videoId }));
  }, []);

  useEffect(() => {
    if (reloadVideoDetails) {
      dispatch(Creators.getPlayerVideoDetails({ id: videoId }));
    }
  }, [reloadVideoDetails]);

  useEffect(() => {
    dispatch(SkillCreators.getPlayerCollectionsList());
  }, []);

  const { thumbUrl, title, duration } = videoDetails;
  const videoMinutes = Math.floor(duration / 60).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  });
  const videoSeconds = Math.floor(duration % 60).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  });

  // const [selectedCollectionId, setSelectedCollectionId] = useState('');
  const [formFields, setFormFields] = useState({
    title: '',
    collectionId: '',
    collectionName: ''
  });

  const [videoHidden, setVideoHidden] = useState(false);
  const [isFormFilled, setIsFormFilled] = useState(false);

  useEffect(() => {
    if (reloadVideoDetails) {
      setFormFields({
        title: videoDetails.title,
        collectionId: videoDetails.collectionId,
        collectionName: videoDetails.collectionName
      });

      videoDetails.access === 'Private' ? setVideoHidden(true) : setVideoHidden(false);
    }
  }, [reloadVideoDetails]);

  // console.log(formFields);

  function checkFormFilled(props) {
    const { title, collectionId, collectionName } = props;
    const isFormTitle = !!`${title}`.trim().length;
    const isCollectionId = !!`${collectionId}`.trim().length;
    const isCollectionName = !!`${collectionName}`.trim().length;

    return isFormTitle && isCollectionId && isCollectionName;
  }

  useEffect(() => {
    const formFilled = checkFormFilled({ ...formFields });
    setIsFormFilled(formFilled);
  }, [formFields]);

  const handleVideoTitle = (title) => {
    setFormFields({ ...formFields, title });
  };

  const handleCollectionName = (collectionName) => {
    const collectionId = newCollectionList.filter((item) => item.name === collectionName)[0]._id;
    // console.log(collectionId);
    // setSelectedCollectionId(collectionId);
    setFormFields({ ...formFields, collectionId, collectionName });
  };

  const toggleHideVideo = () => {
    setVideoHidden(!videoHidden);
  };

  const handleClose = () => {
    // dispatch(Creators.setShowEditVideoModal({ showEditVideo: false }));
    dispatch(Creators.setReloadVideoDetails({ reloadVideoDetails: false }));
    onCancel();
  };

  const handleSave = () => {
    const editVideoForm = { ...formFields, videoHidden, _id: videoId };
    // console.log(editVideoForm);
    dispatch(Creators.editPlayerVideo({ data: editVideoForm }));
    handleClose();
  };

  return (
    <ModalContainer>
      <Content>
        <CloseButton onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
        <FormContainer>
          <Header>
            <Title>Edit Video</Title>
          </Header>

          <VideoInfoContainer>
            <InfoTitle>Video</InfoTitle>
            <InfoWrapper>
              <AvatarComponent url={thumbUrl} />
              <VideoInfo>
                <VideoTitle>{title}</VideoTitle>
                <VideoDuration>{`${videoMinutes}:${videoSeconds}`}</VideoDuration>
              </VideoInfo>
            </InfoWrapper>
          </VideoInfoContainer>

          <InputContainer>
            <FormInput label='Title' value={formFields.title} setValue={handleVideoTitle} />
          </InputContainer>

          <InputContainer>
            <Dropdown
              list={dropdownList}
              label='Collection'
              value={formFields.collectionName}
              setValue={handleCollectionName}
            />
          </InputContainer>

          <HideVideo>
            <HideVideoTitle>Make Video Hidden</HideVideoTitle>
            <ToggleSwitch isChecked={videoHidden} disabled={false} onChangeHandler={toggleHideVideo} />
          </HideVideo>

          <DividerContainer>
            <Divider />
          </DividerContainer>

          <FormFooter>
            <div>
              <ButtonComponent buttonText='Save' isButtonAction={isFormFilled} buttonClick={handleSave} />
            </div>
          </FormFooter>
        </FormContainer>
      </Content>
    </ModalContainer>
  );
};

export default EditVideoModal;
