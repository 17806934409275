/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Creators } from './store/action';

import { Creators as ModalDataCreators } from '../RestPlayers/store';

import { TableStyles, ActionContainer, ImageDiv, PlayersCol } from './GroupViewStyle';

import { ArrayString, EmptyContentText, FeedContainer } from '../../../globalShared/CommonStyles';
import { textColors } from '../../../shared/constants/colors';
import { fontWeight } from '../../../shared/constants/font';

import { AvatarComponent, PaginationComponent, TooltipComponnet } from '../../../globalShared';
import Toast, { Toasts } from '../../../services/toast';
import { localString } from '../../../services/locallization/localStrings';
import { shortenText } from '../../../services/utility';

const { Table, Th, Tr, Td } = TableStyles;
const { textWhite } = textColors;
const { bold } = fontWeight;

const GroupView = () => {
  const [count, setCount] = useState(10);

  const groupData = useSelector((state) => state.groupList.groupList);
  const { totalItems, loading, searchKey, page } = useSelector((state) => state.groupList);

  const playersModal = useSelector((state) => state.modalData.playersModal);
  const reload = useSelector((state) => state.modalData.reload);
  const success = useSelector((state) => state.modalData.success);
  const error = useSelector((state) => state.modalData.error);

  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  const dispatch = useDispatch();
  const fetchSearch = () => dispatch(Creators.searchGroup({ count, page, searchKey }));

  const handlePage = (page) => {
    dispatch(Creators.setGroupPage({ page }));
  };

  const onSeeGropudetailsClick = (groupDetails) => {
    dispatch(ModalDataCreators.setPlayersModal({ playersModal: !playersModal }));
    dispatch(ModalDataCreators.getSelectedGroup({ groupPlayers: groupDetails }));
  };

  const onGroupEditClick = (groupDetails) => {
    dispatch(ModalDataCreators.getSelectedGroup({ groupPlayers: groupDetails }));
    dispatch(
      ModalDataCreators.setAddGroupModal({
        addGroupModal: true,
        addGroupModalMode: 'EDIT'
      })
    );
  };

  const onGroupDeleteClick = (groupDetails) => {
    const payload = { _id: groupDetails._id };
    dispatch(ModalDataCreators.editDeleteGroup(payload));
  };

  // effect hooks

  useEffect(() => {
    if (searchKey) {
      dispatch(
        Creators.searchGroup({
          count,
          page,
          searchKey
        })
      );
    } else {
      dispatch(Creators.groupList({ page, count }));
    }
  }, [page, count, searchKey]);

  useEffect(() => {
    if (reload) {
      searchKey ? fetchSearch() : page === 1 ? dispatch(Creators.groupList({ page, count })) : handlePage(1);
    }
  }, [reload]);

  useEffect(() => {
    if (success) {
      const reset = () => dispatch(ModalDataCreators.resetSuccess());
      Toast.success(string.success, 2, reset);
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      const reset = () => dispatch(ModalDataCreators.resetError());
      Toast.error(error, 2, reset);
    }
  }, [error]);

  useEffect(
    () => () => {
      dispatch(Creators.resetGroup());
    },
    []
  );

  return (
    <>
      <Toasts />
      {groupData.length ? (
        <>
          <FeedContainer className='overFlow' style={{ paddingRight: '1rem', height: 'calc(100vh - 211px)' }}>
            <Table>
              <thead>
                <tr>
                  <Th style={{ paddingLeft: '2rem' }}>{string.title}</Th>
                  <Th>{string.players}</Th>
                  <Th>{string.createdBy}</Th>
                  <Th style={{ textAlign: 'center' }}>{string.actions}</Th>
                </tr>
              </thead>
              <tbody>
                {groupData.map((player) => {
                  const { _id, name, playerList, title } = player;
                  let firstThreeList = null;
                  let remainingList = null;
                  if (playerList.length > 3) {
                    firstThreeList = playerList.slice(0, 3);
                    remainingList = playerList.slice(3, playerList.length);
                  } else {
                    firstThreeList = playerList;
                  }
                  return (
                    <Tr key={_id}>
                      <Td
                        style={{ maxWidth: '280px' }}
                        onClick={() => {
                          onSeeGropudetailsClick(player);
                        }}
                      >
                        <ArrayString style={{ color: textWhite, fontWeight: bold, paddingLeft: '10px' }}>
                          {name || title}
                        </ArrayString>
                      </Td>
                      <Td>
                        <ImageDiv
                          onClick={() => {
                            onSeeGropudetailsClick(player);
                          }}
                        >
                          {firstThreeList.map((playerInside) => (
                            <PlayersCol key={`${playerInside._id}${Math.random}`}>
                              <AvatarComponent url={playerInside.profilePicture} />
                            </PlayersCol>
                          ))}
                          <PlayersCol> {remainingList ? `+${remainingList.length} others` : ''}</PlayersCol>
                        </ImageDiv>
                      </Td>
                      <Td
                        style={{ paddingLeft: '11px' }}
                        onClick={() => {
                          onSeeGropudetailsClick(player);
                        }}
                      >
                        {shortenText(player.createdByName, 30, '...')}
                      </Td>
                      <Td>
                        <ActionContainer>
                          <TooltipComponnet type='edit' title={string.edit} onClick={() => onGroupEditClick(player)} />
                          <TooltipComponnet
                            type='delete'
                            title={string.delete}
                            onClick={() => onGroupDeleteClick(player)}
                          />
                        </ActionContainer>
                      </Td>
                    </Tr>
                  );
                })}
              </tbody>
            </Table>
          </FeedContainer>
          <PaginationComponent
            onChange={(value) => {
              handlePage(value.currentPage);
              setCount(value.count);
            }}
            totalItems={totalItems}
            title={string.groups}
            pageNo={page}
          />
        </>
      ) : (
        !loading && (
          <EmptyContentText style={{ height: ' calc(100vh - 162px)' }}>{string.noDataAvailable}</EmptyContentText>
        )
      )}
    </>
  );
};

export default GroupView;
