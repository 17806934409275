import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Creators as ModalDataCreators } from './store';

import {
  Card,
  CardFlex,
  ModalTitle,
  ModalHeaderContainer,
  ModalContentContainer,
  InfoHeader,
  Info,
  TableClone,
  PlayerName,
  HistoryBtn,
  ModalOverlay,
  ScrollContainer
} from './Modal';
// import { ArrayString } from '../../../globalShared/CommonStyles';
import { ReactComponent as Close } from '../../../shared/assets/Close.svg';

import { AvatarComponent } from '../../../globalShared';
import { shortenText } from '../../../services/utility';

const { CardNew, CardPara } = Card;

const PlayersModal = () => {
  const groupPlayersData = useSelector((state) => state.modalData.groupPlayers);
  const playerModal = useSelector((state) => state.modalData.playersModal);
  const historyModal = useSelector((state) => state.modalData.historyModal);

  const dispatch = useDispatch();

  if (playerModal && groupPlayersData) {
    return (
      <>
        <ModalOverlay style={{ right: '25px' }}>
          <CardFlex>
            <CardPara>
              <Close onClick={() => dispatch(ModalDataCreators.setPlayersModal({ playersModal: false }))} />
            </CardPara>

            <CardNew>
              <ModalHeaderContainer>
                <ModalTitle>{groupPlayersData.name}</ModalTitle>
              </ModalHeaderContainer>

              <ModalContentContainer>
                <InfoHeader>Created By :</InfoHeader>

                <Info>{shortenText(groupPlayersData.createdByName, 20, '...')}</Info>
              </ModalContentContainer>
              <ModalContentContainer>
                <InfoHeader>Players :</InfoHeader>
                <Info>{groupPlayersData.playerList.length}</Info>
              </ModalContentContainer>

              <ScrollContainer>
                {groupPlayersData.playerList.map((player) => (
                  <TableClone key={player._id}>
                    <AvatarComponent url={player.profilePicture} />
                    <PlayerName>{shortenText(`${player.firstName} ${player.lastName}`, 10, '...')}</PlayerName>

                    <HistoryBtn
                      onClick={() => {
                        dispatch(ModalDataCreators.setHistoryModalData({ historyModalData: player }));
                        dispatch(ModalDataCreators.setHistoryModal({ historyModal: !historyModal }));
                      }}
                    >
                      Player History
                    </HistoryBtn>
                  </TableClone>
                ))}
              </ScrollContainer>
            </CardNew>
          </CardFlex>
        </ModalOverlay>
      </>
    );
  }
  return <></>;
};

export default PlayersModal;
