/* eslint-disable indent */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Ellipsis, PrevBtn, NxtBtn, P, Wrapper, Options, Option } from './paginationStyles';
import { FlexContainer } from '../../../globalShared/CommonStyles';
import { ReactComponent as Arrow } from '../../assets/Dropdown.svg';
import { DropdownContainer, Select } from '../DropdownComponent/Dropdown.styles';
import useTouchOutside from '../../hooks/useTouchOutside';
import { localString } from '../../../services/locallization/localStrings';

// generates a rage arrray
function* range(start, end) {
  while (start < end) {
    yield start;
    start += 1;
  }
}

const Pagenation = ({ totalItems, title = '', onChange, pageNo = 0, initialCount = 0 }) => {
  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  //
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(10);
  const [showDropdown, setShowDropdown] = useState(false);
  const [totalPages, settotalPages] = useState(1);

  const optionArray = [10, 25, 50, 100];
  const dropDownRef = useRef();

  useTouchOutside(dropDownRef, () => setShowDropdown(false));

  const handleClick = (option) => {
    setCount(option);
    setCurrentPage(1);
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    settotalPages(Math.ceil(totalItems / count));
  }, [totalItems, count]);

  useEffect(() => {
    onChange({ count, currentPage });
  }, [count, currentPage]);

  useEffect(() => {
    if (pageNo) {
      setCurrentPage(pageNo);
    }
  }, [pageNo]);
  useEffect(() => {
    if (initialCount) {
      setCount(initialCount);
    }
  }, [initialCount]);
  const generatePages = () => {
    if (totalPages <= 6) {
      const pages = Array.from(range(1, totalPages + 1));

      return pages.map((page) => (
        <Button key={page} isActive={page === currentPage} onClick={() => setCurrentPage(page)}>
          {page}
        </Button>
      ));
    }

    if (totalPages - currentPage <= 4) {
      const first = Array.from(range(totalPages - 4, totalPages + 1));
      return (
        <FlexContainer>
          <Ellipsis>...</Ellipsis>
          {first.map((page) => (
            <Button key={page} isActive={page === currentPage} onClick={() => setCurrentPage(page)}>
              {page}
            </Button>
          ))}
        </FlexContainer>
      );
    }
    // eslint-disable-next-line operator-linebreak
    const first =
      currentPage === 1
        ? Array.from(range(currentPage, currentPage + 5))
        : Array.from(range(currentPage - 1, currentPage + 4));
    const end = Array.from(range(totalPages, totalPages + 1));
    return (
      <FlexContainer>
        {first.map((page) => (
          <Button key={page} isActive={page === currentPage} onClick={() => setCurrentPage(page)}>
            {page}
          </Button>
        ))}
        <Ellipsis>...</Ellipsis>
        {end.map((page) => (
          <Button key={page} isActive={page === currentPage} onClick={() => setCurrentPage(page)}>
            {page}
          </Button>
        ))}
      </FlexContainer>
    );
  };

  return (
    <Wrapper>
      <FlexContainer>
        <P>Showing</P>
        <div style={{ width: '65px', margin: '0 1rem' }}>
          <DropdownContainer ref={dropDownRef}>
            <Select onClick={() => setShowDropdown(!showDropdown)}>{count}</Select>
            {showDropdown && (
              <Options>
                <div>
                  {optionArray.map((option) => {
                    return (
                      <Option key={option} value={option} onClick={() => handleClick(option)}>
                        {option}
                      </Option>
                    );
                  })}
                </div>
              </Options>
            )}
          </DropdownContainer>
        </div>
        <P>Records Per Page</P>
      </FlexContainer>

      <FlexContainer>
        <PrevBtn disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)}>
          <Arrow />
        </PrevBtn>
        <FlexContainer>{generatePages()}</FlexContainer>
        <NxtBtn disabled={currentPage === totalPages} onClick={() => setCurrentPage(currentPage + 1)}>
          <Arrow />
        </NxtBtn>
      </FlexContainer>

      <P>
        {`${
          currentPage === totalPages
            ? `${string.viewing} ${currentPage === 1 ? '1' : (currentPage - 1) * count + 1} - ${totalItems}`
            : `${string.viewing} ${currentPage === 1 ? '1' : (currentPage - 1) * count + 1} - ${
                currentPage === 1 ? count : currentPage * count
              }
       `
        }
        ${string.of} ${totalItems} ${title}`}
      </P>
    </Wrapper>
  );
};

export default Pagenation;
