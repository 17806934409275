import styled from 'styled-components';
import { textColors, backgroundColor, gradient } from '../../constants';

export const ToggleButton = {
  Container: styled.div`
    position: relative;
    width: 50px;
    height: 27px;
    overflow: hidden;
    border-radius: 100px;
    background: ${backgroundColor.darkOpaq};
  `,
  Layer: styled.div`
    border-radius: 100px;
    width: 100%;
    background-color: #ddd
    transition: all 0.3s ease ;
    z-index: 1;
  `,

  Checkbox: styled.input`
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  `,

  Knob: styled.div`
    z-index: 2;

    &:before {
      content: '';
      position: absolute;
      top: 3px;
      left: ${(props) => (props.checked ? '25px' : '5px')};
      width: 12px;
      height: 2px;
      color: ${textColors.textLight};
      font-size: 10px;
      font-weight: bold;
      text-align: center;
      line-height: 1;
      padding: 9px 4px;
      background: ${(props) => (props.checked ? `${gradient.primary}` : ` ${backgroundColor.light}`)};
      border-radius: 50%;
      transition: all 0.1s ease, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
    }
  `
};
