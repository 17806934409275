import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Creators } from './store';

// assets
import { ReactComponent as CloseIcon } from '../../../shared/assets/Close.svg';

// styles
import {
  ModalContainer,
  Card,
  Title,
  CloseButton,
  Header,
  Confirmation,
  FormFooter
} from './CancelMeditationModalStyles';

// components
import { ButtonComponent } from '../../../globalShared';

const CancelMeditationModal = (props) => {
  const { currentMeditation, onCancel, meditationDetailsClose } = props;
  const dispatch = useDispatch();

  const currentTrackProgress = useSelector((state) => state.playerMindset.currentTrackProgress);

  const handleClose = () => {
    onCancel();
    meditationDetailsClose();
  };

  let basicMeditation = null;
  let guidedMeditation = null;
  let expertMeditation = null;

  const handleSave = () => {
    // save duration
    if (currentMeditation.name === 'basicMeditation') {
      basicMeditation = currentTrackProgress;
    } else if (currentMeditation.name === 'guidedMeditation') {
      guidedMeditation = currentTrackProgress;
    } else if (currentMeditation.name === 'expertMeditation') {
      expertMeditation = currentTrackProgress;
    }

    // if (currentMeditation.id) {
    //   dispatch(
    //     Creators.editPlayerMeditationDuration({
    //       _id: currentMeditation.id,
    //       meditationName: currentMeditation.name,
    //       basicMeditation,
    //       guidedMeditation,
    //       expertMeditation
    //     })
    //   );
    // } else {
    dispatch(
      Creators.setPlayerMeditationDuration({
        meditationName: currentMeditation.name,
        basicMeditation,
        guidedMeditation,
        expertMeditation
      })
    );

    if (currentTrackProgress) {
      meditationDetailsClose();
    } else if (currentTrackProgress === 0) {
      meditationDetailsClose();
    }

    handleClose();
  };

  return (
    <ModalContainer>
      <Card>
        <Header>
          <div>
            <Title>Cancel meditation</Title>
          </div>

          <div>
            <CloseButton onClick={handleClose}>
              <CloseIcon />
            </CloseButton>
          </div>
        </Header>

        <Confirmation>Are you sure you want to cancel meditation?</Confirmation>

        <FormFooter>
          <div>
            <ButtonComponent buttonText='Yes' isButtonAction={true} buttonClick={handleClose} bgType='dark' />
            <ButtonComponent buttonText='Save meditation' buttonClick={handleSave} />
          </div>
        </FormFooter>
      </Card>
    </ModalContainer>
  );
};

export default CancelMeditationModal;
