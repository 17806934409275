/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import {
  AccordianCont,
  AccordianHead,
  DropDown,
  H3,
  AccStat,
  Table,
  Th,
  Thumbnail,
  Count,
  Td,
  Complete,
  ProgressContainer,
  ProgressDetails,
  XTitle,
  WorkoutTitle
} from './SessionStyles';

import { Divider, FlexContainer } from '../../../../../globalShared/CommonStyles';

// icons
import CompleteIcon from '../../../../../shared/assets/completed.svg';
import { ReactComponent as DumbellIcon } from '../../../../../shared/assets/dummbell.svg';
import { ReactComponent as RepsIcon } from '../../../../../shared/assets/reps.svg';
import { ReactComponent as SetsIcon } from '../../../../../shared/assets/sets.svg';
import { ReactComponent as WeightIcon } from '../../../../../shared/assets/weight.svg';
import { ReactComponent as DropdownIcon } from '../../../../../shared/assets/Dropdown.svg';
import ProgressRing from '../../../MealRecentView/ProgressRing';
import { _converIntoKAndM } from '../../../../../services/utility/commurize';

const Accordian = (props) => {
  const [state, setState] = useState(false);
  const { exercise } = props;
  const toBecompleted = exercise.exerciseList;
  const completed = exercise.exercises;
  const toRender = toBecompleted.map((exercise) => {
    const { title, thumbUrl } = exercise;
    const exe = completed.find((el) => el.exerciseName === title);

    let payload = {};
    if (!exe) {
      payload.exerciseName = title;
      payload.totalSets = null;
      payload.totalReps = null;
      payload.totalKgs = null;
      payload.thumbUrl = thumbUrl;
    } else {
      payload = exe;
    }

    return payload;
  });
  return (
    <AccordianCont>
      <AccordianHead onClick={() => setState(!state)}>
        <FlexContainer>
          {toBecompleted.length === completed.length ? (
            <Complete src={CompleteIcon} />
          ) : (
            <ProgressContainer>
              <ProgressRing radius={27} stroke={2} progress={(completed.length / toBecompleted.length) * 100} />
              <ProgressDetails>{`${completed.length} / ${toBecompleted.length}`}</ProgressDetails>
            </ProgressContainer>
          )}
          <XTitle className='word'>{exercise.title}</XTitle>
        </FlexContainer>
        <FlexContainer>
          <FlexContainer>
            <AccStat>
              <SetsIcon />
              <H3>{exercise ? _converIntoKAndM(exercise.totalSets) : 0}</H3>
            </AccStat>
            <AccStat>
              <RepsIcon />
              <H3>{exercise ? exercise.totalReps : 0}</H3>
            </AccStat>
            <AccStat>
              <WeightIcon />
              <H3>{exercise ? _converIntoKAndM(exercise.totalWeight) : 0}</H3>
            </AccStat>
            <AccStat>
              <DumbellIcon />
              <H3>{exercise ? _converIntoKAndM(exercise.totalVolume) : 0}</H3>
            </AccStat>
          </FlexContainer>
          <DropDown className={state ? 'active' : ''}>
            <DropdownIcon />
          </DropDown>
        </FlexContainer>
      </AccordianHead>
      {state && (
        <>
          <Divider />

          <Table>
            <thead>
              <tr>
                <Th style={{ opacity: 0 }}>Title</Th>
                <Th>Sets</Th>
                <Th>Reps</Th>
                <Th>Weight(Kgs)</Th>
              </tr>
            </thead>
            <tbody>
              {toRender.map((exe, i) => (
                <tr key={i}>
                  <Td align='start'>
                    <FlexContainer style={{ maxWidth: '250px' }}>
                      <Thumbnail src={exe.thumbUrl} alt='exercise' />
                      <WorkoutTitle className='clip'>{exe.exerciseName}</WorkoutTitle>
                    </FlexContainer>
                  </Td>
                  <Td>
                    <Count>{exe.totalSets || '-'}</Count>
                  </Td>
                  <Td>
                    <Count>{exe.totalReps || '-'}</Count>
                  </Td>
                  <Td>
                    <Count>{_converIntoKAndM(parseFloat(exe.totalKgs)) || '-'}</Count>
                  </Td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </AccordianCont>
  );
};

export default Accordian;
