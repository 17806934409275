/* eslint-disable arrow-body-style */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Greetings, Name } from './GreetingsCardStyles';
import { FlexContainer } from '../../../globalShared/CommonStyles';

// svg icons
import MorningImage from '../../assets/player_greetings_goodmorning.svg';
import AfternoonImage from '../../assets/player_greetings_goodafternoon.svg';
import EveningImage from '../../assets/player_greetings_evening.svg';
import NightImage from '../../assets/player_greetings_night.svg';
import { shortenText } from '../../../services/utility';
// import { localString } from '../../../services/locallization/localStrings';

// componet
const index = () => {
  const [greeting, setGreeting] = useState(null);
  const [hrs] = useState(new Date().getHours());
  const personalDetails = useSelector((state) => state.login.personalDetail);

  // localiztion string
  // const { language } = useSelector((state) => state.main);
  // const string = localString[language];

  useEffect(() => {
    let greet = '';
    if (hrs < 12) greet = 'Morning';
    else if (hrs >= 12 && hrs <= 17) greet = 'Afternoon';
    else if (hrs > 17 && hrs <= 20) greet = 'Evening';
    else if (hrs > 20 && hrs <= 24) greet = 'Night';
    setGreeting(greet);
  }, [hrs]);

  const imageMap = {
    Morning: MorningImage,
    Afternoon: AfternoonImage,
    Evening: EveningImage,
    Night: NightImage
  };

  return (
    <Container>
      <FlexContainer>
        <Greetings>{`Good ${greeting}`}</Greetings>
        <Name>
          {personalDetails ? shortenText(`${personalDetails.firstName} ${personalDetails.lastName}`, 12, '...') : ''}
        </Name>
      </FlexContainer>
      <img src={imageMap[greeting]} alt='' />
    </Container>
  );
};

export default index;
