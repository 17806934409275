/*
 * action.js file for coach search video view
 * Author:
 * 1.Manikanta
 *
 * @copyright of Panorbit Services LLP.
 */

import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getTeamVideoSearchList: ['payload'],
  getTeamVideoSearchListStart: ['payload'],
  getTeamVideoSearchListSuccess: ['payload'],
  getTeamVideoSearchListFailure: ['payload'],

  searchReset: null
});

export { Types, Creators };
