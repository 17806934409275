import styled from 'styled-components';
import { borderColor, fontSize, textColors } from '../../../shared/constants';

export const H1 = styled.h1`
  font-size: ${fontSize.large};
`;

export const TextSmallLight = styled.p`
  font-size: ${fontSize.smallDefault};
  color: ${textColors.textLight};
`;

export const Stats = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 0.6rem;
  border: 1px solid ${borderColor.light};
`;

export const LegendButtons = styled.div`
  padding: 5px 15px;
  background: #c4c4c41a;
  border-radius: 13px;
  font-size: 12px;

  border: ${(props) => (props.active ? '1px' : '0px')} solid #b5b7bc;
  margin: 2rem 1rem;
  cursor: pointer;
  text-transform: capitalize;
`;
