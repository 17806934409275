/* eslint-disable no-underscore-dangle */
/* eslint-disable no-else-return */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/jsx-one-expression-per-line */

import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import { Creators } from './store';
import { Creators as CommonCreators } from '../../store';

// styles
import {
  Modal,
  Card,
  Title,
  TextLite,
  CloseButton,
  ModalBody,
  IntakeWrapper,
  MealContainer,
  MealDetailsHeader,
  Intake,
  SummaryBlock,
  CommentsBlock
} from './IntakeSummaryModalStyles';

import { AvatarComponent, CommentsComponent, TabsComponenet } from '../../../globalShared';
import IntakeSummary from './IntakeSummaryModal';

// assets
import { ReactComponent as CloseIcon } from '../../../shared/assets/Close.svg';

// component
import { FlexContainer } from '../../../globalShared/CommonStyles';
import { dayFormater, shortenText } from '../../../services/utility';

const RenderIntake = ({ meal, secondaryId }) => {
  const { intake, mealId } = meal;

  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  const intakeCommentsList = useSelector((state) => state.coachMealRecent.intakeSummaryCommentsList);
  const intakeCommentCount = useSelector((state) => state.coachMealRecent.intakeCommentCount);
  const intakeCommentmaxPage = useSelector((state) => state.coachMealRecent.intakeCommentmaxPage);
  const reloadIntakeComments = useSelector((state) => state.main.reloadComments);

  // const commentsList = intakeCommentsList.filter((comment) => comment.referenceId === mealId);

  const handleSubmitComment = (content, id) => {
    dispatch(CommonCreators.addComment({ content, id, secondaryId }));
  };

  const handleUpdateComments = () => {
    if (page < intakeCommentmaxPage) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    if (reloadIntakeComments) {
      dispatch(Creators.getCoachIntakeSummaryComments({ _id: mealId, count: 100, secondaryId }));
    }
  }, [reloadIntakeComments]);

  useEffect(() => {
    dispatch(Creators.getCoachIntakeSummaryComments({ _id: mealId, count: 100, page, secondaryId }));
  }, [page]);

  useEffect(
    () => () => {
      dispatch(Creators.resetIntake());
    },
    []
  );

  return (
    <ModalBody>
      <SummaryBlock>
        <IntakeWrapper>
          <MealContainer>
            <MealDetailsHeader>
              <p>Intake</p>
              <p>Remarks</p>
            </MealDetailsHeader>

            {intake.map((item) => (
              <Intake key={uuidv4()}>
                <p className='word'>{item.descr}</p>
                <p className='word'>{item.remarks}</p>
              </Intake>
            ))}
          </MealContainer>
        </IntakeWrapper>
      </SummaryBlock>
      <CommentsBlock>
        <CommentsComponent
          count={intakeCommentCount || 0}
          updateComments={handleUpdateComments}
          submitComment={handleSubmitComment}
          commentsList={intakeCommentsList}
          id={mealId}
        />
      </CommentsBlock>
    </ModalBody>
  );
};

const DetailsModal = (props) => {
  const { updatedTime, profilePicture, userName, intakeSummary, mealsList = [], id } = props;
  const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const [tab, setTab] = useState('Summary');
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    if (mealsList.length) {
      const newTabs = mealsList.map((meal) => meal.title);
      setTabs(['Summary', ...newTabs]);
    }
  }, [mealsList]);

  useEffect(() => {
    dispatch(Creators.getCoachMealDetails({ mealId: id }));
  }, []);

  const handleClose = () => {
    dispatch(Creators.setIntakeSummaryId({ id: false }));
    dispatch(Creators.setIntakeModalData({ data: {} }));
  };

  return (
    <Modal>
      <Card>
        <CloseButton onClick={handleClose}>
          <CloseIcon />
        </CloseButton>

        {userName && (
          <FlexContainer>
            <AvatarComponent url={profilePicture} />
            <div>
              <Title>{shortenText(userName, 30, '...')}</Title>
              <TextLite>{dayFormater(updatedTime)}</TextLite>
            </div>
          </FlexContainer>
        )}

        {tabs.length ? (
          <TabsComponenet getactiveTab={(tab) => setTab(tab)}>
            {tabs.map((tab) => {
              if (tab === 'Summary') {
                return (
                  <div key='tab' label={tab}>
                    <IntakeSummary data={intakeSummary} />
                  </div>
                );
              } else {
                const intake = mealsList.find(({ title }) => title === tab);
                return (
                  <div key={tab} label={tab}>
                    <RenderIntake meal={intake} secondaryId={intakeSummary._id} />
                  </div>
                );
              }
            })}
          </TabsComponenet>
        ) : (
          ''
        )}
      </Card>
    </Modal>
  );
};

export default DetailsModal;
