import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from '../../auth/LoginView/store';
import { Creators as SkillsCreator } from '../SkillsVIew/store';
// components
import { CoachSideNav } from '../../../globalShared';

// icons
import { ReactComponent as Logo } from '../../../shared/assets/logo-header.svg';
// import { ReactComponent as Notification } from '../../../shared/assets/Notification-bell.svg';
import { ReactComponent as DropDown } from '../../../shared/assets/Dropdown.svg';
import { ReactComponent as User } from '../../../shared/assets/profile.svg';

import { ReactComponent as Logout } from '../../../shared/assets/Logout.svg';
import userImage from '../../../shared/assets/user.jpg';

// styles
import {
  HeaderContainer,
  FlexContainer,
  UserContainer,
  UserIcon,
  DropDownContainer,
  DropDownItem,
  Body,
  ContentContainer
} from './CoachView.styles';

import CoachRoutes from '../../../services/router/coachRoutes';
import useTouchOutside from '../../../shared/hooks/useTouchOutside';
import CoachProfileModal from '../CoachProfile/CoachProfileModal';
import { localString } from '../../../services/locallization/localStrings';

/**
 *
 * component
 */

const CoachView = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { match, location, history, userType } = props;
  const [showDropdown, setshowDropdown] = useState(false);
  const [showCoachProfile, setShowCoachProfile] = useState(false);
  const [ProfilePicture, setProfilePicture] = useState(null);

  const page = useSelector((state) => state.coachSkills.page);
  const maxPage = useSelector((state) => state.coachSkills.maxPage);
  const personalDetails = useSelector((state) => state.login.personalDetail);

  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  const dropDownRef = useRef();
  const scrollRef = useRef();

  const dispatch = useDispatch();

  const handleLogOut = () => {
    dispatch(Creators.logout());
  };

  // close dropdown on click outside
  useTouchOutside(dropDownRef, () => setshowDropdown(false));

  useEffect(() => {
    if (personalDetails) {
      setProfilePicture(personalDetails.profilePicture);
    }
  }, [personalDetails]);

  useEffect(() => {
    if (userType !== 'Coach') {
      history.push('/');
    }

    if (userType === 'Coach') {
      dispatch(Creators.getUserData());
    }
  }, []);

  // infinite scroll for skills feed list
  const onScroll = () => {
    const { pathname } = location;
    if (scrollRef.current && pathname === '/coach/skills' && page <= maxPage) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        dispatch(SkillsCreator.setPage({ page: page + 1 }));
      }
    }
  };

  const showCoachProfileModal = () => {
    setShowCoachProfile(true);
    setshowDropdown(false);
  };

  const handleClose = () => {
    setShowCoachProfile(false);
  };

  return (
    <>
      <HeaderContainer>
        <Logo style={{ cursor: 'pointer' }} onClick={() => history.push('/coach/')} />

        <FlexContainer>
          {/* <div>
            <Notification />
          </div> */}

          <UserContainer onClick={() => setshowDropdown(!showDropdown)}>
            <UserIcon style={{ backgroundImage: `url(${ProfilePicture || userImage})` }} />
            <DropDown />
          </UserContainer>
        </FlexContainer>

        {showDropdown ? (
          <DropDownContainer ref={dropDownRef}>
            <DropDownItem onClick={() => showCoachProfileModal()}>
              <User />
              {string.profile}
            </DropDownItem>

            <DropDownItem onClick={() => handleLogOut()}>
              <Logout />
              {string.logout}
            </DropDownItem>
          </DropDownContainer>
        ) : (
          ''
        )}
      </HeaderContainer>
      <Body>
        <CoachSideNav isExact={match.isExact} />
        <ContentContainer onScroll={onScroll} ref={scrollRef}>
          <CoachRoutes path={match.path} />
        </ContentContainer>
      </Body>
      {showCoachProfile ? <CoachProfileModal closeCoachProfileModal={handleClose} /> : ''}
    </>
  );
};

export default withRouter(CoachView);
