/*
 * action.js file for coach planner view
 * Author:
 * 1.Manikanta
 *
 * @copyright of Panorbit Services LLP.
 */

import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  searchCoachPlanner: ['payload'],
  searchCoachPlannerStart: ['payload'],
  searchCoachPlannerSuccess: ['payload'],
  searchCoachPlannerFailure: ['payload'],

  getCoachPlannerList: ['payload'],
  getCoachPlannerListStart: ['payload'],
  getCoachPlannerListSuccess: ['payload'],
  getCoachPlannerListFailure: ['payload'],

  createPlanner: ['payload'],
  createPlannerStart: ['payload'],
  createPlannerSuccess: ['payload'],
  createPlannerFailure: ['payload'],

  deleteCoachPlanner: ['payload'],
  deleteCoachPlannerStart: ['payload'],
  deleteCoachPlannerSuccess: ['payload'],
  deleteCoachPlannerFailure: ['payload'],

  setPlannerDetails: ['payload'],
  setshowCreatePlanner: ['payload'],

  resetWorkout: null,
  resetPlannerList: null,
  resetGroupList: null,
  resetError: null,
  resetSuccess: null,
  setPlannerSearchKey: ['payload'],
  setPlannerPage: ['payload'],
  resetStrengthPlanner: null
});

export { Types, Creators };
