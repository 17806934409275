import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { fontSize, textColors, backgroundColor, accentColor } from '../../../shared/constants';

export const Container = styled.div`
  padding: 6rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SubHeading = styled.p`
  font-size: ${fontSize.smallDefault};
  color: ${textColors.textLight};
  margin-bottom: 2rem;
  text-align: center;
`;

export const LinkContainer = styled(Link)`
    font-size: ${fontSize.smallDefault};
    padding: 1.5rem;

    & > svg {
      margin-right: 1rem;
    }
}`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
`;

export const Input = styled.input`
  width: 45px;
  height: 45px;

  padding: 1rem;
  font-size: ${fontSize.mediumDefault};
  margin: 1.5rem 0.5rem;
  text-align: center;

  color: ${textColors.textWhite};
  border: none;
  border-radius: 0.6rem;
  background-color: ${backgroundColor.darkOpaq};

  &:focus {
    outline: 1px solid ${accentColor.primary};
  }

  &:-internal-autofill-selected {
    appearance: initial !important;
    background-color: ${backgroundColor.darkOpaq} !important;

    color: ${textColors.textWhite} !important;
  }
`;

export const Text = styled.p`
  font-size: 14px;
  margin-bottom: 5rem;

  & > span {
    font-size: 14px;
    color: ${accentColor.primary};
    margin: 0 0.5rem;
    cursor: pointer;
  }
`;
