import React, { Component } from 'react';
import { LogoHeaderComponent, LinkButtonComponenet } from '../../../globalShared';

import { ReactComponent as Success } from '../../../shared/assets/success.svg';

// styles
import { Container, FormContainer, Heading, SubHeading } from './ResetSuccessStyles';

// text constants
const HeadingText = 'Password';
const SubHeadingText = 'Successfully Reset';

class ResetSuccess extends Component {
  render() {
    return (
      <>
        <LogoHeaderComponent />
        <Container>
          <FormContainer>
            <Success />
            <Heading>{HeadingText}</Heading>
            <SubHeading>{SubHeadingText}</SubHeading>

            <LinkButtonComponenet buttonText='Login' path='/' bgType='primary' />
          </FormContainer>
        </Container>
      </>
    );
  }
}

export default ResetSuccess;
