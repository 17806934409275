/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/*
 * reducer.js file for login view
 * Author:
 * 1. Manikanta
 *
 * @copyright of Panorbit Services LLP.
 */

import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  loadingLogin: false,
  loadingMetaData: false,
  loadingLogout: false,
  loginSuccess: false,
  user: null,
  userType: null,
  passwordChanged: false,
  metaUpdated: false,
  error: null,
  accessToken: null,
  personalDetail: null,
  professionalDetail: null
};

const loginStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loadingLogin: true,
    error: null
  };
};

const loginSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loadingLogin: false,
    loginSuccess: true,
    error: null
  };
};

const loginFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    passwordChanged: null,
    userType: null,
    user: null,
    loadingLogin: false,
    error
  };
};

const userMetaDataStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loadingMetaData: true,
    error: null
  };
};

const userMetaDataSuccess = (state = INITIAL_STATE, action) => {
  const { userType } = action.payload;
  return {
    ...state,
    userType,
    loadingMetaData: false,
    error: null
  };
};

const userMetaDataFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    user: null,
    loadingMetaData: false,
    error
  };
};

const setUserData = (state = INITIAL_STATE, action) => {
  const { accessToken, passwordChanged, userType, metaUpdated } = action.payload;
  return {
    ...state,
    accessToken,
    passwordChanged,
    userType,
    metaUpdated
  };
};

const setUserDetails = (state = INITIAL_STATE, action) => {
  const { personalDetail, professionalDetail } = action.payload;
  return {
    ...state,
    personalDetail,
    professionalDetail,
    loadingMetaData: false
  };
};

const logoutStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loadingLogout: true
  };
};

const logoutSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...INITIAL_STATE
  };
};

const logoutFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loadingLogout: false,
    error
  };
};

const setToken = (state = INITIAL_STATE, action) => {
  const { token } = action.payload;
  return {
    ...state,
    accessToken: token
  };
};

const resetUserData = (state = INITIAL_STATE) => ({ ...state, error: null });

const resetLoading = (state = INITIAL_STATE) => ({ ...state, loadingMetaData: false });

const HANDLERS = {
  // loading variables
  [Types.LOGIN_START]: loginStart,
  [Types.USER_META_DATA_START]: userMetaDataStart,
  [Types.LOGOUT_START]: logoutStart,

  // when success
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.USER_META_DATA_SUCCESS]: userMetaDataSuccess,
  [Types.LOGOUT_SUCCESS]: logoutSuccess,

  // when failure
  [Types.LOGIN_FAILURE]: loginFailure,
  [Types.USER_META_DATA_FAILURE]: userMetaDataFailure,
  [Types.LOGOUT_FAILURE]: logoutFailure,

  // miscellaneous
  [Types.SET_USER_DATA]: setUserData,
  [Types.SET_USER_DETAILS]: setUserDetails,
  [Types.RESET_USER_DATA]: resetUserData,
  [Types.SET_TOKEN]: setToken,
  [Types.RESET_LOADING]: resetLoading
};

export const loginReducer = createReducer(INITIAL_STATE, HANDLERS);
