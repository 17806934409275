import styled from 'styled-components';
import { fontSize, textColors, borderColor, fontWeight, backgroundColor } from '../../constants';

export const Wrapper = styled.div`
  width: 100%;
  // margin: 1rem 0;

  display: flex;
  flex-wrap: wrap;
`;

export const Column = styled.div`
  width: 32%;
  display: inline-block;
`;

export const SubHeading = styled.p`
  font-size: ${fontSize.mediumDefault};
  color: ${textColors.textLight};
  text-transform: capitalize;
`;

export const AttachmentContainer = styled.div`
  padding: 1rem;
  margin: 1rem;

  border-radius: 0.6rem;
  border: 1px solid ${borderColor.light};

  position: relative;
`;

export const FileInput = styled.input`
  width: 0px;
  height: 0px;
  opacity: 0;

  overflow: hidden;
  position: absolute;

  z-index: -1;
  font-size: ${fontSize.mediumDefault};
`;

export const InputField = styled.div`
  width: 165px;
  padding: 1.2rem 15px;
  margin: 10px 0;

  font-size: 16px;
  color: ${textColors.textLight};
  border-radius: 0.6rem;
  background-color: ${backgroundColor.darkOpaq};
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const AttachButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;
  cursor: pointer;

  & > svg {
    height: 18px;
  }
`;

export const FileName = styled.div`
  font-size: ${fontSize.mediumDefault};
  font-weight: ${fontWeight.bold};

  margin: 0 1rem;
  color: ${textColors.textWhite};

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-word;
`;

export const DeleteButton = styled.div`
  background: none;
  border: none;

  cursor: pointer;

  & > svg {
    height: 18px;
  }
`;
export const AddMoreButtonContainer = styled.div`
  height: 100%;
  width: 100;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AddMoreButton = styled.div`
  font-size: 16px;
  color: #63cb5f;

  padding: 10px;

  background: none;
  border: none;

  cursor: pointer;
  text-transform: capitalize;
`;
