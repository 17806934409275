import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  LogoHeaderComponent,
  ButtonComponent,
  InputComponent,
  FormHeader,
  PasswordInputComponent
} from '../../../globalShared';
import { Creators } from './store';
import Toast, { Toasts } from '../../../services/toast';

import { cookie } from '../../../services/cookies/cookieServices';
// styles
import { Container, FormContainer, ErrorMsg } from './CreatePassword.styles';
import { localString } from '../../../services/locallization/localStrings';

// text constants
const TypePassWord = 'password';

/**
 * component
 */
const CreatePassword = (props) => {
  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  const { register, errors, setError, handleSubmit } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      password: '',
      confirmPassword: ''
    }
  });
  const { history } = props;

  const userDetails = cookie.get('userDetails');

  const dispatch = useDispatch();

  // store values
  const serverError = useSelector((state) => state.createPassword.error);
  const isCreatePasswordSuccess = useSelector((state) => state.createPassword.success);

  const onSubmit = (data) => {
    if (data.password !== data.confirmPassword) {
      setError('confirmPassword', { type: 'manual', message: 'Password Not matched' });

      return;
    }

    const payload = {
      password: data.password
    };
    dispatch(Creators.createPassword(payload));
  };

  // effects hooks
  useEffect(() => {
    if (!userDetails) {
      history.push('/');
    }
  }, [userDetails]);

  useEffect(() => {
    if (isCreatePasswordSuccess) {
      history.push('/success');
    }
  }, [isCreatePasswordSuccess]);

  useEffect(() => {
    if (serverError) {
      Toast.error(serverError, 3);
    }
  }, [serverError]);

  return (
    <>
      <LogoHeaderComponent />
      <Container>
        <FormHeader headingText={string.welcome} subHeadingText={string.createYourPassword} />

        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <PasswordInputComponent
            placeholderText={string.enterPassword}
            ipName={TypePassWord}
            ipRef={register({
              required: string.pleaseEnteraValidPassword,
              minLength: {
                value: 8,
                message: `${string.pleaseEnteraValidPassword}-${string.passwordMustContainAtLeast8Characters}`
              },
              pattern: {
                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                message: string.mustIncludeLowUppNumSpec
              }
            })}
          />

          <ErrorMsg>{errors.password?.message}</ErrorMsg>

          <InputComponent
            placeholderText={string.reEnterPassword}
            ipType={TypePassWord}
            ipName='confirmPassword'
            ipRef={register({
              required: string.pleaseEnteraValidPassword,
              minLength: {
                value: 8,
                message: `${string.pleaseEnteraValidPassword}-${string.passwordMustContainAtLeast8Characters}`
              },
              pattern: {
                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                message: string.mustIncludeLowUppNumSpec
              }
            })}
          />
          <ErrorMsg>{errors.confirmPassword?.message}</ErrorMsg>

          <ButtonComponent buttonText={string.submit} isDisabled={false} buttonType='submit' />
        </FormContainer>
      </Container>
      <Toasts />
    </>
  );
};

export default CreatePassword;
