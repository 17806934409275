/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Creators } from './store';

import { ModalContainer, CloseButton, FlexContainer, Divider } from '../../../globalShared/CommonStyles';

import { ReactComponent as Close } from '../../../shared/assets/Close.svg';
import { ReactComponent as Clock } from '../../../shared/assets/clock.svg';
import { ReactComponent as Duration } from '../../../shared/assets/duration.svg';
import { ReactComponent as Dropdown } from '../../../shared/assets/Dropdown.svg';

import {
  P,
  Content,
  BorderBox,
  TimesContainer,
  InfoText,
  Workout,
  MainContainer,
  Exercise,
  SvgC
} from './Details.styles';
import TooltipComponent from '../../../shared/components/Tooltip';
import { TabsComponenet } from '../../../globalShared';
import { compareDates, getMonthDayYear, getReadableDayMonthYear, getToday } from '../../../services/utility/moment';
import { ExerciseTable } from '../Workoutsview/WorkoutOverview';
import { shortenText } from '../../../services/utility';

// eslint-disable-next-line arrow-body-style
const WorkoutComponent = ({ workout, details }) => {
  const [state, setState] = useState(false);
  return (
    <Workout>
      <FlexContainer
        style={{ justifyContent: 'space-between', marginBottom: '1rem', cursor: 'pointer' }}
        onClick={() => setState(!state)}
      >
        <P>{workout}</P>
        <SvgC className={state ? 'active' : ''}>
          <Dropdown />
        </SvgC>
      </FlexContainer>
      {state && <ExerciseTable exercises={details} />}
    </Workout>
  );
};

const SessionDetails = ({ sessions, selectedDate, onCancel, onEdit }) => {
  const dispatch = useDispatch();
  const [tab, setTab] = useState('');
  const [tabs, setTabs] = useState([]);
  const [enableEdit, setEnableEdit] = useState(false);

  const today = getToday();
  const isAfter = compareDates(selectedDate, today);
  const isToday = getMonthDayYear(today) === getMonthDayYear(selectedDate);

  useEffect(() => {
    setEnableEdit(isAfter || isToday);
  }, [isAfter, isToday]);

  useEffect(() => {
    if (sessions.length) {
      const tabs = sessions.map((session) => session.title);
      setTab(tabs[0]);
      setTabs(tabs);
    }
  }, [sessions]);

  //
  const handleCancel = () => {
    onCancel();
  };

  const handleDelete = (_id) => {
    dispatch(Creators.deleteSession({ _id }));
    handleCancel();
  };
  const _renderSession = (session) => {
    const { createdBy, remark, preferrableTimeEnd, preferrableTimeStart, duration, repeatDays, toDate, workouts, _id } =
      session;

    return (
      <Content>
        <FlexContainer style={{ justifyContent: 'space-between' }}>
          <P>
            <span>Created By</span>
            {shortenText(createdBy, 90, '...')}
          </P>

          {enableEdit && (
            <FlexContainer>
              <TooltipComponent type='edit' onClick={() => onEdit(session)} title='Edit' />
              <TooltipComponent type='delete' onClick={() => handleDelete(_id)} title='Delete' />
            </FlexContainer>
          )}
        </FlexContainer>

        <BorderBox>
          <div style={{ padding: '2rem' }}>
            <P>
              <span style={{ padding: '0' }}>Description</span>
            </P>
            <P style={{ marginTop: '1rem' }}>{remark}</P>
          </div>
          <Divider />
          <div style={{ padding: '2rem' }}>
            <TimesContainer>
              <FlexContainer>
                <Clock />
                <P style={{ marginLeft: '1rem' }}>{`${preferrableTimeStart} - ${preferrableTimeEnd}`}</P>
              </FlexContainer>
              {duration && (
                <FlexContainer>
                  <Duration />
                  <P style={{ marginLeft: '1rem' }}>{duration}</P>
                </FlexContainer>
              )}
            </TimesContainer>
            {repeatDays.length ? (
              <InfoText>
                Repeats On
                <span>{repeatDays.length === 7 ? 'Every Day' : repeatDays.join(' , ')}</span>
                Until
                <span>{toDate && getReadableDayMonthYear(toDate)}</span>
              </InfoText>
            ) : (
              ''
            )}
          </div>
        </BorderBox>

        <BorderBox>
          <div style={{ padding: '2rem' }}>
            <P style={{ marginBottom: '1rem' }}>
              <span style={{ padding: '0' }}>Workouts</span>
            </P>
            <>
              {workouts.map(({ title, _id, exerciseDetails }) => (
                <WorkoutComponent key={_id} workout={title} details={exerciseDetails} />
              ))}
            </>
          </div>
        </BorderBox>
      </Content>
    );
  };

  return (
    <ModalContainer>
      <MainContainer width='768'>
        <CloseButton onClick={handleCancel} position='800'>
          <Close />
        </CloseButton>
        {tabs.length && (
          <TabsComponenet getactiveTab={(tab) => setTab(tab)}>
            {tabs.map((tab) => {
              const session = sessions.find(({ title }) => title === tab);
              return (
                <div key={tab} label={tab}>
                  {_renderSession(session)}
                </div>
              );
            })}
          </TabsComponenet>
        )}
      </MainContainer>
    </ModalContainer>
  );
};

export default SessionDetails;
