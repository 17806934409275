/* eslint-disable no-unused-vars */
/*
 * reducer.js file for users view
 * Author:
 * 1.Manikanta
 * @copyright of Panorbit Services LLP.
 */

import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  loading: false,
  error: null,
  usersList: [],
  reload: false,
  userFormData: null,
  success: false,
  maxPages: 0,
  totalCount: 0
};

const getAdminUsersListStart = (state = INITIAL_STATE, action) => ({ ...state, error: null, loading: true });

const getAdminUsersListSuccess = (state = INITIAL_STATE, action) => {
  const { usersList, maxPages, totalCount } = action.payload;
  return {
    ...state,
    loading: false,
    reload: false,
    usersList,
    maxPages,
    totalCount
  };
};

const getAdminUsersListFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const setAdminUsersListStart = (state = INITIAL_STATE, action) => ({
  ...state,
  error: null,
  loading: true,
  success: false
});
const setAdminUsersListSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  reload: true,
  success: true
});
const setAdminUsersListFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const setAdminBulkUsersListStart = (state = INITIAL_STATE, action) => ({ ...state, loading: true, success: false });
const setAdminBulkUsersListSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  reload: true,
  success: true
});
const setAdminBulkUsersListFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const adminDeleteUserStart = (state = INITIAL_STATE, action) => ({ ...state, loading: true, success: false });
const adminDeleteUserSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  reload: true,
  success: true
});
const adminDeleteUserFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const adminUpdateUserDetailsStart = (state = INITIAL_STATE, action) => ({ ...state, loading: true, success: false });
const adminUpdateUserDetailsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  reload: true,
  success: true
});
const adminUpdateUserDetailsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const getAdminUserDetailsStart = (state = INITIAL_STATE, action) => ({ ...state, loading: true });

const getAdminUserDetailsSuccess = (state = INITIAL_STATE, action) => {
  const { userFormData } = action.payload;

  return {
    ...state,
    loading: false,
    userFormData
  };
};

const getAdminUserDetailsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const resetUserDetails = (state = INITIAL_STATE) => ({ ...state, userFormData: null });
const resetError = (state = INITIAL_STATE) => ({ ...state, error: null });
const resetSuccess = (state = INITIAL_STATE) => ({ ...state, success: false });

const HANDLERS = {
  [Types.GET_ADMIN_USERS_LIST_START]: getAdminUsersListStart,
  [Types.GET_ADMIN_USERS_LIST_SUCCESS]: getAdminUsersListSuccess,
  [Types.GET_ADMIN_USERS_LIST_FAILURE]: getAdminUsersListFailure,

  [Types.SET_ADMIN_USERS_LIST_START]: setAdminUsersListStart,
  [Types.SET_ADMIN_USERS_LIST_SUCCESS]: setAdminUsersListSuccess,
  [Types.SET_ADMIN_USERS_LIST_FAILURE]: setAdminUsersListFailure,

  [Types.ADMIN_DELETE_USER_START]: adminDeleteUserStart,
  [Types.ADMIN_DELETE_USER_SUCCESS]: adminDeleteUserSuccess,
  [Types.ADMIN_DELETE_USER_FAILURE]: adminDeleteUserFailure,

  [Types.SET_ADMIN_BULK_USERS_LIST_START]: setAdminBulkUsersListStart,
  [Types.SET_ADMIN_BULK_USERS_LIST_SUCCESS]: setAdminBulkUsersListSuccess,
  [Types.SET_ADMIN_BULK_USERS_LIST_FAILURE]: setAdminBulkUsersListFailure,

  [Types.ADMIN_UPDATE_USER_DETAILS_START]: adminUpdateUserDetailsStart,
  [Types.ADMIN_UPDATE_USER_DETAILS_SUCCESS]: adminUpdateUserDetailsSuccess,
  [Types.ADMIN_UPDATE_USER_DETAILS_FAILURE]: adminUpdateUserDetailsFailure,

  [Types.GET_ADMIN_USER_DETAILS_START]: getAdminUserDetailsStart,
  [Types.GET_ADMIN_USER_DETAILS_SUCCESS]: getAdminUserDetailsSuccess,
  [Types.GET_ADMIN_USER_DETAILS_FAILURE]: getAdminUserDetailsFailure,
  [Types.RESET_USER_DETAILS]: resetUserDetails,
  [Types.RESET_ERROR]: resetError,
  [Types.RESET_SUCCESS]: resetSuccess
};

export const adminUsersReducer = createReducer(INITIAL_STATE, HANDLERS);
