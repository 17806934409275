import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { backgroundColor } from '../../constants';

export const Wrapper = styled(Link)`
  position: relative;
`;

export const Count = styled.p`
  width: 15px;
  height: 15px;
  border-radius: 50%;

  font-size: 12px;
  background: ${backgroundColor.danger};

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  right: 12%;
  top: 10%;
`;
