import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LabelContainer, PlayerCard, PlayerGroupContainer } from './Modal';
import { Creators as ModalDataCreators } from './store';
import { FormInput, SearchInput } from '../../../shared/components/FormComponents';

// Global styles
import { ModalComponent } from '../../../globalShared';

// Imported styles
import { ContentContainer, FormTitle, InputsContainer, Column } from '../ExerciseView/ExerciseStyles';

// Assets
import { ReactComponent as CloseIcon } from '../../../shared/assets/Close.svg';
import { Label } from '../../../shared/components/InputComponent/InputComponent.styles';
import { ValdErrMsg } from '../../../globalShared/CommonStyles';
import { shortenText } from '../../../services/utility';

const AddGroupModal = () => {
  // Hooks state
  const [searchValue, setSearchValue] = useState('');
  const [groupTitle, setGroupTitle] = useState('');
  const [players, setPlayers] = useState([]);
  const [errors, setErrors] = useState({});

  const page = useSelector((state) => state.modalData.pageNo);
  const count = useSelector((state) => state.modalData.countNo);
  const addGroupModal = useSelector((state) => state.modalData.addGroupModal);
  const newGroupPlayers = useSelector((state) => state.modalData.newGroupPlayers);
  const groupPlayers = useSelector((state) => state.modalData.groupPlayers);
  const addGroupModalMode = useSelector((state) => state.modalData.addGroupModalMode);
  const dispatch = useDispatch();

  const handleTitle = (text) => {
    setGroupTitle(text);
    const errs = errors;
    if (!text) {
      errs.title = true;
      setErrors({ ...errs });
    } else {
      errs.title = false;
      setErrors({ ...errs });
    }
  };

  const handleCancel = () => {
    dispatch(
      ModalDataCreators.setAddGroupModal({
        addGroupModal: false,
        addGroupModalMode: 'ADD'
      })
    );
    setSearchValue('');
    dispatch(ModalDataCreators.searchReset(searchValue));
  };

  const validate = () => {
    // const errs = errors;
    let IsValid = true;

    if (!groupTitle) {
      IsValid = false;
      // errs.title = true;
      setErrors({ ...errors, title: true });
    }

    if (!players.length) {
      IsValid = false;
      // errs.players = 'Required';
      setErrors({ ...errors, players: 'Required' });
    }

    return IsValid;
  };

  const onSubmit = () => {
    if (validate()) {
      const newPlayerId = players.map((player) => player._id);
      const payload = {
        title: groupTitle.trim(),
        players: newPlayerId
      };

      if (addGroupModalMode === 'EDIT') {
        payload.mode = 'EDIT';
        payload._id = groupPlayers._id;
        dispatch(ModalDataCreators.editDeleteGroup(payload));
      } else {
        dispatch(ModalDataCreators.getAddGroup(payload));
      }

      handleCancel();
    }
  };

  const onSearchChange = (value) => {
    setSearchValue(value);
  };

  useEffect(() => {
    //
    if (addGroupModalMode === 'EDIT') {
      setGroupTitle(groupPlayers?.title);
      setPlayers(groupPlayers?.playerList);
    } else {
      setGroupTitle('');
      setPlayers([]);
    }
  }, [addGroupModal]);

  useEffect(() => {
    if (searchValue.trim()) {
      dispatch(ModalDataCreators.getGroupPlayersSearchList({ searchValue: searchValue.trim(), page, count }));
    } else {
      //  if the string is empty reset the reducer value or only have spaces
      const payload = {
        newGroupPlayers: []
      };
      dispatch(ModalDataCreators.getGroupPlayersSearchListSuccess(payload));
    }
  }, [searchValue, page]);

  const onClick = (value) => {
    const check = players.filter((player) => value._id === player._id);
    if (check.length === 0) {
      const newPlayersArray = [...players, value];
      setPlayers(newPlayersArray);
    }
    setSearchValue('');

    // onchange error handler
    if (check.length) {
      setErrors({ ...errors, players: 'Player Already Added' });
    } else {
      setErrors({ ...errors, players: '' });
    }
  };

  // for removing the players from the list
  const onRemovePlayerFromList = (id) => {
    const newPlayers = players.filter((player) => player._id !== id);
    setPlayers(newPlayers);
  };

  return (
    <ModalComponent width='600' onCancel={handleCancel} onSubmit={onSubmit}>
      <ContentContainer>
        <form onSubmit={onSubmit}>
          <FormTitle>{addGroupModalMode === 'ADD' ? 'Create Group' : 'Edit Group'}</FormTitle>

          <div style={{ padding: '1.2rem' }}>
            <InputsContainer style={{ border: 'none' }}>
              <Column wdth='100%'>
                <FormInput placeholder='Group Title' label='Title' value={groupTitle} setValue={handleTitle} />
                <ValdErrMsg>{errors.title && 'Required'}</ValdErrMsg>
              </Column>
              <Column wdth='100%'>
                <LabelContainer>
                  <Label style={{ marginTop: '10px' }}>Add players</Label>
                  <Label style={{ marginTop: '10px' }}>{`Total: ${players.length}`}</Label>
                </LabelContainer>
              </Column>
              <Column wdth='100%'>
                <SearchInput
                  placeholder='Search With Players'
                  onChange={(e) => onSearchChange(e)}
                  list={newGroupPlayers}
                  setValue={onClick}
                />
                <ValdErrMsg>{errors.players}</ValdErrMsg>

                <PlayerGroupContainer>
                  {players.map((player) => {
                    const { fullName, _id } = player;
                    let displayName = '';
                    if (fullName) {
                      displayName = fullName;
                    } else {
                      displayName = `${player.firstName} ${player.lastName}`;
                    }
                    return (
                      <PlayerCard key={`${_id}${Math.random()}`}>
                        <span style={{ fontSize: '16px' }}>{shortenText(displayName, 15, '...')}</span>
                        <CloseIcon onClick={() => onRemovePlayerFromList(_id)} style={{ marginLeft: '19px' }} />
                      </PlayerCard>
                    );
                  })}
                </PlayerGroupContainer>
              </Column>
            </InputsContainer>
          </div>
        </form>
      </ContentContainer>
    </ModalComponent>
  );
};

export default AddGroupModal;
