import styled from 'styled-components';

import { fontSize, fontWeight } from '../../../shared/constants';

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const HeaderTxt = styled.h1`
  font-size: ${fontSize.titleVerySmall};
  font-weight: ${fontWeight.bold};
  margin: 0 1rem;
  text-transform: capitalize;
`;

export const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PlayerContainer = styled.div`
  width: 73%;
`;

export const Player = styled.div`
  position: relative;
  display: flex;
  height: 393px !important;
  background: #000;
  border-radius: 10px 10px 0 0;

  & > svg {
    position: absolute;
    right: 36px;
    top: 28px;
  }

  & > svg > g > path {
    fill: #fff;
  }
`;

export const CommentsContainer = styled.div`
  width: 25%;
  height: 503px;
  border-radius: 6px;
`;

export const VideoMenu = styled.div`
  position: absolute;
  right: -170px;
  top: 56px;
  height: 165px;
  width: 220px;

  background: #262130;
  box-shadow: 0px 5px 18px #0000002e;
  border-radius: 10px;
  z-index: 9999;

  display: ${(props) => (props.showVideoMenu ? 'flex' : 'none')};
  align-items: unset;
  flex-direction: column;
  justify-content: center;

  & > div {
    padding: 1rem 2rem;
    font-size: 14px;
    cursor: pointer;
    text-transform: capitalize;
  }
`;
