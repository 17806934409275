/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import { TabsContainer } from './TabStyles';
import { TabsComponenet } from '../../../globalShared';

import SleepTrack from './SleepTrack';
import SleepStats from './SleepStats';

const SleepTabs = (props) => {
  const { date } = props;
  const [activeTab, setActiveTab] = useState('Track');

  const handleTabChange = (label) => {
    setActiveTab(label);
  };

  return (
    <TabsContainer>
      <TabsComponenet getactiveTab={handleTabChange}>
        <div label='Track'>
          <SleepTrack date={date} />
        </div>

        <div label='Stats'>
          <SleepStats date={date} />
        </div>
      </TabsComponenet>
    </TabsContainer>
  );
};

export default SleepTabs;
