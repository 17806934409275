import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getAdminHomeDetails: ['payload'],
  getAdminHomeDetailsStart: ['payload'],
  getAdminHomeDetailsSuccess: ['payload'],
  getFailure: ['payload']
});

export { Types, Creators };
