/* eslint-disable radix */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import moment from 'moment';
import { getDayMonthYear, getToday, getYearMonthDay, getYesterdayDate } from './moment';

export const mergeArray = (arr1, arr2) => {
  const resultArray = Object.values(
    [...arr1, ...arr2].reduce((result, { _id, ...rest }) => {
      result[_id] = {
        ...(result[_id] || {}),
        _id,
        ...rest
      };
      return result;
    }, {})
  );
  return resultArray;
};

function pad(string) {
  return `0 ${string}`.slice(-2);
}

export function Timeformater(seconds) {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  let mm = date.getUTCMinutes();
  let ss = date.getUTCSeconds();

  if (mm < 10) mm = `0${mm}`;
  if (ss < 10) ss = `0${ss}`;

  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`;
  }
  return `${mm} : ${ss}`;
}

export function timeSince(date) {
  const LoacalTime = moment.utc(date).format();
  const seconds = Math.floor((new Date() - new Date(LoacalTime)) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return `${new Date(date).getFullYear()}`;
  }
  interval = seconds / 2592000;

  if (interval > 1) {
    return `${getDayMonthYear(date)}`;
  }
  interval = seconds / 86400;

  if (interval > 1) {
    return `${Math.floor(interval)} ${Math.floor(interval) > 1 ? 'Days' : 'Day'} Ago`;
  }
  interval = seconds / 3600;

  if (interval > 1) {
    return `${Math.floor(interval)} Hrs Ago`;
  }
  interval = seconds / 60;

  if (interval > 1) {
    return `${Math.floor(interval)} Min Ago`;
  }

  return 'Now';
}

export const getInitials = (string) => {
  const names = string.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const dayFormater = (value) => {
  const date = getYearMonthDay(value);
  const today = getYearMonthDay(getToday());
  const yesterday = getYearMonthDay(getYesterdayDate());
  if (date === today) return 'Today';
  if (date === yesterday) return 'Yesterday';

  return getDayMonthYear(value);
};

// behaviourial functions
export const scrollToView = (ref) => {
  setTimeout(() => {
    if (ref.current) ref.current.scrollIntoView({ behavior: 'smooth' });
  }, 100);
};

export function shortenText(text, count, postfix) {
  return text?.length > count ? `${text.substring(0, parseInt(count))}${postfix}` : text;
}
