import styled from 'styled-components';

import { fontSize, backgroundColor, fontWeight } from '../../../shared/constants';

export const ModalOverlay = styled.div`
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  right: 0px;

  display: flex;
  justify-content: flex-end;

  background-color: ${backgroundColor.popupBg};
`;

export const ModalHeaderContainer = styled.div`
  background: #15151c;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 23px;
  margin-left: 29px;
  position: relative;
`;

export const ModalContentContainer = styled.div`
  padding: 1rem 2.5rem;
  width: 100%;

  overflow-y: auto;
  display: flex;
  align-items: center;
`;

export const Card = {
  CardNew: styled.div`
    position: absolute;
    top: 0;
    background-color: #15151c;
    color: #333;
    width: 444px;
    overflow: auto;
    height: 100%;
    border-radius: 6px 0 0 6px;
  `,

  CardPara: styled.div`
    position: absolute;
    right: 462px;
    top: 28px;
    cursor: pointer;
  `
};

export const CardFlex = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: -25px;
`;

export const ModalTitle = styled.h2`
  font-size: 1.7rem;
  margin-right: 15px;
`;

export const Container = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  overflow: hidden;
  padding: 11px 20px;
`;

export const InfoHeader = styled.h2`
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.smallDefault};
  color: #8792ac;
  padding: 4px;
  margin-right: 6px;
`;

export const Info = styled.h2`
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.mediumDefault};
  height: 19px;
  width: 76%;
  color: #cbd1df;
  margin-right: 10px;
  /* margin-top: 16px; */
`;

export const TableClone = styled.div`
  display: flex;
  justify-content: start;
  background: #211e2b;
  top: 156px;
  height: 80px;
  margin-bottom: 20px;
  margin-right: 29px;
  margin-left: 29px;
  padding: 15px 20px;
  border-radius: 6px;
`;

export const PlayerName = styled.div`
  position: relative;
  display: flex;
  font-size: ${fontSize.mediumDefault};
  text-align: center;
  color: white;
  top: 15px;
  max-width: 112px;
  left: 19px;
  height: 19px;
  min-width: 100px;
`;

export const HistoryBtn = styled.div`
  position: relative;
  font-size: ${fontSize.mediumDefault};
  color: #21d0b3;
  cursor: pointer;
  top: 14px;
  left: 72px;
`;

export const ScrollContainer = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 140px);
  margin-top: 1rem;
`;

export const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const PlayerGroupContainer = styled.div``;

export const PlayerCard = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 5px 8px;
  height: 47px;
  margin-bottom: 20px;
  margin-right: 20px;
  position: relative;
  background-color: rgba(78, 67, 105, 0.2);
  border-radius: 6px;
  color: white;
`;
