import styled from 'styled-components';
import { backgroundColor, textColors } from '../../shared/constants';

export const ToastContainer = styled.div`
  position: absolute;
  bottom: 40px;
  right: 20px;
  z-index: 999999999999999999999999;
`;
export const ToastItemContainer = styled.div`
  min-height: 80px;
  min-width: 250px;

  background: ${backgroundColor.light};
  border-radius: 10px;

  display: flex;
  margin: 1rem 0;
  padding: 1.8rem;
`;

export const TextContainer = styled.div`
  padding: 0 1rem;
`;

export const Title = styled.h1`
  font-size: 20px;
  color: ${textColors.textDark};
  margin-bottom: 2px;
  text-transform: capitalize;
`;

export const Message = styled.p`
  font-size: 14px;
  color: ${textColors.textDark};
`;
