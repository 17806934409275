import styled from 'styled-components';
import { fontSize } from '../../../shared/constants';

export const ContentContainer = styled.div``;

export const Form = styled.form`
  width: 100%;
  padding: 3rem;
`;

export const FormTitle = styled.h1`
  font-size: calc(${fontSize.titleVerySmall} - 0.1rem);
  margin-bottom: 1.5rem;
`;

export const Menu = styled.div`
  position: absolute;
  right: 25px;
  top: 0;
  height: 85px;
  width: 96px;
  background: #262130;
  box-shadow: 0px 5px 18px #0000002e;
  border-radius: 6px;
  z-index: 999;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  align-items: unset;
  flex-direction: column;
  justify-content: center;

  & > div {
    padding: 1rem 2rem;
    font-size: 14px;
    cursor: pointer;
  }
`;
