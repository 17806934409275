/* eslint-disable no-unused-expressions */
import React, { useRef, useEffect } from 'react';
import { Label } from '../../../../globalShared/CommonStyles';
import { Container, Input } from './FormInput.styles';

const FormInput = (props) => {
  // values
  const { placeholder, label, disabled, value, styles, showLable = true, type = 'text', error } = props;

  // functions
  const { setValue } = props;
  const ref = useRef();

  useEffect(() => {
    if (error) {
      ref.current && ref.current.focus();
    }
  }, [error]);

  return (
    <Container style={styles}>
      {showLable && <Label>{label}</Label>}
      <div>
        <Input
          disabled={disabled}
          placeholder={placeholder || ''}
          value={value}
          onChange={(event) => setValue(event.target.value)}
          type={type}
          ref={ref}
          className={error ? 'error' : ''}
        />
      </div>
    </Container>
  );
};

export default FormInput;
