import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getPlayerMoodStats: ['payload'],
  getPlayerMoodStatsStart: ['payload'],
  getPlayerMoodStatsSuccess: ['payload'],
  getPlayerMoodStatsFailure: ['payload'],

  getPlayerSleepStats: ['payload'],
  getPlayerSleepStatsStart: ['payload'],
  getPlayerSleepStatsSuccess: ['payload'],
  getPlayerSleepStatsFailure: ['payload'],

  getPlayerMeditationStats: ['payload'],
  getPlayerMeditationStatsStart: ['payload'],
  getPlayerMeditationStatsSuccess: ['payload'],
  getPlayerMeditationStatsFailure: ['payload'],

  getPlayerTodaysMeditationCheck: ['payload'],
  getPlayerTodaysMeditationCheckStart: ['payload'],
  getPlayerTodaysMeditationCheckSuccess: ['payload'],
  getPlayerTodaysMeditationCheckFailure: ['payload'],

  addPlayerMeditationComment: ['payload'],
  addPlayerMeditationCommentStart: ['payload'],
  addPlayerMeditationCommentSuccess: ['payload'],
  addPlayerMeditationCommentFailure: ['payload'],

  getPlayerMeditationTrackComments: ['payload'],
  getPlayerMeditationTrackCommentsStart: ['payload'],
  getPlayerMeditationTrackCommentsSuccess: ['payload'],
  getPlayerMeditationTrackCommentsFailure: ['payload'],

  deletePlayerTodaysMeditationCheck: ['payload'],
  deletePlayerTodaysMeditationCheckStart: ['payload'],
  deletePlayerTodaysMeditationCheckSuccess: ['payload'],
  deletePlayerTodaysMeditationCheckFailure: ['payload'],

  setPlayerMeditationDuration: ['payload'],
  setPlayerMeditationDurationStart: ['payload'],
  setPlayerMeditationDurationSuccess: ['payload'],
  setPlayerMeditationDurationFailure: ['payload'],

  editPlayerMeditationDuration: ['payload'],
  editPlayerMeditationDurationStart: ['payload'],
  editPlayerMeditationDurationSuccess: ['payload'],
  editPlayerMeditationDurationFailure: ['payload'],

  getPlayerTodaysSleepCheck: ['payload'],
  getPlayerTodaysSleepCheckStart: ['payload'],
  getPlayerTodaysSleepCheckSuccess: ['payload'],
  getPlayerTodaysSleepCheckFailure: ['payload'],

  addPlayerSleep: ['payload'],
  addPlayerSleepStart: ['payload'],
  addPlayerSleepSuccess: ['payload'],
  addPlayerSleepFailure: ['payload'],

  deletePlayerSleep: ['payload'],
  deletePlayerSleepStart: ['payload'],
  deletePlayerSleepSuccess: ['payload'],
  deletePlayerSleepFailure: ['payload'],

  getPlayerSleepTrackComments: ['payload'],
  getPlayerSleepTrackCommentsStart: ['payload'],
  getPlayerSleepTrackCommentsSuccess: ['payload'],
  getPlayerSleepTrackCommentsFailure: ['payload'],

  setCurrentTrackProgress: ['payload']
});

export { Types, Creators };
