/* eslint-disable radix */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getToday, getYearMonthDay } from '../../../services/utility/moment';
import Toast, { Toasts } from '../../../services/toast';
import { Creators } from './store';
import { Creators as CommonCreators } from '../../store';
// styles
import {
  Calender,
  Container,
  ExercisesC,
  H2,
  H3,
  P,
  Progress,
  ProgressContainer,
  SessionsCardContainer,
  SessionsContainer,
  SessionsHeader,
  Stat,
  Stats,
  StatusContainer,
  Workout,
  WorkoutsBody,
  WorkoutsContainer,
  WorkoutsFooter,
  WorktoutFlowCon
} from './StrengthStyles';

import { EmptyContentText, FlexContainer } from '../../../globalShared/CommonStyles';
import { ListItem, ThumbContainer, Thumbnail } from '../../Coach/ExerciseView/ExerciseStyles';

// assets
import dummyImage from '../../../shared/assets/workout.jpeg';
import { ReactComponent as Arrow } from '../../../shared/assets/Dropdown.svg';

import { ReactComponent as RepsIcon } from '../../../shared/assets/reps.svg';
import { ReactComponent as SetsIcon } from '../../../shared/assets/sets.svg';
import { ReactComponent as WeightIcon } from '../../../shared/assets/weight.svg';
import { ReactComponent as VolumeIcon } from '../../../shared/assets/dummbell.svg';
import { ReactComponent as Completed } from '../../../shared/assets/done.svg';

// components
import ExerciseDetailsModal from './ExerciseDetailsModal';
import { ButtonComponent, LoadingComponent } from '../../../globalShared';
import StrengthSessionCard from '../../../shared/components/StrengthSessionCard';
import HeaderCalender from '../../../shared/components/HeaderCalenderComponent';
import ProgressRing from '../../Coach/MealRecentView/ProgressRing';
import { _converIntoKAndM } from '../../../services/utility/commurize';
// import { scrollToView } from '../../../services/utility';

/**
 * Strength view starts
 */
const StrengthView = () => {
  const [ShowExerciseModal, setShowExerciseModal] = useState(false);
  const [selectedSession, setselectedSession] = useState('');
  const [isSessionCompleted, setIsSessionCompleted] = useState(false);
  const [selectedWorkOut, setselectedWorkOut] = useState('');
  const [selectedExercise, setselectedExercise] = useState('');
  const [exerciseSet, setExercisSet] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [recentSessionId, setrecentSessionId] = useState('');
  const [isToady, setIsToday] = useState(true);
  const [recentSession, setRecentSession] = useState('');
  const dispatch = useDispatch();
  // const selectedSessionRef = useRef();
  // const otherSessionRef = useRef();

  const sessionsList = useSelector((state) => state.playerStrength.sessionsList);
  const availableList = useSelector((state) => state.playerStrength.availableSessions);
  const { workoutList, sessionStats } = useSelector((state) => state.playerStrength);
  const exerciseDetails = useSelector((state) => state.playerStrength.exerciseDetails);
  const success = useSelector((state) => state.playerStrength.success);
  const error = useSelector((state) => state.playerStrength.error);
  const reload = useSelector((state) => state.playerStrength.reload);
  const sessionComments = useSelector((state) => state.playerStrength.commentsList);
  const reloadComments = useSelector((state) => state.main.reloadComments);
  const loading = useSelector((state) => state.playerStrength.loading);

  //
  const getDaySessions = (date) => {
    dispatch(Creators.getDaySessionList({ date: getYearMonthDay(date) }));
  };

  const getFromToSessions = ({ startDate, endDate }) => {
    dispatch(Creators.getAvailableSessions({ fromDate: getYearMonthDay(startDate), toDate: getYearMonthDay(endDate) }));
  };

  const handleMarkAsComplete = () => {
    const progressStatus = 'Marked Completed';
    dispatch(Creators.setSessionStatus({ _id: selectedSession.sessionId, progressStatus }));
    setRecentSession(selectedSession);
  };

  const handleShowExerciseModal = (exercise, workoutId) => {
    if (!isSessionCompleted && isToady) {
      setselectedExercise(exercise);
      setselectedWorkOut(workoutId);
      setShowExerciseModal(true);
    }
  };

  const getExerciseDetails = () => {
    dispatch(
      Creators.getWorkoutExerciseDetails({
        sessionId: selectedSession.sessionId,
        workoutId: selectedWorkOut,
        exerciseId: selectedExercise.exerciseId
      })
    );
  };

  const handleUpdateExercise = (data) => {
    const payload = {
      sessionId: selectedSession.sessionId,
      workoutId: selectedWorkOut,
      exerciseId: selectedExercise.exerciseId,
      sets: data,
      // eslint-disable-next-line radix
      noOfSets: parseInt(exerciseDetails.sets),
      exerciseName: selectedExercise.title
    };

    dispatch(Creators.setPlayerExercise(payload));
  };

  const handleGetComment = (_id) => {
    if (_id) {
      dispatch(Creators.getPlayerSessionComments({ _id, count: 100 }));
      setrecentSessionId(_id);
    }
  };

  const handleSubmitComment = (comment) => {
    dispatch(CommonCreators.addComment({ ...comment }));
    setrecentSessionId(comment.id);
  };

  const handleDayChange = (value) => {
    getDaySessions(value);
    setSelectedDate(value);
    setselectedSession(null);
  };

  // console.log(exerciseSet);
  const handleGoNext = () => setselectedExercise(exerciseSet.next);
  const handleGoPrev = () => {
    setselectedExercise(exerciseSet.prev);
  };

  useEffect(() => {
    const Today = getToday();
    setIsToday(getYearMonthDay(Today) === getYearMonthDay(selectedDate));
  }, [selectedDate]);

  useEffect(() => {
    if (sessionsList.length > 0 && !selectedSession) {
      setselectedSession(sessionsList[0]);
    } else {
      const session = sessionsList.find((ele) => ele.sessionId === selectedSession.sessionId);
      setselectedSession(session);
    }
  }, [sessionsList]);

  useEffect(() => {
    if (selectedSession) {
      dispatch(Creators.getSessionWorkouts({ _id: selectedSession.sessionId, count: 100, page: 1 }));
    }
  }, [selectedSession]);

  useEffect(() => {
    if (selectedSession) {
      setIsSessionCompleted(
        selectedSession.progressStatus === 'Marked Completed' || selectedSession.progressStatus === 'Completed'
      );
    }
  }, [selectedSession]);

  useEffect(() => {
    if (selectedExercise) {
      // console.log(selectedExercise);
      const workout = workoutList.filter((x) => x.workoutId === selectedWorkOut);
      const exercises = workout[0]?.exerciseList;
      const index = exercises.findIndex((i) => i.exerciseId === selectedExercise.exerciseId);

      if (index === 0) {
        const result = { prev: null, current: exercises[index], next: exercises[index + 1] };
        setExercisSet(result);
      }

      if (index === exercises.length - 1) {
        const result = { prev: exercises[index - 1], current: exercises[index], next: null };
        setExercisSet(result);
      }

      if (index !== 0 && index !== exercises.length - 1) {
        const result = { prev: exercises[index - 1], current: exercises[index], next: exercises[index + 1] };
        setExercisSet(result);
      }

      getExerciseDetails();
      // scrollToView(selectedSessionRef);
    }
  }, [selectedExercise]);

  useEffect(() => {
    if (error) {
      const reset = () => dispatch(Creators.resetError());
      Toast.error(error, 2, reset);
    }
  }, [error]);

  useEffect(() => {
    if (success) {
      const reset = () => dispatch(Creators.resetSuccess());
      Toast.success('success', 2, reset);
    }
  }, [success]);

  useEffect(() => {
    if (reload) {
      getDaySessions(selectedDate);
      setselectedSession(recentSession);
    }
  }, [reload]);

  useEffect(() => {
    if (reloadComments) {
      handleGetComment(recentSessionId);
    }
  }, [reloadComments]);

  const _renderStats = () => {
    const { progressPercentage = 0, totalReps = 0, totalSets = 0, totalVolume = 0, totalWeight = 0 } = sessionStats;

    return (
      <StatusContainer>
        <ProgressContainer>
          <ProgressRing radius={105} stroke={8} progress={progressPercentage} />
          <Progress>{`${Math.round(progressPercentage)}%`}</Progress>
        </ProgressContainer>

        <Stats>
          <Stat>
            <SetsIcon />
            <h1>{totalSets}</h1>
            <span>Sets</span>
          </Stat>
          <Stat>
            <RepsIcon />
            <h1>{totalReps}</h1>
            <span>Reps</span>
          </Stat>
          <Stat>
            <WeightIcon />
            <h1>{_converIntoKAndM(parseFloat(totalWeight))}</h1>
            <span>Weight(Kg)</span>
          </Stat>
          <Stat>
            <VolumeIcon />
            <h1>{_converIntoKAndM(parseFloat(totalVolume))}</h1>
            <span>Total Volume</span>
          </Stat>
        </Stats>
      </StatusContainer>
    );
  };

  // eslint-disable-next-line arrow-body-style
  const renderWorkout = (workout, i) => {
    const { exerciseList, workoutName, workoutId } = workout;
    return (
      <Workout key={`${i + 1}`} Inactive={isSessionCompleted || !isToady}>
        <H3 style={{ marginBottom: '1rem' }}>{workoutName}</H3>
        {exerciseList.map((exercise, i) => {
          const { exerciseId, title, thumbUrl, suggestedKgs, suggestedReps, suggestedsets, status } = exercise;
          return (
            <ListItem
              key={`${exerciseId}_${i}`}
              style={{ width: '100%', cursor: 'pointer' }}
              onClick={() => handleShowExerciseModal(exercise, workoutId)}
            >
              <FlexContainer style={{ cursor: 'pointer', maxWidth: '70%' }} onClick={() => null}>
                <ThumbContainer>
                  <Thumbnail src={thumbUrl || dummyImage} alt={title} />
                  {status === 'Completed' ? <Completed /> : ''}
                </ThumbContainer>
                <P className='clip' style={{ margin: '0 1rem' }}>
                  {title}
                </P>
              </FlexContainer>
              <ExercisesC>
                {suggestedsets && <P light>{`${_converIntoKAndM(parseInt(suggestedsets))} Sets`}</P>}
                {suggestedReps && <P light>{`${suggestedReps} Reps`}</P>}
                {suggestedKgs && <P light>{`${_converIntoKAndM(parseFloat(suggestedKgs))} Kgs`}</P>}
                <Arrow />
              </ExercisesC>
            </ListItem>
          );
        })}
      </Workout>
    );
  };

  return (
    <>
      {loading && <LoadingComponent />}
      <Toasts />
      {ShowExerciseModal && (
        <ExerciseDetailsModal
          selectedExercise={exerciseDetails}
          fetch={getExerciseDetails}
          onCancel={() => setShowExerciseModal(false)}
          onSubmit={handleUpdateExercise}
          exerciseSet={exerciseSet}
          goNext={handleGoNext}
          goPrev={handleGoPrev}
          updateSession={() => getDaySessions(selectedDate)}
        />
      )}
      <Calender>
        <HeaderCalender
          onChange={handleDayChange}
          getDates={(dates) => getFromToSessions(dates)}
          list={availableList}
        />
      </Calender>
      {/* <Divider /> */}
      <Container>
        <SessionsContainer>
          <SessionsHeader>
            <H3>Sessions</H3>
          </SessionsHeader>
          <SessionsCardContainer>
            {sessionsList.length ? (
              sessionsList.map((session) => (
                <StrengthSessionCard
                  active={session.sessionId === selectedSession?.sessionId}
                  key={session.sessionId}
                  data={session}
                  onClick={() => setselectedSession(session)}
                  submitComment={handleSubmitComment}
                  onShowComments={handleGetComment}
                  comments={sessionComments}
                  // ref={session.sessionId === selectedSession?.sessionId ? selectedSessionRef : otherSessionRef}
                />
              ))
            ) : (
              <EmptyContentText fontSize='24px'>No Sessions Available</EmptyContentText>
            )}
          </SessionsCardContainer>
        </SessionsContainer>
        {selectedSession && (
          <WorkoutsContainer>
            <WorktoutFlowCon>
              <SessionsHeader>
                <H2 style={{ fontWeight: '700' }}>Workouts</H2>
              </SessionsHeader>
              {_renderStats()}
              <WorkoutsBody>{workoutList.map((workout, i) => renderWorkout(workout, i))}</WorkoutsBody>
            </WorktoutFlowCon>
            <WorkoutsFooter>
              <ButtonComponent
                isButtonAction={!isSessionCompleted && isToady}
                buttonClick={handleMarkAsComplete}
                buttonText='Mark as completed'
                margin={false}
              />
            </WorkoutsFooter>
          </WorkoutsContainer>
        )}
      </Container>
    </>
  );
};

export default StrengthView;
