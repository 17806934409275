/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import moment from 'moment';

import { useDispatch } from 'react-redux';
import { Creators } from './store';

// styles
import {
  ModalContainer,
  ContentContainer,
  CloseButton,
  FormTitle,
  MealTypeContainer,
  MealText,
  MealType,
  Column,
  ScrollContainer,
  FormFooter,
  ButtonContainer,
  DelContainer
} from './ManualInputModalStyles';

// icons
import { ReactComponent as CloseIcon } from '../../../shared/assets/Close.svg';

import { AddMoreComponent, ButtonComponent, TooltipComponnet } from '../../../globalShared';
import { Divider, FlexContainer, ValdErrMsg } from '../../../globalShared/CommonStyles';
import { FormInput, TextArea, Dropdown } from '../../../shared/components/FormComponents';
import { genTimeStrings } from '../../../services/utility/timeFormat';
import { shortenText } from '../../../services/utility';

const numbersRegx = /^[0-9]+$/;

const ManualInputModal = (props) => {
  const { _id, date, time, mealId, mealTitle, inputModalClose } = props;
  const todaysDate = moment().format('YYYY-MM-DD');
  const dispatch = useDispatch();
  const mealTime = genTimeStrings();

  // form data

  const [intakeTime, setIntakeTime] = useState('');
  const [Kcal, setKcal] = useState('');
  const [intakes, setIntakes] = useState([{ descr: '', remarks: '' }]);
  const [errors, setErrors] = useState({});

  // form validation
  function checkFormFilled() {
    let IsValid = true;

    const intakeIsFilled = intakes.map(({ descr }) => !!descr).reduce((res, cur) => res && cur, true);

    if (!intakeIsFilled) {
      IsValid = false;
      setErrors({ ...errors, IntakeErr: 'Fill All Intakes' });
    }

    if (!intakeTime) {
      IsValid = false;
      setErrors({ ...errors, timeErr: 'Required' });
    }

    if (!`${Kcal}`) {
      IsValid = false;
      setErrors({ ...errors, calErr: 'Required' });
    }
    return IsValid;
  }

  const handleKcalChange = (value) => {
    if (value.match(numbersRegx) || value.length === 0) {
      const val = value ? parseInt(value, 10) : '';
      setKcal(val);
      setErrors({ ...errors, calErr: null });
    }
  };

  const handleTimeChange = (value) => {
    setIntakeTime(value);
    if (value) {
      setErrors({ ...errors, timeErr: null });
    }
  };

  const handleIntakesChange = (value, k) => {
    const newObj = [...intakes];
    newObj[k] = { ...newObj[k], descr: value };
    setIntakes(newObj);
  };

  const handleRemarksChange = (value, k) => {
    const newObj = [...intakes];
    newObj[k] = { ...newObj[k], remarks: value };

    setIntakes(newObj);
  };

  const handleCancel = () => {
    inputModalClose();
  };

  const handleSubmit = () => {
    const payload = {
      _id,
      timeCompleted: intakeTime,
      action: 'Manual Input',
      meals: {
        mealId,
        title: mealTitle,
        kcal: Kcal,
        time,
        intake: intakes
      }
    };

    if (checkFormFilled()) {
      dispatch(Creators.setPlayerMealUpdate({ mealData: payload }));
      inputModalClose();
    }
  };

  const addIntakes = () => {
    const val = [...intakes, { descr: '', remarks: '' }];
    setIntakes(val);
  };

  const removeIntakes = (index) => {
    const val = intakes.filter((_, k) => k !== index);
    setIntakes(val);
  };

  return (
    <ModalContainer>
      <ContentContainer>
        <CloseButton onClick={inputModalClose}>
          <CloseIcon />
        </CloseButton>
        <FormTitle>Input Intakes Manually</FormTitle>
        <MealTypeContainer>
          <MealText>Meal</MealText>
          <MealType>{shortenText(mealTitle, 20, '...')}</MealType>
        </MealTypeContainer>

        <FlexContainer style={{ width: '100%', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <Column wdth='180px'>
            <Dropdown
              list={mealTime}
              label='Time'
              value={intakeTime}
              setValue={(value) => handleTimeChange(value)}
              maxHeight='150'
            />
            {errors.timeErr && <ValdErrMsg>{errors.timeErr}</ValdErrMsg>}
          </Column>

          <Column wdth='180px'>
            <FormInput label='Kcal' placeholder='300' value={Kcal} setValue={(value) => handleKcalChange(value)} />
            {errors.calErr && <ValdErrMsg>{errors.calErr}</ValdErrMsg>}
          </Column>
        </FlexContainer>

        <ScrollContainer>
          {intakes.map((item, k) => (
            <div key={k} style={{ margin: '1.5rem 0' }}>
              <DelContainer>
                <TooltipComponnet type='clear' title='clear' onClick={() => removeIntakes(k)} />
              </DelContainer>
              <TextArea
                value={item.descr}
                placeholder='Input your Intakes Here'
                label='Intakes'
                height='79px'
                setValue={(value) => handleIntakesChange(value, k)}
              />

              <TextArea
                value={item.remarks}
                placeholder='Input your Remarks Here'
                label='Remarks'
                height='79px'
                setValue={(value) => handleRemarksChange(value, k)}
              />
              <Divider />
            </div>
          ))}
          <AddMoreComponent onClick={addIntakes}>+ Add Intake</AddMoreComponent>
        </ScrollContainer>

        {errors.IntakeErr && <ValdErrMsg>{errors.IntakeErr}</ValdErrMsg>}
        <FormFooter>
          <Divider />
          <ButtonContainer>
            <ButtonComponent buttonText='Cancel' isButtonAction={true} buttonClick={handleCancel} bgType='dark' />
            <ButtonComponent buttonText='Done' isButtonAction={date === todaysDate} buttonClick={handleSubmit} />
          </ButtonContainer>
        </FormFooter>
      </ContentContainer>
    </ModalContainer>
  );
};

export default ManualInputModal;
