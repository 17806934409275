/*
 * reducer.js file for player strength view
 * Author:
 * 1.Manikanta
 * @copyright of Panorbit Services LLP.
 */

import { createReducer } from 'reduxsauce';
import { mergeArray } from '../../../../services/utility';
import { Types } from './action';

const INITIAL_STATE = {
  loading: false,
  workoutsLoading: false,
  exerciseLoading: false,
  error: null,
  maxPage: 1,
  reload: false,
  success: false,
  sessionsList: [],
  workoutList: [],
  availableSessions: [],
  exerciseDetails: null,
  commentsList: [],
  sessionStats: {}
};

const getDaySessionListStart = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true,
  workoutList: [],
  sessionStats: {}
});

const getDaySessionListSuccess = (state = INITIAL_STATE, action) => {
  const { sessionsList } = action.payload;

  return {
    ...state,
    loading: false,
    reload: false,
    sessionsList
  };
};

const getDaySessionListFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const setAvailableSessions = (state = INITIAL_STATE, action) => {
  const { availableSessions } = action.payload;

  return {
    ...state,
    loading: false,
    reload: false,
    availableSessions
  };
};

const getAvailableSessionsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const getSessionWorkoutsStart = (state = INITIAL_STATE) => ({ ...state, error: null, workoutsLoading: true });

const getSessionWorkoutsSuccess = (state = INITIAL_STATE, action) => {
  const { workoutList, sessionStats } = action.payload;

  return {
    ...state,
    workoutsLoading: false,
    reload: false,
    workoutList,
    sessionStats
  };
};

const getSessionWorkoutsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    workoutsLoading: false,
    error
  };
};

const getWorkoutExerciseDetailsStart = (state = INITIAL_STATE) => ({ ...state, error: null, exerciseLoading: true });

const getWorkoutExerciseDetailsSuccess = (state = INITIAL_STATE, action) => {
  const { exerciseDetails } = action.payload;

  return {
    ...state,
    exerciseLoading: false,
    exerciseDetails
  };
};

const getWorkoutExerciseDetailsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    exerciseLoading: false,
    error
  };
};

const setSessionStatusStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const setSessionStatusSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: false,
  reload: true,
  success: true
});

const setSessionStatusFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const setPlayerExerciseStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const setPlayerExerciseSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: false,
  success: true
});

const setPlayerExerciseFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const getPlayerSessionCommentsStart = (state = INITIAL_STATE) => ({
  ...state,
  error: null
});

const getPlayerSessionCommentsSuccess = (state = INITIAL_STATE, action) => {
  const newCommentsList = action.payload.commentsList;
  const oldCommentsList = state.commentsList;
  const newArray = mergeArray(oldCommentsList, newCommentsList);
  return {
    ...state,
    commentsList: [...newArray]
  };
};

const getPlayerSessionCommentsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    error
  };
};

const resetSuccess = (state = INITIAL_STATE) => ({
  ...state,
  success: false
});

const resetError = (state = INITIAL_STATE) => ({
  ...state,
  error: null
});

const HANDLERS = {
  [Types.GET_DAY_SESSION_LIST_START]: getDaySessionListStart,
  [Types.GET_DAY_SESSION_LIST_SUCCESS]: getDaySessionListSuccess,
  [Types.GET_DAY_SESSION_LIST_FAILURE]: getDaySessionListFailure,

  [Types.SET_AVAILABLE_SESSIONS]: setAvailableSessions,
  [Types.GET_AVAILABLE_SESSIONS_FAILURE]: getAvailableSessionsFailure,

  [Types.GET_SESSION_WORKOUTS_START]: getSessionWorkoutsStart,
  [Types.GET_SESSION_WORKOUTS_SUCCESS]: getSessionWorkoutsSuccess,
  [Types.GET_SESSION_WORKOUTS_FAILURE]: getSessionWorkoutsFailure,

  [Types.GET_WORKOUT_EXERCISE_DETAILS_START]: getWorkoutExerciseDetailsStart,
  [Types.GET_WORKOUT_EXERCISE_DETAILS_SUCCESS]: getWorkoutExerciseDetailsSuccess,
  [Types.GET_WORKOUT_EXERCISE_DETAILS_FAILURE]: getWorkoutExerciseDetailsFailure,

  [Types.SET_SESSION_STATUS_START]: setSessionStatusStart,
  [Types.SET_SESSION_STATUS_SUCCESS]: setSessionStatusSuccess,
  [Types.SET_SESSION_STATUS_FAILURE]: setSessionStatusFailure,

  [Types.SET_PLAYER_EXERCISE_START]: setPlayerExerciseStart,
  [Types.SET_PLAYER_EXERCISE_SUCCESS]: setPlayerExerciseSuccess,
  [Types.SET_PLAYER_EXERCISE_FAILURE]: setPlayerExerciseFailure,

  [Types.GET_PLAYER_SESSION_COMMENTS_START]: getPlayerSessionCommentsStart,
  [Types.GET_PLAYER_SESSION_COMMENTS_SUCCESS]: getPlayerSessionCommentsSuccess,
  [Types.GET_PLAYER_SESSION_COMMENTS_FAILURE]: getPlayerSessionCommentsFailure,

  [Types.RESET_SUCCESS]: resetSuccess,
  [Types.RESET_ERROR]: resetError
};

export const playerStrengthReducer = createReducer(INITIAL_STATE, HANDLERS);
