/* eslint-disable prefer-destructuring */
import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import { Creators } from './store';
import { Creators as CommonCreators } from '../../store';

// styles
import {
  SleepDateWrapper,
  SleepDate,
  SavedContainer,
  SavedText,
  SleepDurationWrapper,
  SleepDurationText,
  Duration,
  TodaysEntryHeader,
  TodaysEntry,
  BedTimeContainer,
  BedTimes,
  Text,
  H1,
  DividerWrapper,
  CommentInputContainer
} from './SleepTrackSavedStyles';

import { Divider, FlexContainer } from '../../../globalShared/CommonStyles';
import CommentsFooter from '../../../shared/components/CommetsFooter';

// icons
import { ReactComponent as SaveIcon } from '../../../shared/assets/save.svg';
import { ReactComponent as CloseIcon } from '../../../shared/assets/Close.svg';
import { ReactComponent as BedtimeIcon } from '../../../shared/assets/bedtime_icon.svg';
import { ReactComponent as WakeuptimeIcon } from '../../../shared/assets/wakeuptime_icon.svg';
import { CommentInputComponent } from '../../../globalShared';

const SleepTrackSaved = (props) => {
  const { date, formattedDate, todaysSleepCheck } = props;
  const dispatch = useDispatch();

  const [recentCommentID, setRecentCommentID] = useState('');

  // values from redux store
  const sleepCommentsList = useSelector((state) => state.playerMindset.sleepCommentsList);
  const reloadSleepTrackComments = useSelector((state) => state.playerMindset.reloadSleepTrackComments);

  let bedTime = '00:00 AM';
  let wakeupTime = '00:00 AM';
  let durationHours = 0;
  let durationMinutes = 0;

  if (todaysSleepCheck) {
    bedTime = todaysSleepCheck.bedTime;
    wakeupTime = todaysSleepCheck.wakeupTime;

    const duration = todaysSleepCheck.duration;
    const durationArray = duration.split(':');
    durationHours = durationArray[0];
    durationMinutes = durationArray[1];
  }

  // handlers

  const handleShowComments = (_id) => {
    dispatch(Creators.getPlayerSleepTrackComments({ _id }));
    setRecentCommentID(_id);
  };

  const handleSubmitComment = (content, id) => {
    dispatch(CommonCreators.addComment({ content, id }));
    handleShowComments(id);
  };

  useEffect(() => {
    if (reloadSleepTrackComments && !!recentCommentID) {
      handleShowComments(recentCommentID);
    }
  }, [reloadSleepTrackComments]);

  const todaysDate = moment().format('YYYY-MM-DD');

  const handleSleepDelete = (_id) => {
    if (date === todaysDate) {
      dispatch(Creators.deletePlayerSleep({ _id }));
    }
  };

  return (
    <>
      <SleepDateWrapper>
        <SleepDate>{formattedDate}</SleepDate>
      </SleepDateWrapper>
      <SavedContainer>
        <SaveIcon />
        <SavedText>Saved</SavedText>
        <SleepDurationWrapper>
          <SleepDurationText>Sleep Duration:</SleepDurationText>
          <Duration>{`${durationHours} Hrs ${durationMinutes} Mins`}</Duration>
        </SleepDurationWrapper>
      </SavedContainer>
      <TodaysEntryHeader>Today&apos;s Entry</TodaysEntryHeader>

      <TodaysEntry>
        <BedTimeContainer>
          <FlexContainer>
            <BedTimes style={{ borderRight: '1px solid #a3a9b733' }}>
              <Text>
                <span>
                  <BedtimeIcon />
                </span>
                <p>Bed Time</p>
              </Text>
              <H1>{bedTime}</H1>
            </BedTimes>

            <BedTimes>
              <Text>
                <span>
                  <WakeuptimeIcon />
                </span>
                <p>Wakeup Time</p>
              </Text>
              <H1>{wakeupTime}</H1>
            </BedTimes>
          </FlexContainer>
          <DividerWrapper>
            <Divider />
          </DividerWrapper>
          <div>
            <CommentsFooter
              _id={todaysSleepCheck.id}
              commentCount={todaysSleepCheck.commentCount}
              commentsList={sleepCommentsList}
              onClick={handleShowComments}
            />
            <CommentInputContainer>
              <CommentInputComponent
                placeholderText='Enter Your Comment Here…'
                id={todaysSleepCheck && todaysSleepCheck.id}
                onSubmit={handleSubmitComment}
              />
            </CommentInputContainer>
          </div>
        </BedTimeContainer>
        {date === todaysDate ? (
          <CloseIcon
            onClick={() => todaysSleepCheck && handleSleepDelete(todaysSleepCheck.id)}
            style={{ cursor: 'pointer' }}
          />
        ) : (
          ''
        )}
      </TodaysEntry>
    </>
  );
};

export default SleepTrackSaved;
