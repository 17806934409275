/* eslint-disable no-console */
/* eslint-disable no-shadow */
import { put, select } from 'redux-saga/effects';

import { Creators } from './action';

import { WebService } from '../../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../../services/axios/endPoints';

export function* getCoachStrengthFeed({ payload }) {
  const { page, count, playerId, fetchTime } = payload;

  try {
    yield put(Creators.getCoachStrengthFeedStart());

    const fullURL = yield `${apiEndPoints.coachStrengthRecent}`;
    const response = yield WebService.get(fullURL, { params: { page, count, playerId, fetchTime } });

    if (response.status >= 200 && response.status < 300) {
      const { strengthFeed } = yield select((state) => state.coachStrengthFeed);
      const feedData = response.data.data;
      if (strengthFeed.length && page !== 1) {
        yield put(
          Creators.getCoachStrengthFeedSuccess({
            strengthFeed: [...strengthFeed, ...(feedData[0] || [])],
            fetchTime: feedData[1].fetchTime,
            isNext: feedData[2].isNext
          })
        );
      } else {
        yield put(
          Creators.getCoachStrengthFeedSuccess({
            strengthFeed: feedData[0] || [],
            fetchTime: feedData[1].fetchTime,
            isNext: feedData[2].isNext
          })
        );
      }
    } else {
      throw response;
    }
  } catch (error) {
    console.log(error);

    // eslint-disable-next-line no-shadow
    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getCoachStrengthFeedFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getCoachStrengthFeedFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getCoachStrengthFeedFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getCoachStrengthFeedFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getCoachStrengthFeedFailure(payload));
    }
  }
}
