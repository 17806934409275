/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { TabsContainer, Tab, NotificationSpan, P } from './Tabs.styles';
import { shortenText } from '../../../services/utility';

const TabsComponent = (props) => {
  const { children, getactiveTab, tab } = props;
  const [activeTab, setActiveTab] = useState(children[0].props.label);

  const handleClick = (label) => {
    setActiveTab(label);
    getactiveTab(label);
  };

  useEffect(() => {
    if (tab) {
      setActiveTab(tab);
    }
  }, [tab]);

  return (
    <>
      <div>
        <TabsContainer>
          {children.map((child, index) => {
            const { label, notification } = child.props;
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Tab className='tab ' isActive={activeTab === label} key={index + 2} onClick={() => handleClick(label)}>
                <P>{shortenText(label, 15, '...')}</P>
                {notification && <NotificationSpan>{notification}</NotificationSpan>}
              </Tab>
            );
          })}
        </TabsContainer>
        <>
          {children.map((child) => {
            if (child.props.label !== activeTab) return undefined;
            return child.props.children;
          })}
        </>
      </div>
    </>
  );
};

TabsComponent.defaultProps = {
  getactiveTab: () => null,
  tab: null
};

TabsComponent.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired,
  getactiveTab: PropTypes.func,
  tab: PropTypes.string
};
export default TabsComponent;
