/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Creators } from './store';

// styles
import { H1, Stats, TextSmallLight, LegendButtons } from './MeditationStatsStyles';
import { FlexContainer } from '../../../globalShared/CommonStyles';
import { Container } from './MeditationTrackStyles';

import { LineChart } from '../../../globalShared';
import { getDay, getMothSmall, getYearMonthDay } from '../../../services/utility/moment';
import { getPastDays } from '../HomeView/utlity';

const MeditationStats = () => {
  // const { date } = props;

  const dispatch = useDispatch();
  const [days, setDays] = useState(7);
  const [dataType, setDataType] = useState('days');

  // values from redux store
  const meditationStats = useSelector((state) => state.playerMindset.meditationStats);
  const avgMeditationDuration = useSelector((state) => state.playerMindset.avgMeditationDuration);

  const avgMeditationDurationArray = avgMeditationDuration && avgMeditationDuration.split(':');
  // const avgMeditationDurationHours = avgMeditationDuration && avgMeditationDurationArray[0];
  const avgMeditationMinutes = (avgMeditationDuration && avgMeditationDurationArray[1]) || 0;
  const avgMeditationSeconds = (avgMeditationDuration && parseInt(avgMeditationDurationArray[2], 10)) || 0;

  // data for line chart
  const pastDays = getPastDays({ type: dataType, days });

  pastDays.forEach((item) => {
    let filterDay = [];

    if (dataType === 'month') {
      filterDay = meditationStats.filter((stat) => getMothSmall(stat.time) === item.MM);
    } else {
      filterDay = meditationStats.filter((stat) => getYearMonthDay(stat.time) === item.MMDDYY);
    }

    item.sleepTime = filterDay.length > 0 ? filterDay[0].duration : null;
  });

  const meditationDates = [];
  const meditationHrs = [];

  pastDays.reverse().forEach((item) => {
    if (dataType === 'month') {
      meditationDates.push(getMothSmall(item.MMDDYY));
    } else {
      meditationDates.push(getDay(item.MMDDYY));
    }
    meditationHrs.push(item.sleepTime);
  });

  // line chart
  const chartData = (canvas) => {
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, canvas.height, 0, 0);
    gradient.addColorStop(0, '#21D0B300');
    gradient.addColorStop(1, '#21D0B382');

    return {
      labels: meditationDates,
      datasets: [
        {
          data: meditationHrs,
          fill: true,
          backgroundColor: gradient,
          borderColor: 'rgba(75,192,192,1)',
          lineTension: 0
        }
      ]
    };
  };

  // effect hooks
  useEffect(() => {
    dispatch(Creators.getPlayerMeditationStats({ page: 1, count: 30, dataType, days }));
  }, [days]);

  // handlers
  const handleWeekMeditation = () => {
    setDataType('days');
    setDays(7);
  };

  const handleMonthMeditation = () => {
    setDataType('days');
    setDays(30);
  };

  const handleSixMonthMeditation = () => {
    setDataType('month');
    setDays(180);
  };

  return (
    <Container>
      <Stats>
        <TextSmallLight style={{ margin: '0rem 1rem' }}>Average Meditation Duration</TextSmallLight>
        <H1>{`${avgMeditationMinutes} Mins ${avgMeditationSeconds} Sec`}</H1>
      </Stats>
      <FlexContainer style={{ justifyContent: 'center' }}>
        <LegendButtons active={days === 7} onClick={() => handleWeekMeditation()}>
          Weekly
        </LegendButtons>
        <LegendButtons active={days === 30} onClick={() => handleMonthMeditation()}>
          Monthly
        </LegendButtons>
        <LegendButtons active={days === 180} onClick={() => handleSixMonthMeditation()}>
          6 months
        </LegendButtons>
      </FlexContainer>
      <div style={{ height: '120px', position: 'relative' }}>
        <LineChart data={chartData} yLabel='Meditation Duration' />
      </div>
    </Container>
  );
};

export default MeditationStats;
