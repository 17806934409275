/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
import { put } from 'redux-saga/effects';
import { Creators } from './action';

import { WebService } from '../../services/axios/webServices';
import { apiEndPoints } from '../../services/axios/endPoints';

export function* addCommentSaga({ payload }) {
  const { content, id, secondaryId } = payload;

  try {
    yield put(Creators.addCommentStart());

    const dataToApi = new FormData();
    dataToApi.append('content', content);
    dataToApi.append('referenceId', id);
    secondaryId && dataToApi.append('secondaryId', secondaryId);

    const fullURL = yield `${apiEndPoints.addComment}`;

    const response = yield WebService({
      method: 'POST',
      url: fullURL,
      data: dataToApi
    });

    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.addCommentSuccess());
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.setError(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.setError(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.setError(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.setError(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.setError(payload));
    }
  }
}
