import styled from 'styled-components';
import { fontSize, borderColor } from '../../../shared/constants';

export const AccordionContent = styled.div`
  font-size: ${fontSize.mediumDefault};
  margin-top: 0.5rem;
  padding: 0px 17px 19px 17px;

  display: flex;
  flex-direction: column;
`;

export const TimeKcalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  margin-bottom: 20px;

  border-top: 1px solid ${borderColor.light};
  border-bottom: 1px solid ${borderColor.light};
`;

export const TimeContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const Time = styled.p`
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
`;

export const KcalContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const Kcal = styled.span`
  display: flex;
  align-items: center;
  gap: 3px;

  & > p {
    display: inline-block;
  }

  & > p:first-child {
    font-size: 14px;
  }

  & > p:last-child {
    font-size: 12px;
    text-transform: uppercase;
    color: #8792ac;
  }
`;

// Meal details table
export const IntakeWrapper = styled.div`
  width: 100%;
  height: auto;
  border: 1px solid ${borderColor.light};
  border-radius: 6px;
  margin-bottom: 20px;
`;

export const MealDetailsHeader = styled.div`
  border-radius: 6px 6px 0 0;

  display: flex;
  width: 100%;
`;

export const IntakeHeader = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;

  width: 50%;
  padding: 11px 16px;

  & > p {
    display: inline-block;
    font-size: 14px;
    color: #f6f6f6;
  }
`;

export const RemarksHeader = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;

  width: 50%;
  padding: 11px 16px;
  border-left: 1px solid ${borderColor.light};

  & > p {
    display: inline-block;
    font-size: 14px;
    color: #f6f6f6;
  }
`;

export const Intake = styled.div`
  display: flex;
  justify-content: space-between;

  border-top: 1px solid ${borderColor.light};

  & > p {
    width: 50%;
    padding: 14px 16px;

    display: inline-block;
    font-size: 14px;
    color: #f6f6f6;
  }

  & > p:last-child {
    border-left: 1px solid ${borderColor.light};
  }
`;

export const CommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 11px;
`;

export const CommentInputContainer = styled.span`
  & > div > div > input {
    font-size: 12px;
  }
`;
