/*
 * action.js file for coach exercise view
 * Author:
 * 1.Manikanta
 *
 * @copyright of Panorbit Services LLP.
 */

import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getCoachExerciseList: ['payload'],
  getCoachExerciseListStart: ['payload'],
  getCoachExerciseListSuccess: ['payload'],
  getCoachExerciseListFailure: ['payload'],

  setCoachExercise: ['payload'],
  setCoachExerciseStart: ['payload'],
  setCoachExerciseSuccess: ['payload'],
  setCoachExerciseFailure: ['payload'],

  deleteCoachExercise: ['payload'],
  deleteCoachExerciseStart: ['payload'],
  deleteCoachExerciseSuccess: ['payload'],
  deleteCoachExerciseFailure: ['payload'],

  getExerciseDetails: ['payload'],
  getExerciseDetailsStart: ['payload'],
  getExerciseDetailsSuccess: ['payload'],
  getExerciseDetailsFailure: ['payload'],

  searchCoachExercise: ['payload'],
  searchCoachExerciseStart: ['payload'],
  searchCoachExerciseSuccess: ['payload'],
  searchCoachExerciseFailure: ['payload'],

  searchExercise: ['payload'],
  searchExerciseSuccess: ['payload'],

  resetExerciseDetails: null,
  setExerciseDetailsId: ['payload'],
  exerciseReset: null,
  resetExerciseList: null,
  setCreateExerciseModal: ['payload'],
  setShowEditExercise: ['payload'],
  resetError: null,
  resetSuccess: null,
  setSearchKey: ['payload'],
  setExercisePage: ['payload']
});

export { Types, Creators };
