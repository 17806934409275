import React from 'react';

import Avatar from '../UserAvatarComponent';

// styles
import { FlexContainer } from '../../../globalShared/CommonStyles';
import { Container, Header, Btn, PlayerTitle, TextLite } from './RecentCard.styles';
import { dayFormater, shortenText } from '../../../services/utility';

const index = (props) => {
  const { userName, profilePicture, date, onClick } = props;

  return (
    <Container>
      <Header>
        <FlexContainer>
          <Avatar url={profilePicture} />
          <div>
            <PlayerTitle>{shortenText(userName, 15, '...')}</PlayerTitle>
            <TextLite>{dayFormater(date)}</TextLite>
          </div>
        </FlexContainer>
        <Btn onClick={() => onClick()}>See Details</Btn>
      </Header>
      <>{props.children}</>
    </Container>
  );
};

export default index;
