import React from 'react';
import { Switch, Route } from 'react-router-dom';

import SkillsView from '../../views/Player/SkillsView/SkillsView';
import CompareView from '../../views/Player/CompareView/CompareView';
import AddVideos from '../../views/Player/AddVideosView/AddVideos';

import VideoplayerView from '../../views/Player/VideoplayerView/VideoplayerView';
import StrengthView from '../../views/Player/StrengthView/StrengthView';
import NutritionView from '../../views/Player/NutritionView/NutritionView';
import MindsetView from '../../views/Player/MindsetView/MindsetView';
import HomeView from '../../views/Player/HomeView/HomeView';
// import { EmptyContentText } from '../../globalShared/CommonStyles';
import HomeAndSupportView from '../../views/HelpAndSupport/HelpAndSupportView';

// page not found
const PageNotFound = ({ text = 'Page Not Found' }) => <h1 style={{ fontSize: '24px' }}>{text}</h1>;

// routes
class PlayerRoutes extends React.Component {
  render() {
    const { path } = this.props;

    return (
      <Switch>
        <Route exact path={`${path}`} component={HomeView} />

        <Route exact path={`${path}/skills`} component={SkillsView} />
        <Route exact path={`${path}/skills/watchVideo/:videoId`} component={VideoplayerView} />
        <Route exact path={`${path}/skills/comparevideo`} component={CompareView} />
        <Route exact path={`${path}/skills/addvideo`} component={AddVideos} />

        {/* <Route exact path={`${path}/watchVideo/:videoId`} component={VideoplayerView} />
        <Route exact path={`${path}/comparevideo/`} component={CompareView} />
        <Route exact path={`${path}/addvideo/`} component={AddVideos} /> */}

        <Route exact path={`${path}/Strength`} component={StrengthView} />
        <Route exact path={`${path}/nutrition`} component={NutritionView} />
        <Route exact path={`${path}/mindset`} component={MindsetView} />

        <Route exact path={`${path}/help-and-support`} component={HomeAndSupportView} />
        <Route path='/*' component={PageNotFound} />
      </Switch>
    );
  }
}

export default PlayerRoutes;
