/*
 * action.js file for nutrion plannerdetails view
 * Author:
 * 1.Manikanta
 *
 * @copyright of Panorbit Services LLP.
 */

import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getMealPlanDetails: ['payload'],
  getMealPlanDetailsStart: ['payload'],
  getMealPlanDetailsSuccess: ['payload'],
  getMealPlanDetailsFailure: ['payload'],

  createPlannerSession: ['payload'],
  createPlannerSessionStart: ['payload'],
  createPlannerSessionSuccess: ['payload'],
  createPlannerSessionFailure: ['payload'],

  deletePlannerSession: ['payload'],
  deletePlannerSessionStart: ['payload'],
  deletePlannerSessionSuccess: ['payload'],
  deletePlannerSessionFailure: ['payload'],

  copyMealPlannerByDay: ['payload'],
  copyMealPlannerByDayStart: ['payload'],
  copyMealPlannerByDaySuccess: ['payload'],
  copyMealPlannerByDayFailure: ['payload'],

  clearPlayerMealPlan: ['payload'],
  clearPlayerMealPlanStart: ['payload'],
  clearPlayerMealPlanSuccess: ['payload'],
  clearPlayerMealPlanFailure: ['payload'],

  copyMealPlannerByWeek: ['payload'],
  copyMealPlannerByWeekStart: ['payload'],
  copyMealPlannerByWeekSuccess: ['payload'],
  copyMealPlannerByWeekFailure: ['payload'],

  addPlayerMealPlan: ['payload'],
  addPlayerMealPlanStart: ['payload'],
  addPlayerMealPlanSuccess: ['payload'],
  addPlayerMealPlanFailure: ['payload'],

  setGroupModalData: ['payload'],
  setShowModal: ['payload'],
  resetSuccess: null,
  resetError: null
});

export { Types, Creators };
