import React from 'react';
import PropTypes from 'prop-types';
import { Label, Container, InputLabel, Input, IpGroup } from './RadioGroup.styles';

const RadioGroupComponent = (props) => {
  const { label, radioGroupArray, radioGroupName, radioRef } = props;
  return (
    <div>
      <Label>{label}</Label>
      <Container>
        {radioGroupArray.map((value) => (
          <IpGroup key={value}>
            <Input type='radio' name={radioGroupName} value={value} id={value} ref={radioRef} />
            <InputLabel htmlfor={value}>{value}</InputLabel>
          </IpGroup>
        ))}
      </Container>
    </div>
  );
};

RadioGroupComponent.defaultProps = {
  label: ''
};

RadioGroupComponent.proptype = {
  label: PropTypes.string,
  radioGroup: PropTypes.instanceOf(Array).isRequired,
  radioRef: PropTypes.func.isRequired,
  radioGroupName: PropTypes.string.isRequired
};

export default RadioGroupComponent;
