import React from 'react';
import ButtonComponent from '../ButtonComponent';

// styles
import { Container, MainContainer, FormFooter, ContentContainer, CloseButton } from './CreateModalStyles';

// icon
import { ReactComponent as CloseIcon } from '../../assets/Close.svg';

/**
 *
 * component
 */
const CreateModal = ({ onSubmit, onCancel, width, button1Props, button2Props, children }) => (
  <Container>
    <CloseButton onClick={onCancel} right={width}>
      <CloseIcon />
    </CloseButton>
    <MainContainer width={width}>
      <ContentContainer>{children}</ContentContainer>
      <FormFooter>
        <ButtonComponent
          buttonText={button1Props.text}
          isButtonAction={true}
          buttonClick={onCancel}
          bgType={button1Props.type}
        />
        <ButtonComponent
          buttonText={button2Props.text}
          isButtonAction={button2Props.isActive}
          buttonClick={onSubmit}
          bgType={button2Props.bgType}
        />
      </FormFooter>
    </MainContainer>
  </Container>
);

CreateModal.defaultProps = {
  button1Props: { text: 'Cancel', type: 'dark', isActive: true },
  button2Props: { text: 'Submit', type: '', isActive: true }
};

export default CreateModal;
