/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
const moment = require('moment');
const { getSpecificDate, getMonth, getYear, getMonthDayYear, getToday, getYearMonthDay } = require('./moment');
const { totalDatesPerMonthView } = require('./dates');

export const getPrevMonthYear = (month, year) => {
  if (month === 1) {
    return {
      month: 12,
      year: year - 1
    };
  }
  return {
    month: month - 1,
    year
  };
};

export const getNextMonthYear = (month, year) => {
  if (month === 12) {
    return {
      month: 1,
      year: year + 1
    };
  }
  return {
    month: month + 1,
    year
  };
};

export const getDatesInMonthDisplay = (month, year) => {
  const daysInMonth = moment(`${month}-${year}`, 'MM-YYYY').daysInMonth();
  const firstWeekday = moment(`${month}-${year}`, 'MM-YYYY').startOf('month').weekday();
  const result = [];

  // Based on index number of firstWeekday, add number of previous month's overflow dates
  const prevMonthYear = getPrevMonthYear(month, year);
  const prevDaysInMonth = moment(`${prevMonthYear.month}-${prevMonthYear.year}`, 'MM-YYYY').daysInMonth();
  for (let i = firstWeekday - 1; i >= 0; i--) {
    result.push({
      currentMonth: false,
      date: getSpecificDate(prevMonthYear.month, prevDaysInMonth - i, prevMonthYear.year)
    });
  }

  // Add all current month dates
  for (let j = 1; j <= daysInMonth; j++) {
    result.push({
      currentMonth: true,
      date: getSpecificDate(month, j, year)
    });
  }

  // Overflow dates for next month to meet totalDatesPerMonthView requirement
  if (result.length < totalDatesPerMonthView) {
    const daysToAdd = totalDatesPerMonthView - result.length;
    const nextMonthYear = getNextMonthYear(month, year);
    for (let k = 1; k <= daysToAdd; k++) {
      result.push({
        currentMonth: false,
        date: getSpecificDate(nextMonthYear.month, k, nextMonthYear.year)
      });
    }
  }

  return result;
};

export const getMonthSet = (selectDate) => {
  const month = getMonth(selectDate) + 1;
  const result = {
    current: selectDate,
    prev: getSpecificDate(month - 1, 1, getYear(selectDate)),
    next: getSpecificDate(month + 1, 1, getYear(selectDate))
  };

  if (month === 1) {
    result.prev = getSpecificDate(12, 1, getYear(selectDate) - 1);
  }

  if (month === 12) {
    result.next = getSpecificDate(1, 1, getYear(selectDate) + 1);
  }

  return result;
};

export const presetDateTracker = (dates) => {
  const result = {};

  if (dates && Array.isArray(dates)) {
    dates.forEach((date) => {
      const dateStr = getMonthDayYear(date);

      if (!result[dateStr]) {
        result[dateStr] = 1;
      } else {
        result[dateStr] += 1;
      }
    });
  }

  return result;
};

export const getPrevDay = (day) => moment(day).subtract(1, 'days').toDate();
export const getNextDay = (day) => moment(day).add(1, 'days').toDate();

export const isToday = (date) => {
  const today = getToday();
  return getYearMonthDay(date) === getYearMonthDay(today);
};

export const getWeekDays = (date) => {
  const days = [];
  // previous 2 days from  today
  for (let i = 2; i >= 0; i--) {
    days.push(moment(date).add(-i, 'days').toDate());
  }

  for (let i = 1; i <= 4; i++) {
    days.push(moment(date).add(i, 'days').toDate());
  }
  return days;
};

export const getYearSet = (selectDate) => {
  const year = getYear(selectDate);
  const month = getMonth(selectDate);

  const result = {
    current: selectDate,
    prev: getSpecificDate(month + 1, 1, year - 1),
    next: getSpecificDate(month + 1, 1, year + 1)
  };
  return result;
};
