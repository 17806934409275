/* eslint-disable arrow-body-style */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unneeded-ternary */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// styles
import {
  Calender,
  Container,
  MindsetNavContainer,
  MindsetContainer,
  MindsetCard,
  CardHeadContainer,
  CardHeading,
  CardSubHeading
} from './MindsetStyles';

// icons
import { ReactComponent as MoodJournalIllus } from '../../../shared/assets/mood_journal_illustration_web.svg';
import { ReactComponent as SleepIllus } from '../../../shared/assets/Sleep_illustration_web.svg';
import { ReactComponent as MeditationIllus } from '../../../shared/assets/meditation_illustration_web.svg';
import { ReactComponent as JournalIcon } from '../../../shared/assets/journal_icon.svg';
import { ReactComponent as SleepIcon } from '../../../shared/assets/sleep_icon.svg';
import { ReactComponent as MeditateIcon } from '../../../shared/assets/meditate_icon.svg';

// components
import HeaderCalander from '../../../shared/components/HeaderCalenderComponent';
import MoodJournalTabs from './MoodJournalTabs';
import SleepTabs from './SleepTabs';
import MeditationTabs from './MeditationTabs';

import { getYearMonthDay } from '../../../services/utility/moment';
import { LoadingComponent } from '../../../globalShared';

const MindsetView = () => {
  const [selectedDate, setSelectedDate] = useState('');
  const loading = useSelector((state) => state.playerMindset.loading);
  const loading1 = useSelector((state) => state.playerMood.loading);

  const [activeCard, setActiveCard] = useState('moodJournal');
  const handleActiveCardChange = (card) => {
    setActiveCard(card);
  };

  const Components = {
    moodJournal: <MoodJournalTabs date={selectedDate} />,
    Sleep: <SleepTabs date={selectedDate} />,
    Meditation: <MeditationTabs date={selectedDate} />
  };

  return (
    <>
      {(loading || loading1) && <LoadingComponent />}
      <Calender>
        <HeaderCalander
          onChange={(value) => {
            setSelectedDate(getYearMonthDay(value));
          }}
          getDates={() => <></>}
        />
      </Calender>
      <Container>
        <MindsetNavContainer>
          <MindsetCard activeCard={activeCard === 'moodJournal'} onClick={() => handleActiveCardChange('moodJournal')}>
            <CardHeadContainer>
              <JournalIcon />
              <CardHeading>Mood Journal</CardHeading>
              <CardSubHeading>What are you Thinking?</CardSubHeading>
            </CardHeadContainer>
            <MoodJournalIllus />
          </MindsetCard>
          <MindsetCard activeCard={activeCard === 'Sleep'} onClick={() => handleActiveCardChange('Sleep')}>
            <CardHeadContainer>
              <SleepIcon />
              <CardHeading>Sleep</CardHeading>
              <CardSubHeading>Did you sleep well?</CardSubHeading>
            </CardHeadContainer>
            <SleepIllus />
          </MindsetCard>
          <MindsetCard activeCard={activeCard === 'Meditation'} onClick={() => handleActiveCardChange('Meditation')}>
            <CardHeadContainer>
              <MeditateIcon />
              <CardHeading>Meditate</CardHeading>
              <CardSubHeading>Align your thoughts</CardSubHeading>
            </CardHeadContainer>
            <MeditationIllus />
          </MindsetCard>
        </MindsetNavContainer>
        <MindsetContainer>{Components[activeCard]}</MindsetContainer>
      </Container>
    </>
  );
};

export default MindsetView;
