import styled from 'styled-components';
import { backgroundColor, fontSize, borderColor, fontWeight } from '../../../shared/constants';

export const ModalContainer = styled.div`
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  justify-content: flex-end;

  background-color: ${backgroundColor.popupBg};

  z-index: 99999;
`;

export const ContentContainer = styled.div`
  height: calc(100% - 91px);
  width: 456px;
  padding: 34px 36px;

  background-color: ${backgroundColor.popup};
  border-radius: 6px 0px 0px 6px;

  overflow-y: auto;
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 478px;
  top: 18px;
  z-index: 99999;
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const FormTitle = styled.h1`
  font-size: ${fontSize.titleVerySmall};
  margin-bottom: 11px;
`;

export const MealTypeContainer = styled.div`
  display: flex;
  margin-bottom: 44px;
`;

export const MealText = styled.p`
  font-size: 14px;
  font-weight: ${fontWeight.bold};
  color: #8792ac;

  text-transform: capitalize;
  padding-right: 11px;
`;

export const MealType = styled.p`
  font-size: 14px;
  color: #f6f6f6;
  text-transform: capitalize;

  padding-left: 11px;
  border-left: 1px solid ${borderColor.light};
`;

export const Column = styled.div`
  width: ${(props) => props.wdth};

  & > span {
    padding: 0px;
  }
`;

export const ScrollContainer = styled.div`
  height: calc(100vh - 350px);
  width: 100%;
  overflow-y: auto;
  padding: 0.5rem;
`;

export const FormFooter = styled.div`
  display: flex;
  flex-direction: column;
  width: 456px;

  position: absolute;
  right: 0px;
  bottom: 0;

  display: flex;
  align-items: flex-end;
  background-color: ${backgroundColor.popup};
`;

export const ButtonContainer = styled.div`
  padding: 1rem 1rem 2rem 0;
`;

export const DelContainer = styled.div`
  width: 40px;
  margin-left: auto;
  position: relative;
  top: 20px;
  right: 4px;
`;
