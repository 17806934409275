import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getPlayerReadiness: ['payload'],
  getPlayerReadinessStart: ['payload'],
  getPlayerReadinessSuccess: ['payload'],
  getPlayerReadinessFailure: ['payload'],

  getPlayerHomeStrength: ['payload'],
  getPlayerHomeStrengthStart: ['payload'],
  getPlayerHomeStrengthSuccess: ['payload'],
  getPlayerHomeStrengthFailure: ['payload'],

  getPlayerHomeNutrition: ['payload'],
  getPlayerHomeNutritionStart: ['payload'],
  getPlayerHomeNutritionSuccess: ['payload'],
  getPlayerHomeNutritionFailure: ['payload'],

  getPlayerHomeHydration: ['payload'],
  getPlayerHomeHydrationStart: ['payload'],
  getPlayerHomeHydrationSuccess: ['payload'],
  getPlayerHomeHydrationFailure: ['payload'],

  getPlayerHomeMoodStats: ['payload'],
  getPlayerHomeMoodStatsStart: ['payload'],
  getPlayerHomeMoodStatsSuccess: ['payload'],
  getPlayerHomeMoodStatsFailure: ['payload']
});

export { Types, Creators };
