import React from 'react';
import { Input, Container, Search } from './SearchBox.styles';
import { ReactComponent as SearchIcon } from '../../assets/Search.svg';

const SearchBox = (props) => {
  const { onSearchChange, placeHolder } = props;
  return (
    <Container>
      <Search>
        <SearchIcon />
      </Search>

      <Input type='search' placeholder={placeHolder} onChange={(e) => onSearchChange(e.target.value)} />
    </Container>
  );
};

export default SearchBox;
