/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import styled, { css } from 'styled-components';

import { fontSize, backgroundColor, textColors, borderColor } from '../../constants';

export const MainContainer = styled.div`
  margin-bottom: 2rem;
  position: relative;
`;

export const Container = styled.div`
  width: 100%;
  height: 45px;

  font-size: ${fontSize.mediumDefault};

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: ${backgroundColor.darkOpaq};
  border-radius: ${(props) => (props.isVisible ? '0.6rem 0.6rem 0 0 ' : '0.6rem')};
  padding: 1rem;

  position: relative;

  text-transform: capitalize;

  cursor: pointer;

  ${(props) =>
    props.isVisible &&
    css`
      border: 1px solid ${borderColor.medium};
      border-bottom: none;
    `}

  & > svg {
    transform: ${(props) => (props.isVisible ? 'rotateX(180deg)' : 'rotateX(0deg)')};
  }
`;

export const Label = styled.p`
  color: ${textColors.textLight};
  font-size: ${fontSize.mediumDefault};
  margin-bottom: 1rem;
  height: 16px;
`;

export const DropdownContainer = styled.div`
  width: 100%;
  height: 140px;

  background-color: #2f2645;
  border-radius: 0 0 0.6rem 0.6rem;

  border: 1px solid ${borderColor.medium};
  border-top: none;
  padding: 0 1rem 1rem 1rem;
  position: absolute;
  z-index: 999;
  overflow-y: auto;
`;

export const Item = styled.div`
  font-size: ${fontSize.mediumDefault};
  color: ${textColors.textWhite};
  padding: 0.8rem 0;
  cursor: pointer;
  text-transform: capitalize;
`;
