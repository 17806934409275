/* eslint-disable no-inner-declarations */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
import { put, call, all } from 'redux-saga/effects';
import { Creators } from './action';

import { WebService } from '../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../services/axios/endPoints';

export function* updatePlayerPersonalDetailsSaga({ payload }) {
  try {
    const {
      profilePictureObj,
      phoneNumber,
      firstName,
      age,
      lastName,
      gender,
      weight,
      level,
      about,
      goals,
      heightFeet,
      heightInch,
      picDelete,
      trainingAge
    } = payload;

    yield put(Creators.updatePlayerPersonalDetailsStart());
    const fullURL = yield `${apiEndPoints.personalDetails}`;

    // data to be updated
    const dataToApi = new FormData();
    dataToApi.append('firstName', firstName);
    dataToApi.append('lastName', lastName);
    dataToApi.append('age', age);
    dataToApi.append('gender', gender.value);
    dataToApi.append('weight', weight);
    dataToApi.append('height', `${heightFeet}.${heightInch}`);
    dataToApi.append('level', level.value);
    dataToApi.append('goals', goals);
    dataToApi.append('about', about);
    dataToApi.append('phoneNumber', phoneNumber);
    dataToApi.append('trainingAge', trainingAge.value);
    profilePictureObj ? dataToApi.append('profilePicture', profilePictureObj) : '';
    picDelete && dataToApi.append('picDelete', picDelete);

    const response = yield WebService({
      method: 'put',
      url: fullURL,
      data: dataToApi
    });

    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.updatePlayerPersonalDetailsSuccess());
    } else {
      throw response;
    }
  } catch (error) {
    console.log(error);

    let payload;
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.updatePlayerPersonalDetailsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.updatePlayerPersonalDetailsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.updatePlayerPersonalDetailsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.updatePlayerPersonalDetailsFailure(payload));
      }
    } else {
      payload = {
        error: error.message
      };

      yield put(Creators.updatePlayerPersonalDetailsFailure(payload));
    }
  }
}

export function* updatePlayerProfessionalDetails({ payload }) {
  try {
    const { injuryFiles, healthFiles, newHealth, newInjury } = payload;
    yield put(Creators.updatePlayerPersonalDetailsStart());

    const files = [];
    const newFiles = [];
    function* fileFn(item, mediaType) {
      if (item.file) {
        const fileFormData = new FormData();
        fileFormData.append('mediaFile', item.file);
        const response = yield WebService.post(apiEndPoints.fileUploadApi, fileFormData);
        const { url, fileName } = response.data?.data;

        const obj = {
          mediaUrl: url,
          mediaType,
          description: item.description,
          fileName
        };
        if (item._id) {
          obj._id = item._id;
          files.push(obj);
        } else {
          newFiles.push(obj);
        }
      } else {
        const obj = {
          mediaType,
          mediaUrl: item.mediaUrl || null,
          fileName: item.fileName || 'Upload File',
          description: item.description
        };
        if (item._id) {
          obj._id = item._id;
          files.push(obj);
        } else {
          newFiles.push(obj);
        }
      }
    }

    if (healthFiles) {
      yield all(healthFiles.map((item) => call(fileFn, item, 'Health')));
    }

    if (injuryFiles) {
      yield all(injuryFiles.map((item) => call(fileFn, item, 'Injury')));
    }

    if (newHealth) {
      yield all(newHealth.map((item) => call(fileFn, item, 'Health')));
    }

    if (newInjury) {
      yield all(newInjury.map((item) => call(fileFn, item, 'Injury')));
    }
    // const fullURL = yield `${apiEndPoints.personalDetails}`;

    // data to be updated
    const dataToApi = new FormData();
    dataToApi.append('mealPreference', payload.mealPreference);
    dataToApi.append('foodAllergy', payload.foodAllergy || 'None');
    dataToApi.append('practiceStartTime', `${payload.practiceStartTime}-${payload.practiceStartTimeMeredian}`);
    dataToApi.append('practiceEndTime', `${payload.practiceEndTime}-${payload.practiceEndTimeMeredian}`);
    dataToApi.append('trainingStartTime', `${payload.trainingStartTime}-${payload.trainingStartTimeMeredian}`);
    dataToApi.append('trainingEndTime', `${payload.trainingEndTime}-${payload.trainingEndTimeMeredian}`);

    const [injuryRes, healthRes, postHealth, postInjury, formRes] = yield all([
      !!injuryFiles &&
        WebService.put(apiEndPoints.uplaodFiles, {
          files: files.filter((item) => item.mediaType === 'Injury')
        }),
      !!healthFiles &&
        WebService.put(apiEndPoints.uplaodFiles, {
          files: files.filter((item) => item.mediaType === 'Health')
        }),
      !!newHealth &&
        WebService.post(apiEndPoints.uplaodFiles, {
          files: newFiles.filter((item) => item.mediaType === 'Health')
        }),
      !!newInjury &&
        WebService.post(apiEndPoints.uplaodFiles, {
          files: newFiles.filter((item) => item.mediaType === 'Injury')
        }),
      WebService.put(apiEndPoints.personalDetails, dataToApi)
    ]);

    if (formRes.status >= 200 && formRes.status < 300) {
      if ((injuryRes.status >= 200 && injuryRes.status < 300) || !injuryRes) {
        if ((healthRes.status >= 200 && healthRes.status < 300) || !healthRes) {
          if ((postHealth.status >= 200 && postHealth.status < 300) || !postHealth) {
            if ((postInjury.status >= 200 && postInjury.status < 300) || !postInjury) {
              yield put(Creators.updatePlayerProfessionalDetailsSuccess());
            } else {
              throw postInjury;
            }
          } else {
            throw postHealth;
          }
        } else {
          throw healthRes;
        }
      } else {
        throw injuryRes;
      }
    } else {
      throw formRes;
    }
  } catch (error) {
    console.log(error);

    let payload;
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.updatePlayerPersonalDetailsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.updatePlayerPersonalDetailsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.updatePlayerPersonalDetailsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.updatePlayerPersonalDetailsFailure(payload));
      }
    } else {
      payload = {
        error: error.message
      };

      yield put(Creators.updatePlayerPersonalDetailsFailure(payload));
    }
  }
}

export function* updateFileSaga({ payload }) {
  try {
    const { fileData } = payload;

    yield put(Creators.updateFileStart());
    const fullURL = yield `${apiEndPoints.fileUpdate}`;

    const response = yield WebService({
      method: 'PUT',
      url: fullURL,
      data: JSON.stringify(fileData)
    });

    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.updateFileSuccess());
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.updateFileFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.updateFileFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.updateFileFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.updateFileFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.updateFileFailure(payload));
    }
  }
}

export function* deleteFileSaga({ payload }) {
  try {
    const { _id } = payload;

    yield put(Creators.deleteFileStart());
    const fullURL = yield `${apiEndPoints.fileUpload}`;

    const response = yield WebService({
      method: 'DELETE',
      url: fullURL,
      data: { _id }
    });

    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.deleteFileSuccess());
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.updatePlayerPersonalDetailsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.updatePlayerPersonalDetailsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.updatePlayerPersonalDetailsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.updatePlayerPersonalDetailsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.updatePlayerPersonalDetailsFailure(payload));
    }
  }
}
