/* eslint-disable no-console */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */

/*
 * saga.js file for login view
 * Author:
 * 1. manikanta
 *
 * @copyright of Panorbit Services LLP.
 */

import { put } from 'redux-saga/effects';
import { Creators } from './action';

import { WebService } from '../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../services/axios/endPoints';

export function* verifyEmailSaga({ payload }) {
  try {
    const { email } = payload;

    yield put(Creators.verifyEmailStart());

    const fullURL = yield `${apiEndPoints.forgetPassword}`;

    const response = yield WebService({
      method: 'POST',
      url: fullURL,
      data: { email }
    });
    if (response.status >= 200 && response.status < 300) {
      const { message } = response.data;
      yield put(Creators.verifyEmailSuccess(message));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.verifyEmailFailure(payload));
          return;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.verifyEmailFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.verifyEmailFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.verifyEmailFailure(payload));
      }
    }

    // for custom error
    payload = {
      error: error.message
    };

    yield put(Creators.verifyEmailFailure(payload));
  }
}
