/* eslint-disable no-unused-vars */
/*
 * reducer.js file for coach exercise view
 * Author:
 * 1.Manikanta
 * @copyright of Panorbit Services LLP.
 */

import { createReducer } from 'reduxsauce';
import { Types } from './action';
import { mergeArray } from '../../../../services/utility';

const INITIAL_STATE = {
  loading: false,
  error: null,
  success: false,
  exerciseList: [],
  exerciseId: '',
  exerciseDetails: '',
  reloadList: false,
  page: 1,
  maxPage: 1,
  showCreateExercise: false,
  totalItems: null,
  searchKey: ''
};

const getCoachExerciseListStart = (state = INITIAL_STATE, action) => ({ ...state, error: null, loading: true });

const getCoachExerciseListSuccess = (state = INITIAL_STATE, action) => {
  const { exerciseList, maxPage, totalItems } = action.payload;

  return {
    ...state,
    loading: false,
    reloadList: false,
    success: false,
    exerciseList,
    maxPage: maxPage.page_count,
    totalItems: totalItems.totalCount
  };
};

const getCoachExerciseListFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const setExerciseDetailsId = (state = INITIAL_STATE, action) => {
  const { id } = action.payload;

  return {
    ...state,
    exerciseId: id
  };
};

const getExerciseDetailsStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getExerciseDetailsSuccess = (state = INITIAL_STATE, action) => {
  const { exerciseDetails } = action.payload;
  return {
    ...state,
    exerciseDetails,
    loading: false
  };
};

const getExerciseDetailsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const searchCoachExerciseFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const searchCoachExerciseSuccess = (state = INITIAL_STATE, action) => {
  const { exerciseList, totalItems } = action.payload;
  return {
    ...state,
    loading: false,
    reloadList: false,
    exerciseList,
    totalItems
  };
};

const searchExerciseSuccess = (state = INITIAL_STATE, action) => {
  const { exerciseList } = action.payload;
  return {
    ...state,
    loading: false,
    exerciseList
  };
};

const setCoachExerciseStart = (state = INITIAL_STATE, action) => ({ ...state, error: null, loading: true });
const setCoachExerciseSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  error: null,
  loading: false,
  success: true,
  reloadList: true
});
const setCoachExerciseFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const deleteCoachExerciseStart = (state = INITIAL_STATE, action) => ({ ...state, error: null, loading: true });
const deleteCoachExerciseSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  error: null,
  loading: false,
  success: true,
  reloadList: true,
  page: 1
});
const deleteCoachExerciseFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const resetExerciseList = (state = INITIAL_STATE) => ({ ...state, exerciseList: [] });

const exerciseReset = () => ({ ...INITIAL_STATE });

const setCreateExerciseModal = (state = INITIAL_STATE, action) => {
  const { showCreateExercise } = action.payload;
  return {
    ...state,
    showCreateExercise
  };
};

const resetError = (state = INITIAL_STATE) => ({ ...state, error: null });
const resetSuccess = (state = INITIAL_STATE) => ({ ...state, success: false });
const resetExerciseDetails = (state = INITIAL_STATE) => ({ ...state, exerciseDetails: '' });

const setSearchKey = (state = INITIAL_STATE, action) => {
  const { searchKey } = action.payload;
  return {
    ...state,
    searchKey,
    page: 1
  };
};

const setExercisePage = (state = INITIAL_STATE, action) => {
  const { page } = action.payload;
  return {
    ...state,
    page
  };
};

const HANDLERS = {
  [Types.GET_COACH_EXERCISE_LIST_START]: getCoachExerciseListStart,
  [Types.GET_COACH_EXERCISE_LIST_SUCCESS]: getCoachExerciseListSuccess,
  [Types.GET_COACH_EXERCISE_LIST_FAILURE]: getCoachExerciseListFailure,

  [Types.GET_EXERCISE_DETAILS_START]: getExerciseDetailsStart,
  [Types.GET_EXERCISE_DETAILS_SUCCESS]: getExerciseDetailsSuccess,
  [Types.GET_EXERCISE_DETAILS_FAILURE]: getExerciseDetailsFailure,

  [Types.SEARCH_COACH_EXERCISE_FAILURE]: searchCoachExerciseFailure,
  [Types.SEARCH_COACH_EXERCISE_SUCCESS]: searchCoachExerciseSuccess,
  [Types.SEARCH_EXERCISE_SUCCESS]: searchExerciseSuccess,

  [Types.SET_COACH_EXERCISE_START]: setCoachExerciseStart,
  [Types.SET_COACH_EXERCISE_SUCCESS]: setCoachExerciseSuccess,
  [Types.SET_COACH_EXERCISE_FAILURE]: setCoachExerciseFailure,

  [Types.DELETE_COACH_EXERCISE_START]: deleteCoachExerciseStart,
  [Types.DELETE_COACH_EXERCISE_SUCCESS]: deleteCoachExerciseSuccess,
  [Types.DELETE_COACH_EXERCISE_FAILURE]: deleteCoachExerciseFailure,

  [Types.SET_EXERCISE_DETAILS_ID]: setExerciseDetailsId,
  [Types.EXERCISE_RESET]: exerciseReset,
  [Types.RESET_EXERCISE_LIST]: resetExerciseList,
  [Types.SET_CREATE_EXERCISE_MODAL]: setCreateExerciseModal,
  [Types.RESET_ERROR]: resetError,
  [Types.RESET_SUCCESS]: resetSuccess,
  [Types.RESET_EXERCISE_DETAILS]: resetExerciseDetails,
  [Types.SET_SEARCH_KEY]: setSearchKey,
  [Types.SET_EXERCISE_PAGE]: setExercisePage
};

export const coachExerciseReducer = createReducer(INITIAL_STATE, HANDLERS);
