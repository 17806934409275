/* eslint-disable no-console */
/* eslint-disable no-shadow */
/*
 * saga.js file for coach workouts view
 * Author:
 * 1. manikanta
 * @copyright of Panorbit Services LLP.
 */

import { put } from 'redux-saga/effects';
import { Creators } from './action';

import { WebService } from '../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../services/axios/endPoints';

export function* searchCoachPlannerSaga({ payload }) {
  const { searchKey, count, page } = payload;

  try {
    yield put(Creators.searchCoachPlannerStart());
    const fullURL = yield `${apiEndPoints.plannerSearch}`;

    const response = yield WebService.get(fullURL, { params: { page, count, searchKey } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(Creators.searchCoachPlannerSuccess({ plannerList: data[0], totalItems: data[2].totalCount }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.searchCoachPlannerFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.searchCoachPlannerFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.searchCoachPlannerFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.searchCoachPlannerFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.searchCoachPlannerFailure(payload));
    }
  }
}

export function* getCoachPlannerListSaga({ payload }) {
  const { page, count } = payload;

  try {
    yield put(Creators.getCoachPlannerListStart());
    const fullURL = yield `${apiEndPoints.createPlanner}`;

    const response = yield WebService.get(fullURL, { params: { page, count } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      yield put(Creators.getCoachPlannerListSuccess({ plannerList: data[0], totalItems: data[2].totalCount }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getCoachPlannerListFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getCoachPlannerListFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getCoachPlannerListFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getCoachPlannerListFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getCoachPlannerListFailure(payload));
    }
  }
}

export function* createPlannerSaga({ payload }) {
  try {
    yield put(Creators.createPlannerStart());
    const fullURL = yield `${apiEndPoints.createPlanner}`;
    let METHOD = 'POST';
    //  eslint-disable-next-line no-undef
    const dataToApi = new FormData();
    dataToApi.append('title', payload.title);
    dataToApi.append('groupId', payload.groupId);
    dataToApi.append('startDate', payload.startDate);
    dataToApi.append('endDate', payload.endDate);
    if (payload._id) {
      dataToApi.append('_id', payload._id);
      METHOD = 'PUT';
    }

    const response = yield WebService({
      method: METHOD,
      url: fullURL,
      data: dataToApi
    });

    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.createPlannerSuccess());
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.createPlannerFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.createPlannerFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.createPlannerFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.createPlannerFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.createPlannerFailure(payload));
    }
  }
}

export function* deleteCoachPlannerSaga({ payload }) {
  const { id } = payload;

  try {
    yield put(Creators.deleteCoachPlannerStart());
    const fullURL = yield `${apiEndPoints.createPlanner}`;

    const response = yield WebService({
      method: 'DELETE',
      url: fullURL,
      data: { _id: id }
    });

    if (response.status >= 200 && response.status < 300) {
      const { message } = response.data;
      yield put(Creators.deleteCoachPlannerSuccess({ message }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.deleteCoachPlannerFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.deleteCoachPlannerFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.deleteCoachPlannerFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.deleteCoachPlannerFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.deleteCoachPlannerFailure(payload));
    }
  }
}
