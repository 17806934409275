import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Creators } from './store';

// assets
import { ReactComponent as CloseIcon } from '../../../shared/assets/Close.svg';

// styles
import {
  ModalContainer,
  Card,
  Title,
  CloseButton,
  Header,
  InputContainer,
  FormFooter
} from './EditCollectionModalStyles';

// components
import { FormInput } from '../../../shared/components/FormComponents';
import { ButtonComponent } from '../../../globalShared';

const EditCollectionModal = (props) => {
  const { currentCollectionName } = props;
  const dispatch = useDispatch();

  const currentCollectionId = useSelector((state) => state.playerSkills.currentCollectionId);

  const [collectionName, setCollectionName] = useState({
    name: currentCollectionName
  });
  const [isNameFilled, setIsNameFilled] = useState(false);

  function checkCollectionNameFilled(props) {
    const { name } = props;
    const isName = !!`${name}`.trim().length;

    return isName;
  }

  useEffect(() => {
    const isTitle = checkCollectionNameFilled({ ...collectionName });
    setIsNameFilled(isTitle);
  }, [collectionName]);

  const handleClose = () => {
    dispatch(Creators.setShowEditCollectionModal({ showEditCollection: false }));
  };

  const handleCancel = () => {
    setCollectionName(collectionName);
    dispatch(Creators.setShowEditCollectionModal({ showEditCollection: false }));
  };

  const handleSave = () => {
    const collection = { ...collectionName, _id: currentCollectionId };
    dispatch(Creators.setPlayerCollectionName({ data: collection }));
    handleCancel();
  };

  return (
    <ModalContainer>
      <Card>
        <form>
          <Header>
            <div>
              <Title>Edit</Title>
            </div>

            <div>
              <CloseButton onClick={handleClose}>
                <CloseIcon />
              </CloseButton>
            </div>
          </Header>

          <InputContainer>
            <FormInput
              label='Collection Title'
              value={collectionName.name}
              setValue={(value) => setCollectionName({ name: value })}
            />
          </InputContainer>
        </form>

        <FormFooter>
          <div>
            <ButtonComponent buttonText='Cancel' isButtonAction={true} buttonClick={handleCancel} bgType='dark' />
            <ButtonComponent buttonText='Save' isButtonAction={isNameFilled} buttonClick={handleSave} />
          </div>
        </FormFooter>
      </Card>
    </ModalContainer>
  );
};

export default EditCollectionModal;
