export const moodStrings = {
  level1: 'Anxious',
  level2: 'Fatigued',
  level3: 'Confused',
  level4: 'Calm',
  level5: 'Satisfied',
  level6: 'Happy'
};

export const levelMap = {
  level1: 'Under 14',
  level2: 'Under 17',
  level3: 'Senior'
};

export const trainingAgeMap = {
  level1: 'Lesser Than 1 Year',
  level2: 'Between 1 And 3 Years',
  level3: 'Greater Than 3 Years'
};

export const meditationMap = {
  basicMeditation: 'Beginner',
  expertMeditation: 'Advanced',
  guidedMeditation: 'Intermediate'
};

export const FeelingsMap = {
  0: 'Anxious',
  1: 'Fatigued',
  2: 'Confused',
  3: 'Calm',
  4: 'Satisfied',
  5: 'Happy'
};

export const MoodLevels = { 0: 'level1', 1: 'level2', 2: 'level3', 3: 'level4', 4: 'level5', 5: 'level6' };

export const genderMap = { M: 'Male', F: 'Female' };

export const labelsMap = {
  Energy: 'Energy',
  Sleep: 'Sleep',
  Mood: 'Mood',
  Stillness: 'Stillness',
  Hydration: 'Hydration',
  Load: 'Load',
  Adherence: 'Adherence',
  Completion: 'Completion'
  // Energy: 'Energy (Kcal)',
  // Sleep: 'Sleep (Hrs)',
  // Mood: 'Mood',
  // Stillness: 'Stillness (Min)',
  // Hydration: 'Hydration (L)',
  // Load: 'Load (Kgs)',
  // Adherence: 'Adherence',
  // Completion: 'Completion'
};
