import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from './store';

// styles
import {
  Container,
  HelpSearchWrapper,
  Card,
  HelpText,
  ReadMoreLink,
  UserHelpCard,
  UserTypeHeader,
  HelpLink
} from './HelpAndSupportStyles';

import { HelpSearchComponent, MasonryLayout, PaginationComponent } from '../../globalShared';

const HelpAndSupportView = () => {
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState('');

  // dispatch
  const dispatch = useDispatch();

  const helpAndSupportList = useSelector((state) => state.helpAndSupport.helpAndSupportList);
  const helpCount = useSelector((state) => state.helpAndSupport.helpCount);
  const adminHelpAndSupportList = useSelector((state) => state.helpAndSupport.adminHelpAndSupportList);
  const coachHelpAndSupportList = useSelector((state) => state.helpAndSupport.coachHelpAndSupportList);
  const playerHelpAndSupportList = useSelector((state) => state.helpAndSupport.playerHelpAndSupportList);
  // console.log(helpAndSupportList);

  const handleHelpSearch = (key) => {
    setSearchKey(key);
  };

  useEffect(() => {
    if (searchKey) {
      dispatch(Creators.searchHelpAndSupport({ page, count, searchKey }));
    }
  }, [page, count, searchKey]);

  useEffect(() => {
    dispatch(Creators.getHelpAndSupportList({ page, count }));
  }, [page, count]);

  useEffect(() => {
    dispatch(Creators.getAdminHelpAndSupportList({ associatedTo: 'Admins', page, count }));
  }, []);

  useEffect(() => {
    dispatch(Creators.getCoachHelpAndSupportList({ associatedTo: 'Coaches', page, count }));
  }, []);

  useEffect(() => {
    dispatch(Creators.getPlayerHelpAndSupportList({ associatedTo: 'Players', page, count }));
  }, []);

  return (
    <Container>
      <HelpSearchWrapper>
        <HelpSearchComponent placeHolder='Search help' onSearchChange={handleHelpSearch} />
      </HelpSearchWrapper>
      <MasonryLayout gap={24} columns={3}>
        {helpAndSupportList &&
          helpAndSupportList.map((helpItem) => (
            <Card key={helpItem._id}>
              <HelpText>{helpItem.content}</HelpText>
              <ReadMoreLink to='#'>Read more</ReadMoreLink>
            </Card>
          ))}
      </MasonryLayout>
      <div style={{ width: '100%' }}>
        <PaginationComponent
          totalItems={helpCount}
          pageNo={page}
          showCount={false}
          onChange={(value) => {
            setPage(value.currentPage);
            setCount(value.count);
          }}
        />
      </div>
      <MasonryLayout gap={24} columns={3}>
        <UserHelpCard>
          <UserTypeHeader>For Admins</UserTypeHeader>
          {adminHelpAndSupportList &&
            adminHelpAndSupportList.map((adminHelp) => (
              <Fragment key={adminHelp._id}>
                <HelpLink to='#'>{adminHelp.title}</HelpLink>
              </Fragment>
            ))}
        </UserHelpCard>
        <UserHelpCard>
          <UserTypeHeader>For Coaches</UserTypeHeader>
          {coachHelpAndSupportList &&
            coachHelpAndSupportList.map((coachHelp) => (
              <Fragment key={coachHelp._id}>
                <HelpLink to='#'>{coachHelp.title}</HelpLink>
              </Fragment>
            ))}
        </UserHelpCard>
        <UserHelpCard>
          <UserTypeHeader>For Players</UserTypeHeader>
          {playerHelpAndSupportList &&
            playerHelpAndSupportList.map((playerHelp) => (
              <Fragment key={playerHelp._id}>
                <HelpLink to='#'>{playerHelp.title}</HelpLink>
              </Fragment>
            ))}
        </UserHelpCard>
      </MasonryLayout>
    </Container>
  );
};

export default HelpAndSupportView;
