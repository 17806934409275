/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getMetadata, getThumbnails } from 'video-metadata-thumbnails';
import Toast, { Toasts, UplaodToast } from '../../../services/toast';
import { Creators } from './store';
import { Creators as VideoPlayerCreators } from '../VideoplayerView/store';

// components
import {
  AvatarComponent,
  CompareButtonComponent,
  DeletePopupComponent,
  IconButtonComponenet,
  LoadingComponent,
  PaginationComponent,
  SearchBoxComponent,
  TooltipComponnet
} from '../../../globalShared';

// styles
import { Divider, EmptyContentText, TableStyles } from '../../../globalShared/CommonStyles';

import {
  Container,
  Linkto,
  FlexContainer,
  Row,
  VideoTitle,
  VideoType,
  Bagde,
  UserActionsContainer,
  SkillHeader,
  ManageContainer,
  ManageIconWrapper,
  SearchContainer,
  FilterContainer,
  FilterToggle,
  FilterCardContainer,
  SelectCheckboxContainer,
  CheckboxInputContainer,
  UploadVideoWrapper,
  ScrollContainer
} from './SkillsView.styles';

// icons

import { ReactComponent as UploadIcon } from '../../../shared/assets/upload_video.svg';

import { ReactComponent as FilterIcon } from '../../../shared/assets/Filter.svg';
import { ReactComponent as UncheckedIcon } from '../../../shared/assets/Check_box.svg';
import { ReactComponent as CheckedIcon } from '../../../shared/assets/Check_box-selected.svg';
import fallbackThumbNail from '../../../shared/assets/Cricket-bat-ball.jpg';

import ManageCollectionModal from './ManageCollectionModal';
import CreateCollectionModal from './CreateCollectionModal';
import EditVideoModal from '../VideoplayerView/EditVideoModal';
import UploadVideoModal from './UploadVideoModal';

import { shortenText, Timeformater } from '../../../services/utility';
import useTouchOutside from '../../../shared/hooks/useTouchOutside';

const { Table, Th, Tr, Td } = TableStyles;

/**
 *
 * componnet
 */
const SkillsView = () => {
  const history = useHistory();

  // state values
  const loading = useSelector((state) => state.playerSkills.loading);
  const videosList = useSelector((state) => state.playerSkills.videosList);
  const collectionsList = useSelector((state) => state.playerSkills.collectionsList);
  const showManageCollection = useSelector((state) => state.playerSkills.showManageCollection);
  const showCreateCollection = useSelector((state) => state.playerSkills.showCreateCollection);
  const videosCount = useSelector((state) => state.playerSkills.videosCount);
  const compareVideos = useSelector((state) => state.compare.videos);

  const videosReload = useSelector((state) => state.playerVideoPlayer.videosReload);
  const reloadVideoDetails = useSelector((state) => state.playerVideoPlayer.reloadVideoDetails);

  const success = useSelector((state) => state.playerSkills.success);
  const errorMsg = useSelector((state) => state.playerSkills.error);
  const reload = useSelector((state) => state.playerSkills.reload);
  const uploading = useSelector((state) => state.playerSkills.uploading);

  const deleteSuccess = useSelector((state) => state.playerVideoPlayer.success);
  const deleteError = useSelector((state) => state.playerVideoPlayer.error);

  const [count, setCount] = useState(10);
  const [page, setPage] = useState(1);

  const [toggleFilterCard, setToggleFilterCard] = useState(false);
  const [collectionFilter, setFilter] = useState([]);
  const [searchKey, setsearchKey] = useState('');

  const [deleteItem, setDeleteItem] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const [currentVideoId, setCurrentVideoId] = useState('');
  const [showEditVideoModal, setShowEditVideoModal] = useState(false);
  const [showUploadVideoModal, setShowUploadVideoModal] = useState(false);

  const [uploadVideo, setUploadVideo] = useState({ file: '', fileName: '' });
  const [videoMeta, setVideoMeta] = useState({ url: '', duration: '' });
  const [loadingFile, setLoadingFile] = useState(false);

  const dropdownRef = useRef(null);
  const inputRef = useRef();

  // dispatch
  const dispatch = useDispatch();

  const fetchSearch = () => {
    const IDArry = [];
    if (collectionFilter.length === collectionsList.length || collectionFilter.length === 0) {
      dispatch(Creators.searchPlayerVideo({ _id: 'All', page, count, searchKey }));
    } else {
      collectionFilter.forEach((collection) => IDArry.push(collection._id));
      dispatch(Creators.searchPlayerVideo({ _id: IDArry.join('_'), page, count, searchKey }));
    }
  };

  const deleteCurrentVideo = (id) => {
    setDeleteItem(true);
    setDeleteItemId(id);
  };

  const popUpCancelFunc = () => {
    setDeleteItemId(null);
    setDeleteItem(false);
  };

  const popUpDeteleFunc = (_id) => {
    dispatch(VideoPlayerCreators.deletePlayerVideo({ _id }));
    popUpCancelFunc();
  };

  const handleVideoSearch = (key) => {
    setsearchKey(key);
    setPage(1);
  };

  // closes filter on clicking outside

  useTouchOutside(dropdownRef, () => setToggleFilterCard(false));

  // effect hooks to toast notifications
  useEffect(() => {
    if (success) {
      Toast.success('success', 2, () => dispatch(Creators.resetSuccess()));
    }
  }, [success]);

  useEffect(() => {
    if (deleteSuccess) {
      Toast.success('success', 2, () => dispatch(VideoPlayerCreators.resetSuccess()));
    }
  }, [deleteSuccess]);

  useEffect(() => {
    if (errorMsg) {
      Toast.error(errorMsg, 2, () => dispatch(Creators.resetError()));
    }
  }, [errorMsg]);

  useEffect(() => {
    if (deleteError) {
      Toast.error(errorMsg, 2, () => dispatch(VideoPlayerCreators.resetError()));
    }
  }, [deleteError]);

  useEffect(() => {
    const IDArry = [];

    if (collectionFilter.length === collectionsList.length || collectionFilter.length === 0) {
      searchKey
        ? dispatch(Creators.searchPlayerVideo({ _id: 'All', page, count, searchKey }))
        : dispatch(Creators.getPlayerVideosList({ _id: 'All', page, count }));
    } else {
      collectionFilter.forEach((collection) => IDArry.push(collection._id));

      searchKey
        ? dispatch(Creators.searchPlayerVideo({ _id: IDArry.join('_'), page, count, searchKey }))
        : dispatch(Creators.getPlayerVideosList({ _id: IDArry.join('_'), page, count }));
    }
  }, [collectionFilter, page, count, searchKey]);

  useEffect(() => {
    dispatch(Creators.getPlayerCollectionsList());
  }, []);

  useEffect(() => {
    if (videosReload || reloadVideoDetails || reload) {
      searchKey ? fetchSearch() : dispatch(Creators.getPlayerVideosList({ _id: 'All', page, count }));
      dispatch(Creators.getPlayerCollectionsList());
    }
  }, [videosReload, reloadVideoDetails, reload]);

  const FilterCard = () => {
    const isSelecetd = (id) => collectionFilter.some((collection) => collection._id === id);

    const handleRemove = (id) => {
      const cltn = collectionFilter.filter((item) => item._id !== id);
      setFilter(cltn);
    };

    const handleAdd = (item) => {
      if (collectionFilter.some((k) => k._id === item._id)) {
        handleRemove(item._id);
      } else {
        const cltn = [...collectionFilter, item];
        setFilter(cltn);
      }

      setPage(1);
    };

    const handleSelectAll = () => {
      if (collectionsList.length === collectionFilter.length) {
        setFilter([]);
      } else {
        setFilter(collectionsList);
      }
    };

    return (
      <FilterCardContainer>
        {collectionsList.length ? (
          <>
            <CheckboxInputContainer onClick={handleSelectAll}>
              <SelectCheckboxContainer>
                {collectionsList.length === collectionFilter.length ? <CheckedIcon /> : <UncheckedIcon />}
              </SelectCheckboxContainer>
              <p style={{ fontSize: '16px' }}>All</p>
            </CheckboxInputContainer>

            {collectionsList.map((collection) => {
              const { name, _id } = collection;
              return (
                <CheckboxInputContainer key={_id} onClick={() => handleAdd(collection)}>
                  <SelectCheckboxContainer>
                    {isSelecetd(_id) ? <CheckedIcon /> : <UncheckedIcon />}
                  </SelectCheckboxContainer>
                  <p style={{ fontSize: '16px' }}>{shortenText(name, 20, '...')}</p>
                </CheckboxInputContainer>
              );
            })}
          </>
        ) : (
          <EmptyContentText fontSize='16px'>No Collection</EmptyContentText>
        )}
      </FilterCardContainer>
    );
  };

  const getThumb = async (source) => {
    const metadata = await getMetadata(source);
    const thumbs = await getThumbnails(source);
    const { blob } = thumbs[1];
    let url = fallbackThumbNail;
    if (blob) {
      url = URL.createObjectURL(blob);
    }
    setVideoMeta({ ...videoMeta, url, duration: metadata.duration });
    setLoadingFile(false);
  };

  const handleFileUpload = ({ target }) => {
    const file = target.files[0];

    const { type } = file;

    if (file && type === 'video/mp4') {
      setLoadingFile(true);
      // generates a local url
      const URL = window.URL || window.webkitURL;
      const url = URL.createObjectURL(file);
      getThumb(url);
      setUploadVideo({ ...uploadVideo, file, fileName: file.name });
      setShowUploadVideoModal(true);
    } else {
      Toast.error('Upload mp4 File', 2);
    }
  };

  const handleUploadCancel = () => {
    setShowUploadVideoModal(false);
    setUploadVideo({ file: '', fileName: '' });
    setVideoMeta({ url: '', duration: '' });
  };

  const handleSubmit = ({ title, collectionId, collectionName, newCollection, addCollection, videoHidden }) => {
    const payload = {
      access: videoHidden,
      addCollection,
      collectionName,
      collectionId,
      duration: videoMeta.duration,
      file: uploadVideo.file,
      newCollection,
      title
    };
    dispatch(Creators.uploadVideo(payload));
    handleUploadCancel();
  };

  return (
    <>
      {uploading && <UplaodToast />}
      {(loading || loadingFile) && <LoadingComponent />}
      <Container>
        <Toasts />
        {deleteItem && deleteItemId ? (
          <DeletePopupComponent
            title='Delete Video'
            primaryText='Are you sure, you want to delete the video permanently?'
            secondaryText=''
            closePopupFunc={popUpCancelFunc}
            deleteFunc={popUpDeteleFunc}
            itemID={deleteItemId}
          />
        ) : (
          ''
        )}
        <Row>
          <SkillHeader>
            <CompareButtonComponent list={compareVideos} to='/player/skills/comparevideo' />

            <ManageContainer>
              <ManageIconWrapper>
                <TooltipComponnet
                  type='manage'
                  title='Manage Collection'
                  onClick={() => dispatch(Creators.setShowManageCollectionModal({ showManageCollection: true }))}
                />
              </ManageIconWrapper>

              <SearchContainer>
                <SearchBoxComponent placeHolder='Search Video' onSearchChange={handleVideoSearch} />
              </SearchContainer>

              <FilterContainer ref={dropdownRef}>
                <FilterToggle onClick={() => setToggleFilterCard(!toggleFilterCard)}>
                  <FilterIcon />
                  Filter
                </FilterToggle>
                {toggleFilterCard && FilterCard()}
              </FilterContainer>

              <UploadVideoWrapper onClick={() => inputRef.current.click()}>
                <IconButtonComponenet margin='0 0 0 1rem'>
                  {!uploadVideo.file && (
                    <input
                      type='file'
                      id='fileInput'
                      style={{ display: 'none' }}
                      accept='video/mp4'
                      ref={inputRef}
                      onChange={handleFileUpload}
                    />
                  )}
                  <UploadIcon />
                  Upload Video
                </IconButtonComponenet>
              </UploadVideoWrapper>
            </ManageContainer>
          </SkillHeader>
        </Row>

        <Divider />
        {videosList.length ? (
          <>
            <ScrollContainer>
              <Table>
                <thead>
                  <tr>
                    <Th pLeft='2'>Title</Th>
                    <Th>Collection</Th>
                    <Th>Upload date</Th>
                    <Th>Duration</Th>
                    <Th>Comments</Th>
                    <Th style={{ textAlign: 'center' }}>Actions</Th>
                  </tr>
                </thead>
                <tbody>
                  {videosList.map((video) => {
                    const { _id, title, collectionName, access, commentCount, uploadDateWeb, duration, thumbUrl } =
                      video;

                    return (
                      <Tr key={_id} style={{ borderBottom: '1px solid #ddd' }}>
                        <Td>
                          <Linkto onClick={() => history.push(`/player/skills/watchVideo/${_id}`)}>
                            <FlexContainer>
                              <AvatarComponent url={thumbUrl} />
                              <div style={{ maxWidth: '300px' }}>
                                <VideoTitle className='word'>{title}</VideoTitle>
                                <VideoType>{access}</VideoType>
                              </div>
                            </FlexContainer>
                          </Linkto>
                        </Td>
                        <Td>
                          <Bagde>
                            <p className='clip'>{collectionName}</p>
                          </Bagde>
                        </Td>
                        <Td>{uploadDateWeb}</Td>
                        <Td>{Timeformater(duration)}</Td>
                        <Td>{commentCount}</Td>
                        <Td>
                          <UserActionsContainer>
                            <TooltipComponnet
                              title='Edit'
                              type='edit'
                              position='top'
                              onClick={() => {
                                dispatch(VideoPlayerCreators.setReloadVideoDetails({ reloadVideoDetails: false }));
                                setShowEditVideoModal(true);
                                setCurrentVideoId(_id);
                              }}
                            />

                            <TooltipComponnet
                              title='Delete'
                              type='delete'
                              position='top'
                              onClick={() => {
                                deleteCurrentVideo(_id);
                              }}
                            />
                          </UserActionsContainer>
                        </Td>
                      </Tr>
                    );
                  })}
                </tbody>
              </Table>
            </ScrollContainer>
            <PaginationComponent
              totalItems={videosCount}
              pageNo={page}
              onChange={(value) => {
                setPage(value.currentPage);
                setCount(value.count);
              }}
            />
          </>
        ) : (
          <EmptyContentText style={{ height: 'calc(100vh - 181px)' }}>No videos Found </EmptyContentText>
        )}
      </Container>
      {/*
       *
       * Manage Collection Modal
       *
       */}

      {showManageCollection && <ManageCollectionModal />}
      {showCreateCollection && <CreateCollectionModal />}
      {showEditVideoModal && <EditVideoModal videoId={currentVideoId} onCancel={() => setShowEditVideoModal(false)} />}
      {/*
       *
       * Upload Video Modal
       *
       */}
      {showUploadVideoModal && !!uploadVideo.file && (
        <UploadVideoModal {...uploadVideo} {...videoMeta} onCancel={handleUploadCancel} onSubmit={handleSubmit} />
      )}
    </>
  );
};

export default SkillsView;
