/* eslint-disable no-console */
/* eslint no-shadow: ["error", { "allow": ["payload"] }]  */

import { put } from 'redux-saga/effects';
import { Creators } from './action';
import { Creators as RestCreators } from '../../RestPlayers/store';

import { WebService } from '../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../services/axios/endPoints';

export function* groupListSaga({ payload }) {
  const { page, count } = payload;
  try {
    yield put(Creators.groupListStart());

    const fullURL = yield `${apiEndPoints.groupList}`;

    const response = yield WebService.get(fullURL, { params: { page, count } });

    if (response.status >= 200 && response.status < 300) {
      const group = response.data.data;

      yield put(Creators.groupListSuccess({ groupList: group[0], totalItems: group[2].totalCount }));
      yield put(RestCreators.resetReload());
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.groupListFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.groupListFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.groupListFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.groupListFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.groupListFailure(payload));
    }
  }
}

// Delete Group
export function* groupDeleteSaga({ payload }) {
  const { name, _id } = payload;
  try {
    yield put(Creators.deleteGroupStart());
    const fullURL = yield `${apiEndPoints.deleteGroup}`;

    // eslint-disable-next-line no-undef

    const response = yield WebService({
      method: 'DELETE',
      url: fullURL,
      data: { name, _id }
    });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      yield put(Creators.deleteGroupSuccess({ newGroupPlayers: data[0] }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.deleteGroupFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.deleteGroupFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.deleteGroupFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.deleteGroupFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.deleteGroupFailure(payload));
    }
  }
}

export function* searchGroupSaga({ payload }) {
  const { searchKey, count, page } = payload;

  try {
    yield put(Creators.searchGroupStart());
    const fullURL = yield `${apiEndPoints.searchGroup}`;

    const response = yield WebService.get(fullURL, { params: { page, count, searchKey } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      yield put(Creators.searchGroupSuccess({ groupList: data[0], totalItems: data[2].totalCount }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.searchGroupFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.searchGroupFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.searchGroupFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.searchGroupFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.searchGroupFailure(payload));
    }
  }
}

export function* searchGroupListSaga({ payload }) {
  const { searchKey } = payload;

  try {
    yield put(Creators.searchGroupStart());
    const fullURL = yield `${apiEndPoints.searchGroupList}`;

    const response = yield WebService.get(fullURL, { params: { searchKey } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      yield put(Creators.searchGroupListSuccess({ groupList: data }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.searchGroupFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.searchGroupFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.searchGroupFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.searchGroupFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.searchGroupFailure(payload));
    }
  }
}
