/* eslint-disable max-len */
import { createReducer } from 'reduxsauce';
import { mergeArray } from '../../../../../../services/utility';
import { Types } from './action';

const INITIAL_STATE = {
  loading: false,
  error: null,
  reload: false,
  success: false,
  sessionDeatils: [],
  commentsList: [],
  commentCount: 0,
  maxCommentPages: 0
};

export const getCoachStrengthFeedDetailsStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

export const getCoachStrengthFeedDetailsSuccess = (state = INITIAL_STATE, action) => {
  const { sessionDeatils } = action.payload;

  return {
    ...state,
    loading: false,
    sessionDeatils
  };
};

export const getCoachStrengthFeedDetailsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const getSessionCommentsStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: false });

const getSessionCommentsSuccess = (state = INITIAL_STATE, action) => {
  const { commentCount, commentsList, maxPage } = action.payload;

  const newCommentsList = commentsList;
  const oldCommentsList = state.commentsList;
  const newArray = mergeArray(oldCommentsList, newCommentsList);
  return {
    ...state,
    commentsList: [...newArray],
    commentCount,
    maxCommentPages: maxPage
  };
};

const getSessionCommentsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    error
  };
};

const resetComments = (state = INITIAL_STATE) => ({ ...state, commentsList: [], commentCount: 0, maxCommentPages: 0 });

const HANDLERS = {
  [Types.GET_COACH_STRENGTH_FEED_DETAILS_START]: getCoachStrengthFeedDetailsStart,
  [Types.GET_COACH_STRENGTH_FEED_DETAILS_SUCCESS]: getCoachStrengthFeedDetailsSuccess,
  [Types.GET_COACH_STRENGTH_FEED_DETAILS_FAILURE]: getCoachStrengthFeedDetailsFailure,

  [Types.GET_SESSION_COMMENTS_START]: getSessionCommentsStart,
  [Types.GET_SESSION_COMMENTS_SUCCESS]: getSessionCommentsSuccess,
  [Types.GET_SESSION_COMMENTS_FAILURE]: getSessionCommentsFailure,

  [Types.RESET_COMMENTS]: resetComments
};

export const coachStrengthFeedDetailsReducer = createReducer(INITIAL_STATE, HANDLERS);
