/*
 * action.js file for details view
 * Author:
 * 1.Manikanta
 *
 * @copyright of Panorbit Services LLP.
 */

import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  setPersonalDetails: ['payload'],
  resetPersonalDetails: []
});

export { Types, Creators };
