/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { textColors, accentColor, fontWeight, fontSize } from '../../constants';

export const LinkButton = styled(Link)`
  padding: 12px 30px;

  border: none;
  border-radius: 0.5rem;

  ${(props) =>
    props.bgtype === 'primary' &&
    css`
      background: transparent linear-gradient(108deg, ${accentColor.secondaryLite} 0%, ${accentColor.primaryDark} 100%)
        0% 0% no-repeat;
    `};

  ${(props) =>
    props.bgtype === 'dark' &&
    css`
      background: transparent linear-gradient(108deg, ${accentColor.secondaryLite} 0%, ${accentColor.primaryDark} 100%)
        0% 0% no-repeat;
    `};

  cursor: pointer;

  text-align: center;

  font-size: ${fontSize.mediumDefault};
  color: ${textColors.textDark};
  font-weight: ${fontWeight.bold};

  &:focus {
    outline: none;
  }
`;
