/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-const */
import React from 'react';
import { Line } from 'react-chartjs-2';

import { ChartCanvasWrapper } from './HomeStyles';

import { getDay, getMothSmall, getYearMonthDay } from '../../../services/utility/moment';
import { getPastDays } from '../../Player/HomeView/utlity';
import { _converIntoKAndM } from '../../../services/utility/commurize';

const TeamNutrition = ({ data, stats }) => {
  const { dataType: type, value: days } = data;
  const pastDays = getPastDays({ type, days });

  pastDays.forEach((item) => {
    let filterDay = [];
    if (type === 'month') {
      filterDay = stats.filter((stat) => getMothSmall(stat.date) === item.MM);
    } else {
      filterDay = stats.filter((stat) => getYearMonthDay(stat.date) === item.MMDDYY);
    }
    item.caloriesIntake = filterDay.length ? filterDay[0].caloriesIntake.toFixed(2) : null;
  });

  let Dates = [];
  let Data = [];

  pastDays.reverse().forEach((item) => {
    if (type === 'month') {
      Dates.push(getMothSmall(item.MMDDYY));
    } else {
      Dates.push(getDay(item.MMDDYY));
    }
    Data.push(item.caloriesIntake);
  });

  // hydration chart data
  const chartData = (canvas) => {
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, canvas.height, 0, 0);
    gradient.addColorStop(0, '#21D0B300');
    gradient.addColorStop(1, '#21D0B382');

    return {
      labels: Dates,
      datasets: [
        {
          label: type === 'month' ? 'Months' : 'Dates',
          data: Data,
          fill: true,
          backgroundColor: gradient,
          borderColor: 'rgba(75,192,192,1)',
          lineTension: 0
        }
      ]
    };
  };

  const options = {
    tooltips: {
      enabled: true,
      callbacks: {
        label(data) {
          return `Kcal ${_converIntoKAndM(parseInt(data.value))}`;
        }
      }
    },
    pan: {
      enabled: true,
      mode: 'x'
    },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        boxWidth: 0
      }
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            zeroLineBorderDash: [5, 5],
            color: 'rgba(0, 0, 0, 0)',
            zeroLineColor: 'rgba(255, 255, 255, 0.3)'
          },
          spanGaps: false
        }
      ],
      yAxes: [
        {
          gridLines: {
            color: 'rgba(255, 255, 255, 0.3)',
            zeroLineColor: 'rgba(255, 255, 255, 0.3)',
            zeroLineBorderDash: [5, 5],
            borderDash: [5, 5]
          },
          scaleLabel: {
            display: true,
            labelString: 'Calories Taken (Kcal)'
          },
          ticks: {
            beginAtZero: true,
            // eslint-disable-next-line no-unused-vars
            userCallback(value, index, labels) {
              return _converIntoKAndM(value);
            }
          }
        }
      ]
    }
  };

  return (
    <ChartCanvasWrapper>
      <Line data={chartData} options={options} />
    </ChartCanvasWrapper>
  );
};

export default TeamNutrition;
