/*
 * action.js file for details view
 * Author:
 * 1.Manikanta
 *
 * @copyright of Panorbit Services LLP.
 */

import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  setUserMeta: ['payload'],
  setUserMetaStart: ['payload'],
  setUserMetaSuccess: ['payload'],
  setUserMetaFailure: ['payload'],

  fileUpload: ['payload'],
  fileUploadStart: ['payload'],
  fileUploadSuccess: ['payload'],
  fileUploadFailure: ['payload'],
  resetSuccess: null
});

export { Types, Creators };
