import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getHelpAndSupportList: ['payload'],
  getHelpAndSupportListStart: ['payload'],
  getHelpAndSupportListSuccess: ['payload'],
  getHelpAndSupportListFailure: ['payload'],

  getAdminHelpAndSupportList: ['payload'],
  getAdminHelpAndSupportListStart: ['payload'],
  getAdminHelpAndSupportListSuccess: ['payload'],
  getAdminHelpAndSupportListFailure: ['payload'],

  getCoachHelpAndSupportList: ['payload'],
  getCoachHelpAndSupportListStart: ['payload'],
  getCoachHelpAndSupportListSuccess: ['payload'],
  getCoachHelpAndSupportListFailure: ['payload'],

  getPlayerHelpAndSupportList: ['payload'],
  getPlayerHelpAndSupportListStart: ['payload'],
  getPlayerHelpAndSupportListSuccess: ['payload'],
  getPlayerHelpAndSupportListFailure: ['payload'],

  searchHelpAndSupport: ['payload'],
  searchHelpAndSupportStart: ['payload'],
  searchHelpAndSupportSuccess: ['payload'],
  searchHelpAndSupportFailure: ['payload']
});

export { Types, Creators };
