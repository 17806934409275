/* eslint-disable max-len */
/* eslint-disable arrow-body-style */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CompareVideoPlayerComponent } from '../../../globalShared';
import { Creators } from './store';
// styles
import { EmptyContainer, LinkItem, HeaderTxt, PlayerWrapper } from './CompareView.styles';
import { Divider, Row, FlexContainer } from '../../../globalShared/CommonStyles';

// icons
import { ReactComponent as AddIcon } from '../../../shared/assets/add_for_compare.svg';
import { ReactComponent as BackIcon } from '../../../shared/assets/back-header.svg';
//

const EmptyContainerComponent = ({ linkto }) => (
  <EmptyContainer>
    <LinkItem to={linkto}>
      <div>
        <AddIcon style={{ marginBottom: '0.2rem' }} />
        Add Video to Compare
      </div>
    </LinkItem>
  </EmptyContainer>
);

// component
const CompareView = ({ history }) => {
  const compareVideos = useSelector((state) => state.compareVideos.videos);
  const dispatch = useDispatch();

  const handleRemove = (id) => {
    const videos = compareVideos.filter((el) => el._id !== id);
    dispatch(Creators.addVideoToCompare({ videos }));
  };

  return (
    <>
      <Row>
        <FlexContainer style={{ padding: '10px 0' }}>
          <BackIcon style={{ cursor: 'pointer' }} onClick={() => history.goBack()} />
          <HeaderTxt>Compare Videos</HeaderTxt>
        </FlexContainer>
      </Row>
      <Divider style={{ marginBottom: '2rem' }} />

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {(() => {
          switch (compareVideos.length) {
            case 1:
              return (
                <>
                  <PlayerWrapper>
                    <CompareVideoPlayerComponent
                      URL={compareVideos[0].videoUrl}
                      title={compareVideos[0].title}
                      player={`${compareVideos[0].firstName} ${compareVideos[0].lastName}`}
                      removeAction={() => handleRemove(compareVideos[0]._id)}
                    />
                  </PlayerWrapper>
                  <EmptyContainerComponent linkto='addvideo' />
                </>
              );
            case 2:
              return (
                <>
                  {compareVideos.map((video) => {
                    const { videoUrl, _id, title, firstName, lastName } = video;
                    return (
                      <PlayerWrapper key={_id}>
                        <CompareVideoPlayerComponent
                          URL={videoUrl}
                          title={title}
                          player={`${firstName} ${lastName}`}
                          removeAction={() => handleRemove(_id)}
                        />
                      </PlayerWrapper>
                    );
                  })}
                </>
              );

            default:
              return (
                <>
                  <EmptyContainerComponent linkto='addvideo' />
                  <EmptyContainerComponent linkto='addvideo' />
                </>
              );
          }
        })()}
      </div>
    </>
  );
};

export default CompareView;
