/* eslint-disable arrow-body-style */
/*
 * reducer file for forgot password views
 * Author:
 * 1. Manikanta
 *
 * @copyright of Panorbit Services LLP.
 */

import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  loading: false,
  error: null,
  success: false,
  email: ''
};

const verifyEmailStart = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: true,
    error: null
  };
};

const verifyEmailSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: false,
    error: null,
    success: true
  };
};

const verifyEmailFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;

  return {
    ...state,
    loading: false,
    success: false,
    error
  };
};

const setUserEmail = (state = INITIAL_STATE, action) => {
  const { email } = action.payload;
  return {
    ...state,
    email
  };
};

const resetData = (state = INITIAL_STATE) => ({ ...state, success: false, error: null });

const HANDLERS = {
  [Types.VERIFY_EMAIL_START]: verifyEmailStart,
  [Types.VERIFY_EMAIL_SUCCESS]: verifyEmailSuccess,
  [Types.VERIFY_EMAIL_FAILURE]: verifyEmailFailure,

  [Types.SET_USER_EMAIL]: setUserEmail,
  [Types.RESET_DATA]: resetData
};

export const forgetPasswordReducer = createReducer(INITIAL_STATE, HANDLERS);
