import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { fontSize, textColors } from '../../../shared/constants';

export const Container = styled.div`
  padding: 6rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const FormContainer = styled.form`
  width: 300px;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
`;

export const LinkContainer = styled(Link)`
  font-size: ${fontSize.smallDefault};
  padding: 1.5rem;

  & > svg {
    margin-right: 1rem;
  }
`;

export const ErrorMsg = styled.p`
  font-size: ${fontSize.mediumDefault};
  color: ${textColors.error};
  margin-right: auto;
  margin-top: -1.5rem;
  margin-bottom: 1rem;
  text-transform: capitalize;
`;

export const Toast = styled.div`
  font-size: 100% !important;
`;
