/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import { createReducer } from 'reduxsauce';
import { Types } from './action';
import { mergeArray } from '../../../../services/utility';

const INITIAL_STATE = {
  loading: false,
  error: null,
  intakeSummary: null,
  waterReload: false,
  meals: [],
  reloadMealDetails: false,
  reload: false,
  success: false,
  page: 1,
  intakeSummaryCommentsList: [],
  mealCommentsList: [],
  mealCommentCount: 0
};

const getPlayerMealPlanListStart = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true,
  mealCommentsList: [],
  intakeSummaryCommentsList: []
});

const getPlayerMealPlanListSuccess = (state = INITIAL_STATE, action) => {
  const { intakeSummary, meals } = action.payload;

  return {
    ...state,
    loading: false,
    reload: false,
    intakeSummary,
    meals
  };
};

const getPlayerMealPlanListFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const setPlayerWaterUpdateStart = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true,
  waterReload: false
});

const setPlayerWaterUpdateSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: false,
  success: true,
  waterReload: true
});

const setPlayerWaterUpdateFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    waterReload: false,
    error
  };
};

const getPlayerIntakeSummaryCommentsStart = (state = INITIAL_STATE) => ({
  ...state,
  error: null
});

const getPlayerIntakeSummaryCommentsSuccess = (state = INITIAL_STATE, action) => {
  const newCommentsList = action.payload.intakeSummaryCommentsList;
  const oldCommentsList = state.intakeSummaryCommentsList;
  const newArray = mergeArray(oldCommentsList, newCommentsList);

  return {
    ...state,
    intakeSummaryCommentsList: [...newArray]
  };
};

const getPlayerMealCommentsStart = (state = INITIAL_STATE) => ({
  ...state,
  error: null
});

const getPlayerMealCommentsSuccess = (state = INITIAL_STATE, action) => {
  const newCommentsList = action.payload.mealCommentsList;
  const mealCommentCount = action.payload.mealCommentCount;

  return {
    ...state,
    mealCommentsList: newCommentsList,
    mealCommentCount
  };
};

const setPlayerMealUpdateStart = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true,
  reloadMealDetails: false
});

const setPlayerMealUpdateSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: false,
  reloadMealDetails: true,
  success: true
});

const setPlayerMealUpdateFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const setPlayerMealUndoneUpdateStart = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true,
  reloadMealDetails: false
});

const setPlayerMealUndoneUpdateSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: false,
  reloadMealDetails: true,
  success: true
});

const setPlayerMealUndoneUpdateFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const setPage = (state = INITIAL_STATE, action) => {
  const { page } = action.payload;
  return {
    ...state,
    page
  };
};

const HANDLERS = {
  [Types.GET_PLAYER_MEAL_PLAN_LIST_START]: getPlayerMealPlanListStart,
  [Types.GET_PLAYER_MEAL_PLAN_LIST_SUCCESS]: getPlayerMealPlanListSuccess,
  [Types.GET_PLAYER_MEAL_PLAN_LIST_FAILURE]: getPlayerMealPlanListFailure,

  [Types.SET_PLAYER_WATER_UPDATE_START]: setPlayerWaterUpdateStart,
  [Types.SET_PLAYER_WATER_UPDATE_SUCCESS]: setPlayerWaterUpdateSuccess,
  [Types.SET_PLAYER_WATER_UPDATE_FAILURE]: setPlayerWaterUpdateFailure,

  [Types.GET_PLAYER_INTAKE_SUMMARY_COMMENTS_START]: getPlayerIntakeSummaryCommentsStart,
  [Types.GET_PLAYER_INTAKE_SUMMARY_COMMENTS_SUCCESS]: getPlayerIntakeSummaryCommentsSuccess,

  [Types.GET_PLAYER_MEAL_COMMENTS_START]: getPlayerMealCommentsStart,
  [Types.GET_PLAYER_MEAL_COMMENTS_SUCCESS]: getPlayerMealCommentsSuccess,

  [Types.SET_PLAYER_MEAL_UPDATE_START]: setPlayerMealUpdateStart,
  [Types.SET_PLAYER_MEAL_UPDATE_SUCCESS]: setPlayerMealUpdateSuccess,
  [Types.SET_PLAYER_MEAL_UPDATE_FAILURE]: setPlayerMealUpdateFailure,

  [Types.SET_PLAYER_MEAL_UNDONE_UPDATE_START]: setPlayerMealUndoneUpdateStart,
  [Types.SET_PLAYER_MEAL_UNDONE_UPDATE_SUCCESS]: setPlayerMealUndoneUpdateSuccess,
  [Types.SET_PLAYER_MEAL_UNDONE_UPDATE_FAILURE]: setPlayerMealUndoneUpdateFailure,

  [Types.SET_PAGE]: setPage
};

export const playerMealPlanReducer = createReducer(INITIAL_STATE, HANDLERS);
