/* eslint-disable no-console */
/* eslint-disable no-shadow */
import { put } from 'redux-saga/effects';
import { Creators } from './action';

import { WebService } from '../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../services/axios/endPoints';
import { getYearMonthDay } from '../../../../services/utility/moment';

const dateTimeString = getYearMonthDay();

export function* searchCoachMealPlannerSaga({ payload }) {
  const { searchKey, count, page } = payload;

  try {
    yield put(Creators.searchCoachMealPlannerStart());
    const fullURL = yield `${apiEndPoints.plannerPlayerSearch}`;

    const response = yield WebService.get(fullURL, { params: { page, count, searchKey, dateTimeString } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      console.log(data);
      yield put(Creators.searchCoachMealPlannerSuccess({ mealPlannerList: data[0], totalItems: data[2].totalCount }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.searchCoachMealPlannerFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.searchCoachMealPlannerFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.searchCoachMealPlannerFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.searchCoachMealPlannerFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.searchCoachMealPlannerFailure(payload));
    }
  }
}

export function* getCoachMealPlannerListSaga({ payload }) {
  const { page, count } = payload;

  try {
    yield put(Creators.getCoachMealPlannerListStart());
    const fullURL = yield `${apiEndPoints.plannerPlayerList}`;

    const response = yield WebService.get(fullURL, { params: { page, count, dateTimeString } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      yield put(Creators.getCoachMealPlannerListSuccess({ mealPlannerList: data[0], totalItems: data[2].totalCount }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getCoachMealPlannerListFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getCoachMealPlannerListFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getCoachMealPlannerListFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getCoachMealPlannerListFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getCoachMealPlannerListFailure(payload));
    }
  }
}

export function* deleteCoachMealPlannerSaga({ payload }) {
  const { id } = payload;

  try {
    yield put(Creators.deleteCoachMealPlannerStart());
    const fullURL = yield `${apiEndPoints.clearPlayerMealPlan}`;

    const response = yield WebService({
      method: 'DELETE',
      url: fullURL,
      data: { _id: id }
    });

    if (response.status >= 200 && response.status < 300) {
      const { message } = response.data;
      yield put(Creators.deleteCoachMealPlannerSuccess({ message }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.deleteCoachMealPlannerFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.deleteCoachMealPlannerFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.deleteCoachMealPlannerFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.deleteCoachMealPlannerFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.deleteCoachMealPlannerFailure(payload));
    }
  }
}
