/* eslint-disable no-unused-vars */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

// styles
import { PasswordResetWrapper, Title } from './CoachProfileModalStyles';
import { Header, NewPasswordWrapper, Column } from './ResetPasswordStyles';

// creators
import { Creators } from './store';

import { ValdErrMsg } from '../../../globalShared/CommonStyles';
import { InputComponent, ButtonComponent } from '../../../globalShared';
import { localString } from '../../../services/locallization/localStrings';

const ResetPassword = (props) => {
  const { closeResetPassword } = props;
  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  const dispatch = useDispatch();

  const { register, handleSubmit, errors, getValues, reset } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const onPasswordUpdateSubmit = (data) => {
    const payload = {
      currentPassword: data.currentPassword,
      password: data.newpassword
    };

    dispatch(Creators.resetCoachPassword(payload));
    reset();
    closeResetPassword();
  };

  return (
    <PasswordResetWrapper>
      <form onSubmit={handleSubmit(onPasswordUpdateSubmit)}>
        <Header>
          <Title>Reset Password</Title>
          <ButtonComponent buttonText={string.save} isButtonAction={true} buttonType='submit' />
        </Header>

        <InputComponent
          ipType='password'
          placeholderText={string.currentPassword}
          label={string.currentPassword}
          ipName='currentPassword'
          ipRef={register({ required: true })}
        />
        <NewPasswordWrapper>
          <Column wdth='50%'>
            <InputComponent
              ipType='password'
              placeholderText={string.newPassword}
              label={string.newPassword}
              ipName='newpassword'
              ipRef={register({
                required: true,
                minLength: {
                  value: 8,
                  message: string.passwordMustContainAtLeast8Characters
                },
                pattern: {
                  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                  message: string.mustIncludeLowUppNumSpec
                }
              })}
              id='newpassword'
            />
            {errors.newpassword && <ValdErrMsg>{errors.newpassword?.message}</ValdErrMsg>}
          </Column>
          <Column wdth='50%'>
            <InputComponent
              ipType='password'
              placeholderText={string.reEnterPassword}
              label={string.reEnterPassword}
              ipName='reenterpassword'
              ipRef={register({
                required: true,
                validate: (value) => value === getValues('newpassword') || string.passwordDoesntMatch
              })}
              id='reenterpassord'
            />
            {errors.reenterpassword && <ValdErrMsg>{errors.reenterpassword?.message}</ValdErrMsg>}
          </Column>
        </NewPasswordWrapper>
      </form>
    </PasswordResetWrapper>
  );
};

export default ResetPassword;
