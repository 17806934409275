import styled from 'styled-components';

import { fontSize } from '../../../shared/constants';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
`;

export const ImageAndInputs = styled.div`
  display: flex;
`;

export const ProfileImg = styled.div`
  position: relative;
  height: 156px;
  width: 240px;
  border-radius: 10px;
  margin-right: 35px;
`;

export const NameInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Image = styled.div`
  position: absolute;
  height: 146px;
  width: 146px;
  border-radius: 10px;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const IconWrapper = styled.div`
  width: 33px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 0px;
  right: 0px;
  background: white;
  border-radius: 50%;
  cursor: pointer;
`;

export const Label = styled.label`
  position: absolute;
  height: 146px;
  width: 146px;
  background: #211e2b;
  border: 1px solid #8792ac;
  border-radius: 10px;
  cursor: pointer;
`;

export const LabelTitle = styled.div`
  font-size: ${fontSize.mediumDefault};
  position: absolute;
  color: #8792ac;
  top: 60px;
  left: 45px;
`;
