import { createReducer } from 'reduxsauce';
import { Types } from './action';
import { mergeArray } from '../../../../services/utility';

const INITIAL_STATE = {
  loading: false,
  error: null,
  moodStats: [],
  stats: '',
  sleepStats: [],
  avgSleepDuration: '',
  meditationStats: [],
  avgMeditationDuration: '',
  todaysMeditationCheck: [],
  todaysSleepCheck: '',
  reloadTodaysMeditationCheck: false,
  reloadTodaysSleepCheck: false,
  basicMeditation: '',
  guidedMeditation: '',
  expertMeditation: '',
  sleepCommentsList: [],
  meditationTrackCommentsList: [],
  reloadSleepTrackComments: false,
  reloadMeditationTrackComments: false,
  currentTrackProgress: 0,
  reload: false,
  success: false
};

const getPlayerMoodStatsStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getPlayerMoodStatsSuccess = (state = INITIAL_STATE, action) => {
  const { moodStats, stats } = action.payload;

  return {
    ...state,
    loading: false,
    reload: false,
    moodStats,
    stats
  };
};

const getPlayerMoodStatsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const getPlayerSleepStatsStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getPlayerSleepStatsSuccess = (state = INITIAL_STATE, action) => {
  const { sleepStats, avgSleepDuration } = action.payload;

  return {
    ...state,
    loading: false,
    reload: false,
    sleepStats,
    avgSleepDuration
  };
};

const getPlayerSleepStatsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const getPlayerMeditationStatsStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getPlayerMeditationStatsSuccess = (state = INITIAL_STATE, action) => {
  const { meditationStats, avgMeditationDuration } = action.payload;

  return {
    ...state,
    loading: false,
    reload: false,
    meditationStats,
    avgMeditationDuration
  };
};

const getPlayerMeditationStatsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const getPlayerTodaysMeditationCheckStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getPlayerTodaysMeditationCheckSuccess = (state = INITIAL_STATE, action) => {
  const { todaysMeditationCheck, basicMeditation, guidedMeditation, expertMeditation } = action.payload;
  const newCheck = todaysMeditationCheck.filter((check) => check !== null);

  return {
    ...state,
    loading: false,
    reload: false,
    todaysMeditationCheck: newCheck,
    basicMeditation,
    guidedMeditation,
    expertMeditation
  };
};

const getPlayerTodaysMeditationCheckFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const addPlayerMeditationCommentStart = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: false,
  reloadMeditationTrackComments: false
});

const addPlayerMeditationCommentSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  reloadMeditationTrackComments: true
});
const addPlayerMeditationCommentFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    reloadMeditationTrackComments: false,
    error
  };
};

const getPlayerMeditationTrackCommentsStart = (state = INITIAL_STATE) => ({
  ...state,
  error: null
});

const getPlayerMeditationTrackCommentsSuccess = (state = INITIAL_STATE, action) => {
  const newCommentsList = action.payload.meditationTrackCommentsList;
  const oldCommentsList = state.meditationTrackCommentsList;
  const newArray = mergeArray(oldCommentsList, newCommentsList);

  return {
    ...state,
    meditationTrackCommentsList: [...newArray]
  };
};

const getPlayerMeditationTrackCommentsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    error
  };
};

const deletePlayerTodaysMeditationCheckStart = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true,
  reloadTodaysMeditationCheck: false
});

const deletePlayerTodaysMeditationCheckSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  reloadTodaysMeditationCheck: true
});

const deletePlayerTodaysMeditationCheckFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    reloadTodaysMeditationCheck: false,
    error
  };
};

const setPlayerMeditationDurationStart = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true,
  reloadTodaysMeditationCheck: false
});

const setPlayerMeditationDurationSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  reloadTodaysMeditationCheck: true
});

const setPlayerMeditationDurationFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    reloadTodaysMeditationCheck: false,
    error
  };
};

const editPlayerMeditationDurationStart = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true,
  reloadTodaysMeditationCheck: false
});

const editPlayerMeditationDurationSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  reloadTodaysMeditationCheck: true
});

const editPlayerMeditationDurationFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    reloadTodaysMeditationCheck: false,
    error
  };
};

const getPlayerTodaysSleepCheckStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getPlayerTodaysSleepCheckSuccess = (state = INITIAL_STATE, action) => {
  const { todaysSleepCheck } = action.payload;

  return {
    ...state,
    loading: false,
    reload: false,
    todaysSleepCheck
  };
};

const getPlayerTodaysSleepCheckFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const addPlayerSleepStart = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true,
  reloadTodaysSleepCheck: false
});

const addPlayerSleepSuccess = (state = INITIAL_STATE) => ({ ...state, loading: false, reloadTodaysSleepCheck: true });
const addPlayerSleepFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    reloadTodaysSleepCheck: false,
    error
  };
};

const deletePlayerSleepStart = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true,
  reloadTodaysSleepCheck: false
});

const deletePlayerSleepSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  reloadTodaysSleepCheck: true
});

const deletePlayerSleepFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    reloadTodaysSleepCheck: false,
    error
  };
};

const getPlayerSleepTrackCommentsStart = (state = INITIAL_STATE) => ({
  ...state,
  error: null
});

const getPlayerSleepTrackCommentsSuccess = (state = INITIAL_STATE, action) => {
  const newCommentsList = action.payload.sleepCommentsList;
  const oldCommentsList = state.sleepCommentsList;
  const newArray = mergeArray(oldCommentsList, newCommentsList);

  return {
    ...state,
    sleepCommentsList: [...newArray]
  };
};

const getPlayerSleepTrackCommentsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    error
  };
};

const setCurrentTrackProgress = (state = INITIAL_STATE, action) => {
  const { currentTrackProgress } = action.payload;

  return {
    ...state,
    currentTrackProgress
  };
};

const HANDLERS = {
  [Types.GET_PLAYER_MOOD_STATS_START]: getPlayerMoodStatsStart,
  [Types.GET_PLAYER_MOOD_STATS_SUCCESS]: getPlayerMoodStatsSuccess,
  [Types.GET_PLAYER_MOOD_STATS_FAILURE]: getPlayerMoodStatsFailure,

  [Types.GET_PLAYER_SLEEP_STATS_START]: getPlayerSleepStatsStart,
  [Types.GET_PLAYER_SLEEP_STATS_SUCCESS]: getPlayerSleepStatsSuccess,
  [Types.GET_PLAYER_SLEEP_STATS_FAILURE]: getPlayerSleepStatsFailure,

  [Types.GET_PLAYER_MEDITATION_STATS_START]: getPlayerMeditationStatsStart,
  [Types.GET_PLAYER_MEDITATION_STATS_SUCCESS]: getPlayerMeditationStatsSuccess,
  [Types.GET_PLAYER_MEDITATION_STATS_FAILURE]: getPlayerMeditationStatsFailure,

  [Types.GET_PLAYER_TODAYS_MEDITATION_CHECK_START]: getPlayerTodaysMeditationCheckStart,
  [Types.GET_PLAYER_TODAYS_MEDITATION_CHECK_SUCCESS]: getPlayerTodaysMeditationCheckSuccess,
  [Types.GET_PLAYER_TODAYS_MEDITATION_CHECK_FAILURE]: getPlayerTodaysMeditationCheckFailure,

  [Types.ADD_PLAYER_MEDITATION_COMMENT_START]: addPlayerMeditationCommentStart,
  [Types.ADD_PLAYER_MEDITATION_COMMENT_SUCCESS]: addPlayerMeditationCommentSuccess,
  [Types.ADD_PLAYER_MEDITATION_COMMENT_FAILURE]: addPlayerMeditationCommentFailure,

  [Types.GET_PLAYER_MEDITATION_TRACK_COMMENTS_START]: getPlayerMeditationTrackCommentsStart,
  [Types.GET_PLAYER_MEDITATION_TRACK_COMMENTS_SUCCESS]: getPlayerMeditationTrackCommentsSuccess,
  [Types.GET_PLAYER_MEDITATION_TRACK_COMMENTS_FAILURE]: getPlayerMeditationTrackCommentsFailure,

  [Types.DELETE_PLAYER_TODAYS_MEDITATION_CHECK_START]: deletePlayerTodaysMeditationCheckStart,
  [Types.DELETE_PLAYER_TODAYS_MEDITATION_CHECK_SUCCESS]: deletePlayerTodaysMeditationCheckSuccess,
  [Types.DELETE_PLAYER_TODAYS_MEDITATION_CHECK_FAILURE]: deletePlayerTodaysMeditationCheckFailure,

  [Types.SET_PLAYER_MEDITATION_DURATION_START]: setPlayerMeditationDurationStart,
  [Types.SET_PLAYER_MEDITATION_DURATION_SUCCESS]: setPlayerMeditationDurationSuccess,
  [Types.SET_PLAYER_MEDITATION_DURATION_FAILURE]: setPlayerMeditationDurationFailure,

  [Types.EDIT_PLAYER_MEDITATION_DURATION_START]: editPlayerMeditationDurationStart,
  [Types.EDIT_PLAYER_MEDITATION_DURATION_SUCCESS]: editPlayerMeditationDurationSuccess,
  [Types.EDIT_PLAYER_MEDITATION_DURATION_FAILURE]: editPlayerMeditationDurationFailure,

  [Types.GET_PLAYER_TODAYS_SLEEP_CHECK_START]: getPlayerTodaysSleepCheckStart,
  [Types.GET_PLAYER_TODAYS_SLEEP_CHECK_SUCCESS]: getPlayerTodaysSleepCheckSuccess,
  [Types.GET_PLAYER_TODAYS_SLEEP_CHECK_FAILURE]: getPlayerTodaysSleepCheckFailure,

  [Types.ADD_PLAYER_SLEEP_START]: addPlayerSleepStart,
  [Types.ADD_PLAYER_SLEEP_SUCCESS]: addPlayerSleepSuccess,
  [Types.ADD_PLAYER_SLEEP_FAILURE]: addPlayerSleepFailure,

  [Types.DELETE_PLAYER_SLEEP_START]: deletePlayerSleepStart,
  [Types.DELETE_PLAYER_SLEEP_SUCCESS]: deletePlayerSleepSuccess,
  [Types.DELETE_PLAYER_SLEEP_FAILURE]: deletePlayerSleepFailure.apply,

  [Types.GET_PLAYER_SLEEP_TRACK_COMMENTS_START]: getPlayerSleepTrackCommentsStart,
  [Types.GET_PLAYER_SLEEP_TRACK_COMMENTS_SUCCESS]: getPlayerSleepTrackCommentsSuccess,
  [Types.GET_PLAYER_SLEEP_TRACK_COMMENTS_FAILURE]: getPlayerSleepTrackCommentsFailure,

  [Types.SET_CURRENT_TRACK_PROGRESS]: setCurrentTrackProgress
};

export const playerMindsetReducer = createReducer(INITIAL_STATE, HANDLERS);
