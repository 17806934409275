/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable no-shadow */
import { put } from 'redux-saga/effects';
import { Creators } from './action';

import { WebService } from '../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../services/axios/endPoints';

export function* getPlayerMealPlanList({ payload }) {
  const { date } = payload;
  try {
    yield put(Creators.getPlayerMealPlanListStart());
    const fullURL = yield `${apiEndPoints.playerMealPlanList}`;

    const response = yield WebService.get(fullURL, { params: { date } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      yield put(Creators.getPlayerMealPlanListSuccess({ intakeSummary: data.intakeSummary, meals: data.meals }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getPlayerMealPlanListFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getPlayerMealPlanListFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getPlayerMealPlanListFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getPlayerMealPlanListFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getPlayerMealPlanListFailure(payload));
    }
  }
}

export function* setPlayerWaterUpdate({ payload }) {
  const { _id, water } = payload;
  // console.log(payload);

  try {
    yield put(Creators.setPlayerWaterUpdateStart());
    const fullURL = yield `${apiEndPoints.playerWaterUpdate}`;

    const response = yield WebService({
      method: 'PATCH',
      url: fullURL,
      data: { _id, water }
    });

    if (response.status >= 200 && response.status < 300) {
      const { message } = response.data;
      yield put(Creators.setPlayerWaterUpdateSuccess({ message }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.setPlayerWaterUpdateFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.setPlayerWaterUpdateFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.setPlayerWaterUpdateFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.setPlayerWaterUpdateFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.setPlayerWaterUpdateFailure(payload));
    }
  }
}

export function* getPlayerIntakeSummaryCommentsSaga({ payload }) {
  const { _id, page = '1', count } = payload;

  try {
    yield put(Creators.getPlayerIntakeSummaryCommentsStart());
    const fullURL = yield `${apiEndPoints.commentList}`;

    const response = yield WebService.get(fullURL, { params: { _id, page, count } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      const payload = {
        intakeSummaryCommentsList: data[0],
        maxPageNo: data[1]
      };

      yield put(Creators.getPlayerIntakeSummaryCommentsSuccess(payload));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getPlayerIntakeSummaryCommentsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getPlayerIntakeSummaryCommentsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getPlayerIntakeSummaryCommentsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getPlayerIntakeSummaryCommentsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getPlayerIntakeSummaryCommentsFailure(payload));
    }
  }
}

export function* getPlayerMealCommentsSaga({ payload }) {
  const { _id, page = '1', count, referenceId } = payload;
  // console.log(payload);

  try {
    yield put(Creators.getPlayerMealCommentsStart());
    const fullURL = yield `${apiEndPoints.commentList}`;

    const response = yield WebService.get(fullURL, { params: { _id, page, count, secondaryId: referenceId } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      const payload = {
        mealCommentsList: data[0],
        maxPageNo: data[1].page_count,
        mealCommentCount: data[2].commentCount
      };

      yield put(Creators.getPlayerMealCommentsSuccess(payload));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getPlayerMealCommentsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getPlayerMealCommentsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getPlayerMealCommentsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getPlayerMealCommentsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getPlayerMealCommentsFailure(payload));
    }
  }
}

export function* setPlayerMealUpdateSaga({ payload }) {
  const { mealData } = payload;
  // console.log(mealData);

  try {
    yield put(Creators.setPlayerMealUpdateStart());
    const fullURL = yield `${apiEndPoints.playerMealUpdate}`;

    const response = yield WebService({
      method: 'PUT',
      url: fullURL,
      data: mealData
    });

    if (response.status >= 200 && response.status < 300) {
      const { message } = response.data;
      yield put(Creators.setPlayerMealUpdateSuccess({ message }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.setPlayerMealUpdateFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.setPlayerMealUpdateFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.setPlayerMealUpdateFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.setPlayerMealUpdateFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.setPlayerMealUpdateFailure(payload));
    }
  }
}

export function* setPlayerMealUndoneUpdateSaga({ payload }) {
  const { _id, mealId } = payload;
  // console.log(payload);

  try {
    yield put(Creators.setPlayerMealUndoneUpdateStart());
    const fullURL = yield `${apiEndPoints.mealUndoneUpdate}`;

    const response = yield WebService({
      method: 'PUT',
      url: fullURL,
      data: { _id, mealId }
    });

    if (response.status >= 200 && response.status < 300) {
      const { message } = response.data;
      yield put(Creators.setPlayerMealUndoneUpdateSuccess({ message }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.setPlayerMealUndoneUpdateFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.setPlayerMealUndoneUpdateFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.setPlayerMealUndoneUpdateFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.setPlayerMealUndoneUpdateFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.setPlayerMealUndoneUpdateFailure(payload));
    }
  }
}
