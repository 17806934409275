/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import styled, { css } from 'styled-components';

import { backgroundColor, shadow } from '../../constants';

const Container = styled.div`
  width: 100%;
  padding: 3rem;
  box-shadow: ${shadow.default};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '1.5rem')};

  ${(props) =>
    props.bgType === 'dark' &&
    css`
      background: ${backgroundColor.darkOpaq};
    `};

  ${(props) =>
    props.minHeight &&
    css`
      min-height: ${props.minHeight}px;
    `};

  overflow-y: auto;
`;

const ContainerComponent = (props) => {
  const { borderRadius, border, bgType, children, minHeight } = props;
  return (
    <Container borderRadius={borderRadius} border={border} bgType={bgType} minHeight={minHeight}>
      {children}
    </Container>
  );
};

export default ContainerComponent;
