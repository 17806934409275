/* eslint-disable max-len */
import React, { useState } from 'react';
import moment from 'moment';

// styles
import {
  Modal,
  Card,
  HeaderContainer,
  CardHeader,
  CloseButton,
  MeditationPlayerContainer,
  TopBgContainer,
  PlayerContainer,
  BottomBgContainer
} from './MeditationDetailsModalStyles';

// icons
import { ReactComponent as CloseIcon } from '../../../shared/assets/Close.svg';
import { ReactComponent as PlayTopBg } from '../../../shared/assets/meditation_1_play_top.svg';
import { ReactComponent as PlayBottomBg } from '../../../shared/assets/meditation_1_play_bottom.svg';

import { AudioPlayer } from '../../../globalShared';
import CancelMeditationModal from './CancelMeditationModal';
import { meditationMap } from '../../../services/utility/maps';

// music track
const audio5min =
  'https://weshare-now-demo.s3.ap-south-1.amazonaws.com/weshare/media/ID_cdc50f9f-5077-4647-958c-6cc64eb5cd15/Health/Q58RVI2U3RLKSRRHRH.mp3';

const audio10Min =
  'https://weshare-now-demo.s3.ap-south-1.amazonaws.com/weshare/media/ID_cdc50f9f-5077-4647-958c-6cc64eb5cd15/Health/ZZ99W87YCV4DEMAO99.mp3';

const audio15Min =
  'https://weshare-now-demo.s3.ap-south-1.amazonaws.com/weshare/media/ID_cdc50f9f-5077-4647-958c-6cc64eb5cd15/Health/NAPFEQKXXL37RA66CF.mp3';

const MeditationDetailsModal = (props) => {
  const { checkDate, currentMeditation, meditationDetailsClose } = props;
  const audioDuration = 0; // use currentMeditation.duration instead
  const todaysDate = moment().format('YYYY-MM-DD');

  const [showCancelMeditationModal, setShowCancelMeditationModal] = useState(false);

  const handleMeditationDetailsClose = () => {
    if (checkDate === todaysDate) {
      setShowCancelMeditationModal(true);
    } else {
      meditationDetailsClose();
    }
  };
  const audioMap = { basicMeditation: audio5min, guidedMeditation: audio10Min, expertMeditation: audio15Min };

  return (
    <>
      <Modal>
        <Card>
          <HeaderContainer>
            <CardHeader>{meditationMap[currentMeditation.name] || ''}</CardHeader>
            <CloseButton onClick={handleMeditationDetailsClose}>
              <CloseIcon />
            </CloseButton>
          </HeaderContainer>

          <MeditationPlayerContainer>
            <TopBgContainer>
              <PlayTopBg />
            </TopBgContainer>

            <PlayerContainer>
              <AudioPlayer
                checkDate={checkDate}
                currentMeditation={currentMeditation}
                audioSrc={audioMap[currentMeditation.name]}
                audioDuration={audioDuration}
                meditationDetailsClose={meditationDetailsClose}
              />
            </PlayerContainer>

            <BottomBgContainer>
              <PlayBottomBg />
            </BottomBgContainer>
          </MeditationPlayerContainer>
        </Card>
      </Modal>
      {showCancelMeditationModal && (
        <CancelMeditationModal
          currentMeditation={currentMeditation}
          onCancel={() => setShowCancelMeditationModal(false)}
          meditationDetailsClose={meditationDetailsClose}
        />
      )}
    </>
  );
};

export default MeditationDetailsModal;
