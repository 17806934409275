import styled from 'styled-components';
import banner from '../../../shared/assets/background.png';
import { backgroundColor, fontSize, textColors } from '../../../shared/constants';
import Add from '../../../shared/assets/Plus.svg';

export const Header = styled.div`
  height: 204px;
  width: 100%;

  background-image: url(${(props) => props.banner || banner});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 6px;

  display: flex;
  align-items: flex-end;
  padding: 2rem;

  position: relative;

  & > .overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background: linear-gradient(0deg, #181421 0%, #181421 20%, #2518426b 100%);

    z-index: 1;
    border-radius: 6px;
  }

  & > .container {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
`;

export const Divider = styled.div`
  height: 19px;
  width: 2px;
  background: #dedede;
  border-radius: 2px;
  margin: 0 3.5rem;
`;

export const Name = styled.p`
  font-size: ${fontSize.title};

  &:after {
    content: url(${Add});
    margin: 0 4.5rem 0 1rem;
  }
`;

export const Number = styled.h1`
  font-size: ${fontSize.heavy};
`;

export const GridContainer = styled.div`
  // height: 421px;
  margin-top: 2rem;

  display: grid;
  grid-template-columns: auto auto auto;

  grid-gap: 20px;

  & > .item1 {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 3;
  }
`;

export const CardsContainer = styled.div`
  padding: 2rem;
  width: 100%;
  height: auto;
  border-radius: 6px;
  background: ${backgroundColor.card};
`;

export const H1 = styled.h1`
  font-size: ${fontSize.title};
`;

export const P = styled.p`
  font-size: ${fontSize.mediumDefault};

  &.light {
    color: ${textColors.textLight};
  }

  &.opaq {
    opacity: 0.3;
  }

  &.price {
    font-size: ${fontSize.titleMediumSmall};
    font-weight: 800;
    position: relative;

    &:before {
      content: '₹';
      font-size: 16px;
      font-weight: 400;
      position: absolute;
      left: -10px;
      top: 3px;
    }
  }
`;

export const Chart = styled.div`
  width: 100%;
`;
