import styled from 'styled-components';

import { fontSize, backgroundColor, fontWeight } from '../../../shared/constants';

export const ModalContainer = styled.div`
  position: absolute;
  z-index: 99999;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${backgroundColor.popupBg};
`;

export const Card = styled.div`
  min-height: 293px;
  min-width: 649px;
  border-radius: 10px;

  padding: 38px;
  position: relative;

  background-color: ${backgroundColor.popup};

  z-index: 999999;
`;

export const Title = styled.div`
  font-size: calc(${fontSize.titleVerySmall} - 0.1rem);
  font-weight: ${fontWeight.bold};
`;

export const CloseButton = styled.span`
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const InputContainer = styled.div`
  margin-bottom: 26px;
`;

export const FormFooter = styled.div`
  right: 0px;
  bottom: 0;

  display: flex;
  justify-content: flex-end;

  & > div > button:first-child {
    margin: 0 13px;
  }

  & > div > button:last-child {
    margin: 0;
  }
`;
