import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import { Creators } from './store';

import { ButtonComponent, CircularSliderComponent } from '../../../globalShared';
import { FlexContainer } from '../../../globalShared/CommonStyles';

// styles
import './SleepSliderStyles.css';
import {
  BedTimeContainer,
  BedTimes,
  Text,
  H1,
  SleepTrackContainer,
  SleepDateWrapper,
  SleepDate,
  SliderContainer,
  SleepSliderWrapper,
  Twelve,
  Three,
  Six,
  Nine,
  SleepDurationWrapper,
  SleepDurationText,
  Duration,
  CircularSliderWrapper,
  ButtonContainer,
  MainContainer
} from './SleepTrackStyles';

// icons
import { ReactComponent as BedtimeIcon } from '../../../shared/assets/bedtime_icon.svg';
import { ReactComponent as WakeuptimeIcon } from '../../../shared/assets/wakeuptime_icon.svg';
import { ReactComponent as SleepSlider } from '../../../shared/assets/sleep_slider.svg';

import SleepTrackSaved from './SleepTrackSaved';

const SleepTrack = (props) => {
  const { date } = props;
  const dispatch = useDispatch();

  const time = {
    0: [9, 'PM'],
    1: [10, 'PM'],
    2: [11, 'PM'],
    3: [12, 'AM'],
    4: [1, 'AM'],
    5: [2, 'AM'],
    6: [3, 'AM'],
    7: [4, 'AM'],
    8: [5, 'AM'],
    9: [6, 'AM'],
    10: [7, 'AM'],
    11: [8, 'AM'],
    12: [9, 'AM']
  };
  const [isTodaysDate, setIsTodaysDate] = useState(false);
  const [sleepTime, setSleepTime] = useState('60, 300');

  const formattedDate = moment(date).format('D MMM YYYY');

  // values from redux store
  const todaysSleepCheck = useSelector((state) => state.playerMindset.todaysSleepCheck);
  const reloadTodaysSleepCheck = useSelector((state) => state.playerMindset.reloadTodaysSleepCheck);

  // effect hooks
  useEffect(() => {
    dispatch(Creators.getPlayerTodaysSleepCheck({ date }));
  }, [date]);

  useEffect(() => {
    if (reloadTodaysSleepCheck) {
      dispatch(Creators.getPlayerTodaysSleepCheck({ date }));
    }
  }, [reloadTodaysSleepCheck, date]);

  // handlers
  const handleTimeChange = (e) => {
    if (e.value) {
      setSleepTime(e.value);
    }
  };

  const handleSave = (bedTime, wakeupTime, sleepDurationDiff) => {
    if (isTodaysDate) {
      dispatch(Creators.addPlayerSleep({ bedTime, wakeupTime, duration: sleepDurationDiff }));
    }
  };

  const checkTodaysDate = () => {
    const todaysDate = moment().format('YYYY-MM-DD');

    if (date === todaysDate) {
      setIsTodaysDate(true);
    } else {
      setIsTodaysDate(false);
    }
  };

  useEffect(() => {
    checkTodaysDate();
  }, [reloadTodaysSleepCheck, date]);

  const sleepDurationAngle = sleepTime.split(',').map((value) => parseInt(value, 10));
  const sleepDurationDiff = (sleepDurationAngle[1] - sleepDurationAngle[0]) * 2;
  const sleepDurationHours = Math.floor(sleepDurationDiff / 60);
  const sleepDurationMinutes = sleepDurationDiff % 60;

  const bedAngleHour = Math.floor(sleepDurationAngle[0] / 30);
  const wakeupAngleHour = Math.floor(sleepDurationAngle[1] / 30);
  const bedTimeHour = time[bedAngleHour][0].toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
  const wakeupTimeHour = time[wakeupAngleHour][0].toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  });

  const bedTimeMinutes = ((sleepDurationAngle[0] % 30) * 2).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  });
  const wakeupTimeMinutes = ((sleepDurationAngle[1] % 30) * 2).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  });

  const bedTimePM = time[bedAngleHour][1];
  const wakeupTimeAM = time[wakeupAngleHour][1];

  const bedTime = `${bedTimeHour}:${bedTimeMinutes} ${bedTimePM}`;
  const wakeupTime = `${wakeupTimeHour}:${wakeupTimeMinutes} ${wakeupTimeAM}`;

  return (
    <MainContainer>
      {todaysSleepCheck && !todaysSleepCheck.id ? (
        <>
          <BedTimeContainer>
            <FlexContainer>
              <BedTimes style={{ borderRight: '1px solid #a3a9b733' }}>
                <Text>
                  <span>
                    <BedtimeIcon />
                  </span>
                  <p>Bed Time</p>
                </Text>
                <H1>{bedTime}</H1>
              </BedTimes>

              <BedTimes>
                <Text>
                  <span>
                    <WakeuptimeIcon />
                  </span>
                  <p>Wakeup Time</p>
                </Text>
                <H1>{wakeupTime}</H1>
              </BedTimes>
            </FlexContainer>
          </BedTimeContainer>
          <SleepDateWrapper>
            <SleepDate>{formattedDate}</SleepDate>
          </SleepDateWrapper>
          <SleepTrackContainer>
            <SliderContainer>
              <SleepSliderWrapper>
                <SleepSlider />
              </SleepSliderWrapper>
              <Twelve>12</Twelve>
              <Three>3</Three>
              <Six>6</Six>
              <Nine>9</Nine>
              <SleepDurationWrapper>
                <SleepDurationText>Sleep Duration:</SleepDurationText>
                <Duration>{`${sleepDurationHours} Hrs ${sleepDurationMinutes} Mins`}</Duration>
              </SleepDurationWrapper>
              <CircularSliderWrapper id='sleep-slider'>
                <CircularSliderComponent
                  min='0'
                  max='360'
                  step='3'
                  radius='140'
                  width='14'
                  sliderType='range'
                  startAngle='0'
                  endAngle='360'
                  update={(e) => handleTimeChange(e)}
                  value={`${sleepTime}`}
                  borderWidth='0'
                  handleSize='+3'
                  handleShape='round'
                  pathColor='#151124'
                  rangeColor='#9186AA'
                  showTooltip='false'
                />
              </CircularSliderWrapper>
            </SliderContainer>
            <ButtonContainer>
              <ButtonComponent
                buttonText='Save'
                autoWidth
                isButtonAction={isTodaysDate}
                buttonClick={() => handleSave(bedTime, wakeupTime, sleepDurationDiff)}
              />
            </ButtonContainer>
          </SleepTrackContainer>
        </>
      ) : (
        <SleepTrackSaved date={date} formattedDate={formattedDate} todaysSleepCheck={todaysSleepCheck} />
      )}
    </MainContainer>
  );
};

export default SleepTrack;
