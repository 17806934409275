/* eslint-disable no-console */
/* eslint-disable no-shadow */
/*
 * saga.js file for coach exercise view
 * Author:
 * 1. manikanta
 * @copyright of Panorbit Services LLP.
 */

import { put } from 'redux-saga/effects';
import { Creators } from './action';

import { WebService } from '../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../services/axios/endPoints';

export function* getCoachExerciseListSaga({ payload }) {
  const { page, count } = payload;

  try {
    yield put(Creators.getCoachExerciseListStart());
    const fullURL = yield `${apiEndPoints.createExercise}`;

    const response = yield WebService.get(fullURL, { params: { page, count } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      yield put(Creators.getCoachExerciseListSuccess({ exerciseList: data[0], maxPage: data[1], totalItems: data[2] }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getCoachExerciseListFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getCoachExerciseListFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getCoachExerciseListFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getCoachExerciseListFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getCoachExerciseListFailure(payload));
    }
  }
}

export function* setCoachExerciseSaga({ payload }) {
  const { data } = payload;

  try {
    yield put(Creators.setCoachExerciseStart());
    const fullURL = yield `${apiEndPoints.createExercise}`;
    let METHOD = 'POST';

    // eslint-disable-next-line no-undef
    const dataToApi = new FormData();
    dataToApi.append('title', data.title);
    dataToApi.append('category', data.category);
    dataToApi.append('bodyPart', data.bodyPart);
    dataToApi.append('equipment', data.equipment);
    dataToApi.append('videoType ', data.videoType);
    dataToApi.append('videoTitle', data.videoTitle || null);

    if (data.videoType === 'Upload Video' && data.videoFile.size) {
      dataToApi.append('videoFile', data.videoFile);
    } else {
      dataToApi.append('videoUrl', data.videoUrl);
    }

    if (data._id) {
      METHOD = 'PUT';
      dataToApi.append('_id', data._id);
    }

    const response = yield WebService({
      method: METHOD,
      url: fullURL,
      data: dataToApi
    });

    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.setCoachExerciseSuccess());
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.setCoachExerciseFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.setCoachExerciseFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.setCoachExerciseFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.setCoachExerciseFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.setCoachExerciseFailure(payload));
    }
  }
}

export function* deleteCoachExerciseSaga({ payload }) {
  const { id } = payload;

  try {
    yield put(Creators.deleteCoachExerciseStart());
    const fullURL = yield `${apiEndPoints.createExercise}`;

    const response = yield WebService({
      method: 'DELETE',
      url: fullURL,
      data: { _id: id }
    });

    if (response.status >= 200 && response.status < 300) {
      const { message } = response.data;
      yield put(Creators.deleteCoachExerciseSuccess({ message }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.deleteCoachExerciseFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.deleteCoachExerciseFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.deleteCoachExerciseFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.deleteCoachExerciseFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.deleteCoachExerciseFailure(payload));
    }
  }
}

export function* getExerciseDetailsSaga({ payload }) {
  const { exerciseId } = payload;

  try {
    yield put(Creators.getExerciseDetailsStart());
    const fullURL = yield `${apiEndPoints.exerciseDetail}`;

    const response = yield WebService.get(fullURL, { params: { _id: exerciseId } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      yield put(Creators.getExerciseDetailsSuccess({ exerciseDetails: data[0] }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getExerciseDetailsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getExerciseDetailsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getExerciseDetailsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getExerciseDetailsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getExerciseDetailsFailure(payload));
    }
  }
}

export function* searchCoachExerciseSaga({ payload }) {
  const { searchKey, count, page } = payload;

  try {
    yield put(Creators.searchCoachExerciseStart());
    const fullURL = yield `${apiEndPoints.exerciseSearch}`;

    const response = yield WebService.get(fullURL, { params: { page, count, searchKey } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(Creators.searchCoachExerciseSuccess({ exerciseList: data[0], totalItems: data[2].totalCount }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.searchCoachExerciseFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.searchCoachExerciseFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.searchCoachExerciseFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.searchCoachExerciseFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.searchCoachExerciseFailure(payload));
    }
  }
}

export function* searchExerciseSaga({ payload }) {
  const { searchKey } = payload;

  try {
    yield put(Creators.searchCoachExerciseStart());
    const fullURL = yield `${apiEndPoints.searchExerciseList}`;

    const response = yield WebService.get(fullURL, { params: { searchKey } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(Creators.searchExerciseSuccess({ exerciseList: data }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.searchCoachExerciseFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.searchCoachExerciseFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.searchCoachExerciseFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.searchCoachExerciseFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.searchCoachExerciseFailure(payload));
    }
  }
}
