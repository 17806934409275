import styled from 'styled-components';
import { backgroundColor, fontSize, textColors } from '../../constants';

export const Container = styled.div`
  height: 95px;
  width: 100%;
  background-color: ${backgroundColor.card};
  border-radius: 6px;
  padding-left: 2.5rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const H1 = styled.h1`
  font-size: 24px;
`;

export const P = styled.p`
  font-size: ${fontSize.medium};
  color: ${(props) => (props.light ? `${textColors.textLight}` : `${textColors.textWhite}`)};
`;
