/* eslint-disable react/jsx-boolean-value */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as MealCreators } from '../MealPlansView/store';

import { TabsComponenet, ButtonComponent, SearchBoxComponent, LoadingComponent } from '../../../globalShared';

// creators
import { Creators } from './store';
import { Creators as MealPlannerCreators } from '../MealPlannerView/store';

// styles
import { Container, ActionsContainer } from './NutritionStyles';
import { FeedContainer, FlexContainer } from '../../../globalShared/CommonStyles';

// components
import MealPlansView from '../MealPlansView/MealPlansView';
import MealPlanDetailsModal from '../MealPlansView/MealPlanDetailsModal';
import CreateMealPlanModal from '../MealPlansView/CreateMealPlanModal';
import MealPlannerView from '../MealPlannerView/MealPlannerView';
import MealRecentView from '../MealRecentView/MealRecentView';

/* component */

const NutritionView = ({ history }) => {
  const [activeTab, setactiveTab] = useState();

  const mealPlanId = useSelector((state) => state.coachMealPlan.mealPlanId);
  const mealPlanList = useSelector((state) => state.coachMealPlan.mealPlanList);

  const filteredMealPlan = mealPlanList.filter((plan) => plan._id === mealPlanId);

  const showCreateMealPlan = useSelector((state) => state.coachMealPlan.showCreateMealPlan);
  const showMealDetailsModal = useSelector((state) => state.coachMealPlan.showMealDetailsModal);
  const { tab } = useSelector((state) => state.nutrition);

  // Loaders
  const plannerLoading = useSelector((state) => state.coachMealPlanner.loading);
  const mealFeedLoading = useSelector((state) => state.coachMealRecent.loading);
  const planLoading = useSelector((state) => state.coachMealPlan.loading);

  const dispatch = useDispatch();

  const handleTabChange = (label) => {
    setactiveTab(label);
  };

  const handleMealPlanSearch = (searchKey) => {
    dispatch(MealCreators.setMealPlanSearchKey({ searchKey }));
  };

  const handleMealPlannerSearch = (searchKey) => {
    dispatch(MealPlannerCreators.setMealPlannerSearchKey({ searchKey }));
  };

  useEffect(() => {
    if (tab) {
      handleTabChange(tab);
    } else {
      handleTabChange('Recent');
    }
  }, [tab]);

  useEffect(
    () => () => {
      dispatch(Creators.setActiveTab({ tab: null }));
    },
    []
  );

  return (
    <>
      {(plannerLoading || planLoading || mealFeedLoading) && <LoadingComponent />}

      <Container>
        <ActionsContainer>
          {activeTab === 'Meal Plans' && (
            <FlexContainer>
              <SearchBoxComponent placeHolder='Search Meal Plan' onSearchChange={handleMealPlanSearch} />
              <ButtonComponent
                buttonText='Create Meal Plan'
                isButtonAction={true}
                buttonClick={() => dispatch(MealCreators.setShowCreateMealPlanModal({ show: true }))}
                marginLeft={true}
                margin={false}
              />
            </FlexContainer>
          )}
          {activeTab === 'Planner' && (
            <FlexContainer>
              <SearchBoxComponent placeHolder='Search Player Planner' onSearchChange={handleMealPlannerSearch} />
            </FlexContainer>
          )}
        </ActionsContainer>
      </Container>
      <TabsComponenet getactiveTab={handleTabChange} tab={activeTab}>
        <div label='Recent'>
          <FeedContainer style={{ height: 'calc(100vh - 162px)' }}>
            <MealRecentView />
          </FeedContainer>
        </div>

        <div label='Planner'>
          <MealPlannerView history={history} />
        </div>

        <div label='Meal Plans'>
          <MealPlansView />
        </div>
      </TabsComponenet>
      {showMealDetailsModal && <MealPlanDetailsModal filteredMealPlan={filteredMealPlan} />}
      {showCreateMealPlan && <CreateMealPlanModal mealPlan={filteredMealPlan[0]} />}
    </>
  );
};

export default NutritionView;
