import React from 'react';
import { Redirect } from 'react-router-dom';

const LoginRedirect = (props) => {
  const { userType, path, passwordChanged, metaUpdated } = props;

  let redirectTo = path;

  console.log(window.innerWidth);
  if (!passwordChanged) {
    redirectTo = '/createpassword';
  } else if (!metaUpdated) {
    redirectTo = '/userdetails';
  }

  if (path === '/' && userType === 'Player') {
    redirectTo = '/player/';
  }
  if (path === '/' && userType === 'Admin') {
    redirectTo = '/admin/';
  }

  if (path === '/' && userType === 'Coach') {
    redirectTo = '/coach/';
  }

  if (window.innerWidth < 1000) {
    redirectTo = '/usedesktop/';
  }

  if (userType) {
    return <Redirect to={redirectTo} />;
  }

  return null;
};

export default LoginRedirect;
