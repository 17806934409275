import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { AccordionContent, MoodTimeWrapper, MoodTime, Thought, NoEntries } from './AccordionStyles';

import { timeSince } from '../../../services/utility';
import MoodIcon from '../MoodIcon';
import { localString } from '../../../services/locallization/localStrings';

const Accordion = (props) => {
  const { dayStat } = props;
  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  return (
    <AccordionContent>
      {dayStat.length ? (
        dayStat.map((item) => {
          const { id, name, time, thought } = item;
          const moodDate = timeSince(time);

          return (
            <Fragment key={id}>
              <MoodTimeWrapper>
                <MoodIcon type={name} />

                <MoodTime>{moodDate}</MoodTime>
              </MoodTimeWrapper>
              <Thought>{thought}</Thought>
            </Fragment>
          );
        })
      ) : (
        <NoEntries>{string.noEntries}</NoEntries>
      )}
    </AccordionContent>
  );
};

export default Accordion;
