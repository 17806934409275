/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from './store';

import { CardContainer, CardsContainer } from './HomeStyles';

import { MasonryLayout } from '../../../globalShared';
import Card from '../../../shared/components/ChartCardsComponent';

import TeamReadiness from './TeamReadiness';
import TeamStrengthConditioning from './TeamStrengthConditioning';
import SleepQuality from '../../Player/HomeView/SleepQuality';
import NutritionAdherence from './TeamNutrition';
import Hydration from '../../Player/HomeView/Hydration';
// import TotalStillness from './TotalStillness';
import TeamStillness from './TeamStillness';

const options = [
  { label: 'Weekly', value: 7, dataType: 'days' },
  { label: 'Monthly', value: 30, dataType: 'days' },
  { label: '6 Months', value: 180, dataType: 'month' }
];

const options2 = [
  { label: 'Weekly', value: 'weeks' },
  { label: 'Monthly', value: 'months' }
];

const HomeView = () => {
  const dispatch = useDispatch();

  const [readiness, setReadiness] = useState(options2[0]);
  const [strength, setStrength] = useState(options2[0]);
  const [sleep, setSleep] = useState(options[0]);
  const [nutrition, setNutrition] = useState({ label: 'Weekly', value: 7 });
  const [hydrate, setHydrate] = useState(options[0]);
  const [stillness, setStillness] = useState(options[0]);

  const _Readiness = useSelector((state) => state.coachHome.readiness);
  const _Strength = useSelector((state) => state.coachHome.strength);
  const { hydrationStats, stillnessStats, sleepStats, nutritionStats } = useSelector((state) => state.coachHome);

  useEffect(() => {
    dispatch(Creators.getCoachHomeReadiness({ dataType: readiness.value }));
  }, [readiness]);

  useEffect(() => {
    dispatch(Creators.getCoachHomeStrength({ dataType: strength.value }));
  }, [strength]);

  useEffect(() => {
    // makes an api call on changing the sleep
    const sleepDatatype = 'days';
    const sleepDays = sleep.value;
    dispatch(Creators.getCoachHomeSleep({ dataType: sleepDatatype, days: sleepDays }));
  }, [sleep]);

  useEffect(() => {
    //  makes an api call on changing the nutrition
    const nutritionDatatype = 'days';
    const nutritionDays = nutrition.value;
    dispatch(Creators.getCoachHomeNutrition({ dataType: nutritionDatatype, days: nutritionDays }));
  }, [nutrition]);

  useEffect(() => {
    //  makes an api call on changing the hydration
    const hydrateDatatype = hydrate.dataType;
    const hydrateDays = hydrate.value;
    dispatch(Creators.getCoachHomeHydration({ dataType: hydrateDatatype, days: hydrateDays }));
  }, [hydrate]);

  useEffect(() => {
    //  makes an api call on changing the stillness
    const stillnessDatatype = stillness.dataType;
    const stillnessDays = stillness.value;
    dispatch(Creators.getCoachHomeStillness({ dataType: stillnessDatatype, days: stillnessDays }));
  }, [stillness]);

  return (
    <>
      <CardsContainer>
        <MasonryLayout columns={3} gap={10}>
          <CardContainer>
            <Card
              title='Team Readiness'
              optionArray={options2}
              selected={readiness}
              onClick={(value) => setReadiness(value)}
            >
              <TeamReadiness {..._Readiness} />
            </Card>
          </CardContainer>

          <CardContainer>
            <Card
              title='Team Strength & Conditioning'
              optionArray={options2}
              selected={strength}
              onClick={(value) => setStrength(value)}
            >
              <TeamStrengthConditioning {..._Strength} />
            </Card>
          </CardContainer>

          <CardContainer>
            <Card
              title='Quality of Sleep'
              label='Average Sleep'
              border='#21d0b39c'
              bgc='#21d0b357'
              showLabel={true}
              optionArray={options}
              selected={sleep}
              onClick={(value) => setSleep(value)}
            >
              <SleepQuality sleepStats={sleepStats} days={sleep.value} type={sleep.dataType} />
            </Card>
          </CardContainer>

          <CardContainer>
            <Card
              label='Average Calories Taken (Kcal)'
              border='#21d0b39c'
              bgc='#21d0b357'
              showLabel={true}
              title='Nutrition Adherence'
              optionArray={options}
              selected={nutrition}
              onClick={(value) => setNutrition(value)}
            >
              <NutritionAdherence data={nutrition} stats={nutritionStats} />
            </Card>
          </CardContainer>

          <CardContainer>
            <Card
              label='Average Water Intakes (in L)'
              border='#21d0b39c'
              bgc='#21d0b357'
              showLabel={true}
              title='Hydration'
              optionArray={options}
              selected={hydrate}
              onClick={(value) => setHydrate(value)}
            >
              <Hydration Hydrate={hydrate} hydrationStats={hydrationStats} />
            </Card>
          </CardContainer>

          <CardContainer>
            <Card
              label='Total Meditation (in Mins)'
              border='#70C54D'
              bgc='#6fc54d52'
              showLabel={true}
              title='Total Stillness'
              optionArray={options}
              selected={stillness}
              onClick={(value) => setStillness(value)}
            >
              <TeamStillness data={stillness} stats={stillnessStats} />
            </Card>
          </CardContainer>
        </MasonryLayout>
      </CardsContainer>
    </>
  );
};

export default HomeView;
