import styled from 'styled-components';

import { backgroundColor, fontSize, fontWeight, borderColor } from '../../../shared/constants';

export const Modal = styled.div`
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  right: 0px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${backgroundColor.popupBg};
  z-index: 99;
  overflow-y: auto;
`;

export const Card = styled.div`
  // width: 340px;

  background-color: ${backgroundColor.popup};
  border-radius: 10px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 35px;
  border-bottom: 1px solid ${borderColor.light};
`;

export const CardHeader = styled.h2`
  font-size: ${fontSize.titleVerySmall};
  font-weight: ${fontWeight.medium};
`;

export const CloseButton = styled.div`
  z-index: 999;
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const MeditationPlayerContainer = styled.div`
  position: relative;
  // height: 558px;
`;

export const TopBgContainer = styled.div`
  // position: absolute;
  // top: 0;
`;

export const PlayerContainer = styled.div`
  height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BottomBgContainer = styled.div`
  // position: absolute;
  // bottom: 0;
`;
