/* eslint-disable no-console */
import { put } from 'redux-saga/effects';
import { Creators } from './action';

import { WebService } from '../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../services/axios/endPoints';

export function* getPlayerDashboardReadinessSaga({ payload }) {
  const { userId, dataType } = payload;

  try {
    yield put(Creators.getPlayerDashboardReadinessStart());

    const fullURL = yield `${apiEndPoints.playerDashBoardReadiness}`;
    const response = yield WebService.get(fullURL, { params: { userId, dataType } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(
        Creators.getPlayerDashboardReadinessSuccess({ readiness: { current: data[0] || [], previous: data[1] || [] } })
      );
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getPlayerDashboardReadinessFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getPlayerDashboardReadinessFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getPlayerDashboardReadinessFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getPlayerDashboardReadinessFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getPlayerDashboardReadinessFailure(payload));
    }
  }
}

export function* getPlayerDashboardStrengthSaga({ payload }) {
  const { userId, dataType } = payload;

  try {
    yield put(Creators.getPlayerDashboardStrengthStart());

    const fullURL = yield `${apiEndPoints.playerDashBoardStrength}`;
    const response = yield WebService.get(fullURL, { params: { userId, dataType } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      yield put(
        Creators.getPlayerDashboardStrengthSuccess({ strength: { current: data[0] || [], previous: data[1] || [] } })
      );
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getPlayerDashboardStrengthFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getPlayerDashboardStrengthFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getPlayerDashboardStrengthFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getPlayerDashboardStrengthFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getPlayerDashboardStrengthFailure(payload));
    }
  }
}

export function* getPlayerDashboardSleepStatsSaga({ payload }) {
  const { page, count, userId, dataType, days } = payload;

  try {
    yield put(Creators.getPlayerDashboardSleepStatsStart());

    const fullURL = yield `${apiEndPoints.listSleep}`;
    const response = yield WebService.get(fullURL, { params: { page, count, userId, dataType, days } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(Creators.getPlayerDashboardSleepStatsSuccess({ sleepStats: data[0] || [] }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getPlayerDashboardSleepStatsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getPlayerDashboardSleepStatsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getPlayerDashboardSleepStatsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getPlayerDashboardSleepStatsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getPlayerDashboardSleepStatsFailure(payload));
    }
  }
}

export function* getPlayerDashboardNutritionSaga({ payload }) {
  const { userId, dataType, days } = payload;

  try {
    yield put(Creators.getPlayerDashboardNutritionStart());

    const fullURL = yield `${apiEndPoints.playerDashBoardNutrition}`;
    const response = yield WebService.get(fullURL, { params: { userId, dataType, days } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(Creators.getPlayerDashboardNutritionSuccess({ nutritionStats: data }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getPlayerDashboardNutritionFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getPlayerDashboardNutritionFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getPlayerDashboardNutritionFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getPlayerDashboardNutritionFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getPlayerDashboardNutritionFailure(payload));
    }
  }
}

export function* getPlayerDashboardHydrationSaga({ payload }) {
  const { userId, dataType, days } = payload;

  try {
    yield put(Creators.getPlayerDashboardHydrationStart());

    const fullURL = yield `${apiEndPoints.playerDashBoardHydration}`;
    const response = yield WebService.get(fullURL, { params: { userId, dataType, days } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(Creators.getPlayerDashboardHydrationSuccess({ hydrationStats: data }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getPlayerDashboardHydrationFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getPlayerDashboardHydrationFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getPlayerDashboardHydrationFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getPlayerDashboardHydrationFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getPlayerDashboardHydrationFailure(payload));
    }
  }
}

export function* getPlayerDashboardMoodStats({ payload }) {
  const { userId, days } = payload;

  try {
    yield put(Creators.getPlayerDashboardMoodStatsStart());

    const fullURL = yield `${apiEndPoints.moodStats}`;
    const response = yield WebService.get(fullURL, { params: { userId, days } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(
        Creators.getPlayerDashboardMoodStatsSuccess({
          moodStats: data[1] ? data[1].moodDict : { level1: 0, level2: 0, level3: 0, level4: 0, level5: 0, level6: 0 }
        })
      );
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getPlayerDashboardMoodStatsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getPlayerDashboardMoodStatsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getPlayerDashboardMoodStatsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getPlayerDashboardMoodStatsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getPlayerDashboardMoodStatsFailure(payload));
    }
  }
}
