import styled from 'styled-components';
import { backgroundColor, fontSize, fontWeight } from '../../constants';

export const Container = styled.div`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${backgroundColor.popupBg};
`;

export const CardContainer = styled.div`
  min-height: 250px;
  min-width: 500px;
  border-radius: 10px;

  padding: 3rem;
  position: relative;

  background-color: ${backgroundColor.popup};

  z-index: 100;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-size: calc(${fontSize.titleVerySmall} - 0.1rem);
  font-weight: ${fontWeight.bold};
`;

export const CloseButton = styled.div`
  cursor: pointer;
`;

export const Body = styled.div`
  padding: 30px 0;

  & > h1 {
    font-size: 20px;
    font-weight: ${fontWeight.medium};
    margin-bottom: 10px;
    text-transform: capitalize;
  }

  & > h2 {
    font-size: 16px;
    font-weight: ${fontWeight.light};
    opacity: 30%;
    text-transform: capitalize;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
