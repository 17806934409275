/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useForm, useFieldArray } from 'react-hook-form';

import { Creators } from './store';

import {
  Container,
  AddUserContainer,
  CloseButton,
  ControllerContainer,
  Title,
  FlexContainer,
  Row,
  FormHeaderTitle,
  InputContainer,
  FormRow,
  FormRowIndex,
  FormRowDelete,
  FormScrollContainer,
  AddMoreButton,
  Footer,
  UploadFileContainer,
  FileName,
  ReUpload,
  DropdownContainer,
  FormContainer,
  Link
} from './Users.styles';

// components
import { ButtonComponent, ControllComponent, NestedDropdown, DragAndDrop } from '../../../globalShared';
import { FormInput } from '../../../shared/components/FormComponents';
// icons
import { ReactComponent as CloseIcon } from '../../../shared/assets/Close.svg';
import { ReactComponent as DeleteIcon } from '../../../shared/assets/delete.svg';
import { ValdErrMsg } from '../../../globalShared/CommonStyles';
import { localString } from '../../../services/locallization/localStrings';

// constants
const templateUrl = 'https://weshare-now-demo.s3.ap-south-1.amazonaws.com/weshare/media/Book+6.xlsx';
const userTypesArray = [
  { value: 'Admin', label: 'Admin', nested: false },
  {
    value: 'Coach',
    label: 'Coach',
    nested: false
  },
  {
    value: 'Player',
    label: 'Player',
    nested: true,
    nestedOptions: [
      { nestedValue: 'Batsman' },
      { nestedValue: 'All Rounder' },
      { nestedValue: 'Bowler' },
      { nestedValue: 'Wicket Keeper' }
    ]
  }
];

const AddUserModal = (props) => {
  const { onCancel } = props;

  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  const dispatch = useDispatch();
  const [state, setState] = useState(string.users);
  const [csvFile, setcsvFile] = useState(null);
  const [csvError, setCsvError] = useState(null);

  const formFields = { playerType: '', userType: '', firstName: '', lastName: '', email: '' };
  const [formArray, setFormArray] = useState([formFields]);
  const [errors, setErrors] = useState([formFields]);

  // form error validations starts here

  // func to check the duplicte values of filelds
  const findDuplicates = (control) => {
    const valueArr = formArray.map((item) => item[control]);
    const errs = [...errors];
    // eslint-disable-next-line array-callback-return
    valueArr.some((item, idx) => {
      const isDup = valueArr.indexOf(item) !== idx;
      if (isDup && item) {
        errs[idx] = { ...errs[idx], [control]: string.valueDuplicated };
      } else {
        errs[idx] = { ...errs[idx], [control]: '' };
      }
      setErrors(errs);
    });
  };

  useEffect(() => {
    findDuplicates('email');
  }, [formArray]);

  const validateForm = () => {
    let IsValid = true;
    const errs = [...errors];

    // eslint-disable-next-line array-callback-return
    formArray.map((item, i) => {
      const { firstName, lastName, userType, email } = item;

      if (!firstName) {
        IsValid = false;
        errs[i] = { ...errs[i], firstName: string.required };
        setErrors(errs);
      }

      if (!lastName) {
        IsValid = false;
        errs[i] = { ...errs[i], lastName: string.required };
        setErrors(errs);
      }

      if (!userType) {
        IsValid = false;
        errs[i].userType = string.required;
        errs[i] = { ...errs[i], userType: string.required };
        setErrors(errs);
      }

      if (!email) {
        IsValid = false;
        errs[i] = { ...errs[i], email: string.required };
        setErrors(errs);
      } else if (!email.match(/.+@.+\../)) {
        IsValid = false;
        errs[i] = { ...errs[i], email: string.invalidEmail };
        setErrors(errs);
      } else if (errs[i].email) {
        IsValid = false;
      }
    });

    return IsValid;
  };

  // form error validations ends here

  const updateControlState = (state) => setState(state);

  const dataSubmit = () => {
    if (validateForm()) {
      dispatch(Creators.setAdminUsersList({ addNewUsers: formArray }));
      onCancel(false);
    }
  };

  const handleOnDrop = (file) => {
    if (file) {
      setcsvFile(file);
      setCsvError(null);
    }
  };

  const uploadBulkUsers = () => {
    if (csvFile) {
      dispatch(Creators.setAdminBulkUsersList({ csvFile }));
      setcsvFile(null);
      onCancel(false);
    } else {
      setCsvError(string.uploadAValidFile);
    }
  };

  const remove = (index) => {
    const newArry = formArray.filter((_, i) => i !== index);
    const errs = errors.filter((_, i) => i !== index);
    setFormArray(newArry);
    setErrors(errs);
  };

  const append = () => {
    const newArry = [...formArray, formFields];
    const errs = [...errors, formFields];

    setFormArray(newArry);
    setErrors(errs);
  };

  const reset = () => {
    setFormArray([formFields]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (state === string.users) {
      dataSubmit();
    } else {
      uploadBulkUsers();
    }
  };

  const handleUserType = ({ value, nested }, index) => {
    const newArry = [...formArray];
    const errs = [...errors];

    newArry[index] = { ...newArry[index], userType: value, playerType: nested };
    setFormArray(newArry);

    // validation
    if (!value) {
      errs[index] = { ...errs[index], userType: string.required };
    } else {
      errs[index] = { ...errs[index], userType: '' };
    }
    setErrors(errs);
  };

  const handleChange = (value, index, control) => {
    const newArry = [...formArray];
    const errs = [...errors];

    newArry[index] = { ...newArry[index], [control]: value };
    setFormArray(newArry);

    // validation
    if (!value) {
      errs[index] = { ...errs[index], [control]: string.required };
    } else {
      errs[index] = { ...errs[index], [control]: '' };
    }
    setErrors(errs);
  };

  const handleCancel = () => {
    reset();
    setcsvFile(null);
    onCancel(false);
  };

  return (
    <Container>
      <CloseButton type='button' onClick={handleCancel}>
        <CloseIcon />
      </CloseButton>
      <AddUserContainer>
        <form onSubmit={handleSubmit}>
          <FormContainer>
            <Title>Add User</Title>
            <FlexContainer style={{ justifyContent: 'space-between' }}>
              <ControllerContainer>
                <ControllComponent
                  controlState={state}
                  updateControlState={updateControlState}
                  firstItemName={string.users}
                  SecondItemName={string.uploadCSV}
                />
              </ControllerContainer>

              {state !== 'Users' && (
                <Link href={templateUrl} target='_blank'>
                  Sample Template
                </Link>
              )}
            </FlexContainer>

            {state === string.users ? (
              <div>
                <FlexContainer style={{ padding: '0 3rem' }}>
                  <Row>
                    <FormHeaderTitle>{string.typeofUser}</FormHeaderTitle>
                    <FormHeaderTitle>{string.firstName}</FormHeaderTitle>
                    <FormHeaderTitle>{string.lastName}</FormHeaderTitle>
                    <FormHeaderTitle>{string.email}</FormHeaderTitle>
                  </Row>
                </FlexContainer>
                <FormScrollContainer>
                  {formArray.map((item, index) => {
                    const { playerType, userType, firstName, lastName, email } = item;
                    return (
                      <FlexContainer key={index}>
                        <FormRowIndex>{index + 1}</FormRowIndex>
                        <FormRow>
                          <DropdownContainer>
                            <NestedDropdown
                              dropDownArray={userTypesArray}
                              value={userType}
                              nestedValue={playerType}
                              setValue={(value) => handleUserType(value, index)}
                            />
                            <ValdErrMsg style={{ margin: '0' }}>{errors[index].userType}</ValdErrMsg>
                          </DropdownContainer>
                          <InputContainer>
                            <FormInput
                              placeholder={string.enterPersonsFirstName}
                              setValue={(value) => handleChange(value, index, 'firstName')}
                              value={firstName}
                              showLable={false}
                            />
                            <ValdErrMsg>{errors[index].firstName}</ValdErrMsg>
                          </InputContainer>
                          <InputContainer>
                            <FormInput
                              placeholder={string.enterPersonsSecondName}
                              setValue={(value) => handleChange(value, index, 'lastName')}
                              value={lastName}
                              showLable={false}
                            />
                            <ValdErrMsg>{errors[index].lastName}</ValdErrMsg>
                          </InputContainer>
                          <InputContainer>
                            <FormInput
                              placeholder={string.enterPersonsEmail}
                              setValue={(value) => handleChange(value, index, 'email')}
                              value={email}
                              showLable={false}
                              type='email'
                            />
                            <ValdErrMsg>{errors[index].email}</ValdErrMsg>
                          </InputContainer>
                        </FormRow>
                        <FormRowDelete onClick={() => remove(index)}>
                          <DeleteIcon />
                        </FormRowDelete>
                      </FlexContainer>
                    );
                  })}
                  <AddMoreButton>
                    <button
                      type='button'
                      onClick={() => {
                        append();
                      }}
                    >
                      {`+ ${string.addMore}`}
                    </button>
                  </AddMoreButton>
                </FormScrollContainer>
              </div>
            ) : (
              <>
                <UploadFileContainer>
                  {csvFile ? (
                    <>
                      <FileName>
                        {string.file}
                        <div>{csvFile.name}</div>
                      </FileName>
                      <ReUpload onClick={() => setcsvFile(null)}>{string.reUpload}</ReUpload>
                    </>
                  ) : (
                    <DragAndDrop onDrop={handleOnDrop} />
                  )}
                </UploadFileContainer>
                <ValdErrMsg style={{ marginTop: '5px' }}>{csvError}</ValdErrMsg>
              </>
            )}
          </FormContainer>
          <Footer>
            <ButtonComponent
              buttonText={string.cancel}
              isButtonAction={true}
              buttonClick={handleCancel}
              bgType='dark'
            />

            <ButtonComponent buttonText={string.add} buttonType='submit' />
          </Footer>
        </form>
      </AddUserContainer>
    </Container>
  );
};

export default AddUserModal;
