import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  readinessloading: false,
  strengthloading: false,
  error: null,
  sleepStats: [],
  nutritionStats: [],
  hydrationStats: [],
  moodStats: '',
  reload: false,
  success: false,
  readiness: null,
  strength: null
};

const getPlayerDashboardReadinessStart = (state = INITIAL_STATE) => ({ ...state, error: null, readinessloading: true });

const getPlayerDashboardReadinessSuccess = (state = INITIAL_STATE, action) => {
  const { readiness } = action.payload;

  return {
    ...state,
    readinessloading: false,
    readiness
  };
};

const getPlayerDashboardReadinessFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    readinessloading: false,
    error
  };
};

const getPlayerDashboardStrengthStart = (state = INITIAL_STATE) => ({ ...state, error: null, strengthloading: true });

const getPlayerDashboardStrengthSuccess = (state = INITIAL_STATE, action) => {
  const { strength } = action.payload;

  return {
    ...state,
    strengthloading: false,
    reload: false,
    strength
  };
};

const getPlayerDashboardStrengthFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    strengthloading: false,
    error
  };
};

const getPlayerDashboardSleepStatsStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getPlayerDashboardSleepStatsSuccess = (state = INITIAL_STATE, action) => {
  const { sleepStats, avgSleepDuration } = action.payload;

  return {
    ...state,
    loading: false,
    reload: false,
    sleepStats,
    avgSleepDuration
  };
};

const getPlayerDashboardSleepStatsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const getPlayerDashboardNutritionStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getPlayerDashboardNutritionSuccess = (state = INITIAL_STATE, action) => {
  const { nutritionStats } = action.payload;

  return {
    ...state,
    loading: false,
    reload: false,
    nutritionStats
  };
};

const getPlayerDashboardNutritionFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const getPlayerDashboardHydrationStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getPlayerDashboardHydrationSuccess = (state = INITIAL_STATE, action) => {
  const { hydrationStats } = action.payload;

  return {
    ...state,
    loading: false,
    reload: false,
    hydrationStats
  };
};

const getPlayerDashboardHydrationFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const getPlayerDashboardMoodStatsStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getPlayerDashboardMoodStatsSuccess = (state = INITIAL_STATE, action) => {
  const { moodStats } = action.payload;

  return {
    ...state,
    loading: false,
    reload: false,
    moodStats
  };
};

const getPlayerDashboardMoodStatsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const HANDLERS = {
  [Types.GET_PLAYER_DASHBOARD_READINESS_START]: getPlayerDashboardReadinessStart,
  [Types.GET_PLAYER_DASHBOARD_READINESS_SUCCESS]: getPlayerDashboardReadinessSuccess,
  [Types.GET_PLAYER_DASHBOARD_READINESS_FAILURE]: getPlayerDashboardReadinessFailure,

  [Types.GET_PLAYER_DASHBOARD_STRENGTH_START]: getPlayerDashboardStrengthStart,
  [Types.GET_PLAYER_DASHBOARD_STRENGTH_SUCCESS]: getPlayerDashboardStrengthSuccess,
  [Types.GET_PLAYER_DASHBOARD_STRENGTH_FAILURE]: getPlayerDashboardStrengthFailure,

  [Types.GET_PLAYER_DASHBOARD_SLEEP_STATS_START]: getPlayerDashboardSleepStatsStart,
  [Types.GET_PLAYER_DASHBOARD_SLEEP_STATS_SUCCESS]: getPlayerDashboardSleepStatsSuccess,
  [Types.GET_PLAYER_DASHBOARD_SLEEP_STATS_FAILURE]: getPlayerDashboardSleepStatsFailure,

  [Types.GET_PLAYER_DASHBOARD_NUTRITION_START]: getPlayerDashboardNutritionStart,
  [Types.GET_PLAYER_DASHBOARD_NUTRITION_SUCCESS]: getPlayerDashboardNutritionSuccess,
  [Types.GET_PLAYER_DASHBOARD_NUTRITION_FAILURE]: getPlayerDashboardNutritionFailure,

  [Types.GET_PLAYER_DASHBOARD_HYDRATION_START]: getPlayerDashboardHydrationStart,
  [Types.GET_PLAYER_DASHBOARD_HYDRATION_SUCCESS]: getPlayerDashboardHydrationSuccess,
  [Types.GET_PLAYER_DASHBOARD_HYDRATION_FAILURE]: getPlayerDashboardHydrationFailure,

  [Types.GET_PLAYER_DASHBOARD_MOOD_STATS_START]: getPlayerDashboardMoodStatsStart,
  [Types.GET_PLAYER_DASHBOARD_MOOD_STATS_SUCCESS]: getPlayerDashboardMoodStatsSuccess,
  [Types.GET_PLAYER_DASHBOARD_MOOD_STATS_FAILURE]: getPlayerDashboardMoodStatsFailure
};

export const playerDashboardReducer = createReducer(INITIAL_STATE, HANDLERS);
