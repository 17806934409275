import styled from 'styled-components';
import { borderColor, textColors } from '../../constants';

export const Container = styled.div`
  height: 100%;
  width: 100%;

  border-radius: 6px;
  background: #4e436926;

  position: relative;
`;

export const CommentsContainer = styled.div`
  width: 25%;
  height: 485px;
  border-radius: 6px;
  background: #4e436926;
  padding: 1rem;
`;

export const CommentsHeader = styled.div`
  padding: 2rem;
  font-size: 16px;
  border-bottom: 0.5px solid ${borderColor.light};
  color: ${textColors.textLight};
`;

export const Footer = styled.div`
  // position: absolute;
  // bottom: 0;
  width: 100%;
  padding: 0.9rem;
`;

export const CommentsScrollContainer = styled.div`
  height: calc(100% - 123px);
  width: 100%;

  padding: 1rem;
  overflow-y: auto;
`;
