import styled from 'styled-components';
import { FlexContainer } from '../../../globalShared/CommonStyles';
import {
  fontSize,
  fontWeight,
  accentColor,
  borderColor,
  textColors,
  shadow,
  backgroundColor
} from '../../../shared/constants';

export const Container = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: 2rem 0;
`;

export const Card = styled.div`
  width: 100%;
  background: #261d3c;
  border-radius: 6px;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 1.5rem;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;

  border-bottom: 1px solid ${borderColor.light};
`;

export const OpenModal = styled.p`
  font-size: ${fontSize.mediumDefault};
  color: ${accentColor.primary};
`;

export const PlayerTitle = styled.h1`
  font-size: ${fontSize.mediumDefault};
  font-weight: ${fontWeight.medium};

  margin: 0 1rem;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

export const MealTakenAT = styled.p`
  font-size: ${fontSize.smallDefault};
  font-weight: ${fontWeight.medium};
  color: ${textColors.textLight};
  margin: 0.5rem 0rem;
`;

export const MealDetails = styled(FlexContainer)`
  background: ${backgroundColor.cardItem};
  padding: 1.6rem;
  border-radius: 6px;
  box-shadow: 0px 10px 10px ${shadow.dark};
  margin-bottom: 1.2rem;
`;

export const Circle = styled.img`
  height: 40px;
  width: 40px;
  margin-right: 1.2rem;
`;

export const Meal = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.5rem;

  & > span {
    font-size: 14px;
    color: ${textColors.textLight};
  }

  & > span:first-child > p {
    font-size: ${fontSize.mediumDefault};
    display: inline-block;
    padding: 0 0.4rem;
  }

  & > span:last-child > p:first-child {
    font-size: 14px;
    display: inline-block;
    color: ${textColors.textLight};
  }
`;

export const Value = styled.p`
  display: inline-block;
  font-size: ${fontSize.mediumDefault};
  padding-left: 0.7rem;
`;

export const MealTitle = styled.p`
  font-size: calc(${fontSize.mediumDefault} - 0.1rem);
  color: ${textColors.textLight};
  margin-right: 1rem;
  word-break: break-word;

  & > span {
    font-size: ${fontSize.mediumDefault};
    font-weight: ${fontWeight.medium};
    padding: 0 0.5rem;
    color: ${textColors.textWhite};
  }
`;
