/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */

/*
 * saga.js file for otp view
 * Author:
 * 1. manikanta
 *
 * @copyright of Panorbit Services LLP.
 */

import { put } from 'redux-saga/effects';
import { Creators } from './action';
import { Creators as Login } from '../../LoginView/store';

import { WebService } from '../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../services/axios/endPoints';

import { setAccessTokenToHeader } from '../../../../services/axios/helper';
// import { setUserDataToLocal } from '../../../../services/cookies/helper';

export function* verifyOtpSaga({ payload }) {
  try {
    const { userMail, otp } = payload;

    // eslint-disable-next-line radix
    const parsOtp = parseInt(otp);

    yield put(Creators.verifyOtpStart());
    const fullURL = yield `${apiEndPoints.OtpValidation}`;

    const dataToApi = new FormData();

    dataToApi.append('email', userMail);
    dataToApi.append('otp', parsOtp);

    const response = yield WebService({
      method: 'POST',
      url: fullURL,
      data: dataToApi
    });

    if (response.status >= 200 && response.status < 300) {
      const { token } = response.data.data;

      // set access token to header
      yield setAccessTokenToHeader(token);

      yield put(Creators.verifyOtpSuccess());
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.verifyOtpFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.verifyOtpFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.verifyOtpFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.verifyOtpFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.verifyOtpFailure(payload));
    }
  }
}
