import React from 'react';
import styled from 'styled-components';
import { textColors } from '../../constants';
import './style.css';

const Container = styled.div`
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${textColors.textLight};
  background: transparent;
  z-index: 99999999999999999;
`;
const index = () => (
  <Container>
    <div className='loadingio-spinner-spinner-p8vbz1ud9p'>
      <div className='ldio-re072nt75n'>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  </Container>
);

export default index;
