/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
import styled, { css } from 'styled-components';
import { accentColor, backgroundColor, borderColor, fontSize, fontWeight, textColors } from '../../../shared/constants';

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;
`;

export const HeaderTxt = styled.h1`
  font-size: ${fontSize.titleVerySmall};
  font-weight: ${fontWeight.bold};
  margin: 0 1rem;
  text-transform: capitalize;
`;

export const P = styled.p`
  font-size: ${fontSize.medium};
  color: ${textColors.textLight};
`;

export const Preference = styled.p`
  font-size: ${fontSize.mediumDefault};
  color: ${textColors.textLight};
  padding: 0 0.5rem;
`;

export const AlergyButton = styled.div`
  font-size: ${fontSize.medium};
  color: ${textColors.textLight};

  border-bottom: 1px solid ${accentColor.primary};
  margin-left: 3rem;

  cursor: pointer;
`;

export const Container = styled.div`
  width: 100%;
`;

export const CalanderHeader = styled.div`
  font-size: 18px;
  font-weight: bold;

  width: 250px;
  margin-bottom: 1.5rem;
  padding: 1.5rem 1rem;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

export const Button = styled.div`
  cursor: pointer;
  & > svg {
    ${(props) =>
      props.prev &&
      css`
        transform: rotate(90deg);
      `}

    ${(props) =>
      props.next &&
      css`
        transform: rotate(-90deg);
      `}
  }
`;

export const Month = styled.div`
  font-size: 20px;
  color: ${textColors.light};
`;

export const Week = styled.div`
  width: 14%;
  font-size: 14px;
  margin-bottom: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Day = styled.div`
  width: 14%;
  height: 100px;

  border-radius: 0.6rem;
  background-color: ${(props) => (props.isToday ? '#21D0B333' : '#271f3b')};
  margin: 0.1%;
  padding: 1rem 1.5rem;

  display: flex;
  flex-direction: column;
  // align-items: ;
  justify-content: space-between;
  cursor: pointer;

  ${(props) =>
    props.currentMonth &&
    css`
      background-color: #4e436914;
      color: #21d0b3;
      width: 14%;
    `}
`;

export const DayHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 15px;

  & > p {
    color: ${(props) => (props.isactive ? '#21d0b3' : '#fff')};
    font-size: 14px;
  }

  & > div > svg {
    margin: 0 0.5rem;
  }
`;

export const SessionsContainer = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 1rem;
  cursor: pointer;

  /* Hide scrollbar */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const Meal = styled.p`
  font-size: 14px;
  word-break: break-word;
`;

export const ModalContainer = styled.div`
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  justify-content: flex-end;

  background-color: ${backgroundColor.popupBg};
  z-index: 9999999;
`;

export const ContentContainer = styled.div`
  height: 100%;
  width: 720px;

  background-color: ${backgroundColor.popup};
  border-radius: 6px 0px 0px 6px;

  overflow-y: auto;
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 730px;
  top: 18px;
  z-index: 999;
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const FormTitle = styled.h1`
  font-size: calc(${fontSize.titleVerySmall} - 0.1rem);
  margin-bottom: 1rem;
`;

export const SelectedDay = styled.h1`
  font-size: ${fontSize.mediumDefault};
  margin-bottom: 1rem;
`;

export const FormFooter = styled.div`
  padding: 1.5rem 0;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  right: 16px;
`;

export const DurationContainer = styled.div`
  width: 45%;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  padding-right: 1rem;
  border-right: 0.5px solid ${borderColor.light};
`;

export const TimeContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  width: 70%;
  padding-left: 1rem;
`;

export const FormField = styled.div`
  width: 100%;
  padding: 1rem;
  border: 1px solid ${borderColor.light};
  border-radius: 6px;
  margin-bottom: 2rem;
`;

export const Menu = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  height: 125px;
  width: 125px;
  background: #262130;
  box-shadow: 0px 5px 18px #0000002e;
  border-radius: 6px;
  z-index: 999;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  align-items: unset;
  flex-direction: column;
  justify-content: center;
`;

export const MenuItem = styled.div`
  padding: 1rem 2rem;
  font-size: 12px;
  cursor: pointer;
  color: ${(props) => (props.isActive ? '#fff' : `${textColors.textLight}`)};
`;

export const ScrollContainer = styled.div`
  height: 410px;
  padding: 1rem;
  overflow-y: auto;
`;

export const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Index = styled.p`
  font-size: ${fontSize.mediumDefault};
  margin-right: 1rem;
`;

export const WeekRow = styled.div`
  position: relative;
`;
export const Aside = styled.div`
  position: absolute;
  left: -30px;
  top: 40%;
  transform: rotate(-90deg);
  font-size: 12px;
  z-index: 999;
  color: ${textColors.textLight};
  display: flex;
  align-items: center;
`;
