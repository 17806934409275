import React, { Component } from 'react';
import { LogoHeaderComponent, LinkButtonComponenet } from '../../../globalShared';

import { ReactComponent as Success } from '../../../shared/assets/success.svg';

// styles
import { Container, FormContainer, Heading, SubHeading } from './PasswordSuccess.styles';

// text constants
const HeadingText = 'Password';
const SubHeadingText = 'Created Successfully';

class SuccessView extends Component {
  constructor() {
    super();
    this.state = {};
  }

  handleClick = () => {};

  render() {
    return (
      <>
        <LogoHeaderComponent />
        <Container>
          <FormContainer>
            <Success />
            <Heading>{HeadingText}</Heading>
            <SubHeading>{SubHeadingText}</SubHeading>
            <SubHeading>In the next step you are required to fill your details</SubHeading>
            <LinkButtonComponenet buttonText='Fill Details' path='/userdetails' bgType='primary' />
          </FormContainer>
        </Container>
      </>
    );
  }
}

export default SuccessView;
