import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  searchCoachMealPlanner: ['payload'],
  searchCoachMealPlannerStart: ['payload'],
  searchCoachMealPlannerSuccess: ['payload'],
  searchCoachMealPlannerFailure: ['payload'],

  getCoachMealPlannerList: ['payload'],
  getCoachMealPlannerListStart: ['payload'],
  getCoachMealPlannerListSuccess: ['payload'],
  getCoachMealPlannerListFailure: ['payload'],

  deleteCoachMealPlanner: ['payload'],
  deleteCoachMealPlannerStart: ['payload'],
  deleteCoachMealPlannerSuccess: ['payload'],
  deleteCoachMealPlannerFailure: ['payload'],

  resetMealPlanner: null,
  resetSuccess: null,
  resetError: null,
  setMealPlannerSearchKey: ['payload'],
  setMealPlannerPage: ['payload']
});

export { Types, Creators };
