/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import styled, { css } from 'styled-components';
import { textColors, accentColor, fontWeight, fontSize, backgroundColor } from '../../constants';

export const Container = styled.button`
  padding: 1.5rem 3rem;
  min-width: 125px;
  max-height: 50px;

  border: none;
  border-radius: 0.5rem;

  cursor: ${(props) => (props.isButtonAction ? 'pointer' : 'default')};

  text-align: center;

  font-size: ${fontSize.mediumDefault};
  color: ${textColors.textDark};
  text-transform: capitalize;
  font-weight: ${fontWeight.bold};

  opacity: ${(props) => (props.isButtonAction ? 1 : 0.4)};
  white-space: nowrap;

  ${(props) =>
    props.bgType === 'primary' &&
    css`
      background: transparent linear-gradient(108deg, ${accentColor.secondaryLite} 0%, ${accentColor.primaryDark} 100%)
        0% 0% no-repeat;
    `};

  ${(props) =>
    props.bgType === 'dark' &&
    css`
      background: #29223a;
      color: ${textColors.textWhite};
    `};

  ${(props) =>
    props.bgType === 'danger' &&
    css`
      background: ${backgroundColor.danger};
      color: ${textColors.textWhite};
    `};

  ${(props) =>
    props.autoWidth &&
    css`
      width: 100%;
    `};

  ${(props) =>
    props.margin &&
    css`
      margin: 0 1rem;
    `};

  ${(props) =>
    props.marginLeft &&
    css`
      margin-left: 1rem;
    `};

  ${(props) =>
    props.marginRight &&
    css`
      margin-right: 1rem;
    `};

  &:focus {
    outline: none;
  }
`;
