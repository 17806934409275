import styled from 'styled-components';
import { backgroundColor } from '../../constants';

export const Container = styled.div`
  height: 95px;
  width: 100%;
  background-color: ${backgroundColor.card};
  border-radius: 6px;
  padding-left: 2.5rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Greetings = styled.p`
  font-size: 20px;
  font-weight: 300;
  text-transform: capitalize;
  white-space: nowrap;
`;

export const Name = styled.h1`
  font-size: 24px;
  margin: auto 1rem;
  font-weight: 800;
`;
