/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import styled, { css } from 'styled-components';

import { fontSize, backgroundColor, accentColor, textColors } from '../../constants';

export const Container = styled.div`
  width: 100%;
  height: 45px;

  font-size: ${fontSize.mediumDefault};

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: ${backgroundColor.dark};
  border-radius: ${(props) => (props.isVisible ? '0.6rem 0.6rem 0 0 ' : '0.6rem')};
  padding: 1rem;

  position: relative;

  text-transform: capitalize;

  cursor: pointer;

  ${(props) =>
    props.isError &&
    css`
      outline: 1px solid ${accentColor.primary};
    `}

  & > svg {
    transform: ${(props) => (props.isVisible ? 'rotateX(180deg)' : 'rotateX(0deg)')};
  }
`;

export const Label = styled.p`
  color: ${textColors.textLight};
  font-size: ${fontSize.mediumDefault};
  margin-bottom: 1rem;
`;

export const DropContainer = styled.div`
  width: 100%;
  background-color: ${backgroundColor.dark};
  border-radius: 0 0 0.6rem 0.6rem;
  padding: 1rem;
  position: absolute;
  z-index: 999;
`;

export const DropItem = styled.div`
  font-size: ${fontSize.mediumDefault};
  color: ${textColors.textWhite};
  padding: 0.8rem 0;
  cursor: pointer;
  text-transform: capitalize;
`;
