/* eslint-disable no-unused-vars */
/*
 * reducer.js file for coach videoplayer view
 * Author:
 * 1.Manikanta
 * @copyright of Panorbit Services LLP.
 */

import { createReducer } from 'reduxsauce';
import { mergeArray } from '../../../../services/utility';
import { Types } from './action';

const INITIAL_STATE = {
  loading: false,
  error: null,
  videoDetails: null,
  reload: false,
  reloadComments: false,
  success: false,
  commentsList: [],
  commentsCount: 0
};

const getVideoDetailsStart = (state = INITIAL_STATE, action) => ({ ...state, error: null, loading: true });

const getVideoDetailsSuccess = (state = INITIAL_STATE, action) => {
  const { videoDetails } = action.payload;

  return {
    ...state,
    loading: false,
    reload: false,
    videoDetails
  };
};

const getVideoDetailsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const getVideoCommentsListStart = (state = INITIAL_STATE, action) => ({
  ...state,
  error: null,
  commentsloading: true,
  reloadComments: false
});

const getVideoCommentsListSuccess = (state = INITIAL_STATE, action) => {
  const { commentsList, maxPage, commentsCount } = action.payload;
  const newList = commentsList;
  const oldList = state.commentsList;
  const newArray = mergeArray(oldList, newList);
  return {
    ...state,
    commentsloading: false,
    reload: false,
    reloadComments: false,
    commentsList: newArray,
    maxPage,
    commentsCount
  };
};

const getVideoCommentsListFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    commentsloading: false,
    reloadComments: false,
    error
  };
};

const resetComments = (state = INITIAL_STATE, action) => ({ ...state, commentsList: [], commentsCount: 0 });

const HANDLERS = {
  [Types.GET_VIDEO_DETAILS_START]: getVideoDetailsStart,
  [Types.GET_VIDEO_DETAILS_SUCCESS]: getVideoDetailsSuccess,
  [Types.GET_VIDEO_DETAILS_FAILURE]: getVideoDetailsFailure,

  [Types.GET_VIDEO_COMMENTS_LIST_START]: getVideoCommentsListStart,
  [Types.GET_VIDEO_COMMENTS_LIST_SUCCESS]: getVideoCommentsListSuccess,
  [Types.GET_VIDEO_COMMENTS_LIST_FAILURE]: getVideoCommentsListFailure,

  [Types.RESET_COMMENTS]: resetComments
};

export const coachVideoPlayerReducer = createReducer(INITIAL_STATE, HANDLERS);
