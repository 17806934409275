/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

// styles
import {
  Button,
  ContentContainer,
  CloseButton,
  Footer,
  MainContainer,
  ModalContainer,
  VideoPlayerContainer,
  TabelContainer,
  Th,
  SuggesCont,
  Stats,
  TotalCont
} from './ExerciseDetailsModalStyles';

// icons
import { ReactComponent as CloseIcon } from '../../../shared/assets/Close.svg';
import { ReactComponent as Arrow } from '../../../shared/assets/Dropdown.svg';

import { ReactComponent as RepsIcon } from '../../../shared/assets/reps.svg';
import { ReactComponent as SetsIcon } from '../../../shared/assets/sets.svg';
import { ReactComponent as WeightIcon } from '../../../shared/assets/weight.svg';

import { P, H3 } from './StrengthStyles';
import { Divider, FlexContainer, TableStyles } from '../../../globalShared/CommonStyles';
import { ButtonComponent, TooltipComponnet } from '../../../globalShared';

import AddMore from '../../../shared/components/AddMoreButtonComponent';
import { FormInput } from '../../../shared/components/FormComponents';
import { _converIntoKAndM } from '../../../services/utility/commurize';

const { Table, Td } = TableStyles;
/**
 * Exercise details Modal
 */

const ExerciseDetailsModal = ({
  onCancel,
  fetch,
  selectedExercise,
  onSubmit,
  goNext,
  goPrev,
  exerciseSet,
  updateSession
}) => {
  const [formData, setformData] = useState([{ reps: '', kgs: '' }]);
  const numbersRegx = /^[0-9]+$/;
  const floatRegex = /^[0-9]*(\.[0-9]{0,2})?$/;

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (selectedExercise?.setsCompleted) {
      setformData(selectedExercise.setsCompleted);
    } else {
      setformData([{ reps: '', kgs: '' }]);
    }
  }, [selectedExercise]);

  const handleCancel = () => {
    onCancel();
  };

  const handleAddMore = () => {
    const newArry = [...formData, { reps: '', kgs: '' }];
    setformData(newArry);
  };

  const handleRemoveSet = (index) => {
    const filterdAry = formData.filter((_, i) => i !== index);
    setformData(filterdAry);
  };

  const handlerepsChange = (value, index) => {
    if (value.match(numbersRegx) || value.length === 0) {
      const newArray = [...formData];
      newArray[index] = { ...newArray[index], reps: parseInt(value) || '' };
      setformData(newArray);
    }
  };

  const handleweightChange = (value, index) => {
    if (floatRegex.test(value) || value.length === 0) {
      const newArray = [...formData];
      newArray[index] = { ...newArray[index], kgs: value };
      setformData(newArray);
    }
  };

  const _renderTotal = (data) => {
    const total = data.reduce(
      (acc, inner) => ({
        reps: parseInt(acc.reps || 0) + parseInt(inner.reps || 0),
        kgs: Number(parseFloat(acc.kgs || 0)) + Number(parseFloat(inner.kgs || 0).toFixed(2))
      }),
      {
        reps: 0,
        kgs: 0
      }
    );
    total.sets = data.length;

    return (
      <TotalCont>
        <P light>Total:</P>
        <P>{`${total.sets} Sets`}</P>
        <P>{`${total.reps} Reps`}</P>
        <P>{`${_converIntoKAndM(parseFloat(total.kgs))} Kgs`}</P>
      </TotalCont>
    );
  };
  const handleSubmit = () => {
    // eslint-disable-next-line no-return-assign
    const result = formData.map((set, i) => {
      const { reps, kgs } = set;
      const data = { sets: i + 1 };

      if (reps) {
        data.reps = reps;
      }

      if (kgs) {
        const kg = parseFloat(kgs).toFixed(2);
        data.kgs = Number(kg);
      }

      return data;
    });
    onSubmit(result);
  };

  useEffect(
    () => () => {
      updateSession();
    },
    []
  );

  return (
    <>
      <ModalContainer>
        <MainContainer>
          <ContentContainer>
            <CloseButton onClick={handleCancel}>
              <CloseIcon />
            </CloseButton>
            <FlexContainer style={{ justifyContent: 'space-between' }}>
              <div style={{ width: '176px' }}>
                <H3 bold>{selectedExercise?.title}</H3>
              </div>

              <FlexContainer>
                <Button prev active={exerciseSet.prev} onClick={() => exerciseSet.prev && goPrev()}>
                  <Arrow />
                  prev Exercise
                </Button>
                <Button next active={exerciseSet.next} onClick={() => exerciseSet.next && goNext()}>
                  Next Exercise
                  <Arrow />
                </Button>
              </FlexContainer>
            </FlexContainer>

            <P light className='word' style={{ margin: '1rem 0' }}>
              {selectedExercise?.bodyPart}
            </P>

            <VideoPlayerContainer>
              <ReactPlayer width='100%' height='100%' url={selectedExercise?.videoUrl} controls={true} />
            </VideoPlayerContainer>
            <SuggesCont>
              <P light style={{ margin: '0 0.5rem', fontSize: '15px' }}>
                Suggested by Coach :
              </P>
              <Stats>
                <FlexContainer>
                  {selectedExercise?.sets && (
                    <>
                      <SetsIcon />
                      <P style={{ margin: '0 0.5rem' }}>
                        {`${_converIntoKAndM(parseInt(selectedExercise.sets))} sets`}
                      </P>
                    </>
                  )}
                </FlexContainer>

                <FlexContainer>
                  {selectedExercise?.reps && (
                    <>
                      <RepsIcon />
                      <P style={{ margin: '0 0.5rem' }}>{`${selectedExercise.reps} Reps`}</P>
                    </>
                  )}
                </FlexContainer>
                <FlexContainer>
                  {selectedExercise?.kgs && (
                    <>
                      <WeightIcon />
                      <P style={{ margin: '0 0.5rem' }}>
                        {`${_converIntoKAndM(parseFloat(selectedExercise.kgs))} Kgs`}
                      </P>
                    </>
                  )}
                </FlexContainer>
              </Stats>
              {selectedExercise?.rest && (
                <FlexContainer style={{ margin: '1rem 0', alignItems: 'start' }}>
                  <P light>Rest:</P>
                  <P style={{ margin: '0 0.5rem' }} className='word'>
                    {selectedExercise?.rest}
                  </P>
                </FlexContainer>
              )}

              {selectedExercise?.instructions && (
                <FlexContainer style={{ margin: '1rem 0', alignItems: 'start' }}>
                  <P light>Instructions:</P>
                  <P style={{ margin: '0 0.5rem' }} className='word'>
                    {selectedExercise?.instructions}
                  </P>
                </FlexContainer>
              )}
            </SuggesCont>
            <Divider />
            <TabelContainer>
              <Table>
                <thead>
                  <tr>
                    <Th>Sets</Th>
                    <Th>Reps</Th>
                    <Th>Kgs</Th>
                    <Th />
                  </tr>
                </thead>

                <tbody>
                  {formData.map((formRow, i, array) => (
                    <tr key={i + 1}>
                      <Td style={{ padding: '0 0.5rem', textAlign: 'center' }}>
                        <P>{i + 1}</P>
                      </Td>
                      <Td style={{ padding: '0 0.5rem' }}>
                        <FormInput
                          value={formRow.reps}
                          setValue={(value) => handlerepsChange(value, i)}
                          styles={{ marginBottom: '0px' }}
                          showLable={false}
                        />
                      </Td>
                      <Td style={{ padding: '0 0.5rem' }}>
                        <FormInput
                          value={formRow.kgs}
                          setValue={(value) => handleweightChange(value, i)}
                          styles={{ marginBottom: '0px' }}
                          showLable={false}
                        />
                      </Td>
                      {array.length > 1 ? (
                        <Td style={{ padding: '0 0.5rem' }}>
                          <TooltipComponnet
                            title='Remove Set'
                            type='clear'
                            position='left'
                            onClick={() => handleRemoveSet(i)}
                          />
                        </Td>
                      ) : (
                        ''
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
              <AddMore onClick={handleAddMore}>+ Add Set</AddMore>
            </TabelContainer>
            {_renderTotal(formData)}
          </ContentContainer>
          <Footer>
            <ButtonComponent buttonText='Save' buttonClick={handleSubmit} />
          </Footer>
        </MainContainer>
      </ModalContainer>
    </>
  );
};

export default ExerciseDetailsModal;
