import styled from 'styled-components';
import { fontSize, textColors, backgroundColor, accentColor } from '../../../constants';

export const Container = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`;

export const Input = styled.input`
  width: 100%;
  height: 45px;

  padding: 1rem;
  font-size: ${fontSize.mediumDefault};

  color: ${textColors.textWhite};
  border: none;
  border-radius: 0.6rem;
  background-color: ${backgroundColor.darkOpaq};

  &:focus {
    outline: 1px solid ${accentColor.primary};
  }

  &.error:focus {
    outline: 1px solid ${backgroundColor.danger};
  }
`;
