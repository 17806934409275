import React from 'react';
import { Line } from 'react-chartjs-2';

export const LineChart = ({ data, yLabel }) => {
  const options = {
    tooltips: {
      enabled: true
    },
    responsive: true,
    legend: {
      display: false
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            zeroLineBorderDash: [5, 5],
            color: 'rgba(0, 0, 0, 0)',
            zeroLineColor: 'rgba(255, 255, 255, 0.3)'
          },
          scaleLabel: {
            display: true,
            labelString: 'Dates'
          },
          spanGaps: false
        }
      ],
      yAxes: [
        {
          gridLines: {
            color: 'rgba(0, 0, 0, 0)',
            zeroLineColor: 'rgba(255, 255, 255, 0.3)',
            zeroLineBorderDash: [5, 5]
          },
          scaleLabel: {
            display: true,
            labelString: yLabel
          },
          ticks: {
            beginAtZero: true // minimum value will be 0.
          }
        }
      ]
    }
  };

  return <Line data={data} options={options} />;
};
