/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import NestedGroup from '../NestedGroup';
import useTouchOutside from '../../hooks/useTouchOutside';

import { Label, Container, DropContainer, DropItem } from './NestedDropdown.styles';

// icon
import { ReactComponent as Arrow } from '../../assets/Dropdown.svg';
import { localString } from '../../../services/locallization/localStrings';

// component
const NestedDropdown = (props) => {
  const dropDownRef = useRef();
  const { setValue, nestedValue, value } = props;

  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  const [state, setState] = useState({
    isVisible: false
  });

  // const [value, setSelectedValue] = useState('');
  // const [nestedValue, setnestedValue] = useState('');

  function toggleHidden() {
    const { isVisible } = state;
    setState({ ...state, isVisible: !isVisible });
  }

  const handleClick = (value, nested = null) => {
    setValue({ value, nested });
    toggleHidden();
  };

  useTouchOutside(dropDownRef, () => setState({ isVisible: false }));

  const { dropDownArray, label } = props;
  const { isVisible } = state;

  return (
    <div ref={dropDownRef}>
      <Label>{label}</Label>
      <Container isVisible={isVisible} onClick={toggleHidden}>
        {nestedValue ? `${value} | ${nestedValue}` : value || string.select}
        <Arrow />
      </Container>

      {isVisible ? (
        <DropContainer>
          {dropDownArray.map((group) => {
            const { value, nested } = group;
            return !nested ? (
              <DropItem key={value} value={value} onClick={() => handleClick(value)}>
                {value}
              </DropItem>
            ) : (
              <NestedGroup key={value} {...group} onClickFunc={handleClick} />
            );
          })}
        </DropContainer>
      ) : (
        ''
      )}
    </div>
  );
};

NestedDropdown.defaultProps = {
  nestedValue: '',
  label: ''
};

NestedDropdown.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  dropDownArray: PropTypes.array.isRequired,
  label: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  nestedValue: PropTypes.string,
  value: PropTypes.string.isRequired
};
export default NestedDropdown;
