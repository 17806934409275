/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import { getDayOfMonth, getMonthDayYear, getMonth, getYear } from '../../../services/utility/moment';
import { getMonthSet, getDatesInMonthDisplay } from '../../../services/utility/date-utils';
import { monthsFull, weekdays } from '../../../services/utility/dates';

// styles
import { Button, CalanderHeader, Month, Week } from './PlannerDetailsStyles';

// icon
import { ReactComponent as Arrow } from '../../../shared/assets/Dropdown.svg';
import { FlexWraper } from '../../../globalShared/CommonStyles';

// generates month changer
export const MonthIndicator = ({ selectDate, setSelectDate }) => {
  const changeDate = (e) => setSelectDate(e);

  const monthSet = getMonthSet(selectDate);

  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <CalanderHeader>
        <Button prev onClick={() => changeDate(monthSet.prev)}>
          <Arrow />
        </Button>

        <Month>{`${monthsFull[getMonth(monthSet.current)]} ${getYear(selectDate)}`}</Month>

        <Button next onClick={() => changeDate(monthSet.next)}>
          <Arrow />
        </Button>
      </CalanderHeader>
    </div>
  );
};

// generates a weekdays
export const WeekdayIndicator = () => {
  const weekdayIcons = weekdays.map((day) => (
    <Week className='weekday-indicator-icon' key={day}>
      {day}
    </Week>
  ));
  return <FlexWraper className='bae-weekday-indicators'>{weekdayIcons}</FlexWraper>;
};

//  generates days
export const DateIndicator = ({ activeDates, selectDate, setSelectDate }) => {
  const changeDate = (e) => {
    setSelectDate(e.target.getAttribute('data-date'));
  };

  const datesInMonth = getDatesInMonthDisplay(getMonth(selectDate) + 1, getYear(selectDate));

  const monthDates = datesInMonth.map((i) => {
    const selected = getMonthDayYear(selectDate) === getMonthDayYear(i.date) ? 'selected' : '';
    const active = activeDates && activeDates[getMonthDayYear(i.date)] ? 'active' : '';

    return (
      <div
        role='presentation'
        className={`date-icon ${selected} ${active}`}
        data-active-month={i.currentMonth}
        data-date={i.date.toString()}
        key={i}
        onClick={changeDate}
      >
        {getDayOfMonth(i.date)}
      </div>
    );
  });

  return <div className='bae-date-indicator'>{monthDates}</div>;
};

export const datesInMonth = (selectDate) => {
  const dates = getDatesInMonthDisplay(getMonth(selectDate) + 1, getYear(selectDate));
  return dates;
};
