/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import { Creators } from './store';

// styles
import {
  ScrollContainer,
  StatsContainer,
  StatsWrapper,
  MoodCount,
  FilterMood,
  DayContainer,
  Rotate,
  DayStatsHead,
  DayStatsWrapper,
  DayStats,
  Day,
  MoodDate,
  DayMood,
  MoodDot,
  Filter
} from './MoodJournalStatsStyles';

// icons
import { ReactComponent as Dropdown } from '../../../shared/assets/Dropdown.svg';

import MoodIcon from '../../../shared/components/MoodIcon';
import { MoodAccordionComponent } from '../../../globalShared';

const MoodJournalStats = ({ date }) => {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [days, setDays] = useState(7);

  // values from redux store
  const stats = useSelector((state) => state.playerMindset.stats);
  const moodStats = useSelector((state) => state.playerMindset.moodStats);
  const { level1, level2, level3, level4, level5, level6 } = stats;

  // past week and month mood details
  const getPastDays = () => {
    const daysAgo = [];
    for (let day = 0; day < days; day += 1) {
      const actualDay = moment(date).subtract(day, 'days');
      const formattedDay = actualDay.format('DD MM YYYY');
      const moodDay = actualDay.format('ddd');
      const moodDate = actualDay.format('DD');
      daysAgo.push({ formattedDay, moodDay, moodDate });
    }

    return daysAgo;
  };

  const pastDays = getPastDays();

  pastDays.forEach((item) => {
    const filteredDay = moodStats.filter((stat) => moment(stat.time).format('DD MM YYYY') === item.formattedDay);
    item.dayStat = filteredDay;
  });

  // effect hooks
  useEffect(() => {
    dispatch(Creators.getPlayerMoodStats({ days }));
  }, [days]);

  const toggle = (index) => {
    if (show === index) {
      return setShow(null);
    }

    setShow(index);
  };

  const handleWeekFilter = () => {
    setDays(7);
  };

  const handleMonthFilter = () => {
    setDays(30);
  };

  const handleThreeMonthFilter = () => {
    setDays(90);
  };

  const renderDot = {
    level6: <MoodDot delightful />,
    level5: <MoodDot happy />,
    level4: <MoodDot good />,
    level3: <MoodDot sad />,
    level2: <MoodDot tired />,
    level1: <MoodDot awful />
  };

  return (
    <>
      <StatsContainer>
        <StatsWrapper>
          <MoodIcon type='level6' />
          <MoodCount delightful>{level6 || 0}</MoodCount>
        </StatsWrapper>

        <StatsWrapper>
          <MoodIcon type='level5' />
          <MoodCount happy>{level5 || 0}</MoodCount>
        </StatsWrapper>

        <StatsWrapper>
          <MoodIcon type='level4' />
          <MoodCount good>{level4 || 0}</MoodCount>
        </StatsWrapper>

        <StatsWrapper>
          <MoodIcon type='level3' />
          <MoodCount sad>{level3 || 0}</MoodCount>
        </StatsWrapper>

        <StatsWrapper>
          <MoodIcon type='level2' />
          <MoodCount tired>{level2 || 0}</MoodCount>
        </StatsWrapper>

        <StatsWrapper>
          <MoodIcon type='level1' />
          <MoodCount awful>{level1 || 0}</MoodCount>
        </StatsWrapper>
      </StatsContainer>

      <FilterMood>
        <Filter active={days === 7} onClick={handleWeekFilter}>
          <p>Weekly</p>
        </Filter>
        <Filter active={days === 30} onClick={handleMonthFilter}>
          <p>Monthly</p>
        </Filter>
        <Filter active={days === 90} onClick={handleThreeMonthFilter}>
          <p>3 Months</p>
        </Filter>
      </FilterMood>
      <ScrollContainer>
        {pastDays.map((moodItem, index) => {
          const { moodDay, moodDate, dayStat } = moodItem;

          return (
            <Fragment key={index}>
              <DayContainer onClick={() => toggle(index)} key={index}>
                <DayStatsHead>
                  <DayStatsWrapper>
                    <DayStats>
                      <Day>{moodDay}</Day>
                      <MoodDate>{moodDate}</MoodDate>
                    </DayStats>

                    <DayMood>
                      {dayStat.map(({ name }) => (
                        <Fragment key={`${name}${Math.random()}`}>{renderDot[name]}</Fragment>
                      ))}
                    </DayMood>
                  </DayStatsWrapper>

                  <span>
                    {show === index ? (
                      <Rotate>
                        <Dropdown />
                      </Rotate>
                    ) : (
                      <Dropdown />
                    )}
                  </span>
                </DayStatsHead>
                <div>{show === index && <MoodAccordionComponent dayStat={dayStat} />}</div>
              </DayContainer>
            </Fragment>
          );
        })}
      </ScrollContainer>
    </>
  );
};

export default MoodJournalStats;
