/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';

import { Container } from './ProfileModalStyles';
import { A, Details, DetailsRow, Header, H2, P, H3, Table, FileContainer } from './PersonalStyles';

import { ReactComponent as Arrow } from '../../../../shared/assets/back.svg';
import { shortenText } from '../../../../services/utility';

const defaultValues = {
  injury: [],
  health: [],

  mealPreference: '',
  foodAllergy: '',

  practiceStartTime: '',
  practiceEndTime: '',

  practiceStartTimeMeredian: 'AM',
  practiceEndTimeMeredian: 'PM',

  trainingStartTime: '',
  trainingEndTime: '',

  trainingStartTimeMeredian: 'AM',
  trainingEndTimeMeredian: 'PM'
};

// eslint-disable-next-line arrow-body-style
const Professional = ({ professionalDetails }) => {
  const [state, setstate] = useState(defaultValues);

  useEffect(() => {
    if (professionalDetails) {
      const { practiceStartTime, practiceEndTime, trainingStartTime, trainingEndTime, foodAllergy } =
        professionalDetails;

      const splitFunc = (string, i, key) => {
        const arry = string.split(key);
        return arry[i];
      };

      setstate({
        ...state,
        ...professionalDetails,
        practiceStartTime: splitFunc(practiceStartTime, 0, '-'),
        practiceEndTime: splitFunc(practiceEndTime, 0, '-'),

        trainingStartTime: splitFunc(trainingStartTime, 0, '-'),
        trainingEndTime: splitFunc(trainingEndTime, 0, '-'),

        practiceStartTimeMeredian: splitFunc(practiceStartTime, 1, '-'),
        practiceEndTimeMeredian: splitFunc(practiceEndTime, 1, '-'),

        trainingStartTimeMeredian: splitFunc(trainingStartTime, 1, '-'),
        trainingEndTimeMeredian: splitFunc(trainingEndTime, 1, '-'),
        foodAllergy: foodAllergy || ''
      });
    }
  }, [professionalDetails]);

  const _tableRow = ({ description, fileName, mediaUrl, _id, i }) => (
    <tr key={`${_id}_${i}`}>
      <td>
        <H3>{`${i + 1}. ${shortenText(description, 50, '...')}`}</H3>
      </td>
      <td>
        <FileContainer>
          <P light>{fileName}</P>
          {mediaUrl && (
            <A href={mediaUrl} target='_blank'>
              <Arrow />
            </A>
          )}
        </FileContainer>
      </td>
    </tr>
  );

  return (
    <>
      <Container style={{ margin: '2rem 0' }}>
        <Header>
          <H2>Details</H2>
        </Header>
        <Details>
          <DetailsRow>
            <P light>Injury History</P>
            {state.injury.length ? (
              <Table>
                <tbody>{state.injury.map((item, i) => _tableRow({ ...item, i }))}</tbody>
              </Table>
            ) : (
              <H3>No Injuries History</H3>
            )}
          </DetailsRow>

          <DetailsRow>
            <P light>Health History</P>
            {state.health.length ? (
              <Table>
                <tbody>{state.health.map((item, i) => _tableRow({ ...item, i }))}</tbody>
              </Table>
            ) : (
              <H3>No Health History</H3>
            )}
          </DetailsRow>

          <DetailsRow>
            <P light>Meal Preference</P>
            <H3>{state.mealPreference}</H3>
          </DetailsRow>

          <DetailsRow>
            <P light>Food Allergy</P>
            <H3>{state.foodAllergy}</H3>
          </DetailsRow>

          <DetailsRow>
            <P light>Practice Time</P>
            <H3>
              {`${state.practiceStartTime || ''} ${state.practiceStartTimeMeredian || ''} - ${
                state.practiceEndTime || ''
              } ${state.practiceEndTimeMeredian || ''}`}
            </H3>
          </DetailsRow>

          <DetailsRow>
            <P light>Training Time</P>
            <H3>
              {`${state.trainingStartTime || ''} ${state.trainingStartTimeMeredian || ''} - ${
                state.trainingEndTime || ''
              } ${state.trainingEndTimeMeredian || ''}`}
            </H3>
          </DetailsRow>
        </Details>
      </Container>
    </>
  );
};

export default Professional;
