import styled from 'styled-components';

import { fontSize, fontWeight } from '../../../shared/constants';

export const Title = styled.div`
  font-size: calc(${fontSize.titleVerySmall} - 0.1rem);
  font-weight: ${fontWeight.bold};
`;

export const FormContainer = styled.div`
  padding: 38px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`;

export const VideoInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 26px;
`;

export const InfoTitle = styled.p`
  font-size: 14px;
  font-weight: ${fontWeight.medium};
  color: #8792ac;
  margin-bottom: 1rem;
`;

export const InfoWrapper = styled.div`
  display: flex;
`;

export const VideoInfo = styled.span`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const VideoTitle = styled.p`
  font-size: 16px;
  font-weight: ${fontWeight.medium};
`;

export const VideoDuration = styled.p`
  font-size: 12px;
  color: #8d8d8d;
`;

export const InputContainer = styled.div`
  // margin-bottom: 26px;
`;

export const HideVideo = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HideVideoTitle = styled.p`
  font-size: 14px;
  font-weight: ${fontWeight.medium};
  color: #8792ac;
`;
