import styled from 'styled-components';

import { accentColor, textColors, borderColor, fontSize } from '../../constants';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 10px 10px 0px 0px;
`;

export const Controlls = styled.div`
  opacity: ${(props) => (props.show ? '1' : '0')};

  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;

  // background: transparent linear-gradient(180deg, #262626de 0%, #26262600 100%) 0% 0% no-repeat;
  border-radius: 10px 10px 0 0;
`;

export const PlayerContainer = styled.div`
  height: 100%;
  border-radius: 10px 10px 0 0;
`;

export const ControllsContainer = styled.div`
  padding: 0 1.5rem;
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  // background: transparent linear-gradient(0deg, #262626de 0%, #26262600 100%) 0% 0% no-repeat;
  border-radius: 10px 10px 0 0;

  & > svg {
    cursor: pointer;
  }
`;

export const PlayControls = styled.div`
  display: flex;
  justify-content: space-evenly;

  width: 100%;
  position: absolute;
  bottom: 50%;

  & > svg {
    cursor: pointer;
  }
`;

export const PlayButton = styled.div`
  cursor: pointer;
  margin: 0 1rem;

  transition: 0.5s;

  & > svg {
    height: 35px;
  }
`;

export const Slider = styled.input`
  height: 4px;
  width: 500px;
  outline: none;

  cursor: pointer;
`;

export const Timer = styled.time`
  font-size: 12px;
  margin: 0 1rem;
  min-width: 40px;
`;

export const PlaybackButton = styled.button`
  padding: 1rem;
  margin: 0.3rem;

  min-width: 60px;
  font-size: 14px;

  background: ${(props) => (props.isSelected ? `${accentColor.primary}` : '#4e436926')};
  color: ${(props) => (props.isSelected ? '#121A19' : `${textColors.textWhite}`)};

  border: none;
  border-radius: 6px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const VolumeButton = styled.div`
  width: 20px;
`;

export const PlayerFooter = styled.div`
  padding: 1rem;
  border: 0.5px solid #a3a9b733;
  border-radius: 0 0 10px 10px;
  border-top: none;
  display: flex;
  justify-content: space-between;
`;

export const FooterTitle = styled.div`
  font-size: 16px;
  margin-bottom: 1rem;
  color: #e7e9ef;

  display: flex;
  align-items: center;
  & > svg {
    margin: 1rem;
  }
`;

export const CompareButton = styled.button`
  cursor: pointer;
  color: ${accentColor.primary};
  background: none;
  border: none;
  font-size: 16px;

  &:focus {
    outline: none;
  }

  & > svg {
    margin: 0 1rem;
  }

  & > svg > g > g > line {
    stroke: ${accentColor.primary};
  }
`;

export const RemoveButton = styled.button`
  cursor: pointer;
  color: ${textColors.error};
  background: none;
  border: none;
  font-size: 16px;

  &:focus {
    outline: none;
  }

  & > svg {
    margin: 0 1rem;
  }

  & > svg > g > line {
    stroke: ${textColors.error};
  }
`;

export const CompareContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 50%;
  border-left: 0.5px solid ${borderColor.light};
`;

export const UserDetailsContainer = styled.div`
  padding: 1rem 0;
  display: flex;
`;
export const UserDetails = styled.p`
  font-size: ${fontSize.smallDefault};
  color: ${textColors.textLight};
  padding: 1rem 0;
  & > span {
    font-size: ${fontSize.mediumDefault};
    padding: 0 0.5rem;
  }
`;

export const TimeSince = styled.p`
  font-size: ${fontSize.smallDefault};
  color: ${textColors.textLight};
  margin-left: auto;
  padding: 1rem 0;
  white-space: nowrap;
`;
