/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as WorkoutCreators } from '../Workoutsview/store';
import { Creators } from './store';

import { AddMoreComponent, ModalComponent, RepeatComponent } from '../../../globalShared';
import { Dropdown, SearchInput, FormInput, TextArea } from '../../../shared/components/FormComponents';

// styles
import {
  SelectedDay,
  FormTitle,
  DurationContainer,
  FormField,
  TimeContainer,
  ScrollContainer,
  FormRow,
  Title,
  PrefCont
} from './PlannerDetailsStyles';
import { Divider, FlexContainer, Label, ValdErrMsg } from '../../../globalShared/CommonStyles';

// icons
import { ReactComponent as CloseIcon } from '../../../shared/assets/Close.svg';
import { getDayMonthYear, getYearMonthDay } from '../../../services/utility/moment';
import { genTimeStrings } from '../../../services/utility/timeFormat';
import { weekdays } from '../../../services/utility/dates';
import { Workout, Exercise, P } from './Details.styles';

/*
 *
 * componnet
 */

const EditSession = (props) => {
  const { onCancel, session, selectedDate, plannerDetails } = props;
  const dispatch = useDispatch();
  const workoutsList = useSelector((state) => state.coachWorkout.workoutsList);

  const [errors, setErrors] = useState([{}]);

  const timeArray = genTimeStrings();

  // state for fomdata
  const [formFields] = useState({
    title: '',
    remark: '',
    durationHour: '',
    durationMinute: '',
    preferrableTimeStart: '',
    preferrableTimeEnd: '',
    workouts: [{ title: '', _id: null }],
    sessionDate: '',
    fromDate: '',
    toDate: '',
    repeatDays: []
  });

  const [formArray, setformArray] = useState([formFields]);

  function checkFormFilled() {
    let isValid = true;
    const errs = [...errors];

    formArray.forEach((el, i) => {
      const { title, workouts } = el;
      const emptyWorkouts = workouts.filter((el) => !el._id);

      if (!title) {
        isValid = false;
        errs[i] = { ...errs[i], titleErr: 'Required' };
      }

      if (emptyWorkouts.length) {
        isValid = false;
        errs[i] = { ...errs[i], workoutErr: 'Fill All Workouts' };
      }

      if ((workouts.length === 1 && !workouts[0]._id) || !workouts.length) {
        isValid = false;
        errs[i] = { ...errs[i], workoutErr: 'Workout Required ' };
      }
    });

    setErrors([...errs]);
    return isValid;
  }

  useEffect(() => {
    if (session) {
      const obj = session[0];
      delete obj.duration;
      setformArray([{ ...obj, fromDate: getYearMonthDay(selectedDate) }]);
      // setEditMode(true);
    }
  }, [session]);

  const handleSearchReset = () => {
    dispatch(WorkoutCreators.resetWorkoutList());
  };

  const handleCancel = () => {
    setformArray([formFields]);
    onCancel();
  };

  // form onchange handlers
  const handleTitleChange = (value, index) => {
    const newArray = [...formArray];
    newArray[index] = { ...newArray[index], title: value };
    setformArray(newArray);

    // onChnage Error Handler
    const errs = [...errors];
    if (!value) {
      errs[index] = { ...errs[index], titleErr: 'Required' };
    } else {
      errs[index] = { ...errs[index], titleErr: '' };
    }
    setErrors(errs);
  };

  const handleRemarkChange = (value, index) => {
    const newArray = [...formArray];
    newArray[index] = { ...newArray[index], remark: value };
    setformArray(newArray);
  };

  const handleDurationHour = (value, index) => {
    const newArray = [...formArray];
    newArray[index] = { ...newArray[index], durationHour: value };
    setformArray(newArray);
  };

  const handleDurationMinute = (value, index) => {
    const newArray = [...formArray];
    newArray[index] = { ...newArray[index], durationMinute: value };
    setformArray(newArray);
  };

  const handleTimeStart = (value, index) => {
    const newArray = [...formArray];
    newArray[index] = { ...newArray[index], preferrableTimeStart: value };
    setformArray(newArray);
  };

  const handleTimeEnd = (value, index) => {
    const newArray = [...formArray];
    newArray[index] = { ...newArray[index], preferrableTimeEnd: value };
    setformArray(newArray);
  };

  const handleSearchChange = (searchKey) => {
    if (searchKey) {
      const payload = {
        count: 10,
        page: 1,
        searchKey
      };
      dispatch(WorkoutCreators.searchWorkoutList(payload));
    } else {
      dispatch(WorkoutCreators.resetWorkoutList());
    }
  };

  const handleAddWorkout = (index) => {
    let workOutsArray = [...formArray[index].workouts];
    workOutsArray = [{ title: '', _id: null }, ...workOutsArray];

    // sets the value to the formArray
    const newArray = [...formArray];
    newArray[index] = { ...newArray[index], workouts: workOutsArray };
    setformArray(newArray);
  };

  const setWorkoutValue = (value, mainIndex, subIndex, type) => {
    let workOutsArray = [...formArray[mainIndex].workouts];
    // onChnage Error Handler
    const errs = [...errors];

    // checks if workout is already added
    if (type !== 'del') {
      const isAlreadyAdded = !!workOutsArray.filter((el) => el._id === value._id).length;

      if (isAlreadyAdded) {
        handleSearchReset();
        return;
      }
    }

    // method2 adds new value at the end
    if (type === 'del') {
      workOutsArray = workOutsArray.filter((_, index) => index !== subIndex);
    } else {
      const workOut = workOutsArray[subIndex];
      workOut.title = value.title;
      workOut._id = value._id;
      workOut.exercises = value.exercises.map((el) => el.title);
    }

    // sets the value to the formArray
    const newArray = [...formArray];
    newArray[mainIndex] = { ...newArray[mainIndex], workouts: workOutsArray };

    setformArray(newArray);

    if (workOutsArray.length) {
      errs[mainIndex] = { ...errs[mainIndex], workoutErr: '' };
    } else {
      errs[mainIndex] = { ...errs[mainIndex], workoutErr: 'Required' };
    }
    setErrors([...errs]);
    handleSearchReset();
  };

  const handleEndDate = (value, index) => {
    const newArray = [...formArray];
    newArray[index] = { ...newArray[index], toDate: getYearMonthDay(value), repeatDays: weekdays };
    setformArray(newArray);

    const errs = [...errors];
    if (!value) {
      errs[index] = { ...errs[index], toDateErr: 'Required' };
    } else {
      errs[index] = { ...errs[index], toDateErr: '' };
    }
    setErrors(errs);
  };

  const handleStartDate = (value, index) => {
    const newArray = [...formArray];
    newArray[index] = { ...newArray[index], fromDate: getYearMonthDay(value) };
    setformArray(newArray);

    const errs = [...errors];
    if (!value) {
      errs[index] = { ...errs[index], fromDateErr: 'Required' };
    } else {
      errs[index] = { ...errs[index], fromDateErr: '' };
    }
    setErrors(errs);
  };

  const handleRepeatDays = (value, index) => {
    const newArray = [...formArray];
    newArray[index] = { ...newArray[index], repeatDays: value };
    setformArray(newArray);
  };

  const handleSubmit = () => {
    if (checkFormFilled()) {
      const modifier = (array) =>
        array.map((element) => ({
          ...element,
          sessionDate: getYearMonthDay(selectedDate),
          plannerName: plannerDetails?.title,
          plannerId: plannerDetails?._id,
          workouts: element.workouts.filter((el) => !!el._id).map((i) => i._id),
          fromDate: element.fromDate || getYearMonthDay(selectedDate),
          toDate: element.toDate || getYearMonthDay(selectedDate)
        }));

      const data = modifier(formArray);
      // eslint-disable-next-line prefer-const
      let dataToUpdate = data.filter((Item) => !!Item._id);

      dataToUpdate = dataToUpdate.map((item) => {
        const res = session.find(({ _id }) => _id === item._id);

        const payload = { ...item, previousTitle: res.title, singledate: false };
        delete payload.noOfExersises;
        // gets the removed days
        const previousRepeatDays = res.repeatDays.filter((x) => !item.repeatDays.includes(x));
        if (previousRepeatDays.length) {
          payload.previousRepeatDays = previousRepeatDays;
        }
        return payload;
      });

      if (dataToUpdate.length) {
        dispatch(Creators.createPlannerSession({ method: 'PUT', data: dataToUpdate }));
      }

      handleCancel();
    }
  };

  return (
    <>
      <ModalComponent width='720' onCancel={handleCancel} onSubmit={handleSubmit}>
        <form style={{ padding: '3rem', paddingBottom: '0' }}>
          <FlexContainer style={{ justifyContent: 'space-between' }}>
            <FormTitle>Edit Session</FormTitle>
            <SelectedDay>{getDayMonthYear(selectedDate)}</SelectedDay>
          </FlexContainer>

          <ScrollContainer>
            {formArray.length
              ? formArray.map((form, k) => (
                  <FormRow key={k}>
                    <FormField>
                      <FormInput
                        placeholder='Session Name'
                        label='Session Title'
                        value={form.title}
                        setValue={(value) => handleTitleChange(value, k)}
                        error={!!errors[k]?.titleErr}
                      />
                      <ValdErrMsg>{errors[k]?.titleErr}</ValdErrMsg>

                      <TextArea
                        value={form.remark}
                        placeholder='Type the Session Remarks Here...'
                        label='Description'
                        height='100px'
                        setValue={(value) => handleRemarkChange(value, k)}
                      />
                      <Divider />
                      <Title>Workouts</Title>
                      <ValdErrMsg>{errors[k]?.workoutErr}</ValdErrMsg>

                      {form.workouts.map((workout, i, arry) => {
                        const { title, _id, exercises } = workout;
                        return (
                          <FlexContainer key={i}>
                            {_id ? (
                              <Workout key={_id} style={{ width: '100%' }} blue>
                                <P>{title}</P>
                                <Exercise className='word'>{exercises.join(' , ')}</Exercise>
                              </Workout>
                            ) : (
                              <SearchInput
                                placeholder='Search Workout'
                                label=''
                                onChange={handleSearchChange}
                                list={workoutsList}
                                setValue={(value) => setWorkoutValue(value, k, i)}
                                focus={arry.length > 1 && i === 0}
                              />
                            )}
                            <CloseIcon
                              style={{ marginLeft: '1rem', marginBottom: '2rem', cursor: 'pointer' }}
                              onClick={() => setWorkoutValue(null, k, i, 'del')}
                            />
                          </FlexContainer>
                        );
                      })}

                      <AddMoreComponent onClick={() => handleAddWorkout(k)}>+ Add Workout</AddMoreComponent>
                      <Divider />
                      <Title>Details</Title>
                      <FlexContainer>
                        <DurationContainer>
                          <div style={{ width: '110px' }}>
                            <Dropdown
                              list={['01 Hrs', '02 Hrs', '03 Hrs']}
                              label='Duration'
                              placeholder='00 Hrs'
                              value={form.durationHour}
                              setValue={(value) => handleDurationHour(value, k)}
                              position='top'
                            />
                            <ValdErrMsg>{errors[k]?.hourErr}</ValdErrMsg>
                          </div>

                          <div style={{ width: '110px' }}>
                            <Dropdown
                              list={['00 min', '15 min', '30 min', '45 min']}
                              label=''
                              placeholder='00 Min'
                              value={form.durationMinute}
                              setValue={(value) => handleDurationMinute(value, k)}
                              position='top'
                            />
                          </div>
                        </DurationContainer>

                        <PrefCont>
                          <Label style={{ marginLeft: '1rem', marginBottom: 0 }}>Preferable Time</Label>

                          <TimeContainer>
                            <Label style={{ marginBottom: '2rem' }}>Between</Label>
                            <div style={{ width: '130px' }}>
                              <Dropdown
                                list={timeArray}
                                label=''
                                value={form.preferrableTimeStart}
                                setValue={(value) => handleTimeStart(value, k)}
                                position='top'
                                maxHeight='170'
                                showLabel={false}
                              />
                            </div>
                            <Label style={{ marginBottom: '2rem' }}>-</Label>

                            <div style={{ width: '130px' }}>
                              <Dropdown
                                list={timeArray}
                                label=''
                                value={form.preferrableTimeEnd}
                                setValue={(value) => handleTimeEnd(value, k)}
                                position='top'
                                maxHeight='170'
                                showLabel={false}
                              />
                            </div>
                          </TimeContainer>
                        </PrefCont>
                      </FlexContainer>
                      <RepeatComponent
                        fromDate={form.fromDate}
                        toDate={form.toDate}
                        repeatDays={form.repeatDays}
                        setStartDate={(value) => handleStartDate(value, k)}
                        setEndDate={(value) => handleEndDate(value, k)}
                        setRepeatDays={(value) => handleRepeatDays(value, k)}
                      />
                    </FormField>
                  </FormRow>
                ))
              : ''}
          </ScrollContainer>
        </form>
      </ModalComponent>
    </>
  );
};

export default EditSession;
