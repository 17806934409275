import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// creators
import { Creators } from './store';
import { Creators as LoginCreators } from '../../auth/LoginView/store';

// styles
import {
  ModalContainer,
  ContentContainer,
  CloseButton,
  DetailsWrapper,
  ProfileContainer,
  Header,
  Title,
  EditWrapper,
  ProfileContent,
  ProfilePicWrapper,
  ProfilePic,
  ProfileDetailsWrapper,
  DetailRow,
  DetailType,
  DetailValue,
  PasswordResetWrapper
} from './CoachProfileModalStyles';

// icons
import { ReactComponent as CloseIcon } from '../../../shared/assets/Close.svg';
import { ReactComponent as EditIcon } from '../../../shared/assets/edit2.svg';
import userImg from '../../../shared/assets/user.jpg';

import Toast, { Toasts } from '../../../services/toast';

import EditProfile from './EditProfile';
import ResetPassword from './ResetPassword';
import { FormInput } from '../../../shared/components/FormComponents';
import { localString } from '../../../services/locallization/localStrings';
import { shortenText } from '../../../services/utility';

const CoachProfileModal = (props) => {
  const { closeCoachProfileModal } = props;
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.login.personalDetail);
  const reloadPersonalDetails = useSelector((state) => state.updateCoachPersonalDetails.reloadPersonalDetails);
  const resetSuccess = useSelector((state) => state.updateCoachPersonalDetails.resetSuccess);
  const success = useSelector((state) => state.updateCoachPersonalDetails.success);
  const error = useSelector((state) => state.updateCoachPersonalDetails.error);

  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  useEffect(() => {
    if (reloadPersonalDetails) {
      dispatch(LoginCreators.getUserData());
    }
  }, [reloadPersonalDetails]);

  // toast notification
  useEffect(() => {
    if (resetSuccess) {
      Toast.success(`${string.success}`, 2, () => dispatch(Creators.resetSuccess()));
    }
  }, [resetSuccess]);

  useEffect(() => {
    if (success) {
      Toast.success(`${string.saved}`, 2, () => dispatch(Creators.resetSuccess()));
      dispatch(LoginCreators.getUserData());
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      Toast.error(error, 2, () => dispatch(Creators.resetError()));
    }
  }, [error]);

  const { firstName, lastName, email, profilePicture, phoneNumber } = userData;

  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);

  const handleClose = () => {
    closeCoachProfileModal();
  };

  return (
    <ModalContainer>
      <ContentContainer>
        <CloseButton onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
        <DetailsWrapper>
          {!showEditProfile ? (
            <>
              <ProfileContainer>
                <Header>
                  <Title>{string.profile}</Title>
                  <EditWrapper onClick={() => setShowEditProfile(true)}>
                    <EditIcon />
                  </EditWrapper>
                </Header>

                <ProfileContent>
                  <ProfilePicWrapper>
                    <ProfilePic style={{ backgroundImage: `url(${profilePicture || userImg})` }} />
                  </ProfilePicWrapper>
                  <ProfileDetailsWrapper>
                    <DetailRow>
                      <DetailType>{string.name}</DetailType>
                      <DetailValue>{shortenText(`${firstName} ${lastName}`, 15, '...')}</DetailValue>
                    </DetailRow>
                    <DetailRow>
                      <DetailType>{string.email}</DetailType>
                      <DetailValue>{email}</DetailValue>
                    </DetailRow>
                    <DetailRow>
                      <DetailType>{string.phone}</DetailType>
                      <DetailValue>{phoneNumber}</DetailValue>
                    </DetailRow>
                  </ProfileDetailsWrapper>
                </ProfileContent>
              </ProfileContainer>
              <Toasts />
            </>
          ) : (
            <EditProfile closeEditProfile={() => setShowEditProfile(false)} />
          )}

          {!showResetPassword ? (
            <PasswordResetWrapper>
              <Header>
                <Title>{string.resetPassword}</Title>
                <EditWrapper onClick={() => setShowResetPassword(true)}>
                  <EditIcon />
                </EditWrapper>
              </Header>

              <FormInput
                placeholder=''
                label={string.currentPassword}
                value='*********'
                setValue={() => null}
                type='password'
              />
            </PasswordResetWrapper>
          ) : (
            <ResetPassword closeResetPassword={() => setShowResetPassword(false)} />
          )}
        </DetailsWrapper>
      </ContentContainer>
    </ModalContainer>
  );
};

export default CoachProfileModal;
