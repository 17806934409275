import styled from 'styled-components';
import { backgroundColor, textColors, fontSize, fontWeight } from '../../constants';

export const styles = {
  Container: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;

    background: ${backgroundColor.darkOpaq};
    border-radius: 0.6rem;
  `,

  ActionContainer: styled.div`
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    display: flex;
    padding: 0.3rem 0.5rem;
    justify-content: space-between;
    align-items: center;
    background-color: ${backgroundColor.actionBackground};
  `,

  ActionItem: styled.div`
    width: 48%;
    height: 100%;
    border-radius: 0.5rem;
    background-color: ${(props) => (props.selectedControl === true ? backgroundColor.lightGray : 'transparent')};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    transition: background-color 0.2s ease-in-out;

    & > p {
      color: ${(props) => (props.selectedControl === true ? textColors.textDark : textColors.textWhite)};
      font-size: ${fontSize.mediumDefault};
      font-weight: ${(props) => (props.selectedControl === true ? fontWeight.bold : 500)};
    }
  `
};
