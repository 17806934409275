/*
 * action.js file for coach plannerdetails view
 * Author:
 * 1.Manikanta
 *
 * @copyright of Panorbit Services LLP.
 */

import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getPlannerDetails: ['payload'],
  getPlannerDetailsStart: ['payload'],
  getPlannerDetailsSuccess: ['payload'],
  getPlannerDetailsFailure: ['payload'],

  getGroupDetails: ['payload'],
  getGroupDetailsStart: ['payload'],
  getGroupDetailsSuccess: ['payload'],
  getGroupDetailsFailure: ['payload'],

  createPlannerSession: ['payload'],
  createPlannerSessionStart: ['payload'],
  createPlannerSessionSuccess: ['payload'],
  createPlannerSessionFailure: ['payload'],

  deleteSession: ['payload'],
  deleteSessionStart: ['payload'],
  deleteSessionSuccess: ['payload'],
  deleteSessionFailure: ['payload'],

  copyScheduleByDay: ['payload'],
  copyScheduleByDayStart: ['payload'],
  copyScheduleByDaySuccess: ['payload'],
  copyScheduleByDayFailure: ['payload'],

  clearScheduleByDay: ['payload'],
  clearScheduleByDayStart: ['payload'],
  clearScheduleByDaySuccess: ['payload'],
  clearScheduleByDayFailure: ['payload'],

  copyScheduleByWeek: ['payload'],
  copyScheduleByWeekStart: ['payload'],
  copyScheduleByWeekSuccess: ['payload'],
  copyScheduleByWeekFailure: ['payload'],

  clearScheduleByWeek: ['payload'],
  clearScheduleByWeekStart: ['payload'],
  clearScheduleByWeekSuccess: ['payload'],
  clearScheduleByWeekFailure: ['payload'],

  setGroupModalData: ['payload'],
  setShowModal: ['payload']
});

export { Types, Creators };
