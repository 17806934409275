/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Creators } from './store';

// styles
import { ModalContainer, CloseButton, Header } from './Workouts.styles';
import { H1, Text, ContentContainer, DescContainer, WorkoutCon, Thead, Tbody, Th, Td, Tr } from './OverviewStyles';

import { MainContainer } from '../../../shared/components/CreateModalComponent/CreateModalStyles';

// icons
import { ReactComponent as CloseIcon } from '../../../shared/assets/Close.svg';
import { ReactComponent as Edit } from '../../../shared/assets/edit.svg';
import { FlexContainer } from '../../../globalShared/CommonStyles';
import { _converIntoKAndM } from '../../../services/utility/commurize';
import { shortenText } from '../../../services/utility';

export const ExerciseTable = ({ exercises }) => (
  <WorkoutCon>
    <Thead>
      <tr>
        <Th className='first'>Exercise</Th>
        <Th>Sets</Th>
        <Th>Reps</Th>
        <Th>Weight(kgs)</Th>
        <Th>Rest</Th>
        <Th className='last'>Instructions</Th>
      </tr>
    </Thead>

    <Tbody>
      {exercises.map((exercise, i) => {
        const { instructions, kgs, title, reps, rest, sets } = exercise;
        return (
          <Tr key={i}>
            <Td>
              <Text className='word'>{title}</Text>
            </Td>
            <Td>
              <Text>{sets}</Text>
            </Td>
            <Td>
              <Text className='word' style={{ whiteSpace: 'nowrap' }}>
                {reps}
              </Text>
            </Td>
            <Td>
              <Text>{_converIntoKAndM(parseFloat(kgs)) || ''}</Text>
            </Td>
            <Td>
              <Text>{rest}</Text>
            </Td>
            <Td>
              <Text className='word'>{instructions}</Text>
            </Td>
          </Tr>
        );
      })}
    </Tbody>
  </WorkoutCon>
);

const WorkoutOverview = () => {
  const dispatch = useDispatch();

  const { workout } = useSelector((state) => state.coachWorkout);

  const handleEdit = () => {
    dispatch(Creators.setShowmodal({ show: true }));
    dispatch(Creators.setShowDetail({ show: false }));
  };

  const handleCancle = () => {
    dispatch(Creators.setWorkout({ workout: null }));
    dispatch(Creators.setShowDetail({ show: false }));
  };

  return (
    <ModalContainer>
      <MainContainer width='768' style={{ position: 'unset' }}>
        <CloseButton onClick={handleCancle}>
          <CloseIcon />
        </CloseButton>

        <Header>
          <FlexContainer>
            <H1 className='border word' style={{ minWidth: '250px', maxWidth: '410px' }}>
              {workout.title}
            </H1>
            <Text>
              <span>Created by </span>
              {shortenText(workout.createdBy, 20, '...')}
            </Text>
          </FlexContainer>
          <Edit onClick={handleEdit} />
        </Header>
        <ContentContainer>
          <DescContainer>
            <Text>
              <span style={{ padding: 0 }}>Description</span>
            </Text>
            <Text className='word' style={{ marginTop: '1rem' }}>
              {workout.description}
            </Text>
          </DescContainer>
          <ExerciseTable exercises={workout.exercises} />
        </ContentContainer>
      </MainContainer>
    </ModalContainer>
  );
};

export default WorkoutOverview;
