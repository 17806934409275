/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable arrow-body-style */

import React, { useState, useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Creators as ExerciseCreators } from '../ExerciseView/store';
import { Creators as WorkoutCreators } from './store';

// styles
import { Title, FormContainer, Button } from './Workouts.styles';

import { FlexContainer, ValdErrMsg } from '../../../globalShared/CommonStyles';

import { ReactComponent as CloseIcon } from '../../../shared/assets/Close.svg';
import { AddMoreComponent, ModalComponent } from '../../../globalShared';
import { FormInput, SearchInput, TextArea } from '../../../shared/components/FormComponents';
import { scrollToView } from '../../../services/utility';
// import Toast, { Toasts } from '../../../services/toast';

const numbersRegx = /^[0-9]+$/;
const floatRegex = /^[0-9]*(\.[0-9]{0,2})?$/;

const CreateWorkoutModal = () => {
  // store to props
  const dispatch = useDispatch();
  const exerciseList = useSelector((state) => state.coachExercise.exerciseList);
  const workout = useSelector((state) => state.coachWorkout.workout);
  const success = useSelector((state) => state.coachWorkout.success);

  const lastItemRef = useRef();
  const listItemRef = useRef();

  // form data

  const [formFields] = useState({ id: '', title: '', sets: '', reps: '', kgs: '', rest: '', instructions: '' });
  const [formArray, setFormArray] = useState([formFields]);
  const [formTitle, setformTitle] = useState('');
  const [description, setDescription] = useState('');
  const [_id, setId] = useState(null);
  const [errors, setErrors] = useState([{}]);
  const [titleErr, setTitleErr] = useState(null);
  const [deletedExe, setDeletedExe] = useState([]);
  const [previousCount, setPreviousCount] = useState(0);

  function checkFormFilled() {
    let IsValid = true;
    const errs = [...errors];

    if (!formTitle) {
      IsValid = false;
      setTitleErr('Required');
    }

    // eslint-disable-next-line array-callback-return
    formArray.map((item, i) => {
      const { id, sets } = item;
      if (!id) {
        IsValid = false;
        errs[i] = { ...errs[i], ererciseErr: 'Required' };
      }

      if (!sets) {
        IsValid = false;
        errs[i] = { ...errs[i], setsErr: 'Required' };
      }
    });

    setErrors([...errs]);
    return IsValid;
  }

  useEffect(() => {
    if (workout) {
      setFormArray(workout.exercises);
      setformTitle(workout.title);
      setDescription(workout.description || '');
      setPreviousCount(workout.exercises.length);
      setId(workout._id);
    }
  }, [workout]);

  const handleCancel = () => {
    dispatch(WorkoutCreators.setWorkout({ workout: null }));
    dispatch(WorkoutCreators.setShowmodal({ show: false }));
    setFormArray([formFields]);
  };

  const handleSearchChange = (searchKey) => {
    if (searchKey) {
      const payload = {
        searchKey
      };
      dispatch(ExerciseCreators.searchExercise(payload));
    } else {
      dispatch(ExerciseCreators.resetExerciseList());
    }
  };

  const onSubmit = () => {
    if (checkFormFilled()) {
      const data = formArray.map((el) => ({ ...el, isActive: true }));
      const count = formArray.length - previousCount;

      if (data.length > 0) {
        dispatch(
          WorkoutCreators.createWorkout({
            exercises: [...data, ...deletedExe],
            title: formTitle,
            _id,
            description,
            updateCount: count
          })
        );
      } else {
        setTitleErr('Please Add Atleast One Exercise');
      }
    }
  };

  const AddExercise = () => {
    setTitleErr(null);
    setFormArray([...formArray, formFields]);
    setErrors([...errors, {}]);
    scrollToView(lastItemRef);
  };

  const deleteFormRow = (index) => {
    const obj = formArray.find((_, k) => k === index);
    const isOld = !!workout?.exercises.find((el) => el.id === obj.id);
    obj.isActive = false;

    if (obj.id && isOld) {
      setDeletedExe([...deletedExe, obj]);
    }
    const newArray = formArray.filter((_, K) => K !== index);
    const newErrors = errors.filter((_, K) => K !== index);
    setFormArray(newArray);
    setErrors(newErrors);
    scrollToView(lastItemRef);
  };

  // form onchage handlers
  const handleTitleChange = (value) => {
    setformTitle(value);

    // onChnage Error Handler

    if (!value) {
      setTitleErr('Required');
    } else {
      setTitleErr(null);
    }
  };

  const handleSetChange = (value, index) => {
    if (value.match(numbersRegx) || value.length === 0) {
      const newArray = [...formArray];
      newArray[index] = { ...newArray[index], sets: value };
      setFormArray(newArray);
    }

    // onChnage Error Handler
    const errs = [...errors];
    if (!value || !value.match(numbersRegx)) {
      errs[index] = { ...errs[index], setsErr: 'Required' };
    } else {
      errs[index] = { ...errs[index], setsErr: '' };
    }
    setErrors(errs);
  };

  const handlerepsChange = (value, index) => {
    if (value || value.length === 0) {
      const newArray = [...formArray];
      newArray[index] = { ...newArray[index], reps: value };
      setFormArray(newArray);
    }
  };

  const handleweightChange = (value, index) => {
    if (floatRegex.test(value) || value.length === 0) {
      const newArray = [...formArray];
      newArray[index] = { ...newArray[index], kgs: value };
      setFormArray(newArray);
    }
  };

  const handleRestChange = (value, index) => {
    const newArray = [...formArray];
    newArray[index] = { ...newArray[index], rest: value };
    setFormArray(newArray);
  };

  const handleInstChange = (value, index) => {
    const newArray = [...formArray];
    newArray[index] = { ...newArray[index], instructions: value };
    setFormArray(newArray);
  };

  const setExerciseValue = (value, index) => {
    const newArray = [...formArray];
    const errs = [...errors];

    const isAdded = formArray.some((el) => {
      return el.id === value._id;
    });

    if (!isAdded) {
      newArray[index] = { ...newArray[index], title: value.title, id: value._id };
      setFormArray(newArray);

      // onChnage error Handler
      errs[index] = { ...errs[index], ererciseErr: '' };
    } else {
      // onChnage error Handler
      errs[index] = { ...errs[index], ererciseErr: 'Exercise Added' };
    }

    setErrors(errs);
    dispatch(ExerciseCreators.resetExerciseList());
  };

  useEffect(() => {
    if (success) {
      handleCancel();
    }
  }, [success]);

  return (
    <ModalComponent width='1038' onCancel={handleCancel} onSubmit={onSubmit}>
      <form style={{ padding: '2rem 3rem' }}>
        <Title>Create Workout</Title>
        <FormInput label='Workout Title' placeholder='Workout' value={formTitle} setValue={handleTitleChange} />
        <ValdErrMsg>{titleErr}</ValdErrMsg>
        <TextArea
          label='Workout Description'
          placeholder='Description'
          value={description}
          setValue={(value) => setDescription(value)}
          height='100px'
        />

        <FormContainer style={{ marginTop: '2rem' }}>
          {formArray.map((form, index, arry) => {
            return (
              <FlexContainer
                style={{ justifyContent: 'space-between', alignItems: 'flex-start' }}
                key={index}
                ref={arry.length - 1 === index ? lastItemRef : listItemRef}
              >
                <div style={{ width: '320px' }}>
                  <SearchInput
                    placeholder='Search Exercise...'
                    label={index < 1 ? 'Exercise' : ''}
                    onChange={handleSearchChange}
                    list={exerciseList}
                    value={{ _id: form.id, title: form.title }}
                    setValue={(value) => setExerciseValue(value, index)}
                    position='top'
                  />
                  <ValdErrMsg>{errors[index]?.ererciseErr}</ValdErrMsg>
                </div>

                <div style={{ width: '75px' }}>
                  <FormInput
                    showLable={index < 1}
                    label='Sets'
                    styles={{ marginBottom: '2rem' }}
                    placeholder='3'
                    value={form.sets}
                    setValue={(value) => handleSetChange(value, index)}
                  />
                  <ValdErrMsg>{errors[index]?.setsErr}</ValdErrMsg>
                </div>

                <div style={{ width: '75px' }}>
                  <FormInput
                    showLable={index < 1}
                    label='Reps'
                    placeholder='12'
                    value={form.reps}
                    styles={{ marginBottom: '2rem' }}
                    setValue={(value) => handlerepsChange(value, index)}
                  />
                </div>

                <div style={{ width: '75px' }}>
                  <FormInput
                    showLable={index < 1}
                    label='Weight(kg)'
                    placeholder='30'
                    value={form.kgs}
                    setValue={(value) => handleweightChange(value, index)}
                    styles={{ marginBottom: '2rem' }}
                  />
                </div>

                <div style={{ width: '75px' }}>
                  <FormInput
                    showLable={index < 1}
                    label='Rest'
                    placeholder='90s'
                    value={form.rest}
                    setValue={(value) => handleRestChange(value, index)}
                    styles={{ marginBottom: '2rem' }}
                  />
                </div>

                <div style={{ width: '240px' }}>
                  <FormInput
                    showLable={index < 1}
                    label='Instructions'
                    placeholder='1@20, 2@30, 3@20'
                    value={form.instructions}
                    setValue={(value) => handleInstChange(value, index)}
                    styles={{ marginBottom: '2rem' }}
                  />
                </div>
                {formArray.length >= 1 && (
                  <CloseIcon
                    onClick={() => deleteFormRow(index)}
                    style={{ cursor: 'pointer', marginTop: index === 0 ? '4rem' : '2rem' }}
                  />
                )}
              </FlexContainer>
            );
          })}
        </FormContainer>
        <Button>
          <AddMoreComponent onClick={AddExercise}> + Add Exercise</AddMoreComponent>
        </Button>
      </form>
    </ModalComponent>
  );
};

export default CreateWorkoutModal;
