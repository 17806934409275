/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Creators } from './store';
import { Creators as CommonCreators } from '../../store';

// styles
import {
  H1,
  H2,
  TextSmallLight,
  BedTimeContainer,
  BedTimes,
  Text,
  Sleep,
  LegendButtons
} from './SleepDetailsModalStyles';
import { CommentInputContainer, TabContainer } from './MoodDetailsModalStyles';
import { Divider, FlexContainer } from '../../../globalShared/CommonStyles';
import { CommentInputComponent, LineChart } from '../../../globalShared';

// icons
import { ReactComponent as BedtimeIcon } from '../../../shared/assets/bedtime_icon.svg';
import { ReactComponent as WakeuptimeIcon } from '../../../shared/assets/wakeuptime_icon.svg';

import CommentsFooter from '../../../shared/components/CommetsFooter';
import { getDay, getMothSmall, getYearMonthDay } from '../../../services/utility/moment';
import { getPastDays } from '../../Player/HomeView/utlity';

const SleepDetailsModal = ({ data }) => {
  const { _id, userId, commentCount } = data;
  const dispatch = useDispatch();

  const [days, setDays] = useState(7);
  const [dataType, setDataType] = useState('days');
  const [recentCommentID, setRecentCommentID] = useState('');

  // values from redux store
  const sleepDetails = useSelector((state) => state.coachMindset.sleepDetails);
  const sleepStats = useSelector((state) => state.coachMindset.sleepStats);
  const avgSleepDuration = useSelector((state) => state.coachMindset.avgSleepDuration);
  const sleepCommentsList = useSelector((state) => state.coachMindset.sleepCommentsList);

  const reloadComments = useSelector((state) => state.main.reloadComments);

  const { duration, bedTime, wakeupTime } = sleepDetails;
  const durationArray = duration && duration.split(':');

  const durationHours = durationArray && durationArray[0];
  const durationMinutes = durationArray && durationArray[1];

  const avgSleepDurationArray = avgSleepDuration && avgSleepDuration.split(':');
  const avgSleepDurationHours = avgSleepDuration && avgSleepDurationArray[0];
  const avgSleepDurationMinutes = avgSleepDuration && avgSleepDurationArray[1];

  // data for line chart
  const pastDays = getPastDays({ type: dataType, days });

  pastDays.forEach((item) => {
    let filterDay = [];

    if (dataType === 'month') {
      filterDay = sleepStats.filter((stat) => getMothSmall(stat.time) === item.MM);
    } else {
      filterDay = sleepStats.filter((stat) => getYearMonthDay(stat.time) === item.MMDDYY);
    }

    item.sleepTime = filterDay.length > 0 ? filterDay[0].duration : null;
  });

  const sleepDates = [];
  const sleepHours = [];

  pastDays.reverse().forEach((item) => {
    if (dataType === 'month') {
      sleepDates.push(getMothSmall(item.MMDDYY));
    } else {
      sleepDates.push(getDay(item.MMDDYY));
    }
    sleepHours.push(item.sleepTime);
  });

  // line chart
  const chartData = (canvas) => {
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, canvas.height, 0, 0);
    gradient.addColorStop(0, '#21D0B300');
    gradient.addColorStop(1, '#21D0B382');

    return {
      labels: sleepDates,
      datasets: [
        {
          data: sleepHours,
          fill: true,
          backgroundColor: gradient,
          borderColor: 'rgba(75,192,192,1)',
          lineTension: 0
        }
      ]
    };
  };

  // effect hooks
  useEffect(() => {
    dispatch(Creators.getCoachSleepDetails({ _id }));
    dispatch(Creators.getCoachSleepComments({ _id, count: 100, page: 1 }));
  }, []);

  useEffect(() => {
    dispatch(Creators.getCoachSleepStats({ page: 1, count: 30, userId, dataType, days }));
  }, [userId, days]);

  const handleSubmitComment = (content, id) => {
    dispatch(CommonCreators.addComment({ content, id }));
    setRecentCommentID(id);
  };

  const handleShowComments = (_id) => {
    dispatch(Creators.getCoachSleepComments({ _id, count: 100, page: 1 }));
    setRecentCommentID(_id);
  };

  useEffect(() => {
    if (reloadComments && !!recentCommentID) {
      handleShowComments(recentCommentID);
    }
  }, [reloadComments]);

  const handleWeekSleep = () => {
    setDataType('days');
    setDays(7);
  };

  const handleMonthSleep = () => {
    setDataType('days');
    setDays(30);
  };

  const handleSixMonthSleep = () => {
    setDataType('month');
    setDays(180);
  };

  return (
    <TabContainer>
      <FlexContainer>
        <TextSmallLight style={{ paddingRight: '1rem' }}> Sleep Duration:</TextSmallLight>
        <H2>{`${durationHours} Hrs ${durationMinutes} Min`}</H2>
      </FlexContainer>
      <BedTimeContainer>
        <FlexContainer>
          <BedTimes style={{ borderRight: '1px solid #a3a9b733' }}>
            <Text>
              <span>
                <BedtimeIcon />
              </span>
              <p>Bed Time</p>
            </Text>
            <H1>{bedTime}</H1>
          </BedTimes>

          <BedTimes>
            <Text>
              <span>
                <WakeuptimeIcon />
              </span>
              <p>Wakeup Time</p>
            </Text>
            <H1>{wakeupTime}</H1>
          </BedTimes>
        </FlexContainer>
        <Divider />
        <div>
          <CommentsFooter
            commentsList={sleepCommentsList}
            _id={_id}
            commentCount={commentCount || 0}
            onClick={handleShowComments}
          />
          <CommentInputContainer>
            <CommentInputComponent placeholderText='Enter Your Comment Here…' id={_id} onSubmit={handleSubmitComment} />
          </CommentInputContainer>
        </div>
      </BedTimeContainer>

      <div>
        <H2 style={{ marginBottom: '1rem' }}>Sleep Stats</H2>
        <Sleep>
          <TextSmallLight style={{ margin: '0rem 1rem' }}>Average Sleep Duration</TextSmallLight>
          <H1>{`${avgSleepDurationHours} Hrs ${avgSleepDurationMinutes} Min`}</H1>
        </Sleep>
        <FlexContainer style={{ justifyContent: 'center' }}>
          <LegendButtons active={days === 7} onClick={() => handleWeekSleep()}>
            Weekly
          </LegendButtons>
          <LegendButtons active={days === 30} onClick={() => handleMonthSleep()}>
            Monthly
          </LegendButtons>
          <LegendButtons active={days === 180} onClick={() => handleSixMonthSleep()}>
            6 months
          </LegendButtons>
        </FlexContainer>
        <div style={{ height: '120px', position: 'relative' }}>
          <LineChart data={chartData} yLabel='Sleep Duration' />
        </div>
      </div>
    </TabContainer>
  );
};

export default SleepDetailsModal;
