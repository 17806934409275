import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  loading: false,
  error: null,
  success: false,
  resetSuccess: false,
  reloadPersonalDetails: false
};

const updatePersonalDetailsStart = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  reloadPersonalDetails: false
});

const updatePersonalDetailsSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  success: true,
  reloadPersonalDetails: true
});

const updatePersonalDetailsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    success: false,
    reloadPersonalDetails: false,
    error
  };
};

const resetPasswordStart = (state = INITIAL_STATE) => ({
  ...state,
  loading: true
});

const resetPasswordSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  resetSuccess: true
});

const resetPasswordFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;

  return {
    ...state,
    loading: false,
    resetSuccess: false,
    error
  };
};

const resetSuccess = (state = INITIAL_STATE) => ({
  ...state,
  resetSuccess: false,
  success: false
});

const resetError = (state = INITIAL_STATE) => ({
  ...state,
  error: null
});

const HANDLERS = {
  [Types.UPDATE_PERSONAL_DETAILS_START]: updatePersonalDetailsStart,
  [Types.UPDATE_PERSONAL_DETAILS_SUCCESS]: updatePersonalDetailsSuccess,
  [Types.UPDATE_PERSONAL_DETAILS_FAILURE]: updatePersonalDetailsFailure,

  [Types.RESET_PASSWORD_START]: resetPasswordStart,
  [Types.RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
  [Types.RESET_PASSWORD_FAILURE]: resetPasswordFailure,
  [Types.RESET_SUCCESS]: resetSuccess,
  [Types.RESET_ERROR]: resetError
};

export const updatePersonalDetailsReducer = createReducer(INITIAL_STATE, HANDLERS);
