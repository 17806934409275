/* eslint-disable no-param-reassign */

import moment from 'moment';

/* eslint-disable radix */
export const timeStringFormat = (time) => {
  if (!time || typeof time !== 'string') return undefined;

  const timeSplitArray = time.split(':');

  if (timeSplitArray.length > 2) return undefined;

  const minute = parseInt(timeSplitArray[0]);
  const second = parseInt(timeSplitArray[1]);

  if (second > 59) return undefined;

  return {
    minute,
    second
  };
};

export const genTimeStrings = (props = {}) => {
  const isEarlierThanEndLimit = (timeValue, endLimit, lastValue) => {
    const timeValueIsEarlier = moment(timeValue, 'h:mm A').diff(moment(endLimit, 'h:mm A')) < 0;
    const timeValueIsLaterThanLastValue =
      lastValue === undefined ? true : moment(lastValue, 'h:mm A').diff(moment(timeValue, 'h:mm A')) < 0;
    return timeValueIsEarlier && timeValueIsLaterThanLastValue;
  };

  let timeValue = props.beginTime || '12:00 AM';
  let lastValue;
  const endLimit = props.endTime || '11:59 PM';
  const stp = props.step || 15;
  const options = [];
  options.push(props.objectType ? { label: timeValue, value: timeValue } : timeValue);

  while (isEarlierThanEndLimit(timeValue, endLimit, lastValue)) {
    lastValue = timeValue;
    timeValue = moment(timeValue, 'h:mm A').add(stp, 'minutes').format('h:mm A');
    options.push(props.objectType ? { label: timeValue, value: timeValue } : timeValue);
  }

  return options;
};
