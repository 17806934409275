import styled from 'styled-components';
import topLeft from '../../assets/card_bg_top_left.svg';
import bottomRight from '../../assets/card_bg_bottom_right.svg';
import { accentColor, fontSize, fontWeight } from '../../constants';

export const Container = styled.div`
  padding: 2.2rem 1.8rem;
  background-color: #241c39;
  background-image: url(${bottomRight}), url(${topLeft});
  background-position: right bottom, left top;
  background-repeat: no-repeat, no-repeat;
  border-radius: 0.6rem;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.2rem;
`;

export const TextLite = styled.p`
  font-size: ${fontSize.smallDefault};
  font-weight: ${fontWeight.medium};
  color: #8792ac;
  margin: 0.5rem 1rem 0rem 1rem;
`;

export const PlayerTitle = styled.h1`
  font-size: ${fontSize.mediumDefault};
  font-weight: ${fontWeight.medium};

  margin: 0 1rem;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  max-width: 350px;
`;

export const Btn = styled.div`
  font-size: ${fontSize.mediumDefault};
  color: ${accentColor.primary};
  cursor: pointer;
  user-select: none;
`;
