import React from 'react';
import { Input, Container, Search } from './HelpSearchStyles';
import { ReactComponent as SearchIcon } from '../../assets/Search.svg';

const HelpSearch = (props) => {
  const { onSearchChange, placeHolder } = props;
  return (
    <Container>
      <Input type='search' placeholder={placeHolder} onChange={(e) => onSearchChange(e.target.value)} />

      <Search>
        <SearchIcon />
      </Search>
    </Container>
  );
};

export default HelpSearch;
