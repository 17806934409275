import styled from 'styled-components';

import { fontSize, backgroundColor, fontWeight } from '../../../shared/constants';

export const ModalContainer = styled.div`
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${backgroundColor.popupBg};
`;

export const Card = styled.div`
  min-height: 230px;
  min-width: 311px;
  border-radius: 10px;

  padding: 35px 38px 43px 38px;
  position: relative;

  background-color: ${backgroundColor.popup};

  z-index: 9999;
`;

export const Title = styled.div`
  font-size: calc(${fontSize.titleVerySmall} - 0.1rem);
  font-weight: ${fontWeight.bold};
`;

export const CloseButton = styled.span`
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
`;

export const MarkDoneContainer = styled.span`
  width: 236px;
  height: 48px;
  border-radius: 10px;
  margin-bottom: 6px;

  display: flex;
  gap: 20px;
  align-items: center;
  padding: 0px 13px;

  background: none;
  transition: background ease-out 0.3s;
  cursor: pointer;

  &:hover {
    background: #111117;
  }
`;

export const MarkDoneText = styled.p`
  font-size: 16px;
  color: #eef0f3;
`;

export const InputManuallyContainer = styled.span`
  width: 236px;
  height: 48px;
  border-radius: 10px;

  display: flex;
  gap: 20px;
  align-items: center;
  padding: 0px 13px;

  background: none;
  transition: background ease-out 0.3s;
  cursor: pointer;

  &:hover {
    background: #111117;
  }
`;

export const InputManuallyText = styled.p`
  font-size: 16px;
  color: #eef0f3;
`;
