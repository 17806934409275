/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable arrow-body-style */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Creators } from './store';
import { Creators as GropuCreators } from '../GroupView/store';
import { ContentContainer, Form, FormTitle } from './PlannerStyles';

import { ReactComponent as CloseIcon } from '../../../shared/assets/Close.svg';
import { ModalComponent } from '../../../globalShared';
import { FormInput, SearchInput, DatePicker } from '../../../shared/components/FormComponents';
import { FlexContainer, ValdErrMsg } from '../../../globalShared/CommonStyles';
import { compareDates, getDayMonthYear, getToday, getYearMonthDay } from '../../../services/utility/moment';
import { localString } from '../../../services/locallization/localStrings';

// import Toast from '../../../services/toast';
// import { ErrorMsg } from '../../ProfessionalDetailsView/ProfessionalDetails.styles';

const CreatePlannerView = ({ onCancel }) => {
  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  // store to props
  const dispatch = useDispatch();
  const { groupList } = useSelector((state) => state.groupList);
  const plannerDetails = useSelector((state) => state.coachPlanner.plannerDetails);
  const success = useSelector((state) => state.coachPlanner.success);

  const fields = { title: '', groupId: '', groupName: '', startDate: '', endDate: '' };

  // form state
  const [formData, setFormData] = useState(fields);
  const [errors, setErrors] = useState({});

  const checkIsFormFilled = () => {
    let Isvalid = true;
    const errs = errors;

    if (!formData.title) {
      Isvalid = false;
      errs.title = string.required;
    }

    if (!formData.groupId) {
      Isvalid = false;
      errs.groupId = string.required;
    }

    if (!formData.startDate) {
      Isvalid = false;
      errs.startDate = string.required;
    }

    if (!formData.endDate) {
      Isvalid = false;
      errs.endDate = string.required;
    }
    setErrors({ ...errors, errs });
    return Isvalid;
  };

  useEffect(() => {
    if (plannerDetails) {
      const { title, _id, startDate, endDate, assignedId, assignedTo } = plannerDetails;
      setFormData({ title, _id, startDate, endDate, groupId: assignedId, groupName: assignedTo });
    }
  }, [plannerDetails]);

  const handleCancel = () => {
    dispatch(GropuCreators.resetGroupList());
    onCancel(false);
  };

  const handleSearchChange = (searchKey) => {
    if (searchKey) {
      dispatch(GropuCreators.searchGroupList({ searchKey }));
    } else {
      dispatch(GropuCreators.resetGroupList());
    }
  };

  const handleStartDate = (date) => {
    const value = getYearMonthDay(date);
    const { endDate } = formData;
    const today = getYearMonthDay(getToday());
    const isToday = today === value;
    const isNotCompletd = compareDates(value, today);
    const errs = errors;

    if ((isToday || isNotCompletd) && !endDate) {
      setFormData({ ...formData, startDate: value });
      errs.startDate = '';
      setErrors({ ...errors, errs });
    } else {
      errs.startDate = string.fromToday;
      setErrors({ ...errors, errs });
    }

    if ((endDate && isNotCompletd) || (isToday && endDate)) {
      const IsBefore = !compareDates(value, endDate);
      if (IsBefore) {
        setFormData({ ...formData, startDate: value });
        errs.startDate = '';
        setErrors({ ...errors, errs });
      } else {
        errs.startDate = string.ntBAfterEndDate;
        setErrors({ ...errors, errs });
      }
    }
  };

  const handleEndDate = (date) => {
    const value = getYearMonthDay(date);
    const { startDate } = formData;
    const today = getYearMonthDay(getToday());
    const isToday = today === value;
    const isNotCompletd = compareDates(value, today);
    const errs = errors;

    if ((isToday || isNotCompletd) && !startDate) {
      setFormData({ ...formData, endDate: value });
      errs.endDate = '';
      setErrors({ ...errors, errs });
    } else {
      errs.endDate = string.fromToday;
      setErrors({ ...errors, errs });
    }

    if ((startDate && isNotCompletd) || (isToday && startDate)) {
      const IsAfter = !compareDates(startDate, value);
      if (IsAfter) {
        setFormData({ ...formData, endDate: value });
        errs.endDate = '';
        setErrors({ ...errors, errs });
      } else {
        errs.endDate = string.ntBAfterEndDate;
        setErrors({ ...errors, errs });
      }
    }
  };

  const handleSetGroupValue = (value) => {
    setFormData({ ...formData, groupName: value.title, groupId: value._id });
    dispatch(Creators.resetGroupList());

    // on change handler
    const errs = errors;
    if (errs.groupId) {
      errs.groupId = '';
      setErrors({ ...errors, errs });
    }
  };

  const onSubmit = () => {
    if (checkIsFormFilled()) {
      dispatch(Creators.createPlanner({ ...formData }));
    }
  };

  useEffect(() => {
    if (success) {
      onCancel();
    }
  }, [success]);

  const handleTitle = (value) => {
    setFormData({ ...formData, title: value });
    const errs = errors;

    if (!value) {
      errs.title = string.required;
      setErrors({ ...errors, errs });
    } else {
      errs.title = '';
      setErrors({ ...errors, errs });
    }
  };

  return (
    <ModalComponent width='670' onCancel={handleCancel} onSubmit={onSubmit}>
      <ContentContainer>
        <Form>
          <FormTitle sty>Create Planner</FormTitle>
          <FormInput
            label={string.title}
            placeholder={string.plannerTitle}
            value={formData.title}
            styles={{ marginBottom: '2.5rem' }}
            setValue={handleTitle}
            error={!!errors.title}
          />
          <ValdErrMsg>{errors.title}</ValdErrMsg>
          <FlexContainer>
            <>
              <SearchInput
                label={string.assignedToGrp}
                placeholder={string.searchGroup}
                onChange={handleSearchChange}
                list={groupList}
                value={{ _id: formData.groupId, title: formData.groupName }}
                setValue={handleSetGroupValue}
              />
              {formData.groupId ? (
                <CloseIcon
                  style={{ marginLeft: '1rem', cursor: 'pointer' }}
                  onClick={() => setFormData({ ...formData, groupName: '', groupId: '' })}
                />
              ) : (
                ''
              )}
            </>
          </FlexContainer>
          <ValdErrMsg>{errors.groupId}</ValdErrMsg>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <DatePicker
                label={string.startDate}
                value={formData.startDate ? getDayMonthYear(formData.startDate) : ''}
                setValue={handleStartDate}
              />
              <ValdErrMsg style={{ marginTop: '0.5rem' }}>{errors.startDate}</ValdErrMsg>
            </div>
            <div>
              <DatePicker
                label={string.endDate}
                value={formData.endDate ? getDayMonthYear(formData.endDate) : ''}
                setValue={handleEndDate}
              />
              <ValdErrMsg style={{ marginTop: '0.5rem' }}>{errors.endDate}</ValdErrMsg>
            </div>
          </div>
        </Form>
      </ContentContainer>
    </ModalComponent>
  );
};

export default CreatePlannerView;
