/*
 * action.js file file for comapre view
 * Author:
 * 1.Manikanta
 *
 * @copyright of Panorbit Services LLP.
 */

import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  addVideoToCompare: ['payload'],
  resetCompareVideos: ['payload']
});

export { Types, Creators };
