/*
 * action.js file for coach search video view
 * Author:
 * 1.Manikanta
 *
 * @copyright of Panorbit Services LLP.
 */

import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getPlayerTeamVideo: ['payload'],
  getPlayerTeamVideoStart: ['payload'],
  getPlayerTeamVideoSuccess: ['payload'],
  getPlayerTeamVideoFailure: ['payload'],

  searchReset: null
});

export { Types, Creators };
