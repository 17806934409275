import styled from 'styled-components';
import { backgroundColor, fontSize, fontWeight } from '../../constants';

export const Container = styled.div`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${backgroundColor.popupBg};
`;

export const CardContainer = styled.div`
  min-height: 250px;
  min-width: 500px;
  border-radius: 10px;

  padding: 3rem;
  position: relative;

  background-color: ${backgroundColor.popup};

  z-index: 100;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-size: calc(${fontSize.titleVerySmall} - 0.1rem);
  font-weight: ${fontWeight.bold};
`;

export const CloseButton = styled.div`
  cursor: pointer;
`;

export const Body = styled.div`
  padding: 3rem 0 1.5rem;
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Width50 = styled.div`
  width: 48%;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Muted = styled.div`
  width: 100%;
  height: 45px;
  padding: 1rem;
  font-size: 1.5rem;
  color: #fff;
  border: none;
  border-radius: 0.6rem;
  background-color: rgba(78, 67, 105, 0.2);
`;

export const Label = styled.p`
  color: white;
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;
