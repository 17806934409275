import styled from 'styled-components';
import { textColors, fontSize, backgroundColor, gradient } from '../../constants';

export const Label = styled.p`
  color: ${textColors.textLight};
  font-size: ${fontSize.mediumDefault};
  margin-bottom: 1rem;
`;

export const Container = styled.div`
  height: 5rem;
  display: flex;
  align-items: center;
`;

export const IpGroup = styled.div`
  display: inline-block;
  margin: 0 0.5rem;
`;

export const Input = styled.input`
  -webkit-appearance: none;

  display: inline-block;
  width: 15px;
  height: 15px;

  background-clip: content-box;
  background-color: ${backgroundColor.darkOpaq};
  border-radius: 50%;

  box-shadow: 0 0 0 3pt ${backgroundColor.darkOpaq};

  &:checked {
    background: ${gradient.primary};
  }

  &:focus {
    outline: none;
  }
`;

export const InputC = styled.div`
  -webkit-appearance: none;

  display: inline-block;
  width: 15px;
  height: 15px;

  background-clip: content-box;
  background-color: ${backgroundColor.darkOpaq};
  border-radius: 50%;

  box-shadow: 0 0 0 3pt ${backgroundColor.darkOpaq};

  &.checked {
    background: ${gradient.primary};
  }

  &:focus {
    outline: none;
  }
`;

export const InputLabel = styled.label`
  color: ${textColors.textWhite};
  font-size: ${fontSize.mediumDefault};
  margin: 0 1rem;
`;
