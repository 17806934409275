import styled from 'styled-components';

import {
  fontSize,
  fontFamily,
  backgroundColor,
  fontWeight,
  textColors,
} from '../../../shared/constants';

export const TableStyles = {
  Table: styled.table`
    font-family: ${fontFamily.bebas}
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 1rem;

    & > td,
    th {
      white-space: nowrap;
      vertical-align: middle;
      border: 0;
      padding-right: -10rem;
    font-weight: ${fontWeight.medium}

    }
  `,

  Th: styled.th`
    font-size: ${fontSize.smallDefault};
    padding-right: rem;
    opacity: 0.5;
    padding: 1rem;
    text-align: left;
    text-transform: uppercase;
  `,

  Tr: styled.tr`
    background: ${backgroundColor.darkOpaq};
    margin-bottom: 1rem;

    &: hover {
      cursor: pointer;
    }

    & > td:first-child {
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
      padding: 1.5rem 1rem;
    }

    & > td:last-child {
      border-top-right-radius: 1rem;
      border-bottom-right-radius: 1rem;
    }
  `,

  Td: styled.td`
    font-size: ${fontSize.mediumDefault};
    padding: 0.25rem;
    color: #cbd1df;
  `,

  Ts: styled.td`
    font-size: ${fontSize.mediumDefault};
    padding: 1.1rem;
    color: #cbd1df;
  `
};

export const ScrollContainer = styled.div`
height: 500px;
overflow-y: scroll;
padding: 1rem;
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const TableParagraph = styled.p`
  color: ${textColors.textWhite};
  font-weight: ${fontWeight.bold};
  padding-left: 11px;
`;

export const ClickContainer = styled.div`
  cursor: pointer;
`;

export const ImageDiv = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 7px;
`;

export const PlayersCol = styled.div`
font-size: ${fontSize.mediumDefault};
padding: 0.25rem;
color: #cbd1df;
`;
