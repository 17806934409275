import styled from 'styled-components';
import { backgroundColor, borderColor, fontSize, fontWeight, textColors } from '../../../shared/constants';

export const CardContainer = styled.div`
  position: absolute;
  top: 50px;
  right: -90px;
  padding: 25px 27px;

  width: 435px;
  height: 681px;
  border-radius: 10px;
  border: 1px solid ${borderColor.light};
  background: #15151c;
  z-index: 9999999;
`;

export const CardHeader = styled.h2`
  font-size: ${fontSize.titleVerySmall};
  font-weight: ${fontWeight.bold};
  margin-bottom: 20px;
`;

export const TodaysNotification = styled.div`
  margin-bottom: 40px;
`;

export const NotificationHeader = styled.p`
  font-size: 14px;
  font-weight: ${fontWeight.medium};
  color: #d0d5e2;
  text-transform: uppercase;
  margin-bottom: 20px;
`;

export const Card = styled.div`
  width: 100%;
  min-height: 80px;
  padding: 16px 14px;
  background: ${backgroundColor.darkOpaq};
  border-radius: 6px;

  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
`;

export const NotifDetails = styled.span`
  display: flex;
  width: 100%;
`;

export const ProfilePicContainer = styled.div`
  position: relative;
  width: 53px;
  height: 53px;

  border: 1px solid #413c53;
  border-radius: 6px;
  margin-right: 13px;
`;

export const NewNotificationDot = styled.span`
  position: absolute;
  top: -3px;
  right: -3px;

  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #c64242;
  z-index: 5;
`;

export const ProfilePic = styled.span`
  position: absolute;
  height: 50px;
  width: 50px;

  border-radius: 6px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const Notification = styled.span`
  width: 235px;
  & > p {
    display: inline-block;
    font-size: 14px;
    font-weight: ${fontWeight.medium};
    margin-right: 3px;
    line-height: 24px;
  }
`;

export const TextSmallLight = styled.p`
  display: inline-block;
  font-size: ${fontSize.smallDefault};
  font-weight: ${fontWeight.medium};
  color: ${textColors.textLight};
`;

export const NotifTime = styled.p`
  margin-top: 5px;
  white-space: nowrap;
  font-size: 12px;
  font-weight: ${fontWeight.medium};
  color: #454c5d;
`;
