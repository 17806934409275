/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable max-len */
import { createReducer } from 'reduxsauce';

import { Types } from './action';

const INITIAL_STATE = {
  groupPlayers: null,
  playersModal: false,
  historyModal: false,
  addGroupModal: false,
  addGroupModalMode: 'ADD',
  historyModalData: [],
  loading: false,
  error: null,
  success: false,
  newGroupPlayers: [],
  addGroupResult: null,
  pageNo: 1,
  countNo: 10,
  reload: false
};

const getSelectedGroup = (state = INITIAL_STATE, action) => {
  const { groupPlayers } = action.payload;
  return {
    ...state,
    groupPlayers
  };
};

const setPlayersModal = (state = INITIAL_STATE, action) => {
  const { playersModal } = action.payload;
  return {
    ...state,
    playersModal
  };
};

const setHistoryModal = (state = INITIAL_STATE, action) => {
  const { historyModal } = action.payload;
  return {
    ...state,
    historyModal
  };
};

const setAddGroupModal = (state = INITIAL_STATE, action) => {
  const { addGroupModal, addGroupModalMode } = action.payload;
  return {
    ...state,
    addGroupModal,
    addGroupModalMode
  };
};

const setHistoryModalData = (state = INITIAL_STATE, action) => {
  const { historyModalData } = action.payload;
  return {
    ...state,
    historyModalData
  };
};

// PlayerSearch action
const getGroupPlayersSearchListStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getGroupPlayersSearchListSuccess = (state = INITIAL_STATE, action) => {
  const { newGroupPlayers } = action.payload;
  newGroupPlayers.forEach((item) => (item.title = item.fullName));
  return {
    ...state,
    loading: false,
    newGroupPlayers
  };
};

const getGroupPlayersSearchListFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

// Add group action
const getAddGroupStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true, reload: true });

const getAddGroupSuccess = (state = INITIAL_STATE, action) => {
  const { addGroupResult } = action.payload;
  return {
    ...state,
    loading: false,
    addGroupResult,
    reload: true,
    success: true
  };
};

const getAddGroupFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const editDeleteGroupStart = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  reload: false
});

const editDeleteGroupSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  success: true,
  reload: true
});

const editDeleteGroupFailure = (state = INITIAL_STATE, action) => {
  const {
    payload: { error }
  } = action;
  return {
    ...state,
    loading: false,
    error
  };
};

const searchReset = () => ({ ...INITIAL_STATE });

const resetReload = (state = INITIAL_STATE) => ({
  ...state,
  reload: false
});

const resetSuccess = (state = INITIAL_STATE) => ({
  ...state,
  success: false
});

const resetError = (state = INITIAL_STATE) => ({
  ...state,
  error: null
});

const HANDLERS = {
  [Types.GET_SELECTED_GROUP]: getSelectedGroup,
  [Types.SET_PLAYERS_MODAL]: setPlayersModal,
  [Types.SET_HISTORY_MODAL]: setHistoryModal,
  [Types.SET_ADD_GROUP_MODAL]: setAddGroupModal,
  [Types.SET_HISTORY_MODAL_DATA]: setHistoryModalData,
  [Types.GET_GROUP_PLAYERS_SEARCH_LIST_START]: getGroupPlayersSearchListStart,
  [Types.GET_GROUP_PLAYERS_SEARCH_LIST_SUCCESS]: getGroupPlayersSearchListSuccess,
  [Types.GET_GROUP_PLAYERS_SEARCH_LIST_FAILURE]: getGroupPlayersSearchListFailure,
  [Types.GET_ADD_GROUP_START]: getAddGroupStart,
  [Types.GET_ADD_GROUP_SUCCESS]: getAddGroupSuccess,
  [Types.GET_ADD_GROUP_FAILURE]: getAddGroupFailure,
  [Types.EDIT_DELETE_GROUP_START]: editDeleteGroupStart,
  [Types.EDIT_DELETE_GROUP_SUCCESS]: editDeleteGroupSuccess,
  [Types.EDIT_DELETE_GROUP_FAILURE]: editDeleteGroupFailure,
  [Types.SEARCH_RESET]: searchReset,
  [Types.RESET_RELOAD]: resetReload,
  [Types.RESET_SUCCESS]: resetSuccess,
  [Types.RESET_ERROR]: resetError
};

export const modalReducer = createReducer(INITIAL_STATE, HANDLERS);
