/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cookie } from '../../services/cookies/cookieServices';
import Toast, { Toasts } from '../../services/toast';

import { Creators } from './store';

import PersonalDetailsView from '../PersonalDetails/PersonalDetails';
import ProfessionalDetailsView from '../ProfessionalDetailsView/ProfessionalDetailsView';
import { LoadingComponent } from '../../globalShared';

// styles
import { MainContainer } from './PlayerDetails.styles';

/**
 *
 * component
 */
const PlayerDetailsView = (props) => {
  const [userDetails] = useState(cookie.get('userDetails'));
  const [step, setStep] = useState(1);

  const dispatch = useDispatch();

  // state values
  const personalDetails = useSelector((state) => state.personalDetails);
  const loading = useSelector((state) => state.personalDetails.loading);
  const ProfileCreated = useSelector((state) => state.userMeta.success);
  const stateError = useSelector((state) => state.userMeta.error);
  const loading2 = useSelector((state) => state.userMeta.loading);

  const onSubmit = (professionalData) => {
    const payload = { ...personalDetails, ...professionalData };
    dispatch(Creators.setUserMeta(payload));
  };

  const goToNext = () => {
    setStep(2);
  };

  const goToPrev = () => {
    setStep(1);
  };

  useEffect(() => {
    if (step !== 1 && step !== 2) {
      setStep(1);
    }
  }, [step]);

  const primarySubmit = (data) => {
    if (userDetails?.userType !== 'Player') {
      const payload = { ...data };
      dispatch(Creators.setUserMeta(payload));
      return;
    }

    goToNext();
  };

  useEffect(() => {
    if (ProfileCreated) {
      props.history.push('/thankyou');
    }
  }, [ProfileCreated]);

  // useEffect(() => {
  //   console.log(personalDetails);
  // }, [personalDetails]);

  useEffect(() => {
    if (stateError) {
      Toast.error(stateError, 2);
    }
  }, [stateError]);

  return (
    <>
      {(loading || loading2) && <LoadingComponent />}
      <MainContainer>
        {step === 1 ? <PersonalDetailsView buttonAction={primarySubmit} /> : ''}
        {step === 2 ? <ProfessionalDetailsView backButtonAction={goToPrev} submitButtonAction={onSubmit} /> : ''}
        <Toasts />
      </MainContainer>
    </>
  );
};

export default PlayerDetailsView;
