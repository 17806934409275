import styled from 'styled-components';

import { Link } from 'react-router-dom';
import { fontSize, fontWeight, accentColor } from '../../../../shared/constants';
import { Divider } from '../../../../globalShared/CommonStyles';

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const HeaderTxt = styled.h1`
  font-size: ${fontSize.titleVerySmall};
  font-weight: ${fontWeight.bold};
  margin: 0 1rem;
  text-transform: capitalize;
`;

export const CustomisedDivider = styled(Divider)`
  margin: 1rem 0 0 0;
`;

export const Container = styled.div`
  height: 475px;
  overflow-y: auto;
  padding: 2rem 0.5rem;
`;

export const Card = styled.div`
  width: 100%;
  background: #261d3c;
  border-radius: 6px;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
`;

export const Linkto = styled(Link)`
  font-size: ${fontSize.title};
  color: ${accentColor.primary};

  &:focus {
    outline: 0px;
  }
`;

export const VideoPlayerContainer = styled.div`
  height: 293px;
  background: #000;
`;

export const PlayerTitle = styled.h1`
  font-size: ${fontSize.mediumDefault};
  font-weight: ${fontWeight.medium};

  margin: 0 1rem;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

export const VideoUploadedAT = styled.p`
  font-size: ${fontSize.smallDefault};
  font-weight: ${fontWeight.medium};
  color: #8792ac;
  margin: 0.5rem 1rem;
`;

export const MsgContainer = styled.div`
  padding: 1.5rem 2.5rem;
  width: 100%;
  height: 60vh;
`;
