import styled from 'styled-components';
import { accentColor, backgroundColor, fontSize, gradient, textColors } from '../../constants';

export const CardContainer = styled.div`
  width: 100%;
  height: auto;

  padding: 2rem;
  margin: 1rem 0;

  background-color: ${backgroundColor.card};
  border: ${(props) => (props.active ? '1px' : '0px')} solid ${accentColor.primary};
  border-radius: 6px;

  position: relative;
  cursor: pointer;
`;

export const Active = styled.div`
  height: 25px;
  width: 2px;
  background: ${gradient.primary};
  border-radius: 0 5px 5px 0;
  position: absolute;
  top: 17px;
  left: 0;
`;

export const Title = styled.p`
  font-size: ${fontSize.mediumDefault};
  margin-left: 1rem;
  word-break: break-word;

  max-width: 230px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
`;

export const Status = styled.p`
  font-size: ${fontSize.smallDefault};
  color: ${accentColor.secondary};
`;

export const ProgressContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Progressbar = styled.div`
  height: 5px;
  background-color: ${backgroundColor.darkOpaq};
  border-radius: 5px;
  width: 100%;
`;

export const Progress = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: ${fontSize.medium};
`;

export const P = styled.p`
  font-size: ${fontSize.smallDefault};
  color: ${(props) => (props.light ? `${textColors.textLight}` : `${textColors.textWhite}`)};
`;

export const Details = styled.div`
  background-color: ${backgroundColor.darkOpaq};
  border-radius: 5px;
  padding: 2rem 1.5rem;
  margin: 1.5rem auto;
  box-shadow: 0px 4px 8px #00000040;
`;

export const Complete = styled.img`
  width: 50;
  height: 50px;

  border-radius: 0.6rem;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const ImageStack = styled.div`
  width: 110px;
  height: 100%;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  & > .before {
    transform: rotate(7deg);
  }

  & > .after {
    transform: rotate(-7deg);
  }
`;

export const Thumbnail = styled.img`
  width: 88px;
  height: 48px;

  border-radius: 0.6rem;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
`;
