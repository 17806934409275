/* eslint-disable no-unused-vars */
/*
 * reducer.js file for skills view
 * Author:
 * 1.Manikanta
 * @copyright of Panorbit Services LLP.
 */

import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  loading: false,
  error: null,
  videosList: [],
  maxPage: 1,
  reload: false,
  success: false,
  collectionsList: [],
  collectionVideos: [],
  currentCollectionId: '',
  collectionReload: false,
  showManageCollection: false,
  showCreateCollection: false,
  showEditCollection: false,
  showDeleteCollection: false,
  showAddToAnotherCollection: false,
  videosCount: 0,
  uploading: false,
  collectionLoading: false
};

const getPlayerVideosListStart = (state = INITIAL_STATE, action) => ({ ...state, error: null, loading: true });

const getPlayerVideosListSuccess = (state = INITIAL_STATE, action) => {
  const { videosList, maxPage, totalCount } = action.payload;
  return {
    ...state,
    loading: false,
    reload: false,
    videosList,
    maxPage,
    videosCount: totalCount
  };
};

const getPlayerVideosListFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const getPlayerCollectionsListStart = (state = INITIAL_STATE, action) => ({
  ...state,
  error: null,
  loading: true,
  collectionReload: false
});

const getPlayerCollectionsListSuccess = (state = INITIAL_STATE, action) => {
  const { collectionsList } = action.payload;
  return {
    ...state,
    loading: false,
    reload: false,
    collectionsList
  };
};

const getPlayerCollectionsListFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const getPlayerCollectionVideosListStart = (state = INITIAL_STATE, action) => ({
  ...state,
  error: null,
  collectionLoading: true
});

const getPlayerCollectionVideosListSuccess = (state = INITIAL_STATE, action) => {
  const { collectionVideos } = action.payload;
  return {
    ...state,
    collectionLoading: false,
    reload: false,
    collectionVideos
  };
};

const getPlayerCollectionVideosListFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    collectionLoading: false,
    error
  };
};

const searchPlayerVideoStart = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  error: null
});

const searchPlayerVideoSuccess = (state = INITIAL_STATE, action) => {
  const { videosList, totalCount } = action.payload;
  return {
    ...state,
    loading: false,
    videosList,
    videosCount: totalCount
  };
};

const searchPlayerVideoFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const setShowManageCollectionModal = (state = INITIAL_STATE, action) => {
  const { showManageCollection } = action.payload;

  return {
    ...state,
    showManageCollection
  };
};

const setShowCreateCollectionModal = (state = INITIAL_STATE, action) => {
  const { showCreateCollection } = action.payload;

  return {
    ...state,
    showCreateCollection
  };
};

const setShowEditCollectionModal = (state = INITIAL_STATE, action) => {
  const { showEditCollection } = action.payload;

  return {
    ...state,
    showEditCollection
  };
};

const setShowDeleteCollectionModal = (state = INITIAL_STATE, action) => {
  const { showDeleteCollection } = action.payload;

  return {
    ...state,
    showDeleteCollection
  };
};

const setShowAddToAnotherCollectionModal = (state = INITIAL_STATE, action) => {
  const { showAddToAnotherCollection } = action.payload;

  return {
    ...state,
    showAddToAnotherCollection
  };
};

const setCurrentCollectionId = (state = INITIAL_STATE, action) => {
  const { currentCollectionId } = action.payload;

  return {
    ...state,
    currentCollectionId
  };
};

const setPlayerCollectionNameStart = (state = INITIAL_STATE, action) => ({
  ...state,
  error: null,
  collectionLoading: true
});

const setPlayerCollectionNameSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  error: null,
  collectionLoading: false,
  success: true,
  collectionReload: true
});

const setPlayerCollectionNameFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    collectionLoading: false,
    error
  };
};

const deletePlayerCollectionStart = (state = INITIAL_STATE, action) => ({
  ...state,
  error: null,
  collectionLoading: true,
  reload: false
});

const deletePlayerCollectionSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  error: null,
  collectionLoading: false,
  success: true,
  collectionReload: true,
  reload: true
});

const deletePlayerCollectionFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    collectionLoading: false,
    error
  };
};

const addToAnotherCollectionStart = (state = INITIAL_STATE, action) => ({
  ...state,
  error: null,
  collectionLoading: true,
  collectionReload: false,
  reload: false
});

const addToAnotherCollectionSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  error: null,
  collectionLoading: false,
  success: true,
  collectionReload: true,
  reload: true
});

const addToAnotherCollectionFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    collectionLoading: false,
    error
  };
};

const skillsReset = (state = INITIAL_STATE) => ({ ...INITIAL_STATE });

const uploadVideoStart = (state = INITIAL_STATE, action) => ({
  ...state,
  error: null,
  reload: false,
  uploading: true
});

const uploadVideoSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  reload: true,
  success: true,
  uploading: false
});

const uploadVideoFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    uploading: false,
    error
  };
};

const resetSuccess = (state = INITIAL_STATE, action) => ({ ...state, success: false, collectionLoading: false });

const resetError = (state = INITIAL_STATE, action) => ({ ...state, error: null });

const HANDLERS = {
  [Types.GET_PLAYER_VIDEOS_LIST_START]: getPlayerVideosListStart,
  [Types.GET_PLAYER_VIDEOS_LIST_SUCCESS]: getPlayerVideosListSuccess,
  [Types.GET_PLAYER_VIDEOS_LIST_FAILURE]: getPlayerVideosListFailure,

  [Types.GET_PLAYER_COLLECTIONS_LIST_START]: getPlayerCollectionsListStart,
  [Types.GET_PLAYER_COLLECTIONS_LIST_SUCCESS]: getPlayerCollectionsListSuccess,
  [Types.GET_PLAYER_COLLECTIONS_LIST_FAILURE]: getPlayerCollectionsListFailure,

  [Types.GET_PLAYER_COLLECTION_VIDEOS_LIST_START]: getPlayerCollectionVideosListStart,
  [Types.GET_PLAYER_COLLECTION_VIDEOS_LIST_SUCCESS]: getPlayerCollectionVideosListSuccess,
  [Types.GET_PLAYER_COLLECTION_VIDEOS_LIST_FAILURE]: getPlayerCollectionVideosListFailure,

  [Types.SEARCH_PLAYER_VIDEO_START]: searchPlayerVideoStart,
  [Types.SEARCH_PLAYER_VIDEO_SUCCESS]: searchPlayerVideoSuccess,
  [Types.SEARCH_PLAYER_VIDEO_FAILURE]: searchPlayerVideoFailure,

  [Types.SET_SHOW_MANAGE_COLLECTION_MODAL]: setShowManageCollectionModal,
  [Types.SET_SHOW_CREATE_COLLECTION_MODAL]: setShowCreateCollectionModal,
  [Types.SET_SHOW_EDIT_COLLECTION_MODAL]: setShowEditCollectionModal,
  [Types.SET_SHOW_DELETE_COLLECTION_MODAL]: setShowDeleteCollectionModal,
  [Types.SET_SHOW_ADD_TO_ANOTHER_COLLECTION_MODAL]: setShowAddToAnotherCollectionModal,
  [Types.SET_CURRENT_COLLECTION_ID]: setCurrentCollectionId,

  [Types.SET_PLAYER_COLLECTION_NAME_START]: setPlayerCollectionNameStart,
  [Types.SET_PLAYER_COLLECTION_NAME_SUCCESS]: setPlayerCollectionNameSuccess,
  [Types.SET_PLAYER_COLLECTION_NAME_FAILURE]: setPlayerCollectionNameFailure,

  [Types.DELETE_PLAYER_COLLECTION_START]: deletePlayerCollectionStart,
  [Types.DELETE_PLAYER_COLLECTION_SUCCESS]: deletePlayerCollectionSuccess,
  [Types.DELETE_PLAYER_COLLECTION_FAILURE]: deletePlayerCollectionFailure,

  [Types.ADD_TO_ANOTHER_COLLECTION_START]: addToAnotherCollectionStart,
  [Types.ADD_TO_ANOTHER_COLLECTION_SUCCESS]: addToAnotherCollectionSuccess,
  [Types.ADD_TO_ANOTHER_COLLECTION_FAILURE]: addToAnotherCollectionFailure,

  [Types.UPLOAD_VIDEO_START]: uploadVideoStart,
  [Types.UPLOAD_VIDEO_SUCCESS]: uploadVideoSuccess,
  [Types.UPLOAD_VIDEO_FAILURE]: uploadVideoFailure,

  [Types.SKILLS_RESET]: skillsReset,
  [Types.RESET_SUCCESS]: resetSuccess,
  [Types.RESET_ERROR]: resetError
};

export const playerSkillsReducer = createReducer(INITIAL_STATE, HANDLERS);
