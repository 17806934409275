/* eslint-disable arrow-body-style */
import React from 'react';
import { useSelector } from 'react-redux';
import { Container, P, H1 } from './ScoreCardStyles';
import { FlexContainer } from '../../../globalShared/CommonStyles';

// svg icons
import Stripes from '../../assets/stripes.svg';
import { localString } from '../../../services/locallization/localStrings';

// componet
const index = ({ score = 0, percent = 0 }) => {
  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  return (
    <Container>
      <FlexContainer style={{ justifyContent: 'space-between' }}>
        <div style={{ marginRight: '5rem' }}>
          <H1>{string.tcrScore}</H1>
          <P light>{`${string.uRperforming} ${percent} % ${string.otherPlayers}`}</P>
        </div>
        <H1 style={{ fontSize: '4rem' }}>{score}</H1>
      </FlexContainer>
      <img src={Stripes} alt='' />
    </Container>
  );
};

export default index;
