/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import styled, { css } from 'styled-components';

import { fontSize, backgroundColor, textColors, borderColor, accentColor } from '../../../constants';

export const MainContainer = styled.div`
  margin-bottom: 2rem;
  position: relative;
`;

export const Container = styled.div`
  width: 100%;
  height: 45px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: ${backgroundColor.dark};
  border-radius: 0.6rem;
  padding: 1rem;
  padding-right: 2rem;

  position: relative;

  text-transform: capitalize;

  cursor: pointer;

  ${(props) =>
    props.isVisible &&
    props.position === 'bottom' &&
    css`
      border: 1px solid ${borderColor.medium};
      border-bottom: none;
      border-radius: 0.6rem 0.6rem 0 0;
    `}

  ${(props) =>
    props.isVisible &&
    props.position === 'top' &&
    css`
      border: 1px solid ${borderColor.medium};
      border-top: none;
      border-radius: 0 0 0.6rem 0.6rem;
    `}

  & > svg {
    transform: ${(props) => (props.isVisible ? 'rotateX(180deg)' : 'rotateX(0deg)')};
    margin-left: auto;
    width: 20px;
    position: absolute;
    right: 10px;
  }

  & > p {
    font-size: ${fontSize.mediumDefault};
  }
`;

export const Label = styled.p`
  color: ${textColors.textLight};
  font-size: ${fontSize.mediumDefault};
  margin-bottom: 1rem;
  height: 16px;
`;

export const DropdownContainer = styled.div`
  width: 100%;
  height: auto;

  background-color: ${backgroundColor.dark};

  border: 1px solid ${borderColor.medium};
  padding: 0 1rem 1rem 1rem;
  position: absolute;
  z-index: 999;
  overflow-y: auto;

  ${(props) =>
    props.maxHeight &&
    css`
      max-height: ${props.maxHeight}px;
    `}

  ${(props) =>
    props.position === 'top' &&
    css`
      border-radius: 0.6rem 0.6rem 0 0;
      bottom: 45px;
      border-bottom: none;
    `}

    ${(props) =>
    props.position === 'bottom' &&
    css`
      border-radius: 0 0 0.6rem 0.6rem;
      border-top: none;
    `}
`;

export const Item = styled.div`
  font-size: ${fontSize.mediumDefault};
  color: ${textColors.textWhite};
  padding: 0.8rem 0;
  cursor: pointer;
  text-transform: capitalize;
`;

export const Add = styled(Item)`
  color: ${accentColor.primary};
  padding-left: 1rem;
`;

export const AddButton = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid ${borderColor.light};
  margin-top: 0.5rem;
  padding: 0.5rem 0;
`;
