import styled from 'styled-components';
import { backgroundColor, borderColor, fontSize, fontWeight, textColors } from '../../../shared/constants';

export const ModalContainer = styled.div`
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  justify-content: flex-end;

  background-color: ${backgroundColor.popupBg};
`;

export const MainContainer = styled.div`
  height: 100%;
  width: ${(props) => props.width}px;
  padding: 1rem 3rem;

  background-color: ${backgroundColor.popup};
  border-radius: 6px 0px 0px 6px;

  overflow-y: auto;
`;

export const P = styled.p`
  font-size: ${fontSize.mediumDefault};
  font-weight: ${fontWeight.medium};

  word-break: break-word;

  & > span {
    font-size: ${fontSize.medium};
    color: ${textColors.textLight};
    padding: 0 0.5rem;
  }
`;

export const Content = styled.div`
  padding: 3rem 0;
`;

export const BorderBox = styled.div`
  border-radius: 6px;
  border: 1px solid ${borderColor.light};
  margin: 2.5rem auto;
`;

export const TimesContainer = styled.div`
  width: 100%;
  display: flex;
  & > div {
    width: 50%;
  }
`;

export const InfoText = styled.p`
  font-size: ${fontSize.medium};
  text-transform: capitalize;
  margin-top: 1rem;
  color: ${textColors.textLight};

  & > span {
    color: ${textColors.textWhite};
    font-size: ${fontSize.medium};
    margin: 0 0.5rem;
  }
`;

export const Workout = styled.div`
  background-color: ${(props) => (props.blue ? '#4e436933' : '#2d2d33')};
  border-radius: 6px;
  padding: 2rem;
  margin: 1rem 0;
`;

export const Exercise = styled.p`
  font-size: 1rem;
  color: #b5b3b3;
  padding-top: 5px;
`;
export const SvgC = styled.div`
  &.active > svg {
    transform: rotate(180deg);
  }
`;
