/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { CloseButton, FlexContainer } from '../../../../../globalShared/CommonStyles';
import Accordian from './Accordian';

import { AvatarComponent, CommentsComponent, LoadingComponent, TabsComponenet } from '../../../../../globalShared';

import {
  H1,
  H2,
  P,
  Title,
  TextLite,
  StatusContainer,
  StatusHeader,
  MainContainer,
  ModalContainer,
  SummaryBlock,
  ModalBody,
  CommentsBlock,
  Stats,
  Stat,
  SessionsContainer
} from './SessionStyles';

// icons
import { ReactComponent as CloseIcon } from '../../../../../shared/assets/Close.svg';
import { ReactComponent as ClockIcon } from '../../../../../shared/assets/clock.svg';
import { ReactComponent as DumbellIcon } from '../../../../../shared/assets/dummbell.svg';
import { ReactComponent as RepsIcon } from '../../../../../shared/assets/reps.svg';
import { ReactComponent as SetsIcon } from '../../../../../shared/assets/sets.svg';
import { ReactComponent as WeightIcon } from '../../../../../shared/assets/weight.svg';

import { Creators } from './store';
import { Creators as StrengthFeedCreators } from '../store';
import { Creators as CommonCreators } from '../../../../store';

import { dayFormater, shortenText } from '../../../../../services/utility';
import { getTime } from '../../../../../services/utility/moment';
import { _converIntoKAndM } from '../../../../../services/utility/commurize';

// eslint-disable-next-line arrow-body-style
const RenderSession = ({ session }) => {
  const dispatch = useDispatch();
  const { status, updatedTime, sessionSets, sessionId, sessionReps, sessionWeight, sessionVolume, workout } = session;
  const { commentCount, maxCommentPages, commentsList } = useSelector((state) => state.coachStrengthFeedDetails);

  const reloadComments = useSelector((state) => state.main.reloadComments);
  const [page, setPage] = useState(1);

  const handleUpdateComments = () => {
    if (maxCommentPages > page) {
      setPage(page + 1);
    }
  };

  const handleSubmitComment = (content, id) => {
    dispatch(CommonCreators.addComment({ content, id }));
  };

  useEffect(() => {
    dispatch(Creators.getSessionComments({ id: sessionId, page }));
  }, [page]);

  useEffect(() => {
    if (reloadComments) {
      dispatch(Creators.getSessionComments({ id: sessionId }));
    }
  }, [reloadComments]);

  useEffect(
    () => () => {
      dispatch(Creators.resetComments());
    },
    []
  );

  return (
    <ModalBody>
      <SummaryBlock>
        <StatusContainer>
          <StatusHeader>
            <H2>Session Stats:</H2>
            <FlexContainer>
              <P>
                <span>Status:</span>
              </P>
              <P>{status}</P>
              <ClockIcon style={{ marginLeft: '1rem' }} />
              <P>
                <span>{getTime(updatedTime)}</span>
              </P>
            </FlexContainer>
          </StatusHeader>

          <Stats>
            <Stat>
              <SetsIcon />
              <H1>{sessionSets}</H1>
              <span>Sets</span>
            </Stat>
            <Stat>
              <RepsIcon />
              <H1>{sessionReps}</H1>
              <span>Reps</span>
            </Stat>
            <Stat>
              <WeightIcon />
              <H1>{_converIntoKAndM(sessionWeight)}</H1>
              <span>Weight(Kg)</span>
            </Stat>
            <Stat>
              <DumbellIcon />
              <H1>{_converIntoKAndM(sessionVolume)}</H1>
              <span>Total Volume(kg)</span>
            </Stat>
          </Stats>
        </StatusContainer>
        <SessionsContainer>
          {workout.map((exercise) => (
            <Accordian exercise={exercise} key={v4()} />
          ))}
        </SessionsContainer>
      </SummaryBlock>
      <CommentsBlock>
        <CommentsComponent
          count={commentCount || 0}
          updateComments={handleUpdateComments}
          submitComment={handleSubmitComment}
          commentsList={commentsList}
          id={sessionId}
        />
      </CommentsBlock>
    </ModalBody>
  );
};

const SessionDetails = () => {
  const dispatch = useDispatch();

  const { session } = useSelector((state) => state.coachStrengthFeed);
  const sessionDeatils = useSelector((state) => state.coachStrengthFeedDetails.sessionDeatils);
  // commnets  state vales

  const loading = useSelector((state) => state.coachStrengthFeedDetails.loading);

  // onsubmiting comment

  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [selectedSesion, setSelectedSesion] = useState(session[0]);
  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    if (session.length) {
      setSelectedSesion(session[0]);
      const newTabs = session.map((i) => i.sessionName);
      setTabs(newTabs);
    }
  }, [session]);

  useEffect(() => {
    if (sessionDeatils.length) {
      setSessions(sessionDeatils);
    }
  }, [sessionDeatils]);

  useEffect(() => {
    const sessionList = session.map((i) => i.sessionId);
    dispatch(Creators.getCoachStrengthFeedDetails({ sessionList }));
  }, []);

  const handleClose = () => {
    dispatch(StrengthFeedCreators.selectedSession({ session: [] }));
    dispatch(StrengthFeedCreators.setShowDetailsModal({ show: false }));
  };

  return (
    <>
      {loading && <LoadingComponent />}

      <ModalContainer>
        <MainContainer>
          <CloseButton position='1150' onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
          <FlexContainer>
            <AvatarComponent url={selectedSesion.profilePicture} />
            <div>
              <Title>{shortenText(selectedSesion.userName, 50, '...')}</Title>
              <TextLite>{dayFormater(selectedSesion.updatedTime)}</TextLite>
            </div>
          </FlexContainer>

          {tabs.length && sessions.length ? (
            <TabsComponenet getactiveTab={(tab) => setActiveTab(tab)}>
              {tabs.map((tab) => {
                const selectedSession = sessions.find(({ sessionName }) => sessionName === tab);

                return (
                  <div label={tab} key={tab + 1}>
                    {selectedSession && <RenderSession session={selectedSession} />}
                  </div>
                );
              })}
            </TabsComponenet>
          ) : (
            <LoadingComponent />
          )}
        </MainContainer>
      </ModalContainer>
    </>
  );
};

export default SessionDetails;
