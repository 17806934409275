import styled from 'styled-components';
import { backgroundColor, borderColor, fontSize, textColors } from '../../../shared/constants';

export const ModalContainer = styled.div`
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  justify-content: flex-end;

  background-color: ${backgroundColor.popupBg};
  z-index: 99999;
`;

export const ContentContainer = styled.div`
  height: 100%;
  width: 782px;

  padding: 2.4rem;

  background-color: ${backgroundColor.popup};
  border-radius: 6px 0px 0px 6px;

  overflow-y: auto;
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 806px;
  top: 2rem;
  z-index: 999;
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const Header = styled.div`
  padding-bottom: 30px;
`;
export const H1 = styled.h1`
  font-size: ${fontSize.large};
`;

export const H2 = styled.p`
  font-size: ${fontSize.mediumDefault};
`;

export const TextSmallLight = styled.p`
  font-size: ${fontSize.smallDefault};
  color: ${textColors.textLight};
`;

export const BedTimeContainer = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 3.5rem;
  width: 100%;
  border: 1px solid ${borderColor.light};
  padding: 1rem;
  border-radius: 6px;
`;

export const BedTimes = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5rem 0;
`;

export const Text = styled.div`
  margin-right: 1.4rem;

  display: flex;
  align-items: center;

  & > span {
    margin-right: 0.6rem;
  }

  & > p {
    display: inline-block;
    font-size: ${fontSize.medium};
  }
`;

export const Sleep = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 0.6rem;
  border: 1px solid ${borderColor.light};
`;

export const LegendButtons = styled.div`
  padding: 5px 15px;
  background: #c4c4c41a;
  border-radius: 13px;
  font-size: 12px;

  border: ${(props) => (props.active ? '1px' : '0px')} solid #b5b7bc;
  margin: 2rem 1rem;
  cursor: pointer;
  text-transform: capitalize;
`;
