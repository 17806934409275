/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled from 'styled-components';
import { borderColor, fontWeight } from '../../constants';
import MoodIcon from '../MoodIcon';

const StatsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  border: 1px solid ${borderColor.light};
  border-radius: 6px;
  padding: 18px 11px !important;
  margin-bottom: 15px;
`;

const StatsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const MoodCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 5px;
  padding: 2px 6px;
  background: white;
  border-radius: 9px;

  font-size: 12px;
  font-weight: ${fontWeight.medium};
  color: #1c162d;

  background: ${(props) => props.mood === 'level6' && '#70C54D'};
  background: ${(props) => props.mood === 'level5' && '#1EA79C'};
  background: ${(props) => props.mood === 'level4' && '#E6D544'};
  background: ${(props) => props.mood === 'level3' && '#EC8541'};
  background: ${(props) => props.mood === 'level2' && '#DF4A37'};
  background: ${(props) => props.mood === 'level1' && '#E23433'};
`;

const MooodCount = ({ moods }) => (
  <StatsContainer>
    {Object.entries(moods).map((mood, i) => (
      <StatsWrapper key={i}>
        <MoodIcon type={mood[0]} />
        <MoodCount mood={mood[0]}>{mood[1]}</MoodCount>
      </StatsWrapper>
    ))}
  </StatsContainer>
);

export default MooodCount;
