import styled from 'styled-components';
import { borderColor, gradient, fontSize, fontWeight, textColors } from '../../constants';

export const TabsContainer = styled.div`
  display: flex;
  border-bottom: 1px solid ${borderColor.light};
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const Tab = styled.div`
  padding: 2rem 1.5rem;
  text-transform: capitalize;
  cursor: pointer;
  position: relative;
  max-width: 200px;

  &:first-child {
    padding-left: 0.5rem !important;
  }

  &:after {
    content: '';
    height: ${(props) => (props.isActive ? '2px' : '0')};
    width: 100%;
    position: absolute;
    bottom: 0;
    background: ${gradient.primary};
    left: 0;
    border-radius: 1px 1x 0px 0px;
  }
`;

export const NotificationSpan = styled.span`
  margin-left: 1rem;
  margin-right: -0.6rem !important;

  height: 16px;
  width: 18px;

  background: #434a5e;
  border-radius: 50%;
  padding: 2px 6px;
  text-align: center;
  color: #a2a7b5;
  font-size: 1rem;

  position: absolute;
  top: 10px;
  right: 0;
`;

export const P = styled.p`
  font-size: ${fontSize.mediumDefault};
  font-weight: ${fontWeight.medium};
  color: ${(props) => (props.isActive ? `${textColors.textWhite}` : `${textColors.textLight}`)};
`;
