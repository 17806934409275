import styled from 'styled-components';
import { backgroundColor, borderColor, fontWeight } from '../../shared/constants';

export const ModalContainer = styled.div`
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  justify-content: flex-end;

  background-color: ${backgroundColor.popupBg};
  z-index: 999999999;
`;

export const ContentContainer = styled.div`
  height: 100%;
  width: 676px;
  padding: 30px 20px 30px 28px;

  background-color: ${backgroundColor.popup};
  border-radius: 20px 0px 0px 20px;

  overflow-y: auto;
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 698px;
  top: 25px;
  z-index: 99999;
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const DetailsWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-right: 1rem;
`;

/* profile */

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 21px 26px;
  border: 1px solid ${borderColor.light};
  border-radius: 6px;
  margin-bottom: 23px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const Title = styled.h2`
  font-size: 20px;
  font-weight: ${fontWeight.medium};
`;

export const EditWrapper = styled.span`
  cursor: pointer;
`;

export const ProfileContent = styled.div`
  width: 100%;
  height: 280px;
  display: flex;
  margin-bottom: 1rem;
`;

export const ProfilePicWrapper = styled.div`
  width: 302px;
  height: 279px;
  padding-right: 23px;
  border-right: 1px solid ${borderColor.light};
`;

export const ProfilePic = styled.div`
  height: 100%;
  width: 279px;
  border-radius: 10px;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const ProfileDetailsWrapper = styled.span`
  display: flex;
  flex-direction: column;
  margin-left: 23px;
  margin-top: 1rem;
`;

export const DetailRow = styled.span`
  display: flex;
  margin-bottom: 22px;
`;

export const DetailType = styled.p`
  margin-top: 3px;
  display: inline-block;
  width: 87px;
  font-size: 14px;
  font-weight: ${fontWeight.medium};
  color: #8792ac;
  text-transform: capitalize;
`;

export const DetailValue = styled.p`
  display: inline-block;
  width: 140px;
  font-size: 16px;
  font-weight: ${fontWeight.medium};
  color: #e7e9ef;
  word-break: break-word;
`;

/* password reset */

export const PasswordResetWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 21px 26px;
  border: 1px solid ${borderColor.light};
  border-radius: 6px;
`;
