/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/state-in-constructor */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Container, Time, Title, ShowComments, ScrollContainer } from './FeedFooterStyles';
import { ReactComponent as Dropdown } from '../../assets/Dropdown.svg';

import { shortenText, Timeformater, timeSince } from '../../../services/utility';
import CommentInfo from '../CommentInfoComponent';
import { EmptyContentText } from '../../../globalShared/CommonStyles';
import { localString } from '../../../services/locallization/localStrings';

const FeedFooter = (props) => {
  const [showComments, setshowComments] = useState(false);
  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  const handleClick = () => {
    const { _id, onClick } = props;

    if (!showComments) {
      onClick(_id);
    }
    setshowComments(!showComments);
  };

  const { title, duration, commentCount, commentsList, _id } = props;
  const filteredList = commentsList.filter((comment) => comment.referenceId === _id);

  return (
    <>
      <Container>
        <div style={{ maxWidth: '80%' }}>
          <Title>{shortenText(title, 30, '...')}</Title>
          {duration ? <Time>{Timeformater(duration)}</Time> : ''}
        </div>

        <ShowComments onClick={handleClick}>
          {commentCount || filteredList.length ? (
            <>
              {`${string.comments} (${filteredList.length || commentCount})`}
              <Dropdown />
            </>
          ) : (
            string.noComments
          )}
        </ShowComments>
      </Container>
      {showComments && (
        <ScrollContainer>
          {filteredList.length ? (
            filteredList.map((comment) => {
              const { _id, content, firstName, lastName, profilePicture, dateAdded } = comment;
              return (
                <CommentInfo
                  key={_id}
                  comment={content}
                  playerName={`${firstName} ${lastName}`}
                  userPic={profilePicture}
                  time={timeSince(dateAdded)}
                />
              );
            })
          ) : (
            <EmptyContentText fontSize='16px'>{string.noComments}</EmptyContentText>
          )}
        </ScrollContainer>
      )}
    </>
  );
};
export default FeedFooter;
