import styled from 'styled-components';
import { fontSize, textColors, backgroundColor, accentColor } from '../../constants';

export const Container = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`;

export const Label = styled.p`
  color: ${textColors.textLight};
  font-size: ${fontSize.mediumDefault};
  margin-bottom: 1rem;
`;

export const Input = styled.input`
  width: 100%;
  height: ${(props) => (props.type === 'textarea' ? '100px' : '45px')};

  padding: 1rem;
  padding-right: 4.2rem;
  font-size: ${fontSize.mediumDefault};

  color: ${textColors.textWhite};
  border: none;
  border-radius: 0.6rem;
  background-color: ${backgroundColor.darkOpaq};

  &:focus {
    outline: 1px solid ${accentColor.primary};
    box-shadow: 0px 0px 2px #0066ff;
  }

  &:-internal-autofill-selected {
    appearance: initial !important;
    background-color: ${backgroundColor.darkOpaq} !important;

    color: ${textColors.textWhite} !important;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  position: relative;
`;
export const Button = styled.button`
  background-color: transparent;
  border: none;
  padding: 10px;

  position: absolute;
  right: 0;
  height: 100%;

  &:focus {
    outline: 0px;
  }
`;
