/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import React from 'react';
import { Bar, Chart } from 'react-chartjs-2';
import styled from 'styled-components';
import MoodIcon from '../MoodIcon';
import cstyle from './chartstyles';

const MoodWrapper = styled.div`
  margin-top: -2.5rem;
  margin-left: 2.3rem;
  display: flex;
  justify-content: space-around;
`;

const ChartCanvasWrapper = styled.div`
  position: relative;
  height: 100%;
  // & > canvas {
  //   width: 100% !important;
  //   height: 275px !important;
  // }
`;

const PlayerMoodTracker = ({ level6, level5, level4, level3, level2, level1 }) => {
  // chart data
  Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
    draw: cstyle.draw
  });

  const chartData = (canvas) => {
    const ctx = canvas.getContext('2d');
    const level1Gradient = ctx.createLinearGradient(0, canvas.height, 0, 0);
    level1Gradient.addColorStop(0, '#1D1B28');
    level1Gradient.addColorStop(1, '#D53232');

    const level2Gradient = ctx.createLinearGradient(0, canvas.height, 0, 0);
    level2Gradient.addColorStop(0, '#1D1B28');
    level2Gradient.addColorStop(1, '#CD4635');

    const level3Gradient = ctx.createLinearGradient(0, canvas.height, 0, 0);
    level3Gradient.addColorStop(0, '#1D1B28');
    level3Gradient.addColorStop(1, '#DC7C3F');

    const level4Gradient = ctx.createLinearGradient(0, canvas.height, 0, 0);
    level4Gradient.addColorStop(0, '#1D1B28');
    level4Gradient.addColorStop(1, '#E0D044');

    const level5Gradient = ctx.createLinearGradient(0, canvas.height, 0, 0);
    level5Gradient.addColorStop(0, '#1D1B28');
    level5Gradient.addColorStop(1, '#1EA197');

    const level6Gradient = ctx.createLinearGradient(0, canvas.height, 0, 0);
    level6Gradient.addColorStop(0, '#1D1B28');
    level6Gradient.addColorStop(1, '#6DBE4B');

    return {
      labels: ['', '', '', '', '', ''],
      datasets: [
        {
          data: [level1, level2, level3, level4, level5, level6],
          backgroundColor: [
            level1Gradient,
            level2Gradient,
            level3Gradient,
            level4Gradient,
            level5Gradient,
            level6Gradient
          ],
          barThickness: 20
        }
      ]
    };
  };

  const options = {
    cornerRadius: 5,
    tooltips: {
      enabled: true
    },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            zeroLineBorderDash: [5, 5],
            color: 'rgba(0, 0, 0, 0)',
            zeroLineColor: 'rgba(255, 255, 255, 0.3)'
          },
          spanGaps: false
        }
      ],
      yAxes: [
        {
          gridLines: {
            color: 'rgba(255, 255, 255, 0.3)',
            zeroLineColor: 'rgba(255, 255, 255, 0.3)',
            zeroLineBorderDash: [5, 5],
            borderDash: [5, 5]
          },
          ticks: {
            beginAtZero: true,
            userCallback(label, index, labels) {
              // when the floored value is the same as the value we have a whole number
              if (Math.floor(label) === label) {
                return label;
              }
            }
          }
        }
      ]
    }
  };

  return (
    <ChartCanvasWrapper>
      <Bar data={chartData} options={options} />
      <MoodWrapper>
        <MoodIcon type='level1' />
        <MoodIcon type='level2' />
        <MoodIcon type='level3' />
        <MoodIcon type='level4' />
        <MoodIcon type='level5' />
        <MoodIcon type='level6' />
      </MoodWrapper>
    </ChartCanvasWrapper>
  );
};

export default PlayerMoodTracker;
