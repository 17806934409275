/* eslint-disable max-len */
import styled, { keyframes } from 'styled-components';

import { accentColor, fontSize, textColors, backgroundColor, borderColor } from '../../../shared/constants';
import {
  Container,
  CardContainer,
  Title,
  CloseButton
} from '../../../shared/components/EditUserDetailsComponent/EditUserDetails.styles';

// add meal modal styles
export const ModalContainer = styled(Container)`
  z-index: 99999;
`;

export const ModalContent = styled(CardContainer)`
  padding: 3rem;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ModalTitle = styled(Title)``;
export const AllergyText = styled(Title)`
  font-size: ${fontSize.title};
`;

export const CloseBtn = styled(CloseButton)``;

export const Label = styled.p`
  color: ${textColors.textLight};
  font-size: 1.3rem;
  margin: 3rem 0rem 2rem 0rem;
`;

export const ExistingMealPlan = styled.div`
  display: flex;
  flex-flow: column;
  /* height: 250px;
  overflow-y: auto;
  padding-right: 1rem; */
`;

export const InputContainer = styled.div`
  padding: 1rem 0;
  display: flex;
  align-items: center;

  border-bottom: 0.5px solid ${borderColor.light};
`;

export const SelectButtonContainer = styled.div`
  margin-right: 1rem;
  width: 25px;
  cursor: pointer;
`;

export const Radio = styled.div`
  height: 25px;
  width: 25px;
  border: 2px solid #3e3457;
  border-radius: 12px;

  &:hover {
    background: #2f2843;
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0 1rem 0;
`;

export const DashedLine = styled.div`
  font-size: 14px;
  padding: 20px;
  text-align: center;
  position: relative;

  &:before {
    content: '';
    border: 1px dashed #a3a9b7;
    position: absolute;
    width: 43%;
    left: 0;
    top: 50%;
    opacity: 20%;
  }

  &:after {
    content: '';
    border: 1px dashed #a3a9b7;
    position: absolute;
    width: 43%;
    right: 0;
    top: 50%;
    opacity: 20%;
  }
`;

export const DashedLineTxt = styled.span`
  position: absolute;
  background-color: ${backgroundColor.popup};
  color: ${textColors.textLight};
  font-size: ${fontSize.smallDefault};
  padding: 0rem 1rem;
  top: -8px;
  left: 180px;
`;

export const forward = keyframes`
  from{ margin-left: 0px;}
  to{ margin-left: 20px;}
`;

export const CustomisedBtn = styled.button`
  width: 100%;
  padding: 1rem 0;
  font-size: ${fontSize.mediumDefault};
  background-color: ${backgroundColor.popup};
  border: 1px solid ${borderColor.light};
  color: ${textColors.light};
  border-radius: 5px;
  cursor: pointer;
  text-transform: capitalize;

  &:hover {
    color: ${accentColor.primary};
    border-color: ${accentColor.primary};
    & > svg {
      animation: ${forward} forwards;
    }
  }
  & > svg {
    margin-left: 0.7rem;
    transform: rotate(-90deg);
    height: 1rem;
    width: 1.5rem;
  }

  & > svg > g > path {
    stroke: ${accentColor.primary};
  }
`;

// Food Allergy modal styles

export const AllergyContainer = styled.div`
  min-height: 150px;
  width: 500px;
  border-radius: 10px;

  padding: 3rem 0;
  position: relative;

  background-color: ${backgroundColor.popup};

  z-index: 100;
`;
