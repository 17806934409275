import styled from 'styled-components';
import { accentColor, backgroundColor, borderColor, fontSize, textColors } from '../../constants';
import icon from '../../assets/Dropdown.svg';

export const Container = styled.div`
  height: auto;
  width: 100%;
  background-color: ${backgroundColor.card};
  border-radius: 6px;
  padding: 2rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: 1px solid ${borderColor.light};
  padding-bottom: 1.2rem;
  margin-bottom: 2rem;
`;

export const Title = styled.p`
  font-size: 16px;
  font-weight: bold;
`;

export const DropdownContainer = styled.div`
  position: relative;
`;

export const Select = styled.div`
  width: 100%;
  height: 45px;

  padding: 1.5rem;
  font-size: ${fontSize.mediumDefault};

  margin-right: 1.5rem;

  border-radius: ${(props) => (props.active ? '6px 6px 0 0' : ' 0.6rem')};
  color: ${textColors.textWhite};

  background: #332949;
  border: none;

  position: relative;
  cursor: pointer;

  display: flex;
  align-items: center;

  &:focus {
    outline-color: ${accentColor.primary};
  }

  &::after {
    content: url(${icon});
    position: absolute;
    right: 10px;
  }
`;

export const Options = styled.div`
  width: 100%;

  position: absolute;
  top: 43px;

  border-radius: 0 0 5px 5px;
  z-index: 99;
  overflow-y: auto;
`;

export const Option = styled.div`
  padding: 0.5rem 1.5rem 2rem 1.5rem;
  background-color: #332949;

  font-size: ${fontSize.mediumDefault};

  &:hover {
    cursor: pointer;
  }
`;

export const Chart = styled.div`
  height: 270px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LabelCon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    width: 12px;
    height: 12px;
    border: ${(props) => (props.border ? `2px solid ${props.border}` : '')};
    background-color: ${(props) => (props.bgc ? `${props.bgc}` : '')};
    margin-right: 1rem;
  }

  & > p {
    color: #ffffff4c;
    font-size: ${fontSize.smallDefault};
  }
`;
