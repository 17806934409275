/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import styled, { css } from 'styled-components';

import { fontSize, backgroundColor, fontWeight, textColors, borderColor, accentColor } from '../../../shared/constants';

export const Container = styled.div`
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  justify-content: flex-end;

  background-color: ${backgroundColor.popupBg};
  z-index: 999999999;
`;

export const ScrollContainer = styled.div`
  height: calc(100vh - 212px);
  overflow-y: auto;
`;

export const AddUserContainer = styled.div`
  height: 100%;
  width: 1077px;

  background-color: ${backgroundColor.popup};
  border-radius: 6px 0px 0px 6px;

  overflow-y: auto;
  position: relative;
`;

export const UploadFileContainer = styled.div`
  height: 385px;
  width: 100%;
  margin: 12px 0px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  border: 3px dashed ${borderColor.light};
  border-radius: 1rem;
`;

export const FileName = styled.div`
  font-size: calc(${fontSize.default} + 0.4rem);

  display: flex;
  align-items: center;

  & > div {
    font-size: calc(${fontSize.default} + 0.4rem);
    padding: 1rem;
    width: 150px;
    border: 1px solid;
    border-radius: 1rem;
    margin: 0 1rem;
  }
`;

export const ReUpload = styled.div`
  font-size: calc(${fontSize.default} + 0.4rem);
  padding: 1rem;
  color: ${accentColor.primary};
  cursor: pointer;
`;

export const CloseButton = styled.div`
  position: absolute;
  right: calc(1077px + 30px);
  top: 30px;
  z-index: 999;
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const ControllerContainer = styled.div`
  width: 250px;
  height: 50px;
  margin-bottom: 20px;
`;

export const Title = styled.h1`
  font-size: ${fontSize.titleVerySmall};
  margin-bottom: 2.5rem;
  font-weight: ${fontWeight.bold};
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
`;

export const FormHeaderTitle = styled.div`
  font-size: ${fontSize.mediumDefault};
  color: ${textColors.textLight};
  width: 24%;
`;
export const InputContainer = styled.div`
  margin-top: 1rem;
  width: 22%;
  position: relative;
`;

export const DropdownContainer = styled.div`
  position: relative;
  width: 22%;
`;

export const FormRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  &::after {
    content: '';

    height: 60px;
    width: 1px;

    position: relative;
    right: 10px;

    background: ${borderColor.light};
  }
`;
export const FormRowIndex = styled.p`
  font-size: 16px;
  padding: 0 1rem;
  margin-bottom: 2rem;
`;
export const FormRowDelete = styled.div`
  position: relative;
  top: -10px;
  right: 0px;
  cursor: pointer;

  & > svg {
    height: 20px;
  }
`;

export const FormScrollContainer = styled.div`
  height: auto;
  width: 100%;
  padding: 1rem;
  overflow-y: auto;
`;

export const AddMoreButton = styled.div`
  height: 215px;
  display: flex;
  justify-content: center;
  align-items: start;

  padding: 2rem 0;
  border-top: 1px solid ${borderColor.light};

  & > button {
    background: transparent;
    border: none;

    font-size: ${fontSize.mediumDefault};
    color: ${accentColor.primary};

    cursor: pointer;

    &:focus {
      outline: 0px;
    }
  }
`;

export const Footer = styled.div`
  padding: 1.5rem 0;
  display: flex;
  justify-content: flex-end;
  border-top: 0.5px solid ${borderColor.light};
`;

export const TableStyles = {
  Table: styled.table`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 2rem;

    & > td,
    th {
      white-space: nowrap;
      vertical-align: middle;
      border: 0;
    }
  `,
  Th: styled.th`
    font-size: ${fontSize.smallDefault};
    padding-right: 1rem;
    opacity: 0.5;
    padding: 1rem;
    text-align: left;
    text-transform: uppercase;
  `,

  Tr: styled.tr`
    background: ${backgroundColor.darkOpaq};

    & > td:first-child {
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
      padding: 1.5rem 2rem;
    }

    & > td:last-child {
      border-top-right-radius: 1rem;
      border-bottom-right-radius: 1rem;
    }
  `,

  Td: styled.td`
    font-size: ${fontSize.mediumDefault};
    padding: 1rem;
    color: #cbd1df;
  `
};

export const UserName = styled.div`
  font-size: ${fontSize.mediumDefault};
  font-weight: ${fontWeight.bold};

  margin: 0 1rem;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

export const UserBagde = styled.span`
  font-size: ${fontSize.default};
  color: ${textColors.textDark};
  background: #2185dc;

  padding: 0.5rem 1rem;
  border-radius: 1.5rem;

  font-weight: bold;

  ${(props) =>
    props.userType === 'Player' &&
    css`
      background: ${accentColor.secondary};
    `};

  ${(props) =>
    props.userType === 'Admin' &&
    css`
      background: #2185dc;
    `};

  ${(props) =>
    props.userType === 'Coach' &&
    css`
      background: ${accentColor.primary};
    `};
`;

export const UserActionsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-left: -2.5rem;

  & > button {
    background: none;
    border: none;
    cursor: pointer;

    &:focus {
      outline: 0px;
    }
  }
`;

export const ActionsContainer = styled.div`
  position: absolute;
  right: 0;
`;

export const MainContainer = styled.div`
  position: relative;
`;

export const FormContainer = styled.div`
  height: calc(100vh - 80px);
  padding: 3rem;
  overflow-y: auto;
`;

export const Link = styled.a`
  font-size: ${fontSize.mediumDefault};
  color: ${accentColor.primary};
  cursor: pointer;
  margin-bottom: 1rem;

  &:focus {
    outline: 0px;
  }
`;
