/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import styled from 'styled-components';
// import { Link } from 'react-router-dom';

import { fontSize, backgroundColor, fontWeight, textColors, borderColor, accentColor } from '../../../shared/constants';

export const Container = styled.div``;

export const Linkto = styled.div`
  cursor: pointer;
  &:focus {
    outline: 0px;
  }
`;

export const ScrollContainer = styled.div`
  height: calc(100vh - 231px);
  width: 100%;
  overflow-y: auto;
  padding-right: 0.5rem;
`;

export const AddUserContainer = styled.div`
  position: absolute;
  top: 0;

  right: ${(props) => (props.showAddUser ? '0' : '-100%')};

  display: ${(props) => (props.showAddUser ? 'block' : 'none')};

  height: 100vh;
  width: 80%;

  padding: 3rem;

  border-radius: 6px 0px 0px 6px;
  box-shadow: -360px 0px 20px 7px #000000cc;

  background-color: ${backgroundColor.popup};
`;

export const UploadFileContainer = styled.div`
  height: 385px;
  width: 100%;
  margin: 12px 0px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  border: 3px dashed ${borderColor.light};
  border-radius: 1rem;
`;

export const FileName = styled.div`
  font-size: calc(${fontSize.default} + 0.4rem);

  display: flex;
  align-items: center;

  & > div {
    font-size: calc(${fontSize.default} + 0.4rem);
    padding: 1rem;
    width: 150px;
    border: 1px solid;
    border-radius: 1rem;
    margin: 0 1rem;
  }
`;

export const ReUpload = styled.div`
  font-size: calc(${fontSize.default} + 0.4rem);
  padding: 1rem;
  color: ${accentColor.primary};
  cursor: pointer;
`;

export const CloseButton = styled.div`
  position: absolute;
  left: -30px;
  top: 13px;
  z-index: 999;
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const ControllerContainer = styled.div`
  width: 250px;
  height: 50px;
  margin-bottom: 20px;
`;

export const Title = styled.h1`
  font-size: ${fontSize.titleVerySmall};
  margin-bottom: 2.5rem;
  font-weight: ${fontWeight.bold};
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
`;

export const FormHeaderTitle = styled.div`
  font-size: ${fontSize.mediumDefault};
  color: ${textColors.textLight};
  width: 24%;
`;
export const InputContainer = styled.div`
  width: 22%;
  position: relative;
`;

export const FormRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  &::after {
    content: '';

    height: 60px;
    width: 1px;

    position: relative;
    right: 10px;

    background: ${borderColor.light};
  }
`;
export const FormRowIndex = styled.p`
  font-size: 16px;
  padding: 0 1rem;
  margin-bottom: 8px;
`;
export const FormRowDelete = styled.div`
  position: relative;
  top: -10px;
  right: 0px;
  cursor: pointer;

  & > svg {
    height: 20px;
  }
`;

export const FormScrollContainer = styled.div`
  height: 365px;
  width: 100%;
  padding: 1rem;
  overflow-y: auto;
`;

export const AddMoreButton = styled.div`
  height: 215px;
  display: flex;
  justify-content: center;
  align-items: start;

  padding: 2rem 0;
  border-top: 1px solid ${borderColor.light};

  & > button {
    background: transparent;
    border: none;

    font-size: ${fontSize.mediumDefault};
    color: ${accentColor.primary};

    cursor: pointer;

    &:focus {
      outline: 0px;
    }
  }
`;

export const Footer = styled.div`
  padding: 1.5rem 0;
  display: flex;
  justify-content: flex-end;
  border-top: 0.5px solid ${borderColor.light};
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Filter = styled.div`
  font-size: ${fontSize.smallDefault};
  margin: 1rem 0.5rem;
  color: ${(props) => (props.isActive ? `${textColors.textDark}` : `${textColors.light}`)};
  padding: 6px 8px;
  border-radius: 0.6rem;
  background: ${(props) => (props.isActive ? `${accentColor.primary}` : '#8d8d8d33')};
  text-transform: uppercase;
  cursor: pointer;
`;

export const VideoTitle = styled.div`
  font-size: ${fontSize.mediumDefault};
  font-weight: ${fontWeight.bold};

  margin: 0 1rem;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

export const VideoType = styled.div`
  font-size: ${fontSize.smallDefault};
  font-weight: ${fontWeight.light};

  margin: 0.5rem 1rem;
`;

export const Bagde = styled.div`
  & > p {
    font-size: ${fontSize.smallDefault};
  }
  background-color: #b5b7bc1a;
  border-radius: 0.5rem;
  border: 1px solid;
  text-align: center;
  padding: 0.5rem;
  max-width: 150px;
`;

export const UserActionsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;

  & > button {
    background: none;
    border: none;
    cursor: pointer;

    &:focus {
      outline: 0px;
    }
  }
`;

export const ManageContainer = styled.span`
  display: flex;
  align-items: center;
`;

export const SkillHeader = styled.span`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ManageIconWrapper = styled.span`
  & > svg {
    transform: rotate(0deg);
    transition: transform ease-in-out 0.5s;
  }

  & > svg:hover {
    transform: rotate(90deg);
    transition: transform ease-in-out 0.5s;
  }
`;

export const SearchContainer = styled.span`
  margin-right: 1rem;
`;

export const FilterContainer = styled.span`
  position: relative;
  background-color: ${backgroundColor.darkOpaq};
  color: #e7e9ef;
  height: 50px;
  width: 105px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 6px;
`;

export const FilterToggle = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  color: #e7e9ef;
  font-size: 16px;
  cursor: pointer;
`;

export const FilterCardContainer = styled.div`
  position: absolute;
  top: 56px;

  width: 240px;
  height: 245px;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  gap: 18px;

  padding: 29px 24px;
  background: #15151c;
  border: 1px solid #4e436933;
  border-radius: 10px;
  z-index: 10;
`;

export const CheckboxInputContainer = styled.div`
  display: flex;
`;

export const SelectCheckboxContainer = styled.div`
  margin-right: 1rem;
  cursor: pointer;
`;

export const FilterCheckBox = styled.div`
  font-size: 16px;
`;

export const UploadVideoWrapper = styled.span``;
