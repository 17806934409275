import styled from 'styled-components';

import { backgroundColor, fontSize, fontWeight, textColors, borderColor } from '../../../shared/constants';

export const Modal = styled.div`
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  right: 0px;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  background-color: ${backgroundColor.popupBg};
  z-index: 99;
`;

export const Card = styled.div`
  width: 768px;
  height: 100%;

  padding: 3.4rem 2.8rem;

  background-color: ${backgroundColor.popup};
  border-radius: 6px 0px 0px 6px;
  overflow-y: auto;
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 798px;
  top: 3rem;
  z-index: 999;
  background: transparent;
  border: none;
  cursor: pointer;
`;

// Card Header

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 42px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2`
  font-size: ${fontSize.mediumDefault};
  font-weight: ${fontWeight.medium};

  padding-bottom: 4px;
  margin: 0 1rem;

  &.lite {
    color: ${textColors.textLight};
  }
`;

export const TextLite = styled.p`
  font-size: ${fontSize.smallDefault};
  font-weight: ${fontWeight.medium};
  color: #8792ac;
  margin: 0.5rem 1rem 0rem 1rem;
`;

export const SubHeading = styled.div`
  & > p {
    display: inline-block;
    font-size: 14px;
    color: ${textColors.textLight};
  }

  & > p:first-child {
    padding-right: 10px;
    font-weight: ${fontWeight.bold};
  }

  & > p:last-child {
    border-left: 1px solid ${borderColor.light};
    padding-left: 10px;
    font-weight: ${fontWeight.medium};
  }
`;

// Intake Summary

export const ModalBody = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 178px);
`;

export const SummaryBlock = styled.div`
  width: 50%;
  overflow-y: auto;
  border-right: 1px solid ${borderColor.light};
  padding-top: 2.8rem;
  padding-right: 2.8rem;
`;

export const CommentsBlock = styled.div`
  width: 50%;
  padding-top: 2.8rem;
  padding-left: 2.8rem;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2.5rem;
  border: 1px solid ${borderColor.light};
  border-radius: 6px;
`;

export const SummaryContainer = styled.div`
  width: 100%;
`;

export const ProgressContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem auto;
`;

export const ProgressDetails = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90px;
`;

export const KcalTaken = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > h1 {
    display: inline-block;
    font-size: 34px;
    font-weight: ${fontWeight.bold};
    padding-right: 10px;
  }

  & > p {
    display: inline-block;
    font-size: 12px;
    font-weight: ${fontWeight.medium};

    text-transform: uppercase;
    color: ${textColors.textGray};
  }
`;

export const TotalKcal = styled.div`
  & > p {
    display: inline-block;

    font-size: 11px;
    font-weight: ${fontWeight.medium};
    color: ${textColors.textGray};
  }

  & > p:first-child {
    padding-right: 0.4rem;
  }
`;

export const MoreDetails = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Carb = styled.div`
  & > p {
    display: inline-block;

    font-size: 12px;
    font-weight: ${fontWeight.medium};
  }

  & > p:first-child {
    color: ${textColors.textGray};
    // text-transform: uppercase;
    padding-right: 5px;
  }
`;

export const Protein = styled.div`
  & > p {
    display: inline-block;

    font-size: 12px;
    font-weight: ${fontWeight.medium};
  }

  & > p:first-child {
    color: ${textColors.textGray};
    // text-transform: uppercase;
    padding-right: 5px;
  }
`;

export const Fat = styled.div`
  & > p {
    display: inline-block;

    font-size: 12px;
    font-weight: ${fontWeight.medium};
  }

  & > p:first-child {
    color: ${textColors.textGray};
    // text-transform: uppercase;
    padding-right: 5px;
  }
`;

// Water Intake

export const WaterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 20px 0;
  margin-top: 2rem;
  border-top: 1px solid ${borderColor.light};
`;

export const WaterHeader = styled.div`
  display: flex;
  justify-content: space-between;

  padding-bottom: 20px;

  & > p {
    display: inline-block;

    font-size: 14px;
    font-weight: ${fontWeight.medium};
  }

  & > p:last-child {
    font-size: 12px;
  }
`;

export const BottleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

// Meal details table
export const IntakeWrapper = styled.div`
  width: 100%;
  // height: 220px;
  overflow-y: auto;
`;

export const MealContainer = styled.div`
  width: 100%;
  height: auto;
  border: 1px solid ${borderColor.light};
  border-radius: 6px;

  overflow: hidden;
`;

export const MealDetailsHeader = styled.div`
  height: 36px;
  background: ${borderColor.light};
  border-radius: 6px 6px 0 0;

  display: flex;
  align-items: center;

  & > p {
    width: 50%;
    padding: 10px 14px;

    display: inline-block;
    font-size: 16px;
    color: ${textColors.textLight};
  }

  & > p:last-child {
    border-left: 1px solid ${borderColor.light};
  }
`;

export const Intake = styled.div`
  display: flex;
  justify-content: space-between;

  border-top: 1px solid ${borderColor.light};

  & > p {
    width: 50%;
    padding: 10px 14px;

    display: inline-block;
    font-size: 14px;
  }

  & > p:last-child {
    border-left: 1px solid ${borderColor.light};
  }
`;

// comments card

export const CommentsCard = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 495px;

  border-radius: 6px;
`;
