/* eslint-disable no-console */
/* eslint-disable no-shadow */
/*
 * saga.js file for login view
 * Author:
 * 1. manikanta
 *
 * @copyright of Panorbit Services LLP.
 */

import { put } from 'redux-saga/effects';
import { Creators } from './action';

import { WebService } from '../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../services/axios/endPoints';

import { setAccessTokenToHeader } from '../../../../services/axios/helper';
import { setUserDataToLocal } from '../../../../services/cookies/helper';

export function* loginSaga({ payload }) {
  try {
    yield put(Creators.loginStart());
    const { email, password } = payload;

    const loginData = {
      username: email,
      password
    };

    const response = yield WebService.post(apiEndPoints.login, loginData);

    if (response.status >= 200 && response.status < 300) {
      const { token, passwordChanged, userType, metaUpdated, email, firstName, lastName } = response.data.data;

      // set auth token to axios headers
      yield setAccessTokenToHeader(token);

      // write code to set AccessToken to localStorage / cookies
      yield setUserDataToLocal({
        accessToken: token,
        passwordChanged,
        userType,
        metaUpdated,
        email,
        firstName,
        lastName
      });

      // sets accessToken to store
      yield put(Creators.setUserData({ accessToken: token, passwordChanged, userType, metaUpdated }));

      // sets login loading to false
      yield put(Creators.loginSuccess());
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });
    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.loginFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.loginFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.loginFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.loginFailure(payload));
    }
  }
}

export function* userMetaDataSaga({ payload }) {
  try {
    const { accessToken, passwordChanged, userType, metaUpdated, email, firstName, lastName } = payload;

    yield put(Creators.userMetaDataStart());

    if (accessToken) {
      // set auth token to axios headers
      yield setAccessTokenToHeader(accessToken);

      // setting it to local again to refresh its date of expiry
      yield setUserDataToLocal({ accessToken, passwordChanged, userType, metaUpdated, email, firstName, lastName });

      // sets accessToken to store
      yield put(Creators.setUserData({ accessToken, passwordChanged, userType, metaUpdated }));
    }

    if (metaUpdated) {
      const fullURL = yield `${apiEndPoints.userData}`;
      const response = yield WebService.get(fullURL);

      if (response.status >= 200 && response.status < 300) {
        const { personalDetail, professionalDetail } = response.data.data;
        yield put(Creators.setUserDetails({ personalDetail, professionalDetail }));
      } else {
        throw response;
      }
    }

    yield put(Creators.resetLoading());
  } catch (error) {
    console.log({ error });
    const payload = {
      error: "Can't fetch User Data, Try loggin in again!"
    };
    yield put(Creators.userMetaDataFailure(payload));
  }
}

export function* logoutSaga() {
  try {
    yield put(Creators.logoutStart());

    // clear auth token from axios headers
    yield setAccessTokenToHeader(null);

    // clearing cookies
    yield setUserDataToLocal(null);

    yield put(Creators.logoutSuccess());
  } catch (error) {
    console.log({ error });
    const payload = {
      error: 'Try again!'
    };
    yield put(Creators.userMetaDataFailure(payload));
  }
}

export function* getUserDataSaga() {
  try {
    const fullURL = yield `${apiEndPoints.userData}`;
    const response = yield WebService.get(fullURL);

    if (response.status >= 200 && response.status < 300) {
      const { personalDetail, professionalDetail } = response.data.data;
      yield put(Creators.setUserDetails({ personalDetail, professionalDetail }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });
    const payload = {
      error: "Can't fetch User Data, Try loggin in again!"
    };
    yield put(Creators.userMetaDataFailure(payload));
  }
}
