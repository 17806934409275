/* eslint-disable indent */
import styled, { css } from 'styled-components';

import { borderColor, fontSize, backgroundColor, textColors, accentColor } from '../shared/constants';

export const Divider = styled.div`
  display: block;
  height: 0.5px;
  width: 100%;
  background-color: ${borderColor.light};
  margin: 1rem 0;
`;

export const TableStyles = {
  Table: styled.table`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 1rem;

    & > td,
    th {
      white-space: nowrap;
      vertical-align: middle;
      border: 0;
    }
  `,
  Th: styled.th`
    font-size: ${fontSize.smallDefault};
    padding-right: 1rem;

    opacity: 0.5;
    padding: 1rem;

    text-align: left;
    text-transform: uppercase;
    user-select: none;

    ${(props) =>
      props.pLeft &&
      css`
        padding-left: ${props.pLeft}rem;
      `};
  `,

  Tr: styled.tr`
    background: ${backgroundColor.darkOpaq};

    & > td:first-child {
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
      padding: 1.5rem 2rem;
    }

    & > td:last-child {
      border-top-right-radius: 1rem;
      border-bottom-right-radius: 1rem;
    }
  `,

  Td: styled.td`
    font-size: ${fontSize.mediumDefault};
    padding: 1rem;
    color: #cbd1df;
  `
};

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ContentContainer = styled.div`
  padding: 1.5rem 2.5rem;
  width: 100%;
  height: calc(100vh - 70px);
  overflow-y: auto;
`;

export const CommingSoon = styled.h1`
  font-size: 40px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  color: ${textColors.textLight};
`;

export const ValdErrMsg = styled.p`
  font-size: ${fontSize.mediumDefault};
  color: ${textColors.error};
  padding: 5px 0;
  margin-right: auto;
  margin-top: -1.5rem;
  margin-bottom: 1rem;
`;

export const UserActionsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;

  & > button {
    background: none;
    border: none;
    cursor: pointer;

    &:focus {
      outline: 0px;
    }
  }
`;

export const FlexWraper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const Label = styled.p`
  color: ${textColors.textLight};
  font-size: ${fontSize.mediumDefault};
  margin-bottom: 1rem;
  height: 16px;
`;

export const ArrayString = styled.p`
  width: ${(props) => props.width};
  font-size: ${fontSize.mediumDefault};
  font-weight: 500;
  margin-bottom: 0.5rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

export const MutedInput = styled.div`
  width: 100%;
  height: 45px;
  padding: 1rem;
  font-size: 1.5rem;
  color: #fff;
  border: none;
  border-radius: 0.6rem;
  background-color: rgba(78, 67, 105, 0.2);
`;

export const FeedContainer = styled.div`
  height: 475px;
  &.overFlow {
    overflow-y: auto;
  }
`;
export const EmptyContentText = styled(CommingSoon)`
  font-size: ${(props) => props.fontSize};
`;

export const Link = styled.a`
  color: ${accentColor.primary};
  font-size: ${fontSize.mediumDefault};
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;

  &.spaceBetween {
    justify-content: space-between;
  }
`;

export const ModalContainer = styled.div`
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  justify-content: flex-end;

  background-color: ${backgroundColor.popupBg};
  z-index: 999999999;
`;

export const CloseButton = styled.div`
  position: absolute;
  right: ${(props) => props.position}px;
  top: 30px;
  z-index: 999;
  background: transparent;
  border: none;
  cursor: pointer;
`;
