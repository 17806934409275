/* eslint-disable react/no-array-index-key */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-underscore-dangle */
/* eslint-disable operator-linebreak */
/* eslint-disable no-shadow */
/* eslint-disable arrow-body-style */

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Toast, { Toasts } from '../../../services/toast';

import { Creators } from './store';
import { Creators as StrengthCreators } from '../StrengthViews/store';

import { datesInMonth, WeekdayIndicator } from './index';

// styles
import { Divider, FlexContainer, FlexWraper } from '../../../globalShared/CommonStyles';
import {
  Header,
  HeaderTxt,
  P,
  Day,
  DayHeader,
  Session,
  SessionsContainer,
  Menu,
  MenuItem,
  WeekRow,
  Aside
} from './PlannerDetailsStyles';

// icons
import { ReactComponent as BackIcon } from '../../../shared/assets/back-header.svg';

import { ReactComponent as MenuIcon } from '../../../shared/assets/3dot_menu.svg';
import CreateSession from './CreateSession';
import GroupMembersModal from './GroupMembersModal';
import PlayersHistoryModal from '../RestPlayers/PlayersHistoryModal';
import {
  getDayOfMonth,
  getMonthDayYear,
  getToday,
  getYearMonthDay,
  compareDates
} from '../../../services/utility/moment';
import { LoadingComponent, TooltipComponnet } from '../../../globalShared';
import { getInitials, shortenText } from '../../../services/utility';
import { MonthIndicator } from '../../../services/utility/Calander';
import SessionDetails from './SessionDetails';
import EditSession from './EditSession';

// constants

const Options = ({ date, copyDate, onCopy, onPaste, onClear, onAdd, isOptions }) => {
  const [showMenu, setShowMenu] = useState(false);
  const today = getToday();
  const isAfter = compareDates(date, today);
  const OptionsActive = isAfter || getMonthDayYear(today) === getMonthDayYear(date);

  const isCopySameDate = getMonthDayYear(copyDate) !== getMonthDayYear(date);
  const handleOnPaste = () => {
    if (copyDate && isCopySameDate && OptionsActive) {
      onPaste(copyDate, date);
    }
  };

  return (
    <div style={{ position: 'relative', display: 'flex' }}>
      {OptionsActive && (
        <TooltipComponnet title='Add Session' position='bottom' type='add' onClick={() => onAdd(date)} />
      )}

      <MenuIcon style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={() => setShowMenu(true)} />

      <Menu show={showMenu} onMouseLeave={() => setShowMenu(false)}>
        <MenuItem
          isActive={date !== copyDate && isOptions}
          onClick={() => (date !== copyDate && isOptions ? onCopy(date) : null)}
        >
          Copy Schedule
        </MenuItem>

        <MenuItem isActive={copyDate && isCopySameDate && OptionsActive} onClick={handleOnPaste}>
          Paste Schedule
        </MenuItem>

        <MenuItem isActive onClick={() => onClear(date)}>
          Clear Schedule
        </MenuItem>
      </Menu>
    </div>
  );
};

const WeekOptions = ({ startDate, endDate, copiedWeek, text, onCopy, onPaste, onClear }) => {
  const [showMenu, setShowMenu] = useState(false);

  const today = getYearMonthDay(getToday());
  const isCompleted =
    !compareDates(getYearMonthDay(startDate), today) && !compareDates(getYearMonthDay(endDate), today);

  const isCopySameWeek =
    getMonthDayYear(copiedWeek?.selectStartDate) === getMonthDayYear(startDate) &&
    getMonthDayYear(copiedWeek?.selectEndDate) === getMonthDayYear(endDate);

  const handleOnPaste = () => {
    if (!isCopySameWeek && !isCompleted) {
      onPaste({ targetStartDate: startDate, targetEndDate: endDate });
    }
  };

  const handleOnCopy = () => {
    onCopy({ selectStartDate: startDate, selectEndDate: endDate });
    Toast.success('success', 2);
  };

  const handleOnClear = () => {
    if (!isCompleted) {
      onClear({ startDate, endDate });
    }
  };

  return (
    <Aside>
      {text}
      <MenuIcon style={{ marginLeft: '1rem', cursor: 'pointer' }} onClick={() => setShowMenu(true)} />

      <Menu
        style={{ transform: 'rotate(90deg)', zIndex: '99999' }}
        show={showMenu}
        onMouseLeave={() => setShowMenu(false)}
      >
        <MenuItem isActive onClick={handleOnCopy}>
          Copy Schedule
        </MenuItem>

        <MenuItem isActive={!isCopySameWeek && !isCompleted} onClick={handleOnPaste}>
          Paste Schedule
        </MenuItem>

        <MenuItem isActive={!isCompleted} onClick={handleOnClear}>
          Clear Schedule
        </MenuItem>
      </Menu>
    </Aside>
  );
};

const PlannerDetailsView = (props) => {
  const { history } = props;
  const { plannerId } = useParams();
  const dispatch = useDispatch();
  const plannerDetails = useSelector((state) => state.coachPlannerDetails.plannerDetails);
  const reloadPlanner = useSelector((state) => state.coachPlannerDetails.reloadPlanner);
  const success = useSelector((state) => state.coachPlannerDetails.success);
  const loading = useSelector((state) => state.coachPlannerDetails.loading);

  const [sessionsList, setSessionsList] = useState([]);
  const [showGroup, setShowGroup] = useState(false);
  const [selectDate, setSelectDate] = useState(getToday());
  const [selectId, setselectId] = useState('');
  const [copyDate, setCopyDate] = useState('');
  const [showSession, setShowSession] = useState(false);
  const [sessionsToedit, setsessionsToedit] = useState(null);
  const [DatesInMonth, setdatsInMonth] = useState([]);
  const [sessionDate, setsessionDate] = useState(null);
  const [selectedWeek, setselectedWeek] = useState(null);
  const [isWeek6, setisWeek6] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [selectedSession, setSelectedSession] = useState({});

  const getDaySessions = (sessionday) => {
    return sessionsList?.filter((item) => item.sessionDate === getYearMonthDay(sessionday));
  };

  useEffect(() => {
    if (plannerId && DatesInMonth.length) {
      dispatch(
        Creators.getPlannerDetails({
          _id: plannerId,
          fromDate: getYearMonthDay(DatesInMonth[0].date),
          toDate: getYearMonthDay(DatesInMonth[41].date)
        })
      );
    }
  }, [plannerId, DatesInMonth]);

  useEffect(() => {
    if (reloadPlanner) {
      dispatch(
        Creators.getPlannerDetails({
          _id: plannerId,
          fromDate: getYearMonthDay(DatesInMonth[0]?.date),
          toDate: getYearMonthDay(DatesInMonth[41]?.date)
        })
      );
    }
  }, [reloadPlanner]);

  useEffect(() => {
    if (success) {
      Toast.success('success', 2);
    }
  }, [success]);

  useEffect(() => {
    if (plannerDetails) {
      dispatch(Creators.getGroupDetails({ _id: plannerDetails.groupId }));
      setSessionsList(plannerDetails.session);
    }
  }, [plannerDetails]);

  useEffect(() => {
    setdatsInMonth(datesInMonth(selectDate));
  }, [selectDate]);

  useEffect(() => {
    const BooleanArray = DatesInMonth.filter((_, i) => i > 34).map((day) => day.currentMonth);
    const result = BooleanArray.reduce((res, cur) => res || cur, false);
    setisWeek6(result);
  }, [DatesInMonth]);

  const handlePaste = (selectDate, targetDate) => {
    dispatch(
      Creators.copyScheduleByDay({
        _id: plannerId,
        selectDate: getYearMonthDay(selectDate),
        targetDate: getYearMonthDay(targetDate)
      })
    );
  };

  const handleClear = (date) => {
    const payload = {
      _id: plannerId,
      sessionDate: getYearMonthDay(date)
    };

    dispatch(Creators.clearScheduleByDay(payload));
  };

  const handleWeekClear = (date) => {
    const payload = {
      _id: plannerId,
      selectStartDate: getYearMonthDay(date.startDate),
      selectEndDate: getYearMonthDay(date.endDate)
    };

    dispatch(Creators.clearScheduleByWeek(payload));
  };

  const handleWeekPaste = (value) => {
    const payload = {
      _id: plannerId,
      ...value,
      ...selectedWeek
    };

    dispatch(Creators.copyScheduleByWeek(payload));
  };

  const handleBack = () => {
    history.goBack();
    dispatch(StrengthCreators.setStrengthTab({ tab: 'Planner' }));
  };

  const _generateDay = (date, sessions, currentMonth) => {
    const onClickHandler = () => {
      if (sessions.length > 0) {
        setShowSession(true);
        setsessionsToedit(sessions);
        setsessionDate(date);
      }
    };
    return (
      <Day
        key={date}
        isActive={sessions.length}
        currentMonth={!currentMonth}
        onMouseOver={() => setselectId(date)}
        onMouseLeave={() => setselectId('')}
        isToday={getMonthDayYear(date) === getMonthDayYear(getToday())}
      >
        <DayHeader>
          <p>{getDayOfMonth(date)}</p>
          {getMonthDayYear(selectId) === getMonthDayYear(date) && currentMonth ? (
            <Options
              isOptions={sessions.length}
              date={date}
              copyDate={copyDate}
              onPaste={handlePaste}
              onCopy={() => setCopyDate(date)}
              onAdd={() => {
                setShowCreate(true);
                setsessionDate(date);
              }}
              onClear={handleClear}
            />
          ) : (
            ''
          )}
        </DayHeader>
        {[...sessions].length ? (
          <SessionsContainer onClick={onClickHandler}>
            {[...sessions].map((item, k) => (
              <Session key={`${item._id}_${k}`}>{getInitials(item.title)}</Session>
            ))}
          </SessionsContainer>
        ) : (
          ''
        )}
      </Day>
    );
  };

  return (
    <>
      {/* Toasts */}
      <Toasts />
      {loading && <LoadingComponent />}

      {/* modals */}

      {/* overview modal */}
      {showSession && (
        <SessionDetails
          sessions={sessionsToedit}
          selectedDate={sessionDate}
          onCancel={() => {
            setShowSession(false);
            setsessionsToedit(null);
          }}
          onEdit={(session) => {
            setSelectedSession(session);
            setShowEdit(true);
            setsessionsToedit(null);
            setShowSession(false);
          }}
        />
      )}

      {/* create session modal */}
      {showCreate && (
        <CreateSession
          sessions={sessionsToedit}
          selectedDate={sessionDate}
          onCancel={() => {
            setShowCreate(false);
            setsessionsToedit(null);
          }}
          plannerDetails={{ title: plannerDetails?.title, _id: plannerId }}
        />
      )}

      {/* Edit session modal */}
      {showEdit && (
        <EditSession
          session={[selectedSession]}
          selectedDate={sessionDate}
          onCancel={() => {
            setShowEdit(false);
            setSelectedSession({});
          }}
          plannerDetails={{ title: plannerDetails?.title, _id: plannerId }}
        />
      )}
      <GroupMembersModal shoModal={showGroup} onCancel={() => setShowGroup(false)} />
      <PlayersHistoryModal />

      {/* header */}
      <Header>
        <FlexContainer style={{ padding: '10px 0' }}>
          <BackIcon style={{ cursor: 'pointer', width: '60px' }} onClick={handleBack} />
          <HeaderTxt>{shortenText(plannerDetails?.title, 50, '...')}</HeaderTxt>
        </FlexContainer>
        <P>
          Assigned To
          <span role='presentation' onClick={() => setShowGroup(true)}>
            {plannerDetails?.groupName}
          </span>
        </P>
      </Header>
      <Divider />

      {/* Calander view */}
      <div style={{ padding: '0 3rem' }}>
        <MonthIndicator selectDate={selectDate} setSelectDate={setSelectDate} />
        <WeekdayIndicator />

        {/* week 1 */}
        <WeekRow>
          <FlexWraper style={{ width: '100%' }}>
            <WeekOptions
              text='W1'
              selectedWeek={selectedWeek}
              startDate={DatesInMonth[0]?.date}
              endDate={DatesInMonth[6]?.date}
              onCopy={(value) => setselectedWeek(value)}
              onPaste={handleWeekPaste}
              onClear={handleWeekClear}
            />
            {DatesInMonth.filter((_, i) => i <= 6).map((day) => {
              const { currentMonth, date } = day;
              const sessions = getDaySessions(date);

              return _generateDay(date, sessions, currentMonth);
            })}
          </FlexWraper>
        </WeekRow>

        {/* week 2 */}
        <WeekRow>
          <FlexWraper style={{ width: '100%' }}>
            <WeekOptions
              text='W2'
              selectedWeek={selectedWeek}
              startDate={DatesInMonth[7]?.date}
              endDate={DatesInMonth[13]?.date}
              onCopy={(value) => setselectedWeek(value)}
              onPaste={handleWeekPaste}
              onClear={handleWeekClear}
            />
            {DatesInMonth.filter((_, i) => i >= 7 && i <= 13).map((day) => {
              const { currentMonth, date } = day;
              const sessions = getDaySessions(date);

              return _generateDay(date, sessions, currentMonth);
            })}
          </FlexWraper>
        </WeekRow>

        {/* week 3 */}
        <WeekRow>
          <FlexWraper style={{ width: '100%' }}>
            <WeekOptions
              text='W3'
              selectedWeek={selectedWeek}
              startDate={DatesInMonth[14]?.date}
              endDate={DatesInMonth[20]?.date}
              onCopy={(value) => setselectedWeek(value)}
              onPaste={handleWeekPaste}
              onClear={handleWeekClear}
            />
            {DatesInMonth.filter((_, i) => i >= 14 && i <= 20).map((day) => {
              const { currentMonth, date } = day;
              const sessions = getDaySessions(date);

              return _generateDay(date, sessions, currentMonth);
            })}
          </FlexWraper>
        </WeekRow>

        {/* week 4 */}
        <WeekRow>
          <FlexWraper style={{ width: '100%' }}>
            <WeekOptions
              text='W4'
              selectedWeek={selectedWeek}
              startDate={DatesInMonth[21]?.date}
              endDate={DatesInMonth[27]?.date}
              onCopy={(value) => setselectedWeek(value)}
              onPaste={handleWeekPaste}
              onClear={handleWeekClear}
            />
            {DatesInMonth.filter((_, i) => i >= 21 && i <= 27).map((day) => {
              const { currentMonth, date } = day;
              const sessions = getDaySessions(date);

              return _generateDay(date, sessions, currentMonth);
            })}
          </FlexWraper>
        </WeekRow>

        {/* week 5 */}
        <WeekRow>
          <FlexWraper style={{ width: '100%' }}>
            <WeekOptions
              text='W5'
              selectedWeek={selectedWeek}
              startDate={DatesInMonth[28]?.date}
              endDate={DatesInMonth[34]?.date}
              onCopy={(value) => setselectedWeek(value)}
              onPaste={handleWeekPaste}
              onClear={handleWeekClear}
            />
            {DatesInMonth.filter((_, i) => i >= 28 && i <= 34).map((day) => {
              const { currentMonth, date } = day;
              const sessions = getDaySessions(date);

              return _generateDay(date, sessions, currentMonth);
            })}
          </FlexWraper>
        </WeekRow>

        {/* week 6 */}
        {isWeek6 && (
          <WeekRow>
            <FlexWraper style={{ width: '100%' }}>
              <WeekOptions
                text='W6'
                selectedWeek={selectedWeek}
                startDate={DatesInMonth[35]?.date}
                endDate={DatesInMonth[41]?.date}
                onCopy={(value) => setselectedWeek(value)}
                onPaste={handleWeekPaste}
                onClear={handleWeekClear}
              />
              {DatesInMonth.filter((_, i) => i > 34).map((day) => {
                const { currentMonth, date } = day;
                const sessions = getDaySessions(date);

                return _generateDay(date, sessions, currentMonth);
              })}
            </FlexWraper>
          </WeekRow>
        )}
      </div>
    </>
  );
};

export default PlannerDetailsView;
