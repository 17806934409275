/* eslint-disable prefer-template */
/* eslint-disable no-mixed-operators */
import React, { Component } from 'react';

import { accentColor } from '../../../shared/constants';

class ProgressRing extends Component {
  constructor(props) {
    super(props);

    const { radius, stroke } = this.props;

    this.normalizedRadius = radius - stroke * 2;
    this.circumference = this.normalizedRadius * 2 * Math.PI;
  }

  render() {
    const { radius, stroke, progress } = this.props;

    const strokeDashoffset = this.circumference - (progress / 100) * this.circumference;

    return (
      <svg height={radius * 2} width={radius * 2} transform='rotate(-90)'>
        <circle
          stroke={accentColor.primary}
          fill='#120A24'
          strokeWidth={stroke}
          strokeDasharray={`${this.circumference} ${this.circumference}`}
          style={{ strokeDashoffset }}
          strokeLinecap='round'
          r={this.normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
    );
  }
}

export default ProgressRing;
