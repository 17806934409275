/* eslint-disable no-else-return */

import React, { useEffect } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { Creators } from './store';
import { Creators as CommonCreators } from '../../store';

// styles
import {
  Card,
  SummaryContainer,
  ProgressContainer,
  ProgressDetails,
  KcalTaken,
  TotalKcal,
  MoreDetails,
  P,
  WaterContainer,
  WaterHeader,
  BottleContainer,
  UnfillBottle,
  FillTheBottle,
  EmptyBottleWrapper,
  CommentsContainer,
  CommentInputContainer
} from './IntakeSummaryStyles';

// assets
import { ReactComponent as KcalIcon } from '../../../shared/assets/kcal_icon.svg';
import { ReactComponent as FilledBottle } from '../../../shared/assets/filled_bottle.svg';
import { ReactComponent as EmptyBottle } from '../../../shared/assets/empty_bottle.svg';
import { ReactComponent as FillBottle } from '../../../shared/assets/fill_bottle_icon.svg';

// components
import ProgressRing from './ProgressRing';

import CommentsFooter from '../../../shared/components/CommetsFooter';
import { CommentInputComponent } from '../../../globalShared';
import { _converIntoKAndM } from '../../../services/utility/commurize';

const IntakeSummary = (props) => {
  const { date } = props;

  const intakeSummaryData = useSelector((state) => state.playerMealPlan.intakeSummary);
  const waterReload = useSelector((state) => state.playerMealPlan.waterReload);
  const intakeCommentsList = useSelector((state) => state.playerMealPlan.intakeSummaryCommentsList);
  const reloadComments = useSelector((state) => state.main.reloadComments);

  const dispatch = useDispatch();
  const count = 10;

  let _id = '';
  let commentCount = 0;
  let kcalTaken = 0;
  let totalKcal = 0;
  let totalCarbohydrates = 0;
  let totalProtein = 0;
  let totalFats = 0;
  let waterTaken = 0;
  let totalWaterIntake = 0;
  let actualMealCount = 0;
  let playerMealCount = 0;

  if (intakeSummaryData._id) {
    _id = intakeSummaryData._id;
    commentCount = intakeSummaryData.commentCount;
    kcalTaken = intakeSummaryData.kcalTaken;
    totalKcal = intakeSummaryData.totalKcal;
    totalCarbohydrates = intakeSummaryData.totalCarbohydrates;
    totalProtein = intakeSummaryData.totalProtein;
    totalFats = intakeSummaryData.totalFats;
    waterTaken = intakeSummaryData.waterTaken;
    totalWaterIntake = intakeSummaryData.totalWaterIntake;
    actualMealCount = intakeSummaryData.actualMealCount;
    playerMealCount = intakeSummaryData.playerMealCount;
  }

  // effect hooks
  useEffect(() => {
    if (waterReload) {
      dispatch(Creators.getPlayerMealPlanList({ date }));
    }
  }, [waterReload, date]);

  useEffect(() => {
    if (reloadComments && _id) {
      dispatch(Creators.getPlayerIntakeSummaryComments({ _id, count }));
    }
  }, [reloadComments]);

  // Water bottle fill and un-fill
  const bottles = [1, 2, 3, 4, 5, 6, 7, 8];
  let updatedWaterTaken = waterTaken;
  const waterPerBottle = 0.5;
  const completelyFilled = parseInt(updatedWaterTaken / waterPerBottle, 10);

  const todaysDate = moment().format('YYYY-MM-DD');

  const handleBottleUnfill = () => {
    updatedWaterTaken -= waterPerBottle;

    if (date === todaysDate) {
      dispatch(Creators.setPlayerWaterUpdate({ _id, water: updatedWaterTaken }));
    }
  };

  const handleBottleFill = () => {
    updatedWaterTaken += waterPerBottle;

    if (date === todaysDate) {
      dispatch(Creators.setPlayerWaterUpdate({ _id, water: updatedWaterTaken }));
    }
  };

  const handleShowComments = (_id) => {
    dispatch(Creators.getPlayerIntakeSummaryComments({ _id, count }));
  };

  const handleSubmitComment = (content, id) => {
    dispatch(CommonCreators.addComment({ content, id }));
  };

  return (
    <Card>
      <SummaryContainer>
        <ProgressContainer>
          <ProgressRing radius={88} stroke={8} progress={(playerMealCount / actualMealCount) * 100} />
          <ProgressDetails>
            <KcalIcon />
            <KcalTaken>
              <h1>{_converIntoKAndM(kcalTaken)}</h1>
              <p>Kcal</p>
            </KcalTaken>
            <TotalKcal>
              <p>Target</p>
              <p>{_converIntoKAndM(totalKcal)}</p>
            </TotalKcal>
          </ProgressDetails>
        </ProgressContainer>

        <MoreDetails>
          <P>
            Carb
            <span className='break'>{`${totalCarbohydrates}gm`}</span>
          </P>
          <P>
            Protein
            <span className='break'>{`${totalProtein}gm`}</span>
          </P>
          <P>
            Fat
            <span className='break'>{`${totalFats}gm`}</span>
          </P>
        </MoreDetails>
      </SummaryContainer>

      <WaterContainer>
        <WaterHeader>
          <p>Water Intake</p>
          <p>{`${waterTaken}L / ${totalWaterIntake}L`}</p>
        </WaterHeader>
        <BottleContainer>
          {bottles.map((bottle) => {
            if (bottle < completelyFilled) {
              if (bottle < 7) {
                return (
                  <React.Fragment key={bottle}>
                    <FilledBottle />
                  </React.Fragment>
                );
              } else if (bottle === 7) {
                return (
                  <UnfillBottle key={bottle} onClick={handleBottleUnfill}>
                    <FilledBottle />
                  </UnfillBottle>
                );
              } else {
                return (
                  <FillTheBottle key={bottle} onClick={handleBottleFill}>
                    <EmptyBottle />
                    <span style={{ position: 'absolute' }}>
                      <FillBottle />
                    </span>
                  </FillTheBottle>
                );
              }
            } else if (bottle === completelyFilled) {
              if (bottle < 7) {
                return (
                  <UnfillBottle key={bottle} onClick={handleBottleUnfill}>
                    <FilledBottle />
                  </UnfillBottle>
                );
              } else if (bottle === 7) {
                return (
                  <UnfillBottle key={bottle} onClick={handleBottleUnfill}>
                    <FilledBottle />
                  </UnfillBottle>
                );
              } else {
                return (
                  <FillTheBottle key={bottle} onClick={handleBottleFill}>
                    <EmptyBottle />
                    <span style={{ position: 'absolute' }}>
                      <FillBottle />
                    </span>
                  </FillTheBottle>
                );
              }
            } else if (date === todaysDate && (bottle === completelyFilled + 1 || completelyFilled >= 8)) {
              return (
                <FillTheBottle key={bottle} onClick={handleBottleFill}>
                  <EmptyBottle />
                  <span style={{ position: 'absolute' }}>
                    <FillBottle />
                  </span>
                </FillTheBottle>
              );
            } else {
              return (
                <EmptyBottleWrapper key={bottle}>
                  <EmptyBottle />
                </EmptyBottleWrapper>
              );
            }
          })}
        </BottleContainer>
      </WaterContainer>

      <CommentsContainer>
        <CommentsFooter
          _id={_id}
          commentCount={commentCount || 0}
          commentsList={intakeCommentsList}
          onClick={handleShowComments}
        />
        <CommentInputContainer>
          <CommentInputComponent placeholderText='Enter Your Comment Here…' id={_id} onSubmit={handleSubmitComment} />
        </CommentInputContainer>
      </CommentsContainer>
    </Card>
  );
};

export default IntakeSummary;
