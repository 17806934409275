import styled, { css } from 'styled-components';
import { fontSize, textColors, backgroundColor, accentColor } from '../../constants';
import icon from '../../assets/Dropdown.svg';

export const Label = styled.p`
  color: ${textColors.textLight};
  font-size: ${fontSize.mediumDefault};
  margin-bottom: 1rem;
`;

export const Select = styled.div`
  width: 100%;
  height: 45px;

  padding: 1.5rem;
  font-size: ${fontSize.mediumDefault};

  border-radius: 0.6rem;
  color: ${textColors.textWhite};

  background: ${backgroundColor.darkOpaq};
  border: none;

  position: relative;
  cursor: pointer;

  display: flex;
  align-items: center;

  ${(props) =>
    props.active &&
    props.position === 'bottom' &&
    css`
      border-radius: 0.6rem 0.6rem 0 0;
    `}

  ${(props) =>
    props.active &&
    props.position === 'top' &&
    css`
      border-radius: 0 0 0.6rem 0.6rem;
    `}

  &:focus {
    outline-color: ${accentColor.primary};
  }

  &::after {
    content: url(${icon});
    position: absolute;
    right: 6px;
  }
`;

export const DropdownContainer = styled.div`
  position: relative;
`;

export const Option = styled.p`
  padding: 1.5rem;

  font-size: ${fontSize.mediumDefault};

  &:hover {
    cursor: pointer;
  }
`;

export const Options = styled.div`
  position: absolute;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  z-index: 99;
  background-color: #2f2645;
  border-top: none;

  ${(props) =>
    props.position === 'bottom' &&
    css`
      border-radius: 0 0 0.6rem 0.6rem;
    `}

  ${(props) =>
    props.position === 'top' &&
    css`
      border-radius: 0.6rem 0.6rem 0 0;
      bottom: 45px;
    `}
`;
