import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const ControllsContainer = styled.div`
  padding: 0 1.5rem;
  display: flex;
  height: 50px;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
`;

export const PlayButton = styled.div`
  cursor: pointer;
  margin: 0 1rem;
  width: 15px;
  transition: 0.3s;
  & > svg {
    height: 15px;
  }
`;
export const PrimaryButton = styled.div`
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  & > svg {
    cursor: pointer;
  }
`;

export const Slider = styled.input`
  height: 4px;
  width: 100%;
  outline: none;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  cursor: pointer;
`;

export const Timer = styled.time`
  font-size: 12px;
  margin: 0 1rem;
  min-width: 40px;
`;
export const VolumeButton = styled.div`
  width: 20px;
  height: 20px;

  & > svg {
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
`;
