/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable operator-linebreak */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Creators } from './store';

import { Creators as CommonCreators } from '../../store';
import {
  FeedVideoPlayer,
  CoachFeedFooterComponent,
  MasonryLayout,
  LoadingComponent,
  AvatarComponent
} from '../../../globalShared';

// styles
import { EmptyContentText, FlexContainer } from '../../../globalShared/CommonStyles';
import {
  Card,
  CardHeader,
  PlayerTitle,
  VideoUploadedAT,
  Linkto,
  Container,
  VideoPlayerContainer
} from './SkillsStyles';
import CommentInputComponent from '../../../shared/components/CommentInputComponent';
import { dayFormater, shortenText } from '../../../services/utility';

// component
const CoachSkillView = (props) => {
  const { playerId } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const count = 10;
  const [currentVideo, setCurrentVideo] = React.useState('');
  const [recentId, setRecentId] = React.useState('');

  // values from redux store
  const feedData = useSelector((state) => state.coachSkills.feedList);
  const commentsList = useSelector((state) => state.coachSkills.commentsList);
  const page = useSelector((state) => state.coachSkills.page);
  const maxPage = useSelector((state) => state.coachSkills.maxPage);
  const loading = useSelector((state) => state.coachSkills.loading);

  const reloadComments = useSelector((state) => state.main.reloadComments);

  // effect hooks
  useEffect(() => {
    dispatch(Creators.getCoachFeedList({ count, page, playerId }));
  }, [playerId]);

  useEffect(() => {
    dispatch(Creators.getCoachFeedList({ count, page, playerId }));
  }, [page]);

  const handleShowComments = (id) => {
    dispatch(Creators.getCoachFeedComments({ id }));
    setRecentId(id);
  };

  const handleSubmitComment = (content, id) => {
    dispatch(CommonCreators.addComment({ content, id }));
    handleShowComments(id);
  };

  const handleScroll = ({ target }) => {
    if (target.scrollHeight - target.scrollTop === target.clientHeight && page < maxPage) {
      dispatch(Creators.setPage({ page: page + 1 }));
    }
  };

  useEffect(
    () => () => {
      dispatch(Creators.coachSkillsReset());
    },
    []
  );

  useEffect(() => {
    if (reloadComments) handleShowComments(recentId);
  }, [reloadComments]);

  const setVideoId = (id) => {
    setCurrentVideo(id);
  };

  const renderSkillFeed = () => (
    <>
      {feedData.length > 0 ? (
        <MasonryLayout gap={25}>
          {feedData.map((feed) => {
            const { firstName, lastName, profilePicture, _id, uploadDate, videoUrl } = feed;
            return (
              <Card key={_id}>
                <CardHeader>
                  <FlexContainer>
                    <AvatarComponent url={profilePicture} />
                    <div>
                      <PlayerTitle>{shortenText(`${firstName} ${lastName}`, 30, '...')}</PlayerTitle>
                      <VideoUploadedAT>{dayFormater(uploadDate)}</VideoUploadedAT>
                    </div>
                  </FlexContainer>

                  <Linkto onClick={() => history.push(`/coach/skills/watchVideo/${_id}`)}>See Details</Linkto>
                </CardHeader>

                <VideoPlayerContainer>
                  <FeedVideoPlayer URL={videoUrl} setVideoId={setVideoId} id={_id} playVideo={currentVideo} />
                </VideoPlayerContainer>

                <CoachFeedFooterComponent {...feed} commentsList={commentsList} onClick={handleShowComments} />

                <div style={{ padding: '1rem 1.5rem' }}>
                  <CommentInputComponent
                    placeholderText='Enter Your Comment Here…'
                    id={_id}
                    onSubmit={handleSubmitComment}
                  />
                </div>
              </Card>
            );
          })}
        </MasonryLayout>
      ) : (
        <>{!loading && <EmptyContentText>No Data Available</EmptyContentText>}</>
      )}
    </>
  );

  // dispatch
  return (
    <>
      {loading && <LoadingComponent />}
      <Container onScroll={handleScroll}>{renderSkillFeed()}</Container>
    </>
  );
};
export default CoachSkillView;
