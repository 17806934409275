import styled from 'styled-components';
import { FlexContainer } from '../../../globalShared/CommonStyles';
import {
  fontSize,
  fontWeight,
  accentColor,
  borderColor,
  shadow,
  textColors,
  backgroundColor
} from '../../../shared/constants';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  padding-right: 0.5rem;
  overflow-y: auto;
`;
export const Card = styled.div`
  width: 100%;
  background: #261d3c;
  border-radius: 6px;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1.5rem 0;
  margin: 1.5rem;

  border-bottom: 1px solid ${borderColor.light};
`;

export const OpenModal = styled.p`
  font-size: ${fontSize.mediumDefault};
  color: ${accentColor.primary};
`;

export const PlayerTitle = styled.h1`
  font-size: ${fontSize.mediumDefault};
  font-weight: ${fontWeight.medium};

  margin: 0 1rem;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

export const ThoughtTime = styled.p`
  font-size: ${fontSize.smallDefault};
  font-weight: ${fontWeight.medium};
  color: #8792ac;
  margin: 0.5rem 1rem;
`;

export const FeedContainer = styled.div`
  margin: 0 1.5rem;
  display: flex;
  flex-direction: column;
`;

export const MoodName = styled.p`
  font-size: 14px;
  font-weight: ${fontWeight.medium};
  color: #8792ac;
  margin-bottom: 1.5rem;

  & > span {
    display: inline-block;
    font-size: 16px;
    font-weight: ${fontWeight.medium};
    padding-left: 0.3rem;
  }
`;

export const ThoughtsContainer = styled.div`
  border: 1px solid ${borderColor.light};
  border-radius: 6px;
  padding: 1rem;

  font-size: 14px;
  font-weight: ${fontWeight.medium};
  color: #8792ac;

  & > p {
    padding-top: 1rem;
    font-size: 14px;
    font-weight: ${fontWeight.medium};
  }
`;

export const SleepContainer = styled.div`
  font-size: 14px;
  font-weight: ${fontWeight.medium};
  color: #8792ac;

  padding-bottom: 1.5rem;

  & > p {
    display: inline-block;
    font-size: 16px;
    font-weight: ${fontWeight.medium};
    padding-left: 0.3rem;
  }
`;

export const MeditationContainer = styled.div`
  font-size: 14px;
  font-weight: ${fontWeight.medium};
  color: #8792ac;

  padding-bottom: 1.5rem;
  border-bottom: 1px solid ${borderColor.light};
`;

export const MeditationTime = styled.p`
  display: inline-block;
  padding-left: 0.3rem;

  font-size: 16px;
  font-weight: ${fontWeight.medium};

  padding-right: 1rem;
  border-right: 1px solid ${borderColor.light};
`;

export const MeditationName = styled.p`
  display: inline-block;
  padding-left: 1rem;

  font-size: 16px;
  font-weight: ${fontWeight.medium};
`;

export const DetailsContainer = styled(FlexContainer)`
  background: ${backgroundColor.cardItem};
  padding: 1.6rem;
  border-radius: 6px;
  box-shadow: 0px 10px 10px ${shadow.dark};
  margin-bottom: 1.2rem;
`;

export const Circle = styled.img`
  height: 40px;
  width: 40px;
  margin-right: 1.2rem;
`;

export const Title = styled.div`
  font-size: calc(${fontSize.mediumDefault} - 0.1rem);
  color: ${textColors.textLight};
  margin-right: 1rem;
  word-break: break-word;
  & > span {
    font-size: ${fontSize.mediumDefault};
    font-weight: ${fontWeight.medium};
    padding: 0 0.5rem;
    color: ${textColors.textWhite};
  }
`;

export const Time = styled.p`
  font-size: ${fontSize.smallDefault};
  font-weight: ${fontWeight.medium};
  color: ${textColors.textLight};
  margin: 0.5rem 0rem;
`;
