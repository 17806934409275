/* eslint-disable no-unused-vars */
/*
 * reducer.js file for coach workouts view
 * Author:
 * 1.Manikanta
 * @copyright of Panorbit Services LLP.
 */

import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  loading: false,
  error: null,
  success: false,
  workoutsList: [],
  reloadList: false,
  page: 1,
  maxPage: 1,
  workout: null,
  showModal: false,
  showDetails: false,
  totalItems: 0,
  searchKey: ''
};

const getCoachWorkoutListStart = (state = INITIAL_STATE) => ({ ...state, loading: true });

const getCoachWorkoutListFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const getCoachWorkoutListSuccess = (state = INITIAL_STATE, action) => {
  const { workoutsList, maxPage, totalItems } = action.payload;
  return {
    ...state,
    loading: false,
    reloadList: false,
    success: false,
    workoutsList,
    maxPage,
    totalItems
  };
};

const searchWorkoutStart = (state = INITIAL_STATE, action) => ({ ...state, error: null, loading: true });

const searchWorkoutSuccess = (state = INITIAL_STATE, action) => {
  const { workoutsList, totalItems } = action.payload;

  return {
    ...state,
    loading: false,
    workoutsList,
    totalItems
  };
};

const searchWorkoutListSuccess = (state = INITIAL_STATE, action) => {
  const { workoutsList } = action.payload;

  return {
    ...state,
    loading: false,
    workoutsList
  };
};

const searchWorkoutFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const resetWorkoutList = (state = INITIAL_STATE) => ({ ...state, workoutsList: [] });

const createWorkoutStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true, reloadList: false });

const createWorkoutSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: false,
  // page: 1,
  reloadList: true,
  success: true
});

const createWorkoutFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const deleteCoachWorkoutStart = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true,
  reloadList: false,
  success: true
});

const deleteCoachWorkoutSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  // page: 1,
  loading: false,
  reloadList: true
});

const deleteCoachWorkoutFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const setWorkout = (state = INITIAL_STATE, action) => {
  const { workout } = action.payload;
  return {
    ...state,
    workout
  };
};

const setShowmodal = (state = INITIAL_STATE, action) => {
  const { show } = action.payload;
  return {
    ...state,
    showModal: show
  };
};

const setShowDetail = (state = INITIAL_STATE, action) => {
  const { show } = action.payload;
  return {
    ...state,
    showDetails: show
  };
};

const resetError = (state = INITIAL_STATE) => ({ ...state, error: null });
const resetSuccess = (state = INITIAL_STATE) => ({ ...state, success: false });

const setWorkoutSearchKey = (state = INITIAL_STATE, action) => {
  const { searchKey } = action.payload;
  return {
    ...state,
    searchKey,
    page: 1
  };
};

const setWorkoutPage = (state = INITIAL_STATE, action) => {
  const { page } = action.payload;
  return {
    ...state,
    page
  };
};

const resetWorkout = () => INITIAL_STATE;

const HANDLERS = {
  [Types.GET_COACH_WORKOUT_LIST_START]: getCoachWorkoutListStart,
  [Types.GET_COACH_WORKOUT_LIST_FAILURE]: getCoachWorkoutListFailure,
  [Types.GET_COACH_WORKOUT_LIST_SUCCESS]: getCoachWorkoutListSuccess,

  [Types.SEARCH_WORKOUT_START]: searchWorkoutStart,
  [Types.SEARCH_WORKOUT_SUCCESS]: searchWorkoutSuccess,
  [Types.SEARCH_WORKOUT_LIST_SUCCESS]: searchWorkoutListSuccess,
  [Types.SEARCH_WORKOUT_FAILURE]: searchWorkoutFailure,

  [Types.CREATE_WORKOUT_START]: createWorkoutStart,
  [Types.CREATE_WORKOUT_SUCCESS]: createWorkoutSuccess,
  [Types.CREATE_WORKOUT_FAILURE]: createWorkoutFailure,

  [Types.DELETE_COACH_WORKOUT_START]: deleteCoachWorkoutStart,
  [Types.DELETE_COACH_WORKOUT_SUCCESS]: deleteCoachWorkoutSuccess,
  [Types.DELETE_COACH_WORKOUT_FAILURE]: deleteCoachWorkoutFailure,

  [Types.RESET_WORKOUT_LIST]: resetWorkoutList,
  [Types.SET_WORKOUT]: setWorkout,
  [Types.SET_SHOWMODAL]: setShowmodal,
  [Types.SET_SHOW_DETAIL]: setShowDetail,

  [Types.RESET_ERROR]: resetError,
  [Types.RESET_SUCCESS]: resetSuccess,
  [Types.SET_WORKOUT_SEARCH_KEY]: setWorkoutSearchKey,
  [Types.SET_WORKOUT_PAGE]: setWorkoutPage,
  [Types.RESET_WORKOUT]: resetWorkout
};

export const coachWorkoutReducer = createReducer(INITIAL_STATE, HANDLERS);
