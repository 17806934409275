/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as CloseIcon } from '../../assets/Close.svg';

import { Container, CardContainer, Title, CloseButton, Header, Body, Footer } from './DeletePopup.styles';
import ButtonComponenet from '../ButtonComponent';
import { localString } from '../../../services/locallization/localStrings';

// component
const DeletePopup = (props) => {
  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  const { title, closePopupFunc, primaryText, secondaryText, deleteFunc, itemID, MainBtnText = string.delete } = props;

  return (
    <Container>
      <CardContainer>
        <Header>
          <Title>{title}</Title>

          <CloseButton onClick={closePopupFunc}>
            <CloseIcon />
          </CloseButton>
        </Header>

        <Body>
          <h1>{primaryText}</h1>
          {secondaryText ? <h2>{secondaryText}</h2> : ''}
        </Body>

        <Footer>
          <ButtonComponenet
            buttonText={string.cancel}
            bgType='dark'
            isButtonAction={true}
            buttonClick={closePopupFunc}
          />
          <ButtonComponenet
            buttonText={MainBtnText}
            bgType='danger'
            isButtonAction={true}
            buttonClick={() => deleteFunc(itemID)}
          />
        </Footer>
      </CardContainer>
    </Container>
  );
};

export default DeletePopup;
