/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from './store';
import { Creators as Coach } from '../../Coach/HomeView/store';
import { Creators as User } from '../UsersView/store';

import { FlexContainer, Link, Flex } from '../../../globalShared/CommonStyles';

import Toast, { Toasts } from '../../../services/toast';

// components
import { ButtonComponent, MasonryLayout } from '../../../globalShared';
import Card from '../../../shared/components/ChartCardsComponent';
import AddUserView from '../UsersView/AdduserModal';

// styles
import { Header, Divider, Name, Number, GridContainer, CardsContainer, H1, P, Chart } from './HomeView.styles';
import TeamReadiness from '../../Coach/HomeView/TeamReadiness';
import TeamStrengthConditioning from '../../Coach/HomeView/TeamStrengthConditioning';
import { localString } from '../../../services/locallization/localStrings';

// consts
const options2 = [
  { label: 'Weekly', value: 'weeks' },
  { label: 'Monthly', value: 'months' }
];

const AdminHomeView = () => {
  const dispatch = useDispatch();

  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  const [readiness, setReadiness] = useState(options2[0]);
  const [strength, setStrength] = useState(options2[0]);

  const [showAddUser, setshowAddUser] = useState(false);

  const _Readiness = useSelector((state) => state.coachHome.readiness);
  const _Strength = useSelector((state) => state.coachHome.strength);
  const details = useSelector((state) => state.adminHome.details);

  const success = useSelector((state) => state.adminUsers.success);
  const errorMsg = useSelector((state) => state.adminUsers.error);

  useEffect(() => {
    dispatch(Coach.getCoachHomeReadiness({ dataType: readiness.value }));
  }, [readiness]);

  useEffect(() => {
    dispatch(Coach.getCoachHomeStrength({ dataType: strength.value }));
  }, [strength]);

  useEffect(() => {
    dispatch(Creators.getAdminHomeDetails());
  }, []);

  // hooks to  display toast notifications
  useEffect(() => {
    if (success) {
      const reset = () => dispatch(User.resetSuccess());
      Toast.success(`${string.success}`, 2, reset);
    }

    if (errorMsg) {
      const reset = () => dispatch(User.resetError());
      Toast.error(errorMsg, 2, reset);
    }
  }, [success, errorMsg]);

  const pair = (name, number) => (
    <FlexContainer>
      <Name>{name}</Name>
      <Number>{number || 0}</Number>
    </FlexContainer>
  );

  // main component
  return (
    <>
      <Toasts />
      <Header banner={details.bannerImage}>
        <div className='overlay' />
        <div className='container'>
          <>{pair(string.users, details.users)}</>
          <Divider />
          <>{pair(string.admins, details.admins)}</>
          <Divider />
          <>{pair(string.coaches, details.coaches)}</>
          <Divider />
          <>{pair(string.players, details.players)}</>
        </div>
      </Header>
      <GridContainer>
        {/* charts card */}
        <CardsContainer className='item1' style={{ paddingBottom: '0' }}>
          <MasonryLayout>
            {/* Readiness Card */}
            <Chart>
              <Card
                title={string.teamReadiness}
                optionArray={options2}
                selected={readiness}
                onClick={(value) => setReadiness(value)}
              >
                <TeamReadiness {..._Readiness} />
              </Card>
            </Chart>

            {/* strength and condiion Card */}
            <Chart>
              <Card
                title={string.teamSNC}
                optionArray={options2}
                selected={strength}
                onClick={(value) => setStrength(value)}
              >
                <TeamStrengthConditioning {..._Strength} />
              </Card>
            </Chart>
          </MasonryLayout>
        </CardsContainer>

        {/* user management card */}
        <CardsContainer>
          <Flex className='spaceBetween' style={{ marginBottom: '1.5rem' }}>
            <H1>{string.userManagement}</H1>
            <Link href='/admin/users'>{string.allUsers}</Link>
          </Flex>

          <Flex className='spaceBetween' style={{ marginBottom: '1.5rem' }}>
            <P className='light'>{`${string.users}:`}</P>
            <H1>{details.users}</H1>
          </Flex>

          <P className='opaq' style={{ marginBottom: '1.5rem' }}>
            {string.manageYourUsers}
          </P>
          <Flex style={{ marginLeft: '-11px' }}>
            <ButtonComponent
              buttonText={string.addUsers}
              bgType='dark'
              buttonClick={() => setshowAddUser(true)}
              margin={true}
            />
            <ButtonComponent buttonText={string.editUser} bgType='dark' margin={false} />
          </Flex>
        </CardsContainer>

        {/* billing card */}

        {/* <CardsContainer>
          <Flex className='spaceBetween' style={{ marginBottom: '1.5rem' }}>
            <H1>{string.billing}</H1>
          </Flex>

          <Flex className='spaceBetween' style={{ marginBottom: '1.5rem' }}>
            <P className='light'>{`${string.totalBalance}:`}</P>
            <P className='price'>156</P>
          </Flex>

          <Flex className='spaceBetween' style={{ marginBottom: '1.5rem' }}>
            <P className='light'>{`${string.nextDueDate}:`}</P>
            <H1>21 oct 20</H1>
          </Flex>

          <Flex style={{ marginLeft: '-11px' }}>
            <ButtonComponent buttonText={string.addMoney} bgType='dark' />
          </Flex>
        </CardsContainer> */}
      </GridContainer>

      {/*
       *
       *
       * add users slider
       *
       *
       *
       */}
      {showAddUser && <AddUserView onCancel={() => setshowAddUser(false)} />}
    </>
  );
};

export default AdminHomeView;
