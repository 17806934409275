/* eslint-disable no-console */

/* eslint-disable react/no-string-refs */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/no-unused-state */
import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { FormHeader, LogoHeaderComponent, ButtonComponent, LoadingComponent } from '../../../globalShared';
import { Container, SubHeading, LinkContainer, Input, FormContainer, Text } from './Otp.styles';
import Toast, { Toasts } from '../../../services/toast';
import { ReactComponent as BackIcon } from '../../../shared/assets/back.svg';
import { Creators } from './store';
import { Creators as Email } from '../ForgotPasswordView/store';
import { localString } from '../../../services/locallization/localStrings';

// text constants
// const HeadingText = 'Forgot Password';
// const SubHeadingText = 'OTP Verification';

const OtpView = (props) => {
  const { history } = props;
  // create  ref
  const pin1Ref = useRef();
  const pin2Ref = useRef();
  const pin3Ref = useRef();
  const pin4Ref = useRef();
  const pin5Ref = useRef();
  const pin6Ref = useRef();

  const [pin1, setPin1] = useState('');
  const [pin2, setPin2] = useState('');
  const [pin3, setPin3] = useState('');
  const [pin4, setPin4] = useState('');
  const [pin5, setPin5] = useState('');
  const [pin6, setPin6] = useState('');

  const dispatch = useDispatch();
  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  // state values
  const { success, error, loading } = useSelector((state) => state.verifyOtp);

  const userMail = useSelector((state) => state.forgotPassword.email);

  const handleSubmit = () => {
    const otp = [pin1, pin2, pin3, pin4, pin5, pin6].join('');
    if (userMail && otp.trim().length === 6) {
      dispatch(Creators.verifyOtp({ userMail, otp }));
    }
  };

  const handleResend = () => {
    if (userMail) {
      dispatch(Email.verifyEmail({ email: userMail }));
    }

    return null;
  };

  useEffect(() => {
    if (success) {
      history.push('/ResetPassword');
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      Toast.error(error, 2);
    }
  }, [error]);

  useEffect(
    () => () => {
      dispatch(Creators.resetOtp());
    },
    []
  );

  return (
    <>
      {loading && <LoadingComponent />}
      <LogoHeaderComponent />
      <Container>
        <FormHeader headingText={string.forgotPassWord} subHeadingText={string.otpVerfication} />
        <SubHeading>
          {string.enterThe6digit}
          <br />
          Received in Your Email
        </SubHeading>
        <FormContainer>
          <div>
            <Input
              maxLength='1'
              value={pin1}
              ref={pin1Ref}
              onChange={(e) => {
                setPin1(e.target.value);
                if (!pin1) {
                  pin2Ref.current.focus();
                }
              }}
              required
            />
            <Input
              maxLength='1'
              value={pin2}
              ref={pin2Ref}
              onChange={(e) => {
                setPin2(e.target.value);
                if (!pin2) {
                  pin3Ref.current.focus();
                }
              }}
              required
            />
            <Input
              maxLength='1'
              value={pin3}
              ref={pin3Ref}
              onChange={(e) => {
                setPin3(e.target.value);
                if (!pin3) {
                  pin4Ref.current.focus();
                }
              }}
              required
            />
            <Input
              maxLength='1'
              value={pin4}
              ref={pin4Ref}
              onChange={(e) => {
                setPin4(e.target.value);
                if (!pin4) {
                  pin5Ref.current.focus();
                }
              }}
              required
            />
            <Input
              maxLength='1'
              value={pin5}
              ref={pin5Ref}
              onChange={(e) => {
                setPin5(e.target.value);
                if (!pin5) {
                  pin6Ref.current.focus();
                }
              }}
              required
            />
            <Input maxLength='1' value={pin6} ref={pin6Ref} onChange={(e) => setPin6(e.target.value)} required />
          </div>
          <Text>
            Didn &lsquo; t Receive The Code?
            <span role='presentation' onClick={handleResend}>
              Resend
            </span>
          </Text>

          <ButtonComponent
            buttonText='Verify OTP'
            isButtonAction={true}
            buttonClick={handleSubmit}
            buttonType='button'
          />
        </FormContainer>
        <LinkContainer
          to={{
            pathname: '/forgotpsw'
          }}
        >
          <BackIcon />
          Back
        </LinkContainer>

        <Toasts />
      </Container>
    </>
  );
};

export default OtpView;
