/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  AccordionContent,
  TimeKcalWrapper,
  TimeContainer,
  Time,
  KcalContainer,
  Kcal,
  IntakeWrapper,
  MealDetailsHeader,
  IntakeHeader,
  RemarksHeader,
  Intake,
  CommentsContainer,
  CommentInputContainer
} from './AccordionStyles';

// icons
import { ReactComponent as TimeClockIcon } from '../../../shared/assets/time_clock.svg';
import { ReactComponent as KcalStrokedIcon } from '../../../shared/assets/kcal_stroked.svg';
import { ReactComponent as IntakeIcon } from '../../../shared/assets/intake_red_icon.svg';
import { ReactComponent as RemarksIcon } from '../../../shared/assets/remarks_orange_icon.svg';

import CommentsFooter from '../../../shared/components/CommetsFooter';
import { CommentInputComponent } from '../../../globalShared';

const Accordion = (props) => {
  const { meal, onShowComments, submitComment, comments } = props;
  const { mealId, time, kcal, intake, commentCount } = meal;

  const handleShowComments = (_id) => {
    onShowComments(_id);
  };

  const handleSubmitComment = (content, mealId) => {
    submitComment({ content, id: mealId });
  };

  return (
    <AccordionContent>
      <TimeKcalWrapper>
        <TimeContainer>
          <TimeClockIcon />
          <Time>{time}</Time>
        </TimeContainer>
        <KcalContainer>
          <KcalStrokedIcon />
          <Kcal>
            <p>{kcal}</p>
            <p>kcal</p>
          </Kcal>
        </KcalContainer>
      </TimeKcalWrapper>

      <IntakeWrapper>
        <MealDetailsHeader>
          <IntakeHeader>
            <IntakeIcon />
            <p>Intakes</p>
          </IntakeHeader>
          <RemarksHeader>
            <RemarksIcon />
            <p>Remarks</p>
          </RemarksHeader>
        </MealDetailsHeader>

        {intake.map((item) => (
          <Intake key={uuidv4()}>
            <p className='word'>{item.descr}</p>
            <p className='word'>{item.remarks}</p>
          </Intake>
        ))}
      </IntakeWrapper>

      <CommentsContainer>
        <CommentsFooter _id={mealId} commentCount={commentCount} commentsList={comments} onClick={handleShowComments} />
        <CommentInputContainer>
          <CommentInputComponent
            placeholderText='Enter Your Comment Here…'
            id={mealId}
            onSubmit={(content, mealId) => handleSubmitComment(content, mealId)}
          />
        </CommentInputContainer>
      </CommentsContainer>
    </AccordionContent>
  );
};

export default Accordion;
