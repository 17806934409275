/* eslint-disable no-confusing-arrow */
import styled from 'styled-components';
import { backgroundColor, borderColor, fontSize, textColors } from '../../../shared/constants';

export const ModalContainer = styled.div`
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  justify-content: flex-end;

  background-color: ${backgroundColor.popupBg};
  z-index: 99999;
`;

export const ContentContainer = styled.div`
  height: 100%;
  width: 1083px;

  padding: 2.4rem;

  background-color: ${backgroundColor.popup};
  border-radius: 6px 0px 0px 6px;

  overflow-y: auto;
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 1105px;
  top: 2rem;
  z-index: 999;
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  border-bottom: 1px solid ${borderColor.light};

  & > svg {
    cursor: pointer;
  }
`;

export const MealTypeContainer = styled.div`
  display: flex;
  align-items: center;

  & > h1 {
    font-size: ${fontSize.titleVerySmall};
    margin-right: 13px;
    max-width: 710px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  & > div > p:first-child {
    display: inline-block;

    font-size: 14px;
    padding-left: 13px;
    border-left: 1px solid ${borderColor.light};
    color: ${textColors.textLight};
  }
`;

export const FoodType = styled.p`
  display: inline-block;

  font-size: 16px;
  margin-left: 0.7rem;
  color: #e7e9ef;
`;

export const Content = styled.div`
  height: 83vh;
  overflow-y: auto;
  padding-right: 1rem;
`;

export const NutrientHeader = styled.p`
  text-transform: capitalize;
  font-size: 14px;
  padding: 22px 0;
  color: #d0d5e2;
`;

export const NutrientsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;

  & > span > p:first-child {
    display: inline-block;
    font-size: 14px;
    color: ${textColors.textLight};
  }
`;

export const NutrientValue = styled.p`
  display: inline-block;
  padding-left: 0.8rem;
  font-size: 16px;
  color: #e7e9ef;
`;

export const MealContainer = styled.div`
  width: 100%;
  height: auto;
  border: 1px solid ${borderColor.light};
  border-radius: 6px;
  margin-bottom: 23px;

  overflow: hidden;
`;

export const MealHeader = styled.div`
  height: 60px;
  background: ${borderColor.light};
  border-bottom: 1px solid ${borderColor.light};
  border-radius: 6px 6px 0 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  & > .meal {
    max-width: 70%;
  }

  & > div > p {
    display: inline-block;
    font-size: 16px;
    color: ${textColors.textLight};
  }
`;

export const MealTitle = styled.h1`
  display: inline-block;
  font-size: 20px;
  padding-left: 0rem;
  text-transform: capitalize;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

export const MoreDetails = styled.div`
  width: 30%;
  display: flex;
  justify-content: space-between;

  & > span > p:first-child {
    display: inline-block;
    font-size: 16px;
    color: ${textColors.textLight};
  }
`;

export const Value = styled.p`
  display: inline-block;
  font-size: 16px;
  padding-left: 0.7rem;
`;

export const MealDetails = styled.div`
  padding: 20px;
`;

export const MealDetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;

  & > p {
    width: 50%;
    display: inline-block;
    font-size: 14px;
    text-transform: capitalize;
    color: ${textColors.textLight};
  }
`;

export const IntakeWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 13px 0;
  border-top: 1px solid ${borderColor.light};

  & > p {
    width: 50%;
    display: inline-block;
    font-size: 16px;
    padding-right: 5px;
  }
`;
