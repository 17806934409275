/*
 * action.js file for createPassword view
 * Author:
 * 1. Manikanta
 *
 * @copyright of Panorbit Services LLP.
 */

import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  createPassword: ['payload'],
  createPasswordStart: ['payload'],
  createPasswordSuccess: ['payload'],
  createPasswordFailure: ['payload'],
  resetPass: null
});

export { Types, Creators };
