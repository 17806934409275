import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Creators as ModalDataCreators } from './store';

// assets
import { ReactComponent as CloseIcon } from '../../../shared/assets/Close.svg';
import { ReactComponent as DownloadIcon } from '../../../shared/assets/Download.svg';

// components
import { TabsComponenet } from '../../../globalShared';
import {
  Container,
  CardContainer,
  Title,
  CloseButton,
  Header,
  Body,
  ScrollContainer,
  Muted,
  Linkto,
  DownloadSpan
} from './PlayersHistoryModalStyles';
import { shortenText } from '../../../services/utility';

const PlayersHistoryModal = () => {
  const historyData = useSelector((state) => state.modalData.historyModalData);
  const healthList = useSelector((state) => state.modalData.historyModalData.healthList);
  const injuryList = useSelector((state) => state.modalData.historyModalData.injuryList);
  // const completeHistoryData = useSelector((state) => state.modalData.groupPlayers);
  const historyModal = useSelector((state) => state.modalData.historyModal);

  // console.log(historyData);
  const dispatch = useDispatch();

  // Injury history tab
  const History = (data = []) => (
    /* eslint-disable */
    <>
      {data.map((el, i) => (
        <Body key={i}>
          <Muted>
            {el.description}
            {el.mediaUrl && (
              <Linkto href={el.mediaUrl} alt={el.description}>
                <DownloadSpan>
                  <DownloadIcon />
                </DownloadSpan>
              </Linkto>
            )}
          </Muted>
        </Body>
      ))}
    </>
  );

  // Render Player History Modal
  const RenderPlayerHistoryModal = () => {
    const { firstName, lastName, noOfHealthList, noOfInjuries } = historyData;
    const handleClose = () => {
      dispatch(ModalDataCreators.setHistoryModal({ historyModal: !historyModal }));
    };

    return (
      <Container>
        <CardContainer>
          <Header>
            <div>
              <Title>{shortenText(`${firstName} ${lastName}`, 30, '...')}</Title>
            </div>

            <div>
              <CloseButton onClick={handleClose}>
                <CloseIcon />
              </CloseButton>
            </div>
          </Header>

          <TabsComponenet>
            <div label='Injury History' notification={noOfInjuries || '0'}>
              <ScrollContainer>{History(injuryList)}</ScrollContainer>
            </div>
            <div label='Health History' notification={noOfHealthList || '0'}>
              <ScrollContainer>{History(healthList)}</ScrollContainer>
            </div>
          </TabsComponenet>
        </CardContainer>
      </Container>
    );
  };

  if (historyModal) {
    return RenderPlayerHistoryModal();
  }
  return <></>;
};

export default PlayersHistoryModal;
