/* eslint-disable indent */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable operator-linebreak */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import CommentInputComponent from '../CommentInputComponent';
import CommentInfo from '../CommentInfoComponent';

// styles
import { Container, CommentsHeader, CommentsScrollContainer, Footer } from './CommentsStyles';
import userIcon from '../../assets/user.jpg';

import { timeSince } from '../../../services/utility';
import { EmptyContentText } from '../../../globalShared/CommonStyles';
import { localString } from '../../../services/locallization/localStrings';

/**
 * component
 */

const CommentsComponent = (props) => {
  const handleScroll = ({ target }) => {
    const { updateComments } = props;
    const { scrollTop, scrollHeight, clientHeight } = target;
    if (scrollHeight - scrollTop === clientHeight) {
      updateComments();
    }
  };

  const { commentsList, submitComment, id, count } = props;
  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  return (
    <Container>
      <CommentsHeader>{`${string.comments} (${count})`}</CommentsHeader>
      <CommentsScrollContainer onScroll={handleScroll}>
        {count ? (
          commentsList.map((comment) => {
            const { content, firstName, _id, lastName, profilePicture, dateAdded } = comment;

            return (
              <CommentInfo
                key={_id}
                userPic={profilePicture || userIcon}
                playerName={`${firstName} ${lastName}`}
                comment={content}
                time={timeSince(dateAdded)}
              />
            );
          })
        ) : (
          <EmptyContentText style={{ fontSize: '16px' }}>{string.noComments}</EmptyContentText>
        )}
      </CommentsScrollContainer>
      <Footer>
        <CommentInputComponent placeholderText={string.enterCommentsHere} id={id} onSubmit={submitComment} />
      </Footer>
    </Container>
  );
};

CommentsComponent.propTypes = {
  commentsList: PropTypes.array.isRequired,
  submitComment: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  updateComments: PropTypes.func.isRequired
};

export default CommentsComponent;
