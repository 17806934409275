/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable no-shadow */
import { put, select } from 'redux-saga/effects';
import { Creators } from './action';

import { WebService } from '../../../../services/axios/webServices';
import { apiEndPoints, rootConfig } from '../../../../services/axios/endPoints';

export function* getCoachMindsetFeedList({ payload }) {
  const { page, count, playerId, fetchTime } = payload;

  try {
    yield put(Creators.getCoachMindsetFeedListStart());

    const fullURL = yield `${apiEndPoints.coachMindsetRecent}`;

    const response = yield WebService.get(fullURL, { params: { page, count, playerId, fetchTime } });

    if (response.status >= 200 && response.status < 300) {
      const { mindsetFeedList } = yield select((state) => state.coachMindset);
      const { data } = response.data;

      if (mindsetFeedList.length && page !== 1) {
        yield put(
          Creators.getCoachMindsetFeedListSuccess({
            mindsetFeedList: [...mindsetFeedList, ...(data[0] || [])],
            fetchTime: data[1].fetchTime,
            isNext: data[2].isNext
          })
        );
      } else {
        yield put(
          Creators.getCoachMindsetFeedListSuccess({
            mindsetFeedList: data[0] || [],
            fetchTime: data[1].fetchTime,
            isNext: data[2].isNext
          })
        );
      }
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getCoachMindsetFeedListFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getCoachMindsetFeedListFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getCoachMindsetFeedListFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getCoachMindsetFeedListFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getCoachMindsetFeedListFailure(payload));
    }
  }
}

export function* getCoachMindsetFeedCommentsSaga({ payload }) {
  const { id, page = '1' } = payload;

  try {
    yield put(Creators.getCoachMindsetFeedCommentsStart());
    const fullURL = yield `${apiEndPoints.commentList}`;

    const response = yield WebService.get(fullURL, { params: { _id: id, page, count: 100 } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      const payload = {
        mindsetCommentsList: data[0],
        maxPageNo: data[1]
      };

      yield put(Creators.getCoachMindsetFeedCommentsSuccess(payload));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getCoachMindsetFeedCommentsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getCoachMindsetFeedCommentsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getCoachMindsetFeedCommentsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getCoachMindsetFeedCommentsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getCoachMindsetFeedCommentsFailure(payload));
    }
  }
}

export function* getCoachMoodCommentsSaga({ payload }) {
  const { _id, page, count } = payload;

  try {
    yield put(Creators.getCoachMoodCommentsStart());
    const fullURL = yield `${apiEndPoints.commentList}`;

    const response = yield WebService.get(fullURL, { params: { _id, page, count } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      const payload = {
        moodCommentsList: data[0],
        moodCommentCount: data[2].commentCount
      };

      yield put(Creators.getCoachMoodCommentsSuccess(payload));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getCoachMoodCommentsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getCoachMoodCommentsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getCoachMoodCommentsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getCoachMoodCommentsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getCoachMoodCommentsFailure(payload));
    }
  }
}

export function* getCoachSleepCommentsSaga({ payload }) {
  const { _id, page, count } = payload;

  try {
    yield put(Creators.getCoachSleepCommentsStart());
    const fullURL = yield `${apiEndPoints.commentList}`;

    const response = yield WebService.get(fullURL, { params: { _id, page, count } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      const payload = {
        sleepCommentsList: data[0]
      };

      yield put(Creators.getCoachSleepCommentsSuccess(payload));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getCoachSleepCommentsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getCoachSleepCommentsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getCoachSleepCommentsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getCoachSleepCommentsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getCoachSleepCommentsFailure(payload));
    }
  }
}

export function* getCoachMeditationCommentsSaga({ payload }) {
  const { _id, page, count } = payload;

  try {
    yield put(Creators.getCoachMeditationCommentsStart());
    const fullURL = yield `${apiEndPoints.commentList}`;

    const response = yield WebService.get(fullURL, { params: { _id, page, count } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      const payload = {
        meditationCommentsList: data[0]
      };

      yield put(Creators.getCoachMeditationCommentsSuccess(payload));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getCoachMeditationCommentsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getCoachMeditationCommentsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getCoachMeditationCommentsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getCoachMeditationCommentsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getCoachMeditationCommentsFailure(payload));
    }
  }
}

export function* getCoachMoodDetails({ payload }) {
  const { _id } = payload;

  try {
    yield put(Creators.getCoachMoodDetailsStart());

    const fullURL = yield `${apiEndPoints.moodDetails}`;
    const response = yield WebService.get(fullURL, { params: { _id } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(Creators.getCoachMoodDetailsSuccess({ moodDetails: data }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getCoachMoodDetailsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getCoachMoodDetailsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getCoachMoodDetailsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getCoachMoodDetailsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getCoachMoodDetailsFailure(payload));
    }
  }
}

export function* getCoachMoodStats({ payload }) {
  const { userId, days } = payload;

  try {
    yield put(Creators.getCoachMoodStatsStart());

    const fullURL = yield `${apiEndPoints.moodStats}`;
    const response = yield WebService.get(fullURL, { params: { userId, days } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(
        Creators.getCoachMoodStatsSuccess({
          moodStats: data[0] || [],
          stats: data[1] ? data[1].moodDict : { level1: 0, level2: 0, level3: 0, level4: 0, level5: 0, level6: 0 }
        })
      );
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getCoachMoodStatsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getCoachMoodStatsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getCoachMoodStatsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getCoachMoodStatsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getCoachMoodStatsFailure(payload));
    }
  }
}

export function* getCoachMeditationDetailsSaga({ payload }) {
  const { meditationName, _id } = payload;
  let endPoint = '';
  if (meditationName === 'guidedMeditation') {
    endPoint = 'GuidedMeditationDetails';
  } else if (meditationName === 'basicMeditation') {
    endPoint = 'BasicMeditationDetails';
  } else if (meditationName === 'expertMeditation') {
    endPoint = 'ExpertMeditationDetails';
  }
  try {
    yield put(Creators.getCoachMeditationDetailsStart());
    const fullURL = yield `${rootConfig.apiRoot}/${endPoint}/`;

    const response = yield WebService.get(fullURL, { params: { _id } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(Creators.getCoachMeditationDetailsSuccess({ meditationDetails: data }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getCoachMeditationDetailsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getCoachMeditationDetailsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getCoachMeditationDetailsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getCoachMeditationDetailsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getCoachMeditationDetailsFailure(payload));
    }
  }
}

export function* getCoachMeditationStatsSaga({ payload }) {
  const { userId, page, days, count, dataType } = payload;

  try {
    yield put(Creators.getCoachMeditationStatsStart());
    const fullURL = yield `${apiEndPoints.listMeditation}`;

    const response = yield WebService.get(fullURL, { params: { userId, page, days, count, dataType } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(
        Creators.getCoachMeditationStatsSuccess({
          meditationStats: data[0] || [],
          avgMeditationDuration: data[1] ? data[1].avgDuration : '00:00:00'
        })
      );
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getCoachMeditationStatsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getCoachMeditationStatsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getCoachMeditationStatsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getCoachMeditationStatsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getCoachMeditationStatsFailure(payload));
    }
  }
}

export function* getCoachSleepDetails({ payload }) {
  const { _id } = payload;

  try {
    yield put(Creators.getCoachSleepDetailsStart());

    const fullURL = yield `${apiEndPoints.sleepDetails}`;
    const response = yield WebService.get(fullURL, { params: { _id } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(Creators.getCoachSleepDetailsSuccess({ sleepDetails: data }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getCoachSleepDetailsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getCoachSleepDetailsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getCoachSleepDetailsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getCoachSleepDetailsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getCoachSleepDetailsFailure(payload));
    }
  }
}

export function* getCoachSleepStatsSaga({ payload }) {
  const { page, count, userId, dataType, days } = payload;

  try {
    yield put(Creators.getCoachSleepStatsStart());

    const fullURL = yield `${apiEndPoints.listSleep}`;
    const response = yield WebService.get(fullURL, { params: { page, count, userId, dataType, days } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(
        Creators.getCoachSleepStatsSuccess({
          sleepStats: data[0] || [],
          avgSleepDuration: data[1] ? data[1].avgDuration : '00:00:00'
        })
      );
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getCoachSleepStatsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getCoachSleepStatsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getCoachSleepStatsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getCoachSleepStatsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getCoachSleepStatsFailure(payload));
    }
  }
}
