import React from 'react';
import { shortenText } from '../../../services/utility';

import { Label, Select, Option, DropdownContainer, Options } from './Dropdown.styles';

class DropdownComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropDown: false,
      selectedValue: props.defaultValue
    };
  }

  handleClick = (selectedOption) => {
    const { isDropDown } = this.state;
    const { onChangeHandler } = this.props;

    onChangeHandler(selectedOption);

    this.setState({ isDropDown: !isDropDown, selectedValue: selectedOption });
  };

  handleToggle = () => {
    const { isDropDown } = this.state;
    this.setState({ isDropDown: !isDropDown });
  };

  render() {
    const {
      selectName,
      selectRef,
      label,
      optionArray,
      defaultText,
      showLabel = true,
      position = 'bottom'
    } = this.props;
    const { isDropDown, selectedValue } = this.state;
    return (
      <>
        {showLabel && <Label>{label}</Label>}

        <DropdownContainer name={selectName}>
          <Select position={position} active={isDropDown} onClick={this.handleToggle}>
            {shortenText(selectedValue?.label || selectedValue?.value || selectedValue?.name || defaultText, 15, '...')}
          </Select>
          <input defaultValue={selectedValue} style={{ display: 'none' }} name={selectName} ref={selectRef} />
          {isDropDown && (
            <Options position={position}>
              <div>
                {optionArray.map((option) => {
                  const { label: optionLabel, value, name, title, _id } = option;
                  return (
                    <Option
                      key={value || name || _id}
                      value={value}
                      role='button'
                      onClick={() => this.handleClick(option)}
                      onKeyDown={this.handleToggle}
                    >
                      {shortenText(optionLabel || name || title, 20, '...')}
                    </Option>
                  );
                })}
              </div>
            </Options>
          )}
        </DropdownContainer>
      </>
    );
  }
}

export default DropdownComponent;
