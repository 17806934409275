import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getCommentsList: ['payload'],
  getCommentsListStart: ['payload'],
  getCommentsListSuccess: ['payload'],

  addComment: ['payload'],
  addCommentStart: ['payload'],
  addCommentSuccess: ['payload'],

  setError: ['payload'],
  resetData: null
});

export { Types, Creators };
