/* eslint-disable no-confusing-arrow */
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { fontWeight, textColors, accentColor } from '../../../shared/constants';

export const SortContainer = styled.div`
  width: 100%;
  padding: 23.5px 0 14px;

  display: flex;
  align-items: center;
`;

export const SortPlayers = styled.p`
  width: 50px;
  height: 15px;
  font-size: 12px;
  opacity: 1;
  color: #8792ac;
  text-align: left;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
  }
`;

export const SortIconContainer = styled.span`
  margin: 0 8px;
  & > svg > g > path:first-child {
    fill: ${(props) => (props.sortType === 'desc' ? accentColor.primary : '#8792ac')};
  }

  & > svg > g > path:last-child {
    fill: ${(props) => (props.sortType === 'asc' ? accentColor.primary : '#8792ac')};
  }
`;

export const Container = styled.div`
  height: 100%;
  padding-right: 0.5rem;
  overflow-y: auto;
`;

export const MainContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const SearchContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const Card = styled.div`
  width: 100%;
  height: 80px;
  background: #261d3b;
  border-radius: 6px;
  // margin: 20px 0;
  margin-top: 0px;
  padding: 15px 20px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Linkto = styled(Link)`
  width: calc(100% / 3 - 15px);
  margin-right: 20px;

  &:focus {
    outline: 0px;
  }
`;

export const PlayerTitle = styled.h1`
  font-size: 16px;
  font-weight: ${fontWeight.bold};
  color: ${textColors.textWhite};

  max-width: 180px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

export const PlayerType = styled.span`
  width: 38px;
  height: 15px;

  font-size: 12px;
  text-transform: capitalize;
  color: #b5b7bc;

  border: 1px solid #b5b7bc;
  border-radius: 5px;
  padding: 2px 6px;
  white-space: nowrap;
  background-color: #b5b7bc1a;
`;
