/* eslint-disable no-console */
/* eslint no-shadow: ["error", { "allow": ["payload"] }] */
import { put } from 'redux-saga/effects';
import { Creators } from './action';
import { Creators as GroupCreators } from '../../GroupView/store';

import { WebService } from '../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../services/axios/endPoints';

export function* getGroupPlayersSearchListSaga({ payload }) {
  const { searchValue, page, count } = payload;
  try {
    yield put(Creators.getGroupPlayersSearchListStart());
    const fullURL = yield `${apiEndPoints.searchPlayers}`;

    const response = yield WebService.get(fullURL, { params: { userName: searchValue, page, count } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      yield put(Creators.getGroupPlayersSearchListSuccess({ newGroupPlayers: data[0] }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getGroupPlayersSearchListFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getGroupPlayersSearchListFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getGroupPlayersSearchListFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getGroupPlayersSearchListFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getGroupPlayersSearchListFailure(payload));
    }
  }
}

// Add Group saga
export function* getAddGroupSaga({ payload }) {
  const { title, players } = payload;
  try {
    yield put(Creators.getAddGroupStart());
    const fullURL = yield `${apiEndPoints.groupAdd}`;

    // eslint-disable-next-line no-undef
    const dataToApi = {
      name: title,
      players
    };

    const response = yield WebService({
      method: 'POST',
      url: fullURL,
      data: dataToApi
    });

    if (response?.status >= 200 && response?.status < 300) {
      const { data } = response.data;
      yield put(Creators.getAddGroupSuccess({ newGroupPlayers: data[0] }));
      yield put(Creators.setAddGroupModal({ addGroupModal: false, addGroupModalMode: 'ADD' }));
      yield put(GroupCreators.setGroupPage({ page: 1 }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getAddGroupFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getAddGroupFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getAddGroupFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getAddGroupFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getAddGroupFailure(payload));
    }
  }
}

// Edit or delete Group saga
export function* editDeleteGroupSaga({ payload }) {
  const { title, players, mode, _id } = payload;
  try {
    yield put(Creators.editDeleteGroupStart());
    const fullURL = yield `${apiEndPoints.groupAdd}`;

    let dataToApi = null;
    let response = null;
    let method = 'POST';

    if (mode === 'EDIT') {
      dataToApi = {
        name: title,
        players,
        _id
      };
      method = 'PUT';
    } else {
      dataToApi = {
        _id
      };
      method = 'DELETE';
    }
    response = yield WebService({
      method,
      url: fullURL,
      data: dataToApi
    });
    // console.log(dataToApi, response);

    if (response?.status >= 200 && response?.status < 300) {
      const { data } = response.data;
      yield put(Creators.editDeleteGroupSuccess({ newGroupPlayers: data }));
      yield put(Creators.setAddGroupModal({ addGroupModal: false, addGroupModalMode: mode }));
      yield put(GroupCreators.setGroupPage({ page: 1 }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.editDeleteGroupFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.editDeleteGroupFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.editDeleteGroupFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.editDeleteGroupFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.editDeleteGroupFailure(payload));
    }
  }
}
