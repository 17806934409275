import styled from 'styled-components';
import { accentColor, textColors, backgroundColor, fontSize } from '../../constants';

export const Button = styled.button`
  height: 40px;
  width: 40px;
  font-size: 14px;
  border-radius: 10px;
  border: none;
  background-color: ${(props) => (props.isActive ? `${accentColor.primary}` : 'transparent')};
  margin: 0.5rem;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    outline: none;
  }
`;

export const Ellipsis = styled.div`
  font-size: 23px;
  letter-spacing: 10px;
  color: ${textColors.textLight};
`;

export const PrevBtn = styled.button`
  height: 40px;
  width: 40px;
  font-size: 14px;
  border-radius: 10px;
  border: none;
  margin: 0.5rem 1rem;
  background-color: ${backgroundColor.darkOpaq};

  display: ${(props) => (props.disabled ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;

  cursor: pointer;

  & > svg {
    transform: rotate(90deg);
  }
  &:focus {
    outline: none;
  }
`;

export const NxtBtn = styled.button`
  height: 40px;
  width: 40px;
  font-size: 14px;
  border-radius: 10px;
  border: none;
  background-color: ${backgroundColor.darkOpaq};
  margin: 0.5rem 1rem;

  display: ${(props) => (props.disabled ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;

  cursor: pointer;

  & > svg {
    transform: rotate(-90deg);
  }

  &:focus {
    outline: none;
  }
`;
export const P = styled.p`
  font-size: ${fontSize.mediumDefault};
  color: ${textColors.textLight};
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Options = styled.div`
  width: 100%;

  position: absolute;
  bottom: 41px;

  border-radius: 5px 5px 0 0;
  z-index: 99;
  overflow-y: auto;
`;

export const Option = styled.div`
  padding: 1.5rem;
  background-color: #271f3b;
  font-size: ${fontSize.mediumDefault};

  &:hover {
    cursor: pointer;
  }
`;
