import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  loading: false,
  error: null,
  feedList: null,
  success: false,
  groupLoading: false,
  groupList: [],
  deleteData: null,
  clickedGroupData: null,
  totalItems: 0,
  page: 1,
  searchKey: ''
};

const getClickedGroupData = (state = INITIAL_STATE, action) => {
  const { clickedGroupData } = action.payload;

  return {
    ...state,
    clickedGroupData
  };
};

const groupListStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const groupListSuccess = (state = INITIAL_STATE, action) => {
  const { groupList, totalItems } = action.payload;
  return {
    ...state,
    loading: false,
    groupList,
    totalItems
  };
};

const groupListFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

// Delete Group
const groupDeleteStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const groupDeleteSuccess = (state = INITIAL_STATE, action) => {
  const { deleteData } = action.payload;
  return {
    ...state,
    loading: false,
    deleteData
  };
};

const groupDeleteFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const searchGroupStart = (state = INITIAL_STATE) => ({ ...state, loading: false, error: null });

const searchGroupSuccess = (state = INITIAL_STATE, action) => {
  const { groupList, totalItems } = action.payload;
  return {
    ...state,
    loading: false,
    groupList,
    totalItems
  };
};

const searchGroupListSuccess = (state = INITIAL_STATE, action) => {
  const { groupList } = action.payload;
  return {
    ...state,
    loading: false,
    groupList
  };
};

const searchGroupFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const resetGroupList = (state = INITIAL_STATE) => ({ ...state, groupList: [] });

const setGroupSearchKey = (state = INITIAL_STATE, action) => {
  const { searchKey } = action.payload;
  return {
    ...state,
    searchKey,
    page: 1
  };
};

const setGroupPage = (state = INITIAL_STATE, action) => {
  const { page } = action.payload;
  return {
    ...state,
    page
  };
};

const resetGroup = () => INITIAL_STATE;

const HANDLERS = {
  // Getting group lists
  [Types.GROUP_LIST_START]: groupListStart,
  [Types.GROUP_LIST_SUCCESS]: groupListSuccess,
  [Types.GROUP_LIST_FAILURE]: groupListFailure,

  // Deleting group
  [Types.GROUP_DELETE_START]: groupDeleteStart,
  [Types.GROUP_DELETE_SUCCESS]: groupDeleteSuccess,
  [Types.GROUP_DELETE_FAILURE]: groupDeleteFailure,
  [Types.GET_CLICKED_GROUP_DATA]: getClickedGroupData,

  [Types.SEARCH_GROUP_START]: searchGroupStart,
  [Types.SEARCH_GROUP_SUCCESS]: searchGroupSuccess,
  [Types.SEARCH_GROUP_FAILURE]: searchGroupFailure,
  [Types.SEARCH_GROUP_LIST_SUCCESS]: searchGroupListSuccess,

  [Types.RESET_GROUP_LIST]: resetGroupList,
  [Types.SET_GROUP_SEARCH_KEY]: setGroupSearchKey,
  [Types.SET_GROUP_PAGE]: setGroupPage,
  [Types.RESET_GROUP]: resetGroup
};

export const groupListReducer = createReducer(INITIAL_STATE, HANDLERS);
