/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import { TabsContainer } from './TabStyles';
import { TabsComponenet } from '../../../globalShared';

import MoodJournalStats from './MoodJournalStats';
import MoodTrack from './MoodTrack';

const MoodJournalTabs = (props) => {
  const { date } = props;
  const [activeTab, setActiveTab] = useState('Track');

  const handleTabChange = (label) => {
    setActiveTab(label);
  };

  return (
    <TabsContainer>
      <TabsComponenet getactiveTab={handleTabChange}>
        <div label='Track'>
          <MoodTrack date={date} />
        </div>

        <div label='Stats'>
          <MoodJournalStats date={date} />
        </div>
      </TabsComponenet>
    </TabsContainer>
  );
};

export default MoodJournalTabs;
