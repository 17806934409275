/* eslint-disable operator-linebreak */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';

import { ToastContainer, ToastItemContainer, Title, TextContainer, Message } from './toast.styles';

import { ReactComponent as SuccessIcon } from '../../shared/assets/Success_message.svg';
import { ReactComponent as WarningIcon } from '../../shared/assets/Warning_message.svg';
import { ReactComponent as ErrorIcon } from '../../shared/assets/Error_message.svg';

//
export const Toasts = () => {
  const [toasts, setToasts] = useState([]);

  useEffect(() => {
    document.addEventListener('toastDispatched', onToastReceived);

    return () => {
      // Unbinds the event listener on unMount
      document.removeEventListener('toastDispatched', onToastReceived);
    };
  }, []);

  function onToastReceived(event) {
    const toast = event.detail;

    if (toast.delay > 0) {
      toast.timer = setTimeout(() => {
        onClose(toast.id);
        if (toast.reset) {
          toast.reset();
        }
      }, toast.delay * 1000);
    }

    setToasts((prevToasts) => [toast, ...prevToasts]);
  }

  function onClose(id, timer) {
    // toasts here has same value as it had when setTimeout set, so use prevToasts to get the up-to-date value
    setToasts((prevToasts) => prevToasts.filter((t) => t.id !== id));

    /**  in case delayed toast was closed manually before timer is up,
     clear timer so that close function won't be called for an already deleted toast */

    if (timer) {
      clearTimeout(timer);
    }
  }

  if (toasts && toasts.length) {
    return (
      <ToastContainer className='toast-wrapper'>
        {toasts.map((toast) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <ToastItem key={toast.id} onClose={onClose} {...toast} />
        ))}
      </ToastContainer>
    );
  }
  return null;

  function ToastItem(props) {
    const { id, message, type, onClose, timer } = props;

    return (
      <ToastItemContainer onClick={() => onClose(id, timer)}>
        <div className={`header ${type}`}>
          {(type === 'success' ? <SuccessIcon /> : '') ||
            (type === 'warning' ? <WarningIcon /> : '') ||
            (type === 'error' ? <ErrorIcon /> : '')}
        </div>
        <TextContainer>
          <Title>{type}</Title>
          <Message>{message}</Message>
        </TextContainer>
      </ToastItemContainer>
    );
  }
};

// export default Toasts;

export default class Toast {
  static fire(message, type, delay = 2, reset) {
    const details = {
      // eslint-disable-next-line radix
      id: parseInt(Date.now()),
      message: message || '',
      type: type || 'default',
      delay: !delay ? 0 : delay,
      reset: reset || null
    };

    const event = new CustomEvent('toastDispatched', { detail: details });
    document.dispatchEvent(event);
    return details.id;
  }

  static success(message, delay, reset) {
    this.fire(message || 'operation success', 'success', delay, reset);
  }

  static error(message, delay, reset) {
    this.fire(message || 'Oops Something went wrong', 'error', delay, reset);
  }

  static info(message, delay, reset) {
    this.fire(message || 'info', 'warning', delay, reset);
  }
}

export const UplaodToast = () => (
  <ToastContainer className='toast-wrapper'>
    <div />
    <ToastItemContainer>
      <div className='header upload' />
      <TextContainer>
        <Title>uploading</Title>
        <Message>Please wait</Message>
      </TextContainer>
    </ToastItemContainer>
  </ToastContainer>
);
