/* eslint-disable no-unused-vars */
/*
 * reducer.js file for coach planner view
 * Author:
 * 1.Manikanta
 * @copyright of Panorbit Services LLP.
 */

import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  loading: false,
  error: null,
  success: false,
  plannerList: [],
  reloadList: false,
  groupList: [],
  page: 1,
  totalItems: 0,
  plannerDetails: null,
  showCreatePlanner: false,
  searchKey: ''
};

const searchCoachPlannerStart = (state = INITIAL_STATE) => ({ ...state, loading: true });

const searchCoachPlannerFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const getCoachPlannerListStart = (state = INITIAL_STATE) => ({ ...state, loading: true });

const getCoachPlannerListFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const getCoachPlannerListSuccess = (state = INITIAL_STATE, action) => {
  const { plannerList, totalItems } = action.payload;

  return {
    ...state,
    loading: false,
    reloadList: false,
    plannerList,
    totalItems
  };
};

const searchCoachPlannerSuccess = (state = INITIAL_STATE, action) => {
  const { plannerList, totalItems } = action.payload;
  return {
    ...state,
    loading: false,
    reloadList: false,
    plannerList,
    totalItems
  };
};

const resetPlannerList = (state = INITIAL_STATE) => ({ ...state, plannerList: [] });

const deleteCoachPlannerStart = (state = INITIAL_STATE) => ({ ...state, loading: true });

const deleteCoachPlannerSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  success: true,
  // page: 1,
  reloadList: true
});

const deleteCoachPlannerFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const createPlannerStart = (state = INITIAL_STATE) => ({ ...state, loading: true });

const createPlannerSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  success: true,
  // page: 1,
  reloadList: true
});

const createPlannerFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const setPlannerDetails = (state, action) => {
  const plannerDetails = action.payload;
  return {
    ...state,
    plannerDetails
  };
};

const setshowCreatePlanner = (state, action) => {
  const { show } = action.payload;
  return {
    ...state,
    showCreatePlanner: show
  };
};
const resetSuccess = (state = INITIAL_STATE) => ({
  ...state,
  success: false
});

const resetError = (state = INITIAL_STATE) => ({
  ...state,
  error: null
});

const setPlannerSearchKey = (state = INITIAL_STATE, action) => {
  const { searchKey } = action.payload;
  return {
    ...state,
    searchKey,
    page: 1
  };
};

const setPlannerPage = (state = INITIAL_STATE, action) => {
  const { page } = action.payload;
  return {
    ...state,
    page
  };
};

const resetStrengthPlanner = () => INITIAL_STATE;

// handlers
const HANDLERS = {
  [Types.SEARCH_COACH_PLANNER_START]: searchCoachPlannerStart,
  [Types.SEARCH_COACH_PLANNER_FAILURE]: searchCoachPlannerFailure,
  [Types.SEARCH_COACH_PLANNER_SUCCESS]: searchCoachPlannerSuccess,

  [Types.GET_COACH_PLANNER_LIST_START]: getCoachPlannerListStart,
  [Types.GET_COACH_PLANNER_LIST_FAILURE]: getCoachPlannerListFailure,
  [Types.GET_COACH_PLANNER_LIST_SUCCESS]: getCoachPlannerListSuccess,

  [Types.DELETE_COACH_PLANNER_START]: deleteCoachPlannerStart,
  [Types.DELETE_COACH_PLANNER_SUCCESS]: deleteCoachPlannerSuccess,
  [Types.DELETE_COACH_PLANNER_FAILURE]: deleteCoachPlannerFailure,

  [Types.CREATE_PLANNER_START]: createPlannerStart,
  [Types.CREATE_PLANNER_SUCCESS]: createPlannerSuccess,
  [Types.CREATE_PLANNER_FAILURE]: createPlannerFailure,

  [Types.RESET_PLANNER_LIST]: resetPlannerList,
  [Types.SET_PLANNER_DETAILS]: setPlannerDetails,
  [Types.SETSHOW_CREATE_PLANNER]: setshowCreatePlanner,
  [Types.RESET_SUCCESS]: resetSuccess,
  [Types.RESET_ERROR]: resetError,
  [Types.SET_PLANNER_SEARCH_KEY]: setPlannerSearchKey,
  [Types.SET_PLANNER_PAGE]: setPlannerPage,
  [Types.RESET_STRENGTH_PLANNER]: resetStrengthPlanner
};

export const coachPlannerReducer = createReducer(INITIAL_STATE, HANDLERS);
