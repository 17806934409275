import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import { Creators } from './store';
import {
  Content,
  CloseButton,
  ContentDiv,
  Header,
  ModalContainer,
  ContentRow,
  VideoPlayerContainer
} from './ExerciseStyles';

import { ReactComponent as CloseIcon } from '../../../shared/assets/Close.svg';
// import { ReactComponent as EditIcon } from '../../../shared/assets/edit.svg';
import { TooltipComponnet } from '../../../globalShared';
import { localString } from '../../../services/locallization/localStrings';

const EditExercise = (props) => {
  const { exerciseId } = props;
  const dispatch = useDispatch();
  const exerciseDetails = useSelector((state) => state.coachExercise.exerciseDetails);

  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  const handleClose = () => {
    dispatch(Creators.setExerciseDetailsId({ id: '' }));
    dispatch(Creators.resetExerciseDetails());
  };

  const handleEdit = () => {
    dispatch(Creators.setCreateExerciseModal({ showCreateExercise: true }));
    dispatch(Creators.setExerciseDetailsId({ id: null }));
    dispatch(Creators.getExerciseDetailsSuccess({ exerciseDetails }));
  };

  useEffect(() => {
    if (exerciseId) dispatch(Creators.getExerciseDetails({ exerciseId }));
  }, [exerciseId]);

  return (
    <div>
      <ModalContainer>
        <ContentDiv style={{ padding: '3rem' }}>
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
          <Header>
            <h1>{exerciseDetails?.title}</h1>
            <TooltipComponnet title={string.edit} type='edit' position='top' onClick={handleEdit} />
          </Header>

          <Content>
            <ContentRow>
              <p>{string.exerciseCategory}</p>
              <span>{exerciseDetails?.category}</span>
            </ContentRow>

            <ContentRow>
              <p>{string.cues}</p>
              <span className='word' style={{ paddingLeft: '7rem' }}>
                {exerciseDetails?.bodyPart}
              </span>
            </ContentRow>

            <ContentRow>
              <p>{string.equipment}</p>
              <span>{exerciseDetails?.equipment}</span>
            </ContentRow>
          </Content>
          <VideoPlayerContainer>
            <ReactPlayer width='100%' height='100%' url={exerciseDetails?.videoUrl} controls={true} />
          </VideoPlayerContainer>
        </ContentDiv>
      </ModalContainer>
    </div>
  );
};

export default EditExercise;
