import React from 'react';

import { DatePicker } from '../FormComponents';
import { compareDates, getReadableDayMonthYear, getToday, getYearMonthDay } from '../../../services/utility/moment';

import { FlexContainer, ValdErrMsg } from '../../../globalShared/CommonStyles';
import { Title, Label, Day, InfoText } from './RepeatComponent.styles';

// eslint-disable-next-line arrow-body-style
const index = (props) => {
  const { setEndDate, setStartDate, setRepeatDays, fromDate, toDate, repeatDays, errors = {} } = props;

  const [startError, setStartError] = React.useState(null);
  const [endError, setEndError] = React.useState(null);

  const handlefrom = (value) => {
    const today = getYearMonthDay(getToday());
    const isToday = getYearMonthDay(getToday()) === getYearMonthDay(value);
    const isNotCompletd = compareDates(value, today);
    if ((isToday || isNotCompletd) && !toDate) {
      setStartDate(value);
      setStartError(null);
    } else {
      setStartError('Date Should be From Today');
    }

    if ((toDate && isNotCompletd) || (isToday && toDate)) {
      const IsBefore = !compareDates(value, toDate);
      if (IsBefore) {
        setStartDate(value);
        setStartError(null);
      } else {
        setStartError("Date Can't be After End Date");
      }
    }
  };

  const handleEnd = (value) => {
    const today = getYearMonthDay(getToday());
    const isToday = getYearMonthDay(getToday()) === getYearMonthDay(value);
    const isNotCompletd = compareDates(value, today);

    if ((isToday || isNotCompletd) && !fromDate) {
      setEndDate(value);

      setEndError(null);
    } else {
      setEndError('Date Should be From Today');
    }

    if ((fromDate && isNotCompletd) || (isToday && fromDate)) {
      const IsAfter = !compareDates(fromDate, value);
      if (IsAfter) {
        setEndDate(value);
        setEndError(null);
      } else {
        setEndError("Date Can't be Before Start Date");
      }
    }
  };

  const handleRepeat = (value) => {
    let newDays = repeatDays;
    if (repeatDays.includes(value)) {
      newDays = repeatDays.filter((item) => item !== value);
    } else {
      newDays = [...newDays, value];
    }
    setRepeatDays(newDays);
  };

  return (
    <>
      <Title>Repeat</Title>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <DatePicker
            label='Start Date'
            value={fromDate && getReadableDayMonthYear(fromDate)}
            setValue={handlefrom}
            position='top'
          />
          {(startError || errors.fromDateErr) && (
            <ValdErrMsg style={{ marginTop: '0.5rem' }}>{startError || errors.fromDateErr}</ValdErrMsg>
          )}
        </div>
        <div>
          <DatePicker
            label='End Date'
            value={toDate && getReadableDayMonthYear(toDate)}
            showFooter={true}
            setValue={handleEnd}
            position='top'
          />
          {(endError || errors.toDateErr) && (
            <ValdErrMsg style={{ marginTop: '0.5rem' }}>{endError || errors.toDateErr}</ValdErrMsg>
          )}
        </div>
      </div>

      <FlexContainer style={{ margin: '2rem 0' }}>
        <Label style={{ marginBottom: `${repeatDays.length ? '2rem' : '0'}` }}>Repeat On</Label>
        <div>
          <FlexContainer>
            <Day onClick={() => handleRepeat('Mon')} selected={repeatDays.includes('Mon')}>
              M
            </Day>
            <Day onClick={() => handleRepeat('Tue')} selected={repeatDays.includes('Tue')}>
              T
            </Day>
            <Day onClick={() => handleRepeat('Wed')} selected={repeatDays.includes('Wed')}>
              W
            </Day>
            <Day onClick={() => handleRepeat('Thu')} selected={repeatDays.includes('Thu')}>
              T
            </Day>
            <Day onClick={() => handleRepeat('Fri')} selected={repeatDays.includes('Fri')}>
              F
            </Day>
            <Day onClick={() => handleRepeat('Sat')} selected={repeatDays.includes('Sat')}>
              S
            </Day>
            <Day onClick={() => handleRepeat('Sun')} selected={repeatDays.includes('Sun')}>
              S
            </Day>
          </FlexContainer>
          {repeatDays.length ? (
            <InfoText>
              Repeats
              <span>{repeatDays.length === 7 ? 'Every Day' : repeatDays.join(' , ')}</span>
              until
              <span>{toDate && getReadableDayMonthYear(toDate)}</span>
            </InfoText>
          ) : (
            ''
          )}
        </div>
      </FlexContainer>
      {/* repeatDaysErr */}
      <ValdErrMsg>{errors.repeatDaysErr}</ValdErrMsg>
    </>
  );
};

export default index;
