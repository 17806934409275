/* eslint-disable no-unused-vars */
/*
 * reducer.js file for coach search video view
 * Author:
 * 1.Manikanta
 * @copyright of Panorbit Services LLP.
 */

import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  loading: false,
  error: null,
  videosList: [],
  totalCount: 0
};

const getPlayerTeamVideoStart = (state = INITIAL_STATE, action) => ({ ...state, error: null });

const getPlayerTeamVideoSuccess = (state = INITIAL_STATE, action) => {
  const { videosList, totalCount } = action.payload;
  return {
    ...state,
    videosList,
    totalCount
  };
};

const getPlayerTeamVideoFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const searchReset = () => ({ ...INITIAL_STATE });

const HANDLERS = {
  [Types.GET_PLAYER_TEAM_VIDEO_START]: getPlayerTeamVideoStart,
  [Types.GET_PLAYER_TEAM_VIDEO_SUCCESS]: getPlayerTeamVideoSuccess,
  [Types.GET_PLAYER_TEAM_VIDEO_FAILURE]: getPlayerTeamVideoFailure,
  [Types.SEARCH_RESET]: searchReset
};

export const PlayerTeamVideoReducer = createReducer(INITIAL_STATE, HANDLERS);
