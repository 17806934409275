/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from './store';

import { CardContainer, CardsContainer } from './PlayerDashboardStyles';

import { MasonryLayout } from '../../../globalShared';
import Card from '../../../shared/components/ChartCardsComponent';

import NutritionAdherence from '../../Player/HomeView/NutritionAdherence';
import Hydration from '../../Player/HomeView/Hydration';
import Readiness from '../../Player/HomeView/Readiness';
import SleepQuality from '../../Player/HomeView/SleepQuality';
import StrengthConditioning from '../../Player/HomeView/StrengthConditioning';
import PlayerMoodTracker from '../../../shared/components/Charts/MoodTracker';

const options = [
  { label: 'Weekly', value: 7, dataType: 'days' },
  { label: 'Monthly', value: 30, dataType: 'days' },
  { label: '6 Months', value: 180, dataType: 'month' }
];

const options2 = [
  { label: 'Weekly', value: 'weeks' },
  { label: 'Monthly', value: 'months' }
];

const PlayerDashboardView = (props) => {
  const { playerId } = props;
  const [strength, setstrength] = useState(options2[0]);
  const [readiness, setreadiness] = useState(options2[0]);
  const [sleep, setsleep] = useState(options[1]);
  const [nutrition, setnutrition] = useState(options[1]);
  const [Hydrate, setHydration] = useState(options[1]);
  const [Mood, setMood] = useState(options[1]);

  const dispatch = useDispatch();

  const _Readiness = useSelector((state) => state.playerDashboard.readiness);
  const _Strength = useSelector((state) => state.playerDashboard.strength);
  const _SleepStats = useSelector((state) => state.playerDashboard.sleepStats);
  const nutritionStats = useSelector((state) => state.playerDashboard.nutritionStats);
  const hydrationStats = useSelector((state) => state.playerDashboard.hydrationStats);
  const moodStats = useSelector((state) => state.playerDashboard.moodStats);

  useEffect(() => {
    dispatch(Creators.getPlayerDashboardStrength({ dataType: strength.value, userId: playerId }));
  }, [strength]);

  useEffect(() => {
    dispatch(Creators.getPlayerDashboardReadiness({ userId: playerId, dataType: readiness.value }));
  }, [readiness]);

  useEffect(() => {
    dispatch(
      Creators.getPlayerDashboardSleepStats({
        page: 1,
        count: 30,
        userId: playerId,
        dataType: sleep.dataType,
        days: sleep.value
      })
    );
  }, [sleep]);

  useEffect(() => {
    dispatch(
      Creators.getPlayerDashboardNutrition({ userId: playerId, dataType: nutrition.dataType, days: nutrition.value })
    );
  }, [nutrition]);

  useEffect(() => {
    dispatch(
      Creators.getPlayerDashboardHydration({ userId: playerId, dataType: Hydrate.dataType, days: Hydrate.value })
    );
  }, [Hydrate]);

  useEffect(() => {
    //  makes an api call on changing the mood
    const moodDays = Mood.value;
    dispatch(Creators.getPlayerDashboardMoodStats({ userId: playerId, days: moodDays }));
  }, [Mood]);

  return (
    <>
      <CardsContainer>
        <MasonryLayout columns={3} gap={10}>
          <CardContainer>
            <Card
              title='Readiness'
              optionArray={options2}
              selected={readiness}
              onClick={(value) => setreadiness(value)}
            >
              <Readiness {..._Readiness} />
            </Card>
          </CardContainer>

          <CardContainer>
            <Card
              title='Strength & Conditioning'
              optionArray={options2}
              selected={strength}
              onClick={(value) => setstrength(value)}
            >
              <StrengthConditioning {..._Strength} />
            </Card>
          </CardContainer>

          <CardContainer>
            <Card title='Quality of Sleep' optionArray={options} selected={sleep} onClick={(value) => setsleep(value)}>
              <SleepQuality sleepStats={_SleepStats} days={sleep.value} type={sleep.dataType} />
            </Card>
          </CardContainer>

          <CardContainer>
            <Card
              title='Nutrition Adherence'
              optionArray={options}
              selected={nutrition}
              onClick={(value) => setnutrition(value)}
            >
              <NutritionAdherence nutrition={nutrition} nutritionStats={nutritionStats} />
            </Card>
          </CardContainer>

          <CardContainer>
            <Card title='Hydration' optionArray={options} selected={Hydrate} onClick={(value) => setHydration(value)}>
              <Hydration Hydrate={Hydrate} hydrationStats={hydrationStats} />
            </Card>
          </CardContainer>

          <CardContainer>
            <Card title='Mood Tracker' optionArray={options} selected={Mood} onClick={(value) => setMood(value)}>
              <PlayerMoodTracker {...moodStats} />
            </Card>
          </CardContainer>
        </MasonryLayout>
      </CardsContainer>
    </>
  );
};

export default PlayerDashboardView;
