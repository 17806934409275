import styled from 'styled-components';
import {
  fontSize,
  fontWeight,
  fontFamily,
  backgroundColor,
  textColors,
  borderColor
} from '../../../../../shared/constants';
import { FlexContainer } from '../../../../../globalShared/CommonStyles';

//
export const ModalContainer = styled.div`
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  justify-content: flex-end;

  background-color: ${backgroundColor.popupBg};
  z-index: 99999;
`;

export const MainContainer = styled.div`
  height: 100%;
  width: 1120px;

  padding: 2.4rem;

  background-color: ${backgroundColor.popup};
  border-radius: 6px 0px 0px 6px;

  overflow-y: auto;
`;

export const ModalBody = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 158px);
`;

export const SummaryBlock = styled.div`
  width: 60%;
  height: calc(100vh - 158px);
  overflow-y: auto;

  border-right: 1px solid ${borderColor.light};
  padding-top: 2.8rem;
  padding-right: 2.8rem;
`;

export const CommentsBlock = styled.div`
  width: 40%;
  padding-top: 2.8rem;
  padding-left: 2.8rem;
`;

export const StatusContainer = styled.div`
  width: 100%;
  min-height: 220px;
  background-color: #31323b4d;
  padding: 2rem;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const StatusHeader = styled(FlexContainer)`
  width: 100%;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const Stats = styled(FlexContainer)`
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

export const Stat = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;

  & > h1 {
    margin-top: 1.4rem;
  }

  & > span {
    font-size: ${fontSize.medium};
    color: ${textColors.textLight};
  }
`;

export const AccStat = styled.div`
  margin: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > svg {
    height: 18px;
    width: 24px;
  }

  & > h3 {
    margin-top: 1rem;
  }

  & > span {
    font-size: ${fontSize.medium};
    color: ${textColors.textLight};
  }
`;

export const H1 = styled.h1`
  font-size: ${fontSize.titleMedium};
`;

export const H2 = styled.h2`
  font-size: ${fontSize.title};
`;

export const XTitle = styled.p`
  font-size: ${fontSize.mediumDefault};
  margin: 0 1rem;
  min-width: 100px;
  max-width: 200px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

export const H3 = styled.h3`
  font-size: ${fontSize.mediumDefault};
`;

export const P = styled.p`
  font-size: ${fontSize.mediumDefault};
  font-weight: ${fontWeight.medium};

  word-break: break-all;

  & > span {
    font-size: ${fontSize.medium};
    color: ${textColors.textLight};
    padding: 0 0.5rem;
  }
`;

export const WorkoutTitle = styled(P)`
  font-size: ${fontSize.medium};
`;

export const SessionsContainer = styled.div``;

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 1rem;

  & > td,
  th {
    white-space: nowrap;
    vertical-align: middle;
    border: 0;
  }
`;

export const Th = styled.th`
  font-size: ${fontSize.smallDefault};
  color: ${textColors.textLight};
  text-align: center;
  user-select: none;
`;

export const Td = styled.td``;

export const Thumbnail = styled.img`
  width: 88px;
  height: 48px;
  margin-right: 1rem;
  border-radius: 0.6rem;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const Complete = styled.img`
  width: 40;
  height: 40px;

  border-radius: 0.6rem;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const Count = styled.div`
  background: rgb(163 169 183 / 10%);
  border-radius: 6px;
  font-size: 15px;
  text-align: center;
  padding: 12px 32px;
  width: fit-content;
  margin: 0 auto;
`;

export const DropDown = styled.div`
  width: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  &.active > svg {
    transform: rotate(-180deg);
  }
`;

export const ProgressContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProgressDetails = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: ${fontSize.medium};
`;

//
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const WorkoutStatus = styled.div`
  font-size: ${fontSize.smallDefault};
  font-weight: ${fontWeight.bold};
  color: #568836;
`;

export const HeaderTxt = styled.h1`
  font-size: ${fontSize.titleVerySmall};
  font-weight: ${fontWeight.bold};
  margin: 0 1rem;
  text-transform: capitalize;
`;

export const Title = styled.h2`
  font-size: ${fontSize.mediumDefault};
  font-weight: ${fontWeight.medium};

  padding-bottom: 4px;
  margin: 0 1rem;

  &.lite {
    color: ${textColors.textLight};
  }
`;

export const TextLite = styled.p`
  font-size: ${fontSize.smallDefault};
  font-weight: ${fontWeight.medium};
  color: #8792ac;
  margin: 0.5rem 1rem 0rem 1rem;
`;

export const WorkoutsContainer = styled.div`
  width: 68%;
`;

export const WorkoutsHeader = styled(MainContainer)`
  font-family: ${fontFamily.bebas};
  width: 100%;
  padding: 1.5rem 0 1.8rem 0;
  justify-content: space-around;

  & > div:first-child {
    padding-left: 1rem;
  }
`;

export const WorkoutsHeaderItem = styled.div`
  font-size: ${fontSize.smallDefault};
  width: 35%;
  opacity: 0.5;
  text-align: left;
  text-transform: uppercase;
`;

export const Workouts = styled.div`
  width: 100%;
`;

export const WorkoutItem = styled(MainContainer)`
  margin-bottom: 1rem;
  background: ${backgroundColor.darkOpaq};
  border-radius: 0.5rem;

  &:hover {
    cursor: pointer;
  }

  & > div:first-child {
    font-weight: ${fontWeight.bold};
    color: ${textColors.textWhite};
  }
`;

export const WorkoutField = styled.div`
  font-size: ${fontSize.mediumDefault};
  padding: 2rem 2rem;
  width: 38%;
  color: #cbd1df;
`;

export const CommentsContainer = styled.div`
  width: 30%;
  height: 500px;
  border-radius: 6px;
`;

export const AccordianCont = styled.div`
  width: 100%;
  background: transparent;
  margin: 1rem 0;
  padding: 2rem;
  border: 1px solid ${borderColor.light};
  border-radius: 5px;
`;

export const AccordianHead = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0rem 0rem 1.5rem 0rem;
  cursor: pointer;
`;

export const UserDetailsContainer = styled.div`
  padding: 1rem 0;
  display: flex;
`;
export const UserDetails = styled.div`
  font-size: ${fontSize.smallDefault};
  color: ${textColors.textLight};
  display: flex;
  flex-direction: column;
  padding: 0.2rem 0;
  & > div {
    color: ${textColors.textLight};
    font-size: ${fontSize.smallDefault};
    padding: 0 0.5rem;
  }
  & > div > span {
    font-size: ${fontSize.mediumDefault};
    padding: 0 0.3rem;
  }
`;

export const TimeSince = styled.p`
  font-size: ${fontSize.smallDefault};
  color: ${textColors.textLight};
  margin-left: auto;
  padding: 1rem 0;
`;
