/* eslint-disable no-shadow */
/* eslint-disable operator-linebreak */
/* eslint-disable max-len */

import React, { useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';

// styles
import {
  Container,
  ControllsContainer,
  PlayButton,
  Slider,
  Timer,
  Footer,
  Title,
  SubTitle,
  PlayerFooter,
  FooterTitle,
  PlaybackButton,
  CompareContainer,
  RemoveButton
} from './CompareVideoPlayer.styles';

import { ReactComponent as PlayIcon } from '../../assets/play.svg';
import { ReactComponent as PauseIcon } from '../../assets/pause.svg';
import { ReactComponent as Speed } from '../../assets/Video speed.svg';

import { localString } from '../../../services/locallization/localStrings';
import { shortenText } from '../../../services/utility';

function pad(string) {
  return `0 ${string}`.slice(-2);
}

function format(seconds) {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  let mm = date.getUTCMinutes();
  let ss = pad(date.getUTCSeconds());

  if (mm < 10) mm = `0${mm}`;
  if (ss < 10) ss = `0${ss}`;

  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`;
  }
  return `${mm} : ${ss}`;
}

const Duration = ({ seconds }) => <Timer dateTime={`P${Math.round(seconds)}S`}>{format(seconds)}</Timer>;

// componant
const CompareVideoPlayer = ({ URL, title, player, removeAction }) => {
  // refs
  const playerRef = useRef(null);

  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  // player State
  const [state, setState] = useState({
    playing: false,
    controls: false,
    muted: true,
    played: 0,
    duration: 0,
    playbackRate: 1.0,
    seeking: false
  });

  const { played, playbackRate, muted, playing, duration } = state;

  const handlePlayPause = () => {
    setState({ ...state, playing: !state.playing });
  };

  const handleProgress = (changeState) => {
    setState({ ...state, ...changeState });
  };

  const handleEnded = () => {
    setState({ ...state, playing: false });
  };

  const handleDuration = (duration) => {
    setState({ ...state, duration });
  };

  const onSeek = ({ target }) => {
    setState({ ...state, playing: false, played: parseFloat(target.value / 100) });
    playerRef.current.seekTo(target.value / 100, 'fraction');
  };

  const onSeekMouseDown = () => {
    setState({ ...state, seeking: true });
  };

  const onSeekMouseUp = ({ target }) => {
    setState({ ...state, seeking: false });
    playerRef.current.seekTo(target.value / 100, 'fraction');
  };

  const handleSetPlaybackRate = (speed) => {
    setState({ ...state, playbackRate: speed });
  };

  return (
    <Container>
      <ReactPlayer
        ref={playerRef}
        width='100%'
        height='100%'
        url={URL}
        muted={muted}
        playing={playing}
        playbackRate={playbackRate}
        onProgress={handleProgress}
        onEnded={handleEnded}
        onDuration={handleDuration}
      />

      <ControllsContainer className='controlls_container'>
        <PlayButton onClick={handlePlayPause}>{playing ? <PauseIcon /> : <PlayIcon />}</PlayButton>

        <Slider
          type='range'
          min='1'
          max='100'
          step='any'
          value={played * 100}
          onChange={onSeek}
          onMouseUp={onSeekMouseUp}
          onMouseDown={onSeekMouseDown}
        />

        <Duration seconds={duration * played} />
      </ControllsContainer>
      <PlayerFooter>
        <div>
          <FooterTitle>
            <Speed />
            {string.videoSpeed}
          </FooterTitle>
          <div>
            <PlaybackButton isSelected={playbackRate === 0.5} onClick={() => handleSetPlaybackRate(0.5)}>
              0.5x
            </PlaybackButton>
            <PlaybackButton isSelected={playbackRate === 0.75} onClick={() => handleSetPlaybackRate(0.75)}>
              0.75x
            </PlaybackButton>
            <PlaybackButton isSelected={playbackRate === 1} onClick={() => handleSetPlaybackRate(1)}>
              1x
            </PlaybackButton>
            <PlaybackButton isSelected={playbackRate === 1.25} onClick={() => handleSetPlaybackRate(1.25)}>
              1.25x
            </PlaybackButton>
            <PlaybackButton isSelected={playbackRate === 1.5} onClick={() => handleSetPlaybackRate(1.5)}>
              1.5x
            </PlaybackButton>
          </div>
        </div>
        <CompareContainer>
          <RemoveButton onClick={removeAction}>{string.removeFromCompare}</RemoveButton>
        </CompareContainer>
      </PlayerFooter>

      <Footer>
        <div>
          <Title className='word'>{title}</Title>
          <SubTitle>{`${string.by} ${shortenText(player, 30, '...')}`}</SubTitle>
        </div>
      </Footer>
    </Container>
  );
};

export default CompareVideoPlayer;
