import styled from 'styled-components';

import { fontSize, backgroundColor, textColors } from '../../constants';

export const Container = styled.div`
  position: relative;
`;

export const DropContainer = styled.div`
  width: 100%;
  background-color: ${backgroundColor.dark};
  border-radius: 0.6rem;
  padding: 1rem;

  position: absolute;
  top: 0;
  left: 110%;
`;

export const DropItem = styled.div`
  font-size: ${fontSize.mediumDefault};
  color: ${textColors.textWhite};
  padding: 0.8rem 0;
  cursor: pointer;
  text-transform: capitalize;

  display: flex;
  justify-content: space-between;
  align-items: center;

  & > svg {
    transform: rotate(-90deg);
  }
`;
