import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import AddMoreComponent from '../AddMoreButtonComponent';
import { FlexContainer } from '../../../globalShared/CommonStyles';
import { FormInput } from '../FormComponents';
import { localString } from '../../../services/locallization/localStrings';

const Column = styled.div`
  width: ${(props) => props.wdth};
`;

const AddFood = ({ onAdd }) => {
  const [descr, setdescr] = useState('');
  const [remarks, setremark] = useState('');

  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  const handleIntakeChange = (value) => {
    setdescr(value);
  };

  const handleRemarksChange = (value) => {
    setremark(value);
  };

  const handleOnadd = () => {
    if (descr.length) {
      onAdd({ descr, remarks });
    }
    setdescr('');
    setremark('');
  };

  return (
    <FlexContainer style={{ width: '100%', justifyContent: 'space-between' }}>
      <Column wdth='558px'>
        <FormInput
          label='Intake'
          styles={{ marginBottom: '0rem', paddingRight: '1rem' }}
          placeholder='Type the Intakes...'
          value={descr}
          setValue={(value) => handleIntakeChange(value)}
        />
      </Column>
      <Column wdth='365px'>
        <FormInput
          label='Remarks'
          styles={{ marginBottom: '0rem', paddingRight: '1rem' }}
          placeholder='Input Remarks Here...'
          value={remarks}
          setValue={(value) => handleRemarksChange(value)}
        />
      </Column>
      <Column wdth='100px' style={{ marginTop: '10px' }}>
        <AddMoreComponent onClick={handleOnadd}>{`+ ${string.addFood}`}</AddMoreComponent>
      </Column>
    </FlexContainer>
  );
};

export default AddFood;
