import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  groupList: ['payload'],
  groupListStart: ['payload'],
  groupListSuccess: ['payload'],
  groupListFailure: ['payload'],

  // Delete Group
  groupDeleteStart: ['payload'],
  groupDeleteSuccess: ['payload'],
  groupDeleteFailure: ['payload'],

  // Clicked Group
  getClickedGroupData: ['payload'],

  // search group
  searchGroup: ['payload'],
  searchGroupStart: ['payload'],
  searchGroupSuccess: ['payload'],
  searchGroupFailure: ['payload'],
  searchGroupList: ['payload'],
  searchGroupListSuccess: ['payload'],

  resetGroupList: null,
  setGroupSearchKey: ['payload'],
  setGroupPage: ['payload'],
  resetGroup: ['payload']
});

export { Types, Creators };
