import styled from 'styled-components';
import { accentColor, borderColor, fontSize, textColors } from '../../constants';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  border-radius: 1rem;

  & > div {
    border-radius: 1rem;

    & > video {
      border-radius: 1rem;
    }
  }
`;

export const ControllsContainer = styled.div`
  height: 50px;
  padding: 0 1.5rem;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  position: absolute;
  bottom: 0;
  width: 100%;
`;

export const PlayButton = styled.div`
  width: 15px;
  margin: 0 1rem;

  cursor: pointer;
  transition: 0.3s;
  & > svg {
    height: 15px;
  }
`;

export const Slider = styled.input`
  height: 4px;
  width: 100%;

  outline: none;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  cursor: pointer;
`;

export const Timer = styled.time`
  font-size: 12px;
  margin: 0 1rem;
  min-width: 40px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0.5rem;
`;

export const Title = styled.p`
  font-size: ${fontSize.mediumDefault};
  margin-bottom: 0.5rem;
`;

export const SubTitle = styled.p`
  font-size: ${fontSize.mediumDefault};
  color: ${textColors.textLight};
`;

export const Button = styled.div`
  color: ${textColors.error};
  font-size: 14px;
  cursor: pointer;
`;

export const PlayerFooter = styled.div`
  padding: 1rem;
  border: 0.5px solid #a3a9b733;
  border-radius: 0 0 10px 10px;
  border-top: none;
  display: flex;
  justify-content: space-between;
`;

export const FooterTitle = styled.div`
  font-size: 16px;
  margin-bottom: 1rem;
  color: #e7e9ef;

  display: flex;
  align-items: center;
  & > svg {
    margin: 1rem;
  }
`;

export const PlaybackButton = styled.button`
  padding: 1rem;
  margin: 0.3rem;

  min-width: 60px;
  font-size: 14px;

  background: ${(props) => (props.isSelected ? `${accentColor.primary}` : '#4e436926')};
  color: ${(props) => (props.isSelected ? '#121A19' : `${textColors.textWhite}`)};

  border: none;
  border-radius: 6px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const CompareContainer = styled.div`
  padding: 0 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  /* width: 50%; */
  border-left: 0.5px solid ${borderColor.light};
`;

export const RemoveButton = styled.button`
  cursor: pointer;
  color: ${textColors.error};
  background: none;
  border: none;
  font-size: 16px;

  &:focus {
    outline: none;
  }

  & > svg {
    margin: 0 1rem;
  }

  & > svg > g > line {
    stroke: ${textColors.error};
  }
`;
