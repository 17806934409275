/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import { TabsContainer } from './TabStyles';
import { TabsComponenet } from '../../../globalShared';

import MeditationStats from './MeditationStats';
import MeditationTrack from './MeditationTrack';

const MeditationTabs = (props) => {
  const { date } = props;
  const [activeTab, setActiveTab] = useState('Track');

  const handleTabChange = (label) => {
    setActiveTab(label);
  };

  return (
    <TabsContainer>
      <TabsComponenet getactiveTab={handleTabChange}>
        <div label='Track'>
          <MeditationTrack date={date} />
        </div>

        <div label='Stats'>
          <MeditationStats date={date} />
        </div>
      </TabsComponenet>
    </TabsContainer>
  );
};

export default MeditationTabs;
