/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable no-shadow */
import { put } from 'redux-saga/effects';
import { Creators } from './action';

import { WebService } from '../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../services/axios/endPoints';

export function* getPlayerMoodListSaga({ payload }) {
  const { date } = payload;

  try {
    yield put(Creators.getPlayerMoodListStart());

    const fullURL = yield `${apiEndPoints.mindSet}`;
    const response = yield WebService.get(fullURL, { params: { date, count: 100, page: 1 } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(Creators.getPlayerMoodListSuccess({ moodList: data[0] || data }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getPlayerMoodListFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getPlayerMoodListFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getPlayerMoodListFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getPlayerMoodListFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getPlayerMoodListFailure(payload));
    }
  }
}

export function* setPlayerMoodSaga({ payload }) {
  const { mood, thought } = payload;
  try {
    yield put(Creators.setPlayerMoodStart());
    const fullURL = yield `${apiEndPoints.addMindSet}`;

    const dataToApi = new FormData();
    dataToApi.append('name', mood);
    dataToApi.append('thought', thought);

    const response = yield WebService({
      method: 'POST',
      url: fullURL,
      data: dataToApi
    });

    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.setPlayerMoodSuccess());
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.setPlayerMoodFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.setPlayerMoodFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.setPlayerMoodFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.setPlayerMoodFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.setPlayerMoodFailure(payload));
    }
  }
}

export function* deletePlayerMoodSaga({ payload }) {
  const { _id } = payload;

  try {
    yield put(Creators.deletePlayerMoodStart());

    const dataToApi = new FormData();
    dataToApi.append('_id', _id);

    const fullURL = yield `${apiEndPoints.deleteMindset}`;
    const response = yield WebService({
      method: 'DELETE',
      url: fullURL,
      data: dataToApi
    });

    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.deletePlayerMoodSuccess());
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.deletePlayerMoodFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.deletePlayerMoodFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.deletePlayerMoodFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.deletePlayerMoodFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.deletePlayerMoodFailure(payload));
    }
  }
}

export function* getPlayerMoodCommentsSaga({ payload }) {
  const { _id, page, count } = payload;

  try {
    yield put(Creators.getPlayerMoodCommentsStart());
    const fullURL = yield `${apiEndPoints.commentList}`;

    const response = yield WebService.get(fullURL, { params: { _id, page, count } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      const payload = {
        commentsList: data[0],
        maxPageNo: data[1]
      };

      yield put(Creators.getPlayerMoodCommentsSuccess(payload));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getPlayerMoodCommentsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getPlayerMoodCommentsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getPlayerMoodCommentsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getPlayerMoodCommentsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getPlayerMoodCommentsFailure(payload));
    }
  }
}
