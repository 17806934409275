/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable indent */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-indent */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from './store';

// styles
import { EmptyContentText, TableStyles, UserActionsContainer } from '../../../globalShared/CommonStyles';
// icons

import { ArrayString } from './Workouts.styles';
import { TooltipComponnet, PaginationComponent } from '../../../globalShared';
import Toast, { Toasts } from '../../../services/toast';
import { shortenText } from '../../../services/utility';

const { Table, Tr, Th, Td } = TableStyles;
//
const Workoutsview = () => {
  // const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);

  const dispatch = useDispatch();
  // store to view

  const workoutsList = useSelector((state) => state.coachWorkout.workoutsList);
  const reloadList = useSelector((state) => state.coachWorkout.reloadList);
  const { success, loading, page, searchKey } = useSelector((state) => state.coachWorkout);
  const error = useSelector((state) => state.coachWorkout.error);
  const totalItems = useSelector((state) => state.coachWorkout.totalItems);

  //
  const handleDelete = (id) => {
    dispatch(Creators.deleteCoachWorkout({ id }));
  };

  const handleEdit = (workout) => {
    dispatch(Creators.setWorkout({ workout }));
    dispatch(Creators.setShowmodal({ show: true }));
  };

  const handleClick = (workout) => {
    if (workout) {
      dispatch(Creators.setWorkout({ workout }));
      dispatch(Creators.setShowDetail({ show: true }));
    }
  };

  const handlePage = (page) => {
    dispatch(Creators.setWorkoutPage({ page }));
  };

  const fetchList = () => dispatch(Creators.getCoachWorkoutList({ page, count }));
  const fetchSearch = () => dispatch(Creators.searchWorkout({ count, page, searchKey }));

  // hooks
  useEffect(() => {
    if (searchKey) {
      fetchSearch();
    } else {
      fetchList();
    }
  }, [page, count, searchKey]);

  useEffect(() => {
    if (reloadList) {
      searchKey ? fetchSearch() : page === 1 ? fetchList() : handlePage(1);
    }
  }, [reloadList]);

  useEffect(() => {
    if (success) {
      Toast.success('success', 1, () => dispatch(Creators.resetSuccess()));
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      Toast.error(error, 2, () => dispatch(Creators.resetError()));
    }
  }, [error]);

  useEffect(
    () => () => {
      dispatch(Creators.resetWorkout());
    },
    []
  );

  return (
    <>
      <Toasts />
      {workoutsList.length ? (
        <>
          <div style={{ height: 'calc(100vh - 211px)', overflowY: 'auto', paddingRight: '0.8rem' }}>
            <Table>
              <thead>
                <tr>
                  <Th style={{ paddingLeft: '2rem' }}>Title</Th>
                  <Th>exercises</Th>
                  <Th>assigned planner</Th>
                  <Th>created by</Th>
                  <Th style={{ textAlign: 'center' }}>Actions</Th>
                </tr>
              </thead>
              <tbody>
                {workoutsList.map((workout) => (
                  <Tr style={{ height: '80px' }} key={workout._id}>
                    <Td style={{ cursor: 'pointer' }} onClick={() => handleClick(workout)}>
                      <ArrayString width='150px'>{workout.title}</ArrayString>
                    </Td>
                    <Td style={{ cursor: 'pointer' }} onClick={() => handleClick(workout)}>
                      {workout.exerciseCount}
                    </Td>
                    <Td style={{ cursor: 'pointer' }} onClick={() => handleClick(workout)}>
                      <ArrayString width='180px'>{workout.plannerList.join(',')}</ArrayString>
                    </Td>
                    <Td style={{ cursor: 'pointer' }} onClick={() => handleClick(workout)}>
                      {shortenText(workout.createdBy, 30, '...')}
                    </Td>
                    <Td>
                      <UserActionsContainer>
                        <TooltipComponnet title='Edit' type='edit' onClick={() => handleEdit(workout)} />
                        <TooltipComponnet title='Delete' type='delete' onClick={() => handleDelete(workout._id)} />
                      </UserActionsContainer>
                    </Td>
                  </Tr>
                ))}
              </tbody>
            </Table>
          </div>
          <PaginationComponent
            onChange={(value) => {
              handlePage(value.currentPage);
              setCount(value.count);
            }}
            totalItems={totalItems}
            title='Workouts'
            pageNo={page}
          />
        </>
      ) : (
        !loading && <EmptyContentText style={{ height: 'calc(100vh - 162px)' }}>No Data Available</EmptyContentText>
      )}
    </>
  );
};

export default Workoutsview;
