/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable no-shadow */
import { put, select } from 'redux-saga/effects';
import { Creators } from './action';

import { WebService } from '../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../services/axios/endPoints';

// const count = 50;

export function* getCoachMealFeedList({ payload }) {
  const { page, count, playerId, fetchTime } = payload;
  try {
    yield put(Creators.getCoachMealFeedListStart());
    const fullURL = yield `${apiEndPoints.coachNutritionRecent}`;

    const response = yield WebService.get(fullURL, { params: { page, count, playerId, fetchTime } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      const { mealFeedList } = yield select((state) => state.coachMealRecent);

      if (mealFeedList.length && page !== 1) {
        yield put(
          Creators.getCoachMealFeedListSuccess({
            mealFeedList: [...mealFeedList, ...(data[0] || [])],
            fetchTime: data[1].fetchTime,
            isNext: data[2].isNext
          })
        );
      } else {
        yield put(
          Creators.getCoachMealFeedListSuccess({
            mealFeedList: data[0] || [],
            fetchTime: data[1].fetchTime,
            isNext: data[2].isNext
          })
        );
      }
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getCoachMealFeedListFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getCoachMealFeedListFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getCoachMealFeedListFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getCoachMealFeedListFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getCoachMealFeedListFailure(payload));
    }
  }
}

export function* getCoachMealFeedCommentsSaga({ payload }) {
  const { _id, referenceId, page = '1', count } = payload;

  try {
    yield put(Creators.getCoachMealFeedCommentsStart());
    const fullURL = yield `${apiEndPoints.commentList}`;

    const response = yield WebService.get(fullURL, { params: { _id, page, count, secondaryId: referenceId } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      const payload = {
        mealCommentsList: data[0],
        maxPageNo: data[1]
      };

      yield put(Creators.getCoachMealFeedCommentsSuccess(payload));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getCoachMealFeedCommentsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getCoachMealFeedCommentsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getCoachMealFeedCommentsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getCoachMealFeedCommentsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getCoachMealFeedCommentsFailure(payload));
    }
  }
}

export function* getCoachIntakeSummaryCommentsSaga({ payload }) {
  const { _id, page = '1', count, secondaryId } = payload;

  try {
    yield put(Creators.getCoachIntakeSummaryCommentsStart());
    const fullURL = yield `${apiEndPoints.commentList}`;

    const response = yield WebService.get(fullURL, { params: { _id, page, count, secondaryId } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      const payload = {
        intakeSummaryCommentsList: data[0] || [],
        maxPageNo: data[1].page_count,
        intakeCommentCount: data[2].commentCount
      };

      yield put(Creators.getCoachIntakeSummaryCommentsSuccess(payload));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getCoachIntakeSummaryCommentsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getCoachIntakeSummaryCommentsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getCoachIntakeSummaryCommentsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getCoachIntakeSummaryCommentsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getCoachIntakeSummaryCommentsFailure(payload));
    }
  }
}

export function* addCoachIntakeSummaryCommentSaga({ payload }) {
  const { content, intakeSummaryId } = payload;

  try {
    yield put(Creators.addCoachIntakeSummaryCommentStart());

    const dataToApi = new FormData();
    dataToApi.append('referenceId', intakeSummaryId);
    dataToApi.append('content', content);

    const fullURL = yield `${apiEndPoints.addComment}`;

    const response = yield WebService({
      method: 'POST',
      url: fullURL,
      data: dataToApi
    });

    if (response.status >= 200 && response.status < 300) {
      const { message } = response.data;

      yield put(Creators.addCoachIntakeSummaryCommentSuccess({ message }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.addCoachIntakeSummaryCommentFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.addCoachIntakeSummaryCommentFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.addCoachIntakeSummaryCommentFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.addCoachIntakeSummaryCommentFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.addCoachIntakeSummaryCommentFailure(payload));
    }
  }
}

export function* getCoachMealDetailsSaga({ payload }) {
  const { mealId } = payload;
  try {
    yield put(Creators.getCoachMealDetailsStart());
    const fullURL = yield `${apiEndPoints.nutritionDetails}`;

    const response = yield WebService.get(fullURL, { params: { mealId } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      yield put(Creators.setIntakeModalData({ data: data[0] }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getCoachMealDetailsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getCoachMealDetailsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getCoachMealDetailsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getCoachMealDetailsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getCoachMealDetailsFailure(payload));
    }
  }
}
