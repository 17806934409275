import { genTimeStrings } from '../../services/utility/timeFormat';

export const foodTime = genTimeStrings();

export const appLanguage = {
  english: 'English'
};

export const mealTypes = [
  { value: 'Vegetarian', label: 'Vegetarian' },
  { value: 'Non-Vegetarian', label: 'Non-Vegetarian' },
  { value: 'Vegan', label: 'Vegan' },
  { value: 'Eggetarian', label: 'Eggetarian' }
];

export const typeOfFood = ['Vegetarian', 'Non-Vegetarian', 'Vegan', 'Eggetarian'];
