/* eslint-disable no-console */
/* eslint-disable no-shadow */
/*
 * saga.js file for nutrition plannerdetails view
 * Author:
 * 1. manikanta
 * @copyright of Panorbit Services LLP.
 */

import { put } from 'redux-saga/effects';
import { Creators } from './action';

import { WebService } from '../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../services/axios/endPoints';
import { getYearMonthDay } from '../../../../services/utility/moment';

export function* getMealPlanDetailsSaga({ payload }) {
  const { _id, fromDate, toDate } = payload;
  try {
    yield put(Creators.getMealPlanDetailsStart());
    const fullURL = yield `${apiEndPoints.playerMealPlanDetail}`;

    const response = yield WebService.get(fullURL, { params: { _id, fromDate, toDate } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(Creators.getMealPlanDetailsSuccess({ plannerDetails: data }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getMealPlanDetailsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getMealPlanDetailsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getMealPlanDetailsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getMealPlanDetailsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getMealPlanDetailsFailure(payload));
    }
  }
}

export function* addPlayerMealPlanSaga({ payload }) {
  const { playerId, planId, fromDate, toDate, repeatDays } = payload;
  console.log(repeatDays);
  try {
    yield put(Creators.addPlayerMealPlanStart());
    const fullURL = yield `${apiEndPoints.addPlayerMealPlan}`;

    //  eslint-disable-next-line no-undef
    const dataToApi = new FormData();

    dataToApi.append('planId', planId);
    dataToApi.append('playerId', playerId);
    dataToApi.append('fromDate', getYearMonthDay(fromDate));
    dataToApi.append('toDate', getYearMonthDay(toDate));
    dataToApi.append('repeatDays', [JSON.stringify(repeatDays)]);

    const response = yield WebService({
      method: 'POST',
      url: fullURL,
      data: dataToApi
    });

    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.addPlayerMealPlanSuccess());
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.addPlayerMealPlanFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.addPlayerMealPlanFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.addPlayerMealPlanFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.addPlayerMealPlanFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.addPlayerMealPlanFailure(payload));
    }
  }
}

export function* copyMealPlannerByDaySaga({ payload }) {
  const { _id, selectDate, targetDate } = payload;

  try {
    yield put(Creators.copyMealPlannerByDayStart());
    const fullURL = yield `${apiEndPoints.copyMealPlannerByDay}`;

    //  eslint-disable-next-line no-undef
    const dataToApi = new FormData();
    dataToApi.append('_id', _id);
    dataToApi.append('selectDate', selectDate);
    dataToApi.append('targetDate', targetDate);

    const response = yield WebService({
      method: 'POST',
      url: fullURL,
      data: dataToApi
    });

    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.copyMealPlannerByDaySuccess());
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.copyMealPlannerByDayFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.copyMealPlannerByDayFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.copyMealPlannerByDayFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.copyMealPlannerByDayFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.copyMealPlannerByDayFailure(payload));
    }
  }
}

export function* copyMealPlannerByWeekSaga({ payload }) {
  const { _id, selectEndDate, selectStartDate, targetStartDate, targetEndDate } = payload;
  try {
    yield put(Creators.copyMealPlannerByWeekStart());
    const fullURL = yield `${apiEndPoints.copyMealPlannerByWeek}`;

    //  eslint-disable-next-line no-undef
    const dataToApi = new FormData();
    dataToApi.append('_id', _id);
    dataToApi.append('selectStartDate', getYearMonthDay(selectStartDate));
    dataToApi.append('selectEndDate', getYearMonthDay(selectEndDate));
    dataToApi.append('targetStartDate', getYearMonthDay(targetStartDate));
    dataToApi.append('targetEndDate', getYearMonthDay(targetEndDate));

    const response = yield WebService({
      method: 'POST',
      url: fullURL,
      data: dataToApi
    });

    if (response.status >= 200 && response.status < 300) {
      const { message } = response.data;
      yield put(Creators.copyMealPlannerByWeekSuccess({ message }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.copyMealPlannerByWeekFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.copyMealPlannerByWeekFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.copyMealPlannerByWeekFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.copyMealPlannerByWeekFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.copyMealPlannerByWeekFailure(payload));
    }
  }
}

export function* clearPlayerMealPlanSaga({ payload }) {
  const { _id, plannerEndDate, plannerStartDate, dayType } = payload;
  try {
    yield put(Creators.clearPlayerMealPlanStart());
    const fullURL = yield `${apiEndPoints.clearPlayerMealPlan}`;

    //  eslint-disable-next-line no-undef
    const dataToApi = new FormData();
    dataToApi.append('_id', _id);
    dataToApi.append('plannerStartDate', plannerStartDate);

    if (!dayType) {
      dataToApi.append('plannerEndDate', plannerEndDate);
    }

    const response = yield WebService({
      method: 'DELETE',
      url: fullURL,
      data: dataToApi
    });

    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.clearPlayerMealPlanSuccess());
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.clearPlayerMealPlanFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.clearPlayerMealPlanFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.clearPlayerMealPlanFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.clearPlayerMealPlanFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.clearPlayerMealPlanFailure(payload));
    }
  }
}
