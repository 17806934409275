import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  updatePersonalDetails: ['payload'],
  updatePersonalDetailsStart: ['payload'],
  updatePersonalDetailsSuccess: ['payload'],
  updatePersonalDetailsFailure: ['payload'],

  resetPassword: ['payload'],
  resetPasswordStart: ['payload'],
  resetPasswordSuccess: ['payload'],
  resetPasswordFailure: ['payload'],

  resetSuccess: null,
  resetError: null
});

export { Types, Creators };
