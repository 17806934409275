/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

// import { useDispatch } from 'react-redux';
// import { Creators } from './store';
import {
  ModalContainer,
  ContentContainer,
  CloseButton,
  Header,
  MealTypeContainer,
  FoodType,
  Content,
  NutrientHeader,
  NutrientsWrapper,
  NutrientValue,
  MealContainer,
  MealHeader,
  MealTitle,
  MoreDetails,
  Value,
  MealDetails,
  MealDetailsHeader,
  IntakeWrapper
} from '../MealPlansView/MealPlanDetailsModalStyles';

import { ReactComponent as CloseIcon } from '../../../shared/assets/Close.svg';
import { ReactComponent as EditIcon } from '../../../shared/assets/edit.svg';
import { compareDates, getMonthDayYear, getToday } from '../../../services/utility/moment';

const MealPlanDetailsModal = (props) => {
  const { filteredMealPlan, onEdit, onClose, selectedDate } = props;
  const [enableEdit, setEnableEdit] = useState(false);
  const today = getToday();
  const isAfter = compareDates(selectedDate, today);
  const isToday = getMonthDayYear(today) === getMonthDayYear(selectedDate);

  useEffect(() => {
    setEnableEdit(isAfter || isToday);
  }, [isAfter, isToday]);

  const handleClose = () => {
    onClose();
  };

  const handleEdit = (mealPlan) => {
    onEdit(mealPlan);
    onClose();
  };

  return (
    <div>
      <ModalContainer>
        <ContentContainer>
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
          {filteredMealPlan.map((plan) => (
            <div key={plan._id}>
              <Header>
                <MealTypeContainer>
                  <h1>{plan.title}</h1>
                  <div>
                    {plan.typeOfFood !== 'None' && (
                      <>
                        <p>Meal Preference</p>
                        <FoodType>{plan.typeOfFood}</FoodType>
                      </>
                    )}
                  </div>
                </MealTypeContainer>
                {enableEdit && <EditIcon onClick={() => handleEdit(plan)} />}
              </Header>
              <Content>
                <NutrientHeader>Per day nutrients intake</NutrientHeader>
                <NutrientsWrapper>
                  <span>
                    <p>Protein(g)</p>
                    <NutrientValue>{plan.protein}</NutrientValue>
                  </span>
                  <span>
                    <p>Carbohydrates(g)</p>
                    <NutrientValue>{plan.carbohydrates}</NutrientValue>
                  </span>
                  <span>
                    <p>Fats(g)</p>
                    <NutrientValue>{plan.fats}</NutrientValue>
                  </span>
                  <span>
                    <p>Total Kcal</p>
                    <NutrientValue>{plan.totalKcal}</NutrientValue>
                  </span>
                  <span>
                    <p>Total Water Intake(L)</p>
                    <NutrientValue>{plan.totalWaterIntake}</NutrientValue>
                  </span>
                </NutrientsWrapper>

                {plan.meals.map((meal) => (
                  <MealContainer key={meal.mealId}>
                    <MealHeader>
                      <div className='meal'>
                        <p>Meal/Snack</p>
                        <MealTitle>{meal.title}</MealTitle>
                      </div>
                      <MoreDetails>
                        <span>
                          <p>Kcal</p>
                          <Value>{meal.kcal}</Value>
                        </span>
                        <span>
                          <p>Time</p>
                          <Value>{meal.time}</Value>
                        </span>
                      </MoreDetails>
                    </MealHeader>
                    <MealDetails>
                      <MealDetailsHeader>
                        <p>Intake</p>
                        <p>Remarks</p>
                      </MealDetailsHeader>

                      {meal.intake.map((detail) => (
                        <IntakeWrapper key={uuidv4()}>
                          <p className='word'>{detail.descr}</p>
                          <p className='word'>{detail.remarks}</p>
                        </IntakeWrapper>
                      ))}
                    </MealDetails>
                  </MealContainer>
                ))}
              </Content>
            </div>
          ))}
        </ContentContainer>
      </ModalContainer>
    </div>
  );
};

export default MealPlanDetailsModal;
