/* eslint-disable no-unused-vars */
/*
 * reducer.js file for comapre view
 * Author:
 * 1.Manikanta
 * @copyright of Panorbit Services LLP.
 */

import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  loading: false,
  error: null,
  videos: []
};

const addVideoToCompare = (state = INITIAL_STATE, action) => {
  const { videos } = action.payload;

  return {
    ...state,
    videos
  };
};

const HANDLERS = {
  [Types.ADD_VIDEO_TO_COMPARE]: addVideoToCompare
};

export const compareReducer = createReducer(INITIAL_STATE, HANDLERS);
