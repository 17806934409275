import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from '../auth/LoginView/store';

// components
import { AdminSidenav } from '../../globalShared';

// icons
import { ReactComponent as Logo } from '../../shared/assets/logo-header.svg';
// import { ReactComponent as Notification } from '../../shared/assets/Notification-bell.svg';
import { ReactComponent as DropDown } from '../../shared/assets/Dropdown.svg';
import { ReactComponent as User } from '../../shared/assets/profile.svg';

import { ReactComponent as Logout } from '../../shared/assets/Logout.svg';
import userImage from '../../shared/assets/user.jpg';

// styles
import {
  HeaderContainer,
  FlexContainer,
  UserContainer,
  UserIcon,
  DropDownContainer,
  DropDownItem,
  Body,
  ContentContainer
} from './admin.styles';

import AdminRoutes from '../../services/router/adminRoutes';
import useTouchOutside from '../../shared/hooks/useTouchOutside';

import ProfileModal from '../ProfileModal/ProfileModal';

/**
 *
 * component
 */

const AdminView = (props) => {
  const { match, history, userType } = props;
  const dispatch = useDispatch();
  const personalDetails = useSelector((state) => state.login.personalDetail);

  const [showProfile, setShowProfile] = useState(false);
  const [showDropdown, setshowDropdown] = useState(false);
  const [profilePicture, setProfilePicture] = useState(userImage);

  // close dropdown on click outside
  const dropDownRef = useRef();
  useTouchOutside(dropDownRef, () => setshowDropdown(false));

  useEffect(() => {
    if (personalDetails) {
      setProfilePicture(personalDetails.profilePicture);
    }
  }, [personalDetails]);

  // logout
  const handleLogOut = () => {
    dispatch(Creators.logout());
  };

  const showProfileModal = () => {
    setShowProfile(true);
    setshowDropdown(false);
  };

  const handleClose = () => {
    setShowProfile(false);
  };

  useEffect(() => {
    if (userType !== 'Admin') {
      history.push('/');
    }

    if (userType === 'Admin') {
      dispatch(Creators.getUserData());
    }
  }, []);

  return (
    <>
      <HeaderContainer>
        <Logo style={{ cursor: 'pointer' }} onClick={() => history.push('/admin/')} />

        <FlexContainer>
          {/* <div>
            <Notification />
          </div> */}

          <UserContainer onClick={() => setshowDropdown(!showDropdown)}>
            <UserIcon style={{ backgroundImage: `url( ${profilePicture || userImage})` }} />
            <DropDown />
          </UserContainer>
        </FlexContainer>

        {showDropdown ? (
          <DropDownContainer ref={dropDownRef}>
            <DropDownItem onClick={() => showProfileModal()}>
              <User />
              Profile
            </DropDownItem>

            <DropDownItem onClick={handleLogOut}>
              <Logout />
              Logout
            </DropDownItem>
          </DropDownContainer>
        ) : (
          ''
        )}
      </HeaderContainer>
      <Body>
        <AdminSidenav isExact={match.isExact} />
        <ContentContainer>
          <AdminRoutes path={match.path} />
        </ContentContainer>
      </Body>
      {showProfile ? <ProfileModal closeAdminProfileModal={handleClose} /> : ''}
    </>
  );
};

export default withRouter(AdminView);
