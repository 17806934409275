import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import App from './App';

import GlobalStyle from './shared/styles/GlobalStyle';
import { store } from './store/store';

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <GlobalStyle />
      <App />
    </Provider>
  </Router>,
  // eslint-disable-next-line no-undef
  document.getElementById('root')
);
