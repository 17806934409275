import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getPlayerMealPlanList: ['payload'],
  getPlayerMealPlanListStart: ['payload'],
  getPlayerMealPlanListSuccess: ['payload'],
  getPlayerMealPlanListFailure: ['payload'],

  setPlayerWaterUpdate: ['payload'],
  setPlayerWaterUpdateStart: ['payload'],
  setPlayerWaterUpdateSuccess: ['payload'],
  setPlayerWaterUpdateFailure: ['payload'],

  getPlayerIntakeSummaryComments: ['payload'],
  getPlayerIntakeSummaryCommentsStart: ['payload'],
  getPlayerIntakeSummaryCommentsSuccess: ['payload'],
  getPlayerIntakeSummaryCommentsFailure: ['payload'],

  getPlayerMealComments: ['payload'],
  getPlayerMealCommentsStart: ['payload'],
  getPlayerMealCommentsSuccess: ['payload'],
  getPlayerMealCommentsFailure: ['payload'],

  setPlayerMealUpdate: ['payload'],
  setPlayerMealUpdateStart: ['payload'],
  setPlayerMealUpdateSuccess: ['payload'],
  setPlayerMealUpdateFailure: ['payload'],

  setPlayerMealUndoneUpdate: ['payload'],
  setPlayerMealUndoneUpdateStart: ['payload'],
  setPlayerMealUndoneUpdateSuccess: ['payload'],
  setPlayerMealUndoneUpdateFailure: ['payload'],

  setPage: ['payload']
});

export { Types, Creators };
