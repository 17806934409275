/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from './MoodStore';
import { Creators as CommonCreators } from '../../store';

import {
  ButtonComponent,
  CircularSliderComponent,
  CommentInputComponent,
  TooltipComponnet
} from '../../../globalShared';
import MoodIcon from '../../../shared/components/MoodIcon';
import { TextArea } from '../../../shared/components/FormComponents';
import CommentsFooter from '../../../shared/components/CommetsFooter';

import {
  Header,
  MoodContainer,
  ButtonContainer,
  TrackContainer,
  Circular,
  GradientBg,
  WhiteBg,
  EmojiContainer,
  SliderRange,
  SuccessContainer,
  H1,
  MainContainer,
  MoodContainer1,
  MoodBody,
  MoodHeader,
  MoodTime,
  MoodWrapper,
  MoodTitle,
  CommentsContainer,
  CommentInputContainer,
  ListItem,
  MoodText
} from './MoodTrackStyles';

import Success from '../../../shared/assets/success.svg';
import Slider from '../../../shared/assets/mood_slider.svg';

import { timeSince } from '../../../services/utility';
import { getToday, getYearMonthDay, compareDates } from '../../../services/utility/moment';
import { EmptyContentText } from '../../../globalShared/CommonStyles';
import { FeelingsMap, MoodLevels, moodStrings } from '../../../services/utility/maps';

const MoodTrack = ({ date }) => {
  const success = useSelector((state) => state.playerMood.success);
  const moodList = useSelector((state) => state.playerMood.moodList);
  const reload = useSelector((state) => state.playerMood.reload);
  const commentsList = useSelector((state) => state.playerMood.commentsList);
  const reloadMoodComments = useSelector((state) => state.main.reloadComments);

  const [feelingText, setfeelingText] = useState('');
  const [feeling, setfeeling] = useState(3);
  const [isToday, setIsToday] = useState(true);
  const [isAfterDate, setIsAfterDate] = useState(false);
  const [recentCommentID, setRecentCommentID] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (success || date || reload) {
      dispatch(Creators.getPlayerMoodList({ date }));
    }
  }, [success, date, reload]);

  useEffect(() => {
    const today = getYearMonthDay(getToday());
    const isAfter = compareDates(date, today);
    setIsToday(date === today);
    setIsAfterDate(date === today || isAfter);
  }, [date]);

  const handleSave = () => {
    dispatch(Creators.setPlayerMood({ mood: MoodLevels[feeling], thought: feelingText || '' }));
    setfeelingText('');
  };

  const handleDeleteMood = (_id) => {
    dispatch(Creators.deletePlayerMood({ _id }));
  };

  const onShowComments = (_id) => {
    dispatch(Creators.getPlayerMoodComments({ _id, count: 100, page: 1 }));
    setRecentCommentID(_id);
  };

  const handleAddComment = (content, id) => {
    dispatch(CommonCreators.addComment({ content, id }));
    onShowComments(id);
  };

  useEffect(() => {
    if (reloadMoodComments && !!recentCommentID) {
      onShowComments(recentCommentID);
    }
  }, [reloadMoodComments]);

  const trackView = () => (
    <>
      <MoodContainer>
        <TrackContainer>
          <EmojiContainer top='120' left='30' onClick={() => setfeeling(0)}>
            <MoodIcon type='level1' />
          </EmojiContainer>

          <EmojiContainer top='51' left='60' onClick={() => setfeeling(1)}>
            <MoodIcon type='level2' />
          </EmojiContainer>

          <EmojiContainer top='15' left='116' onClick={() => setfeeling(2)}>
            <MoodIcon type='level3' />
          </EmojiContainer>

          <EmojiContainer top='14' left='183' onClick={() => setfeeling(3)}>
            <MoodIcon type='level4' />
          </EmojiContainer>

          <EmojiContainer top='56' left='250' onClick={() => setfeeling(4)}>
            <MoodIcon type='level5' />
          </EmojiContainer>

          <EmojiContainer top='120' left='276' onClick={() => setfeeling(5)}>
            <MoodIcon type='level6' />
          </EmojiContainer>

          <GradientBg />
          <WhiteBg />
          <SliderRange>
            <img src={Slider} alt='slider' />
          </SliderRange>
          <MoodText mood={feeling}>{FeelingsMap[feeling]}</MoodText>
          <Circular>
            <CircularSliderComponent
              min='0'
              max='5'
              step='1'
              startAngle='0'
              endAngle='180'
              update={(e) => setfeeling(e.value)}
              value={feeling}
              borderWidth='0'
              handleSize='+8'
              handleShape='dot'
              radius='92'
            />
          </Circular>
        </TrackContainer>
        <div style={{ width: 'calc(100% - 325px)' }}>
          <div style={{ padding: '0 1rem' }}>
            <TextArea
              placeholder='Write Your Thoughts Here....'
              value={feelingText}
              setValue={(value) => setfeelingText(value)}
            />
          </div>
          <ButtonContainer>
            <ButtonComponent buttonText='Save' autoWidth isButtonAction={isToday} buttonClick={handleSave} />
          </ButtonContainer>
        </div>
      </MoodContainer>
    </>
  );

  const successView = () => {
    const lenght = moodList.length;
    return lenght ? (
      <>
        <SuccessContainer>
          <img src={Success} alt='success' />
          <p>Saved</p>
        </SuccessContainer>
        <H1>Today&apos;s Check</H1>

        {moodList.map((mood) => (
          <div style={{ padding: '1rem 0' }} key={mood.id}>
            <ListItem>
              <MoodContainer1>
                <MoodHeader>
                  <MoodWrapper>
                    <MoodIcon type={mood.name} />
                    <MoodTitle>{moodStrings[mood.name]}</MoodTitle>
                  </MoodWrapper>
                  <div>
                    <MoodTime>{timeSince(mood.time)}</MoodTime>
                  </div>
                </MoodHeader>

                {mood.thought && (
                  <MoodBody>
                    <p>{mood.thought}</p>
                  </MoodBody>
                )}
                <CommentsContainer>
                  <CommentsFooter
                    commentsList={commentsList}
                    _id={mood.id}
                    commentCount={mood.commentCount}
                    onClick={onShowComments}
                  />
                  <CommentInputContainer>
                    <CommentInputComponent
                      placeholderText='Enter Your Comment Here…'
                      id={mood.id}
                      onSubmit={(value) => handleAddComment(value, mood.id)}
                    />
                  </CommentInputContainer>
                </CommentsContainer>
              </MoodContainer1>
              <TooltipComponnet title='Delete' type='close' position='left' onClick={() => handleDeleteMood(mood.id)} />
            </ListItem>
          </div>
        ))}
      </>
    ) : (
      <EmptyContentText>No Mood&apos;s Found</EmptyContentText>
    );
  };
  return (
    <MainContainer>
      <Header>What are you Thinking?</Header>
      <>
        {!success && isAfterDate && trackView()}
        {success && isAfterDate && moodList.length > 0 && successView()}
        {success && moodList.length === 0 && trackView()}
        {!isAfterDate && successView()}
      </>
    </MainContainer>
  );
};

export default MoodTrack;
