import styled, { css } from 'styled-components';
import { accentColor, fontSize, textColors } from '../../constants';

export const Title = styled.h1`
  font-size: ${fontSize.mediumDefault};
  margin-bottom: 1rem;
`;

export const Label = styled.p`
  font-size: 14px;
  padding-right: 1rem;
  color: ${textColors.textLight};
`;

export const Day = styled.div`
  height: 28px;
  width: 28px;
  background: #262135;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  ${(props) =>
    props.selected &&
    css`
      background-color: ${accentColor.primary};
      color: ${textColors.textDark};
    `};
`;

export const InfoText = styled.p`
  font-size: ${fontSize.medium};
  text-transform: capitalize;
  margin-top: 1rem;
  color: ${textColors.textLight};

  & > span {
    color: ${textColors.textWhite};
    font-size: ${fontSize.medium};
    margin: 0 0.5rem;
  }
`;
