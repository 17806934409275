import styled from 'styled-components';
import { fontSize, textColors, backgroundColor, accentColor } from '../../../constants';

export const Textarea = styled.textarea`
  height: ${(props) => (props.height ? props.height : '135px')};
  width: 100%;

  resize: none;
  padding: 1rem;
  font-size: ${fontSize.mediumDefault};

  color: ${textColors.textWhite};
  border: none;
  border-radius: 0.6rem;
  background-color: ${backgroundColor.darkOpaq};

  &:focus {
    outline: 1px solid ${accentColor.primary};
  }
`;
