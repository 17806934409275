import styled from 'styled-components';
import { borderColor, textColors } from '../../../shared/constants';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.5rem;
`;

export const H2 = styled.h2`
  font-size: 20px;
`;

export const Image = styled.div`
  height: 280px;
  width: 280px;
  border-radius: 5px;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  position: relative;
  margin-right: 5rem;

  &:after {
    content: '';
    width: 2px;
    height: 100%;
    background: ${borderColor.light};
    position: absolute;
    right: -23px;
    border-radius: 2px;
  }
`;

export const P = styled.p`
  font-size: 14px;
  color: ${(props) => (props.light ? `${textColors.textLight}` : `${textColors.textWhite}`)};
  min-width: 10rem;
`;
export const H3 = styled.p`
  font-size: 16px;
  color: ${(props) => (props.light ? `${textColors.textLight}` : `${textColors.textWhite}`)};
`;

export const DetailsCnt = styled.div`
  display: flex;
  flex-direction: column;
  width: 290px;
  position: relative;
  margin-right: 5rem;

  &:after {
    content: '';
    width: ${(props) => (props.after ? '2px' : '0px')};
    height: 100%;
    background: ${borderColor.light};
    position: absolute;
    right: -23px;
    border-radius: 2px;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;
  margin-right: 5rem;
`;

export const DetailsRow = styled.div`
  display: flex;
  margin: 1.1rem 0;

  & > p {
    margin-right: 1.5rem;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const Table = styled.table`
  border: 1px solid ${borderColor.light};
  border-radius: 5px;
  border-spacing: 0;
  width: 100%;

  & > tbody {
    & > tr > td {
      border-bottom: 1px solid ${borderColor.light};
      padding: 1rem 2rem;
    }

    & > tr:last-child > td {
      border-bottom: none;
    }

    & > tr > td:first-child {
      border-right: 1px solid ${borderColor.light};
    }
  }
`;

export const FileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const A = styled.a`
  &:focus {
    outline: 0px;
  }

  & > svg {
    transform: rotate(133deg);
  }

  & > svg > g > line {
    stroke: ${textColors.textLight};
  }
`;
