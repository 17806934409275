import styled from 'styled-components';

import { fontSize, backgroundColor, fontWeight, borderColor } from '../../../shared/constants';

export const ModalContainer = styled.div`
  position: absolute;
  z-index: 99999;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${backgroundColor.popupBg};
`;

export const Card = styled.div`
  min-height: 423px;
  min-width: 569px;
  border-radius: 10px;

  padding: 35px 38px 27px 38px;
  position: relative;

  background-color: ${backgroundColor.popup};

  z-index: 999999;
`;

export const Title = styled.div`
  font-size: calc(${fontSize.titleVerySmall} - 0.1rem);
  font-weight: ${fontWeight.bold};
`;

export const CloseButton = styled.span`
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
`;

export const SubTitle = styled.p`
  font-size: 12px;
  font-weight: ${fontWeight.medium};
  color: #8792ac;
  margin-bottom: 8px;
`;

export const CollectionContainer = styled.div`
  margin-bottom: 21px;
  height: 224px;

  overflow-y: auto;
  padding-right: 0.5rem;
`;

export const InputContainer = styled.div`
  padding: 1.5rem 0;
  display: flex;
  align-items: center;

  border-bottom: 0.5px solid ${borderColor.light};
`;

export const SelectButtonContainer = styled.div`
  margin-right: 1rem;
  width: 25px;
  cursor: pointer;
`;

export const Radio = styled.div`
  height: 25px;
  width: 25px;
  border: 2px solid #3e3457;
  border-radius: 12px;

  &:hover {
    background: #2f2843;
  }
`;

export const CollectionName = styled.p`
  font-size: 16px;
  color: #e7e9ef;
  cursor: pointer;
`;

export const FormFooter = styled.div`
  right: 0px;
  bottom: 0;

  display: flex;
  justify-content: flex-end;

  & > div > button:first-child {
    margin: 0 13px;
  }

  & > div > button:last-child {
    margin: 0;
  }
`;
