import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { accentColor, fontWeight } from '../../shared/constants';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HelpSearchWrapper = styled.div`
  width: 435px;
  padding: 1rem 0 2.5rem 0;
`;

export const Card = styled.div`
  width: 100%;
  height: 160px;
  padding: 28px 26px;
  background: #261d3c;
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  gap: 23px;
`;

export const HelpText = styled.h6`
  font-size: 22px;
  font-weight: ${fontWeight.medium};
  color: #dedede;
  text-align: left;
  line-height: 34px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const ReadMoreLink = styled(Link)`
  font-size: 16px;
  font-weight: ${fontWeight.medium};
  color: ${accentColor.primary};
`;

export const UserHelpCard = styled.div`
  width: 100%;
  padding: 28px 26px;

  display: flex;
  flex-direction: column;
`;

export const UserTypeHeader = styled.h6`
  font-size: 18px;
  font-weight: ${fontWeight.medium};
  color: #dedede;
  margin-bottom: 25px;
`;

export const HelpLink = styled(Link)`
  font-size: 16px;
  font-weight: ${fontWeight.medium};
  color: ${accentColor.primary};
  margin-bottom: 11px;
`;
