import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Creators } from './store';

// assets
import { ReactComponent as CloseIcon } from '../../../shared/assets/Close.svg';

// styles
import {
  ModalContainer,
  Card,
  Title,
  CloseButton,
  Header,
  Confirmation,
  FormFooter
} from './DeleteCollectionModalStyles';

// components
import { ButtonComponent } from '../../../globalShared';

const DeleteCollectionModal = () => {
  const dispatch = useDispatch();

  const currentCollectionId = useSelector((state) => state.playerSkills.currentCollectionId);

  const handleClose = () => {
    dispatch(Creators.setShowDeleteCollectionModal({ showDeleteCollection: false }));
  };

  const handleKeepVideos = () => {
    dispatch(Creators.deletePlayerCollection({ _id: currentCollectionId, deleteCommand: 'keepVideos' }));
    handleClose();
  };

  const handleDeleteVideos = () => {
    dispatch(Creators.deletePlayerCollection({ _id: currentCollectionId, deleteCommand: 'deleteVideos' }));
    handleClose();
  };

  return (
    <ModalContainer>
      <Card>
        <Header>
          <div>
            <Title>Delete Collection</Title>
          </div>

          <div>
            <CloseButton onClick={handleClose}>
              <CloseIcon />
            </CloseButton>
          </div>
        </Header>

        <Confirmation>Are you sure, you want to delete the collection permanently?</Confirmation>

        <FormFooter>
          <div>
            <ButtonComponent
              buttonText='Delete videos'
              isButtonAction={true}
              buttonClick={handleDeleteVideos}
              bgType='dark'
            />
            <ButtonComponent buttonText='Keep videos' isButtonAction={true} buttonClick={handleKeepVideos} />
          </div>
        </FormFooter>
      </Card>
    </ModalContainer>
  );
};

export default DeleteCollectionModal;
