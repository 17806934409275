import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Creators } from './store';

// styles
import {
  Title,
  FormContainer,
  Header,
  VideoInfoContainer,
  InfoTitle,
  InfoWrapper,
  VideoInfo,
  VideoTitle,
  VideoDuration,
  InputContainer,
  HideVideo,
  HideVideoTitle
} from './UploadVideoModalStyles';

import { ValdErrMsg } from '../../../globalShared/CommonStyles';

// components
import { FormInput, Dropdown } from '../../../shared/components/FormComponents';
import { AvatarComponent, ModalComponent, ToggleSwitch } from '../../../globalShared';
import { Timeformater } from '../../../services/utility';

const UploadVideoModal = (props) => {
  const { onCancel, fileName, url, duration, onSubmit } = props;
  const dispatch = useDispatch();

  // state values
  const collectionsList = useSelector((state) => state.playerSkills.collectionsList);

  const newCollectionList =
    collectionsList &&
    collectionsList.map((item) => ({
      _id: item._id,
      name: item.name
    }));

  const dropdownList = newCollectionList.map((item) => item.name);

  // effect hooks
  useEffect(() => {
    dispatch(Creators.getPlayerCollectionsList());
  }, []);

  const [formFields, setFormFields] = useState({
    title: '',
    collectionId: '',
    collectionName: '',
    newCollection: ''
  });

  const [videoHidden, setVideoHidden] = useState(false);
  // const [isFormFilled, setIsFormFilled] = useState(false);
  const [addCollection, setAddCollection] = useState(false);
  const [errors, setErrors] = useState({});

  function checkFormFilled() {
    let Isvalid = true;
    const errs = errors;

    if (!formFields.title) {
      Isvalid = false;
      errs.titleErr = 'Required';
    }

    if (!formFields.collectionId && !formFields.newCollection) {
      Isvalid = false;
      errs.collection = 'Required';
    }

    setErrors({ ...errs });
    return Isvalid;
  }

  const handleVideoTitle = (title) => {
    setFormFields({ ...formFields, title });
    if (title) {
      setErrors({ ...errors, titleErr: '' });
    } else {
      setErrors({ ...errors, titleErr: 'Required' });
    }
  };

  const handleCollectionName = (collectionName) => {
    const collectionId = newCollectionList.filter((item) => item.name === collectionName)[0]._id;

    if (addCollection) {
      setAddCollection(false);
      setFormFields({ ...formFields, newCollection: '', collectionId, collectionName });
    } else {
      setFormFields({ ...formFields, collectionId, collectionName });
    }

    if (collectionName) {
      setErrors({ ...errors, collection: '' });
    } else {
      setErrors({ ...errors, collection: 'Required' });
    }
  };

  const toggleHideVideo = () => {
    setVideoHidden(!videoHidden);
  };

  const handleClose = () => {
    onCancel();
  };

  const handleUpload = () => {
    if (checkFormFilled()) {
      onSubmit({ ...formFields, videoHidden, addCollection });
      handleClose();
    }
  };

  const handleAddCollection = () => {
    setAddCollection(true);
    setFormFields({ ...formFields, collectionId: '', collectionName: '' });
  };

  const handleNewCollection = (value) => {
    setFormFields({ ...formFields, newCollection: value, collectionId: '', collectionName: '' });
    if (value) {
      setErrors({ ...errors, collection: '' });
    } else {
      setErrors({ ...errors, collection: 'Required' });
    }
  };

  return (
    <ModalComponent width='630' onSubmit={handleUpload} onCancel={handleClose} button2Props={{ text: 'upload' }}>
      <FormContainer>
        <Header>
          <Title>Upload Video</Title>
        </Header>

        <VideoInfoContainer>
          <InfoTitle>Video</InfoTitle>
          <InfoWrapper>
            <AvatarComponent url={url} />
            <VideoInfo>
              <VideoTitle>{fileName || 'N/A'}</VideoTitle>
              <VideoDuration>{Timeformater(duration)}</VideoDuration>
            </VideoInfo>
          </InfoWrapper>
        </VideoInfoContainer>

        <InputContainer>
          <FormInput label='Title' placeholder='Video Title' value={formFields.title} setValue={handleVideoTitle} />
          <ValdErrMsg>{errors.titleErr}</ValdErrMsg>
        </InputContainer>

        <InputContainer>
          <Dropdown
            list={dropdownList}
            label='Collection'
            value={formFields.collectionName}
            setValue={handleCollectionName}
            maxHeight='135'
            button={true}
            buttonText='Add Collection'
            onAdd={handleAddCollection}
          />
        </InputContainer>
        {addCollection && (
          <InputContainer>
            <FormInput
              showLable={false}
              placeholder='Type Collection Name Here...'
              value={formFields.newCollection}
              setValue={handleNewCollection}
            />
          </InputContainer>
        )}
        <ValdErrMsg>{errors.collection}</ValdErrMsg>

        <HideVideo>
          <HideVideoTitle>Make Video Hidden</HideVideoTitle>
          <ToggleSwitch isChecked={videoHidden} disabled={false} onChangeHandler={toggleHideVideo} />
        </HideVideo>
      </FormContainer>
    </ModalComponent>
  );
};

export default UploadVideoModal;
