/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// infinite scroll component
import InfiniteScroll from 'react-infinite-scroll-component';

import { timeSince } from '../../../services/utility';
import { MasonryLayout, RecentCardComponent } from '../../../globalShared';

import { Creators } from './store';

// styles
import { EmptyContentText, FlexContainer } from '../../../globalShared/CommonStyles';

import CompletedIcon from '../../../shared/assets/completed.svg';
import ProgressIcon from '../../../shared/assets/work_inprogress.svg';

import { Container, MealTakenAT, MealDetails, Circle, MealTitle } from './MealRecentStyles';
import DetailsModal from './DetailsModal';
import { localString } from '../../../services/locallization/localStrings';
import { _converIntoKAndM } from '../../../services/utility/commurize';

// component
const NutritionRecentView = (props) => {
  const { playerId } = props;

  const dispatch = useDispatch();

  const [count] = useState(10);
  const [page, setPage] = useState(1);
  const [feedId, setFeedId] = useState('');

  // values from redux store
  const mealFeedData = useSelector((state) => state.coachMealRecent.mealFeedList);
  const intakeModalData = useSelector((state) => state.coachMealRecent.intakeModalData);
  const seeDetails = useSelector((state) => state.coachMealRecent.intakeSummaryId);
  const { isNext, fetchTime } = useSelector((state) => state.coachMealRecent);
  const loading = useSelector((state) => state.coachMealRecent.loading);

  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  const showIntakeSummary = (data) => {
    setFeedId(data._id);
    dispatch(Creators.setIntakeSummaryId({ id: true }));
  };

  // effect hooks
  useEffect(() => {
    const payload = { page, count: 10, playerId };
    if (page > 1 && fetchTime) {
      payload.fetchTime = fetchTime;
    }
    dispatch(Creators.getCoachMealFeedList(payload));
  }, [page]);

  const handleScroll = () => {
    setPage(page + 1);
  };

  useEffect(
    () => () => {
      dispatch(Creators.coachMealReset());
    },
    []
  );

  const renderMealFeed = () => (
    <>
      {mealFeedData.length > 0 ? (
        <>
          <InfiniteScroll
            dataLength={mealFeedData.length}
            next={handleScroll}
            scrollableTarget='nutritionScroll'
            inverse={false}
            hasMore={isNext}
          >
            <MasonryLayout>
              {mealFeedData.map((feed) => {
                const {
                  _id,
                  userName,
                  profilePicture,
                  actualKcal,
                  actualMeals,
                  actualwaterIntake,
                  expectedKcal,
                  expectedMeals,
                  expectedWaterIntake,
                  lastMeal,
                  updatedTime
                } = feed;

                return (
                  <RecentCardComponent
                    key={_id}
                    onClick={() => showIntakeSummary(feed)}
                    userName={userName}
                    profilePicture={profilePicture}
                    date={updatedTime}
                  >
                    <MealDetails>
                      <Circle src={CompletedIcon} alt='Completed' />

                      <div>
                        <MealTitle>
                          {`${string.lastCompletedMeal} :`}
                          <span>{lastMeal}</span>
                        </MealTitle>
                        <MealTakenAT>{timeSince(updatedTime)}</MealTakenAT>
                      </div>
                    </MealDetails>
                    <MealDetails>
                      <>
                        {actualMeals === expectedMeals ? (
                          <Circle src={CompletedIcon} alt='Completed' />
                        ) : (
                          <Circle src={ProgressIcon} alt='In Progress' />
                        )}
                      </>
                      <FlexContainer style={{ justifyContent: 'space-between' }}>
                        <MealTitle>
                          Meal
                          <span>{`${actualMeals}/${expectedMeals}`}</span>
                        </MealTitle>
                        <MealTitle>
                          Kcal
                          <span>{`${_converIntoKAndM(actualKcal)}/${_converIntoKAndM(expectedKcal)}`}</span>
                        </MealTitle>
                        <MealTitle>
                          Water
                          <span>{`${actualwaterIntake}L/${expectedWaterIntake}L`}</span>
                        </MealTitle>
                      </FlexContainer>
                    </MealDetails>
                  </RecentCardComponent>
                );
              })}
            </MasonryLayout>
          </InfiniteScroll>
        </>
      ) : (
        <EmptyContentText>{!loading && string.noDataAvailable}</EmptyContentText>
      )}
    </>
  );

  // dispatch
  return (
    <>
      <Container id='nutritionScroll'>{renderMealFeed()}</Container>
      {seeDetails && <DetailsModal id={feedId} {...intakeModalData} />}
    </>
  );
};
export default NutritionRecentView;
