/* eslint-disable indent */
/* eslint-disable no-confusing-arrow */
import styled, { css } from 'styled-components';
import { FlexContainer } from '../../../globalShared/CommonStyles';
import { accentColor, backgroundColor, borderColor, fontSize, textColors } from '../../../shared/constants';

export const ModalContainer = styled.div`
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  justify-content: flex-end;

  background-color: ${backgroundColor.popupBg};
  z-index: 9999999;
`;

export const MainContainer = styled.div`
  height: 100%;
  width: 548px;

  background-color: ${backgroundColor.popup};
  border-radius: 6px 0px 0px 6px;

  overflow-y: auto;
  // padding: 3.5rem 4.5rem 0 4.5rem;
`;

export const ContentContainer = styled.div`
  height: calc(100% - 70px);
  width: 100%;
  overflow-y: auto;
  padding: 3.5rem 4.5rem 0 4.5rem;
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 560px;
  top: 18px;
  z-index: 999;
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const Button = styled.div`
  margin: 0 1rem;
  border: 1px solid ${(props) => (props.active ? `${accentColor.primary}` : `${borderColor.medium}`)};
  padding: 0.5rem 1rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  background: none;
  font-size: ${fontSize.medium};
  color: ${(props) => (props.active ? `${accentColor.primary}` : `${textColors.textLight}`)};
  cursor: ${(props) => (props.active ? 'pointer' : 'default')};

  & > svg > g > path {
    ${(props) =>
      props.active &&
      css`
        stroke: #21d0b3;
      `}
  }

  & > svg {
    ${(props) =>
      props.prev &&
      css`
        transform: rotate(90deg);
      `}

    ${(props) =>
      props.next &&
      css`
        transform: rotate(-90deg);
      `}
  }
`;

export const VideoPlayerContainer = styled.div`
  height: 257px;
  width: 100%;
  margin: 1rem 0;
  background-color: black;
  border-radius: 10px;
`;
export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${borderColor.light};
  padding: 1rem 0;
  bottom: 0;
`;
export const TabelContainer = styled.div``;

export const Th = styled.th`
  font-size: ${fontSize.smallDefault};
  padding: 0.5rem 1rem;
  user-select: none;
`;

export const SuggesCont = styled.div`
  width: 100%;
  padding: 1.5rem;
  border: 1px solid ${borderColor.light};
  border-radius: 6px;

  margin-bottom: 1.5rem;
`;

export const Stats = styled(FlexContainer)`
  margin: 2rem 0;
  justify-content: space-evenly;
`;

export const TotalCont = styled.div`
  display: flex;
  background: #211e2b;
  width: fit-content;
  padding: 2rem;
  border-radius: 6px;
  justify-content: space-around;
  margin: 2rem auto;
  & > p {
    margin: 0 1rem;
    font-size: ${fontSize.mediumDefault};
  }
`;
