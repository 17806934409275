/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
// import { v4 as uuidv4 } from 'uuid';
import { createReducer } from 'reduxsauce';
import { Types } from './action';
import { mergeArray } from '../../../../services/utility';

const INITIAL_STATE = {
  loading: false,
  error: null,
  mealFeedList: [],
  reload: false,
  success: false,
  page: 1,
  maxPage: 1,
  intakeModalData: {},
  intakeSummaryId: '',
  mealCommentsLoading: false,
  mealCommentsList: [],
  reloadComments: false,
  // intake commnet details
  intakeSummaryCommentsList: [],
  intakeCommentCount: 0,
  intakeCommentmaxPage: 1,
  reloadIntakeComments: false,
  isNext: false,
  fetchTime: null
};

const getCoachMealFeedListStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getCoachMealFeedListSuccess = (state = INITIAL_STATE, action) => {
  const { mealFeedList, isNext, fetchTime } = action.payload;

  return {
    ...state,
    loading: false,
    reload: false,
    mealFeedList,
    isNext,
    fetchTime
  };
};

const getCoachMealFeedListFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const getCoachMealFeedCommentsStart = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  mealCommentsLoading: true
});

const getCoachMealFeedCommentsSuccess = (state = INITIAL_STATE, action) => {
  const newCommentsList = action.payload.mealCommentsList;

  return {
    ...state,
    mealCommentsList: newCommentsList
  };
};

const getCoachIntakeSummaryCommentsStart = (state = INITIAL_STATE) => ({
  ...state,
  error: null
});

const getCoachIntakeSummaryCommentsSuccess = (state = INITIAL_STATE, action) => {
  const { intakeSummaryCommentsList, intakeCommentCount, maxPageNo } = action.payload;
  const oldComments = state.intakeSummaryCommentsList;
  const newArray = mergeArray(oldComments, intakeSummaryCommentsList);

  return {
    ...state,
    intakeSummaryCommentsList: newArray,
    intakeCommentCount,
    intakeCommentmaxPage: maxPageNo
  };
};

const addCoachIntakeSummaryCommentStart = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  reloadIntakeComments: false
});

const addCoachIntakeSummaryCommentSuccess = (state = INITIAL_STATE) => ({
  ...state,
  reloadIntakeComments: true
});

const addCoachIntakeSummaryCommentFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    error
  };
};

const setIntakeModalData = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;

  return {
    ...state,
    intakeModalData: data
  };
};

const setIntakeSummaryId = (state = INITIAL_STATE, action) => {
  const { id } = action.payload;

  return {
    ...state,
    intakeSummaryId: id
  };
};

const setPage = (state = INITIAL_STATE, action) => {
  const { page } = action.payload;
  return {
    ...state,
    page
  };
};

const coachMealReset = () => ({ ...INITIAL_STATE });

const resetIntake = (state = INITIAL_STATE) => ({
  ...state,
  intakeSummaryCommentsList: [],
  intakeCommentCount: 0,
  intakeCommentmaxPage: 1
});

const HANDLERS = {
  [Types.GET_COACH_MEAL_FEED_LIST_START]: getCoachMealFeedListStart,
  [Types.GET_COACH_MEAL_FEED_LIST_SUCCESS]: getCoachMealFeedListSuccess,
  [Types.GET_COACH_MEAL_FEED_LIST_FAILURE]: getCoachMealFeedListFailure,

  [Types.GET_COACH_MEAL_FEED_COMMENTS_START]: getCoachMealFeedCommentsStart,
  [Types.GET_COACH_MEAL_FEED_COMMENTS_SUCCESS]: getCoachMealFeedCommentsSuccess,

  [Types.GET_COACH_INTAKE_SUMMARY_COMMENTS_START]: getCoachIntakeSummaryCommentsStart,
  [Types.GET_COACH_INTAKE_SUMMARY_COMMENTS_SUCCESS]: getCoachIntakeSummaryCommentsSuccess,

  [Types.ADD_COACH_INTAKE_SUMMARY_COMMENT_START]: addCoachIntakeSummaryCommentStart,
  [Types.ADD_COACH_INTAKE_SUMMARY_COMMENT_SUCCESS]: addCoachIntakeSummaryCommentSuccess,
  [Types.ADD_COACH_INTAKE_SUMMARY_COMMENT_FAILURE]: addCoachIntakeSummaryCommentFailure,

  [Types.SET_INTAKE_MODAL_DATA]: setIntakeModalData,
  [Types.SET_INTAKE_SUMMARY_ID]: setIntakeSummaryId,
  [Types.SET_PAGE]: setPage,
  [Types.COACH_MEAL_RESET]: coachMealReset,
  [Types.RESET_INTAKE]: resetIntake
};

export const coachMealRecentReducer = createReducer(INITIAL_STATE, HANDLERS);
