import styled from 'styled-components';

export const CardsContainer = styled.div`
  padding: 1rem 0;
`;

export const CardContainer = styled.div`
  width: 100%;
  /* height: 451px; */
  margin-bottom: 1rem;
`;

export const ChartCanvasWrapper = styled.div`
  & > canvas {
    width: 100% !important;
    height: 275px !important;
  }
`;
