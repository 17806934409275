import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// creators
import { Creators } from './store';
import { Creators as LoginCreators } from '../auth/LoginView/store';

// styles
import {
  ModalContainer,
  ContentContainer,
  CloseButton,
  DetailsWrapper,
  ProfileContainer,
  Header,
  Title,
  EditWrapper,
  ProfileContent,
  ProfilePicWrapper,
  ProfilePic,
  ProfileDetailsWrapper,
  DetailRow,
  DetailType,
  DetailValue,
  PasswordResetWrapper
} from './ProfileModalStyles';

// icons
import { ReactComponent as CloseIcon } from '../../shared/assets/Close.svg';
import { ReactComponent as EditIcon } from '../../shared/assets/edit2.svg';
import userImg from '../../shared/assets/user.jpg';

import Toast, { Toasts } from '../../services/toast';

import EditProfile from './EditProfile';
import ResetPassword from './ResetPassword';
import { FormInput } from '../../shared/components/FormComponents';

const ProfileModal = (props) => {
  const { closeAdminProfileModal } = props;
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.login.personalDetail);
  const reloadPersonalDetails = useSelector((state) => state.updatePersonalDetails.reloadPersonalDetails);
  const resetSuccess = useSelector((state) => state.updatePersonalDetails.resetSuccess);
  const success = useSelector((state) => state.updatePersonalDetails.success);
  const error = useSelector((state) => state.updatePersonalDetails.error);
  const error2 = useSelector((state) => state.playerProfile.error);

  useEffect(() => {
    if (reloadPersonalDetails) {
      dispatch(LoginCreators.getUserData());
    }
  }, [reloadPersonalDetails]);

  // toast notification
  useEffect(() => {
    if (resetSuccess) {
      Toast.success('success', 2, () => dispatch(Creators.resetSuccess()));
    }
  }, [resetSuccess]);

  useEffect(() => {
    if (success) {
      Toast.success('saved', 2, () => dispatch(Creators.resetSuccess()));
      dispatch(LoginCreators.getUserData());
    }
  }, [success]);

  useEffect(() => {
    if (error || error2) {
      Toast.success(error, 2, () => dispatch(Creators.resetError()));
    }
  }, [error, error2]);

  const { firstName, lastName, email, profilePicture, phoneNumber } = userData;

  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);

  const handleClose = () => {
    closeAdminProfileModal();
  };

  return (
    <ModalContainer>
      <ContentContainer>
        <CloseButton onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
        <DetailsWrapper>
          {!showEditProfile ? (
            <>
              <ProfileContainer>
                <Header>
                  <Title>Profile</Title>
                  <EditWrapper onClick={() => setShowEditProfile(true)}>
                    <EditIcon />
                  </EditWrapper>
                </Header>

                <ProfileContent>
                  <ProfilePicWrapper>
                    <ProfilePic style={{ backgroundImage: `url(${profilePicture || userImg})` }} />
                  </ProfilePicWrapper>
                  <ProfileDetailsWrapper>
                    <DetailRow>
                      <DetailType>Name</DetailType>
                      <DetailValue>{`${firstName} ${lastName}`}</DetailValue>
                    </DetailRow>
                    <DetailRow>
                      <DetailType>Email</DetailType>
                      <DetailValue>{email}</DetailValue>
                    </DetailRow>
                    <DetailRow>
                      <DetailType>Phone</DetailType>
                      <DetailValue>{phoneNumber}</DetailValue>
                    </DetailRow>
                  </ProfileDetailsWrapper>
                </ProfileContent>
              </ProfileContainer>
              <Toasts />
            </>
          ) : (
            <EditProfile closeEditProfile={() => setShowEditProfile(false)} />
          )}

          {!showResetPassword ? (
            <PasswordResetWrapper>
              <Header>
                <Title>Reset Password</Title>
                <EditWrapper onClick={() => setShowResetPassword(true)}>
                  <EditIcon />
                </EditWrapper>
              </Header>
              <FormInput
                placeholder=''
                label='Current Password'
                value='*********'
                setValue={() => null}
                type='password'
              />
            </PasswordResetWrapper>
          ) : (
            <ResetPassword closeResetPassword={() => setShowResetPassword(false)} />
          )}
        </DetailsWrapper>
      </ContentContainer>
    </ModalContainer>
  );
};

export default ProfileModal;
