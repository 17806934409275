/* eslint-disable no-underscore-dangle */
/* eslint-disable operator-linebreak */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Creators } from './store';
import { Creators as CompareCreators } from '../../CompareView/store';
import { Creators as CommonCreators } from '../../store';

// components
import { MainVideoPlayer, CommentsComponent, CompareButtonComponent, LoadingComponent } from '../../../globalShared';

// styles
import { Divider, FlexContainer } from '../../../globalShared/CommonStyles';
import { Header, HeaderTxt, MainContainer, PlayerContainer, CommentsContainer, Player } from './Videoplayer.style';

// icons
import { ReactComponent as BackIcon } from '../../../shared/assets/back-header.svg';
import { shortenText } from '../../../services/utility';

const PlayerView = (props) => {
  const { history } = props;
  const { videoId } = useParams();
  const dispatch = useDispatch();

  // state values
  const videoDetails = useSelector((state) => state.coachVideoPlayer.videoDetails);
  const commentsList = useSelector((state) => state.coachVideoPlayer.commentsList);
  const compareVideos = useSelector((state) => state.compare.videos);
  const reloadComments = useSelector((state) => state.main.reloadComments);
  const maxPage = useSelector((state) => state.coachVideoPlayer.maxPage);
  const commentsCount = useSelector((state) => state.coachVideoPlayer.commentsCount);
  const loading = useSelector((state) => state.coachVideoPlayer.loading);

  const [state, setState] = useState({
    URL: '',
    videoTitle: ''
  });

  const [page, setPage] = useState(1);

  const handleAddToCompare = () => {
    if (compareVideos.length < 2) {
      const videos = [...compareVideos, videoDetails];
      dispatch(CompareCreators.addVideoToCompare({ videos }));
    }
  };

  const handleRemovefromCompare = () => {
    const { _id } = videoDetails;
    const videos = compareVideos.filter((item) => item._id !== _id);
    dispatch(CompareCreators.addVideoToCompare({ videos }));
  };

  const handleSubmitComment = (content, id) => dispatch(CommonCreators.addComment({ content, id }));

  useEffect(() => {
    if (videoDetails) {
      setState({ ...state, URL: videoDetails.videoUrl, videoTitle: videoDetails.title });
    }
  }, [videoDetails]);

  // effect hooks
  useEffect(() => {
    dispatch(Creators.getVideoDetails({ id: videoId }));
    dispatch(Creators.getVideoCommentsList({ id: videoId }));
  }, []);

  useEffect(() => {
    dispatch(Creators.getVideoCommentsList({ id: videoId, page }));
  }, [page]);

  useEffect(() => {
    if (reloadComments) {
      dispatch(Creators.getVideoCommentsList({ id: videoId }));
    }
  }, [reloadComments]);

  // state destructuring
  const { URL, videoTitle } = state;

  const handleUpdateComments = () => {
    if (maxPage > page) {
      setPage(page + 1);
    }
  };

  // resets state on unmount
  useEffect(
    () => () => {
      dispatch(Creators.resetComments());
    },
    []
  );

  return (
    <>
      {loading && <LoadingComponent />}
      <Header>
        <FlexContainer style={{ padding: '10px 0', maxWidth: '80%' }}>
          <BackIcon style={{ cursor: 'pointer' }} onClick={() => history.goBack()} />
          <HeaderTxt>{shortenText(videoTitle, 95, '...')}</HeaderTxt>
        </FlexContainer>
        <CompareButtonComponent list={compareVideos} to='/coach/skills/comparevideo' />
      </Header>

      <Divider />
      <MainContainer>
        <PlayerContainer>
          <Player>
            <MainVideoPlayer
              URL={URL}
              videoDetails={videoDetails}
              addToCompareAction={handleAddToCompare}
              removeFromCompare={handleRemovefromCompare}
            />
          </Player>
        </PlayerContainer>
        <CommentsContainer>
          <CommentsComponent
            commentsList={commentsList}
            id={videoId}
            submitComment={handleSubmitComment}
            count={commentsCount}
            updateComments={handleUpdateComments}
          />
        </CommentsContainer>
      </MainContainer>
    </>
  );
};

export default PlayerView;
