/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable operator-linebreak */

/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable operator-linebreak */
/* eslint-disable max-len */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import sortArray from 'sort-array';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from './store';

import { ReactComponent as SortIcon } from '../../../shared/assets/sorting.svg';

import {
  TabsComponenet,
  SearchBoxComponent,
  MasonryLayout,
  LoadingComponent,
  PaginationComponent,
  AvatarComponent
} from '../../../globalShared';

// styles
import { FlexContainer, FeedContainer, EmptyContentText } from '../../../globalShared/CommonStyles';
import {
  SortContainer,
  SortPlayers,
  SortIconContainer,
  MainContainer,
  SearchContainer,
  Card,
  InfoWrapper,
  Linkto,
  PlayerTitle,
  PlayerType
} from './PlayersStyles';

import Toast, { Toasts } from '../../../services/toast';
// component
const CoachPlayersView = () => {
  const dispatch = useDispatch();

  // values from redux store
  const playerList = useSelector((state) => state.coachPlayers.playerList);
  const loading = useSelector((state) => state.coachPlayers.loading);
  const error = useSelector((state) => state.coachPlayers.error);
  // const maxPage = useSelector((state) => state.coachPlayers.maxPage);
  const totalCount = useSelector((state) => state.coachPlayers.totalCount);

  const [playerFilter, setPlayerFilter] = useState('All');
  const [sortType, setSortType] = useState('');
  const [filteredPlayers, setFilteredPlayers] = useState([]);

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(100);

  const onSearchChange = (userName) => {
    setPage(1);
    dispatch(Creators.SearchCoachPlayers({ page: 1, count, userName }));
    setPlayerFilter('All');
  };

  const handleSort = () => {
    const type = sortType === 'asc' ? 'desc' : 'asc';
    setSortType(type);
    const sortPlayers = sortArray(filteredPlayers, {
      by: 'fullName',
      order: type,
      computed: {
        fullName: (item) => item.fullName
      }
    });

    setFilteredPlayers(sortPlayers);
  };

  const handleTabChange = (label) => {
    setPlayerFilter(label);
    setPage(1);
  };

  useEffect(() => {
    if (error) {
      const reset = () => dispatch(Creators.resetError());
      Toast.error(error, 2, reset);
    }
  }, [error]);

  useEffect(() => {
    setFilteredPlayers(playerList);
  }, [playerList]);

  useEffect(() => {
    dispatch(Creators.getCoachPlayerList({ count, page, playerType: playerFilter }));
  }, [page, count, playerFilter]);

  const _renderPlayer = (players) => (
    <>
      {players.length > 0 ? (
        <>
          <SortContainer>
            <SortPlayers onClick={handleSort}>players &nbsp;</SortPlayers>
            <SortIconContainer sortType={sortType}>
              <SortIcon />
            </SortIconContainer>
          </SortContainer>
          <FeedContainer style={{ height: 'calc(100vh - 263px)' }} className='overFlow'>
            <MasonryLayout columns={3}>
              {players.map((player) => {
                const { _id, fullName, profilePicture, playerType } = player;

                return (
                  <Linkto to={`players/${_id}`} key={_id}>
                    <Card>
                      <InfoWrapper>
                        <FlexContainer>
                          <AvatarComponent url={profilePicture} />
                          <PlayerTitle>{`${fullName}`}</PlayerTitle>
                        </FlexContainer>
                        <div>{playerFilter === 'All' ? <PlayerType>{playerType}</PlayerType> : ''}</div>
                      </InfoWrapper>
                    </Card>
                  </Linkto>
                );
              })}
            </MasonryLayout>
          </FeedContainer>

          <PaginationComponent
            totalItems={totalCount}
            pageNo={page}
            onChange={(value) => {
              setPage(value.currentPage);
              setCount(value.count);
            }}
            initialCount={100}
          />
        </>
      ) : (
        <>{!loading && <EmptyContentText style={{ height: 'calc(100vh - 220px)' }}>No Users Found</EmptyContentText>}</>
      )}
    </>
  );

  // dispatch
  return (
    <>
      <Toasts />
      {loading && <LoadingComponent />}
      <MainContainer>
        <TabsComponenet tab={playerFilter} getactiveTab={handleTabChange}>
          <div label='All'>{_renderPlayer(filteredPlayers)}</div>
          <div label='Batsman'>{_renderPlayer(filteredPlayers)}</div>
          <div label='Bowler'>{_renderPlayer(filteredPlayers)}</div>
          <div label='All Rounder'>{_renderPlayer(filteredPlayers)}</div>
          <div label='Wicket Keeper'>{_renderPlayer(filteredPlayers)}</div>
        </TabsComponenet>

        <SearchContainer>
          <SearchBoxComponent placeHolder='Search Players' onSearchChange={onSearchChange} />
        </SearchContainer>
      </MainContainer>
    </>
  );
};
export default CoachPlayersView;
