/*
 * reducer.js file for details view
 * Author:
 * 1.Manikanta
 *
 * @copyright of Panorbit Services LLP.
 */
import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  loading: false,
  error: null,
  data: '',
  fileUplaodSuccess: null,
  success: false
};

const setUserMetaStart = (state = INITIAL_STATE) => ({ ...state, loading: true, error: null });
const setUserMetaSuccess = (state = INITIAL_STATE) => ({ ...state, loading: false, success: true, error: null });
const setUserMetaFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return { ...state, loading: false, error };
};

const fileUploadStart = (state = INITIAL_STATE) => ({ ...state, loading: true, error: null });
const fileUploadSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  fileUplaodSuccess: true,
  error: null
});

const fileUploadFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return { ...state, loading: false, error };
};

const resetSuccess = (state = INITIAL_STATE) => ({ ...state, success: false });

const HANDLERS = {
  [Types.SET_USER_META_START]: setUserMetaStart,
  [Types.SET_USER_META_SUCCESS]: setUserMetaSuccess,
  [Types.SET_USER_META_FAILURE]: setUserMetaFailure,

  [Types.FILE_UPLOAD_START]: fileUploadStart,
  [Types.FILE_UPLOAD_SUCCESS]: fileUploadSuccess,
  [Types.FILE_UPLOAD_FAILURE]: fileUploadFailure,

  [Types.RESET_SUCCESS]: resetSuccess
};

export const userMetaReducer = createReducer(INITIAL_STATE, HANDLERS);
