import styled from 'styled-components';
import { textColors, fontSize, fontWeight } from '../../../shared/constants';

export const Container = styled.div`
  padding: 6rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const FormContainer = styled.form`
  width: 500px;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
`;

export const Heading = styled.h1`
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.titleMediumSmall};
  margin: 3rem 0 0.5rem 0;
`;

export const SubHeading = styled.p`
  font-size: ${fontSize.mediumDefault};
  color: ${textColors.textLight};
  margin-bottom: 4rem;
  text-transform: capitalize;
`;
