import styled from 'styled-components';
import { accentColor, backgroundColor, borderColor, fontSize, fontWeight } from '../../../shared/constants';

export const Title = styled.div`
  font-size: calc(${fontSize.titleVerySmall} - 0.1rem);
  font-weight: ${fontWeight.bold};
  margin-bottom: 2rem;
`;

export const UserActionsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;

  & > button {
    background: none;
    border: none;
    cursor: pointer;

    &:focus {
      outline: 0px;
    }
  }
`;

export const ArrayString = styled.p`
  width: ${(props) => props.width};
  font-size: ${fontSize.mediumDefault};
  font-weight: 500;
  margin-bottom: 0.5rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

export const ModalContainer = styled.div`
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  justify-content: flex-end;

  background-color: ${backgroundColor.popupBg};
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 800px;
  top: 30px;
  z-index: 999;
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const Muted = styled.div`
  width: 100%;
  height: 45px;
  padding: 1rem;
  font-size: 1.5rem;
  color: #fff;
  border: none;
  border-radius: 0.6rem;
  background-color: rgba(78, 67, 105, 0.2);
`;

export const DividerTxt = styled.p`
  font-size: 14px;
  padding: 20px;
  text-align: center;
  position: relative;

  &:before {
    content: '';
    border: 1px dashed #a3a9b7;
    position: absolute;
    width: 43%;
    left: 0;
    top: 50%;
    opacity: 20%;
  }

  &:after {
    content: '';
    border: 1px dashed #a3a9b7;
    position: absolute;
    width: 43%;
    right: 0;
    top: 50%;
    opacity: 20%;
  }
`;

export const FormContainer = styled.div`
  /* height: 260px; */
  /* overflow-y: auto; */
  margin-top: 2rem;
  padding: 0.5rem;
`;

export const ScrollContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 0 0.5rem;
  overflow-y: auto;
`;

export const AddMoreButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;

  padding: 2rem 0;

  & > button {
    background: transparent;
    border: none;

    font-size: ${fontSize.mediumDefault};
    color: ${accentColor.primary};

    cursor: pointer;

    &:focus {
      outline: 0px;
    }
  }
`;

export const FormFooter = styled.div`
  position: absolute;
  right: 10px;
  bottom: 0;

  padding: 1.5rem 0;
  display: flex;
  justify-content: flex-end;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px;
  border-bottom: 1px solid ${borderColor.light};

  & > svg {
    cursor: pointer;
  }
`;

export const FoodType = styled.p`
  display: inline-block;

  font-size: 16px;
  margin-left: 0.7rem;
  color: #e7e9ef;
`;

export const Button = styled.div`
  position: sticky;
  bottom: 0;
  background-color: ${backgroundColor.popup};
`;
