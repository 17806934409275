/* eslint-disable no-console */
/* eslint-disable no-shadow */
/*
 * saga.js file for coach plannerdetails view
 * Author:
 * 1. manikanta
 * @copyright of Panorbit Services LLP.
 */

import { put } from 'redux-saga/effects';
import { Creators } from './action';

import { WebService } from '../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../services/axios/endPoints';
import { getYearMonthDay } from '../../../../services/utility/moment';

export function* getPlannerDetailsSaga({ payload }) {
  const { _id, fromDate, toDate } = payload;

  try {
    yield put(Creators.getPlannerDetailsStart());
    const fullURL = yield `${apiEndPoints.addSession}`;

    const response = yield WebService.get(fullURL, { params: { _id, fromDate, toDate } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(Creators.getPlannerDetailsSuccess({ plannerDetails: data }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getPlannerDetailsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getPlannerDetailsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getPlannerDetailsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getPlannerDetailsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getPlannerDetailsFailure(payload));
    }
  }
}

export function* getGroupDetailsSaga({ payload }) {
  const { _id } = payload;

  try {
    yield put(Creators.getGroupDetailsStart());
    const fullURL = yield `${apiEndPoints.groupDetails}`;

    const response = yield WebService.get(fullURL, { params: { _id } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      yield put(Creators.getGroupDetailsSuccess({ groupDetails: data }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getGroupDetailsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getGroupDetailsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getGroupDetailsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getGroupDetailsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getGroupDetailsFailure(payload));
    }
  }
}

export function* createPlannerSessionSaga({ payload }) {
  const { method, data } = payload;
  try {
    yield put(Creators.createPlannerSessionStart());
    const fullURL = yield `${apiEndPoints.addSession}`;

    //  eslint-disable-next-line no-undef
    const dataToApi = new FormData();

    dataToApi.append('sessionList', JSON.stringify(data));

    const response = yield WebService({
      method,
      url: fullURL,
      data: dataToApi
    });

    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.createPlannerSessionSuccess());
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.createPlannerSessionFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.createPlannerSessionFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.createPlannerSessionFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.createPlannerSessionFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.createPlannerSessionFailure(payload));
    }
  }
}

export function* copyScheduleByDaySaga({ payload }) {
  const { _id, selectDate, targetDate } = payload;

  try {
    yield put(Creators.copyScheduleByDayStart());
    const fullURL = yield `${apiEndPoints.copyScheduleByDay}`;

    //  eslint-disable-next-line no-undef
    const dataToApi = new FormData();
    dataToApi.append('_id', _id);
    dataToApi.append('selectDate', selectDate);
    dataToApi.append('targetDate', targetDate);

    const response = yield WebService({
      method: 'POST',
      url: fullURL,
      data: dataToApi
    });

    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.copyScheduleByDaySuccess());
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.copyScheduleByDayFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.copyScheduleByDayFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.copyScheduleByDayFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.copyScheduleByDayFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.copyScheduleByDayFailure(payload));
    }
  }
}

export function* copyScheduleByWeekSaga({ payload }) {
  const { _id, selectEndDate, selectStartDate, targetStartDate, targetEndDate } = payload;
  try {
    yield put(Creators.copyScheduleByWeekStart());
    const fullURL = yield `${apiEndPoints.copyScheduleByWeek}`;

    //  eslint-disable-next-line no-undef
    const dataToApi = new FormData();
    dataToApi.append('_id', _id);
    dataToApi.append('selectStartDate', getYearMonthDay(selectStartDate));
    dataToApi.append('selectEndDate', getYearMonthDay(selectEndDate));
    dataToApi.append('targetStartDate', getYearMonthDay(targetStartDate));
    dataToApi.append('targetEndDate', getYearMonthDay(targetEndDate));

    const response = yield WebService({
      method: 'POST',
      url: fullURL,
      data: dataToApi
    });

    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.copyScheduleByDaySuccess());
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.copyScheduleByWeekFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.copyScheduleByWeekFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.copyScheduleByWeekFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.copyScheduleByWeekFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.copyScheduleByWeekFailure(payload));
    }
  }
}

export function* clearScheduleByDaySaga({ payload }) {
  const { _id, sessionDate } = payload;
  try {
    yield put(Creators.clearScheduleByDayStart());
    const fullURL = yield `${apiEndPoints.clearScheduleByDay}`;

    //  eslint-disable-next-line no-undef
    const dataToApi = new FormData();
    dataToApi.append('_id', _id);
    dataToApi.append('sessionDate', sessionDate);

    const response = yield WebService({
      method: 'DELETE',
      url: fullURL,
      data: dataToApi
    });

    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.clearScheduleByDaySuccess());
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.clearScheduleByDayFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.clearScheduleByDayFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.clearScheduleByDayFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.clearScheduleByDayFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.clearScheduleByDayFailure(payload));
    }
  }
}

export function* clearScheduleByWeekSaga({ payload }) {
  try {
    yield put(Creators.clearScheduleByWeekStart());
    const fullURL = yield `${apiEndPoints.clearScheduleByWeek}`;

    //  eslint-disable-next-line no-undef
    const dataToApi = new FormData();
    dataToApi.append('_id', payload._id);
    dataToApi.append('sessionStartDate', payload.selectStartDate);
    dataToApi.append('sessionEndDate', payload.selectEndDate);

    const response = yield WebService({
      method: 'DELETE',
      url: fullURL,
      data: dataToApi
    });

    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.copyScheduleByDaySuccess());
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.clearScheduleByWeekFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.clearScheduleByWeekFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.clearScheduleByWeekFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.clearScheduleByWeekFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.clearScheduleByWeekFailure(payload));
    }
  }
}

export function* deleteSessionSaga({ payload }) {
  const { _id } = payload;

  try {
    yield put(Creators.deleteSessionStart());
    const fullURL = yield `${apiEndPoints.addSession}`;

    const response = yield WebService({
      method: 'DELETE',
      url: fullURL,
      data: { _id }
    });

    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.deleteSessionSuccess());
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.deleteSessionFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.deleteSessionFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.deleteSessionFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.deleteSessionFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.deleteSessionFailure(payload));
    }
  }
}
