import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getCoachStrengthFeed: ['payload'],
  getCoachStrengthFeedStart: ['payload'],
  getCoachStrengthFeedSuccess: ['payload'],
  getCoachStrengthFeedFailure: ['payload'],

  resetCoachStrengthFeed: null,
  setShowDetailsModal: ['payload'],
  selectedSession: ['payload']
});

export { Types, Creators };
