/* eslint-disable no-unused-vars */
import { createReducer } from 'reduxsauce';
import { mergeArray } from '../../../../services/utility';
import { Types } from './action';

const INITIAL_STATE = {
  loading: false,
  error: null,
  success: false,
  moodList: [],
  reload: false,
  commentsList: [],
  showTracker: true
};

const getPlayerMoodListStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getPlayerMoodListSuccess = (state = INITIAL_STATE, action) => {
  const { moodList } = action.payload;

  return {
    ...state,
    loading: false,
    reload: false,
    moodList: moodList || []
  };
};

const getPlayerMoodListFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const setPlayerMoodStart = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true,
  success: false
});

const setPlayerMoodSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  success: true,
  showTracker: false
});

const setPlayerMoodFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const deletePlayerMoodStart = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true
});

const deletePlayerMoodSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  success: true,
  reload: true
});

const deletePlayerMoodFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const getPlayerMoodCommentsSuccess = (state = INITIAL_STATE, action) => {
  const newCommentsList = action.payload.commentsList;
  const oldCommentsList = state.commentsList;
  const newArray = mergeArray(oldCommentsList, newCommentsList);
  return {
    ...state,
    commentsList: [...newArray]
  };
};

const getPlayerMoodCommentsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    error
  };
};

const HANDLERS = {
  [Types.GET_PLAYER_MOOD_LIST_START]: getPlayerMoodListStart,
  [Types.GET_PLAYER_MOOD_LIST_SUCCESS]: getPlayerMoodListSuccess,
  [Types.GET_PLAYER_MOOD_LIST_FAILURE]: getPlayerMoodListFailure,

  [Types.SET_PLAYER_MOOD_START]: setPlayerMoodStart,
  [Types.SET_PLAYER_MOOD_SUCCESS]: setPlayerMoodSuccess,
  [Types.SET_PLAYER_MOOD_FAILURE]: setPlayerMoodFailure,

  [Types.DELETE_PLAYER_MOOD_START]: deletePlayerMoodStart,
  [Types.DELETE_PLAYER_MOOD_SUCCESS]: deletePlayerMoodSuccess,
  [Types.DELETE_PLAYER_MOOD_FAILURE]: deletePlayerMoodFailure,

  [Types.GET_PLAYER_MOOD_COMMENTS_SUCCESS]: getPlayerMoodCommentsSuccess,
  [Types.GET_PLAYER_MOOD_COMMENTS_FAILURE]: getPlayerMoodCommentsFailure
};

export const playerMoodReducer = createReducer(INITIAL_STATE, HANDLERS);
