import styled from 'styled-components';
import { backgroundColor, borderColor, fontSize, accentColor, textColors } from '../../../shared/constants';

export const ModalContainer = styled.div`
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  justify-content: flex-end;

  background-color: ${backgroundColor.popupBg};
  z-index: 9999;
`;

export const ContentContainer = styled.div`
  padding-top: 3rem;
`;

export const ContentDiv = styled.div`
  height: 100%;
  width: 600px;
  padding-top: 3rem;

  background-color: ${backgroundColor.popup};
  border-radius: 6px 0px 0px 6px;

  overflow-y: auto;
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 635px;
  top: 18px;
  z-index: 999;
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const FormTitle = styled.h1`
  font-size: ${fontSize.titleVerySmall};
  margin-bottom: 1rem;
  padding-left: 3rem;
`;
export const FormContainer = styled.div`
  // height: 490px;
  padding: 2rem;
`;
export const ScrollContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 0 1rem;
  overflow-y: auto;
`;

export const AddMoreButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;

  padding: 2rem 0;

  & > button {
    background: transparent;
    border: none;

    font-size: ${fontSize.mediumDefault};
    color: ${accentColor.primary};

    cursor: pointer;

    &:focus {
      outline: 0px;
    }
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  padding: 1rem;
  border: 0.5px solid ${borderColor.light};

  margin-bottom: 1rem;
  border-radius: 1rem;
`;

export const Column = styled.div`
  width: ${(props) => props.wdth};
  padding: 0 1rem;
`;

export const Heading = styled.h1`
  font-size: 12px;
  color: #8792ac;
  text-transform: uppercase;
  font-weight: 600;
  padding: 2rem 0 1rem;

  & > svg {
    margin: 0 0.5rem;
  }
`;

export const ListContainer = styled.div`
  width: 100%;
  padding: 1rem 0;
  overflow-y: auto;
`;

export const Wrapper = styled.div`
  // min-height: calc(100vh - 170px);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const ListItem = styled.div`
  height: 78px;
  width: 100%;
  background: #4e436926;
  border-radius: 6px;
  padding: 1.5rem;
  margin-bottom: 2rem;

  display: flex;
  align-items: center;
  position: relative;
`;

export const ThumbContainer = styled.div`
  height: 48px;
  width: 88px;
  position: relative;

  & > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const Thumbnail = styled.img`
  width: 88px;
  height: 48px;

  border-radius: 0.6rem;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const Cue = styled.p`
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

export const ItemDetails = styled.div`
  padding: 0 1.5rem;

  & > h1 {
    font-size: ${fontSize.mediumDefault};
    font-weight: 700;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }

  & > p {
    font-size: ${fontSize.smallDefault};
    color: #cbd1df;
  }
`;

export const MenuBtn = styled.div`
  width: 10px !important;
  margin-left: auto;
  cursor: pointer;
`;

export const Menu = styled.div`
  position: absolute;
  right: 25px;
  top: 27px;
  height: 85px;
  width: 96px;
  background: #262130;
  box-shadow: 0px 5px 18px #0000002e;
  border-radius: 6px;
  z-index: 999;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  align-items: unset;
  flex-direction: column;
  justify-content: center;

  & > div {
    padding: 1rem 2rem;
    font-size: 14px;
    cursor: pointer;
  }
`;

export const MenuItem = styled.p`
  padding: 1rem 2rem;
  font-size: 14px;
  cursor: ${(props) => (props.isActive ? 'pointer ' : 'disabled')};
  color: ${(props) => (props.isActive ? `${textColors.textWhite}` : `${textColors.textLight}`)};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  & > h1 {
    font-size: ${fontSize.titleVerySmall};
  }

  & > svg {
    cursor: pointer;
  }
`;

export const Content = styled.div`
  margin-top: 47px;
`;

export const ContentRow = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 2rem 0;
  border-bottom: 0.5px solid ${borderColor.light};

  & > p {
    font-size: ${fontSize.smallDefault};
    color: ${textColors.textLight};
  }

  & > span {
    font-size: ${fontSize.mediumDefault};
    color: ${textColors.textWhite};
    text-align: right;
  }
`;

export const VideoPlayerContainer = styled.div`
  height: 250px;
  width: 100%;
  margin: 1rem 0;
  background-color: black;
`;
