import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from '../../ProfileModal/store';

import Toast, { Toasts } from '../../../services/toast';

// styles
import { Header, H2 } from './PersonalStyles';
import { Container } from './ProfileModalStyles';
import { Input } from '../../../shared/components/FormComponents/FormInput/FormInput.styles';
import { ErrorMsg, Layout } from '../../PersonalDetails/PersonalDetails.styles';

// components
import { ButtonComponent, TooltipComponnet } from '../../../globalShared';
import { FormInput } from '../../../shared/components/FormComponents';

const { Row, Column } = Layout;

const Password = () => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const [state, setState] = useState({ currentPassword: '', password: '', confirmPassword: '' });
  const [errors, setErrors] = useState({});

  const success = useSelector((state) => state.updatePersonalDetails.resetSuccess);
  const error = useSelector((state) => state.updatePersonalDetails.error);

  // toast notification

  useEffect(() => {
    if (success) {
      Toast.success('Password Changed', 2, () => dispatch(Creators.resetSuccess()));
      setEditMode(false);
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      Toast.error(error, 2, () => dispatch(Creators.resetError()));
    }
  }, [error]);

  const validateForm = () => {
    const errors = {};
    let IsValid = true;

    if (!state.currentPassword) {
      IsValid = false;
      errors.currentPassword = 'Required';
    }

    if (!state.password) {
      IsValid = false;
      errors.password = 'Required';
    } else if (!state.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)) {
      IsValid = false;
      errors.password = 'must Include lower upper number special';
    }

    if (!state.confirmPassword) {
      IsValid = false;
      errors.confirmPassword = 'Required';
    } else if (state.password !== state.confirmPassword) {
      IsValid = false;
      errors.confirmPassword = 'Password Not matched';
    }

    setErrors(errors);
    return IsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      dispatch(Creators.resetPassword({ ...state }));
    }
  };

  return (
    <>
      <Toasts />
      <Container style={{ margin: '2rem 0' }}>
        <form onSubmit={handleSubmit}>
          <Header>
            <H2>Reset Password</H2>
            {editMode ? (
              <ButtonComponent buttonText='Save' buttonType='submit' />
            ) : (
              <TooltipComponnet type='edit2' title='Edit' onClick={() => setEditMode(true)} />
            )}
          </Header>
          {!editMode ? (
            <Input showLabel={false} type='password' value='0000000000' disabled={true} setValue={() => null} />
          ) : (
            <>
              <Row style={{ margin: '0' }}>
                <Column>
                  <FormInput
                    placeholder=''
                    type='password'
                    label='Current Password'
                    value={state.currentPassword}
                    setValue={(value) => setState({ ...state, currentPassword: value })}
                  />
                  <ErrorMsg>{errors.currentPassword}</ErrorMsg>
                </Column>

                <Column>
                  <FormInput
                    placeholder=''
                    label='New Password'
                    value={state.password}
                    setValue={(value) => setState({ ...state, password: value })}
                  />
                  <ErrorMsg>{errors.password}</ErrorMsg>
                </Column>

                <Column>
                  <FormInput
                    placeholder=''
                    type='password'
                    label='Re-enter New Password'
                    value={state.confirmPassword}
                    setValue={(value) => setState({ ...state, confirmPassword: value })}
                  />
                  <ErrorMsg>{errors.confirmPassword}</ErrorMsg>
                </Column>
              </Row>
            </>
          )}
        </form>
      </Container>
    </>
  );
};

export default Password;
