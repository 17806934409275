import styled from 'styled-components';
import { fontSize, textColors, fontWeight, borderColor, backgroundColor } from '../../shared/constants';

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 6% 8%;
`;

export const Title = styled.h1`
  font-size: ${fontSize.titleMedium};
  margin-bottom: 10px;
`;

export const SubHeading = styled.p`
  font-size: ${fontSize.mediumDefault};
`;

export const Layout = {
  Row: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 3.5rem 0;
  `,

  Column: styled.div`
    width: 32%;
    display: inline-block;
  `,
  Wrapper: styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 5rem 0;
  `
};

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Width50 = styled.div`
  width: 48%;
`;

export const DashedContainer = styled.div`
    font-size: 18px;
    width: 160px;
    padding: 10px;
    border: 3px dashed ${borderColor.light};
    border-radius: 0.5rem;
}`;

export const InputContainer = styled.div`
  height: 130px;
  width: 130px;

  color: ${textColors.textLight};
  text-align: center;
  font-size: ${fontSize.default};
  font-weight: ${fontWeight.medium};
  background-color: ${backgroundColor.darkOpaq};
  border-radius: 0.6rem;

  padding: 1rem;
  overflow: hidden;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  cursor: pointer;
  &:hover {
    color: ${textColors.textWhite};
  }
`;

export const PictureContainer = styled.div`
  height: 130px;
  width: 130px;

  border-radius: 0.6rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;
export const ButtonContainer = styled.div`
  position: relative;
  bottom: -10px;
  left: -7px;

  width: 100px;
`;

export const FileInput = styled.input`
  width: 0px;
  height: 0px;
  opacity: 0;

  overflow: hidden;
  position: absolute;

  z-index: -1;
  font-size: ${fontSize.mediumDefault};
`;

export const TogglerContainer = styled.div`
  width: 35%;
  display: flex;
  justify-content: space-between;
  & > p {
    font-size: ${fontSize.mediumDefault};
    color: ${textColors.textLight};
  }
`;

export const Divider = styled.div`
  display: block;
  height: 0.5px;
  width: 100%;
  background-color: ${borderColor.light};
`;

export const AttachmentContainer = styled.div`
  border-radius: 0.6rem;
  height: 100px;
  border: 1px solid #a3a9b733;
  padding: 1rem;
  margin: 1rem;
`;

export const ErrorMsg = styled.p`
  font-size: ${fontSize.mediumDefault};
  color: ${textColors.error};
  margin-right: auto;
  margin-top: -1.5rem;
  margin-bottom: 1rem;
`;

export const Footer = styled.div`
  padding: 15px 0;
  display: flex;
  justify-content: flex-end;
`;

export const BackButton = styled.div`
  padding: 1.2rem 2rem;
  margin: auto 1rem;
  border: none;
  border-radius: 0.5rem;
  background: ${backgroundColor.darkOpaq};
  cursor: pointer;
  text-align: center;
  font-size: ${fontSize.mediumDefault};
  color: ${textColors.textWhite};
`;
export const Timer = styled.div`
  width: 100px;
  margin: 0 10px;
`;
export const Dash = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-top: 3px;
  font-size: 16px;
`;
