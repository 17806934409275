import styled from 'styled-components';
import { fontSize, textColors } from '../../constants';

export const Container = styled.div`
  padding: 1.6rem;

  display: flex;
  justify-content: space-between;
  align-items: start;
`;

export const Title = styled.p`
  font-size: ${fontSize.mediumDefault};
  margin-bottom: 0.5rem;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
`;

export const Time = styled.p`
  font-size: ${fontSize.smallDefault};
  color: ${textColors.textLight};
`;

export const ShowComments = styled.div`
  font-size: calc(${fontSize.mediumDefault} - 0.1rem);
  // text-transform: uppercase;
  cursor: pointer;

  & > svg {
    margin: 0 0.5rem;
  }
`;

export const CommentContainer = styled.div`
  padding: 1rem 1.5rem;
`;
export const ScrollContainer = styled.div`
  max-height: 150px;
  padding: 1rem;
  overflow-y: auto;
`;
