/* eslint-disable arrow-body-style */
/*
 * reducer.js file for createpassword view
 * Author:
 * 1. Manikanta
 *
 * @copyright of Panorbit Services LLP.
 */

import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  loading: false,
  error: null,
  success: false
};

const createPasswordStart = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: true
  };
};

const createPasswordSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: false,
    success: true
  };
};

const createPasswordFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    success: false,
    error
  };
};

const resetPass = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: false,
    success: false
  };
};

const HANDLERS = {
  [Types.CREATE_PASSWORD_START]: createPasswordStart,
  [Types.CREATE_PASSWORD_SUCCESS]: createPasswordSuccess,
  [Types.CREATE_PASSWORD_FAILURE]: createPasswordFailure,
  [Types.RESET_PASS]: resetPass
};

export const createPasswordReducer = createReducer(INITIAL_STATE, HANDLERS);
