/*
 * action.js file for player strength view
 * Author:
 * 1.Manikanta
 *
 * @copyright of Panorbit Services LLP.
 */

import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getDaySessionList: ['payload'],
  getDaySessionListStart: ['payload'],
  getDaySessionListSuccess: ['payload'],
  getDaySessionListFailure: ['payload'],

  getAvailableSessions: ['payload'],
  getAvailableSessionsStart: ['payload'],
  setAvailableSessions: ['payload'],
  getAvailableSessionsFailure: ['payload'],

  getSessionWorkouts: ['payload'],
  getSessionWorkoutsStart: ['payload'],
  getSessionWorkoutsSuccess: ['payload'],
  getSessionWorkoutsFailure: ['payload'],

  setSessionStatus: ['payload'],
  setSessionStatusStart: ['payload'],
  setSessionStatusSuccess: ['payload'],
  setSessionStatusFailure: ['payload'],

  getWorkoutExerciseDetails: ['payload'],
  getWorkoutExerciseDetailsStart: ['payload'],
  getWorkoutExerciseDetailsSuccess: ['payload'],
  getWorkoutExerciseDetailsFailure: ['payload'],

  setPlayerExercise: ['payload'],
  setPlayerExerciseStart: ['payload'],
  setPlayerExerciseSuccess: ['payload'],
  setPlayerExerciseFailure: ['payload'],

  addPlayerSessionComment: ['payload'],
  addPlayerSessionCommentStart: ['payload'],
  addPlayerSessionCommentSuccess: ['payload'],
  addPlayerSessionCommentFailure: ['payload'],

  getPlayerSessionComments: ['payload'],
  getPlayerSessionCommentsStart: ['payload'],
  getPlayerSessionCommentsSuccess: ['payload'],
  getPlayerSessionCommentsFailure: ['payload'],

  resetError: null,
  resetSuccess: null
});

export { Types, Creators };
