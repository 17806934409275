/* eslint-disable arrow-body-style */
/* eslint-disable indent */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable object-property-newline */
/* eslint-disable no-param-reassign */

import React, { useState, useEffect, useRef } from 'react';

import { useSelector } from 'react-redux';

// styles
import {
  CloseButton,
  InputsContainer,
  MealInputsContainer,
  Column,
  NutrientHeader,
  MealContainer,
  FormTitle,
  AddMealButton,
  AddmealContainer
} from '../MealPlansView/CreateMealPlanModalStyles';
import { FormFooter, P, MainContainer, ModalContainer, ContentContainer, Muted } from './CustomMealStyles';

import { Divider, FlexContainer } from '../../../globalShared/CommonStyles';

import { ReactComponent as DeleteIcon } from '../../../shared/assets/delete.svg';
import { ReactComponent as CloseIcon } from '../../../shared/assets/Close.svg';
import { ReactComponent as AddICon } from '../../../shared/assets/add_meal.svg';
import { ReactComponent as DragIcon } from '../../../shared/assets/drag.svg';

import { AddMoreComponent, ButtonComponent } from '../../../globalShared';
import { FormInput, Dropdown } from '../../../shared/components/FormComponents';

import { foodTime } from '../../../shared/constants/lists';
import { ErrorMsg } from '../../PersonalDetails/PersonalDetails.styles';
import { scrollToView } from '../../../services/utility';

const numbersRegx = /^[0-9]+$/;
const floatRegex = /^[0-9]*(\.[0-9]{0,2})?$/;

const CreateCustomMealPlanModal = ({ mealPlan, onCancel, onSubmit }) => {
  const lastItemRef = useRef();
  const listItemRef = useRef();

  const filelds = {
    title: 'Customized Meal Plan',
    typeOfFood: 'None',
    protein: '',
    carbohydrates: '',
    fats: '',
    totalKcal: '',
    totalWaterIntake: ''
  };
  // form data
  const [formFields, setFormFields] = useState(filelds);

  const [mealFormFields] = useState({
    title: '',
    kcal: '',
    time: '',
    intake: [{ descr: '', remarks: '' }]
  });

  const [mealFormArray, setMealFormArray] = useState([mealFormFields]);
  const [errors, setErrors] = useState({
    ...formFields,
    toatlMeal: '',
    meal: [{ Mtitle: '', Mcal: '', Mtime: '', MIntake: '' }]
  });

  const success = useSelector((state) => state.coachMealPlan.success);

  const _calcKcal = (name, value) => {
    const { protein, carbohydrates, fats } = formFields;
    let total = 0;

    if (name === 'protein') {
      total = carbohydrates * 4 + fats * 9 + value * 4;
    }
    if (name === 'carb') {
      total = value * 4 + fats * 9 + protein * 4;
    }
    if (name === 'fats') {
      total = carbohydrates * 4 + protein * 4 + value * 9;
    }

    return total;
  };

  // form validations
  const validateForm = () => {
    const errs = errors;
    let IsValid = true;

    if (!`${formFields.protein}`) {
      IsValid = false;
      errs.protein = 'Required';
    }

    if (!`${formFields.carbohydrates}`) {
      IsValid = false;
      errs.carbohydrates = 'Required';
    }

    if (!`${formFields.fats}`) {
      IsValid = false;

      errs.fats = 'Required';
    }

    if (!`${formFields.totalKcal}`) {
      IsValid = false;

      errs.totalKcal = 'Required';
    }

    if (!`${formFields.totalWaterIntake}`) {
      IsValid = false;
      errs.totalWaterIntake = 'Required';
    }

    // eslint-disable-next-line array-callback-return
    mealFormArray.map((meal, i) => {
      const mealErr = errors.meal;
      const intakeIsFilled = meal.intake.map(({ descr }) => !!descr).reduce((res, cur) => res && cur, true);

      if (!meal.title) {
        IsValid = false;
        errs.meal[i] = { ...mealErr[i], Mtitle: true };
      }

      if (!meal.kcal) {
        IsValid = false;
        errs.meal[i] = { ...mealErr[i], Mcal: 'Required' };
      }

      if (!meal.time) {
        IsValid = false;
        errs.meal[i] = { ...mealErr[i], Mtime: true };
      }

      if (!intakeIsFilled) {
        IsValid = false;
        mealErr[i] = { ...mealErr[i], MIntake: 'Fill Intakes' };
        setErrors({ ...errors, meal: mealErr });
      }
    });

    if (!mealFormArray.length) {
      IsValid = false;
      errs.toatlMeal = 'Required Meal';
    }

    setErrors({ ...errs });
    return IsValid;
  };

  // sets formfields if melplan is available
  useEffect(() => {
    if (mealPlan) {
      const { title, _id, carbohydrates, fats, meals, protein, totalKcal, totalWaterIntake, typeOfFood } = mealPlan;
      setFormFields({ title, _id, carbohydrates, fats, protein, totalKcal, totalWaterIntake, typeOfFood });
      setMealFormArray(meals);
    }
  }, [mealPlan]);

  const handleCancel = () => {
    setFormFields(filelds);
    setMealFormArray([mealFormFields]);
    onCancel();
  };

  const handleSubmit = () => {
    if (validateForm()) {
      onSubmit({ ...formFields, meals: mealFormArray });
    }
  };

  // form functionality
  const addMeals = () => {
    setMealFormArray([
      ...mealFormArray,
      {
        title: '',
        kcal: '',
        time: '',
        intake: [{ descr: '', remarks: '' }]
      }
    ]);

    // adds err fields
    let mealErr = errors.meal;
    mealErr = [...mealErr, { Mtitle: '', Mcal: '', Mtime: '', MIntake: '' }];
    setErrors({ ...errors, meal: mealErr, toatlMeal: '' });

    scrollToView(lastItemRef);
  };

  const deleteMeal = (_mealId) => {
    const newArray = mealFormArray.filter((_, K) => K !== _mealId);
    setMealFormArray(newArray);

    // removes err fields
    let mealErr = errors.meal;
    mealErr = mealErr.filter((_, K) => K !== _mealId);
    setErrors({ ...errors, meal: mealErr });
  };

  const deleteFood = (mealId, intakeId) => {
    let IntakesArray = [...mealFormArray[mealId].intake];
    IntakesArray = IntakesArray.filter((_, index) => index !== intakeId);

    const newArray = [...mealFormArray];
    newArray[mealId] = { ...newArray[mealId], intake: IntakesArray };
    setMealFormArray(newArray);

    // onChange err handler
    const mealErr = errors.meal;
    mealErr[mealId] = { ...mealErr[mealId], MIntake: '' };
    setErrors({ ...errors, meal: mealErr });
  };

  // plan details form onchange handlers

  const handleProtein = (value) => {
    if (value.match(numbersRegx) || value.length === 0) {
      setFormFields({ ...formFields, protein: value, totalKcal: _calcKcal('protein', value) });
    }

    // onChange form Handler
    const errs = errors;
    if (!value || !value.match(numbersRegx)) {
      errs.protein = 'Required';
      setErrors({ ...errs });
    } else {
      errs.protein = false;
      errs.totalKcal = false;
      setErrors({ ...errs });
    }
  };

  const handleCarbohydrates = (value) => {
    if (value.match(numbersRegx) || value.length === 0) {
      setFormFields({ ...formFields, carbohydrates: value, totalKcal: _calcKcal('carb', value) });
    }

    // onChange form Handler
    const errs = errors;
    if (!value || !value.match(numbersRegx)) {
      errs.carbohydrates = 'Required';
      setErrors({ ...errs });
    } else {
      errs.carbohydrates = false;
      errs.totalKcal = false;
      setErrors({ ...errs });
    }
  };

  const handleFats = (value) => {
    if (value.match(numbersRegx) || value.length === 0) {
      setFormFields({ ...formFields, fats: value, totalKcal: _calcKcal('fats', value) });
    }

    // onChange form Handler
    const errs = errors;
    if (!value || !value.match(numbersRegx)) {
      errs.fats = 'Required';
      setErrors({ ...errs });
    } else {
      errs.fats = false;
      errs.totalKcal = false;
      setErrors({ ...errs });
    }
  };

  const handleTotalKcal = (value) => {
    if (floatRegex.test(value) || value.length === 0) {
      setFormFields({ ...formFields, totalKcal: value });
    }

    // onChange form Handler
    const errs = errors;
    if (!value || !floatRegex.test(value)) {
      errs.totalKcal = 'Required';
      setErrors({ ...errs });
    } else {
      errs.totalKcal = false;
      setErrors({ ...errs });
    }
  };

  const handleTotalWater = (value) => {
    if (floatRegex.test(value) || value.length === 0) {
      setFormFields({ ...formFields, totalWaterIntake: value });
    }

    // onChange form Handler
    const errs = errors;
    if (!value) {
      errs.totalWaterIntake = 'Required';
      setErrors({ ...errs });
    } else {
      errs.totalWaterIntake = false;
      setErrors({ ...errs });
    }
  };

  // meal form onchange handlers
  const handleMealTitleChange = (value, index) => {
    const newArray = [...mealFormArray];
    newArray[index] = { ...newArray[index], title: value };
    setMealFormArray(newArray);

    // onChange err handler
    const mealErr = errors.meal;
    if (!value) {
      mealErr[index] = { ...mealErr[index], Mtitle: true };
    } else {
      mealErr[index] = { ...mealErr[index], Mtitle: false };
    }

    setErrors({ ...errors, meal: mealErr });
  };

  const handleKcalChange = (value, index) => {
    if (value.match(numbersRegx) || value.length === 0) {
      const newArray = [...mealFormArray];
      newArray[index] = { ...newArray[index], kcal: value };
      setMealFormArray(newArray);
    }

    // onChange err handler
    const mealErr = errors.meal;
    if (!value) {
      mealErr[index] = { ...mealErr[index], Mcal: 'Required' };
    } else {
      mealErr[index] = { ...mealErr[index], Mcal: false };
    }
    setErrors({ ...errors, meal: mealErr });
  };

  const handleTimeChange = (value, index) => {
    const newArray = [...mealFormArray];
    newArray[index] = { ...newArray[index], time: value };
    setMealFormArray(newArray);

    // onChange err handler
    const mealErr = errors.meal;
    if (mealErr[index].Mtime) {
      mealErr[index] = { ...mealErr[index], Mtime: false };
    }
    setErrors({ ...errors, meal: mealErr });
  };

  const handleAddIntake = (index) => {
    const newArray = [...mealFormArray];
    newArray[index].intake = [...newArray[index].intake, { descr: '', remake: '' }];
    setMealFormArray(newArray);
  };

  const handleIntakeChange = (value, mainIndex, subIndex, type) => {
    const newArry = [...mealFormArray];

    const newIntakeArry = newArry[mainIndex].intake;
    newIntakeArry[subIndex] = { ...newIntakeArry[subIndex], [type]: value };

    newArry[mainIndex] = { ...newArry[mainIndex], intake: newIntakeArry };
    setMealFormArray(newArry);

    // onChange err handler
    const mealErr = errors.meal;
    mealErr[mainIndex] = { ...mealErr[mainIndex], MIntake: '' };
    setErrors({ ...errors, meal: mealErr });
  };

  useEffect(() => {
    if (success) {
      handleCancel();
    }
  }, [success]);

  return (
    <ModalContainer>
      <ContentContainer>
        <CloseButton onClick={handleCancel}>
          <CloseIcon />
        </CloseButton>

        <MainContainer>
          <InputsContainer>
            <FlexContainer style={{ width: '100%', justifyContent: 'space-between' }}>
              <Muted>{formFields.title}</Muted>
            </FlexContainer>
            <NutrientHeader>Per Day Nutrients Intake</NutrientHeader>
            <FlexContainer style={{ width: '100%', justifyContent: 'space-between', alignItems: 'flex-start' }}>
              <Column wdth='175px'>
                <FormInput
                  label='Protein(g)'
                  placeholder='Enter in gm'
                  value={formFields.protein}
                  setValue={handleProtein}
                  error={!!errors.protein}
                />
                <ErrorMsg>{errors.protein}</ErrorMsg>
              </Column>
              <Column wdth='175px'>
                <FormInput
                  label='Carbohydrates(g)'
                  placeholder='Enter in gm'
                  value={formFields.carbohydrates}
                  setValue={handleCarbohydrates}
                  error={!!errors.carbohydrates}
                />
                <ErrorMsg>{errors.carbohydrates}</ErrorMsg>
              </Column>
              <Column wdth='175px'>
                <FormInput
                  label='Fats(g)'
                  placeholder='Enter in gm'
                  value={formFields.fats}
                  setValue={handleFats}
                  error={!!errors.fats}
                />
                <ErrorMsg>{errors.fats}</ErrorMsg>
              </Column>
              <Column wdth='175px'>
                <FormInput
                  label='Total Kcal'
                  placeholder='Enter Value'
                  value={formFields.totalKcal}
                  setValue={handleTotalKcal}
                  error={!!errors.totalKcal}
                />
                <ErrorMsg>{errors.totalKcal}</ErrorMsg>
              </Column>
              <Column wdth='175px'>
                <FormInput
                  label='Total Water Intake(L)'
                  placeholder='0'
                  value={formFields.totalWaterIntake}
                  setValue={handleTotalWater}
                  error={!!errors.totalWaterIntake}
                />
                <ErrorMsg>{errors.totalWaterIntake}</ErrorMsg>
              </Column>
            </FlexContainer>
          </InputsContainer>

          <AddmealContainer>
            <FormTitle style={{ margin: '0' }}>Meals</FormTitle>
            <AddMealButton onClick={addMeals}>
              <AddICon />
              Add Meal
            </AddMealButton>
          </AddmealContainer>

          <>
            {mealFormArray.length
              ? mealFormArray.map((form, _mealId, array) => {
                  const { title: mealTitle, kcal, time } = form;
                  const errs = errors.meal;
                  return (
                    <MealContainer key={_mealId} ref={array.length - 1 === _mealId ? lastItemRef : listItemRef}>
                      <MealInputsContainer style={{ width: '100%', marginRight: '0.5rem', paddingLeft: '0.5rem' }}>
                        <DragIcon style={{ marginTop: '4rem', marginRight: '1.5rem' }} />
                        <div>
                          <FlexContainer
                            style={{ width: '100%', justifyContent: 'space-between', alignItems: 'flex-start' }}
                          >
                            <Column wdth='530px'>
                              <FormInput
                                label='Meal Title'
                                placeholder='Enter Meal Title...'
                                value={mealTitle}
                                setValue={(value) => handleMealTitleChange(value, _mealId)}
                                error={!!errs[_mealId]?.Mtitle}
                              />
                              <ErrorMsg>{errs[_mealId]?.Mtitle && 'Required'}</ErrorMsg>
                            </Column>
                            <Column wdth='175px' style={{ margin: '0 4px' }}>
                              <FormInput
                                label='Kcal'
                                placeholder='Enter Value'
                                value={kcal}
                                setValue={(value) => handleKcalChange(value, _mealId)}
                                error={!!errs[_mealId]?.Mcal}
                              />
                              <ErrorMsg>{errs[_mealId]?.Mcal}</ErrorMsg>
                            </Column>
                            <Column wdth='175px'>
                              <Dropdown
                                list={foodTime}
                                label='Time'
                                value={time}
                                setValue={(value) => handleTimeChange(value, _mealId)}
                                maxHeight='107'
                              />
                              <ErrorMsg>{errs[_mealId]?.Mtime && 'Required'}</ErrorMsg>
                            </Column>
                          </FlexContainer>
                          <Divider />
                          <ErrorMsg style={{ margin: 0 }}>{errs[_mealId]?.MIntake}</ErrorMsg>
                          {form.intake.map((intakeItem, k) => {
                            return (
                              <FlexContainer
                                key={k}
                                style={{ width: '100%', justifyContent: 'space-between', margin: '0.3rem 0' }}
                              >
                                <Column wdth='500px'>
                                  <FormInput
                                    showLable={k === 0}
                                    label='Intake'
                                    placeholder='Type The Intakes...'
                                    value={intakeItem.descr}
                                    setValue={(value) => handleIntakeChange(value, _mealId, k, 'descr')}
                                  />
                                </Column>

                                <Column wdth='350px'>
                                  <FormInput
                                    showLable={k === 0}
                                    label='Remarks'
                                    placeholder='Input Remarks Here...'
                                    value={intakeItem.remarks}
                                    setValue={(value) => handleIntakeChange(value, _mealId, k, 'remarks')}
                                  />
                                </Column>
                                <CloseIcon
                                  onClick={() => deleteFood(_mealId, k)}
                                  style={{ cursor: 'pointer', marginTop: `${k === 0 ? '0.5' : '-2'}rem` }}
                                />
                              </FlexContainer>
                            );
                          })}
                          <AddMoreComponent onClick={() => handleAddIntake(_mealId)}>+ Add Intake</AddMoreComponent>
                        </div>
                      </MealInputsContainer>
                      <Column style={{ cursor: 'pointer' }}>
                        <DeleteIcon
                          style={{ marginTop: '5rem', height: '2.2rem' }}
                          onClick={() => deleteMeal(_mealId)}
                        />
                      </Column>
                    </MealContainer>
                  );
                })
              : ''}
            <ErrorMsg style={{ marginTop: 0 }}>{errors.toatlMeal}</ErrorMsg>
          </>
        </MainContainer>
        <Divider />
        <FormFooter>
          <P>
            <span style={{ color: 'red' }}>* </span>
            Customized meal plans are specific to the players
          </P>
          <div>
            <ButtonComponent buttonText='Cancel' isButtonAction={true} buttonClick={handleCancel} bgType='dark' />
            <ButtonComponent buttonText='Submit' isButtonAction={true} buttonClick={handleSubmit} />
          </div>
        </FormFooter>
      </ContentContainer>
    </ModalContainer>
  );
};

export default CreateCustomMealPlanModal;
