/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from '../../auth/LoginView/store';
// components
import { PlayerSideNav } from '../../../globalShared';

// icons
import { ReactComponent as Logo } from '../../../shared/assets/logo-header.svg';
import { ReactComponent as Notification } from '../../../shared/assets/Notification-bell.svg';
import { ReactComponent as DropDown } from '../../../shared/assets/Dropdown.svg';
import { ReactComponent as User } from '../../../shared/assets/profile.svg';

import { ReactComponent as Logout } from '../../../shared/assets/Logout.svg';
import userImage from '../../../shared/assets/user.jpg';

// styles
import {
  HeaderContainer,
  FlexContainer,
  NotificationWrapper,
  UserContainer,
  UserIcon,
  DropDownContainer,
  DropDownItem,
  Body,
  ContentContainer
} from './PlayerView.styles';

import PlayerRoutes from '../../../services/router/playerRoutes';
import useTouchOutside from '../../../shared/hooks/useTouchOutside';

import NotificationCard from './NotificationCard';
import ProfileModal from '../ProfileModal/ProfileModal';

/**
 *
 * component
 */

const PlayerView = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { match, userType } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const [showDropdown, setshowDropdown] = useState(false);
  const [showNotificationCard, setShowNotificationCard] = useState(false);
  const [showProfile, setShowProfile] = useState(false);

  const personalDetails = useSelector((state) => state.login.personalDetail);
  // close dropdown on click outside
  const dropDownRef = useRef();
  const notificationCardRef = useRef();
  useTouchOutside(dropDownRef, () => setshowDropdown(false));
  useTouchOutside(notificationCardRef, () => setShowNotificationCard(false));

  // logout
  const handleLogOut = () => {
    dispatch(Creators.logout());
  };

  const handleShowProfile = () => {
    setShowProfile(true);
    setshowDropdown(!showDropdown);
  };

  useEffect(() => {
    if (userType === 'Player') {
      dispatch(Creators.getUserData());
    }
    if (userType !== 'Player') {
      history.push('/');
    }
  }, []);

  return (
    <>
      <HeaderContainer>
        <Logo style={{ cursor: 'pointer' }} onClick={() => history.push('/player/')} />

        <FlexContainer>
          {/* <NotificationWrapper>
            <Notification
              style={{ cursor: 'pointer' }}
              ref={notificationCardRef}
              onClick={() => setShowNotificationCard(false)}
            />
            {showNotificationCard ? <NotificationCard /> : ''}
          </NotificationWrapper> */}

          <UserContainer onClick={() => setshowDropdown(!showDropdown)}>
            <UserIcon style={{ backgroundImage: `url(${personalDetails?.profilePicture || userImage})` }} />
            <DropDown />
          </UserContainer>
        </FlexContainer>

        {showDropdown ? (
          <DropDownContainer ref={dropDownRef}>
            <DropDownItem onClick={handleShowProfile}>
              <User />
              Profile
            </DropDownItem>

            <DropDownItem onClick={handleLogOut}>
              <Logout />
              Logout
            </DropDownItem>
          </DropDownContainer>
        ) : (
          ''
        )}
      </HeaderContainer>
      <Body>
        {showProfile && <ProfileModal onCancel={() => setShowProfile(false)} />}
        <PlayerSideNav isExact={match.isExact} />
        <ContentContainer>
          <PlayerRoutes path={match.path} />
        </ContentContainer>
      </Body>
    </>
  );
};

export default withRouter(PlayerView);
