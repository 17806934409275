/* eslint-disable no-shadow */
import React, { useState } from 'react';

import { Container, DropContainer, DropItem } from './NestedGroup.styles';
// icon
import { ReactComponent as Arrow } from '../../assets/Dropdown.svg';

// component

const NestedGroup = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const { nestedOptions, value, onClickFunc } = props;

  const handleClick = (nestedValue) => {
    onClickFunc(value, nestedValue);
  };

  return (
    <Container>
      <DropItem onClick={() => setIsVisible(!isVisible)}>
        {value}
        <Arrow />
      </DropItem>
      {isVisible ? (
        <DropContainer>
          {nestedOptions.map((option) => {
            const { nestedValue } = option;
            return (
              <DropItem key={nestedValue} onClick={() => handleClick(nestedValue)}>
                {nestedValue}
              </DropItem>
            );
          })}
        </DropContainer>
      ) : (
        ''
      )}
    </Container>
  );
};

export default NestedGroup;
