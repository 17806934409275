/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
import { put } from 'redux-saga/effects';
import { Creators } from './action';

import { WebService } from '../../../services/axios/webServices';
import { apiEndPoints } from '../../../services/axios/endPoints';
import { setAccessTokenToHeader } from '../../../services/axios/helper';
import { setUserDataToLocal } from '../../../services/cookies/helper';
import { cookie } from '../../../services/cookies/cookieServices';
// import { Creators as LoginCreators } from '../../auth/LoginView/store';

export function* updatePersonalDetailsSaga({ payload }) {
  try {
    const { firstName, lastName, upload, phoneNumber, picDelete } = payload;

    yield put(Creators.updatePersonalDetailsStart());
    const fullURL = yield `${apiEndPoints.personalDetails}`;
    // data to be updated
    const dataToApi = new FormData();
    dataToApi.append('firstName', firstName);
    dataToApi.append('lastName', lastName);
    dataToApi.append('phoneNumber', phoneNumber);
    !!upload && dataToApi.append('profilePicture', upload);
    picDelete && dataToApi.append('picDelete', picDelete);

    const response = yield WebService({
      method: 'put',
      url: fullURL,
      data: dataToApi
    });

    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.updatePersonalDetailsSuccess());
    } else {
      throw response;
    }
  } catch (error) {
    console.log(error);

    let payload;
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.updatePersonalDetailsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.updatePersonalDetailsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.updatePersonalDetailsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.updatePersonalDetailsFailure(payload));
      }
    } else {
      payload = {
        error: error.message
      };

      yield put(Creators.updatePersonalDetailsFailure(payload));
    }
  }
}

export function* resetPasswordSaga({ payload }) {
  try {
    const { password, currentPassword } = payload;
    yield put(Creators.resetPasswordStart());
    const fullURL = yield `${apiEndPoints.resetPassword}`;

    const response = yield WebService({
      method: 'POST',
      url: fullURL,
      data: { password, currentPassword }
    });

    if (response.status >= 200 && response.status < 300) {
      const { token } = response.data;
      const cookies = cookie.get('userDetails');

      // set auth token to axios headers
      yield setAccessTokenToHeader(token);

      // write code to set AccessToken to localStorage / cookies
      yield setUserDataToLocal({ ...cookies, accessToken: token });

      yield put(Creators.resetPasswordSuccess());
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.resetPasswordFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.resetPasswordFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.resetPasswordFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.resetPasswordFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.resetPasswordFailure(payload));
    }
  }
}
