/* eslint-disable no-lone-blocks */
import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  loading: false,
  error: null,
  strengthFeed: [],
  reload: false,
  success: false,
  showModal: false,
  session: [],
  maxPage: 1,
  page: 1,
  isNext: false,
  fetchTime: null
};

const getCoachStrengthFeedStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getCoachStrengthFeedSuccess = (state = INITIAL_STATE, action) => {
  const { strengthFeed, isNext, fetchTime } = action.payload;
  return {
    ...state,
    loading: false,
    strengthFeed,
    isNext,
    fetchTime
  };
};

const getCoachStrengthFeedFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};
const resetCoachStrengthFeed = () => ({ ...INITIAL_STATE });

const setShowDetailsModal = (state = INITIAL_STATE, action) => {
  const { show } = action.payload;
  return {
    ...state,
    showModal: show
  };
};

const selectedSession = (state = INITIAL_STATE, action) => {
  const { session } = action.payload;
  return {
    ...state,
    session
  };
};

const HANDLERS = {
  [Types.GET_COACH_STRENGTH_FEED_START]: getCoachStrengthFeedStart,
  [Types.GET_COACH_STRENGTH_FEED_SUCCESS]: getCoachStrengthFeedSuccess,
  [Types.GET_COACH_STRENGTH_FEED_FAILURE]: getCoachStrengthFeedFailure,
  [Types.RESET_COACH_STRENGTH_FEED]: resetCoachStrengthFeed,
  [Types.SET_SHOW_DETAILS_MODAL]: setShowDetailsModal,
  [Types.SELECTED_SESSION]: selectedSession
};

export const coachStrengthReducer = createReducer(INITIAL_STATE, HANDLERS);
