import React from 'react';
import { ReactComponent as Logo } from '../../assets/logo_tcr.svg';
import { Heading, SubHeading, Container } from './FormHeader.styles';

const FormHeader = (props) => {
  const { headingText, subHeadingText } = props;
  return (
    <Container>
      <Logo />
      <Heading>{headingText}</Heading>
      <SubHeading>{subHeadingText}</SubHeading>
    </Container>
  );
};

export default FormHeader;
