import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Input, Label, InputContainer, Button } from './PasswordInput.styles';
import { ReactComponent as Hide } from '../../assets/Hide password.svg';
import { ReactComponent as Show } from '../../assets/Show password.svg';

//
const PasswordInputComponent = (props) => {
  const { placeholderText, ipName, ipRef, label } = props;

  const [isTypePassword, setisTypePassword] = useState(true);

  return (
    <Container>
      <Label forhtml={ipName}>{label}</Label>
      <InputContainer>
        <Input type={isTypePassword ? 'password' : 'text'} placeholder={placeholderText} name={ipName} ref={ipRef} />
        <Button type='button' onClick={() => setisTypePassword(!isTypePassword)}>
          {!isTypePassword ? <Hide /> : <Show />}
        </Button>
      </InputContainer>
    </Container>
  );
};

PasswordInputComponent.defaultProps = {
  label: ''
};

PasswordInputComponent.propTypes = {
  placeholderText: PropTypes.string.isRequired,
  ipName: PropTypes.string.isRequired,
  ipRef: PropTypes.func.isRequired,
  label: PropTypes.string
};

export default PasswordInputComponent;
