/* eslint-disable no-console */
/* eslint-disable no-shadow */
/*
 * saga.js file for users view
 * Author:
 * 1. manikanta
 * @copyright of Panorbit Services LLP.
 */

import { put } from 'redux-saga/effects';
import { Creators } from './action';

import { WebService } from '../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../services/axios/endPoints';

export function* getAdminUsersListSaga({ payload }) {
  try {
    const { userfilter, page, count, searchKey } = payload;
    console.log(userfilter);
    if (userfilter) {
      yield put(Creators.getAdminUsersListStart());
    }

    const fullURL = yield `${apiEndPoints.addUser}`;

    const response = yield WebService.get(fullURL, {
      params: {
        userType: userfilter,
        page,
        count,
        searchKey
      }
    });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      yield put(
        Creators.getAdminUsersListSuccess({
          usersList: data[0],
          maxPages: data[1].page_count,
          totalCount: data[2].totalCount
        })
      );
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getAdminUsersListFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getAdminUsersListFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getAdminUsersListFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getAdminUsersListFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getAdminUsersListFailure(payload));
    }
  }
}

export function* setAdminUsersListSaga({ payload }) {
  console.log(payload);
  try {
    const { addNewUsers } = payload;

    yield put(Creators.setAdminUsersListStart());
    const fullURL = yield `${apiEndPoints.addUser}`;

    const response = yield WebService.post(fullURL, { userList: addNewUsers });

    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.setAdminUsersListSuccess());
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.setAdminUsersListFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.setAdminUsersListFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.setAdminUsersListFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.setAdminUsersListFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.setAdminUsersListFailure(payload));
    }
  }
}

export function* adminDeleteUserSaga({ payload }) {
  try {
    const { id } = payload;

    yield put(Creators.adminDeleteUserStart());
    const fullURL = yield `${apiEndPoints.addUser}`;

    const response = yield WebService({
      method: 'DELETE',
      url: fullURL,
      data: { id }
    });

    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.adminDeleteUserSuccess());
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.adminDeleteUserFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.adminDeleteUserFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.adminDeleteUserFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.adminDeleteUserFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.adminDeleteUserFailure(payload));
    }
  }
}

export function* setAdminBulkUsersListSaga({ payload }) {
  try {
    const { csvFile } = payload;

    yield put(Creators.setAdminBulkUsersListStart());

    // eslint-disable-next-line no-undef
    const dataToApi = new FormData();
    dataToApi.append('csvFile', csvFile);

    const fullURL = yield `${apiEndPoints.addBulkUsers}`;

    const response = yield WebService({
      method: 'POST',
      url: fullURL,
      data: dataToApi
    });

    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.setAdminBulkUsersListSuccess());
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.setAdminBulkUsersListFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.setAdminBulkUsersListFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.setAdminBulkUsersListFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.setAdminBulkUsersListFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.setAdminBulkUsersListFailure(payload));
    }
  }
}

export function* adminUpdateUserDetailsSaga({ payload }) {
  try {
    const { userId, firstName, lastName } = payload;
    yield put(Creators.adminUpdateUserDetailsStart());

    // eslint-disable-next-line no-undef
    const dataToApi = new FormData();
    dataToApi.append('_id', userId);
    dataToApi.append('firstName', firstName);
    dataToApi.append('lastName', lastName);

    const fullURL = yield `${apiEndPoints.addUser}`;

    const response = yield WebService({
      method: 'PUT',
      url: fullURL,
      data: dataToApi
    });

    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.adminUpdateUserDetailsSuccess());
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.adminUpdateUserDetailsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.adminUpdateUserDetailsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.adminUpdateUserDetailsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.adminUpdateUserDetailsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.adminUpdateUserDetailsFailure(payload));
    }
  }
}

export function* getAdminUserDetailsSaga({ payload }) {
  try {
    const { id } = payload;
    yield put(Creators.getAdminUserDetailsStart());
    const fullURL = yield `${apiEndPoints.userDetails}`;

    const response = yield WebService.get(fullURL, {
      params: {
        _id: id
      }
    });

    if (response.status >= 200 && response.status < 300) {
      const { firstName, lastName, email } = response.data.data[0];

      const userFormData = { firstName, lastName, email };

      yield put(Creators.getAdminUserDetailsSuccess({ userFormData }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getAdminUserDetailsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getAdminUserDetailsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getAdminUserDetailsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getAdminUserDetailsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getAdminUserDetailsFailure(payload));
    }
  }
}
