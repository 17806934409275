export const rootConfig = {
  // production url
  apiRoot: 'https://api.thecricketrevolution.com/api/v1_2'

  // testing url
  // apiRoot: 'http://devapi.thecricketrevolution.com/api/v1_1'
};

export const apiEndPoints = {
  login: `${rootConfig.apiRoot}/login/`,
  resetPassword: `${rootConfig.apiRoot}/resetPassword/`,
  forgetPassword: `${rootConfig.apiRoot}/forgetPassword/`,

  personalDetails: `${rootConfig.apiRoot}/personalDetails/`,
  OtpValidation: `${rootConfig.apiRoot}/OtpValidation/`,
  fileUploadApi: `${rootConfig.apiRoot}/fileUpload/`,
  fileUpdate: `${rootConfig.apiRoot}/multipleFileUpdate/`,
  fileUpload: `${rootConfig.apiRoot}/FileUploadApi/`,
  multifileupload: `${rootConfig.apiRoot}/multipleFileUploadApi/`,
  uplaodFiles: `${rootConfig.apiRoot}/healthFile/`,

  userData: `${rootConfig.apiRoot}/userMetaData/`,

  addUser: `${rootConfig.apiRoot}/user/`,
  addBulkUsers: `${rootConfig.apiRoot}/addBulkUser/`,
  userDetails: `${rootConfig.apiRoot}/userDetail/`,
  addVideo: `${rootConfig.apiRoot}/video/`,
  addCollection: `${rootConfig.apiRoot}/collection/`,
  videoInCollection: `${rootConfig.apiRoot}/videoInCollection/`,
  videoSearch: `${rootConfig.apiRoot}/videoSearch/`,

  playerMealPlanList: `${rootConfig.apiRoot}/playerMealPlanList/`,
  playerWaterUpdate: `${rootConfig.apiRoot}/playerWaterUpdate/`,
  playerMealUpdate: `${rootConfig.apiRoot}/playerMealUpdate/`,
  mealUndoneUpdate: `${rootConfig.apiRoot}/mealUndoneUpdate/`,
  previousMeditationCheck: `${rootConfig.apiRoot}/previousMeditationCheck/`,

  DeleteBasicMeditation: `${rootConfig.apiRoot}/DeleteBasicMeditation/`,
  DeleteGuidedMeditation: `${rootConfig.apiRoot}/DeleteGuidedMeditation/`,
  DeleteExpertMeditation: `${rootConfig.apiRoot}/DeleteExpertMeditation/`,

  addBasicMeditatation: `${rootConfig.apiRoot}/addBasicMeditatation/`,
  AddGuidedMeditatation: `${rootConfig.apiRoot}/AddGuidedMeditatation/`,
  AddExpertMeditatation: `${rootConfig.apiRoot}/AddExpertMeditatation/`,

  EditBasicMeditation: `${rootConfig.apiRoot}/EditBasicMeditation/`,
  EditGuidedMeditatation: `${rootConfig.apiRoot}/EditGuidedMeditatation/`,
  EditExpertMeditatation: `${rootConfig.apiRoot}/EditExpertMeditatation/`,

  previousSleepCheck: `${rootConfig.apiRoot}/previousSleepCheck/`,
  addSleep: `${rootConfig.apiRoot}/addSleep/`,
  deleteSleep: `${rootConfig.apiRoot}/deleteSleep/`,

  playerDashBoardNutrition: `${rootConfig.apiRoot}/playerDashBoardNutrition/`,
  playerDashBoardHydration: `${rootConfig.apiRoot}/playerDashBoardHydration/`,

  coachPlayerList: `${rootConfig.apiRoot}/playerList/`,

  coachSkillFeed: `${rootConfig.apiRoot}/coachSkillFeed/`,
  commentList: `${rootConfig.apiRoot}/commentList/`,
  videoDetail: `${rootConfig.apiRoot}/videoDetail/`,
  teamVideoSearch: `${rootConfig.apiRoot}/teamVideoSearch/`,
  addComment: `${rootConfig.apiRoot}/comment/`,
  groupList: `${rootConfig.apiRoot}/group/`,
  createExercise: `${rootConfig.apiRoot}/exercise/`,
  searchPlayers: `${rootConfig.apiRoot}/playerSearch/`,
  groupAdd: `${rootConfig.apiRoot}/group/`,
  exerciseDetail: `${rootConfig.apiRoot}/exerciseDetail/`,
  workOutSearch: `${rootConfig.apiRoot}/workOutSearch/`,
  createWorkout: `${rootConfig.apiRoot}/workout/`,
  exerciseSearch: `${rootConfig.apiRoot}/exerciseSearch/`,
  createPlanner: `${rootConfig.apiRoot}/planner/`,
  searchGroup: `${rootConfig.apiRoot}/searchGroup/`,
  addSession: `${rootConfig.apiRoot}/session/`,
  coachStrengthFeed: `${rootConfig.apiRoot}/coachStrengthFeed/`,
  groupDetails: `${rootConfig.apiRoot}/groupDetails/`,
  plannerSearch: `${rootConfig.apiRoot}/plannerSearch/`,
  copyScheduleByDay: `${rootConfig.apiRoot}/copyScheduleByDay/`,
  copyScheduleByWeek: `${rootConfig.apiRoot}/copyScheduleByWeek/`,
  clearScheduleByDay: `${rootConfig.apiRoot}/clearScheduleByDay/`,
  clearScheduleByWeek: `${rootConfig.apiRoot}/clearScheduleByWeek/`,
  mealPlanList: `${rootConfig.apiRoot}/mealPlanList/`,
  mealPlanSearch: `${rootConfig.apiRoot}/mealPlanSearch/`,
  coachStrengthFeedDetails: `${rootConfig.apiRoot}/coachFeedDetails/`,
  plannerPlayerList: `${rootConfig.apiRoot}/plannerPlayerList/`,
  coachMealFeed: `${rootConfig.apiRoot}/nutritionRecentFeed/`,
  addPlayerMealPlan: `${rootConfig.apiRoot}/addPlayerMealPlan/`,
  playerMealPlanDetail: `${rootConfig.apiRoot}/playerMealPlanDetail/`,
  createMealPlan: `${rootConfig.apiRoot}/createMealPlan/`,
  deleteMealPlan: `${rootConfig.apiRoot}/deleteMealPlan/`,
  mealPlanEdit: `${rootConfig.apiRoot}/mealPlanEdit/`,

  copyMealPlannerByDay: `${rootConfig.apiRoot}/copyMealPlannerByDay/`,
  clearPlayerMealPlan: `${rootConfig.apiRoot}/clearPlayerMealPlan/`,
  copyMealPlannerByWeek: `${rootConfig.apiRoot}/copyMealPlannerByWeek/`,
  plannerPlayerSearch: `${rootConfig.apiRoot}/plannerPlayerSearch/`,

  coachMindsetFeed: `${rootConfig.apiRoot}/CoachMindsetFeed/`,
  moodDetails: `${rootConfig.apiRoot}/mindSetDetails/`,
  moodStats: `${rootConfig.apiRoot}/listMindset/`,
  sleepDetails: `${rootConfig.apiRoot}/sleepDetails/`,
  listSleep: `${rootConfig.apiRoot}/listSleep/`,
  listMeditation: `${rootConfig.apiRoot}/listMeditation/`,
  coachplayerStrengthFeed: `${rootConfig.apiRoot}/coachplayerStrengthFeed/`,
  PlayerVideoList: `${rootConfig.apiRoot}/playerVideos/`,
  coachNutritionPlayerFeed: `${rootConfig.apiRoot}/coachNutritionPlayerFeed/`,
  PlayerMindsetFeed: `${rootConfig.apiRoot}/PlayerMindsetFeed/`,
  removeFromCollection: `${rootConfig.apiRoot}/removeFromCollection/`,
  addMindSet: `${rootConfig.apiRoot}/addMindSet/`,
  mindSet: `${rootConfig.apiRoot}/mindSetTodaycheck/`,
  deleteMindset: `${rootConfig.apiRoot}/deleteMindset/`,
  sessionList: `${rootConfig.apiRoot}/sessionList/`,
  exerciseInWorkout: `${rootConfig.apiRoot}/exerciseInWorkout/`,
  workoutExerciseDetails: `${rootConfig.apiRoot}/workoutExerciseDetails/`,
  updateExercise: `${rootConfig.apiRoot}/updateExercise/`,

  playerDashBoardReadiness: `${rootConfig.apiRoot}/playerDashBoardReadiness/`,
  playerDashBoardStrength: `${rootConfig.apiRoot}/playerDashBoardStrength/`,

  coachDashBoardReadiness: `${rootConfig.apiRoot}/coachDashBoardReadiness/`,
  coachDashBoardStrength: `${rootConfig.apiRoot}/coachDashBoardStrength/`,
  coachDashBoardSleep: `${rootConfig.apiRoot}/ListTeamSleep/`,
  coachDashBoardNutrition: `${rootConfig.apiRoot}/CoachDashBoardAverageNutrition/`,
  coachDashBoardHydration: `${rootConfig.apiRoot}/coachDashBoardHydration/`,
  coachDashBoardStillness: `${rootConfig.apiRoot}/coachDashBoardStillness/`,

  helpAndSupport: `${rootConfig.apiRoot}/helpAndSupport/`,
  helpAndSupportSearch: `${rootConfig.apiRoot}/helpAndSupportSearch/`,
  adminDashBoard: `${rootConfig.apiRoot}/AdminDashBoardDetails/`,
  duplicateMealPlan: `${rootConfig.apiRoot}/cloneMealPlan/`,
  coachStrengthRecent: `${rootConfig.apiRoot}/coachDayFeed/`,
  coachNutritionRecent: `${rootConfig.apiRoot}/CoachFeedNutrition/`,
  coachMindsetRecent: `${rootConfig.apiRoot}/CoachMoodFeed/`,
  coachDashBoardAverageHydration: `${rootConfig.apiRoot}/CoachDashBoardAverageHydration/`,
  coachDashBoardTeamStillness: `${rootConfig.apiRoot}/CoachDashBoardTeamStillness/`,
  nutritionDetails: `${rootConfig.apiRoot}/nutritionDetails/`,

  searchGroupList: `${rootConfig.apiRoot}/SearchGroupList/`,
  searchWorkOutList: `${rootConfig.apiRoot}/WorkOutSearchList/`,
  searchExerciseList: `${rootConfig.apiRoot}/ExerciseListApi/`
};
