/* eslint-disable no-unused-vars */
/*
 * reducer.js file for details view
 * Author:
 * 1.Manikanta
 *
 * @copyright of Panorbit Services LLP.
 */
import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  loading: false,
  error: null,
  data: '',
  injuries: [{ file: '', description: '' }],
  health: [{ file: '', description: '' }],
  mealPreference: '',
  foodAllergy: '',
  practiceStartTime: '',
  practiceEndTime: '',
  trainingStartTime: '',
  trainingEndTime: '',
  practiceStartTimeMeredian: 'AM',
  practiceEndTimeMeredian: 'AM',
  trainingStartTimeMeredian: 'PM',
  trainingEndTimeMeredian: 'PM'
};

const setProfessionalDetails = (state = INITIAL_STATE, action) => ({ ...state, loading: true, ...action.payload });
const resetProfessionalDetails = (state = INITIAL_STATE) => ({ ...INITIAL_STATE });

const HANDLERS = {
  [Types.SET_PROFESSIONAL_DETAILS]: setProfessionalDetails,
  [Types.RESET_PROFESSIONAL_DETAILS]: resetProfessionalDetails
};

export const professionalDetailsReducer = createReducer(INITIAL_STATE, HANDLERS);
