/*
 * reducer.js file for details view
 * Author:
 * 1.Manikanta
 *
 * @copyright of Panorbit Services LLP.
 */
/* eslint-disable no-unused-vars */
import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  loading: false,
  error: null,
  data: '',
  fName: '',
  lName: '',
  email: '',
  phoneNumber: '',
  age: '',
  gender: '',
  weight: '',
  feet: '',
  inches: '',
  level: '',
  about: '',
  goals: '',
  trainingAge: ''
};

const setPersonalDetails = (state = INITIAL_STATE, action) => {
  const { payload } = action;

  return {
    ...state,
    ...payload
  };
};
const resetPersonalDetails = (state = INITIAL_STATE) => ({ ...INITIAL_STATE });

const HANDLERS = {
  [Types.SET_PERSONAL_DETAILS]: setPersonalDetails,
  [Types.RESET_PERSONAL_DETAILS]: resetPersonalDetails
};

export const PersonalDetailsReducer = createReducer(INITIAL_STATE, HANDLERS);
