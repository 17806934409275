/* eslint-disable no-console */
/* eslint-disable no-shadow */
/* eslint-disable no-undef */
/*
 * saga.js file for skills view
 * Author:
 * 1. manikanta
 * @copyright of Panorbit Services LLP.
 */

import { put } from 'redux-saga/effects';
import { Creators } from './action';

import { WebService } from '../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../services/axios/endPoints';

export function* getPlayerVideosListSaga({ payload }) {
  const { _id, page, count } = payload;

  try {
    yield put(Creators.getPlayerVideosListStart());
    const fullURL = yield `${apiEndPoints.addVideo}`;

    const response = yield WebService.get(fullURL, { params: { _id, page, count } });

    if (response.status >= 200 && response.status < 300) {
      const { data, message } = response.data;
      yield put(
        Creators.getPlayerVideosListSuccess({
          videosList: message === 'No Data' ? [] : data[0],
          maxPage: data[1].page_count,
          totalCount: data[2].totalCount
        })
      );
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getPlayerVideosListFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getPlayerVideosListFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getPlayerVideosListFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getPlayerVideosListFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getPlayerVideosListFailure(payload));
    }
  }
}

export function* getPlayerCollectionsListSaga() {
  try {
    yield put(Creators.getPlayerCollectionsListStart());
    const fullURL = yield `${apiEndPoints.addCollection}`;
    yield setTimeout(() => {
      console.log(1);
    }, 3000);

    const response = yield WebService.get(fullURL);

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      yield put(Creators.getPlayerCollectionsListSuccess({ collectionsList: data }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getPlayerCollectionsListFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getPlayerCollectionsListFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getPlayerCollectionsListFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getPlayerCollectionsListFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getPlayerCollectionsListFailure(payload));
    }
  }
}

export function* getPlayerCollectionVideosListSaga({ payload }) {
  const { _id, page, count } = payload;

  try {
    yield put(Creators.getPlayerCollectionVideosListStart());
    const fullURL = yield `${apiEndPoints.addVideo}`;

    const response = yield WebService.get(fullURL, { params: { _id, page, count } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      yield put(Creators.getPlayerCollectionVideosListSuccess({ collectionVideos: data }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getPlayerCollectionVideosListFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getPlayerCollectionVideosListFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getPlayerCollectionVideosListFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getPlayerCollectionVideosListFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getPlayerCollectionVideosListFailure(payload));
    }
  }
}

export function* searchPlayerVideoSaga({ payload }) {
  const { searchKey, page, count, _id } = payload;

  try {
    yield put(Creators.searchPlayerVideoStart());
    const fullURL = yield `${apiEndPoints.videoSearch}`;
    const response = yield WebService.get(fullURL, { params: { searchKey, page, count, _id } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      // console.log(data);
      yield put(
        Creators.searchPlayerVideoSuccess({
          videosList: typeof data === 'object' ? data[0] : [],
          maxPage: data[1].page_count || 0,
          totalCount: data[2].totalCount || 0
        })
      );
    } else {
      throw response;
    }
  } catch (error) {
    console.log(error);

    let payload;
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.searchPlayerVideoFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.searchPlayerVideoFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.searchPlayerVideoFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update.Try again later'
          };
          yield put(Creators.searchPlayerVideoFailure(payload));
      }
    } else {
      payload = {
        error: error.message
      };

      yield put(Creators.searchPlayerVideoFailure(payload));
    }
  }
}

export function* setPlayerCollectionNameSaga({ payload }) {
  const { data } = payload;

  try {
    yield put(Creators.setPlayerCollectionNameStart());
    const fullURL = yield `${apiEndPoints.addCollection}`;
    let METHOD = 'POST';

    const dataToApi = new FormData();

    dataToApi.append('name', data.name);

    if (data._id) {
      METHOD = 'PUT';
      dataToApi.append('_id', data._id);
    }

    const response = yield WebService({
      method: METHOD,
      url: fullURL,
      data: dataToApi
    });

    if (response.status >= 200 && response.status < 300) {
      const { message } = response.data;
      yield put(Creators.setPlayerCollectionNameSuccess({ message }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.setPlayerCollectionNameFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.setPlayerCollectionNameFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.setPlayerCollectionNameFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.setPlayerCollectionNameFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.setPlayerCollectionNameFailure(payload));
    }
  }
}

export function* deletePlayerCollectionSaga({ payload }) {
  const { _id, deleteCommand } = payload;
  // console.log(payload);

  try {
    yield put(Creators.deletePlayerCollectionStart());
    const fullURL = yield `${apiEndPoints.addCollection}`;

    const response = yield WebService({
      method: 'DELETE',
      url: fullURL,
      data: { _id, deleteCommand }
    });

    if (response.status >= 200 && response.status < 300) {
      const { message } = response.data;
      yield put(Creators.deletePlayerCollectionSuccess({ message }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.deletePlayerCollectionFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.deletePlayerCollectionFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.deletePlayerCollectionFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.deletePlayerCollectionFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.deletePlayerCollectionFailure(payload));
    }
  }
}

export function* addToAnotherCollectionSaga({ payload }) {
  let _id;
  let access;

  let videoId;
  let collectionName;
  let collectionId;

  if (payload._id) {
    _id = payload._id;
    access = payload.access;
  } else {
    videoId = payload.addVideoToCollection.videoId;
    collectionName = payload.addVideoToCollection.collectionName;
    collectionId = payload.addVideoToCollection.collectionId;
  }

  try {
    yield put(Creators.addToAnotherCollectionStart());
    const fullURL = yield `${apiEndPoints.addVideo}`;
    const METHOD = 'PUT';
    let response;

    if (access) {
      response = yield WebService({
        method: METHOD,
        url: fullURL,
        data: { _id, access }
      });
    } else {
      response = yield WebService({
        method: METHOD,
        url: fullURL,
        data: { _id: videoId, collectionName, collectionId }
      });
    }

    if (response.status >= 200 && response.status < 300) {
      const { message } = response.data;
      yield put(Creators.addToAnotherCollectionSuccess({ message }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.addToAnotherCollectionFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.addToAnotherCollectionFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.addToAnotherCollectionFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.addToAnotherCollectionFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.addToAnotherCollectionFailure(payload));
    }
  }
}

export function* uploadVideoSaga({ payload }) {
  const { title, addCollection, newCollection, collectionName, collectionId, access, duration, file } = payload;
  try {
    yield put(Creators.uploadVideoStart());
    let newId = '';

    // create collection

    if (addCollection) {
      const fullURL0 = yield `${apiEndPoints.addCollection}`;
      const response = yield WebService({
        method: 'POST',
        url: fullURL0,
        data: { name: newCollection }
      });

      if (response.status >= 200 && response.status < 300) {
        const { data } = response.data;
        newId = data._id;
        console.log(data._id);
      } else {
        throw response;
      }
    }

    // upload video
    const fullURL1 = yield `${apiEndPoints.addVideo}`;

    const formdata = new FormData();
    formdata.append('title', title);
    formdata.append('collectionName', collectionName || newCollection);
    formdata.append('collectionId', collectionId || newId);
    formdata.append('access', access ? 'Private' : 'Public');
    formdata.append('duration', duration);
    formdata.append('videoFile', file);

    const response = yield WebService({
      method: 'POST',
      url: fullURL1,
      data: formdata
    });

    if (response.status >= 200 && response.status < 300) {
      const { message } = response.data;
      yield put(Creators.uploadVideoSuccess({ message }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.uploadVideoFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.uploadVideoFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.uploadVideoFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.uploadVideoFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.uploadVideoFailure(payload));
    }
  }
}
