import { put } from 'redux-saga/effects';
import { Creators } from './action';

import { WebService } from '../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../services/axios/endPoints';

export function* getCoachHomeReadinessSaga({ payload }) {
  const { dataType } = payload;

  try {
    yield put(Creators.getCoachHomeReadinessStart());

    const fullURL = yield `${apiEndPoints.coachDashBoardReadiness}`;
    const response = yield WebService.get(fullURL, { params: { dataType } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(Creators.getCoachHomeReadinessSuccess({ readiness: { current: data[0], previous: data[1] } }));
    } else {
      throw response;
    }
  } catch (error) {
    // console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getCoachHomeReadinessFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getCoachHomeReadinessFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getCoachHomeReadinessFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getCoachHomeReadinessFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getCoachHomeReadinessFailure(payload));
    }
  }
}

export function* getCoachHomeStrengthSaga({ payload }) {
  const { dataType } = payload;

  try {
    yield put(Creators.getCoachHomeStrengthStart());

    const fullURL = yield `${apiEndPoints.coachDashBoardStrength}`;
    const response = yield WebService.get(fullURL, { params: { dataType } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      yield put(Creators.getCoachHomeStrengthSuccess({ strength: { current: data[0], previous: data[1] } }));
    } else {
      throw response;
    }
  } catch (error) {
    // console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getCoachHomeStrengthFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getCoachHomeStrengthFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getCoachHomeStrengthFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getCoachHomeStrengthFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getCoachHomeStrengthFailure(payload));
    }
  }
}

export function* getCoachHomeSleepSaga({ payload }) {
  const { dataType, days } = payload;

  try {
    yield put(Creators.getCoachHomeSleepStart());

    const fullURL = yield `${apiEndPoints.coachDashBoardSleep}`;
    const response = yield WebService.get(fullURL, { params: { dataType, days } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(Creators.getCoachHomeSleepSuccess({ sleepStats: data }));
    } else {
      throw response;
    }
  } catch (error) {
    // console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getCoachHomeSleepFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getCoachHomeSleepFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getCoachHomeSleepFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getCoachHomeSleepFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getCoachHomeSleepFailure(payload));
    }
  }
}

export function* getCoachHomeNutritionSaga({ payload }) {
  const { dataType, days } = payload;

  try {
    yield put(Creators.getCoachHomeNutritionStart());

    const fullURL = yield `${apiEndPoints.coachDashBoardNutrition}`;
    const response = yield WebService.get(fullURL, { params: { dataType, days } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(Creators.getCoachHomeNutritionSuccess({ nutritionStats: data }));
    } else {
      throw response;
    }
  } catch (error) {
    // console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getCoachHomeNutritionFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getCoachHomeNutritionFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getCoachHomeNutritionFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getCoachHomeNutritionFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getCoachHomeNutritionFailure(payload));
    }
  }
}

export function* getCoachHomeHydrationSaga({ payload }) {
  const { dataType, days } = payload;

  try {
    yield put(Creators.getCoachHomeHydrationStart());

    const fullURL = yield `${apiEndPoints.coachDashBoardAverageHydration}`;
    const response = yield WebService.get(fullURL, { params: { dataType, days } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(Creators.getCoachHomeHydrationSuccess({ hydrationStats: data }));
    } else {
      throw response;
    }
  } catch (error) {
    // console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getCoachHomeHydrationFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getCoachHomeHydrationFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getCoachHomeHydrationFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getCoachHomeHydrationFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getCoachHomeHydrationFailure(payload));
    }
  }
}

export function* getCoachHomeStillnessSaga({ payload }) {
  const { dataType, days } = payload;

  try {
    yield put(Creators.getCoachHomeStillnessStart());

    const fullURL = yield `${apiEndPoints.coachDashBoardTeamStillness}`;
    const response = yield WebService.get(fullURL, { params: { dataType, days } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(Creators.getCoachHomeStillnessSuccess({ stillnessStats: data }));
    } else {
      throw response;
    }
  } catch (error) {
    // console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getCoachHomeStillnessFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getCoachHomeStillnessFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getCoachHomeStillnessFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getCoachHomeStillnessFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getCoachHomeStillnessFailure(payload));
    }
  }
}
