/* eslint-disable no-confusing-arrow */
import styled from 'styled-components';
import { borderColor, fontWeight } from '../../../shared/constants';

export const ScrollContainer = styled.div`
  padding-right: 1rem;
`;

export const StatsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  border: 1px solid ${borderColor.light};
  border-radius: 6px;
  padding: 18px 11px !important;
  margin-top: 15px;
`;

export const StatsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const MoodCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 5px;
  padding: 2px 6px;
  background: white;
  border-radius: 9px;

  font-size: 12px;
  font-weight: ${fontWeight.medium};
  color: #1c162d;

  background: ${(props) => props.delightful && '#70C54D'};
  background: ${(props) => props.happy && '#1EA79C'};
  background: ${(props) => props.good && '#E6D544'};
  background: ${(props) => props.sad && '#EC8541'};
  background: ${(props) => props.tired && '#DF4A37'};
  background: ${(props) => props.awful && '#E23433'};
`;

// mood filter
export const FilterMood = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin: 2rem 0 1.2rem;
`;

export const Filter = styled.div`
  border-radius: 13px;
  padding: 5px 15px;
  background: #c4c4c41a;
  margin-bottom: 12px;

  cursor: pointer;
  border: 1px solid ${(props) => (props.active === true ? '#B5B7BC' : 'transparent')};

  & > p {
    display: inline-block;
    font-size: 12px;
    font-weight: ${fontWeight.medium};
    text-transform: capitalize;
  }
`;

// mood details accordion
export const DayContainer = styled.div`
  border: 1px solid ${borderColor.light};
  border-radius: 6px;
  padding: 12px 18px !important;

  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 1rem;

  &:hover {
    cursor: pointer;
  }
`;

export const Rotate = styled.div`
  & > svg {
    transform: rotateX(180deg);
  }
`;

export const DayStatsHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DayStatsWrapper = styled.div`
  display: flex;
  gap: 2.5rem;
`;

export const DayStats = styled.div`
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
`;

export const Day = styled.p`
  display: inline-block;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: ${fontWeight.medium};
  color: #ffffff;
  opacity: 0.9;
`;

export const MoodDate = styled.p`
  display: inline-block;
  font-size: 14px;
  font-weight: ${fontWeight.medium};
  color: #ffffff;
  opacity: 0.5;
`;

export const DayMood = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

export const MoodDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${(props) => props.delightful && '#70C54D'};
  background: ${(props) => props.happy && '#1EA79C'};
  background: ${(props) => props.good && '#E6D544'};
  background: ${(props) => props.sad && '#EC8541'};
  background: ${(props) => props.tired && '#DF4A37'};
  background: ${(props) => props.awful && '#E23433'};
`;
