/*
 * sagas.js file for store
 * Author:
 * 1. Manikanta
 * @copyright of Panorbit Services LLP.
 */

import { takeLatest } from 'redux-saga/effects';
import { Types } from './actions';

//  import sagas here
import { loginSaga, userMetaDataSaga, logoutSaga, getUserDataSaga } from '../views/auth/LoginView/store';
import { createPasswordSaga } from '../views/auth/CreatePassword/store';
import { verifyEmailSaga } from '../views/auth/ForgotPasswordView/store';
import { setUserMetaSaga, fileUploadSaga } from '../views/PlayerDetailsView/store';
import { verifyOtpSaga } from '../views/auth/OtpView/store';
import {
  getAdminUsersListSaga,
  setAdminUsersListSaga,
  adminDeleteUserSaga,
  setAdminBulkUsersListSaga,
  adminUpdateUserDetailsSaga,
  getAdminUserDetailsSaga
} from '../views/admin/UsersView/store';

// uesrType:player sagas
import {
  getPlayerVideosListSaga,
  getPlayerCollectionsListSaga,
  getPlayerCollectionVideosListSaga,
  searchPlayerVideoSaga,
  setPlayerCollectionNameSaga,
  deletePlayerCollectionSaga,
  addToAnotherCollectionSaga,
  uploadVideoSaga
} from '../views/Player/SkillsView/store';

import {
  getPlayerVideoDetailsSaga,
  getPlayerVideoCommentsListSaga,
  addPlayerVideoCommentSaga,
  removeFromCollectionSaga,
  editPlayerVideoSaga,
  deletePlayerVideoSaga
} from '../views/Player/VideoplayerView/store';

import {
  getDaySessionListSaga,
  getAvailableSessionsSaga,
  getSessionWorkoutsSaga,
  setSessionStatusSaga,
  getWorkoutExerciseDetailsSaga,
  setPlayerExerciseSaga,
  getPlayerSessionCommentsSaga
} from '../views/Player/StrengthView/store';
// userType: Player Nutrition sagas
import {
  getPlayerMealPlanList,
  setPlayerWaterUpdate,
  getPlayerIntakeSummaryCommentsSaga,
  getPlayerMealCommentsSaga,
  setPlayerMealUpdateSaga,
  setPlayerMealUndoneUpdateSaga
} from '../views/Player/NutritionView/store';

// userType: Player Mindset sagas
import {
  getPlayerMoodStats,
  getPlayerSleepStatsSaga,
  getPlayerMeditationStatsSaga,
  getPlayerTodaysMeditationCheckSaga,
  addPlayerMeditationCommentSaga,
  getPlayerMeditationTrackCommentsSaga,
  deletePlayerTodaysMeditationCheckSaga,
  setPlayerMeditationDurationSaga,
  editPlayerMeditationDurationSaga,
  getPlayerTodaysSleepCheckSaga,
  addPlayerSleepSaga,
  deletePlayerSleepSaga,
  getPlayerSleepTrackCommentsSaga
} from '../views/Player/MindsetView/store';

// userType: Coach sags
import {
  getCoachPlayerList,
  getCoachPlayerDetailsSaga,
  SearchCoachPlayersSaga
} from '../views/Coach/PlayersView/store';
import { getCoachFeedList, getCoachFeedCommentsSaga } from '../views/Coach/SkillsVIew/store';
import { getVideoDetailsSaga, getVideoCommentsListSaga } from '../views/Coach/VideoplayerView/store';
import { getTeamVideoSearchListSaga } from '../views/Coach/SearchVideosView/store';

import {
  getCoachExerciseListSaga,
  setCoachExerciseSaga,
  deleteCoachExerciseSaga,
  getExerciseDetailsSaga,
  searchCoachExerciseSaga,
  searchExerciseSaga
} from '../views/Coach/ExerciseView/store';
import { getGroupPlayersSearchListSaga, getAddGroupSaga, editDeleteGroupSaga } from '../views/Coach/RestPlayers/store';
import {
  getCoachWorkoutListSaga,
  createWorkoutSaga,
  deleteCoachWorkoutSaga,
  searchWorkoutSaga,
  searchWorkoutListSaga
} from '../views/Coach/Workoutsview/store';

import {
  createPlannerSaga,
  deleteCoachPlannerSaga,
  getCoachPlannerListSaga,
  searchCoachPlannerSaga
} from '../views/Coach/PlannerView/store';

import {
  getPlannerDetailsSaga,
  getGroupDetailsSaga,
  createPlannerSessionSaga,
  copyScheduleByDaySaga,
  copyScheduleByWeekSaga,
  clearScheduleByDaySaga,
  clearScheduleByWeekSaga,
  deleteSessionSaga
} from '../views/Coach/PlannerDetailsView/store';

import { getCoachStrengthFeed } from '../views/Coach/StrengthViews/RecentView/store';
import {
  getCoachStrengthFeedDetails,
  getSessionCommentsSaga
} from '../views/Coach/StrengthViews/RecentView/SessionDetails/store';

// Coach Nutrition sagas
import {
  getCoachMealPlanListSaga,
  createMealPlanSaga,
  deleteCoachMealPlanSaga,
  searchCoachMealPlanSaga
} from '../views/Coach/MealPlansView/store';

import {
  searchCoachMealPlannerSaga,
  getCoachMealPlannerListSaga,
  deleteCoachMealPlannerSaga
} from '../views/Coach/MealPlannerView/store';

import {
  getCoachMealFeedList,
  getCoachMealFeedCommentsSaga,
  getCoachIntakeSummaryCommentsSaga,
  addCoachIntakeSummaryCommentSaga,
  getCoachMealDetailsSaga
} from '../views/Coach/MealRecentView/store';

import { groupListSaga, searchGroupListSaga, searchGroupSaga } from '../views/Coach/GroupView/store';

import {
  getMealPlanDetailsSaga,
  copyMealPlannerByDaySaga,
  clearPlayerMealPlanSaga,
  copyMealPlannerByWeekSaga,
  addPlayerMealPlanSaga
} from '../views/Coach/MealPlannerDetailView/store';

import { updatePersonalDetailsSaga, resetPasswordSaga } from '../views/ProfileModal/store';

// Coach Mindset sagas
import {
  getCoachMindsetFeedList,
  getCoachMindsetFeedCommentsSaga,
  getCoachMoodCommentsSaga,
  getCoachSleepCommentsSaga,
  getCoachMeditationCommentsSaga,
  getCoachMoodDetails,
  getCoachMoodStats,
  getCoachMeditationDetailsSaga,
  getCoachMeditationStatsSaga,
  getCoachSleepDetails,
  getCoachSleepStatsSaga
} from '../views/Coach/MindsetView/store';
import { getPlayerTeamVideoSaga } from '../views/Player/AddVideosView/store';
import {
  deletePlayerMoodSaga,
  getPlayerMoodCommentsSaga,
  getPlayerMoodListSaga,
  setPlayerMoodSaga
} from '../views/Player/MindsetView/MoodStore';

import {
  getPlayerReadinessSaga,
  getPlayerHomeStrengthSaga,
  getPlayerHomeNutritionSaga,
  getPlayerHomeHydrationSaga,
  getPlayerHomeMoodStats
} from '../views/Player/HomeView/store';

import {
  deleteFileSaga,
  updateFileSaga,
  updatePlayerPersonalDetailsSaga,
  updatePlayerProfessionalDetails
} from '../views/Player/ProfileModal/store';

// coach Home sagas
import {
  getCoachHomeReadinessSaga,
  getCoachHomeStrengthSaga,
  getCoachHomeSleepSaga,
  getCoachHomeNutritionSaga,
  getCoachHomeHydrationSaga,
  getCoachHomeStillnessSaga
} from '../views/Coach/HomeView/store';

import {
  getPlayerDashboardReadinessSaga,
  getPlayerDashboardStrengthSaga,
  getPlayerDashboardSleepStatsSaga,
  getPlayerDashboardNutritionSaga,
  getPlayerDashboardHydrationSaga,
  getPlayerDashboardMoodStats
} from '../views/Coach/PlayerDashboardView/store';

import { updateCoachPersonalDetailsSaga, resetCoachPasswordSaga } from '../views/Coach/CoachProfile/store';
import { getAdminHomeDetailsSaga } from '../views/admin/HomeView/store';

// Help and Support sagas
import {
  getHelpAndSupportListSaga,
  getAdminHelpAndSupportListSaga,
  getCoachHelpAndSupportListSaga,
  getPlayerHelpAndSupportListSaga,
  searchHelpAndSupportSaga
} from '../views/HelpAndSupport/store';
import { addCommentSaga } from '../views/store';
import { duplicateCoachMealPlanSaga } from '../views/Coach/MealPlansView/store/saga';

//
export default function* watchAuth() {
  yield takeLatest(Types.LOGIN, loginSaga);
  yield takeLatest(Types.LOGOUT, logoutSaga);
  yield takeLatest(Types.CREATE_PASSWORD, createPasswordSaga);
  yield takeLatest(Types.VERIFY_EMAIL, verifyEmailSaga);
  yield takeLatest(Types.SET_USER_META, setUserMetaSaga);
  yield takeLatest(Types.VERIFY_OTP, verifyOtpSaga);
  yield takeLatest(Types.FILE_UPLOAD, fileUploadSaga);
  yield takeLatest(Types.USER_META_DATA, userMetaDataSaga);
  yield takeLatest(Types.GET_ADMIN_USERS_LIST, getAdminUsersListSaga);
  yield takeLatest(Types.SET_ADMIN_USERS_LIST, setAdminUsersListSaga);
  yield takeLatest(Types.ADMIN_DELETE_USER, adminDeleteUserSaga);
  yield takeLatest(Types.SET_ADMIN_BULK_USERS_LIST, setAdminBulkUsersListSaga);
  yield takeLatest(Types.ADMIN_UPDATE_USER_DETAILS, adminUpdateUserDetailsSaga);
  yield takeLatest(Types.GET_ADMIN_USER_DETAILS, getAdminUserDetailsSaga);
  yield takeLatest(Types.UPDATE_PERSONAL_DETAILS, updatePersonalDetailsSaga);
  yield takeLatest(Types.GET_USER_DATA, getUserDataSaga);
  yield takeLatest(Types.UPDATE_FILE, updateFileSaga);
  // player
  yield takeLatest(Types.GET_PLAYER_VIDEOS_LIST, getPlayerVideosListSaga);
  yield takeLatest(Types.GET_PLAYER_COLLECTIONS_LIST, getPlayerCollectionsListSaga);
  yield takeLatest(Types.GET_PLAYER_COLLECTION_VIDEOS_LIST, getPlayerCollectionVideosListSaga);
  yield takeLatest(Types.SEARCH_PLAYER_VIDEO, searchPlayerVideoSaga);
  yield takeLatest(Types.SET_PLAYER_COLLECTION_NAME, setPlayerCollectionNameSaga);
  yield takeLatest(Types.DELETE_PLAYER_COLLECTION, deletePlayerCollectionSaga);
  yield takeLatest(Types.ADD_TO_ANOTHER_COLLECTION, addToAnotherCollectionSaga);
  yield takeLatest(Types.GET_PLAYER_TEAM_VIDEO, getPlayerTeamVideoSaga);
  yield takeLatest(Types.GET_PLAYER_VIDEO_DETAILS, getPlayerVideoDetailsSaga);
  yield takeLatest(Types.GET_PLAYER_VIDEO_COMMENTS_LIST, getPlayerVideoCommentsListSaga);
  yield takeLatest(Types.ADD_PLAYER_VIDEO_COMMENT, addPlayerVideoCommentSaga);
  yield takeLatest(Types.REMOVE_FROM_COLLECTION, removeFromCollectionSaga);
  yield takeLatest(Types.EDIT_PLAYER_VIDEO, editPlayerVideoSaga);
  yield takeLatest(Types.DELETE_PLAYER_VIDEO, deletePlayerVideoSaga);
  yield takeLatest(Types.GET_DAY_SESSION_LIST, getDaySessionListSaga);
  yield takeLatest(Types.GET_AVAILABLE_SESSIONS, getAvailableSessionsSaga);
  yield takeLatest(Types.GET_SESSION_WORKOUTS, getSessionWorkoutsSaga);
  yield takeLatest(Types.SET_SESSION_STATUS, setSessionStatusSaga);
  yield takeLatest(Types.GET_WORKOUT_EXERCISE_DETAILS, getWorkoutExerciseDetailsSaga);
  yield takeLatest(Types.SET_PLAYER_EXERCISE, setPlayerExerciseSaga);

  yield takeLatest(Types.GET_PLAYER_SESSION_COMMENTS, getPlayerSessionCommentsSaga);
  yield takeLatest(Types.UPDATE_PLAYER_PERSONAL_DETAILS, updatePlayerPersonalDetailsSaga);
  yield takeLatest(Types.UPDATE_PLAYER_PROFESSIONAL_DETAILS, updatePlayerProfessionalDetails);
  yield takeLatest(Types.DELETE_FILE, deleteFileSaga);
  yield takeLatest(Types.UPLOAD_VIDEO, uploadVideoSaga);

  // player Home
  yield takeLatest(Types.GET_PLAYER_READINESS, getPlayerReadinessSaga);
  yield takeLatest(Types.GET_PLAYER_HOME_STRENGTH, getPlayerHomeStrengthSaga);
  yield takeLatest(Types.GET_PLAYER_HOME_NUTRITION, getPlayerHomeNutritionSaga);
  yield takeLatest(Types.GET_PLAYER_HOME_HYDRATION, getPlayerHomeHydrationSaga);
  yield takeLatest(Types.GET_PLAYER_HOME_MOOD_STATS, getPlayerHomeMoodStats);

  // Player Nutrition
  yield takeLatest(Types.GET_PLAYER_MEAL_PLAN_LIST, getPlayerMealPlanList);
  yield takeLatest(Types.SET_PLAYER_WATER_UPDATE, setPlayerWaterUpdate);
  yield takeLatest(Types.GET_PLAYER_INTAKE_SUMMARY_COMMENTS, getPlayerIntakeSummaryCommentsSaga);

  yield takeLatest(Types.GET_PLAYER_MEAL_COMMENTS, getPlayerMealCommentsSaga);

  yield takeLatest(Types.SET_PLAYER_MEAL_UPDATE, setPlayerMealUpdateSaga);
  yield takeLatest(Types.SET_PLAYER_MEAL_UNDONE_UPDATE, setPlayerMealUndoneUpdateSaga);

  // Player Mindset
  yield takeLatest(Types.GET_PLAYER_MOOD_STATS, getPlayerMoodStats);
  yield takeLatest(Types.GET_PLAYER_SLEEP_STATS, getPlayerSleepStatsSaga);
  yield takeLatest(Types.GET_PLAYER_MEDITATION_STATS, getPlayerMeditationStatsSaga);
  yield takeLatest(Types.GET_PLAYER_TODAYS_MEDITATION_CHECK, getPlayerTodaysMeditationCheckSaga);
  yield takeLatest(Types.ADD_PLAYER_MEDITATION_COMMENT, addPlayerMeditationCommentSaga);
  yield takeLatest(Types.GET_PLAYER_MEDITATION_TRACK_COMMENTS, getPlayerMeditationTrackCommentsSaga);
  yield takeLatest(Types.DELETE_PLAYER_TODAYS_MEDITATION_CHECK, deletePlayerTodaysMeditationCheckSaga);
  yield takeLatest(Types.SET_PLAYER_MEDITATION_DURATION, setPlayerMeditationDurationSaga);
  yield takeLatest(Types.EDIT_PLAYER_MEDITATION_DURATION, editPlayerMeditationDurationSaga);
  yield takeLatest(Types.SET_PLAYER_MOOD, setPlayerMoodSaga);
  yield takeLatest(Types.GET_PLAYER_MOOD_LIST, getPlayerMoodListSaga);
  yield takeLatest(Types.DELETE_PLAYER_MOOD, deletePlayerMoodSaga);
  yield takeLatest(Types.GET_PLAYER_TODAYS_SLEEP_CHECK, getPlayerTodaysSleepCheckSaga);
  yield takeLatest(Types.ADD_PLAYER_SLEEP, addPlayerSleepSaga);
  yield takeLatest(Types.DELETE_PLAYER_SLEEP, deletePlayerSleepSaga);

  yield takeLatest(Types.GET_PLAYER_SLEEP_TRACK_COMMENTS, getPlayerSleepTrackCommentsSaga);
  yield takeLatest(Types.GET_PLAYER_MOOD_COMMENTS, getPlayerMoodCommentsSaga);

  // coach
  yield takeLatest(Types.GET_COACH_PLAYER_LIST, getCoachPlayerList);
  yield takeLatest(Types.GET_COACH_FEED_LIST, getCoachFeedList);
  yield takeLatest(Types.GET_VIDEO_DETAILS, getVideoDetailsSaga);
  yield takeLatest(Types.GET_VIDEO_COMMENTS_LIST, getVideoCommentsListSaga);
  yield takeLatest(Types.GET_TEAM_VIDEO_SEARCH_LIST, getTeamVideoSearchListSaga);
  yield takeLatest(Types.DELETE_SESSION, deleteSessionSaga);
  yield takeLatest(Types.GET_COACH_FEED_COMMENTS, getCoachFeedCommentsSaga);

  yield takeLatest(Types.GROUP_LIST, groupListSaga);
  yield takeLatest(Types.GET_COACH_EXERCISE_LIST, getCoachExerciseListSaga);
  yield takeLatest(Types.GET_GROUP_PLAYERS_SEARCH_LIST, getGroupPlayersSearchListSaga);
  yield takeLatest(Types.GET_ADD_GROUP, getAddGroupSaga);
  yield takeLatest(Types.SET_COACH_EXERCISE, setCoachExerciseSaga);
  yield takeLatest(Types.DELETE_COACH_EXERCISE, deleteCoachExerciseSaga);
  yield takeLatest(Types.GET_EXERCISE_DETAILS, getExerciseDetailsSaga);
  yield takeLatest(Types.SEARCH_COACH_EXERCISE, searchCoachExerciseSaga);
  yield takeLatest(Types.GET_COACH_WORKOUT_LIST, getCoachWorkoutListSaga);
  yield takeLatest(Types.CREATE_WORKOUT, createWorkoutSaga);
  yield takeLatest(Types.DELETE_COACH_WORKOUT, deleteCoachWorkoutSaga);
  yield takeLatest(Types.CREATE_PLANNER, createPlannerSaga);
  yield takeLatest(Types.DELETE_COACH_PLANNER, deleteCoachPlannerSaga);
  yield takeLatest(Types.GET_COACH_PLANNER_LIST, getCoachPlannerListSaga);
  yield takeLatest(Types.SEARCH_COACH_PLANNER, searchCoachPlannerSaga);
  yield takeLatest(Types.SEARCH_GROUP, searchGroupSaga);
  yield takeLatest(Types.SEARCH_WORKOUT, searchWorkoutSaga);
  yield takeLatest(Types.GET_PLANNER_DETAILS, getPlannerDetailsSaga);
  yield takeLatest(Types.EDIT_DELETE_GROUP, editDeleteGroupSaga);
  yield takeLatest(Types.GET_COACH_STRENGTH_FEED, getCoachStrengthFeed);
  yield takeLatest(Types.GET_GROUP_DETAILS, getGroupDetailsSaga);
  yield takeLatest(Types.CREATE_PLANNER_SESSION, createPlannerSessionSaga);
  yield takeLatest(Types.COPY_SCHEDULE_BY_DAY, copyScheduleByDaySaga);
  yield takeLatest(Types.CLEAR_SCHEDULE_BY_DAY, clearScheduleByDaySaga);
  yield takeLatest(Types.COPY_SCHEDULE_BY_WEEK, copyScheduleByWeekSaga);
  yield takeLatest(Types.CLEAR_SCHEDULE_BY_WEEK, clearScheduleByWeekSaga);
  yield takeLatest(Types.GET_COACH_PLAYER_DETAILS, getCoachPlayerDetailsSaga);
  yield takeLatest(Types.SEARCH_COACH_PLAYERS, SearchCoachPlayersSaga);
  yield takeLatest(Types.UPDATE_COACH_PERSONAL_DETAILS, updateCoachPersonalDetailsSaga);
  yield takeLatest(Types.RESET_COACH_PASSWORD, resetCoachPasswordSaga);
  yield takeLatest(Types.GET_SESSION_COMMENTS, getSessionCommentsSaga);

  // coach Home
  yield takeLatest(Types.GET_COACH_HOME_READINESS, getCoachHomeReadinessSaga);
  yield takeLatest(Types.GET_COACH_HOME_STRENGTH, getCoachHomeStrengthSaga);
  yield takeLatest(Types.GET_COACH_HOME_SLEEP, getCoachHomeSleepSaga);
  yield takeLatest(Types.GET_COACH_HOME_NUTRITION, getCoachHomeNutritionSaga);
  yield takeLatest(Types.GET_COACH_HOME_HYDRATION, getCoachHomeHydrationSaga);
  yield takeLatest(Types.GET_COACH_HOME_STILLNESS, getCoachHomeStillnessSaga);

  // player Dashboard
  yield takeLatest(Types.GET_PLAYER_DASHBOARD_READINESS, getPlayerDashboardReadinessSaga);
  yield takeLatest(Types.GET_PLAYER_DASHBOARD_STRENGTH, getPlayerDashboardStrengthSaga);
  yield takeLatest(Types.GET_PLAYER_DASHBOARD_SLEEP_STATS, getPlayerDashboardSleepStatsSaga);
  yield takeLatest(Types.GET_PLAYER_DASHBOARD_NUTRITION, getPlayerDashboardNutritionSaga);
  yield takeLatest(Types.GET_PLAYER_DASHBOARD_HYDRATION, getPlayerDashboardHydrationSaga);
  yield takeLatest(Types.GET_PLAYER_DASHBOARD_MOOD_STATS, getPlayerDashboardMoodStats);

  // Coach Nutrition
  yield takeLatest(Types.GET_COACH_MEAL_PLAN_LIST, getCoachMealPlanListSaga);
  yield takeLatest(Types.CREATE_MEAL_PLAN, createMealPlanSaga);
  yield takeLatest(Types.DELETE_COACH_MEAL_PLAN, deleteCoachMealPlanSaga);
  yield takeLatest(Types.SEARCH_COACH_MEAL_PLAN, searchCoachMealPlanSaga);
  yield takeLatest(Types.GET_COACH_STRENGTH_FEED_DETAILS, getCoachStrengthFeedDetails);

  yield takeLatest(Types.SEARCH_COACH_MEAL_PLANNER, searchCoachMealPlannerSaga);
  yield takeLatest(Types.GET_COACH_MEAL_PLANNER_LIST, getCoachMealPlannerListSaga);
  yield takeLatest(Types.DELETE_COACH_MEAL_PLANNER, deleteCoachMealPlannerSaga);

  yield takeLatest(Types.GET_COACH_MEAL_FEED_LIST, getCoachMealFeedList);
  yield takeLatest(Types.GET_COACH_MEAL_FEED_COMMENTS, getCoachMealFeedCommentsSaga);

  yield takeLatest(Types.GET_COACH_INTAKE_SUMMARY_COMMENTS, getCoachIntakeSummaryCommentsSaga);
  yield takeLatest(Types.ADD_COACH_INTAKE_SUMMARY_COMMENT, addCoachIntakeSummaryCommentSaga);

  yield takeLatest(Types.GET_MEAL_PLAN_DETAILS, getMealPlanDetailsSaga);
  yield takeLatest(Types.COPY_MEAL_PLANNER_BY_DAY, copyMealPlannerByDaySaga);
  yield takeLatest(Types.CLEAR_PLAYER_MEAL_PLAN, clearPlayerMealPlanSaga);
  yield takeLatest(Types.COPY_MEAL_PLANNER_BY_WEEK, copyMealPlannerByWeekSaga);
  yield takeLatest(Types.ADD_PLAYER_MEAL_PLAN, addPlayerMealPlanSaga);
  yield takeLatest(Types.DUPLICATE_COACH_MEAL_PLAN, duplicateCoachMealPlanSaga);
  yield takeLatest(Types.GET_COACH_MEAL_DETAILS, getCoachMealDetailsSaga);

  yield takeLatest(Types.RESET_PASSWORD, resetPasswordSaga);
  // Coach Mindset
  yield takeLatest(Types.GET_COACH_MINDSET_FEED_LIST, getCoachMindsetFeedList);
  yield takeLatest(Types.GET_COACH_MINDSET_FEED_COMMENTS, getCoachMindsetFeedCommentsSaga);

  yield takeLatest(Types.GET_COACH_MOOD_COMMENTS, getCoachMoodCommentsSaga);
  yield takeLatest(Types.GET_COACH_SLEEP_COMMENTS, getCoachSleepCommentsSaga);
  yield takeLatest(Types.GET_COACH_MEDITATION_COMMENTS, getCoachMeditationCommentsSaga);
  yield takeLatest(Types.GET_COACH_MOOD_DETAILS, getCoachMoodDetails);
  yield takeLatest(Types.GET_COACH_MOOD_STATS, getCoachMoodStats);
  yield takeLatest(Types.GET_COACH_MEDITATION_DETAILS, getCoachMeditationDetailsSaga);
  yield takeLatest(Types.GET_COACH_MEDITATION_STATS, getCoachMeditationStatsSaga);
  yield takeLatest(Types.GET_COACH_SLEEP_DETAILS, getCoachSleepDetails);
  yield takeLatest(Types.GET_COACH_SLEEP_STATS, getCoachSleepStatsSaga);

  // Help and Support
  yield takeLatest(Types.GET_HELP_AND_SUPPORT_LIST, getHelpAndSupportListSaga);
  yield takeLatest(Types.GET_ADMIN_HELP_AND_SUPPORT_LIST, getAdminHelpAndSupportListSaga);
  yield takeLatest(Types.GET_COACH_HELP_AND_SUPPORT_LIST, getCoachHelpAndSupportListSaga);
  yield takeLatest(Types.GET_PLAYER_HELP_AND_SUPPORT_LIST, getPlayerHelpAndSupportListSaga);
  yield takeLatest(Types.SEARCH_HELP_AND_SUPPORT, searchHelpAndSupportSaga);
  // admin
  yield takeLatest(Types.GET_ADMIN_HOME_DETAILS, getAdminHomeDetailsSaga);

  yield takeLatest(Types.ADD_COMMENT, addCommentSaga);

  // search
  yield takeLatest(Types.SEARCH_EXERCISE, searchExerciseSaga);
  yield takeLatest(Types.SEARCH_GROUP_LIST, searchGroupListSaga);
  yield takeLatest(Types.SEARCH_WORKOUT_LIST, searchWorkoutListSaga);
}
