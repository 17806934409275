import styled, { css } from 'styled-components';
import { borderColor, fontSize, fontWeight, textColors } from '../../../shared/constants';

export const Header = styled.div`
  font-size: ${fontSize.mediumDefault};
  width: 100%;
  text-align: center;
  padding: 1.5rem !important;
  text-transform: capitalize;
`;

export const MoodContainer = styled.div`
  width: 100%;
  height: 314px;
  border: 1px solid #a3a9b745;
  border-radius: 6px;
  padding: 1rem;
  display: flex;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  padding: 2rem 0;
  justify-content: center;
  display: flex;
`;

export const TrackContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  width: 325px !important;
`;

export const GradientBg = styled.div`
  height: 206px;
  width: 206px;
  background: transparent linear-gradient(180deg, #ffffff 0%, #8d8d8d 100%) 0% 0% no-repeat;
  border-radius: 50%;

  position: absolute;
  left: 63px;
`;

export const WhiteBg = styled.div`
  height: 198px;
  width: 198px;
  background: #fff;
  border-radius: 50%;

  position: absolute;
  left: 67px;
`;

export const EmojiContainer = styled.div`
  position: absolute;
  top: ${(props) => `${props.top}px`};
  left: ${(props) => `${props.left}px`};
  cursor: pointer;
`;

export const SliderRange = styled.div`
  position: absolute;
  top: 61px;
  left: 78px;
`;

export const Circular = styled.div`
  position: absolute;
  left: 76px;
`;

export const MoodText = styled.p`
  font-size: ${fontSize.titleVerySmall};

  position: absolute;
  top: 138px;
  left: 94px;

  width: 150px;
  text-align: center;

  font-weight: ${fontWeight.bold};
  text-transform: capitalize;

  ${(props) =>
    props.mood === 0 &&
    css`
      color: #e23433;
    `};

  ${(props) =>
    props.mood === 1 &&
    css`
      color: rgb(223, 74, 55);
    `};

  ${(props) =>
    props.mood === 2 &&
    css`
      color: #ec8541;
    `};

  ${(props) =>
    props.mood === 3 &&
    css`
      color: #e6d544;
    `};
  ${(props) =>
    props.mood === 4 &&
    css`
      color: #1ea79c;
    `};

  ${(props) =>
    props.mood === 5 &&
    css`
      color: #70c54d;
    `};
`;

export const SuccessContainer = styled.div`
  height: 230px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 3rem;

  & > img {
    width: 200px;
    margin: 0 auto;
  }

  & > p {
    font-size: 12px;
    color: #7ac943;
    text-align: center;
    margin-top: 2rem;
  }
`;

export const H1 = styled.h1`
  font-size: ${fontSize.medium};
`;

export const MainContainer = styled.div`
  height: calc(100vh - 235px);
  overflow-y: auto;
  padding-right: 1rem;
`;

// mood details
export const MoodContainer1 = styled.div`
  width: 100%;
  height: auto;
  border: 1px solid ${borderColor.light};

  border-radius: 6px;
  margin-bottom: 1.5rem;
  padding: 18px 14px;
`;

export const MoodHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 15px 0;
  padding-top: 0;
  border-bottom: 1px solid ${borderColor.light};
`;

export const MoodWrapper = styled.span`
  display: flex;
  align-items: center;
`;

export const MoodTitle = styled.p`
  display: inline-block;
  padding-left: 1rem;
  font-size: 12px;
  text-transform: capitalize;
`;

export const MoodTime = styled.p`
  display: inline-block;
  font-size: 12px;

  color: #f6f6f6;
  opacity: 0.4;
`;

export const MoodBody = styled.div`
  padding: 12px 0;
  border-bottom: 1px solid ${borderColor.light};

  & > p {
    font-size: 14px;
    color: #f6f6f6;
  }
`;

export const CommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
`;

export const NoComments = styled.p`
  font-size: 12px;
  font-weight: ${fontWeight.medium};

  text-transform: capitalize;
  color: ${textColors.textLight};
`;

export const CommentInputContainer = styled.div`
  padding-top: 1rem;
`;

export const ListItem = styled.div`
  display: flex;
  align-items: flex-start;
`;
