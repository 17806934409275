import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

// styles
import { ProfileContainer, Title } from './ProfileModalStyles';
import {
  Header,
  ImageAndInputs,
  ProfileImg,
  NameInputWrapper,
  Image,
  IconWrapper,
  Label,
  LabelTitle
} from './EditProfileStyles';

// creators
import { Creators } from './store';

// icons
import { ReactComponent as DeleteIcon } from '../../shared/assets/delete.svg';

import { InputComponent, ButtonComponent } from '../../globalShared';
import { ErrorMsg } from '../PersonalDetails/PersonalDetails.styles';

const EditProfile = (props) => {
  const { closeEditProfile } = props;
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.login.personalDetail);

  const { profilePicture } = userData;

  const [uploadImg, setUploadImg] = useState(profilePicture);
  const [showImgInput, setShowImgInput] = useState(false);
  const [state, setState] = useState(null);

  const { register, handleSubmit, errors } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...userData
    }
  });

  const handleImgInputChange = (e) => {
    const file = e.target.files[0];

    const { type } = file;
    // Allowing file type
    const allowedExtensions = /(jpg|jpeg|png)$/i;

    if (allowedExtensions.exec(type)) {
      const objectUrl = URL.createObjectURL(file);
      setUploadImg(objectUrl);
      setShowImgInput(true);
      setState(e.target.files[0]);
    }
  };

  const handledelete = () => {
    setUploadImg();
    setState(null);
    setShowImgInput(false);
  };

  const onDetailsSubmit = (data) => {
    const picDelete = !uploadImg && !state;
    dispatch(Creators.updatePersonalDetails({ ...data, upload: state, picDelete }));
    closeEditProfile();
  };

  return (
    <ProfileContainer>
      <form onSubmit={handleSubmit(onDetailsSubmit)}>
        <Header>
          <Title>Profile</Title>
          <ButtonComponent buttonText='Save' isButtonAction={true} buttonType='submit' />
        </Header>

        <ImageAndInputs>
          <ProfileImg>
            {showImgInput ? (
              <>
                <Image style={{ backgroundImage: `url(${uploadImg})` }} />
                <IconWrapper onClick={handledelete}>
                  <DeleteIcon />
                </IconWrapper>
              </>
            ) : (
              <>
                <input
                  type='file'
                  id='upload'
                  name='upload'
                  accept='image/*'
                  onChange={handleImgInputChange}
                  ref={register({ required: false })}
                  style={{ display: 'none' }}
                />
                <Label htmlFor='upload'>
                  <LabelTitle>Upload</LabelTitle>
                </Label>
                {uploadImg ? (
                  <>
                    <Image style={{ backgroundImage: `url(${uploadImg})` }} />
                    <IconWrapper onClick={() => setUploadImg()}>
                      <DeleteIcon />
                    </IconWrapper>
                  </>
                ) : (
                  ''
                )}
              </>
            )}
          </ProfileImg>
          <NameInputWrapper>
            <InputComponent
              placeholderText='first name'
              label='First Name'
              ipName='firstName'
              ipRef={register({ required: true })}
            />
            <ErrorMsg>{errors.firstName?.message}</ErrorMsg>
            <InputComponent
              ipType='string'
              placeholderText='last name'
              label='Last Name'
              ipName='lastName'
              ipRef={register({ required: true })}
            />
            <ErrorMsg>{errors.lastName?.message}</ErrorMsg>
          </NameInputWrapper>
        </ImageAndInputs>
        <InputComponent
          disabled={true}
          placeholderText='email'
          label='Email'
          ipName='email'
          ipRef={register({ required: false })}
        />
        <InputComponent
          placeholderText='mobile no.'
          label='Mobile'
          ipName='phoneNumber'
          ipRef={register({
            required: 'Required',
            minLength: { value: 10, message: 'The number shuold contain 10 digits ' },
            maxLength: { value: 10, message: 'The number shuold contain 10 digits ' },
            pattern: { value: /^[0-9]*$/, message: 'Should contain only numbers' }
          })}
        />
        <ErrorMsg>{errors.phoneNumber?.message}</ErrorMsg>
      </form>
    </ProfileContainer>
  );
};

export default EditProfile;
