import styled from 'styled-components';

import { fontSize, backgroundColor, fontWeight } from '../../../shared/constants';

export const ModalContainer = styled.div`
  position: absolute;
  z-index: 99999;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: flex-end;

  background-color: ${backgroundColor.popupBg};
`;

export const Content = styled.div`
  height: 100%;
  width: 632px;
  border-radius: 6px 0px 0px 6px;

  padding: 38px;

  background-color: ${backgroundColor.popup};

  z-index: 99999;
  overflow-y: auto;
`;

export const CloseButton = styled.span`
  position: absolute;
  right: 654px;
  top: 2rem;
  z-index: 99999;
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const Title = styled.div`
  font-size: calc(${fontSize.titleVerySmall} - 0.1rem);
  font-weight: ${fontWeight.bold};
`;

export const FormContainer = styled.div`
  position: relative;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`;

export const VideoInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 26px;
`;

export const InfoTitle = styled.p`
  font-size: 14px;
  font-weight: ${fontWeight.medium};
  color: #8792ac;
  margin-bottom: 1rem;
`;

export const InfoWrapper = styled.div`
  display: flex;
`;

export const VideoInfo = styled.span`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const VideoTitle = styled.p`
  font-size: 16px;
  font-weight: ${fontWeight.medium};
`;

export const VideoDuration = styled.p`
  font-size: 12px;
  color: #8d8d8d;
`;

export const InputContainer = styled.div`
  margin-bottom: 26px;
`;

export const HideVideo = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HideVideoTitle = styled.p`
  font-size: 14px;
  font-weight: ${fontWeight.medium};
  color: #8792ac;
`;

export const DividerContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 60px;
  right: 0px;
`;

export const FormFooter = styled.div`
  position: absolute;
  right: 0px;
  bottom: 0px;

  display: flex;
  justify-content: flex-end;
`;
