/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable no-shadow */
import { put } from 'redux-saga/effects';
import { Creators } from './action';

import { WebService } from '../../../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../../../services/axios/endPoints';

export function* getCoachStrengthFeedDetails({ payload }) {
  const { sessionList } = payload;
  try {
    yield put(Creators.getCoachStrengthFeedDetailsStart());

    const fullURL = yield `${apiEndPoints.coachStrengthFeedDetails}`;
    const response = yield WebService.get(fullURL, { params: { sessionList: JSON.stringify(sessionList) } });

    if (response.status >= 200 && response.status < 300) {
      const feedData = response.data.data;
      yield put(Creators.getCoachStrengthFeedDetailsSuccess({ sessionDeatils: feedData || [] }));
    } else {
      throw response;
    }
  } catch (error) {
    // console.log(error);

    // eslint-disable-next-line no-shadow
    let payload;
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getCoachStrengthFeedDetailsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getCoachStrengthFeedDetailsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getCoachStrengthFeedDetailsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getCoachStrengthFeedDetailsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getCoachStrengthFeedDetailsFailure(payload));
    }
  }
}

export function* getSessionCommentsSaga({ payload }) {
  const { id, page = 1 } = payload;

  try {
    yield put(Creators.getSessionCommentsStart());
    const fullURL = yield `${apiEndPoints.commentList}`;

    const response = yield WebService.get(fullURL, { params: { _id: id, page, count: 100 } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      yield put(
        Creators.getSessionCommentsSuccess({
          commentsList: data[0],
          maxPage: data[1].page_count,
          commentCount: data[2].commentCount
        })
      );
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });
    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getSessionCommentsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getSessionCommentsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getSessionCommentsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getSessionCommentsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getSessionCommentsFailure(payload));
    }
  }
}
