import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from './store';

import { ContentContainer, FormTitle, FormContainer, ScrollContainer, InputsContainer, Column } from './ExerciseStyles';

import { FormInput, Dropdown } from '../../../shared/components/FormComponents';
import VideoType from '../../../shared/components/VideoType';
import { ErrorMsg } from '../../PersonalDetails/PersonalDetails.styles';
import { ModalComponent } from '../../../globalShared';
import { localString } from '../../../services/locallization/localStrings';

// regexPatterns
const youtubeRegex =
  // eslint-disable-next-line no-useless-escape
  /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;

// default values
const category = ['strength', 'Cardiovascular', 'Flexibility'];

const equipment = [
  'Adjustable Bench',
  'Band',
  'Ball',
  'Bench',
  'Barbell',
  'Body only',
  'Cable',
  'Dumbbells',
  'Kettlebells',
  'Thread Mill',
  'Gym Bike',
  'Smith Machine'
];

const formField = {
  title: '',
  category: '',
  bodyPart: '',
  equipment: '',
  videoType: 'youtube',
  videoTitle: '',
  videoFile: '',
  videoUrl: ''
};

export const AddExerciseView = () => {
  const dispatch = useDispatch();
  const exerciseDetails = useSelector((state) => state.coachExercise.exerciseDetails);
  const success = useSelector((state) => state.coachExercise.success);

  const [formData, setformData] = useState(formField);
  // const [isFilled, setisFilled] = useState(false);
  const [errors, setErrors] = useState({});

  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  // form validations
  const validateForm = () => {
    const errs = errors;
    let IsValid = true;

    if (!formData.title) {
      IsValid = false;
      setErrors({ ...errors, title: true });
      errs.title = true;
    }

    if (!formData.category) {
      IsValid = false;
      setErrors({ ...errors, category: true });
      errs.category = true;
    }

    if (!formData.bodyPart) {
      IsValid = false;
      setErrors({ ...errors, bodyPart: true });
      errs.bodyPart = true;
    }
    if (!formData.equipment) {
      IsValid = false;
      setErrors({ ...errors, equipment: true });
      errs.equipment = true;
    }

    if (formData.videoType !== 'Upload Video' && !formData.videoUrl) {
      IsValid = false;
      setErrors({ ...errors, videoUrl: true });
      errs.videoUrl = true;
    }

    if (formData.videoType === 'Upload Video' && !formData.videoFile) {
      IsValid = false;
      setErrors({ ...errors, videoFile: true });
      errs.videoFile = true;
    }

    if (formData.videoType === 'Upload Video' && !formData.videoTitle) {
      IsValid = false;
      setErrors({ ...errors, videoTitle: true });
      errs.videoTitle = true;
    }

    return IsValid;
  };

  useEffect(() => {
    if (exerciseDetails) {
      setformData(exerciseDetails);
    }
  }, [exerciseDetails]);

  const onCancel = () => {
    dispatch(Creators.setCreateExerciseModal({ showCreateExercise: false }));
    dispatch(Creators.getExerciseDetailsSuccess({ exerciseDetails: '' }));
    setformData(formField);
  };

  useEffect(() => {
    if (success) {
      onCancel();
    }
  }, [success]);

  const onSubmit = () => {
    if (validateForm()) {
      dispatch(Creators.setCoachExercise({ data: formData }));
    }
  };

  // videoUrl validate function
  const validateUrl = (url) => {
    if (!url) {
      return true;
    }

    if (formData.videoType === 'youtube') {
      return url.match(youtubeRegex);
    }

    if (formData.videoType === 'vimeo') {
      return true;
    }

    return false;
  };

  // form onchange handlers
  const handleTitle = (value) => {
    setformData({ ...formData, title: value });

    // onChange form Handler
    const errs = errors;
    if (!value) {
      errs.title = true;
      setErrors({ ...errs });
    } else {
      errs.title = false;
      setErrors({ ...errs });
    }
  };

  const handleCategory = (value) => {
    setformData({ ...formData, category: value });

    // onChange form Handler
    const errs = errors;
    if (errors.category) {
      errs.category = false;
      setErrors({ ...errs });
    }
  };

  const handleBodyPart = (value) => {
    setformData({ ...formData, bodyPart: value });

    // onChange form Handler
    const errs = errors;
    if (!value) {
      errs.bodyPart = true;
      setErrors({ ...errs });
    } else {
      errs.bodyPart = false;
      setErrors({ ...errs });
    }
  };

  const handleequipment = (value) => {
    setformData({ ...formData, equipment: value });
    // onChange form Handler
    const errs = errors;
    if (errs.equipment) {
      errs.equipment = false;
      setErrors({ ...errs });
    }
  };

  const handleSetFile = (value) => {
    setformData({ ...formData, videoFile: value });

    // onChange form Handler
    const errs = errors;
    if (!value) {
      errs.videoFile = true;
      setErrors({ ...errs });
    } else {
      errs.videoFile = false;
      setErrors({ ...errs });
    }
  };

  const handleSetLink = (value) => {
    const errs = errors;

    if (validateUrl(value)) {
      setformData({ ...formData, videoUrl: value });
      errs.videoUrl = false;
    } else {
      errs.videoUrl = true;
    }
    setErrors({ ...errs });
  };

  const handleSetType = (value) => {
    setformData({ ...formData, videoType: value });
  };

  const handleVideoTitle = (value) => {
    setformData({ ...formData, videoTitle: value });

    // onChange form Handler
    const errs = errors;
    if (!value) {
      errs.videoTitle = true;
      setErrors({ ...errs });
    } else {
      errs.videoTitle = false;
      setErrors({ ...errs });
    }
  };

  return (
    <ModalComponent width='600' onCancel={onCancel} onSubmit={onSubmit}>
      <ContentContainer>
        <FormTitle>{string.createExercise}</FormTitle>
        <FormContainer>
          <ScrollContainer>
            <InputsContainer>
              <Column wdth='50%'>
                <FormInput
                  placeholder={string.createExercise}
                  label={string.createExercise}
                  value={formData.title}
                  setValue={handleTitle}
                />
                <ErrorMsg>{errors.title && string.required}</ErrorMsg>
              </Column>
              <Column wdth='50%'>
                <Dropdown
                  list={category}
                  label={string.exerciseCategory}
                  value={formData.category}
                  setValue={handleCategory}
                />
                <ErrorMsg>{errors.category && string.required}</ErrorMsg>
              </Column>

              <Column wdth='50%'>
                <FormInput
                  placeholder={string.enterCue}
                  label={string.cues}
                  value={formData.bodyPart}
                  setValue={handleBodyPart}
                />
                <ErrorMsg>{errors.bodyPart && string.required}</ErrorMsg>
              </Column>

              <Column wdth='50%'>
                <Dropdown
                  list={equipment}
                  label={string.equipment}
                  value={formData.equipment}
                  setValue={handleequipment}
                  maxHeight='185'
                />
                <ErrorMsg>{errors.equipment && string.required}</ErrorMsg>
              </Column>

              <Column wdth='100%'>
                <VideoType
                  videoTitle={formData.videoTitle}
                  videoLink={formData.videoUrl}
                  videoFile={formData.videoFile}
                  videoType={formData.videoType}
                  setFile={handleSetFile}
                  setVideoType={handleSetType}
                  setVideoTitle={handleVideoTitle}
                  setLink={handleSetLink}
                />

                {formData.videoType === 'Upload Video' ? (
                  <>
                    <ErrorMsg style={{ marginTop: '1rem' }}>{errors.videoTitle && string.titleRequired}</ErrorMsg>
                    <ErrorMsg style={{ marginTop: '1rem' }}>{errors.videoFile && string.uploadFile}</ErrorMsg>
                  </>
                ) : (
                  <ErrorMsg>{errors.videoUrl && string.enterValidUrl}</ErrorMsg>
                )}
              </Column>
            </InputsContainer>
          </ScrollContainer>
        </FormContainer>
      </ContentContainer>
    </ModalComponent>
  );
};
