export const fontSize = {
  titleHuge: '4.5rem',
  titleSize: '3.2rem',
  titleMedium: '2.8rem',
  titleMediumSmall: '2.6rem',
  titleSmall: '2.5rem',
  titleVerySmall: '2.1rem',
  mediumDefault: '1.6rem',
  title: '1.8rem',

  smallDefault: '1.2rem',
  default: '1rem',
  mediumSmall: '0.85rem',
  small: '0.7rem',
  large: '2rem',
  medium: '1.4rem',
  heavy: '4rem'
};

export const fontWeight = {
  light: 300,
  medium: 500,
  bold: 700
};

export const fontFamily = {
  bebas: "'Bebas Neue', cursive;"
};
