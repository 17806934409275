/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { Creators } from './store';
import { Creators as CommonCreators } from '../../store';
import { timeSince } from '../../../services/utility';

import {
  MoodContainer,
  MoodHeader,
  MoodWrapper,
  MoodTitle,
  MoodTime,
  MoodBody,
  CommentsContainer,
  CommentInputContainer,
  StatsHeader,
  ScrollContainer,
  FilterMood,
  Filter,
  DayContainer,
  Rotate,
  DayStatsHead,
  DayStatsWrapper,
  DayStats,
  Day,
  MoodDate,
  DayMood,
  MoodDot,
  TabContainer
} from './MoodDetailsModalStyles';

// icons

import { ReactComponent as Dropdown } from '../../../shared/assets/Dropdown.svg';

import { CommentInputComponent, MoodAccordionComponent } from '../../../globalShared';
import CommentsFooter from '../../../shared/components/CommetsFooter';
import MoodIcon from '../../../shared/components/MoodIcon';
import MooodCount from '../../../shared/components/MoodCount';
import { moodStrings } from '../../../services/utility/maps';

const ACC = ({ data }) => {
  const { moodDay, moodDate, dayStat } = data;
  const [state, setState] = useState(false);

  const renderDot = {
    level6: <MoodDot delightful />,
    level5: <MoodDot happy />,
    level4: <MoodDot good />,
    level3: <MoodDot sad />,
    level2: <MoodDot tired />,
    level1: <MoodDot awful />
  };

  return (
    <>
      <DayContainer onClick={() => setState(!state)}>
        <DayStatsHead>
          <DayStatsWrapper>
            <DayStats>
              <Day>{moodDay}</Day>
              <MoodDate>{moodDate}</MoodDate>
            </DayStats>

            <DayMood>
              {dayStat.map(({ name }) => (
                <Fragment key={`${name}${Math.random()}`}>{renderDot[name]}</Fragment>
              ))}
            </DayMood>
          </DayStatsWrapper>

          <span>
            {state ? (
              <Rotate>
                <Dropdown />
              </Rotate>
            ) : (
              <Dropdown />
            )}
          </span>
        </DayStatsHead>
        <div>{state && <MoodAccordionComponent dayStat={dayStat} />}</div>
      </DayContainer>
    </>
  );
};

const MoodDetails = ({ feedDetails }) => {
  const dispatch = useDispatch();
  const { moodName, updatedTime, thought, commentCount, _id } = feedDetails;
  const [days, setDays] = useState(7);

  const [recentCommentID, setRecentCommentID] = useState('');

  // values from redux store
  // const moodDetailsId = useSelector((state) => state.coachMindset.moodDetailsId);
  const stats = useSelector((state) => state.coachMindset.stats);
  const moodStats = useSelector((state) => state.coachMindset.moodStats);
  const moodCommentsList = useSelector((state) => state.coachMindset.moodCommentsList);
  const reloadComments = useSelector((state) => state.main.reloadComments);

  const moodTime = timeSince(updatedTime);

  // past week and month mood details
  const getPastDays = () => {
    const daysAgo = [];
    for (let day = 0; day < days; day += 1) {
      const actualDay = moment().subtract(day, 'days');
      const formattedDay = actualDay.format('DD MM YYYY');
      const moodDay = actualDay.format('ddd');
      const moodDate = actualDay.format('DD');
      daysAgo.push({ formattedDay, moodDay, moodDate });
    }

    return daysAgo;
  };

  const pastDays = getPastDays();

  pastDays.forEach((item) => {
    const filteredDay = moodStats.filter((stat) => moment(stat.time).format('DD MM YYYY') === item.formattedDay);
    item.dayStat = filteredDay;
  });

  // effect hooks
  useEffect(() => {
    if (_id) {
      dispatch(Creators.getCoachMoodDetails({ _id }));
    }
  }, [_id]);

  useEffect(() => {
    dispatch(Creators.getCoachMoodStats({ userId: feedDetails?.userId, days }));
  }, [days]);

  const handleShowComments = (_id) => {
    dispatch(Creators.getCoachMoodComments({ _id, count: 100, page: 1 }));
    setRecentCommentID(_id);
  };

  const handleSubmitComment = (content, id) => {
    dispatch(CommonCreators.addComment({ content, id }));
    setRecentCommentID(id);
  };

  useEffect(() => {
    handleShowComments(feedDetails?._id);
  }, []);

  useEffect(() => {
    if (reloadComments && !!recentCommentID) {
      handleShowComments(recentCommentID);
    }
  }, [reloadComments]);

  const handleWeekFilter = () => {
    setDays(7);
  };

  const handleMonthFilter = () => {
    setDays(30);
  };

  const handleThreeMonthFilter = () => {
    setDays(90);
  };

  return (
    <TabContainer>
      <MoodContainer>
        <MoodHeader>
          <MoodWrapper>
            <MoodIcon type={moodName} />
            <MoodTitle>{moodStrings[moodName]}</MoodTitle>
          </MoodWrapper>
          <div>
            <MoodTime>{moodTime}</MoodTime>
          </div>
        </MoodHeader>

        {thought && (
          <MoodBody>
            <p>{thought}</p>
          </MoodBody>
        )}

        <CommentsContainer>
          <CommentsFooter
            commentsList={moodCommentsList}
            _id={feedDetails?._id}
            commentCount={commentCount || 0}
            onClick={handleShowComments}
          />
          <CommentInputContainer>
            <CommentInputComponent
              id={feedDetails?._id}
              placeholderText='Enter Your Comment Here…'
              onSubmit={handleSubmitComment}
            />
          </CommentInputContainer>
        </CommentsContainer>
      </MoodContainer>

      <StatsHeader>Mood Journal Stats</StatsHeader>

      <MooodCount moods={stats} />

      <FilterMood>
        <Filter active={days === 7} onClick={handleWeekFilter}>
          <p>Weekly</p>
        </Filter>
        <Filter active={days === 30} onClick={handleMonthFilter}>
          <p>Monthly</p>
        </Filter>
        <Filter active={days === 90} onClick={handleThreeMonthFilter}>
          <p>3 Months</p>
        </Filter>
      </FilterMood>
      <ScrollContainer>
        {pastDays.map((moodItem, index) => (
          <ACC data={moodItem} key={index} />
        ))}
      </ScrollContainer>
    </TabContainer>
  );
};

export default MoodDetails;
