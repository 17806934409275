import styled from 'styled-components';

export const Container = styled.div`
  /* position: relative; */
`;

export const ActionsContainer = styled.div`
  position: absolute;
  right: 30px;
}
`;
