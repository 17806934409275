/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-boolean-value */
import React from 'react';

import { ModalContainer, AllergyContainer, ModalHeader, ModalTitle, CloseBtn, AllergyText } from './AddMealStyles';

import { ReactComponent as CloseIcon } from '../../../shared/assets/Close.svg';
import { Divider } from '../../../globalShared/CommonStyles';

const FoodAllergyModal = ({ onCancel, food }) => (
  <ModalContainer>
    <AllergyContainer>
      <ModalHeader style={{ padding: '0 3rem 2rem' }}>
        <ModalTitle>Food Allergy</ModalTitle>
        <CloseBtn onClick={onCancel}>
          <CloseIcon />
        </CloseBtn>
      </ModalHeader>
      <Divider />
      <AllergyText style={{ padding: '1rem 3rem' }}>{food || 'None'}</AllergyText>
    </AllergyContainer>
  </ModalContainer>
);

export default FoodAllergyModal;
