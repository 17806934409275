import styled from 'styled-components';
import { backgroundColor, fontSize, gradient } from '../../../shared/constants';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1rem 2.5rem;
  height: 70px;
  position: relative;
  background: ${gradient.dark};
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const UserContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 70px;

  padding: 5px 6px;
  margin-left: 1.5rem;

  background: ${backgroundColor.darkOpaq};
  border-radius: 25px;

  cursor: pointer;
`;

export const UserIcon = styled.div`
  height: 35px;
  width: 35px;

  border-radius: 50%;
  background: ${backgroundColor.light};

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const DropDownContainer = styled.div`
  position: absolute;
  right: 15px;
  top: 70px;

  width: 180px;

  padding: 2rem;

  background-color: #262130;
  border-radius: 10px;
  z-index: 9999;
`;

export const DropDownItem = styled.div`
  display: flex;
  align-items: center;

  padding: 1.2rem 0;

  font-size: ${fontSize.title};

  cursor: pointer;
  & > svg {
    margin-right: 10px;
  }
`;

export const Body = styled.div`
  display: flex;
`;

export const ContentContainer = styled.div`
  padding: 1.5rem 2.5rem;
  width: 100%;
  height: calc(100vh - 70px);
  overflow-y: auto;
`;
