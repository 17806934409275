/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable indent */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from './store';
import Toast, { Toasts } from '../../../services/toast';

// styles
import {
  Heading,
  ListContainer,
  ListItem,
  Thumbnail,
  ItemDetails,
  Menu,
  Wrapper,
  MenuBtn,
  Cue,
  MenuItem
} from './ExerciseStyles';

import { EmptyContentText, FeedContainer, FlexContainer } from '../../../globalShared/CommonStyles';

// assets
// import { ReactComponent as SortIcon } from '../../../shared/assets/sorting.svg';
import { ReactComponent as MenuIcon } from '../../../shared/assets/3dot_menu.svg';

import Pagenation from '../../../shared/components/PaginationComponent';
import { MasonryLayout } from '../../../globalShared';
import { localString } from '../../../services/locallization/localStrings';

/**
 *
 * component
 */

const ExerciseView = () => {
  const [showOptionId, setshowOptionId] = useState(null);

  const [count, setCount] = useState(10);

  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  const dispatch = useDispatch();
  const exerciseList = useSelector((state) => state.coachExercise.exerciseList);

  const { success, loading, searchKey, page } = useSelector((state) => state.coachExercise);
  const error = useSelector((state) => state.coachExercise.error);
  const reloadList = useSelector((state) => state.coachExercise.reloadList);
  const totalItems = useSelector((state) => state.coachExercise.totalItems);

  // eslint-disable-next-line no-unused-vars
  const deleteExercise = (id) => {
    dispatch(Creators.deleteCoachExercise({ id }));
    setshowOptionId(null);
  };

  const showExerciseDetails = (id) => {
    dispatch(Creators.setExerciseDetailsId({ id }));
  };

  const fetchList = () => dispatch(Creators.getCoachExerciseList({ page, count }));
  const fetchSearch = () => dispatch(Creators.searchCoachExercise({ count, page, searchKey }));

  const handlePage = (page) => {
    dispatch(Creators.setExercisePage({ page }));
  };

  // Toast Notification hooks
  useEffect(() => {
    if (success) {
      Toast.success(string.success, 2, () => dispatch(Creators.resetSuccess()));
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      Toast.error(error, 2, () => dispatch(Creators.resetError()));
    }
  }, [error]);

  useEffect(() => {
    if (reloadList) {
      searchKey ? fetchSearch() : page === 1 ? fetchList() : handlePage(1);
    }
  }, [reloadList]);

  useEffect(() => {
    if (searchKey) {
      fetchSearch();
    } else {
      fetchList();
    }
  }, [count, page, searchKey]);

  useEffect(
    () => () => {
      dispatch(Creators.exerciseReset());
    },
    []
  );

  const handleEdit = (exercise) => {
    dispatch(Creators.getExerciseDetailsSuccess({ exerciseDetails: exercise }));
    dispatch(Creators.setCreateExerciseModal({ showCreateExercise: true }));
    setshowOptionId(null);
  };

  return (
    <>
      <Toasts />
      {exerciseList.length ? (
        <>
          <FeedContainer style={{ height: 'calc(100vh - 211px)' }} className='overFlow'>
            <Heading>
              {string.exercises}
              {/* <SortIcon /> */}
            </Heading>
            <ListContainer>
              <Wrapper>
                <MasonryLayout>
                  {exerciseList.map((exercise) => {
                    const { _id, title, thumbUrl, bodyPart } = exercise;
                    return (
                      <ListItem key={_id}>
                        <FlexContainer
                          style={{ cursor: 'pointer' }}
                          onClick={() => (showOptionId ? null : showExerciseDetails(_id))}
                        >
                          <Thumbnail src={thumbUrl} />
                          <ItemDetails>
                            <h1>{title}</h1>
                            <Cue className='word'>{bodyPart}</Cue>
                          </ItemDetails>
                        </FlexContainer>
                        <MenuBtn onClick={() => setshowOptionId(_id)}>
                          <MenuIcon />
                        </MenuBtn>
                        <div>
                          <Menu show={showOptionId === _id} onMouseLeave={() => setshowOptionId(null)}>
                            <MenuItem isActive={true} onClick={() => handleEdit(exercise)}>
                              {string.edit}
                            </MenuItem>
                            <MenuItem isActive={false} onClick={() => null}>
                              {string.delete}
                            </MenuItem>
                          </Menu>
                        </div>
                      </ListItem>
                    );
                  })}
                </MasonryLayout>
              </Wrapper>
            </ListContainer>
          </FeedContainer>
          <Pagenation
            onChange={(value) => {
              handlePage(value.currentPage);
              setCount(value.count);
            }}
            totalItems={totalItems}
            pageNo={page}
            title={string.exercises}
          />
        </>
      ) : (
        !loading && (
          <EmptyContentText style={{ height: 'calc(100vh - 162px)' }}>{string.noDataAvailable}</EmptyContentText>
        )
      )}
    </>
  );
};

export default ExerciseView;
