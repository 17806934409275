import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Creators as ModalDataCreators } from '../RestPlayers/store';

import {
  Card,
  CardFlex,
  ModalTitle,
  ModalHeaderContainer,
  ModalContentContainer,
  InfoHeader,
  Info,
  TableClone,
  PlayerName,
  HistoryBtn,
  ModalOverlay,
  ScrollContainer
} from './Modal';

import { ReactComponent as Close } from '../../../shared/assets/Close.svg';

import { AvatarComponent } from '../../../globalShared';
import { shortenText } from '../../../services/utility';

const { CardNew, CardPara } = Card;

const GroupMembersModal = ({ shoModal, onCancel }) => {
  const groupDetails = useSelector((state) => state.coachPlannerDetails.groupDetails);
  const historyModal = useSelector((state) => state.modalData.historyModal);

  const dispatch = useDispatch();

  if (shoModal && groupDetails) {
    return (
      <>
        <ModalOverlay>
          <CardFlex>
            <CardPara>
              <Close onClick={onCancel} />
            </CardPara>

            <CardNew>
              <ModalHeaderContainer>
                <ModalTitle>{groupDetails.name}</ModalTitle>
              </ModalHeaderContainer>

              <ModalContentContainer>
                <InfoHeader>Players:</InfoHeader>
                <Info>{groupDetails.noOfPlayers}</Info>
              </ModalContentContainer>

              <ScrollContainer>
                {groupDetails.playerList.map((player) => (
                  <TableClone key={player._id}>
                    <AvatarComponent url={player.profilePicture} />
                    <PlayerName>{shortenText(`${player.firstName} ${player.lastName}`, 10, '...')}</PlayerName>

                    <HistoryBtn
                      onClick={() => {
                        dispatch(ModalDataCreators.setHistoryModalData({ historyModalData: player }));
                        dispatch(ModalDataCreators.setHistoryModal({ historyModal: !historyModal }));
                      }}
                    >
                      Player History
                    </HistoryBtn>
                  </TableClone>
                ))}
              </ScrollContainer>
            </CardNew>
          </CardFlex>
        </ModalOverlay>
      </>
    );
  }
  return <></>;
};

export default GroupMembersModal;
