import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { accentColor, fontSize, fontWeight, backgroundColor } from '../../../shared/constants';

export const EmptyContainer = styled.div`
  height: 290px;
  width: 49%;
  border: 2px dashed ${accentColor.primary};
  border-radius: 10px;
  padding: 10px;
`;

export const LinkItem = styled(Link)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #221c34;
  font-size: 16px;
  border-radius: 10px;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: ${accentColor.primary};
  }
`;

export const HeaderTxt = styled.h1`
  font-size: ${fontSize.titleVerySmall};
  font-weight: ${fontWeight.bold};
  margin: 0 1rem;
`;

export const PlayerWrapper = styled.div`
  height: 290px;
  width: 49%;
  background: ${backgroundColor.popupBg};
  border-radius: 1rem;
`;
