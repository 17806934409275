import styled from 'styled-components';
import { fontSize } from '../../../shared/constants';

export const PlayersContainer = styled.span`
  display: flex;
  align-items: center;
`;

export const Others = styled.p`
  font-size: ${fontSize.mediumDefault};
  color: #cbd1df;
`;
