import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// infinite scroll component
import InfiniteScroll from 'react-infinite-scroll-component';

import { Creators } from './store';
import { LoadingComponent, RecentCardComponent } from '../../../globalShared';
import { timeSince } from '../../../services/utility';

// styles
import { CommingSoon } from '../../../globalShared/CommonStyles';
import { Container, Circle, Title, Time, DetailsContainer } from './MindsetStyles';

import MasonryLayout from '../../../shared/components/MasonryLayoutComponent';

// components
import { meditationMap, moodStrings } from '../../../services/utility/maps';

// icons
import CompletedIcon from '../../../shared/assets/completed.svg';
import ProgressIcon from '../../../shared/assets/work_inprogress.svg';
import MindSetModal from './Modal';

// component
const MindsetView = (props) => {
  const { playerId } = props;
  const dispatch = useDispatch();
  const [feedDetails, setFeedDetails] = useState('');
  const [showModal, setShowModal] = useState(false);

  const [count] = useState(10);
  const [page, setPage] = useState(1);

  // values from redux store
  const mindsetFeedData = useSelector((state) => state.coachMindset.mindsetFeedList);
  const { isNext, fetchTime } = useSelector((state) => state.coachMindset);
  const loading = useSelector((state) => state.coachMindset.loading);

  // effect hooks
  useEffect(() => {
    const payload = { page, count: 10, playerId };
    if (page > 1 && fetchTime) {
      payload.fetchTime = fetchTime;
    }
    dispatch(Creators.getCoachMindsetFeedList(payload));
  }, [page]);

  useEffect(
    () => () => {
      dispatch(Creators.resetCoachMindSet());
    },
    []
  );

  // set modal id
  const handleSeeDetails = (data) => {
    setFeedDetails(data);
    setShowModal(true);
  };
  const handleClose = () => {
    setFeedDetails(null);
    setShowModal(false);
  };

  const handleScroll = () => {
    setPage(page + 1);
  };

  const renderSleep = (duration = 0) => {
    const sleepHours = Math.floor(duration / 60);
    const sleepMinutes = duration % 60;

    return (
      <Title>
        Slept For
        <span>{`${sleepHours} Hrs ${sleepMinutes} Mins`}</span>
      </Title>
    );
  };

  const renderMeditation = (meditationDuration, meditationName) => {
    const meditationMinutes = Math.floor(meditationDuration / 60);
    const meditationSeconds = meditationDuration % 60;

    return (
      <Title>
        Meditated For
        <span>{`${meditationMinutes} Mins ${meditationSeconds} Sec`}</span>
        <span>{meditationMap[meditationName]}</span>
      </Title>
    );
  };

  return (
    <>
      {loading && <LoadingComponent />}
      <Container id='mindsetScroll'>
        {mindsetFeedData.length ? (
          <>
            <InfiniteScroll
              dataLength={mindsetFeedData.length}
              next={handleScroll}
              scrollableTarget='mindsetScroll'
              inverse={false}
              hasMore={isNext}
            >
              <MasonryLayout>
                {mindsetFeedData.map((feed) => {
                  const isContains = (value) => feed[0].indexOf(value) > -1;
                  const { _id, fullName, profilePicture, createdDate } = feed[1];
                  const mindSetObjs = feed.filter((_, k) => k > 0);
                  const sleep = mindSetObjs.find(({ mindsetType }) => mindsetType === 'sleep');
                  const mood = mindSetObjs.find(({ mindsetType }) => mindsetType === 'mood');
                  const meditation = mindSetObjs.find(({ mindsetType }) => mindsetType === 'meditation');

                  return (
                    <RecentCardComponent
                      key={_id}
                      profilePicture={profilePicture}
                      userName={fullName}
                      date={createdDate}
                      onClick={() => handleSeeDetails(feed)}
                    >
                      <DetailsContainer>
                        {isContains('mood') ? (
                          <>
                            <Circle src={CompletedIcon} />
                            <div>
                              <Title>
                                Marked
                                <span>{moodStrings[mood.moodName]}</span>
                              </Title>
                              <Time>{timeSince(mood.updatedTime)}</Time>
                            </div>
                          </>
                        ) : (
                          <>
                            <Circle src={ProgressIcon} />
                            <div>
                              <Title>No Update for Mood Journal</Title>
                              <Time>Waiting for Update</Time>
                            </div>
                          </>
                        )}
                      </DetailsContainer>
                      <DetailsContainer>
                        {isContains('sleep') ? (
                          <>
                            <Circle src={CompletedIcon} />
                            <div>
                              {renderSleep(sleep.sleepDuration)}
                              <Time>{timeSince(sleep.updatedTime)}</Time>
                            </div>
                          </>
                        ) : (
                          <>
                            <Circle src={ProgressIcon} />
                            <div>
                              <Title>No Update for Sleep</Title>
                              <Time>Waiting for Update</Time>
                            </div>
                          </>
                        )}
                      </DetailsContainer>
                      <DetailsContainer>
                        {isContains('meditation') ? (
                          <>
                            <Circle src={CompletedIcon} />
                            <div>
                              {renderMeditation(meditation.meditationDuration, meditation.meditationName)}
                              <Time>{timeSince(meditation.updatedTime)}</Time>
                            </div>
                          </>
                        ) : (
                          <>
                            <Circle src={ProgressIcon} />
                            <div>
                              <Title>No Update for Meditation</Title>
                              <Time>Waiting for Update</Time>
                            </div>
                          </>
                        )}
                      </DetailsContainer>
                    </RecentCardComponent>
                  );
                })}
              </MasonryLayout>
            </InfiniteScroll>
          </>
        ) : (
          <CommingSoon>{!loading && 'No Data Available'}</CommingSoon>
        )}
      </Container>
      {showModal && <MindSetModal data={feedDetails} onCancel={handleClose} />}
    </>
  );
};
export default MindsetView;
