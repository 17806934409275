/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  ModalContent,
  ModalTitle,
  ExistingMealPlan,
  Label,
  InputContainer,
  SelectButtonContainer,
  Radio,
  DashedLine,
  CustomisedBtn
} from './AddMealStyles';

import { Creators } from '../MealPlansView/store';

import { ModalComponent, RepeatComponent } from '../../../globalShared/index';

import { ReactComponent as Arrow } from '../../../shared/assets/Dropdown.svg';
import { ReactComponent as Selected } from '../../../shared/assets/Radio_selected.svg';
import { Divider, ValdErrMsg } from '../../../globalShared/CommonStyles';
import { weekdays } from '../../../services/utility/dates';
import { getYearMonthDay } from '../../../services/utility/moment';

const AddMealPlanModal = ({ onCancel, onAdd, onCreate, selectDate }) => {
  const dispatch = useDispatch();
  const [page] = useState(1);
  const [selectedMeal, setselectedMeal] = useState(null);
  const [fromDate, seFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [repeatDays, setRepeatDays] = useState([]);
  const [errs, setErrs] = useState({});
  const mealPlannerList = useSelector((state) => state.coachMealPlan.mealPlanList);

  useEffect(() => {
    dispatch(Creators.getCoachMealPlanList({ page, count: 100 }));
  }, [page]);

  function checkFormFilled() {
    let isValid = true;
    const errors = errs;

    if (!selectedMeal) {
      isValid = false;
      setErrs({ ...errors, id: 'Select 1 meal' });
    }

    // if (!fromDate) {
    //   isValid = false;
    //   setErrs({ ...errors, fromDateErr: 'Required' });
    // }

    // if (!toDate) {
    //   isValid = false;
    //   setErrs({ ...errors, toDateErr: 'Required' });
    // }

    // if (ismoreThanWeek(fromDate, toDate) && !repeatDays.length) {
    //   isValid = false;
    //   setErrs({ ...errors, repeatDaysErr: 'Required' });
    // }

    return isValid;
  }

  const handleAdd = () => {
    if (checkFormFilled()) {
      onAdd({
        planId: selectedMeal._id,
        fromDate: fromDate || getYearMonthDay(selectDate),
        toDate: toDate || getYearMonthDay(selectDate),
        repeatDays: repeatDays.length ? repeatDays : weekdays
      });
      onCancel();
    }
  };

  const handleCreateMealPlan = () => {
    onCreate();
    onCancel();
  };

  const handleEndDate = (value) => {
    let err = errs;
    setToDate(value);
    setRepeatDays(weekdays);

    if (!value) {
      err = { ...err, toDateErr: 'Required' };
    } else {
      err = { ...err, toDateErr: '' };
    }
    setErrs(err);
  };

  const handleStartDate = (value) => {
    let err = errs;
    seFromDate(value);

    if (!value) {
      err = { ...err, fromDateErr: 'Required' };
    } else {
      err = { ...err, fromDateErr: '' };
    }
    setErrs(err);
  };

  const handleRepeatDays = (value) => {
    setRepeatDays(value);
  };

  return (
    <>
      <ModalComponent width='554' onSubmit={handleAdd} onCancel={onCancel}>
        <ModalContent>
          <ModalTitle>Add Meal Plan</ModalTitle>
          <Label>Existing Meal Plan</Label>
          <ExistingMealPlan>
            {mealPlannerList &&
              mealPlannerList.map((meal) => (
                <InputContainer key={meal._id} onClick={() => setselectedMeal(meal)}>
                  <SelectButtonContainer>
                    {selectedMeal?._id === meal._id ? <Selected /> : <Radio />}
                  </SelectButtonContainer>
                  <p style={{ fontSize: '14px' }}>{meal.title}</p>
                </InputContainer>
              ))}
          </ExistingMealPlan>
          {errs.id && <ValdErrMsg style={{ marginTop: '1rem' }}>{errs.id}</ValdErrMsg>}
          <DashedLine>OR</DashedLine>
          <CustomisedBtn onClick={handleCreateMealPlan}>
            Create a customised meal plan
            <Arrow />
          </CustomisedBtn>
          <Divider style={{ margin: '2rem 0' }} />
          <RepeatComponent
            fromDate={fromDate}
            toDate={toDate}
            repeatDays={repeatDays}
            setStartDate={(value) => handleStartDate(value)}
            setEndDate={(value) => handleEndDate(value)}
            setRepeatDays={(value) => handleRepeatDays(value)}
          />
        </ModalContent>
      </ModalComponent>
    </>
  );
};

export default AddMealPlanModal;
