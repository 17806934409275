/* eslint-disable no-shadow */
/* eslint-disable operator-linebreak */

import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import screenfull from 'screenfull';

// styles
import {
  Container,
  ControllsContainer,
  PlayButton,
  Slider,
  Timer,
  VolumeButton,
  PrimaryButton
} from './FeedVideoPlayer.Styles';

import { ReactComponent as PlayIcon } from '../../assets/play.svg';
import { ReactComponent as PauseIcon } from '../../assets/pause.svg';
import { ReactComponent as Fullscreen } from '../../assets/maximize.svg';
import { ReactComponent as MuteIcon } from '../../assets/mute.svg';
import { ReactComponent as VolumeIcon } from '../../assets/volume.svg';
import { ReactComponent as PrimaryPlayIcon } from '../../assets/play_web.svg';

function pad(string) {
  return `0 ${string}`.slice(-2);
}

function format(seconds) {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  let mm = date.getUTCMinutes();
  let ss = pad(date.getUTCSeconds());

  if (mm < 10) mm = `0${mm}`;
  if (ss < 10) ss = `0${ss}`;

  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`;
  }
  return `${mm} : ${ss}`;
}

const Duration = ({ seconds }) => <Timer dateTime={`P${Math.round(seconds)}S`}>{format(seconds)}</Timer>;

// componant
const FeedVideoPlayer = ({ URL, setVideoId, playVideo, id }) => {
  // refs
  const playerRef = useRef(null);
  const playerContainerRef = useRef(null);

  // player State
  const [state, setState] = useState({
    playing: false,
    controls: false,
    muted: true,
    played: 0,
    duration: 0,
    playbackRate: 1.0,
    seeking: false,
    showPlay: true
  });
  const [playCurrent, setPlayCurrent] = useState(false);

  useEffect(() => {
    setPlayCurrent(playVideo === id);
  }, [playVideo]);

  const { played, playbackRate, muted, playing, duration, showPlay } = state;

  const handlePlayPause = () => {
    setState({ ...state, playing: !state.playing });
    setVideoId(id);
  };

  const handlePlay = () => {
    setState({ ...state, showPlay: false, playing: !state.playing });
    setVideoId(id);
  };

  const handleProgress = (changeState) => {
    setState({ ...state, ...changeState });
  };

  const handleEnded = () => {
    setState({ ...state, playing: false });
  };

  const handleDuration = (duration) => {
    setState({ ...state, duration });
  };

  const onSeek = ({ target }) => {
    setState({ ...state, played: parseFloat(target.value / 100) });
    playerRef.current.seekTo(target.value / 100, 'fraction');
  };

  const onSeekMouseDown = () => {
    setState({ ...state, seeking: true });
  };

  const onSeekMouseUp = ({ target }) => {
    setState({ ...state, seeking: false });
    playerRef.current.seekTo(target.value / 100, 'fraction');
  };

  const handleToggleMuted = () => {
    setState({ ...state, muted: !muted });
  };

  const handleClickFullscreen = () => {
    screenfull.toggle(playerContainerRef.current);
  };

  return (
    <Container ref={playerContainerRef}>
      <ReactPlayer
        ref={playerRef}
        width='100%'
        height='100%'
        url={URL}
        muted={muted}
        playing={playing && playCurrent}
        playbackRate={playbackRate}
        onProgress={handleProgress}
        onEnded={handleEnded}
        onDuration={handleDuration}
      />
      {showPlay ? (
        <PrimaryButton>
          <PrimaryPlayIcon onClick={handlePlay} />
        </PrimaryButton>
      ) : (
        <ControllsContainer className='controlls_container'>
          <PlayButton>
            {playing && playCurrent ? <PauseIcon onClick={handlePlayPause} /> : <PlayIcon onClick={handlePlay} />}
          </PlayButton>
          <Slider
            type='range'
            min='1'
            max='100'
            step='any'
            value={played * 100}
            onChange={onSeek}
            onMouseUp={onSeekMouseUp}
            onMouseDown={onSeekMouseDown}
          />
          <Duration seconds={duration * played} />

          <VolumeButton onClick={handleToggleMuted}>{muted ? <MuteIcon /> : <VolumeIcon />}</VolumeButton>
          <Fullscreen onClick={handleClickFullscreen} style={{ margin: '0 1.5rem', cursor: 'pointer' }} />
        </ControllsContainer>
      )}
    </Container>
  );
};

export default FeedVideoPlayer;
