/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable operator-linebreak */

import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as Arrow } from '../../../assets/Dropdown.svg';
import { ReactComponent as Calender } from '../../../assets/calendar.svg';
import { borderColor, textColors } from '../../../constants';
import { FlexContainer } from '../../../../globalShared/CommonStyles';
import useTouchOutside from '../../../hooks/useTouchOutside';
import {
  endOfMonth,
  getDayOfMonth,
  getMonthYear,
  getToday,
  getYearMonthDay
} from '../../../../services/utility/moment';
import { datesInMonth } from '../../../../services/utility/Calander';
import { getMonthSet } from '../../../../services/utility/date-utils';

import { ReactComponent as UncheckedIcon } from '../../../assets/Check_box.svg';
import { ReactComponent as CheckedIcon } from '../../../assets/Check_box-selected.svg';

const Frame = styled.div`
  width: 250px;
  border-radius: 0.6rem;
  background: #101016;
  padding: 1rem;

  position: absolute;
  z-index: 9;

  ${(props) =>
    props.position === 'top' &&
    css`
      bottom: 35px;
    `};

  ${(props) =>
    props.position === 'bottom' &&
    css`
      top: 35px;
    `};
`;

const Header = styled.div`
  font-size: 18px;
  font-weight: bold;
  padding: 1rem;
  display: flex;
  justify-content: space-evenly;
`;

const Button = styled.div`
  cursor: pointer;
  & > svg {
    ${(props) =>
      props.prev &&
      css`
        transform: rotate(90deg);
      `}

    ${(props) =>
      props.next &&
      css`
        transform: rotate(-90deg);
      `}
  }
`;

const Body = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const WeekDay = styled.div`
  width: 14%;
  height: 28px;
  font-size: 14px;
  color: ${textColors.textLight};
  margin-bottom: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Day = styled.div`
  width: 14%;
  height: 28px;
  font-size: 14px;

  border-radius: 0.6rem;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  ${(props) =>
    props.isSelected &&
    css`
      background-color: #21d0b3;
      color: #0e231f;
    `}
`;

const SelectedDate = styled.p`
  font-size: 14px;
  padding: 0 1rem;
`;

const Label = styled.p`
  font-size: 14px;
  padding-right: 1rem;
  color: ${textColors.textLight};
`;

const Container = styled.div`
  width: 225px;
  position: relative;
`;

const Footer = styled.div`
  padding: 1rem 0;
  font-size: 14px;
  border-top: 1px solid ${borderColor.light};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${textColors.textLight};
  & > span {
    color: ${textColors.textWhite};
    font-size: 14px;
  }
  & > svg {
    margin: 0 5px;
    cursor: pointer;
  }
`;

const DataPicker = ({ label, value, setValue, showFooter, position = 'bottom' }) => {
  const DAYS_OF_THE_WEEK = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

  const [selectedDate, setSelectedDate] = useState(getToday());
  const [dates, setDates] = useState([]);
  const [monthSet, setMonthSet] = useState(getMonthSet(selectedDate));
  const [showPicker, setshowPicker] = useState(false);
  const [selectEndOfMonth, setSelectEndOfMonth] = useState(false);
  const dropDownRef = useRef();

  useEffect(() => {
    setDates(datesInMonth(selectedDate));
    setMonthSet(getMonthSet(selectedDate));
  }, [selectedDate]);

  const handleClick = (d) => {
    setValue(d);
    setSelectedDate(d);
    setshowPicker(false);
  };

  const handleEnd = () => {
    setSelectEndOfMonth(!selectEndOfMonth);
    if (!selectEndOfMonth) {
      handleClick(endOfMonth());
    } else {
      setSelectedDate(getToday());
    }
  };

  useTouchOutside(dropDownRef, () => setshowPicker(false));

  return (
    <Container ref={dropDownRef}>
      <FlexContainer>
        <Label>{label}</Label>
        <Calender onClick={() => setshowPicker(!showPicker)} />
        <SelectedDate>{value}</SelectedDate>
      </FlexContainer>

      {showPicker && (
        <Frame position={position}>
          <Header>
            <Button prev onClick={() => setSelectedDate(monthSet.prev)}>
              <Arrow />
            </Button>
            <div>{getMonthYear(selectedDate)}</div>
            <Button next onClick={() => setSelectedDate(monthSet.next)}>
              <Arrow />
            </Button>
          </Header>
          <Body>
            {DAYS_OF_THE_WEEK.map((d, index) => (
              <WeekDay key={d + index}>
                <>{d}</>
              </WeekDay>
            ))}
          </Body>
          <Body>
            {dates.map(({ currentMonth, date }, index) => (
              <Day
                key={index}
                isSelected={getYearMonthDay(date) === getYearMonthDay(selectedDate)}
                onClick={() => handleClick(date)}
              >
                {currentMonth ? getDayOfMonth(date) : ''}
              </Day>
            ))}
          </Body>
          {showFooter && (
            <Footer onClick={handleEnd}>
              {selectEndOfMonth ? <CheckedIcon /> : <UncheckedIcon />}
              Until Planner Ends -&nbsp;<span>{endOfMonth().format('DD MMM YY')}</span>
            </Footer>
          )}
        </Frame>
      )}
    </Container>
  );
};

export default DataPicker;
