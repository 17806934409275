/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
/*
 * reducer.js file for coach skills view
 * Author:
 * 1.Manikanta
 * @copyright of Panorbit Services LLP.
 */

import { createReducer } from 'reduxsauce';
import { Types } from './action';
import { mergeArray } from '../../../../services/utility';

const INITIAL_STATE = {
  loading: false,
  error: null,
  feedList: [],
  reload: false,
  success: false,
  page: 1,
  maxPage: 1,
  commentsLoading: false,
  commentsList: [],
  commentCount: 0
};

const getCoachFeedListStart = (state = INITIAL_STATE, action) => ({ ...state, error: null, loading: true });

const getCoachFeedListSuccess = (state = INITIAL_STATE, action) => {
  const { feedList, maxPage } = action.payload;
  const newList = feedList;
  const oldList = state.feedList;

  const newArray = mergeArray(oldList, newList);
  return {
    ...state,
    loading: false,
    reload: false,
    feedList: [...newArray],
    maxPage
  };
};

const getCoachFeedListFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const getCoachFeedCommentsStart = (state = INITIAL_STATE, action) => ({ ...state, error: null, commentsLoading: true });
const getCoachFeedCommentsSuccess = (state = INITIAL_STATE, action) => {
  const { commentCount } = action.payload;
  const newCommnetsList = action.payload.commentsList;
  const oldCommentsList = state.commentsList;
  const newArry = mergeArray(oldCommentsList, newCommnetsList);

  return {
    ...state,
    commentsList: [...newArry],
    commentCount
  };
};

const setPage = (state = INITIAL_STATE, action) => {
  const { page } = action.payload;
  return {
    ...state,
    page
  };
};

const coachSkillsReset = () => ({ ...INITIAL_STATE });

const HANDLERS = {
  [Types.GET_COACH_FEED_LIST_START]: getCoachFeedListStart,
  [Types.GET_COACH_FEED_LIST_SUCCESS]: getCoachFeedListSuccess,
  [Types.GET_COACH_FEED_LIST_FAILURE]: getCoachFeedListFailure,

  [Types.GET_COACH_FEED_COMMENTS_START]: getCoachFeedCommentsStart,
  [Types.GET_COACH_FEED_COMMENTS_SUCCESS]: getCoachFeedCommentsSuccess,
  [Types.SET_PAGE]: setPage,
  [Types.COACH_SKILLS_RESET]: coachSkillsReset
};

export const coachSkillsReducer = createReducer(INITIAL_STATE, HANDLERS);
