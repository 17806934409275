import styled from 'styled-components';

import { fontWeight } from '../../../shared/constants';

export const Calender = styled.div`
  height: auto;
`;

export const Container = styled.div`
  display: flex;
  gap: 27px;
  width: 100%;
  padding-top: 23px;
  height: calc(100vh - 175px);
  overflow-y: auto;
`;

export const IntakeSummaryContainer = styled.div`
  width: auto;
`;

export const MealsContainer = styled.div`
  width: 100%;
`;

export const IntakeSummaryHeader = styled.p`
  font-size: 16px;
  font-weight: ${fontWeight.medium};
  margin-bottom: 25px;
`;

export const MealsHeader = styled.p`
  font-size: 20px;
  font-weight: ${fontWeight.bold};
  margin-bottom: 25px;
`;

export const MsgContainer = styled.div`
  width: 100%;
  height: 60vh;
`;
