import React from 'react';
import { Switch, Route } from 'react-router-dom';

import PlayersView from '../../views/Coach/PlayersView/PlayersView';
import CoachPlayerDetailsView from '../../views/Coach/PlayersView/CoachPlayerDetailsView/CoachPlayerDetailsView';
import SkillsView from '../../views/Coach/SkillsVIew/SkillsView';
import VideoplayerView from '../../views/Coach/VideoplayerView/VideoplayerView';
import CompareView from '../../views/CompareView/CompareView';
import searchVideosView from '../../views/Coach/SearchVideosView/searchVideosView';
import StrengthView from '../../views/Coach/StrengthViews/StrengthView';
import NutritionView from '../../views/Coach/NutritionViews/NutritionView';
import SessionDetails from '../../views/Coach/StrengthViews/RecentView/SessionDetails/SessionDetails';

import { CommingSoon } from '../../globalShared/CommonStyles';
import PlannerDetailsView from '../../views/Coach/PlannerDetailsView/PlannerDetailsView';
import MealPlannerDetailView from '../../views/Coach/MealPlannerDetailView/MealPlannerDetailView';
import MindsetView from '../../views/Coach/MindsetView/MindsetView';
import HomeView from '../../views/Coach/HomeView/HomeView';
import HomeAndSupportView from '../../views/HelpAndSupport/HelpAndSupportView';

// page not found
// const PageNotFound = () => <h1 style={{ fontSize: '24px' }}>Page Not Found</h1>;

// coming  soon
const ComingSoon = () => <CommingSoon>coming soon </CommingSoon>;

// routes
class CoachRoutes extends React.Component {
  render() {
    const { path } = this.props;

    return (
      <Switch>
        <Route exact path={`${path}`} component={HomeView} />
        <Route exact path={`${path}/players/:id`} component={CoachPlayerDetailsView} />
        <Route exact path={`${path}/players`} component={PlayersView} />

        <Route exact path={`${path}/skills`} component={SkillsView} />
        <Route exact path={`${path}/skills/watchVideo/:videoId`} component={VideoplayerView} />
        <Route exact path={`${path}/skills/comparevideo/`} component={CompareView} />
        <Route exact path={`${path}/skills/searchVideo/`} component={searchVideosView} />

        <Route exact path={`${path}/strength`} component={StrengthView} />
        <Route exact path={`${path}/strength/planner/:plannerId`} component={PlannerDetailsView} />
        <Route exact path={`${path}/nutrition`} component={NutritionView} />
        <Route exact path={`${path}/nutrition/meal_planner/:playerId`} component={MealPlannerDetailView} />
        <Route exact path={`${path}/strength/:id`} component={SessionDetails} />
        <Route exact path={`${path}/mindset`} component={MindsetView} />
        <Route exact path={`${path}/help-and-support`} component={HomeAndSupportView} />
        <Route path='/*' component={ComingSoon} />
      </Switch>
    );
  }
}

export default CoachRoutes;
