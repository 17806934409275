/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { TabsComponenet, ButtonComponent, SearchBoxComponent, LoadingComponent } from '../../../globalShared';
import { Creators } from './store';
import { Creators as ModalDataCreators } from '../RestPlayers/store';
import { Creators as ExerciseCreators } from '../ExerciseView/store';
import { Creators as WorkoutCreators } from '../Workoutsview/store';
import { Creators as PlannerCreators } from '../PlannerView/store';
import { Creators as GroupCreators } from '../GroupView/store';

// components
import ExerciseView from '../ExerciseView/ExerciseView';
import GroupView from '../GroupView/GroupView';
import RecentView from './RecentView/RecentView';

import PlayersModal from '../RestPlayers/PlayersModal';
import AddGroupModal from '../RestPlayers/AddGroupModal';
import PlayersHistoryModal from '../RestPlayers/PlayersHistoryModal';
import ExerciseDetails from '../ExerciseView/EditExercise';
import { AddExerciseView } from '../ExerciseView/CreateExercise';

// styles
import { Container, ActionsContainer } from './StrengthStyles';
import Workoutsview from '../Workoutsview/Workoutsview';
// import CreateWorkout from '../Workoutsview/CreateWorkout';

import CreateWorkoutModal from '../Workoutsview/CreateWorkoutModal';
import PlannerView from '../PlannerView/PlannerView';
import CreatePlannerView from '../PlannerView/CreatePlannerView';

import { FlexContainer } from '../../../shared/components/EditUserDetailsComponent/EditUserDetails.styles';
import { FeedContainer } from '../../../globalShared/CommonStyles';
import WorkoutOverview from '../Workoutsview/WorkoutOverview';

/**
 * component
 */
const StrengthView = ({ history }) => {
  const [activeTab, setactiveTab] = useState('');

  // state to props
  const showCreateWorkout = useSelector((state) => state.coachWorkout.showModal);
  const showWorkoutDetails = useSelector((state) => state.coachWorkout.showDetails);
  const showCreatePlanner = useSelector((state) => state.coachPlanner.showCreatePlanner);
  const showCreateExercise = useSelector((state) => state.coachExercise.showCreateExercise);

  const { tab } = useSelector((state) => state.strength);

  // loaders
  const strengthLoading = useSelector((state) => state.coachStrengthFeed.loading);
  const plannerLoading = useSelector((state) => state.coachPlanner.loading);
  const groupLoading = useSelector((state) => state.groupList.loading);
  const workoutLoading = useSelector((state) => state.coachWorkout.loading);
  const exerciseLoading = useSelector((state) => state.coachExercise.loading);

  const dispatch = useDispatch();

  const handleTabChange = (label) => {
    setactiveTab(label);
  };

  const handleAddExercise = () => {
    dispatch(ExerciseCreators.setCreateExerciseModal({ showCreateExercise: true }));
  };

  const handleSubmitExercise = () => {
    dispatch(WorkoutCreators.setShowmodal({ show: true }));
  };

  // search handers
  const handleExerciseSearch = (searchKey) => {
    dispatch(ExerciseCreators.setSearchKey({ searchKey }));
  };

  const handleWorkoutSearch = (searchKey) => {
    dispatch(WorkoutCreators.setWorkoutSearchKey({ searchKey }));
  };

  const handlePlannerSearch = (searchKey) => {
    dispatch(PlannerCreators.setPlannerSearchKey({ searchKey }));
  };

  const handleGroupSearch = (searchKey) => {
    dispatch(GroupCreators.setGroupSearchKey({ searchKey }));
  };

  const addGroupModal = useSelector((state) => state.modalData.addGroupModal);
  const exerciseId = useSelector((state) => state.coachExercise.exerciseId);

  useEffect(() => {
    if (tab) {
      handleTabChange(tab);
    } else {
      handleTabChange('Recent');
    }
  }, [tab]);

  useEffect(
    () => () => {
      dispatch(Creators.setStrengthTab({ tab: null }));
    },
    []
  );
  return (
    <>
      {(strengthLoading || plannerLoading || groupLoading || workoutLoading || exerciseLoading) && <LoadingComponent />}
      <Container>
        <ActionsContainer>
          {activeTab === 'Exercises' && (
            <FlexContainer>
              <SearchBoxComponent placeHolder='Search Exercise' onSearchChange={handleExerciseSearch} />
              <ButtonComponent
                buttonText='Create Exercise'
                isButtonAction={true}
                buttonClick={handleAddExercise}
                marginLeft={true}
                margin={false}
              />
            </FlexContainer>
          )}

          {activeTab === 'Workouts' && (
            <FlexContainer>
              <SearchBoxComponent placeHolder='Search Workout' onSearchChange={handleWorkoutSearch} />
              <ButtonComponent
                buttonText='Create Workout'
                isButtonAction={true}
                buttonClick={handleSubmitExercise}
                marginLeft={true}
                margin={false}
              />
            </FlexContainer>
          )}

          {activeTab === 'Planner' && (
            <FlexContainer>
              <SearchBoxComponent placeHolder='Search With Planner' onSearchChange={handlePlannerSearch} />
              <ButtonComponent
                buttonText='Create Planner'
                isButtonAction={true}
                buttonClick={() => dispatch(PlannerCreators.setshowCreatePlanner({ show: true }))}
                marginLeft={true}
                margin={false}
              />
            </FlexContainer>
          )}
          {activeTab === 'Groups' && (
            <FlexContainer>
              <SearchBoxComponent placeHolder='Search Group' onSearchChange={handleGroupSearch} />
              <ButtonComponent
                buttonText='Create Group'
                isButtonAction={true}
                marginLeft={true}
                margin={false}
                buttonClick={() => {
                  dispatch(
                    ModalDataCreators.setAddGroupModal({
                      addGroupModal: !addGroupModal,
                      addGroupModalMode: 'ADD'
                    })
                  );
                }}
              />
            </FlexContainer>
          )}
        </ActionsContainer>
        <TabsComponenet getactiveTab={handleTabChange} tab={activeTab}>
          <div label='Recent'>
            <FeedContainer style={{ height: ' calc(100vh - 162px)' }}>
              <RecentView />
            </FeedContainer>
          </div>
          <div label='Planner'>
            <PlannerView history={history} />
          </div>

          <div label='Groups'>
            <GroupView />
          </div>

          <div label='Workouts'>
            <Workoutsview />
          </div>

          <div label='Exercises'>
            <ExerciseView />
          </div>
        </TabsComponenet>
      </Container>
      {showCreateExercise && <AddExerciseView />}

      {showCreateWorkout && <CreateWorkoutModal />}
      {showCreatePlanner && (
        <CreatePlannerView
          onCancel={() => {
            dispatch(PlannerCreators.setshowCreatePlanner({ show: false }));
            dispatch(PlannerCreators.setPlannerDetails(null));
          }}
        />
      )}
      {activeTab === 'Groups' && <PlayersModal />}
      {addGroupModal && <AddGroupModal />}
      {activeTab === 'Groups' && <PlayersHistoryModal />}
      {exerciseId && <ExerciseDetails exerciseId={exerciseId} />}
      {showWorkoutDetails && <WorkoutOverview />}
    </>
  );
};

export default StrengthView;
