/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Creators } from './store';

// styles
import { H1, TextSmallLight, Sleep, LegendButtons } from './SleepStatsStyles';
import { FlexContainer } from '../../../globalShared/CommonStyles';
import { LineChart } from '../../../globalShared';

import { getDay, getMothSmall, getYearMonthDay } from '../../../services/utility/moment';
import { MainContainer } from './SleepTrackStyles';
import { getPastDays } from '../HomeView/utlity';

const SleepStats = () => {
  const dispatch = useDispatch();

  const [days, setDays] = useState(7);
  const [dataType, setDataType] = useState('days');

  // values from redux store
  const sleepStats = useSelector((state) => state.playerMindset.sleepStats);
  const avgSleepDuration = useSelector((state) => state.playerMindset.avgSleepDuration);

  const avgSleepDurationArray = avgSleepDuration && avgSleepDuration.split(':');
  const avgSleepDurationHours = (avgSleepDuration && avgSleepDurationArray[0]) || 0;
  const avgSleepDurationMinutes = (avgSleepDuration && avgSleepDurationArray[1]) || 0;

  // data for line chart

  const pastDays = getPastDays({ type: dataType, days });

  pastDays.forEach((item) => {
    let filterDay = [];

    if (dataType === 'month') {
      filterDay = sleepStats.filter((stat) => getMothSmall(stat.time) === item.MM);
    } else {
      filterDay = sleepStats.filter((stat) => getYearMonthDay(stat.time) === item.MMDDYY);
    }

    item.sleepTime = filterDay.length > 0 ? filterDay[0].duration : null;
  });

  const sleepDates = [];
  const sleepHours = [];

  pastDays.reverse().forEach((item) => {
    if (dataType === 'month') {
      sleepDates.push(getMothSmall(item.MMDDYY));
    } else {
      sleepDates.push(getDay(item.MMDDYY));
    }
    sleepHours.push(item.sleepTime);
  });

  // line chart
  const chartData = (canvas) => {
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, canvas.height, 0, 0);
    gradient.addColorStop(0, '#21D0B300');
    gradient.addColorStop(1, '#21D0B382');

    return {
      labels: sleepDates,
      datasets: [
        {
          data: sleepHours,
          fill: true,
          backgroundColor: gradient,
          borderColor: 'rgba(75,192,192,1)',
          lineTension: 0
        }
      ]
    };
  };

  // effect hooks
  useEffect(() => {
    dispatch(Creators.getPlayerSleepStats({ page: 1, count: 30, dataType, days }));
  }, [days]);

  // handlers
  const handleWeekSleep = () => {
    setDataType('days');
    setDays(7);
  };

  const handleMonthSleep = () => {
    setDataType('days');
    setDays(30);
  };

  const handleSixMonthSleep = () => {
    setDataType('month');
    setDays(180);
  };

  return (
    <MainContainer>
      <Sleep>
        <TextSmallLight style={{ margin: '0rem 1rem' }}>Average Sleep Duration</TextSmallLight>
        <H1>{`${avgSleepDurationHours} Hrs ${avgSleepDurationMinutes} Min`}</H1>
      </Sleep>
      <FlexContainer style={{ justifyContent: 'center' }}>
        <LegendButtons active={days === 7} onClick={handleWeekSleep}>
          Weekly
        </LegendButtons>
        <LegendButtons active={days === 30} onClick={handleMonthSleep}>
          Monthly
        </LegendButtons>
        <LegendButtons active={days === 180} onClick={handleSixMonthSleep}>
          6 months
        </LegendButtons>
      </FlexContainer>
      <div style={{ height: '120px', position: 'relative' }}>
        <LineChart data={chartData} yLabel='Sleep Duration' />
      </div>
    </MainContainer>
  );
};

export default SleepStats;
