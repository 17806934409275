/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { ReactComponent as CloseIcon } from '../../assets/Close.svg';

import {
  Container,
  CardContainer,
  Title,
  CloseButton,
  Header,
  Body,
  Footer,
  FlexContainer,
  Width50,
  Muted,
  Label
} from './EditUserDetails.styles';
import ButtonComponenet from '../ButtonComponent';
import InputComponent from '../InputComponent';
import { ValdErrMsg } from '../../../globalShared/CommonStyles';
import { localString } from '../../../services/locallization/localStrings';

// component
const EditUserDetails = (props) => {
  const { title, closePopupFunc, submitFunc, defaultData } = props;

  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  const { firstName, lastName, email, uid } = defaultData;

  const { handleSubmit, register, errors } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { firstName, lastName }
  });

  const onSubmit = (data, e) => {
    submitFunc({ ...data, userId: uid });
    e.target.reset();
  };

  return (
    <Container>
      <CardContainer>
        <Header>
          <Title>{title}</Title>

          <CloseButton onClick={closePopupFunc}>
            <CloseIcon />
          </CloseButton>
        </Header>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Body>
            <FlexContainer>
              <Width50>
                <InputComponent
                  label={string.firstName}
                  placeholderText={string.firstName}
                  ipName='firstName'
                  ipRef={register({ required: 'required' })}
                />
                {errors.firstName && <ValdErrMsg>{errors.firstName.message}</ValdErrMsg>}
              </Width50>
              <Width50>
                <InputComponent
                  label={string.lastName}
                  placeholderText={string.lastName}
                  ipName='lastName'
                  ipRef={register({ required: 'required' })}
                />
                {errors.lastName && <ValdErrMsg>{errors.lastName.message}</ValdErrMsg>}
              </Width50>
            </FlexContainer>

            <Label>Email</Label>

            <Muted>{email}</Muted>
          </Body>
          <Footer>
            <ButtonComponenet
              buttonText={string.cancel}
              bgType='dark'
              isButtonAction={true}
              buttonClick={closePopupFunc}
            />
            <ButtonComponenet buttonType='submit' buttonText={string.save} />
          </Footer>
        </form>
      </CardContainer>
    </Container>
  );
};

export default EditUserDetails;
