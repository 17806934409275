/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useSelector } from 'react-redux';

import {
  Wrapper,
  Column,
  AttachmentContainer,
  SubHeading,
  FlexContainer,
  AttachButton,
  DeleteButton,
  AddMoreButton,
  AddMoreButtonContainer,
  FileName,
  Container
} from '../../AttachmentsListComponent/AttachmentsList.styles';

import { ReactComponent as DeleteIcon } from '../../../assets/delete.svg';
import { ReactComponent as AttachIcon } from '../../../assets/attach-files.svg';
import TextArea from '../Textarea';
import { localString } from '../../../../services/locallization/localStrings';

const HistoryAttachments = (props) => {
  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  const formFields = {
    file: null,
    fileName: '',
    description: '',
    mediaUrl: null
  };

  const { value, onFileChange, desChange, setValue, onDelete, name } = props;

  const handleAddMore = () => {
    setValue([...value, formFields]);
  };

  const remove = (index, id) => {
    if (id) {
      onDelete(id);
    }
    const newArry = value.filter((_, i) => i !== index);
    setValue(newArry);
  };

  const handleChange = ({ target }) => {
    const index = target.name;
    const file = target.files[0];
    const fileName = file.name;
    onFileChange({ file, index, fileName });
  };

  return (
    <Wrapper>
      {value.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Column key={index}>
          <AttachmentContainer>
            <FlexContainer style={{ margin: '0 0' }}>
              <SubHeading>{index + 1}</SubHeading>

              <Container>
                <>
                  <input
                    style={{ display: ' none' }}
                    type='file'
                    name={index}
                    id={`index_${index + 1}`}
                    onChange={handleChange}
                  />
                  <FileName>{item.fileName}</FileName>
                  <label htmlFor={`index_${index + 1}`}>
                    <AttachButton>
                      <AttachIcon />
                    </AttachButton>
                  </label>
                </>

                <DeleteButton type='button' onClick={() => remove(index, item._id)}>
                  <DeleteIcon />
                </DeleteButton>
              </Container>
            </FlexContainer>

            <TextArea
              placeholder={string.enterDetailsHere}
              height='100px'
              value={item.description}
              setValue={(value) => desChange({ value, index })}
            />
          </AttachmentContainer>
        </Column>
      ))}
      <Column>
        <AddMoreButtonContainer>
          <AddMoreButton type='button' onClick={handleAddMore}>
            {value.length ? `+ ${string.addMore}` : `+ ${string.add} ${name} ${string.history}`}
          </AddMoreButton>
        </AddMoreButtonContainer>
      </Column>
    </Wrapper>
  );
};

export default HistoryAttachments;
