import styled from 'styled-components';

import { fontSize, fontWeight, textColors } from '../../constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Heading = styled.h1`
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.titleMediumSmall};
  margin: 3rem 0 0.5rem 0;
`;

export const SubHeading = styled.p`
  font-size: ${fontSize.mediumDefault};
  color: ${textColors.textLight};
  margin-bottom: 4rem;
`;
