import { appLanguage } from '../../shared/constants/lists';

export const localString = {
  [appLanguage.english]: {
    addFood: 'Add Food',
    home: 'Home',
    players: 'Players',
    skills: 'Skills',
    strength: 'Strength',
    nutrition: 'Nutrition',
    mindset: 'Mindset',
    helpNsupport: 'Help and Support',
    users: 'Users',
    billing: 'Billing',
    teamSettings: 'Team Settings',
    addMore: 'Add More',
    select: 'Select',
    comments: 'Comments',
    noComments: 'No Comments',
    enterCommentsHere: 'Enter Your Comment Here…',
    noCommentsYet: 'No Comments Yet',
    compareVideo: 'Compare Video',
    by: 'by',
    remove: 'Remove',
    cancel: 'Cancel',
    submit: 'Submit',
    delete: 'Delete',
    save: 'Save',
    dNd: 'Drag and Drop or Click To Upload',
    firstName: 'First Name',
    lastName: 'Last Name',
    enterDetailsHere: 'Enter Details Here…',
    add: 'Add',
    history: 'History',
    notFound: 'Not Found',
    morning: 'Morning',
    afterNoon: 'Afternoon',
    evening: 'Evening',
    night: 'Night',
    videoSpeed: 'Video Speed',
    removeFromCompare: ' Remove From Compare',
    addToCompare: 'Add to Compare',
    uploadedVideoIn: 'Uploaded a Video In',
    noEntries: 'No Entries',
    viewing: 'Viewing',
    of: 'of',
    tcrScore: 'TCR Score',
    uRperforming: 'You are performing better than',
    otherPlayers: 'of other players',
    invalidFileFormat: 'Invalid File Format',
    pasteUrl: 'Paste Video URL Here',
    videoUrl: 'Video URL',
    tutorialVideoTitle: 'Tutorial Video Title',
    videoTitle: 'Video Title',
    clickToUpload: 'Click to Upload Video',
    success: 'Success',
    admins: 'Admins',
    coaches: 'Coaches',
    teamReadiness: 'Team Readiness',
    teamSNC: 'Team Strength & Conditioning',
    userManagement: 'User Management',
    allUsers: 'All Users',
    manageYourUsers: ' Manage Your Users From Here',
    addUsers: 'Add User',
    editUser: 'Edit User',
    totalBalance: 'Total Balance',
    nextDueDate: 'Next Due Date',
    addMoney: 'Add Money',
    valueDuplicated: 'Value Duplicated',
    required: 'Required',
    invalidEmail: 'Invalid Email',
    uploadCSV: 'Upload CSV',
    email: 'Email',
    typeofUser: 'Type of User',
    enterPersonsFirstName: "Enter Person's First Name",
    enterPersonsSecondName: "Enter Person's Second Name",
    enterPersonsEmail: "Enter Person's Email",
    file: 'File',
    reUpload: 'Re-Upload',
    uploadAValidFile: 'Upload a Valid File',
    admin: 'Admin',
    coach: 'Coach',
    player: 'Player',
    all: 'All',
    phone: 'Phone',
    actions: 'Actions',
    tcrAdmin: 'TCRAdmin',
    noUsersFound: 'No Users Found',
    deleteUser: 'Delete User',
    want2DeleteUser: 'Are You Sure You Permanently Want To Delete The User?',
    deleteAllTheInfo: 'It Will Delete All The Information Along With The User',
    searchUsers: 'Search Users',
    addUser: 'Add User',
    welcome: 'Welcome',
    createYourPassword: 'Create Your Password',
    pleaseEnteraValidPassword: 'Please Enter a Valid Password',
    passwordMustContainAtLeast8Characters: 'Password Must Contain at Least 8 Characters',
    mustIncludeLowUppNumSpec: 'Must Include Lower Case, Upper Case, Number & a Special Character',
    enterPassword: 'Enter Password',
    reEnterPassword: 'Re-Enter Password',
    forgotPassWord: 'Forgot Password',
    verifyYourEmail: 'Verify Your Email',
    enterEmailAddress: 'Enter Email Address Here',
    pleaseEnteraValidEmail: 'Please Enter a Valid Email Address',
    verifyEmail: 'Verify Email',
    logIn2Continue: 'Login to Continue',
    login: 'Login',
    back2Login: 'Back To Login',
    otpVerfication: 'OTP Verification',
    enterThe6digit: 'Please Enter The 6-digit OTP That You',
    lastCompletedMeal: 'Last Completed Meal ',
    noDataAvailable: 'No Data Available',
    skill: 'Skill',
    saved: 'Saved',
    profile: 'Profile',
    name: 'Name',
    resetPassword: 'Reset Password',
    currentPassword: 'Current Password',
    upload: 'Upload',
    theNumberShuoldContainTenDigits: 'The Number Should Contain 10 Digits ',
    onlyNumbers: 'Should Contain Only Numbers',
    newPassword: 'New Password',
    passwordDoesntMatch: "Password Doesn't Match",
    logout: 'Logout',
    createExercise: 'Create Exercise',
    exerciseTitle: 'Exercise Title',
    exerciseCategory: 'Exercise Category',
    enterCue: 'Enter Cue',
    cues: 'Cues',
    equipment: 'Equipment',
    titleRequired: 'Title Required',
    uploadFile: 'Upload File',
    enterValidUrl: 'Enter a Valid Url',
    edit: 'Edit',
    exercises: 'Exercises',
    createdBy: 'Created By',
    title: 'Title',
    groups: 'Groups',
    assignedTo: 'Assigned To',
    startDate: 'Start Date',
    endDate: 'End Date',
    planners: 'Planners',
    fromToday: 'Date Should be From today',
    ntBAfterEndDate: "Date Can't be After End Date",
    plannerTitle: 'Planner Title',
    assignedToGrp: 'Assign to Group',
    searchGroup: 'Search Group',
    assignedPlayers: 'Assigned Players',
    mealPreference: 'Meal Preference',
    perDaynutrientsIntake: 'Per Day Nutrients Intake',
    proteinG: 'Protein (g)',
    carbohydratesG: 'Carbohydrates (g)',
    fatsG: 'fats (g)',
    totalKcal: 'Total Kcal',
    totalWaterIntake: 'Total Water Intake (L)',
    mealSnack: 'Meal/Snack',
    kcal: 'Kcal',
    time: 'Time',
    intake: 'Intake',
    remarks: 'Remarks'
  }
};
