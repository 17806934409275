import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  LogoHeaderComponent,
  ButtonComponent,
  InputComponent,
  FormHeader,
  PasswordInputComponent
} from '../../../globalShared';

import { Creators } from '../CreatePassword/store';
import Toast, { Toasts } from '../../../services/toast';

// styles
import { Container, FormContainer, ErrorMsg } from './ResetPasswordstyles';

// text constants
const HeadingText = 'Forgot Password';
const SubHeadingText = 'Set a New Password';
const PasswordPlaceHolderText = 'Enter Password';
const confirmPasswordText = 'Re-Enter Password';
const TypePassWord = 'password';

/**
 * component
 */
const ResetPassword = (props) => {
  const { register, errors, setError, handleSubmit } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      password: '',
      confirmPassword: ''
    }
  });
  const { history } = props;

  const dispatch = useDispatch();

  // store values
  const serverError = useSelector((state) => state.createPassword.error);
  const isCreatePasswordSuccess = useSelector((state) => state.createPassword.success);

  const onSubmit = (data) => {
    if (data.password !== data.confirmPassword) {
      setError('confirmPassword', { message: 'Password Not matched' });
      Toast.error('Password Not matched');
      return;
    }
    dispatch(Creators.createPassword({ password: data.password }));
  };

  // effects hooks
  useEffect(() => {
    if (isCreatePasswordSuccess) {
      history.push('/resetsuccess');
    }
  }, [isCreatePasswordSuccess]);

  useEffect(() => {
    if (serverError) {
      Toast.error(serverError, 2);
    }
  }, [serverError]);

  useEffect(
    () => () => {
      dispatch(Creators.resetPass());
    },
    []
  );

  return (
    <>
      <LogoHeaderComponent />
      <Container>
        <FormHeader headingText={HeadingText} subHeadingText={SubHeadingText} />

        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <PasswordInputComponent
            placeholderText={PasswordPlaceHolderText}
            ipName={TypePassWord}
            ipRef={register({
              required: 'Please enter a valid password',
              minLength: {
                value: 8,
                message: 'Password must contain at least 8 characters'
              },
              pattern: {
                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                message: 'must Include, lower, upper, number, special'
              }
            })}
          />
          <ErrorMsg>{errors.password?.message}</ErrorMsg>

          <InputComponent
            placeholderText={confirmPasswordText}
            ipType={TypePassWord}
            ipName='confirmPassword'
            ipRef={register({
              required: 'Please Enter a Valid Password',
              minLength: {
                value: 8,
                message: 'Password must contain at least 8 characters'
              },
              pattern: {
                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                message: 'must Include, lower, upper, number, special'
              }
            })}
          />
          <ErrorMsg>{errors.confirmPassword?.message}</ErrorMsg>

          <ButtonComponent buttonText='Reset Password' isDisabled={false} buttonType='submit' />
        </FormContainer>
      </Container>
      <Toasts />
    </>
  );
};

export default ResetPassword;
