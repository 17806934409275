import styled from 'styled-components';

import { fontSize, fontWeight } from '../../../shared/constants';

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const HeaderTxt = styled.h1`
  font-size: ${fontSize.titleVerySmall};
  font-weight: ${fontWeight.bold};
  margin: 0 1rem;
  text-transform: capitalize;
`;

export const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PlayerContainer = styled.div`
  width: 73%;
`;

export const Player = styled.div`
  height: 393px !important;
  background: #000;
  border-radius: 10px 10px 0 0;
`;
export const CommentsContainer = styled.div`
  width: 25%;
  height: 541px;
`;
