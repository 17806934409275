/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

import Toast, { Toasts } from '../../../services/toast';
import { Creators } from './store';

// componenets
import {
  LogoHeaderComponent,
  ButtonComponent,
  FormHeader,
  InputComponent,
  PasswordInputComponent
} from '../../../globalShared';

// styles
import { Container, FormContainer, LinkContainer, ErrorMsg } from './Login.styles';
import { localString } from '../../../services/locallization/localStrings';

// text constants

// const EmailPlaceHolderText = 'Enter Email Address Here';
const PasswordPlaceHolderText = 'Enter Password Here';
const TypePassWord = 'password';

/**
 * Componenet
 */
const LoginView = (props) => {
  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  const { register, errors, handleSubmit } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
      password: ''
    }
  });
  const { history } = props;

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(Creators.login(data));
  };

  //  state variables
  // const userType = useSelector((store) => store.login.userType);
  const loginSuccess = useSelector((store) => store.login.loginSuccess);
  const passwordChanged = useSelector((store) => store.login.passwordChanged);
  const metaUpdated = useSelector((store) => store.login.metaUpdated);

  const userType = useSelector((store) => store.login.userType);
  const stateError = useSelector((state) => state.login.error);
  // const accessToken = useSelector((state) => state.login.accessToken);

  useEffect(() => {
    if (loginSuccess && passwordChanged && metaUpdated) {
      history.push(`/${userType}/`);
    }
  }, [passwordChanged, metaUpdated, loginSuccess]);

  useEffect(() => {
    if (loginSuccess && !passwordChanged) {
      history.push('/createpassword');
    }
  }, [passwordChanged, loginSuccess]);

  useEffect(() => {
    if (passwordChanged && !metaUpdated) {
      history.push('/userdetails');
    }
  }, [passwordChanged, metaUpdated]);

  useEffect(() => {
    if (stateError) {
      Toast.error(stateError, 2);
    }
  }, [stateError]);

  useEffect(
    () => () => {
      dispatch(Creators.resetUserData());
    },
    []
  );
  return (
    <>
      <LogoHeaderComponent />
      <Container>
        <FormHeader headingText={string.welcome} subHeadingText={string.logIn2Continue} />
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <InputComponent
            placeholderText={string.enterEmailAddress}
            ipType='email'
            ipName='email'
            ipRef={register({ required: string.pleaseEnteraValidEmail })}
          />
          {errors.email && <ErrorMsg>{errors.email.message}</ErrorMsg>}
          <PasswordInputComponent
            placeholderText={PasswordPlaceHolderText}
            ipType={TypePassWord}
            ipName={TypePassWord}
            ipRef={register({
              required: string.pleaseEnteraValidPassword
            })}
          />
          {errors.password && <ErrorMsg>{errors.password.message}</ErrorMsg>}

          <LinkContainer
            to={{
              pathname: '/forgotpsw'
            }}
          >
            {`${string.forgotPassWord} ?`}
          </LinkContainer>
          <ButtonComponent buttonText={string.login} isDisabled={true} buttonType='submit' />
        </FormContainer>
      </Container>
      <Toasts />
    </>
  );
};

export default LoginView;
