/* eslint-disable no-underscore-dangle */
/* eslint-disable no-else-return */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/jsx-one-expression-per-line */

import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import { Creators } from './store';
import { Creators as CommonCreators } from '../../store';

// styles
import {
  Title,
  ModalBody,
  Details,
  SummaryContainer,
  ProgressContainer,
  ProgressDetails,
  KcalTaken,
  TotalKcal,
  MoreDetails,
  Carb,
  Protein,
  Fat,
  WaterContainer,
  WaterHeader,
  BottleContainer,
  CommentsCard,
  SummaryBlock,
  CommentsBlock
} from './IntakeSummaryModalStyles';

import { CommentsComponent } from '../../../globalShared';

// assets

import { ReactComponent as KcalIcon } from '../../../shared/assets/kcal_icon.svg';
import { ReactComponent as FilledBottle } from '../../../shared/assets/filled_bottle.svg';
import { ReactComponent as EmptyBottle } from '../../../shared/assets/empty_bottle.svg';

// component
import ProgressRing from './ProgressRing';
import { _converIntoKAndM } from '../../../services/utility/commurize';

const IntakeSummaryModal = (props) => {
  const { data = {} } = props;
  const dispatch = useDispatch();
  const count = 100;

  const intakeCommentsList = useSelector((state) => state.coachMealRecent.intakeSummaryCommentsList);
  const intakeCommentCount = useSelector((state) => state.coachMealRecent.intakeCommentCount);
  const intakeCommentmaxPage = useSelector((state) => state.coachMealRecent.intakeCommentmaxPage);

  const { reloadComments } = useSelector((state) => state.main);

  const [page, setPage] = React.useState(1);

  // effect hooks
  useEffect(() => {
    dispatch(Creators.getCoachIntakeSummaryComments({ _id: data._id, count, page }));
  }, [page]);

  useEffect(() => {
    if (reloadComments && data._id) {
      dispatch(Creators.getCoachIntakeSummaryComments({ _id: data._id, count }));
    }
  }, [reloadComments]);

  const handleSubmitComment = (content, id) => {
    dispatch(CommonCreators.addComment({ content, id }));
  };

  const handleUpdateComments = () => {
    if (page < intakeCommentmaxPage) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    dispatch(Creators.getCoachIntakeSummaryComments({ _id: data._id, count, page }));
  }, []);

  useEffect(
    () => () => {
      dispatch(Creators.resetIntake());
    },
    []
  );

  const bottles = [1, 2, 3, 4, 5, 6, 7, 8];
  const waterPerBottle = 0.5;
  const completelyFilled = parseInt(data.waterTaken / waterPerBottle, 10);

  return (
    <div key={uuidv4()}>
      <ModalBody>
        <SummaryBlock>
          <Details>
            <Title className='lite' style={{ margin: '2rem 0' }}>
              TODAY&apos;S TARGET
            </Title>
            <SummaryContainer>
              <ProgressContainer>
                <ProgressRing radius={105} stroke={8} progress={(data.playerMealCount / data.actualMealCount) * 100} />
                <ProgressDetails>
                  <KcalIcon />
                  <KcalTaken>
                    <h1>{_converIntoKAndM(data.kcalTaken)}</h1>
                    <p>Kcal</p>
                  </KcalTaken>
                  <TotalKcal>
                    <p>Target</p>
                    <p>{data.totalKcal}</p>
                  </TotalKcal>
                </ProgressDetails>
              </ProgressContainer>
            </SummaryContainer>

            <MoreDetails>
              <Carb>
                <p>Carb</p>
                <p>{_converIntoKAndM(data.totalCarbohydrates)}gm</p>
              </Carb>
              <Protein>
                <p>Protein</p>
                <p>{_converIntoKAndM(data.totalProtein)}gm</p>
              </Protein>
              <Fat>
                <p>Fat</p>
                <p>{_converIntoKAndM(data.totalFats)}gm</p>
              </Fat>
            </MoreDetails>

            <WaterContainer>
              <WaterHeader>
                <p>Water Intake</p>
                <p>{`${data.waterTaken}L / ${data.totalWaterIntake}L`}</p>
              </WaterHeader>
              <BottleContainer>
                {bottles.map((bottle) => {
                  if (bottle <= completelyFilled) {
                    return (
                      <React.Fragment key={bottle}>
                        <FilledBottle />
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <React.Fragment key={bottle}>
                        <EmptyBottle />
                      </React.Fragment>
                    );
                  }
                })}
              </BottleContainer>
            </WaterContainer>
          </Details>
        </SummaryBlock>
        <CommentsBlock>
          <CommentsCard>
            <CommentsComponent
              count={intakeCommentCount}
              updateComments={handleUpdateComments}
              submitComment={handleSubmitComment}
              commentsList={intakeCommentsList}
              id={data._id}
            />
          </CommentsCard>
        </CommentsBlock>
      </ModalBody>
    </div>
  );
};

export default IntakeSummaryModal;
