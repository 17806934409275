/* eslint-disable no-console */
/* eslint-disable no-shadow */
/*
 * saga.js file for coach workouts view
 * Author:
 * 1. manikanta
 * @copyright of Panorbit Services LLP.
 */

import { put } from 'redux-saga/effects';
import { Creators } from './action';

import { WebService } from '../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../services/axios/endPoints';

export function* getCoachWorkoutListSaga({ payload }) {
  const { page, count } = payload;

  try {
    yield put(Creators.getCoachWorkoutListStart());
    const fullURL = yield `${apiEndPoints.createWorkout}`;

    const response = yield WebService.get(fullURL, { params: { page, count } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      yield put(
        Creators.getCoachWorkoutListSuccess({
          workoutsList: data[0],
          totalItems: data[2].totalCount,
          maxPage: data[1].page_count
        })
      );
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getCoachWorkoutListFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getCoachWorkoutListFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getCoachWorkoutListFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getCoachWorkoutListFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getCoachWorkoutListFailure(payload));
    }
  }
}

export function* createWorkoutSaga({ payload }) {
  const { exercises, title, _id, description, updateCount } = payload;

  let METHOD = 'POST';
  try {
    yield put(Creators.createWorkoutStart());
    const fullURL = yield `${apiEndPoints.createWorkout}`;

    //  eslint-disable-next-line no-undef
    const dataToApi = new FormData();
    dataToApi.append('title', title);
    dataToApi.append('description', description);
    dataToApi.append('exercises', JSON.stringify(exercises));

    if (_id) {
      METHOD = 'PUT';
      dataToApi.append('_id', _id);
      dataToApi.append('updateCount', updateCount);
    }

    const response = yield WebService({
      method: METHOD,
      url: fullURL,
      data: dataToApi
    });

    if (response.status >= 200 && response.status < 300) {
      const { message } = response.data;
      yield put(Creators.createWorkoutSuccess({ message }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.createWorkoutFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.createWorkoutFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.createWorkoutFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.createWorkoutFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.createWorkoutFailure(payload));
    }
  }
}

export function* deleteCoachWorkoutSaga({ payload }) {
  const { id } = payload;

  try {
    yield put(Creators.deleteCoachWorkoutStart());
    const fullURL = yield `${apiEndPoints.createWorkout}`;

    const response = yield WebService({
      method: 'DELETE',
      url: fullURL,
      data: { _id: id }
    });

    if (response.status >= 200 && response.status < 300) {
      const { message } = response.data;

      yield put(Creators.deleteCoachWorkoutSuccess({ message }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.deleteCoachWorkoutFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.deleteCoachWorkoutFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.deleteCoachWorkoutFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.deleteCoachWorkoutFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.deleteCoachWorkoutFailure(payload));
    }
  }
}

export function* searchWorkoutSaga({ payload }) {
  const { page, count, searchKey } = payload;

  try {
    yield put(Creators.searchWorkoutStart());
    const fullURL = yield `${apiEndPoints.workOutSearch}`;

    const response = yield WebService.get(fullURL, { params: { page, count, searchKey } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(Creators.searchWorkoutSuccess({ workoutsList: data[0], totalItems: data[2].totalCount }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.searchWorkoutFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.searchWorkoutFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.searchWorkoutFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.searchWorkoutFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.searchWorkoutFailure(payload));
    }
  }
}

export function* searchWorkoutListSaga({ payload }) {
  const { searchKey } = payload;

  try {
    yield put(Creators.searchWorkoutStart());
    const fullURL = yield `${apiEndPoints.searchWorkOutList}`;

    const response = yield WebService.get(fullURL, { params: { searchKey } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(Creators.searchWorkoutListSuccess({ workoutsList: data }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.searchWorkoutFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.searchWorkoutFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.searchWorkoutFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.searchWorkoutFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.searchWorkoutFailure(payload));
    }
  }
}
