/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useForm } from 'react-hook-form';

import { Creators } from './store';
import Toast, { Toasts } from '../../../services/toast';
import {
  LogoHeaderComponent,
  ButtonComponent,
  InputComponent,
  FormHeader,
  LoadingComponent
} from '../../../globalShared';

// styles
import { Container, FormContainer, LinkContainer, ErrorMsg } from './ForgotPassword.styles';
import { ReactComponent as Back } from '../../../shared/assets/back.svg';
import { localString } from '../../../services/locallization/localStrings';

/**
 *
 * component
 */
const ForgotPasswordView = (props) => {
  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  const { handleSubmit, register, errors } = useForm({ mode: 'onChange', reValidateMode: 'onChange' });

  const dispatch = useDispatch();
  const { history } = props;

  // sate values
  const { error, success, loading } = useSelector((state) => state.forgotPassword);

  const onSubmit = (data) => {
    dispatch(Creators.verifyEmail({ email: data.email }));
    dispatch(Creators.setUserEmail({ email: data.email }));
  };

  // effects Hooks

  useEffect(
    () => () => {
      dispatch(Creators.resetData());
    },
    []
  );

  useEffect(() => {
    if (error) {
      Toast.error(error, 2);
    }
  }, [error]);

  useEffect(() => {
    if (success) {
      history.push('/verification');
    }
  }, [success]);

  return (
    <>
      {loading && <LoadingComponent />}
      <LogoHeaderComponent />
      <Container>
        <FormHeader headingText={string.forgotPassWord} subHeadingText={string.verifyYourEmail} />
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <InputComponent
            placeholderText={string.enterEmailAddress}
            ipType='email'
            ipName='email'
            ipRef={register({ required: string.pleaseEnteraValidEmail })}
          />
          <ErrorMsg>{errors.email?.message}</ErrorMsg>

          <ButtonComponent buttonText={string.verifyEmail} isDisabled={false} buttonType='submit' />
          <LinkContainer
            to={{
              pathname: '/'
            }}
          >
            <Back />
            {string.back2Login}
          </LinkContainer>
        </FormContainer>
      </Container>

      <Toasts />
    </>
  );
};

export default ForgotPasswordView;
