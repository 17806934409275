/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
// import { v4 as uuidv4 } from 'uuid';
import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  tab: null
};

const setActiveTab = (state = INITIAL_STATE, action) => {
  const { tab } = action.payload;
  return {
    ...state,
    tab
  };
};

const HANDLERS = {
  [Types.SET_ACTIVE_TAB]: setActiveTab
};

export const NutritonReducer = createReducer(INITIAL_STATE, HANDLERS);
