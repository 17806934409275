import styled from 'styled-components';
import { fontSize, borderColor } from '../../constants';

export const AccordionContent = styled.div`
  font-size: ${fontSize.mediumDefault};
  margin-top: 1rem;

  display: flex;
  flex-direction: column;
`;

export const MoodTimeWrapper = styled.div`
  padding: 1rem 4rem 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  border-top: 1px solid ${borderColor.light};
`;

export const MoodTime = styled.p`
  display: inline-block;
  font-size: 12px;

  color: #ffffff;
  opacity: 0.5;
`;

export const Thought = styled.p`
  font-size: 14px;
  color: #f6f6f6;
  padding-bottom: 1rem;

  &:last-child {
    padding-bottom: 0;
  }
`;

export const NoEntries = styled.p`
  text-align: center;
  font-size: 14px;
  color: #f6f6f6;
  opacity: 0.5;
`;
