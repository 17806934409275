/* eslint-disable no-unused-expressions */
/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Creators } from './store';
import { TableStyles, FlexContainer, EmptyContentText } from '../../../globalShared/CommonStyles';
import Toast, { Toasts } from '../../../services/toast';

// styles
import { PlayerTitle, ArrayString, OpenPlanner, Menu, Option } from './MealPlannerStyles';

// icons
import { ReactComponent as MenuIcon } from '../../../shared/assets/3dot_menu.svg';
import { ReactComponent as Arrow } from '../../../shared/assets/Dropdown.svg';
import { AvatarComponent, PaginationComponent } from '../../../globalShared';
import { shortenText } from '../../../services/utility';

const { Table, Tr, Th, Td } = TableStyles;

/* component */

const MealPlannerView = ({ history }) => {
  const [count, setCount] = useState(10);
  const [showOptionId, setshowOptionId] = useState(null);

  const dispatch = useDispatch();

  const { mealPlannerList, reloadList, error, success, loading, searchKey, page, totalItems } = useSelector(
    (state) => state.coachMealPlanner
  );

  const deleteMealPlanner = (id) => {
    dispatch(Creators.deleteCoachMealPlanner({ id }));
    setshowOptionId(null);
  };

  const handlePage = (page) => {
    dispatch(Creators.setMealPlannerPage({ page }));
  };

  useEffect(() => {
    if (success) {
      const reset = () => dispatch(Creators.resetSuccess());
      Toast.success('success', 2, reset);
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      const reset = () => dispatch(Creators.resetError());
      Toast.error(error, 2, reset);
    }
  }, [error]);

  useEffect(() => {
    if (searchKey) {
      dispatch(Creators.searchCoachMealPlanner({ count, page, searchKey }));
    } else {
      dispatch(Creators.getCoachMealPlannerList({ page, count }));
    }
  }, [page, count, searchKey]);

  useEffect(() => {
    if (reloadList) {
      searchKey
        ? dispatch(Creators.searchCoachMealPlanner({ count, page, searchKey }))
        : dispatch(Creators.getCoachMealPlannerList({ page, count }));
    }
  }, [reloadList]);

  useEffect(
    () => () => {
      dispatch(Creators.resetMealPlanner());
    },
    []
  );

  return (
    <>
      <Toasts />
      {mealPlannerList.length ? (
        <>
          <div style={{ height: 'calc(100vh - 211px)', overflowY: 'auto', paddingRight: '0.5rem' }}>
            <Table>
              <thead>
                <tr>
                  <Th pLeft='2'>Player</Th>
                  <Th>Assigned Plans</Th>
                  <Th>Created By</Th>
                  <Th />
                  <Th />
                </tr>
              </thead>
              <tbody>
                {mealPlannerList.map((plan) => {
                  const assignedPlan = plan.assignedMealPlan.join(', ');
                  const showPlanner = plan.mealPreferenceStatus;
                  return (
                    <Tr style={{ height: '80px' }} key={plan._id}>
                      <Td style={{ maxWidth: '25%' }}>
                        <FlexContainer>
                          <AvatarComponent url={plan.profilePicture} />
                          <PlayerTitle>{plan.playerName}</PlayerTitle>
                        </FlexContainer>
                      </Td>
                      <Td>
                        <ArrayString width='150px'>{assignedPlan}</ArrayString>
                      </Td>
                      <Td>{shortenText(plan.createdBy, 15, '...')}</Td>
                      <Td
                        style={{ cursor: `${showPlanner ? 'cursor' : ''}`, width: '180px' }}
                        onClick={() => {
                          if (showPlanner) history.push(`nutrition/meal_planner/${plan._id}`);
                        }}
                      >
                        {showPlanner && (
                          <OpenPlanner>
                            Open Planner
                            <Arrow />
                          </OpenPlanner>
                        )}
                      </Td>
                      <Td style={{ position: 'relative' }}>
                        <MenuIcon onClick={() => setshowOptionId(plan._id)} style={{ cursor: 'pointer' }} />
                        <div>
                          <Menu show={showOptionId === plan._id} onMouseLeave={() => setshowOptionId(null)}>
                            <Option
                              isActive={!!assignedPlan}
                              onClick={() => {
                                if (assignedPlan) deleteMealPlanner(plan._id);
                              }}
                            >
                              Clear plan
                            </Option>
                          </Menu>
                        </div>
                      </Td>
                    </Tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <PaginationComponent
            onChange={(value) => {
              handlePage(value.currentPage);
              setCount(value.count);
            }}
            totalItems={totalItems}
            title='Planners'
            pageNo={page}
          />
        </>
      ) : (
        !loading && <EmptyContentText style={{ height: 'calc(100vh - 162px)' }}>No Data Available</EmptyContentText>
      )}
    </>
  );
};

export default MealPlannerView;
