/* eslint-disable no-undef */
/* eslint-disable no-shadow */
import { put } from 'redux-saga/effects';
import { Creators } from './action';

import { WebService } from '../../../../services/axios/webServices';
import { apiEndPoints, rootConfig } from '../../../../services/axios/endPoints';

export function* getPlayerMoodStats({ payload }) {
  const { days } = payload;

  try {
    yield put(Creators.getPlayerMoodStatsStart());

    const fullURL = yield `${apiEndPoints.moodStats}`;
    const response = yield WebService.get(fullURL, { params: { days } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(
        Creators.getPlayerMoodStatsSuccess({
          moodStats: data[0],
          stats: data[1].moodDict || { level1: 0, level2: 0, level3: 0, level4: 0, level5: 0, level6: 0 }
        })
      );
    } else {
      throw response;
    }
  } catch (error) {
    // console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getPlayerMoodStatsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getPlayerMoodStatsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getPlayerMoodStatsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getPlayerMoodStatsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getPlayerMoodStatsFailure(payload));
    }
  }
}

export function* getPlayerSleepStatsSaga({ payload }) {
  const { page, count, dataType, days } = payload;

  try {
    yield put(Creators.getPlayerSleepStatsStart());

    const fullURL = yield `${apiEndPoints.listSleep}`;
    const response = yield WebService.get(fullURL, { params: { page, count, dataType, days } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(
        Creators.getPlayerSleepStatsSuccess({
          sleepStats: data[0] || [],
          avgSleepDuration: data[1] ? data[1].avgDuration : '00:00:00'
        })
      );
    } else {
      throw response;
    }
  } catch (error) {
    // console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getPlayerSleepStatsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getPlayerSleepStatsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getPlayerSleepStatsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getPlayerSleepStatsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getPlayerSleepStatsFailure(payload));
    }
  }
}

export function* getPlayerMeditationStatsSaga({ payload }) {
  const { page, count, days, dataType } = payload;

  try {
    yield put(Creators.getPlayerMeditationStatsStart());
    const fullURL = yield `${apiEndPoints.listMeditation}`;

    const response = yield WebService.get(fullURL, { params: { page, count, dataType, days } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(
        Creators.getPlayerMeditationStatsSuccess({
          meditationStats: data[0] || [],
          avgMeditationDuration: data[1] ? data[1].avgDuration : '00:00:00'
        })
      );
    } else {
      throw response;
    }
  } catch (error) {
    // console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getPlayerMeditationStatsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getPlayerMeditationStatsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getPlayerMeditationStatsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getPlayerMeditationStatsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getPlayerMeditationStatsFailure(payload));
    }
  }
}

export function* getPlayerTodaysMeditationCheckSaga({ payload }) {
  const { date } = payload;

  try {
    yield put(Creators.getPlayerTodaysMeditationCheckStart());
    const fullURL = yield `${apiEndPoints.previousMeditationCheck}`;

    const response = yield WebService.get(fullURL, { params: { date } });

    if (response.status >= 200 && response.status < 300) {
      let basicMeditation = null;
      let guidedMeditation = null;
      let expertMeditation = null;

      const { data } = response.data;

      if (data.basicMeditation) {
        basicMeditation = data.basicMeditation;
      }
      if (data.guidedMeditation) {
        guidedMeditation = data.guidedMeditation;
      }
      if (data.expertMeditation) {
        expertMeditation = data.expertMeditation;
      }

      yield put(
        Creators.getPlayerTodaysMeditationCheckSuccess({
          todaysMeditationCheck: [basicMeditation, guidedMeditation, expertMeditation],
          basicMeditation,
          guidedMeditation,
          expertMeditation
        })
      );
    } else {
      throw response;
    }
  } catch (error) {
    // console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getPlayerTodaysMeditationCheckFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getPlayerTodaysMeditationCheckFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getPlayerTodaysMeditationCheckFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getPlayerTodaysMeditationCheckFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getPlayerTodaysMeditationCheckFailure(payload));
    }
  }
}

export function* addPlayerMeditationCommentSaga({ payload }) {
  const { content, mindsetId } = payload;

  try {
    yield put(Creators.addPlayerMeditationCommentStart());

    const dataToApi = new FormData();
    dataToApi.append('referenceId', mindsetId);
    dataToApi.append('content', content);

    const fullURL = yield `${apiEndPoints.addComment}`;

    const response = yield WebService({
      method: 'POST',
      url: fullURL,
      data: dataToApi
    });

    if (response.status >= 200 && response.status < 300) {
      const { message } = response.data;

      yield put(Creators.addPlayerMeditationCommentSuccess({ message }));
    } else {
      throw response;
    }
  } catch (error) {
    // console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.addPlayerMeditationCommentFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.addPlayerMeditationCommentFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.addPlayerMeditationCommentFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.addPlayerMeditationCommentFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.addPlayerMeditationCommentFailure(payload));
    }
  }
}

export function* getPlayerMeditationTrackCommentsSaga({ payload }) {
  const { _id, page = '1' } = payload;

  try {
    yield put(Creators.getPlayerMeditationTrackCommentsStart());
    const fullURL = yield `${apiEndPoints.commentList}`;

    const response = yield WebService.get(fullURL, { params: { _id, page, count: 100 } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      const payload = {
        meditationTrackCommentsList: data[0]
      };

      yield put(Creators.getPlayerMeditationTrackCommentsSuccess(payload));
    } else {
      throw response;
    }
  } catch (error) {
    // console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getPlayerMeditationTrackCommentsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getPlayerMeditationTrackCommentsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getPlayerMeditationTrackCommentsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getPlayerMeditationTrackCommentsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getPlayerMeditationTrackCommentsFailure(payload));
    }
  }
}

export function* deletePlayerTodaysMeditationCheckSaga({ payload }) {
  const { _id, meditationName } = payload;
  let endPoint = '';
  let dataToApi;
  if (meditationName === 'basicMeditation') {
    endPoint = 'DeleteBasicMeditation';
    dataToApi = { _id };
  } else if (meditationName === 'guidedMeditation') {
    endPoint = 'DeleteGuidedMeditation';
    dataToApi = { _id };
  } else if (meditationName === 'expertMeditation') {
    endPoint = 'DeleteExpertMeditation';
    dataToApi = { _id };
  }

  try {
    yield put(Creators.deletePlayerTodaysMeditationCheckStart());
    const fullURL = yield `${rootConfig.apiRoot}/${endPoint}/`;

    let response;
    if (_id) {
      response = yield WebService({
        method: 'DELETE',
        url: fullURL,
        data: dataToApi
      });
    }

    if (response.status >= 200 && response.status < 300) {
      const { message } = response.data;

      yield put(Creators.deletePlayerTodaysMeditationCheckSuccess({ message }));
    } else {
      throw response;
    }
  } catch (error) {
    // console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.deletePlayerTodaysMeditationCheckFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.deletePlayerTodaysMeditationCheckFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.deletePlayerTodaysMeditationCheckFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.deletePlayerTodaysMeditationCheckFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.deletePlayerTodaysMeditationCheckFailure(payload));
    }
  }
}

export function* setPlayerMeditationDurationSaga({ payload }) {
  const { meditationName, basicMeditation, guidedMeditation, expertMeditation } = payload;
  let endPoint = '';
  let dataToApi;

  if (meditationName === 'basicMeditation') {
    endPoint = 'addBasicMeditatation';
    dataToApi = { basicMeditation };
  } else if (meditationName === 'guidedMeditation') {
    endPoint = 'AddGuidedMeditatation';
    dataToApi = { guidedMeditation };
  } else if (meditationName === 'expertMeditation') {
    endPoint = 'AddExpertMeditatation';
    dataToApi = { expertMeditation };
  }

  try {
    yield put(Creators.setPlayerMeditationDurationStart());
    const fullURL = yield `${rootConfig.apiRoot}/${endPoint}/`;

    // console.log(fullURL);
    // console.log(dataToApi);
    let response;
    if (dataToApi.basicMeditation || dataToApi.guidedMeditation || dataToApi.expertMeditation) {
      response = yield WebService({
        method: 'POST',
        url: fullURL,
        data: dataToApi
      });
    }

    if (response.status >= 200 && response.status < 300) {
      const { message } = response.data;

      yield put(Creators.setPlayerMeditationDurationSuccess({ message }));
    } else {
      throw response;
    }
  } catch (error) {
    // console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.setPlayerMeditationDurationFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.setPlayerMeditationDurationFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.setPlayerMeditationDurationFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.setPlayerMeditationDurationFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.setPlayerMeditationDurationFailure(payload));
    }
  }
}

export function* editPlayerMeditationDurationSaga({ payload }) {
  const { _id, meditationName, basicMeditation, guidedMeditation, expertMeditation } = payload;
  let endPoint = '';
  let dataToApi;

  if (meditationName === 'basicMeditation') {
    endPoint = 'EditBasicMeditation';
    dataToApi = { _id, basicMeditation };
  } else if (meditationName === 'guidedMeditation') {
    endPoint = 'EditGuidedMeditation';
    dataToApi = { _id, guidedMeditation };
  } else if (meditationName === 'expertMeditation') {
    endPoint = 'EditExpertMeditation';
    dataToApi = { _id, expertMeditation };
  }

  try {
    yield put(Creators.editPlayerMeditationDurationStart());
    const fullURL = yield `${rootConfig.apiRoot}/${endPoint}/`;

    let response;
    if (dataToApi.basicMeditation || dataToApi.guidedMeditation || dataToApi.expertMeditation) {
      response = yield WebService({
        method: 'PUT',
        url: fullURL,
        data: dataToApi
      });
    }

    if (response.status >= 200 && response.status < 300) {
      const { message } = response.data;

      yield put(Creators.editPlayerMeditationDurationSuccess({ message }));
    } else {
      throw response;
    }
  } catch (error) {
    // console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.editPlayerMeditationDurationFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.editPlayerMeditationDurationFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.editPlayerMeditationDurationFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.editPlayerMeditationDurationFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.editPlayerMeditationDurationFailure(payload));
    }
  }
}

export function* getPlayerTodaysSleepCheckSaga({ payload }) {
  const { date } = payload;

  try {
    yield put(Creators.getPlayerTodaysSleepCheckStart());
    const fullURL = yield `${apiEndPoints.previousSleepCheck}`;

    const response = yield WebService.get(fullURL, { params: { date } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      yield put(Creators.getPlayerTodaysSleepCheckSuccess({ todaysSleepCheck: data }));
    } else {
      throw response;
    }
  } catch (error) {
    // console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getPlayerTodaysSleepCheckFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getPlayerTodaysSleepCheckFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getPlayerTodaysSleepCheckFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getPlayerTodaysSleepCheckFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getPlayerTodaysSleepCheckFailure(payload));
    }
  }
}

export function* addPlayerSleepSaga({ payload }) {
  const { bedTime, wakeupTime, duration } = payload;

  try {
    yield put(Creators.addPlayerSleepStart());

    const fullURL = yield `${apiEndPoints.addSleep}`;

    const response = yield WebService({
      method: 'POST',
      url: fullURL,
      data: { bedTime, wakeupTime, duration }
    });

    if (response.status >= 200 && response.status < 300) {
      const { message } = response.data;

      yield put(Creators.addPlayerSleepSuccess({ message }));
    } else {
      throw response;
    }
  } catch (error) {
    // console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.addPlayerSleepFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.addPlayerSleepFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.addPlayerSleepFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.addPlayerSleepFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.addPlayerSleepFailure(payload));
    }
  }
}

export function* deletePlayerSleepSaga({ payload }) {
  const { _id } = payload;

  try {
    yield put(Creators.deletePlayerSleepStart());
    const fullURL = yield `${apiEndPoints.deleteSleep}`;

    const response = yield WebService({
      method: 'DELETE',
      url: fullURL,
      data: { _id }
    });

    if (response.status >= 200 && response.status < 300) {
      const { message } = response.data;

      yield put(Creators.deletePlayerSleepSuccess({ message }));
    } else {
      throw response;
    }
  } catch (error) {
    // console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.deletePlayerSleepFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.deletePlayerSleepFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.deletePlayerSleepFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.deletePlayerSleepFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.deletePlayerSleepFailure(payload));
    }
  }
}

export function* getPlayerSleepTrackCommentsSaga({ payload }) {
  const { _id, page = '1' } = payload;

  try {
    yield put(Creators.getPlayerSleepTrackCommentsStart());
    const fullURL = yield `${apiEndPoints.commentList}`;

    const response = yield WebService.get(fullURL, { params: { _id, page, count: 100 } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      const payload = {
        sleepCommentsList: data[0]
      };

      yield put(Creators.getPlayerSleepTrackCommentsSuccess(payload));
    } else {
      throw response;
    }
  } catch (error) {
    // console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getPlayerSleepTrackCommentsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getPlayerSleepTrackCommentsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getPlayerSleepTrackCommentsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getPlayerSleepTrackCommentsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getPlayerSleepTrackCommentsFailure(payload));
    }
  }
}
