import React from 'react';
import PropTypes from 'prop-types';
import { LinkButton } from './LinkButton.styles';

class LinkButtonComoponent extends React.Component {
  render() {
    const { path, bgType, buttonText } = this.props;
    return (
      <div>
        <LinkButton to={path} bgtype={bgType}>
          {buttonText}
        </LinkButton>
      </div>
    );
  }
}

LinkButtonComoponent.defaultProps = {
  bgType: 'primary'
};

LinkButtonComoponent.propTypes = {
  path: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  bgType: PropTypes.string
};
export default LinkButtonComoponent;
