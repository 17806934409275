import React from 'react';
import PropTypes from 'prop-types';

// styles
import { Container } from './IconButtton.styles';

class IconButtonComponent extends React.Component {
  handleClick = () => {
    const { isOnClickAction, onClick } = this.props;

    if (isOnClickAction) onClick();
  };

  render() {
    const { isOnClickAction, buttonType, bgType, children, margin } = this.props;
    return (
      <Container
        type={buttonType}
        onClick={() => this.handleClick()}
        isOnClickAction={isOnClickAction}
        bgType={bgType}
        margin={margin}
      >
        {children}
      </Container>
    );
  }
}

IconButtonComponent.defaultProps = {
  buttonType: 'button',
  bgType: 'primary',
  isOnClickAction: false,
  onClick: () => null,
  margin: ''
};

IconButtonComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.string, PropTypes.node]).isRequired,
  isOnClickAction: PropTypes.bool,
  onClick: PropTypes.func,
  bgType: PropTypes.string,
  buttonType: PropTypes.string,
  margin: PropTypes.string
};

export default IconButtonComponent;
