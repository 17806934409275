/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import { Label, Container, DropdownContainer, Item, MainContainer } from './FormDropdownStyles';

// import { SearchBoxComponent } from '../../../globalShared';

// icon
import { ReactComponent as Arrow } from '../../assets/Dropdown.svg';

// component
class FormDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      selectedValue: props.defaultValue
    };

    this.toggleHidden = this.toggleHidden.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = (value) => {
    this.setState({
      selectedValue: value
    });
    this.toggleHidden();
  };

  toggleHidden() {
    const { isVisible } = this.state;
    this.setState({
      isVisible: !isVisible
    });
  }

  render() {
    const { dropDownArray, dropDownName, dropDownRef, label } = this.props;
    const { isVisible, selectedValue } = this.state;
    return (
      <div>
        <Label>{label}</Label>
        <MainContainer style={{ position: 'relative' }}>
          <Container isVisible={isVisible} onClick={this.toggleHidden}>
            {selectedValue || 'Select'}
            <Arrow />
          </Container>

          <input defaultValue={selectedValue} style={{ display: 'none' }} name={dropDownName} ref={dropDownRef} />

          {isVisible && (
            <DropdownContainer>
              {dropDownArray.map((item) => {
                const { value } = item;
                return (
                  <Item key={value} value={value} onClick={() => this.handleClick(value)}>
                    {value}
                  </Item>
                );
              })}
            </DropdownContainer>
          )}
        </MainContainer>
      </div>
    );
  }
}

FormDropdown.defaultProps = {
  label: '',
  defaultValue: ''
};

FormDropdown.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  dropDownArray: PropTypes.array.isRequired,
  dropDownName: PropTypes.string.isRequired,
  dropDownRef: PropTypes.func.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.string
};
export default FormDropdown;
