/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable no-shadow */
/*
 * saga.js file for videoplayer view
 * Author:
 * 1. manikanta
 * @copyright of Panorbit Services LLP.
 */

import { put } from 'redux-saga/effects';
import { Creators } from './action';

import { WebService } from '../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../services/axios/endPoints';

export function* getVideoDetailsSaga({ payload }) {
  const { id, page = '1' } = payload;
  try {
    yield put(Creators.getVideoDetailsStart());
    const fullURL = yield `${apiEndPoints.videoDetail}`;

    const response = yield WebService.get(fullURL, { params: { _id: id, page } });

    if (response.status >= 200 && response.status < 300) {
      const data = response.data.data[0];

      yield put(Creators.getVideoDetailsSuccess({ videoDetails: data[0] }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getVideoDetailsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getVideoDetailsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getVideoDetailsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getVideoDetailsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getVideoDetailsFailure(payload));
    }
  }
}

export function* getVideoCommentsListSaga({ payload }) {
  const { id, page = '1' } = payload;
  try {
    yield put(Creators.getVideoCommentsListStart());
    const fullURL = yield `${apiEndPoints.commentList}`;

    const response = yield WebService.get(fullURL, { params: { _id: id, page, count: 100 } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      const payload = {
        commentsList: data[0] || [],
        maxPage: data[1].page_count,
        commentsCount: data[2].commentCount
      };
      yield put(Creators.getVideoCommentsListSuccess(payload));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getVideoCommentsListFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getVideoCommentsListFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getVideoCommentsListFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getVideoCommentsListFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getVideoCommentsListFailure(payload));
    }
  }
}
