import React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { Creators } from './store';

// assets
import { ReactComponent as CloseIcon } from '../../../shared/assets/Close.svg';
import { ReactComponent as NutritionTickIcon } from '../../../shared/assets/Nutrition_tick.svg';
import { ReactComponent as InputIntakeIcon } from '../../../shared/assets/add_session.svg';

// styles
import {
  ModalContainer,
  Card,
  Title,
  CloseButton,
  Header,
  MarkDoneContainer,
  MarkDoneText,
  InputManuallyContainer,
  InputManuallyText
} from './MealActionModalStyles';

const MealActionModal = (props) => {
  const { _id, date, meal, onCancel, showManualInput } = props;
  const dispatch = useDispatch();

  const { title, kcal, time, intake, mealId } = meal;
  const mealDetails = {
    title,
    kcal: parseInt(kcal, 10),
    time,
    intake,
    mealId
  };

  const mealData = {
    _id,
    action: 'Done',
    timeCompleted: meal.time,
    meals: mealDetails
  };

  const todaysDate = moment().format('YYYY-MM-DD');

  const handleMarkDone = () => {
    if (date === todaysDate) {
      dispatch(Creators.setPlayerMealUpdate({ mealData }));
      onCancel();
    }
  };

  const handleManualInput = () => {
    onCancel();
    showManualInput();
  };

  return (
    <>
      <ModalContainer>
        <Card>
          <Header>
            <div>
              <Title>Choose an Option</Title>
            </div>

            <div>
              <CloseButton onClick={onCancel}>
                <CloseIcon />
              </CloseButton>
            </div>
          </Header>

          <MarkDoneContainer onClick={handleMarkDone}>
            <NutritionTickIcon />
            <MarkDoneText>Mark as Done</MarkDoneText>
          </MarkDoneContainer>

          <InputManuallyContainer onClick={handleManualInput}>
            <InputIntakeIcon />
            <InputManuallyText>Input Intakes Manually</InputManuallyText>
          </InputManuallyContainer>
        </Card>
      </ModalContainer>
    </>
  );
};

export default MealActionModal;
