import styled from 'styled-components';

import { borderColor, fontWeight } from '../../../shared/constants';

export const Calender = styled.div`
  height: auto;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  margin-top: 26px;

  height: calc(100vh - 186px);
  overflow-y: auto;
`;

export const MindsetNavContainer = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 21px;

  border-right: 1px solid ${borderColor.light};
  padding-right: 20px;

  height: 100%;
  overflow-y: auto;
`;

export const MindsetCard = styled.div`
  height: 182px;
  border: 1px solid ${(props) => (props.activeCard ? '#21d0b3' : 'transparent')};
  border-radius: 6px;

  // overflow: hidden;
  cursor: pointer;

  position: relative;
  background-color: #251d38;

  & > svg {
    opacity: ${(props) => (props.activeCard ? 1 : 0.15)};
  }

  transition: border ease-out 0.2s;
  &:hover {
    border: 1px solid #21d0b3;
  }
`;

export const CardHeadContainer = styled.div`
  padding: 1.5rem;
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 1rem;
  }
`;

export const CardHeading = styled.p`
  font-size: 14px;
  text-transform: capitalize;
  padding-right: 1rem;
  border-right: 2px solid ${borderColor.medium};
  color: #f6f6f6;
  margin-right: 1rem;
`;

export const CardSubHeading = styled.p`
  font-size: 16px;
  font-weight: ${fontWeight.bold};

  color: #f6f6f6;
  text-transform: capitalize;
`;

export const MindsetContainer = styled.div`
  width: calc(100% - 367px);
  height: 100%;
  overflow-y: auto;
`;
