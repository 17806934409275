/* eslint-disable indent */
import styled, { css } from 'styled-components';
import { accentColor, backgroundColor, borderColor, fontSize, gradient, textColors } from '../../constants';

export const Day = styled.div`
  width: 28px;

  font-size: 16px;
  color: ${(props) => (props.active ? `${accentColor.primary}` : `${textColors.textLight}`)};
  white-space: break-spaces;

  text-align: center;
  cursor: pointer;
  position: relative;
  padding-bottom: 1rem;
  transition: all 0.3s;

  &:before {
    content: '';
    width: ${(props) => (props.isAvailable ? '3px' : '0')};
    height: ${(props) => (props.isAvailable ? '3px' : '0')};
    background: ${accentColor.primary};

    border-radius: 50%;

    position: absolute;
    right: 0;
    top: 0;
  }

  &:after {
    content: '';
    height: ${(props) => (props.active ? '2px' : '0')};
    width: 100%;
    position: absolute;
    bottom: -10px;
    background: ${gradient.primary};
    left: 0;
    border-radius: 2px 2px 0px 0px;
  }
`;

export const Button = styled.div`
  cursor: pointer;
  & > svg {
    ${(props) =>
      props.prev &&
      css`
        transform: rotate(90deg);
      `}

    ${(props) =>
      props.next &&
      css`
        transform: rotate(-90deg);
      `}
  }
`;

export const MonthButton = styled(Button)`
  font-size: 14px;
  padding: 1rem 1.5rem;
  border: 1px solid ${borderColor.light};
  border-radius: 5px;
  cursor: pointer;
  & > svg {
    margin: 0 0.5rem;
  }
`;

export const ToggleButton = styled.div`
  background-color: ${backgroundColor.darkOpaq};
  padding: 1rem;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;

  border: ${(props) => (props.active ? '1px' : '0px')} solid #21d0b333;

  & > svg {
    margin: 0 0.5rem;
  }
`;

export const P = styled.p`
  font-size: ${fontSize.medium};
  color: ${(props) => (props.light ? `${textColors.textLight}` : `${textColors.textWhite}`)};
`;

export const H3 = styled.p`
  font-size: ${fontSize.mediumDefault};
  font-weight: ${(props) => (props.bold ? '700' : 'normal')};
`;

export const MonthContainer = styled.div`
  width: 300px;
  height: 240px;
  position: absolute;
  background: #15151c;
  border-radius: 5px;
  z-index: 9999;
`;

export const MonthContainerHeader = styled.div`
  padding: 2rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Block = styled.div`
  padding: 1rem 2rem;
  font-size: ${(props) => (props.font ? `${props.font}px` : `${fontSize.medium}`)};
  background: ${(props) => (props.active ? `${accentColor.primary}` : '#4e436926')};
  border-radius: 5px;
  margin: 0.5rem;
  min-width: 64px;
  color: ${(props) => (props.active ? `${textColors.textDark}` : `${textColors.textWhite}`)};
  cursor: pointer;
`;

export const MonthView = styled.div`
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MonthDaysContainer = styled.div`
  height: 100%;
  width: 500px;
  border: 0.5px solid ${borderColor.light};
  border-radius: 0px 0px 6px 6px;
  margin: 0 3rem;
`;

export const WeekRow = styled.div`
  position: relative;
`;

export const DayInMonth = styled.div`
  width: 14%;
  height: 75px;
  font-size: ${fontSize.medium};
  margin: 0.1%;
  padding: 1rem 1.5rem;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #8792ac52;
  cursor: pointer;

  ${(props) =>
    props.currentMonth &&
    css`
      color: ${textColors.textLight};
    `}

  ${(props) =>
    props.isSelected &&
    css`
      color: ${accentColor.primary};
    `}

  &:before {
    content: '';
    width: ${(props) => (props.active ? '3px' : '0')};
    height: ${(props) => (props.active ? '3px' : '0')};
    background: ${accentColor.primary};
    position: relative;
    border-radius: 50%;
    left: 25px;
    top: -5px;
  }
`;

export const FlexWraper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;
