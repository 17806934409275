import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Creators } from './store';

// assets
import { ReactComponent as CloseIcon } from '../../../shared/assets/Close.svg';

// styles
import {
  ModalContainer,
  ContentContainer,
  CloseButton,
  FormTitle,
  DividerContainer,
  FormFooter
} from './CreateCollectionModalStyles';

import { Divider } from '../../../globalShared/CommonStyles';

// components
import { FormInput } from '../../../shared/components/FormComponents';
import { ButtonComponent } from '../../../globalShared';

const CreateCollectionModal = () => {
  const dispatch = useDispatch();

  const [collectionName, setCollectionName] = useState({
    name: ''
  });
  const [isNameFilled, setIsNameFilled] = useState(false);

  function checkCollectionNameFilled(props) {
    const { name } = props;
    const isName = !!`${name}`.trim().length;

    return isName;
  }

  useEffect(() => {
    const isTitle = checkCollectionNameFilled({ ...collectionName });
    setIsNameFilled(isTitle);
  }, [collectionName]);

  const handleClose = () => {
    dispatch(Creators.setShowCreateCollectionModal({ showCreateCollection: false }));
    dispatch(Creators.setShowManageCollectionModal({ showManageCollection: true }));
  };

  const handleCancel = () => {
    setCollectionName(collectionName);
    dispatch(Creators.setShowCreateCollectionModal({ showCreateCollection: false }));
    dispatch(Creators.setShowManageCollectionModal({ showManageCollection: true }));
  };

  const handleSubmit = () => {
    const collection = { ...collectionName };
    dispatch(Creators.setPlayerCollectionName({ data: collection }));
    handleCancel();
  };

  return (
    <ModalContainer>
      <CloseButton onClick={handleClose}>
        <CloseIcon />
      </CloseButton>
      <ContentContainer>
        <form>
          <div>
            <FormTitle>Create Collection</FormTitle>
          </div>

          <FormInput
            label='Title'
            placeholder='Collection Title'
            value={collectionName.name}
            setValue={(value) => setCollectionName({ name: value })}
          />
        </form>

        <DividerContainer>
          <Divider />
        </DividerContainer>

        <FormFooter>
          <div>
            <ButtonComponent buttonText='Cancel' isButtonAction={true} buttonClick={handleCancel} bgType='dark' />
            <ButtonComponent buttonText='Submit' isButtonAction={isNameFilled} buttonClick={handleSubmit} />
          </div>
        </FormFooter>
      </ContentContainer>
    </ModalContainer>
  );
};

export default CreateCollectionModal;
