import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { CommingSoon } from '../../globalShared/CommonStyles';
import AdminHomeView from '../../views/admin/HomeView/HomeView';

import UserView from '../../views/admin/UsersView/UsersView';
import HomeAndSupportView from '../../views/HelpAndSupport/HelpAndSupportView';

// page not found
const PageNotFound = () => <h1 style={{ fontSize: '24px' }}>Page Not Found</h1>;

// coming  soon
const ComingSoon = () => <CommingSoon>coming soon </CommingSoon>;

// routes
class AdminRoutes extends React.Component {
  render() {
    const { path } = this.props;

    return (
      <Switch>
        <Route exact path={`${path}`} component={AdminHomeView} />
        <Route exact path={`${path}/users`} component={UserView} />
        {/* <Route exact path={`${path}/billing`} component={ComingSoon} /> */}
        <Route exact path={`${path}/settings`} component={ComingSoon} />
        <Route exact path={`${path}/help-and-support`} component={HomeAndSupportView} />

        <Route path='/*' component={PageNotFound} />
      </Switch>
    );
  }
}

export default AdminRoutes;
