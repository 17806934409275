/* eslint-disable no-console */
/* eslint-disable no-shadow */
/*
 * saga.js file for skills view
 * Author:
 * 1. manikanta
 * @copyright of Panorbit Services LLP.
 */

import { put } from 'redux-saga/effects';
import { Creators } from './action';

import { WebService } from '../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../services/axios/endPoints';

export function* getCoachFeedList({ payload }) {
  const { page, count, playerId } = payload;

  try {
    yield put(Creators.getCoachFeedListStart());

    let fullURL = yield `${apiEndPoints.coachSkillFeed}`;

    if (playerId) {
      fullURL = yield `${apiEndPoints.PlayerVideoList}`;
    }

    const response = yield WebService.get(fullURL, { params: { page, count, _id: playerId } });

    if (response.status >= 200 && response.status < 300) {
      const { videos } = response.data.data;
      yield put(Creators.getCoachFeedListSuccess({ feedList: videos[0], maxPage: videos[1].page_count }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getCoachFeedListFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getCoachFeedListFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getCoachFeedListFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getCoachFeedListFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getCoachFeedListFailure(payload));
    }
  }
}

export function* getCoachFeedCommentsSaga({ payload }) {
  const { id, page = '1' } = payload;
  try {
    yield put(Creators.getCoachFeedCommentsStart());
    const fullURL = yield `${apiEndPoints.commentList}`;

    const response = yield WebService.get(fullURL, { params: { _id: id, page, count: 100 } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      const payload = {
        commentsList: data[0],
        maxPage: data[1].page_count,
        commentCount: data[2].commentCount
      };

      yield put(Creators.getCoachFeedCommentsSuccess(payload));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getCoachFeedCommentsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getCoachFeedCommentsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getCoachFeedCommentsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getCoachFeedCommentsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getCoachFeedCommentsFailure(payload));
    }
  }
}
