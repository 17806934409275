/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
import { put } from 'redux-saga/effects';
import { Creators } from './action';

import { WebService } from '../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../services/axios/endPoints';
import { Creators as LoginCreators } from '../../../auth/LoginView/store';

export function* updateCoachPersonalDetailsSaga({ payload }) {
  try {
    const { firstName, lastName, imageFile, phoneNumber, picDelete } = payload;

    yield put(Creators.updateCoachPersonalDetailsStart());
    const fullURL = yield `${apiEndPoints.personalDetails}`;
    // data to be updated
    const dataToApi = new FormData();
    dataToApi.append('firstName', firstName);
    dataToApi.append('lastName', lastName);
    dataToApi.append('phoneNumber', phoneNumber);
    imageFile ? dataToApi.append('profilePicture', imageFile) : '';
    picDelete && dataToApi.append('picDelete', picDelete);

    const response = yield WebService({
      method: 'put',
      url: fullURL,
      data: dataToApi
    });

    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.updateCoachPersonalDetailsSuccess());
    } else {
      throw response;
    }
  } catch (error) {
    console.log(error);

    let payload;
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.updateCoachPersonalDetailsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.updateCoachPersonalDetailsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.updateCoachPersonalDetailsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.updateCoachPersonalDetailsFailure(payload));
      }
    } else {
      payload = {
        error: error.message
      };

      yield put(Creators.updateCoachPersonalDetailsFailure(payload));
    }
  }
}

export function* resetCoachPasswordSaga({ payload }) {
  try {
    const { password, currentPassword } = payload;

    yield put(Creators.resetCoachPasswordStart());
    const fullURL = yield `${apiEndPoints.resetPassword}`;

    const response = yield WebService({
      method: 'POST',
      url: fullURL,
      data: { password, currentPassword }
    });

    if (response.status >= 200 && response.status < 300) {
      const { message } = response.data;

      yield put(Creators.resetCoachPasswordSuccess(message));
      yield put(LoginCreators.logout());
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.resetCoachPasswordFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.resetCoachPasswordFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.resetCoachPasswordFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.resetCoachPasswordFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.resetCoachPasswordFailure(payload));
    }
  }
}
