/* eslint-disable no-unused-vars */
/*
 * reducer.js file for coach search video view
 * Author:
 * 1.Manikanta
 * @copyright of Panorbit Services LLP.
 */

import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  loading: false,
  error: null,
  success: false,
  videosList: [],
  pageNo: 1
};

const getTeamVideoSearchListStart = (state = INITIAL_STATE, action) => ({ ...state, error: null, loading: true });

const getTeamVideoSearchListSuccess = (state = INITIAL_STATE, action) => {
  const { videosList } = action.payload;
  return {
    ...state,
    loading: false,
    reload: false,
    videosList
  };
};

const getTeamVideoSearchListFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const searchReset = () => ({ ...INITIAL_STATE });

const HANDLERS = {
  [Types.GET_TEAM_VIDEO_SEARCH_LIST_START]: getTeamVideoSearchListStart,
  [Types.GET_TEAM_VIDEO_SEARCH_LIST_SUCCESS]: getTeamVideoSearchListSuccess,
  [Types.GET_TEAM_VIDEO_SEARCH_LIST_FAILURE]: getTeamVideoSearchListFailure,
  [Types.SEARCH_RESET]: searchReset
};

export const coachSearchVideoReducer = createReducer(INITIAL_STATE, HANDLERS);
