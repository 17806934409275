/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { ContentContainer, CloseButton, H2 } from './ProfileModalStyles';
import { ReactComponent as CloseIcon } from '../../../shared/assets/Close.svg';
import { TabsComponenet } from '../../../globalShared';
import PersonalDetails from './Personal';
import Password from './Password';
import Professional from './Professional';
import { ModalContainer } from '../../../globalShared/CommonStyles';

const ProfileModal = ({ onCancel }) => {
  const [activeTab, setActiveTab] = useState('Professional');

  const handleTabChange = (label) => {
    setActiveTab(label);
  };

  return (
    <ModalContainer>
      <ContentContainer>
        <CloseButton onClick={onCancel}>
          <CloseIcon />
        </CloseButton>
        <H2>Profile</H2>
        <TabsComponenet getactiveTab={handleTabChange}>
          <div label='Personal'>
            <PersonalDetails />
            <Password />
          </div>

          <div label='Professional'>
            <Professional />
          </div>
        </TabsComponenet>
      </ContentContainer>
    </ModalContainer>
  );
};

export default ProfileModal;
