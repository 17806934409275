/* eslint-disable no-console */
/* eslint-disable no-shadow */

import { put } from 'redux-saga/effects';
import { Creators } from './action';

import { WebService } from '../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../services/axios/endPoints';

export function* getCoachPlayerList({ payload }) {
  const { page, count, playerType } = payload;

  try {
    yield put(Creators.getCoachPlayerListStart());

    const fullURL = yield `${apiEndPoints.coachPlayerList}`;
    const response = yield WebService.get(fullURL, { params: { page, count, playerType } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      yield put(
        Creators.getCoachPlayerListSuccess({
          playerList: data[0] || [],
          maxPage: data[1].page_count,
          totalCount: data[2].totalCount
        })
      );
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getCoachPlayerListFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getCoachPlayerListFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getCoachPlayerListFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getCoachPlayerListFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getCoachPlayerListFailure(payload));
    }
  }
}

export function* SearchCoachPlayersSaga({ payload }) {
  const { page, count, userName } = payload;

  try {
    yield put(Creators.getCoachPlayerListStart());

    const fullURL = yield `${apiEndPoints.searchPlayers}`;
    const response = yield WebService.get(fullURL, { params: { page, count, userName } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;
      yield put(Creators.SearchCoachPlayersSuccess({ playerList: data[0], maxPage: data[1].page_count }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getCoachPlayerListFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getCoachPlayerListFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getCoachPlayerListFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getCoachPlayerListFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getCoachPlayerListFailure(payload));
    }
  }
}

export function* getCoachPlayerDetailsSaga({ payload }) {
  try {
    const { _id } = payload;
    yield put(Creators.getCoachPlayerDetailsStart());
    const fullURL = yield `${apiEndPoints.userDetails}`;

    const response = yield WebService.get(fullURL, { params: { _id } });

    if (response.status >= 200 && response.status < 300) {
      const playerDetails = response.data.data;
      yield put(Creators.setPlayerDetails({ playerDetails }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification sysytem here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getCoachPlayerDetailsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getCoachPlayerDetailsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };
          yield put(Creators.getCoachPlayerDetailsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getCoachPlayerDetailsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getCoachPlayerDetailsFailure(payload));
    }
  }
}
