import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from './store';
import { Creators as CompareCreators } from '../CompareView/store';
import { Creators as SkillCreators } from '../SkillsView/store';

// components
import {
  SearchBoxComponent,
  ButtonComponent,
  ControllComponent,
  DropdownComponent,
  PaginationComponent,
  AvatarComponent
} from '../../../globalShared';

// styles
import { Divider, TableStyles, FlexContainer } from '../../../globalShared/CommonStyles';
import {
  Container,
  HeaderTxt,
  Header,
  SearchContainer,
  VideoTitle,
  SelectButtonContainer,
  Radio,
  EmptyContainer,
  ControlContainer,
  Bagde
} from './AddVideosView';

// icons
import { ReactComponent as BackIcon } from '../../../shared/assets/back-header.svg';
import { ReactComponent as SelectIcon } from '../../../shared/assets/Radio_selected.svg';
import Toast, { Toasts } from '../../../services/toast';
import { shortenText, Timeformater } from '../../../services/utility';

const { Table, Th, Tr, Td } = TableStyles;
/**
 *
 * component
 */

const AddVideos = ({ history }) => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const [controllState, setcontrollState] = useState('My Videos');
  const [filter, setfilter] = useState({ name: 'All', _id: 'All' });

  // redux store values
  const teamVideos = useSelector((state) => state.playerTeamVideos.videosList);
  const playerVideos = useSelector((state) => state.playerSkills.videosList);
  const skillTotalVideos = useSelector((state) => state.playerSkills.videosCount);
  const compareVideos = useSelector((state) => state.compareVideos.videos);
  const collectionsList = useSelector((state) => state.playerSkills.collectionsList);
  const TeamToatlVideos = useSelector((state) => state.playerTeamVideos.totalCount);
  const [videos, setvideos] = useState([]);

  // checks wether the video is selected or not
  const isSelecetd = (id) => compareVideos.some((video) => video._id === id);

  useEffect(() => {
    if (searchValue) {
      dispatch(Creators.getPlayerTeamVideo({ searchValue, page, count }));
    } else {
      dispatch(Creators.searchReset());
    }
  }, [searchValue, page, count]);

  useEffect(() => {
    dispatch(SkillCreators.getPlayerVideosList({ _id: filter._id, page, count }));
  }, [filter, page, count]);

  useEffect(() => {
    if (controllState === 'My Videos') {
      setvideos(playerVideos);
    } else {
      setvideos(teamVideos);
    }
  }, [teamVideos, playerVideos, controllState]);

  useEffect(() => {
    dispatch(SkillCreators.getPlayerCollectionsList());
  }, []);

  const handleRemovefromCompare = (id) => {
    const videos = compareVideos.filter((item) => item._id !== id);
    dispatch(CompareCreators.addVideoToCompare({ videos }));
  };

  const handleAddToCompare = (video) => {
    if (compareVideos.some((item) => item._id === video._id)) {
      handleRemovefromCompare(video._id);
    } else if (compareVideos.length < 2) {
      const videos = [...compareVideos, video];
      dispatch(CompareCreators.addVideoToCompare({ videos }));
    } else {
      Toast.info('Remove one to add this', 2);
    }
  };

  const onSearchChange = (value) => {
    setSearchValue(value);
    setPage(1);
  };

  const handleClick = () => {
    if (compareVideos.length) {
      history.push('comparevideo');
    }
  };

  return (
    <>
      <Toasts />
      <Header>
        <FlexContainer style={{ padding: '10px 0' }}>
          <BackIcon style={{ cursor: 'pointer' }} onClick={() => history.goBack()} />
          <HeaderTxt>Compare Videos</HeaderTxt>
        </FlexContainer>
        <ButtonComponent
          buttonText='Compare'
          isButtonAction={compareVideos.length === 2}
          buttonClick={handleClick}
          margin={false}
        />
      </Header>

      <Divider />

      <SearchContainer>
        <ControlContainer>
          <ControllComponent
            firstItemName='My Videos'
            SecondItemName='Teams Videos'
            controlState={controllState}
            updateControlState={(value) => setcontrollState(value)}
          />
        </ControlContainer>
        <div style={{ width: '220px' }}>
          {controllState === 'Teams Videos' ? (
            <SearchBoxComponent placeHolder='Search' onSearchChange={onSearchChange} />
          ) : (
            <DropdownComponent
              defaultText='All'
              optionArray={[{ name: 'All', _id: 'All' }, ...collectionsList]}
              onChangeHandler={(value) => setfilter(value)}
              showLabel={false}
            />
          )}
        </div>
      </SearchContainer>

      {videos.length ? (
        <>
          <Container>
            <Table>
              <thead>
                <tr>
                  <Th style={{ paddingLeft: '12.4rem' }}>Title</Th>
                  <Th>Collection</Th>
                  <Th>uploaded by</Th>
                  <Th>Upload date</Th>
                  <Th>Duration</Th>
                  <Th>Comments</Th>
                </tr>
              </thead>
              <tbody>
                {videos.length &&
                  videos.map((video) => {
                    const {
                      _id,
                      firstName,
                      lastName,
                      thumbUrl,
                      title,
                      uploadDate,
                      commentCount,
                      duration,
                      collectionName
                    } = video;

                    return (
                      <Tr key={_id} onClick={() => handleAddToCompare(video)} style={{ cursor: 'pointer' }}>
                        <Td style={{ maxWidth: '350px' }}>
                          <FlexContainer>
                            <SelectButtonContainer>
                              {isSelecetd(_id) ? <SelectIcon /> : <Radio />}
                            </SelectButtonContainer>
                            <AvatarComponent url={thumbUrl} />
                            <VideoTitle>{title}</VideoTitle>
                          </FlexContainer>
                        </Td>
                        <Td>
                          <Bagde>
                            <p className='clip'>{`${collectionName}`}</p>
                          </Bagde>
                        </Td>
                        <Td>{shortenText(`${firstName} ${lastName}`, 15, '...')}</Td>
                        <Td>{uploadDate}</Td>
                        <Td>{Timeformater(duration)}</Td>
                        <Td>{commentCount}</Td>
                      </Tr>
                    );
                  })}
              </tbody>
            </Table>
          </Container>
          <PaginationComponent
            onChange={(value) => {
              setPage(value.currentPage);
              setCount(value.count);
            }}
            totalItems={controllState === 'My Videos' ? skillTotalVideos : TeamToatlVideos}
          />
        </>
      ) : (
        <EmptyContainer>{searchValue ? 'No Videos Available' : 'Search for Videos or Players'}</EmptyContainer>
      )}
    </>
  );
};

export default AddVideos;
