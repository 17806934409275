/*
 * action.js file for otp views
 * Author:
 * 1. Manikanta
 *
 * @copyright of Panorbit Services LLP.
 */

import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  verifyOtp: ['payload'],
  verifyOtpStart: ['payload'],
  verifyOtpSuccess: ['payload'],
  verifyOtpFailure: ['payload'],

  resetOtp: null
});

export { Types, Creators };
