import styled from 'styled-components';

import { backgroundColor, fontWeight } from '../../../shared/constants';

export const ScrollContainer = styled.div`
  // height: 600px;
  padding-right: 1rem;
  // overflow-y: auto;
`;

// meal accordion
export const MealContainer = styled.div`
  background: ${backgroundColor.darkOpaq};
  border-radius: 6px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const MealHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
`;

export const MealTitleContainer = styled.div`
  position: relative;
  width: 80%;
  display: flex;
  align-items: center;

  padding-left: 17px;
  cursor: pointer;
`;

export const CheckNew = styled.div`
  position: absolute;
  left: 0;
`;

export const MealTitle = styled.p`
  font-size: 16px;
  font-weight: ${fontWeight.bold};
  word-break: break-word;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

export const MealOptionsContainer = styled.div`
  padding-right: 17px;
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const DoneContainer = styled.div`
  display: flex;
  gap: 19px;
  padding: 9px 0px;

  & > p {
    text-transform: capitalize;
    font-size: 12px;
    font-weight: ${fontWeight.bold};
    color: #7ac943;
  }
`;

export const MealUndoneOption = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const MealUndoneMenu = styled.div`
  position: absolute;
  right: 7px;
  top: -20px;
  height: 54px;
  width: 140px;

  background: #262130;
  box-shadow: 0px 5px 18px #0000002e;
  border-radius: 10px;
  z-index: 999;

  display: ${(props) => (props.showMealUndone ? 'flex' : 'none')};
  align-items: unset;
  flex-direction: column;
  justify-content: center;

  & > div {
    padding: 19px;
    font-size: 14px;
    color: #eef0f3;
    cursor: pointer;
  }
`;

export const DropdownWrapper = styled.div`
  cursor: pointer;
`;

export const Rotate = styled.div`
  & > svg {
    transform: rotateX(180deg);
  }
`;
