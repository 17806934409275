import { put } from 'redux-saga/effects';
import { Creators } from './action';

import { WebService } from '../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../services/axios/endPoints';

export function* getPlayerHomeNutritionSaga({ payload }) {
  const { dataType, days } = payload;

  try {
    yield put(Creators.getPlayerHomeNutritionStart());

    const fullURL = yield `${apiEndPoints.playerDashBoardNutrition}`;
    const response = yield WebService.get(fullURL, { params: { dataType, days } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(Creators.getPlayerHomeNutritionSuccess({ nutritionStats: data }));
    } else {
      throw response;
    }
  } catch (error) {
    // console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getPlayerHomeNutritionFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getPlayerHomeNutritionFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getPlayerHomeNutritionFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getPlayerHomeNutritionFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getPlayerHomeNutritionFailure(payload));
    }
  }
}

export function* getPlayerHomeHydrationSaga({ payload }) {
  const { dataType, days } = payload;

  try {
    yield put(Creators.getPlayerHomeHydrationStart());

    const fullURL = yield `${apiEndPoints.playerDashBoardHydration}`;
    const response = yield WebService.get(fullURL, { params: { dataType, days } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(Creators.getPlayerHomeHydrationSuccess({ hydrationStats: data }));
    } else {
      throw response;
    }
  } catch (error) {
    // console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getPlayerHomeHydrationFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getPlayerHomeHydrationFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getPlayerHomeHydrationFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getPlayerHomeHydrationFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getPlayerHomeHydrationFailure(payload));
    }
  }
}

export function* getPlayerHomeMoodStats({ payload }) {
  const { days } = payload;

  try {
    yield put(Creators.getPlayerHomeMoodStatsStart());

    const fullURL = yield `${apiEndPoints.moodStats}`;
    const response = yield WebService.get(fullURL, { params: { days } });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      yield put(
        Creators.getPlayerHomeMoodStatsSuccess({
          moodStats: data[1] ? data[1].moodDict : { level1: 0, level2: 0, level3: 0, level4: 0, level5: 0, level6: 0 }
        })
      );
    } else {
      throw response;
    }
  } catch (error) {
    // console.log({ error });

    let payload;
    if (error && error.response && error.response.status) {
      // add toast notification system here
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message
          };
          yield put(Creators.getPlayerHomeMoodStatsFailure(payload));
          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };
          yield put(Creators.getPlayerHomeMoodStatsFailure(payload));
          break;
        case 500:
          payload = {
            error: 'Something went wrong try later'
          };
          yield put(Creators.getPlayerHomeMoodStatsFailure(payload));
          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
          yield put(Creators.getPlayerHomeMoodStatsFailure(payload));
      }
    } else {
      // for custom error
      payload = {
        error: error.message
      };

      yield put(Creators.getPlayerHomeMoodStatsFailure(payload));
    }
  }
}
