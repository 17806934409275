/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unneeded-ternary */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  ModalContainer,
  Card,
  Title,
  CloseButton,
  Header,
  SubTitle,
  CollectionContainer,
  InputContainer,
  SelectButtonContainer,
  Radio,
  CollectionName,
  FormFooter
} from './AddToAnotherCollectionModalStyles';

import { Creators } from './store';

import { ButtonComponent } from '../../../globalShared/index';

import { ReactComponent as CloseIcon } from '../../../shared/assets/Close.svg';

import { ReactComponent as Selected } from '../../../shared/assets/Radio_selected.svg';

const AddToAnotherCollectionModal = (props) => {
  const { currentVideoId, onCancel } = props;
  const dispatch = useDispatch();
  const [selectedCollection, setSelectedCollection] = useState(null);

  const isSelected = selectedCollection ? true : false;

  // state values
  const collectionsList = useSelector((state) => state.playerSkills.collectionsList);
  const collectionReload = useSelector((state) => state.playerSkills.collectionReload);

  // effect hooks
  useEffect(() => {
    dispatch(Creators.getPlayerCollectionsList());
  }, [collectionReload]);

  const handleClose = () => {
    // dispatch(Creators.setShowAddToAnotherCollectionModal({ showAddToAnotherCollection: false }));
    onCancel();
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleSave = () => {
    if (selectedCollection) {
      const { name, _id } = selectedCollection;

      const addVideoToCollection = { videoId: currentVideoId, collectionName: name, collectionId: _id };

      dispatch(Creators.addToAnotherCollection({ addVideoToCollection }));
      handleCancel();
    }
  };

  return (
    <ModalContainer>
      <Card>
        <Header>
          <Title>Add to another collection</Title>
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
        </Header>
        <SubTitle>Select the collection to add the video to</SubTitle>
        <form>
          <CollectionContainer>
            {collectionsList &&
              collectionsList.map((collection) => (
                <InputContainer key={collection._id} onClick={() => setSelectedCollection(collection)}>
                  <SelectButtonContainer>
                    {selectedCollection?._id === collection._id ? <Selected /> : <Radio />}
                  </SelectButtonContainer>
                  <CollectionName>{collection.name}</CollectionName>
                </InputContainer>
              ))}
          </CollectionContainer>

          <FormFooter>
            <div>
              <ButtonComponent buttonText='Cancel' isButtonAction={true} buttonClick={handleCancel} bgType='dark' />
              <ButtonComponent buttonText='Save' isButtonAction={isSelected} buttonClick={handleSave} />
            </div>
          </FormFooter>
        </form>
      </Card>
    </ModalContainer>
  );
};

export default AddToAnotherCollectionModal;
