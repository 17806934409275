import styled from 'styled-components';
import { fontSize, textColors, backgroundColor } from '../../constants';

export const Input = styled.input`
  width: 100%;
  height: 50px;

  padding: 14px 17px;
  font-size: ${fontSize.mediumDefault};

  color: ${textColors.textWhite};
  border: none;

  border-radius: 0.6rem 0 0 0.6rem;
  background-color: ${backgroundColor.darkOpaq};

  &:focus {
    outline: none;
  }

  &:-internal-autofill-selected {
    appearance: initial !important;
    background-color: ${backgroundColor.darkOpaq} !important;

    color: ${textColors.textWhite} !important;
  }
`;
export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Search = styled.div`
  height: 50px;

  display: flex;
  align-items: center;

  padding: 1rem 1.5rem 1rem 1rem;

  border: none;
  border-radius: 0 0.6rem 0.6rem 0;
  background-color: ${backgroundColor.darkOpaq};
`;
