export const textColors = {
  textDark: '#1C1E19',
  textMedium: '#272e30',
  textWhite: '#fff',
  textLight: '#8792AC',
  textGray: '#a2a2a2',
  textGrayDark: '#6b6b6b',
  danger: '#e12a3c',
  success: '#28cf87',
  muted: '#5d5d5d',
  error: '#E44343',
  light: '#E7E9EF'
};

export const borderColor = {
  light: '#a3a9b733',
  medium: '#797091'
};

export const backgroundColor = {
  darkOpaq: 'rgba(78, 67, 105, 0.2)',
  light: '#fff',
  dark: '#201c2a',
  blue: '#291E43',
  popup: '#15151C',
  popupBg: '#000000cc',
  danger: '#E44343',
  lightGray: '#CECECE',
  lightBlue: '#3F335B',
  card: '#4e436926',
  cardItem: '#4e43694d'
};

export const shadow = {
  dark: '#0000004D'
};

export const accentColor = {
  primary: '#21D0B3',
  primaryDark: '#28CFAB',

  secondary: '#7AC943',
  secondaryLite: '#72CA4D',

  dark: '#121317',
  midNightBlue: '#251842'
};

export const gradient = {
  primary: 'linear-gradient(108deg,#72CA4D 0%,#28CFAB 100%)',
  dark: 'linear-gradient(124deg, #251842 0%, #121317 100%)'
};
