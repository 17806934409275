/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// styles
import { Container, LinkItem, HomeLink, Help, ToggleButton } from './AdminSideNav';

// icons
import { ReactComponent as HomeIcon } from '../../assets/Home.svg';
import { ReactComponent as UsersIcon } from '../../assets/Users.svg';
import { ReactComponent as BillingIcon } from '../../assets/Biling.svg';
import { ReactComponent as SettingsIcon } from '../../assets/Team-settings.svg';
import { ReactComponent as HelpIcon } from '../../assets/Help.svg';
import { ReactComponent as Collapse } from '../../assets/Hamburger_collapse_menu.svg';
import { ReactComponent as Expand } from '../../assets/Hamburger_expand_menu.svg';
import { localString } from '../../../services/locallization/localStrings';
// component
const AdminSidenav = (props) => {
  const [active, setactive] = useState(true);

  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];
  return (
    <Container collapse={active}>
      <ToggleButton onClick={() => setactive(!active)}>{active ? <Collapse /> : <Expand />}</ToggleButton>

      <HomeLink className={props.isExact ? 'active' : ''} to='/admin/'>
        <HomeIcon />
        {active ? string.home : ''}
      </HomeLink>

      <LinkItem to='users'>
        <UsersIcon />
        {active ? string.users : ''}
      </LinkItem>

      {/* <LinkItem to='billing'>
        <BillingIcon />
        {active ? string.billing : ''}
      </LinkItem> */}

      <LinkItem to='settings'>
        <SettingsIcon />
        {active ? string.teamSettings : ''}
      </LinkItem>

      {/* <Help to='help-and-support'>
        <HelpIcon />
        {active ? string.helpNsupport : ''}
      </Help> */}
    </Container>
  );
};

export default AdminSidenav;
