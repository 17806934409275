import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ReactComponent as UploadIcon } from '../../assets/upload.svg';
import { Container, OuterContainer } from './DragAndDrop.styles';
import { localString } from '../../../services/locallization/localStrings';

// component
const DragAndDrop = (props) => {
  const $input = useRef(null);
  const { maxFiles, onDrop } = props;

  // localiztion string
  const { language } = useSelector((state) => state.main);
  const string = localString[language];

  const onChangeHandler = (file) => {
    onDrop(file[0]);
  };

  const onDropHandler = (files) => {
    const file = files[0];
    const isCSV = file.name.match(/.*\.(xlsx|xls|csv)/g);
    if (isCSV) {
      onDrop(file);
    }
  };

  return (
    <OuterContainer>
      <Container
        role='presentation'
        onClick={() => $input.current.click()}
        onDrop={(e) => {
          onDropHandler(e.dataTransfer.files);
          e.preventDefault();
        }}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDragLeave={(e) => {
          e.preventDefault();
        }}
      >
        <UploadIcon />
        <h2>{string.dNd}</h2>
        <input
          style={{ display: 'none' }}
          type='file'
          accept='.xls'
          ref={$input}
          onChange={(e) => {
            onChangeHandler(e.target.files);
          }}
          multiple={maxFiles > 1}
        />
      </Container>
    </OuterContainer>
  );
};

DragAndDrop.defaultProps = {
  maxFiles: 1
};
DragAndDrop.propTypes = {
  onDrop: PropTypes.func.isRequired,
  maxFiles: PropTypes.number
};

export default DragAndDrop;
