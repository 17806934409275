import styled from 'styled-components';
import { backgroundColor, fontSize, textColors } from '../../../shared/constants';

export const MainContainer = styled.div`
  padding: 2rem 3.5rem 0px;
  padding-top: 0;
  height: calc(100vh - 100px);
  overflow-y: auto;
`;

export const ContentContainer = styled.div`
  height: 100%;
  width: 1083px;

  background-color: ${backgroundColor.popup};
  border-radius: 6px 0px 0px 6px;

  overflow-y: auto;
`;

export const ModalContainer = styled.div`
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  justify-content: flex-end;

  background-color: ${backgroundColor.popupBg};

  z-index: 99999;
`;

export const FormFooter = styled.div`
  right: 10px;
  bottom: 0;

  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const P = styled.p`
  font-size: ${fontSize.smallDefault};
  color: ${textColors.textLight};
`;

export const Muted = styled.div`
  width: 100%;
  height: 45px;
  padding: 1rem;
  font-size: 1.5rem;
  color: #fff;
  border: none;
  border-radius: 0.6rem;
  background-color: rgba(78, 67, 105, 0.2);
  margin-bottom: 1.5rem;
`;
