import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  loading: false,
  error: null,
  success: false,
  mealPlannerList: [],
  reloadList: false,
  page: 1,
  maxPage: 1,
  totalItems: 0,
  searchKey: ''
};

const searchCoachMealPlannerStart = (state = INITIAL_STATE) => ({ ...state, loading: true });

const searchCoachMealPlannerSuccess = (state = INITIAL_STATE, action) => {
  const { mealPlannerList, totalItems } = action.payload;
  return {
    ...state,
    loading: false,
    mealPlannerList,
    totalItems
  };
};

const searchCoachMealPlannerFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const getCoachMealPlannerListStart = (state = INITIAL_STATE) => ({ ...state, loading: true });

const getCoachMealPlannerListSuccess = (state = INITIAL_STATE, action) => {
  const { mealPlannerList, totalItems } = action.payload;
  return {
    ...state,
    loading: false,
    reloadList: false,
    mealPlannerList,
    totalItems
  };
};

const getCoachMealPlannerListFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const resetMealPlanner = () => INITIAL_STATE;

const deleteCoachMealPlannerStart = (state = INITIAL_STATE) => ({ ...state, loading: true });

const deleteCoachMealPlannerSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  reloadList: true,
  success: true
});

const deleteCoachMealPlannerFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const resetError = (state = INITIAL_STATE) => ({ ...state, error: null });
const resetSuccess = (state = INITIAL_STATE) => ({ ...state, success: false });

const setMealPlannerSearchKey = (state = INITIAL_STATE, action) => {
  const { searchKey } = action.payload;
  return {
    ...state,
    searchKey,
    page: 1
  };
};

const setMealPlannerPage = (state = INITIAL_STATE, action) => {
  const { page } = action.payload;
  return {
    ...state,
    page
  };
};

const HANDLERS = {
  [Types.SEARCH_COACH_MEAL_PLANNER_START]: searchCoachMealPlannerStart,
  [Types.SEARCH_COACH_MEAL_PLANNER_FAILURE]: searchCoachMealPlannerFailure,
  [Types.SEARCH_COACH_MEAL_PLANNER_SUCCESS]: searchCoachMealPlannerSuccess,

  [Types.GET_COACH_MEAL_PLANNER_LIST_START]: getCoachMealPlannerListStart,
  [Types.GET_COACH_MEAL_PLANNER_LIST_FAILURE]: getCoachMealPlannerListFailure,
  [Types.GET_COACH_MEAL_PLANNER_LIST_SUCCESS]: getCoachMealPlannerListSuccess,

  [Types.DELETE_COACH_MEAL_PLANNER_START]: deleteCoachMealPlannerStart,
  [Types.DELETE_COACH_MEAL_PLANNER_SUCCESS]: deleteCoachMealPlannerSuccess,
  [Types.DELETE_COACH_MEAL_PLANNER_FAILURE]: deleteCoachMealPlannerFailure,

  [Types.RESET_MEAL_PLANNER]: resetMealPlanner,
  [Types.RESET_ERROR]: resetError,
  [Types.RESET_SUCCESS]: resetSuccess,
  [Types.SET_MEAL_PLANNER_SEARCH_KEY]: setMealPlannerSearchKey,
  [Types.SET_MEAL_PLANNER_PAGE]: setMealPlannerPage
};

export const coachMealPlannerReducer = createReducer(INITIAL_STATE, HANDLERS);
