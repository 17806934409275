import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getCoachMealPlanList: ['payload'],
  getCoachMealPlanListStart: ['payload'],
  getCoachMealPlanListSuccess: ['payload'],
  getCoachMealPlanListFailure: ['payload'],

  createMealPlan: ['payload'],
  createMealPlanStart: ['payload'],
  createMealPlanSuccess: ['payload'],
  createMealPlanFailure: ['payload'],

  deleteCoachMealPlan: ['payload'],
  deleteCoachMealPlanStart: ['payload'],
  deleteCoachMealPlanSuccess: ['payload'],
  deleteCoachMealPlanFailure: ['payload'],

  duplicateCoachMealPlan: ['payload'],
  duplicateCoachMealPlanStart: ['payload'],
  duplicateCoachMealPlanSuccess: ['payload'],
  duplicateCoachMealPlanFailure: ['payload'],

  searchCoachMealPlan: ['payload'],
  searchCoachMealPlanStart: ['payload'],
  searchCoachMealPlanSuccess: ['payload'],
  searchCoachMealPlanFailure: ['payload'],

  setMealPlanDetailsId: ['payload'],
  setShowCreateMealPlanModal: ['payload'],
  setShowMealPlanDetailsModal: ['payload'],

  mealPlanReset: null,
  resetSuccess: null,
  resetError: null,
  setMealPlanSearchKey: ['payload'],
  setMealPlanPage: ['payload']
});

export { Types, Creators };
