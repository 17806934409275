/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import { createReducer } from 'reduxsauce';
import { Types } from './action';
import { mergeArray } from '../../../../services/utility';

const INITIAL_STATE = {
  loading: false,
  error: null,
  mindsetFeedList: [],
  moodDetails: '',
  moodStats: [],
  stats: '',
  meditationDetails: '',
  meditationStats: [],
  avgMeditationDuration: '',
  sleepDetails: '',
  sleepStats: [],
  avgSleepDuration: '',
  reload: false,
  success: false,
  page: 1,
  maxPage: 1,
  moodDetailsId: '',
  sleepDetailsId: '',
  meditationDetailsId: '',
  mindsetCommentsLoading: false,
  mindsetCommentsList: [],
  moodCommentsList: [],
  moodCommentCount: 0,
  sleepCommentsList: [],
  sleepCommentCount: 0,
  meditationCommentsList: [],
  meditationCommentCount: 0,
  reloadComments: false,
  isNext: false,
  fetchTime: null
};

const getCoachMindsetFeedListStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getCoachMindsetFeedListSuccess = (state = INITIAL_STATE, action) => {
  const { mindsetFeedList, isNext, fetchTime } = action.payload;
  return {
    ...state,
    loading: false,
    reload: false,
    mindsetFeedList,
    isNext,
    fetchTime
  };
};

const getCoachMindsetFeedListFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const getCoachMindsetFeedCommentsStart = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  mindsetCommentsLoading: true
});

const getCoachMindsetFeedCommentsSuccess = (state = INITIAL_STATE, action) => {
  const newCommentsList = action.payload.mindsetCommentsList;
  const oldCommentsList = state.mindsetCommentsList;
  const newArray = mergeArray(oldCommentsList, newCommentsList);

  return {
    ...state,
    mindsetCommentsList: [...newArray]
  };
};

const getCoachMoodCommentsStart = (state = INITIAL_STATE) => ({
  ...state,
  error: null
});

const getCoachMoodCommentsSuccess = (state = INITIAL_STATE, action) => {
  const newCommentsList = action.payload.moodCommentsList;
  const moodCommentCount = action.payload.moodCommentCount;

  return {
    ...state,
    moodCommentsList: newCommentsList,
    moodCommentCount
  };
};

const getCoachMoodCommentsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    error
  };
};

const getCoachSleepCommentsStart = (state = INITIAL_STATE) => ({
  ...state,
  error: null
});

const getCoachSleepCommentsSuccess = (state = INITIAL_STATE, action) => {
  const newCommentsList = action.payload.sleepCommentsList;
  const sleepCommentCount = action.payload.sleepCommentCount;

  return {
    ...state,
    sleepCommentsList: newCommentsList,
    sleepCommentCount
  };
};

const getCoachSleepCommentsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    error
  };
};

const getCoachMeditationCommentsStart = (state = INITIAL_STATE) => ({
  ...state,
  error: null
});

const getCoachMeditationCommentsSuccess = (state = INITIAL_STATE, action) => {
  const newCommentsList = action.payload.meditationCommentsList;
  const meditationCommentCount = action.payload.meditationCommentCount;

  return {
    ...state,
    meditationCommentsList: newCommentsList,
    meditationCommentCount
  };
};

const getCoachMeditationCommentsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    error
  };
};

const getCoachMoodDetailsStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getCoachMoodDetailsSuccess = (state = INITIAL_STATE, action) => {
  const { moodDetails } = action.payload;

  return {
    ...state,
    loading: false,
    reload: false,
    moodDetails
  };
};

const getCoachMoodDetailsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const getCoachMoodStatsStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getCoachMoodStatsSuccess = (state = INITIAL_STATE, action) => {
  const { moodStats, stats } = action.payload;

  return {
    ...state,
    loading: false,
    reload: false,
    moodStats,
    stats
  };
};

const getCoachMoodStatsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const getCoachMeditationDetailsStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getCoachMeditationDetailsSuccess = (state = INITIAL_STATE, action) => {
  const { meditationDetails } = action.payload;

  return {
    ...state,
    loading: false,
    reload: false,
    meditationDetails
  };
};

const getCoachMeditationDetailsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const getCoachMeditationStatsStart = (state = INITIAL_STATE) => ({ ...state, error: null });

const getCoachMeditationStatsSuccess = (state = INITIAL_STATE, action) => {
  const { meditationStats, avgMeditationDuration } = action.payload;

  return {
    ...state,
    reload: false,
    meditationStats,
    avgMeditationDuration
  };
};

const getCoachMeditationStatsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    error
  };
};

const getCoachSleepDetailsStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getCoachSleepDetailsSuccess = (state = INITIAL_STATE, action) => {
  const { sleepDetails } = action.payload;

  return {
    ...state,
    loading: false,
    reload: false,
    sleepDetails
  };
};

const getCoachSleepDetailsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const getCoachSleepStatsStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getCoachSleepStatsSuccess = (state = INITIAL_STATE, action) => {
  const { sleepStats, avgSleepDuration } = action.payload;

  return {
    ...state,
    loading: false,
    reload: false,
    sleepStats,
    avgSleepDuration
  };
};

const getCoachSleepStatsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const setMoodDetailsId = (state = INITIAL_STATE, action) => {
  const { id } = action.payload;

  return {
    ...state,
    moodDetailsId: id
  };
};

const setSleepDetailsId = (state = INITIAL_STATE, action) => {
  const { id } = action.payload;

  return {
    ...state,
    sleepDetailsId: id
  };
};

const setMeditationDetailsId = (state = INITIAL_STATE, action) => {
  const { id } = action.payload;

  return {
    ...state,
    meditationDetailsId: id
  };
};

const setPage = (state = INITIAL_STATE, action) => {
  const { page } = action.payload;
  return {
    ...state,
    page
  };
};

const resetCoachMindSet = () => ({ ...INITIAL_STATE });

const HANDLERS = {
  [Types.GET_COACH_MINDSET_FEED_LIST_START]: getCoachMindsetFeedListStart,
  [Types.GET_COACH_MINDSET_FEED_LIST_SUCCESS]: getCoachMindsetFeedListSuccess,
  [Types.GET_COACH_MINDSET_FEED_LIST_FAILURE]: getCoachMindsetFeedListFailure,

  [Types.GET_COACH_MINDSET_FEED_COMMENTS_START]: getCoachMindsetFeedCommentsStart,
  [Types.GET_COACH_MINDSET_FEED_COMMENTS_SUCCESS]: getCoachMindsetFeedCommentsSuccess,

  [Types.GET_COACH_MOOD_COMMENTS_START]: getCoachMoodCommentsStart,
  [Types.GET_COACH_MOOD_COMMENTS_SUCCESS]: getCoachMoodCommentsSuccess,
  [Types.GET_COACH_MOOD_COMMENTS_FAILURE]: getCoachMoodCommentsFailure,

  [Types.GET_COACH_SLEEP_COMMENTS_START]: getCoachSleepCommentsStart,
  [Types.GET_COACH_SLEEP_COMMENTS_SUCCESS]: getCoachSleepCommentsSuccess,
  [Types.GET_COACH_SLEEP_COMMENTS_FAILURE]: getCoachSleepCommentsFailure,

  [Types.GET_COACH_MEDITATION_COMMENTS_START]: getCoachMeditationCommentsStart,
  [Types.GET_COACH_MEDITATION_COMMENTS_SUCCESS]: getCoachMeditationCommentsSuccess,
  [Types.GET_COACH_MEDITATION_COMMENTS_FAILURE]: getCoachMeditationCommentsFailure,

  [Types.GET_COACH_MOOD_DETAILS_START]: getCoachMoodDetailsStart,
  [Types.GET_COACH_MOOD_DETAILS_SUCCESS]: getCoachMoodDetailsSuccess,
  [Types.GET_COACH_MOOD_DETAILS_FAILURE]: getCoachMoodDetailsFailure,

  [Types.GET_COACH_MOOD_STATS_START]: getCoachMoodStatsStart,
  [Types.GET_COACH_MOOD_STATS_SUCCESS]: getCoachMoodStatsSuccess,
  [Types.GET_COACH_MOOD_STATS_FAILURE]: getCoachMoodStatsFailure,

  [Types.GET_COACH_MEDITATION_DETAILS_START]: getCoachMeditationDetailsStart,
  [Types.GET_COACH_MEDITATION_DETAILS_SUCCESS]: getCoachMeditationDetailsSuccess,
  [Types.GET_COACH_MEDITATION_DETAILS_FAILURE]: getCoachMeditationDetailsFailure,

  [Types.GET_COACH_MEDITATION_STATS_START]: getCoachMeditationStatsStart,
  [Types.GET_COACH_MEDITATION_STATS_SUCCESS]: getCoachMeditationStatsSuccess,
  [Types.GET_COACH_MEDITATION_STATS_FAILURE]: getCoachMeditationStatsFailure,

  [Types.GET_COACH_SLEEP_DETAILS_START]: getCoachSleepDetailsStart,
  [Types.GET_COACH_SLEEP_DETAILS_SUCCESS]: getCoachSleepDetailsSuccess,
  [Types.GET_COACH_SLEEP_DETAILS_FAILURE]: getCoachSleepDetailsFailure,

  [Types.GET_COACH_SLEEP_STATS_START]: getCoachSleepStatsStart,
  [Types.GET_COACH_SLEEP_STATS_SUCCESS]: getCoachSleepStatsSuccess,
  [Types.GET_COACH_SLEEP_STATS_FAILURE]: getCoachSleepStatsFailure,

  [Types.SET_MOOD_DETAILS_ID]: setMoodDetailsId,
  [Types.SET_SLEEP_DETAILS_ID]: setSleepDetailsId,
  [Types.SET_MEDITATION_DETAILS_ID]: setMeditationDetailsId,
  [Types.SET_PAGE]: setPage,
  [Types.RESET_COACH_MIND_SET]: resetCoachMindSet
};

export const coachMindsetReducer = createReducer(INITIAL_STATE, HANDLERS);
