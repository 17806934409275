/* eslint-disable no-unused-vars */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

// styles
import { PasswordResetWrapper, Title } from './ProfileModalStyles';
import { Header, NewPasswordWrapper, Column } from './ResetPasswordStyles';

// creators
import { Creators } from './store';

import { ValdErrMsg } from '../../globalShared/CommonStyles';
import { InputComponent, ButtonComponent } from '../../globalShared';

const ResetPassword = (props) => {
  const { closeResetPassword } = props;
  const dispatch = useDispatch();

  const { register, handleSubmit, errors, getValues, reset } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const onPasswordUpdateSubmit = (data) => {
    const payload = {
      currentPassword: data.currentPassword,
      password: data.newpassword
    };

    dispatch(Creators.resetPassword(payload));
    reset();
    closeResetPassword();
  };

  return (
    <PasswordResetWrapper>
      <form onSubmit={handleSubmit(onPasswordUpdateSubmit)}>
        <Header>
          <Title>Reset Password</Title>
          <ButtonComponent buttonText='Save' isButtonAction={true} buttonType='submit' />
        </Header>

        <InputComponent
          ipType='password'
          placeholderText='current password'
          label='Current password'
          ipName='currentPassword'
          ipRef={register({ required: true })}
        />
        <NewPasswordWrapper>
          <Column wdth='50%'>
            <InputComponent
              ipType='password'
              placeholderText='new password'
              label='New password'
              ipName='newpassword'
              ipRef={register({
                required: true,
                minLength: {
                  value: 8,
                  message: 'Password must contain at least 8 characters'
                },
                pattern: {
                  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                  message: 'must contain lower, upper, number and special characters'
                }
              })}
              id='newpassword'
            />
            {errors.newpassword && <ValdErrMsg>{errors.newpassword?.message}</ValdErrMsg>}
          </Column>
          <Column wdth='50%'>
            <InputComponent
              ipType='password'
              placeholderText='re-enter password'
              label='Re-enter new password'
              ipName='reenterpassword'
              ipRef={register({
                required: true,
                validate: (value) => value === getValues('newpassword') || "Password doesn't match"
              })}
              id='reenterpassord'
            />
            {errors.reenterpassword && <ValdErrMsg>{errors.reenterpassword?.message}</ValdErrMsg>}
          </Column>
        </NewPasswordWrapper>
      </form>
    </PasswordResetWrapper>
  );
};

export default ResetPassword;
