import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  updatePlayerPersonalDetails: ['payload'],
  updatePlayerPersonalDetailsStart: ['payload'],
  updatePlayerPersonalDetailsSuccess: ['payload'],
  updatePlayerPersonalDetailsFailure: ['payload'],

  updatePlayerProfessionalDetails: ['payload'],
  updatePlayerProfessionalDetailsStart: ['payload'],
  updatePlayerProfessionalDetailsSuccess: ['payload'],
  updatePlayerProfessionalDetailsFailure: ['payload'],

  updateFile: ['payload'],
  updateFileStart: ['payload'],
  updateFileSuccess: ['payload'],
  updateFileFailure: ['payload'],

  deleteFile: ['payload'],
  deleteFileStart: ['payload'],
  deleteFileSuccess: ['payload'],
  deleteFileFailure: ['payload'],

  resetSuccess: null,
  resetError: null
});

export { Types, Creators };
