import styled from 'styled-components';
import { fontSize, borderColor, fontWeight, textColors } from '../../../shared/constants';

export const BedTimeContainer = styled.div`
  margin-top: 21px;
  margin-bottom: 18px;
  width: 100%;
  border: 1px solid ${borderColor.light};
  border-radius: 6px;

  & > div {
    padding: 0px !important;
  }
`;

export const BedTimes = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
`;

export const Text = styled.div`
  margin-right: 1.4rem;

  display: flex;
  align-items: center;

  & > span {
    margin-right: 0.6rem;
  }

  & > p {
    display: inline-block;
    font-size: ${fontSize.medium};
  }
`;

export const H1 = styled.h1`
  font-size: ${fontSize.large};
`;

export const SleepTrackContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SleepDateWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 17px;
`;

export const SleepDate = styled.p`
  font-size: 12px;
  font-weight: ${fontWeight.medium};
  color: ${textColors.textLight};
`;

export const SliderContainer = styled.div`
  width: 273px;
  height: 273px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 31px;
  background: #120a24;
  border-radius: 50%;
`;

export const SleepSliderWrapper = styled.span`
  position: absolute;
`;

export const Twelve = styled.p`
  position: absolute;
  top: 40px;

  font-size: 12px;
  font-weight: ${fontWeight.medium};
  color: ${textColors.textLight};
`;

export const Three = styled.p`
  position: absolute;
  right: 40px;

  font-size: 12px;
  font-weight: ${fontWeight.medium};
  color: ${textColors.textLight};
`;

export const Six = styled.p`
  position: absolute;
  bottom: 40px;

  font-size: 12px;
  font-weight: ${fontWeight.medium};
  color: ${textColors.textLight};
`;

export const Nine = styled.p`
  position: absolute;
  left: 40px;

  font-size: 12px;
  font-weight: ${fontWeight.medium};
  color: ${textColors.textLight};
`;

export const SleepDurationWrapper = styled.span`
  position: absolute;
  display: flex;
  gap: 2px;
  flex-direction: column;
  align-items: center;
`;

export const SleepDurationText = styled.p`
  font-size: 12px;
  font-weight: ${fontWeight.medium};
  color: ${textColors.textLight};
`;

export const Duration = styled.p`
  font-size: 22px;
  font-weight: ${fontWeight.bold};
  color: #f6f6f6;
`;

export const CircularSliderWrapper = styled.span`
  position: absolute;
`;

export const ButtonContainer = styled.div`
  width: 341px;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
`;

export const MainContainer = styled.div`
  height: calc(100vh - 235px);
  overflow-y: auto;
  padding-right: 1rem;
`;
