import React from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { Radar } from 'react-chartjs-2';
import { ChartCanvasWrapper } from './HomeStyles';

import { fontWeight, borderColor, textColors } from '../../../shared/constants';
import { labelsMap } from '../../../services/utility/maps';

const StrengthConditioning = (props) => {
  const { current, previous } = props;

  let previousLabel = '';
  let currentLabel = '';

  if (previous?.week && current?.week) {
    previousLabel = 'Last Week';
    currentLabel = 'This Week';
  } else if (previous?.createdMonth && current?.createdMonth) {
    previousLabel = 'Last Month';
    currentLabel = 'This Month';
  } else {
    previousLabel = 'Last week';
    currentLabel = 'This week';
  }

  const data = {
    labels: ['Adherence', 'Load', 'Completion', 'Sleep'],
    datasets: [
      {
        label: previousLabel,
        data: [previous?.adherence, previous?.load, previous?.completion, previous?.sleep],
        backgroundColor: '#6F783D',
        borderColor: 'rgba(0, 0, 0, 0)'
      },
      {
        label: currentLabel,
        data: [current?.adherence, current?.load, current?.completion, current?.sleep],
        backgroundColor: '#3D5138',
        borderColor: 'rgba(0, 0, 0, 0)'
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scale: {
      ticks: {
        callback: () => '',
        backdropColor: 'rgba(0, 0, 0, 0)',
        beginAtZero: true
      },
      gridLines: false,
      pointLabels: {
        fontSize: 15,
        fontStyle: `${fontWeight.medium}`
      },
      angleLines: {
        color: `${borderColor.light}`,
        lineWidth: 1
      }
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        boxWidth: 12,
        padding: 30
      }
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      callbacks: {
        title(tooltipItems, data) {
          const { labels } = data;
          const { index } = tooltipItems[0];
          const label = labels[index];
          return labelsMap[label];
        }
      }
    },
    plugins: {
      datalabels: {
        color: `${textColors.textLight}`,
        font: {
          size: 0,
          weight: `${fontWeight.medium}`
        },
        align: 'right',
        offset: 2,
        formatter: Math.round
      }
    }
  };

  return (
    <ChartCanvasWrapper>
      <Radar data={data} options={options} plugins={[ChartDataLabels]} />
    </ChartCanvasWrapper>
  );
};

export default StrengthConditioning;
