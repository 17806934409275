import React from 'react';
import styled from 'styled-components';
import user from '../../assets/user.jpg';

const PlayerImage = styled.img`
  width: 50px !important;
  height: 50px;
  max-width: 100%;

  /* background-image: url(${(props) => props.url || user});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; */

  border-radius: 0.6rem;
  margin-right: 9px;
`;

const Avatar = ({ url }) => <PlayerImage src={url || user} alt='image' />;

export default Avatar;
