/* eslint-disable arrow-body-style */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unneeded-ternary */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Creators } from './store';

// styles
import {
  Calender,
  Container,
  IntakeSummaryContainer,
  MealsContainer,
  IntakeSummaryHeader,
  MealsHeader,
  MsgContainer
} from './NutritionStyles';

// components
import HeaderCalander from '../../../shared/components/HeaderCalenderComponent';
import { CommingSoon } from '../../../globalShared/CommonStyles';

import { getYearMonthDay } from '../../../services/utility/moment';

import IntakeSummary from './IntakeSummary';
import Meals from './Meals';
import { LoadingComponent } from '../../../globalShared';

const NutritionView = () => {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState('');
  // const date = '2021-03-28';

  const intakeSummaryData = useSelector((state) => state.playerMealPlan.intakeSummary);
  const mealsData = useSelector((state) => state.playerMealPlan.meals);
  const reloadMealDetails = useSelector((state) => state.playerMealPlan.reloadMealDetails);
  const loading = useSelector((state) => state.playerMealPlan.loading);

  // effect hooks
  useEffect(() => {
    dispatch(Creators.getPlayerMealPlanList({ date: getYearMonthDay(selectedDate) }));
  }, [selectedDate]);

  useEffect(() => {
    if (reloadMealDetails) {
      dispatch(Creators.getPlayerMealPlanList({ date: getYearMonthDay(selectedDate) }));
    }
  }, [reloadMealDetails, selectedDate]);

  return (
    <>
      {loading && <LoadingComponent />}
      <Calender>
        <HeaderCalander
          onChange={(value) => {
            dispatch(Creators.getPlayerMealPlanList({ date: getYearMonthDay(value) }));
            setSelectedDate(getYearMonthDay(value));
          }}
          getDates={() => <></>}
        />
      </Calender>
      <Container>
        {!intakeSummaryData ? (
          ''
        ) : (
          <IntakeSummaryContainer>
            <IntakeSummaryHeader>Intake Summary</IntakeSummaryHeader>
            <IntakeSummary date={selectedDate} />
          </IntakeSummaryContainer>
        )}

        {mealsData && mealsData.length ? (
          <MealsContainer>
            <MealsHeader>Meals</MealsHeader>
            <Meals date={selectedDate} />
          </MealsContainer>
        ) : (
          <MsgContainer>
            <CommingSoon>No Meal Plan</CommingSoon>
          </MsgContainer>
        )}
      </Container>
    </>
  );
};

export default NutritionView;
