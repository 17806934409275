import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getCoachMindsetFeedList: ['payload'],
  getCoachMindsetFeedListStart: ['payload'],
  getCoachMindsetFeedListSuccess: ['payload'],
  getCoachMindsetFeedListFailure: ['payload'],

  getCoachMindsetFeedComments: ['payload'],
  getCoachMindsetFeedCommentsStart: ['payload'],
  getCoachMindsetFeedCommentsSuccess: ['payload'],
  getCoachMindsetFeedCommentsFailure: ['payload'],

  getCoachMoodComments: ['payload'],
  getCoachMoodCommentsStart: ['payload'],
  getCoachMoodCommentsSuccess: ['payload'],
  getCoachMoodCommentsFailure: ['payload'],

  getCoachSleepComments: ['payload'],
  getCoachSleepCommentsStart: ['payload'],
  getCoachSleepCommentsSuccess: ['payload'],
  getCoachSleepCommentsFailure: ['payload'],

  getCoachMeditationComments: ['payload'],
  getCoachMeditationCommentsStart: ['payload'],
  getCoachMeditationCommentsSuccess: ['payload'],
  getCoachMeditationCommentsFailure: ['payload'],

  getCoachMoodDetails: ['payload'],
  getCoachMoodDetailsStart: ['payload'],
  getCoachMoodDetailsSuccess: ['payload'],
  getCoachMoodDetailsFailure: ['payload'],

  getCoachMoodStats: ['payload'],
  getCoachMoodStatsStart: ['payload'],
  getCoachMoodStatsSuccess: ['payload'],
  getCoachMoodStatsFailure: ['payload'],

  getCoachMeditationDetails: ['payload'],
  getCoachMeditationDetailsStart: ['payload'],
  getCoachMeditationDetailsSuccess: ['payload'],
  getCoachMeditationDetailsFailure: ['payload'],

  getCoachMeditationStats: ['payload'],
  getCoachMeditationStatsStart: ['payload'],
  getCoachMeditationStatsSuccess: ['payload'],
  getCoachMeditationStatsFailure: ['payload'],

  getCoachSleepDetails: ['payload'],
  getCoachSleepDetailsStart: ['payload'],
  getCoachSleepDetailsSuccess: ['payload'],
  getCoachSleepDetailsFailure: ['payload'],

  getCoachSleepStats: ['payload'],
  getCoachSleepStatsStart: ['payload'],
  getCoachSleepStatsSuccess: ['payload'],
  getCoachSleepStatsFailure: ['payload'],

  coachMindsetReset: ['payload'],
  setMoodDetailsId: ['payload'],
  setSleepDetailsId: ['payload'],
  setMeditationDetailsId: ['payload'],
  setPage: ['payload'],
  resetCoachMindSet: null
});

export { Types, Creators };
