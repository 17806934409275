import styled from 'styled-components';

import { borderColor, fontSize, fontWeight, textColors } from '../../../shared/constants';

export const Container = styled.div`
  height: calc(100vh - 235px);
  overflow-y: auto;
  padding-right: 1rem;
  padding-top: 19px;
`;

export const MeditationHeader = styled.p`
  font-size: 16px;
  color: #f6f6f6;
  margin-bottom: 19px;
  text-align: center;
`;

export const MeditationCardContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const Card = styled.span`
  position: relative;
  height: 98px;
  background: #231c35;
  border-radius: 6px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 27px;
  overflow: hidden;
  cursor: pointer;
`;

export const BasicMeditationCard = styled(Card)`
  width: 100%;
`;

export const GuidedMeditationCard = styled(Card)`
  width: 100%;
`;

export const ExpertMeditationCard = styled(Card)`
  width: 100%;
  margin-bottom: 48px;
`;

export const CardDetailsContainer = styled.span`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const CardHeader = styled.p`
  font-size: 16px;
  font-weight: ${fontWeight.medium};
`;

export const CardDetails = styled.span`
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 8px;
  }
`;

export const MeditationDuration = styled.p`
  display: inline-block;
  font-size: 12px;
  font-weight: ${fontWeight.medium};
  color: ${textColors.textLight};
`;

export const IllusContainer = styled.span`
  & > svg {
    border-radius: 0 6px 6px 0;
  }
`;

export const PlayContainer = styled.span`
  position: absolute;
  right: 15px;

  display: flex;
  align-items: center;
`;

// today's checks
export const TodaysCheckHeader = styled.p`
  font-size: 14px;
  font-weight: ${fontWeight.bold};
  color: #f6f6f6;
  text-transform: capitalize;
  margin-bottom: 12px;
`;

export const TodaysCheck = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 0.5rem;

  & > svg > g > line {
    stroke: #a3a9b7;
  }

  & > svg {
    margin-top: 2rem;
  }
`;

export const MedContainer = styled.div`
  margin-top: 1rem;
  width: 100%;
  border: 1px solid ${borderColor.light};
  padding: 1.5rem;
  border-radius: 6px;
`;

export const H3 = styled.p`
  font-size: ${fontSize.smallDefault};
`;

export const HDiv = styled.div`
  display: inline-block;
  height: 22px;
  width: 0.5px;
  background-color: ${borderColor.light};
  margin: 0 1rem;
`;

export const TextSmallLight = styled.p`
  font-size: ${fontSize.smallDefault};
  color: ${textColors.textLight};
`;

export const CommentInputContainer = styled.div`
  & > div > div > input {
    font-size: 12px;
  }
`;
