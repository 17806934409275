import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  updateCoachPersonalDetails: ['payload'],
  updateCoachPersonalDetailsStart: ['payload'],
  updateCoachPersonalDetailsSuccess: ['payload'],
  updateCoachPersonalDetailsFailure: ['payload'],

  resetCoachPassword: ['payload'],
  resetCoachPasswordStart: ['payload'],
  resetCoachPasswordSuccess: ['payload'],
  resetCoachPasswordFailure: ['payload'],

  resetSuccess: null,
  resetError: null
});

export { Types, Creators };
