/*
 * action.js file for login view
 * Author:
 * 1. Manikanta
 *
 * @copyright of Panorbit Services LLP.
 */

import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  login: ['payload'],
  loginStart: ['payload'],
  loginSuccess: ['payload'],
  loginFailure: ['payload'],

  logout: ['payload'],
  logoutStart: ['payload'],
  logoutSuccess: ['payload'],
  logoutFailure: ['payload'],

  userMetaData: ['payload'],
  userMetaDataStart: ['payload'],
  userMetaDataSuccess: ['payload'],
  userMetaDataFailure: ['payload'],

  setUserData: ['payload'],
  setUserDetails: ['payload'],
  setToken: ['payload'],
  resetUserData: null,
  getUserData: ['payload'],
  resetLoading: null
});

export { Types, Creators };
