import styled from 'styled-components';

export const Title = styled.p`
  color: #8792ac;
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

export const SelectItemsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;

export const SelectItem = styled.div`
  width: 32%;
  height: 50px;

  padding: 1rem;
  background: #211e2b;
  border-radius: 6px;

  font-size: 20px;

  display: flex;
  align-items: center;
`;

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.5rem;
`;

export const Select = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 2px solid #3e3457;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #2f2843;
    cursor: pointer;
  }
`;

export const Drop = styled.div`
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background: transparent linear-gradient(212deg, #21d0b3 0%, #7ac943 100%) 0% 0%;
`;

export const DNDContainer = styled.div`
  height: 75px;
  width: 100%;
  border: 2px dashed #4e4369;
  border-radius: 10px;
  padding: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DND = styled.label`
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  color: #8792ac;

  background-color: #211e2b;
  border-radius: 6px;
  cursor: pointer;
`;
