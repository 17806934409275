/*
 * action.js file for videoplayer view
 * Author:
 * 1.Manikanta
 *
 * @copyright of Panorbit Services LLP.
 */

import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getVideoDetails: ['payload'],
  getVideoDetailsStart: ['payload'],
  getVideoDetailsSuccess: ['payload'],
  getVideoDetailsFailure: ['payload'],

  getVideoCommentsList: ['payload'],
  getVideoCommentsListStart: ['payload'],
  getVideoCommentsListSuccess: ['payload'],
  getVideoCommentsListFailure: ['payload'],

  VideoDetailsReset: ['payload'],
  resetComments: null
});

export { Types, Creators };
