/* eslint-disable no-param-reassign */

import React from 'react';
import { Line } from 'react-chartjs-2';
import { getDay, getMothSmall, getYearMonthDay } from '../../../services/utility/moment';
import { ChartCanvasWrapper } from './HomeStyles';
import { getPastDays } from './utlity';

const SleepQuality = ({ sleepStats, days, type }) => {
  // data for line chart
  const pastDays = getPastDays({ type, days });

  pastDays.forEach((item) => {
    let filterDay = [];

    if (type !== 'month') {
      filterDay = sleepStats.filter((stat) => getYearMonthDay(stat.time) === item.MMDDYY);
    } else {
      filterDay = sleepStats.filter((stat) => getMothSmall(stat.time) === item.MM);
    }

    item.sleepTime = filterDay.length > 0 ? filterDay[0].duration : null;
  });

  const sleepDates = [];
  const sleepHours = [];

  pastDays.reverse().forEach((item) => {
    if (type !== 'month') {
      sleepDates.push(getDay(item.MMDDYY));
    } else {
      sleepDates.push(getMothSmall(item.MMDDYY));
    }
    sleepHours.push(item.sleepTime);
  });

  // line chart
  const chartData = (canvas) => {
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, canvas.height, 0, 0);
    gradient.addColorStop(0, '#21D0B300');
    gradient.addColorStop(1, '#21D0B382');

    return {
      labels: sleepDates,
      datasets: [
        {
          label: type === 'month' ? 'Month' : 'Dates',
          data: sleepHours,
          fill: true,
          backgroundColor: gradient,
          borderColor: 'rgba(75,192,192,1)',
          lineTension: 0
        }
      ]
    };
  };

  const options = {
    tooltips: {
      enabled: true,
      callbacks: {
        label(data) {
          return `Sleep ${data.value}`;
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        boxWidth: 0
      }
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            zeroLineBorderDash: [5, 5],
            color: 'rgba(0, 0, 0, 0)',
            zeroLineColor: 'rgba(255, 255, 255, 0.3)'
          },
          spanGaps: false
        }
      ],
      yAxes: [
        {
          gridLines: {
            color: '#ffffff4c',
            zeroLineColor: 'rgba(255, 255, 255, 0.3)',
            zeroLineBorderDash: [5, 5],
            borderDash: [5, 5]
          },
          scaleLabel: {
            display: true,
            labelString: 'Average Sleep (in hrs)'
          },
          ticks: {
            beginAtZero: true
          }
        }
      ]
    }
  };

  return (
    <ChartCanvasWrapper>
      <Line data={chartData} options={options} />
    </ChartCanvasWrapper>
  );
};

export default SleepQuality;
