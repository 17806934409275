import styled from 'styled-components';

import { fontWeight, textColors, borderColor } from '../../../shared/constants';

export const Card = styled.div`
  width: 341px;
  height: auto;

  padding: 15px;

  border: 1px solid ${borderColor.light};
  border-radius: 6px;
`;

export const CloseButton = styled.div`
  z-index: 999;
  background: transparent;
  border: none;
  cursor: pointer;
`;

// Intake Summary

export const SummaryContainer = styled.div`
  width: 270px;
  height: 157px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 1.5rem;
`;

export const ProgressContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 177px;
  height: 177px;
`;

export const ProgressDetails = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90px;
`;

export const KcalTaken = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > h1 {
    display: inline-block;
    font-size: 34px;
    font-weight: ${fontWeight.bold};
    padding-right: 10px;
  }

  & > p {
    display: inline-block;
    font-size: 12px;
    font-weight: ${fontWeight.medium};

    text-transform: uppercase;
    color: ${textColors.textGray};
  }
`;

export const TotalKcal = styled.div`
  & > p {
    display: inline-block;

    font-size: 11px;
    font-weight: ${fontWeight.medium};
    color: ${textColors.textGray};
  }

  & > p:first-child {
    padding-right: 0.4rem;
  }
`;

export const MoreDetails = styled.div`
  height: 104px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const P = styled.p`
  font-size: 12px;
  font-weight: ${fontWeight.medium};
  color: ${textColors.textGray};

  & > span {
    padding-left: 5px;
    color: ${textColors.textWhite};
    font-size: 12px;
  }
`;

// Water Intake

export const WaterContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 13px 0 20px 0;

  border-top: 1px solid ${borderColor.light};
  border-bottom: 1px solid ${borderColor.light};
`;

export const WaterHeader = styled.div`
  display: flex;
  justify-content: space-between;

  padding-bottom: 20px;

  & > p {
    display: inline-block;

    font-size: 14px;
    font-weight: ${fontWeight.medium};
  }

  & > p:last-child {
    font-size: 12px;
  }
`;

export const BottleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const UnfillBottle = styled.div``;

export const FillTheBottle = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmptyBottleWrapper = styled.div`
  & > svg > g > path {
    fill: #595467;
  }
`;

export const CommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 11px;
`;

export const CommentInputContainer = styled.div`
  & > div > div > input {
    font-size: 12px;
  }
`;
