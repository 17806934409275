import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getCoachPlayerList: ['payload'],
  getCoachPlayerListStart: ['payload'],
  getCoachPlayerListSuccess: ['payload'],
  getCoachPlayerListFailure: ['payload'],

  getCoachPlayerDetails: ['payload'],
  getCoachPlayerDetailsStart: ['payload'],
  getCoachPlayerDetailsSuccess: ['payload'],
  getCoachPlayerDetailsFailure: ['payload'],

  SearchCoachPlayers: ['payload'],
  SearchCoachPlayersStart: ['payload'],
  SearchCoachPlayersSuccess: ['payload'],
  SearchCoachPlayersFailure: ['payload'],

  setPlayerDetails: ['payload'],

  coachPlayerReset: ['payload'],
  setPage: ['payload'],
  resetError: null
});

export { Types, Creators };
