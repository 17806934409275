/* eslint-disable no-unused-vars */
/*
 * reducer.js file for coach plannerdetails view
 * Author:
 * 1.Manikanta
 * @copyright of Panorbit Services LLP.
 */

import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  loading: false,
  error: null,
  success: false,
  workoutList: [],
  plannerDetails: null,
  groupDetails: null,
  pageNo: 1,
  maxPage: 1,
  reloadPlanner: false
};

const getPlannerDetailsStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getPlannerDetailsSuccess = (state = INITIAL_STATE, action) => {
  const { plannerDetails } = action.payload;

  return {
    ...state,
    loading: false,
    plannerDetails,
    reloadPlanner: false,
    success: false
  };
};

const getPlannerDetailsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const getGroupDetailsStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const getGroupDetailsSuccess = (state = INITIAL_STATE, action) => {
  const { groupDetails } = action.payload;

  return {
    ...state,
    loading: false,
    groupDetails,
    reloadPlanner: false
  };
};

const getGroupDetailsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const createPlannerSessionStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });

const createPlannerSessionSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: false,
  reloadPlanner: true,
  success: true
});

const createPlannerSessionFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const deleteSessionStart = (state = INITIAL_STATE) => ({ ...state, error: null, loading: true });
const deleteSessionSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true,
  reloadPlanner: true,
  success: true
});
const deleteSessionFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const copyScheduleByDayStart = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true,
  reloadPlanner: false
});

const copyScheduleByDaySuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: false,
  reloadPlanner: true,
  success: true
});

const copyScheduleByDayFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const clearScheduleByDayStart = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true,
  reloadPlanner: false
});

const clearScheduleByDaySuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: false,
  reloadPlanner: true,
  success: true
});

const clearScheduleByDayFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const clearScheduleByWeekStart = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true,
  reloadPlanner: false
});

const clearScheduleByWeekSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: false,
  reloadPlanner: true,
  success: true
});

const clearScheduleByWeekFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    loading: false,
    error
  };
};

const HANDLERS = {
  [Types.GET_PLANNER_DETAILS_START]: getPlannerDetailsStart,
  [Types.GET_PLANNER_DETAILS_SUCCESS]: getPlannerDetailsSuccess,
  [Types.GET_PLANNER_DETAILS_FAILURE]: getPlannerDetailsFailure,

  [Types.GET_GROUP_DETAILS_START]: getGroupDetailsStart,
  [Types.GET_GROUP_DETAILS_SUCCESS]: getGroupDetailsSuccess,
  [Types.GET_GROUP_DETAILS_FAILURE]: getGroupDetailsFailure,

  [Types.CREATE_PLANNER_SESSION_START]: createPlannerSessionStart,
  [Types.CREATE_PLANNER_SESSION_SUCCESS]: createPlannerSessionSuccess,
  [Types.CREATE_PLANNER_SESSION_FAILURE]: createPlannerSessionFailure,

  [Types.DELETE_SESSION_START]: deleteSessionStart,
  [Types.DELETE_SESSION_SUCCESS]: deleteSessionSuccess,
  [Types.DELETE_SESSION_FAILURE]: deleteSessionFailure,

  [Types.COPY_SCHEDULE_BY_DAY_START]: copyScheduleByDayStart,
  [Types.COPY_SCHEDULE_BY_DAY_SUCCESS]: copyScheduleByDaySuccess,
  [Types.COPY_SCHEDULE_BY_DAY_FAILURE]: copyScheduleByDayFailure,

  [Types.CLEAR_SCHEDULE_BY_DAY_START]: clearScheduleByDayStart,
  [Types.CLEAR_SCHEDULE_BY_DAY_SUCCESS]: clearScheduleByDaySuccess,
  [Types.CLEAR_SCHEDULE_BY_DAY_FAILURE]: clearScheduleByDayFailure,

  [Types.CLEAR_SCHEDULE_BY_WEEK_START]: clearScheduleByWeekStart,
  [Types.CLEAR_SCHEDULE_BY_WEEK_SUCCESS]: clearScheduleByWeekSuccess,
  [Types.CLEAR_SCHEDULE_BY_WEEK_FAILURE]: clearScheduleByWeekFailure
};

export const coachPlannerDetailsReducer = createReducer(INITIAL_STATE, HANDLERS);
