/*
 * action.js file for skills view
 * Author:
 * 1.Manikanta
 *
 * @copyright of Panorbit Services LLP.
 */

import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getCoachFeedList: ['payload'],
  getCoachFeedListStart: ['payload'],
  getCoachFeedListSuccess: ['payload'],
  getCoachFeedListFailure: ['payload'],

  getCoachFeedComments: ['payload'],
  getCoachFeedCommentsStart: ['payload'],
  getCoachFeedCommentsSuccess: ['payload'],
  getCoachFeedCommentsFailure: ['payload'],

  coachSkillsReset: ['payload'],
  setPage: ['payload']
});

export { Types, Creators };
