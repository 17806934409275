import React from 'react';
import { Label } from '../../../../globalShared/CommonStyles';
import { Textarea } from './TextareaComponent.styles';

const TextArea = (props) => {
  // values
  const { placeholder, label, value, height } = props;

  // functions
  const { setValue } = props;

  return (
    <div>
      <Label>{label}</Label>
      <Textarea placeholder={placeholder} value={value} height={height} onChange={(e) => setValue(e.target.value)} />
    </div>
  );
};
export default TextArea;
