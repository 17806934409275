import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getCoachMealFeedList: ['payload'],
  getCoachMealFeedListStart: ['payload'],
  getCoachMealFeedListSuccess: ['payload'],
  getCoachMealFeedListFailure: ['payload'],

  getCoachMealFeedComments: ['payload'],
  getCoachMealFeedCommentsStart: ['payload'],
  getCoachMealFeedCommentsSuccess: ['payload'],
  getCoachMealFeedCommentsFailure: ['payload'],

  getCoachMealDetails: ['payload'],
  getCoachMealDetailsStart: ['payload'],
  getCoachMealDetailsSuccess: ['payload'],
  getCoachMealDetailsFailure: ['payload'],

  getCoachIntakeSummaryComments: ['payload'],
  getCoachIntakeSummaryCommentsStart: ['payload'],
  getCoachIntakeSummaryCommentsSuccess: ['payload'],
  getCoachIntakeSummaryCommentsFailure: ['payload'],

  addCoachIntakeSummaryComment: ['payload'],
  addCoachIntakeSummaryCommentStart: ['payload'],
  addCoachIntakeSummaryCommentSuccess: ['payload'],
  addCoachIntakeSummaryCommentFailure: ['payload'],

  coachMealReset: null,
  setIntakeModalData: ['payload'],
  setIntakeSummaryId: ['payload'],
  setPage: ['payload'],
  resetIntake: null
});

export { Types, Creators };
