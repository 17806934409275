import React from 'react';

// styles
import { Container } from './ProfileModalStyles';

import { DetailsCnt, DetailsRow, Header, H2, Image, P, H3, Flex } from './PersonalStyles';

// assets
import dummyImage from '../../../../shared/assets/user.jpg';

// maps
import { genderMap, levelMap, trainingAgeMap } from '../../../../services/utility/maps';
import { shortenText } from '../../../../services/utility';

const Personal = ({ personalDetails }) => (
  <>
    <Container style={{ margin: '2rem 0' }}>
      <Header>
        <H2>Details</H2>
      </Header>
      <Flex>
        <Image style={{ backgroundImage: `url(${personalDetails?.profilePicture || dummyImage})` }} />
        <DetailsCnt after>
          <DetailsRow>
            <P light>Name</P>
            <H3>{shortenText(`${personalDetails?.firstName} ${personalDetails?.lastName}`, 10, '...')}</H3>
          </DetailsRow>
          <DetailsRow>
            <P light>Email</P>
            <H3 style={{ overflowWrap: 'anywhere' }}>{`${personalDetails?.email}`}</H3>
          </DetailsRow>
          <DetailsRow>
            <P light>Phone</P>
            <H3>{`${personalDetails?.phoneNumber}`}</H3>
          </DetailsRow>
          <DetailsRow>
            <P light>Age</P>
            <H3>{`${personalDetails?.age}`}</H3>
          </DetailsRow>
          <DetailsRow>
            <P light>Gender</P>
            <H3>{genderMap[personalDetails?.gender]}</H3>
          </DetailsRow>
          <DetailsRow>
            <P light>Weight</P>
            <H3>{`${personalDetails?.weight}`}</H3>
          </DetailsRow>
          <DetailsRow>
            <P light>Height</P>
            <H3>{`${personalDetails?.height}`}</H3>
          </DetailsRow>
        </DetailsCnt>

        <DetailsCnt>
          <DetailsRow>
            <P light>Level</P>
            <H3>{levelMap[personalDetails?.level]}</H3>
          </DetailsRow>
          <DetailsRow>
            <P light>Training Age</P>
            <H3>{trainingAgeMap[personalDetails?.trainingAge]}</H3>
          </DetailsRow>
          <DetailsRow>
            <P light>About</P>
            <H3>{personalDetails?.about}</H3>
          </DetailsRow>
          <DetailsRow>
            <P light>Goals</P>
            <H3>{personalDetails?.goals}</H3>
          </DetailsRow>
        </DetailsCnt>
      </Flex>
    </Container>
  </>
);

export default Personal;
