import styled from 'styled-components';

import { fontSize, fontWeight, accentColor } from '../../../shared/constants';

export const Container = styled.div`
  height: 100%;
  overflow-y: auto;
  padding-right: 0.5rem;
`;

export const Card = styled.div`
  background: #261d3c;
  border-radius: 6px;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 1.3rem;
`;

export const Linkto = styled.div`
  font-size: ${fontSize.title};
  color: ${accentColor.primary};
  cursor: pointer;
  &:focus {
    outline: 0px;
  }
`;

export const VideoPlayerContainer = styled.div`
  height: 293px;
  background: #000;
`;

export const PlayerTitle = styled.h1`
  font-size: ${fontSize.mediumDefault};
  font-weight: ${fontWeight.medium};

  margin: 0 1rem;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

export const VideoUploadedAT = styled.p`
  font-size: ${fontSize.smallDefault};
  font-weight: ${fontWeight.medium};
  color: #8792ac;
  margin: 0.5rem 1rem;
`;
