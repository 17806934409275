import styled from 'styled-components';
import { backgroundColor, borderColor } from '../../constants';

export const Container = styled.div`
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  justify-content: flex-end;

  background-color: ${backgroundColor.popupBg};
  z-index: 999999999;
`;

export const MainContainer = styled.div`
  height: 100%;
  width: ${(props) => props.width}px;

  background-color: ${backgroundColor.popup};
  border-radius: 6px 0px 0px 6px;

  overflow-y: auto;
  position: relative;
`;

export const ContentContainer = styled.div`
  height: calc(100vh - 81px);
  overflow-y: auto;
`;

export const CloseButton = styled.div`
  position: absolute;
  right: calc(${(props) => props.right}px + 30px);
  top: 18px;
  z-index: 999;
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const FormFooter = styled.div`
  padding: 1.5rem 0;
  display: flex;
  justify-content: flex-end;

  border-top: 1px solid ${borderColor.light};
`;
